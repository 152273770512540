import { yupResolver } from "@hookform/resolvers/yup";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useEffect } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";
import { object, string } from "yup";

import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";

import { AppbarTitleWithBackAction } from "@components/Appbar";
import { useErrors } from "@hooks/useErrors";
import { RootStackParamList } from "@navigators/navigation.types";
import {
  createNote,
  deleteNote,
  editNote,
  getPersonalNoteDetails,
} from "@services/ApiService/profile";
import { globalStyles } from "@styles/global";
import { MULTILINE_INPUT_MAX_LENGTH } from "@utils/constants";
import { ActivityIndicator } from "react-native-paper";
import { queryKeysPersonalNotes } from "./queryKeysPersonalNotes";

type CreateAndEditNoteProps = PropsWithChildren<
  NativeStackScreenProps<RootStackParamList, "CreateAndEditNote">
>;

type FormTypes = {
  title: string;
  content: string;
};

const CreateAndEditNote: FC<CreateAndEditNoteProps> = ({
  navigation: { goBack, setOptions },
  route,
}) => {
  const id = route.params?.id;
  const { container, gapLarge } = globalStyles;
  const { t } = useTranslation();
  const { setErrorsFromResponse, errors } = useErrors();
  const queryClient = useQueryClient();

  const shouldQueryEnable = id !== undefined;

  const {
    isLoading: isQueryLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: queryKeysPersonalNotes.details(id),
    queryFn: async () => await getPersonalNoteDetails(id),
    enabled: shouldQueryEnable,
    onSuccess: ({ title, content }) => {
      setValue("title", title);
      setValue("content", content);
    },
  });

  const schema = object().shape({
    title: string().required(t("T00014")).trim(),
    content: string()
      .max(MULTILINE_INPUT_MAX_LENGTH)
      .required(t("T00014"))
      .trim(),
  });

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading: mutationLoading } = useMutation({
    mutationFn: async (data: FormTypes) =>
      await (id ? editNote(id, data) : createNote(data)),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeysPersonalNotes.list(),
        }),
        id &&
          queryClient.invalidateQueries({
            queryKey: queryKeysPersonalNotes.details(id),
          }),
      ]);

      goBack();
    },
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const { isLoading: isDeleting, mutate: deleteThisNote } = useMutation({
    mutationFn: deleteNote,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeysPersonalNotes.list(),
      });
      goBack();
    },
  });

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack } }) => (
        <AppbarTitleWithBackAction
          title={t(id ? "T00096" : "T00095")}
          onClose={goBack}
        />
      ),
    });
  }, [id, setOptions, t]);

  const onSubmit = (data: FormTypes) => mutate(data);

  if (isQueryLoading && id)
    return <ActivityIndicator style={globalStyles.loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <ScrollView
      contentContainerStyle={[container, gapLarge]}
      scrollEnabled={false}>
      <Input
        name="title"
        label="T00797"
        control={control as unknown as Control<FieldValues>}
        errors={errors?.title}
      />
      <Input
        name="content"
        label="T00406"
        control={control as unknown as Control<FieldValues>}
        multiline
        errors={errors?.content}
        maxLength={MULTILINE_INPUT_MAX_LENGTH}
      />
      {id && (
        <PrimaryButton
          label="T00412"
          mode="outlined"
          onPress={() => deleteThisNote(id)}
          loading={isDeleting}
        />
      )}
      <PrimaryButton
        label="T00164"
        onPress={handleSubmit(onSubmit)}
        loading={mutationLoading}
      />
    </ScrollView>
  );
};

export default CreateAndEditNote;
