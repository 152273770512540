import { FC } from "react";
import { Appbar } from "react-native-paper";
import { TitleWithAction } from "./Appbar.types";
import { palettes } from "@styles/colors";
import { isANDROID } from "@utils/constants";

type AppbarTitleWithBackActionProps = {
  statusBarHeight?: number;
} & TitleWithAction;

const AppbarTitleWithBackAction: FC<AppbarTitleWithBackActionProps> = ({
  title,
  onBack,
  onClose,
  filledIcon = false,
  containerStyle,
  statusBarHeight,
}) => {
  const applySmallFontSize =
    typeof title === "string" && isANDROID && title.length >= 20;

  return (
    <Appbar.Header
      elevated
      mode="center-aligned"
      style={containerStyle}
      statusBarHeight={statusBarHeight}>
      {onBack ? (
        filledIcon ? (
          <Appbar.Action
            icon="arrow-left"
            onPress={onBack}
            iconColor={palettes.primary[100]}
            style={{ backgroundColor: palettes.primary[40] }}
          />
        ) : (
          <Appbar.Action icon="arrow-left" onPress={onBack} />
        )
      ) : null}
      {applySmallFontSize ? (
        <Appbar.Content title={title} titleStyle={{ fontSize: 20 }} />
      ) : (
        <Appbar.Content title={title} />
      )}
      {onClose ? (
        <Appbar.Action icon="close" size={29} onPress={onClose} />
      ) : null}
    </Appbar.Header>
  );
};
export default AppbarTitleWithBackAction;
