import { DETAILS, LIST } from "../../../../utils/constants";

const keys = {
  personalNotes: "personalNotes",
};

export const queryKeysPersonalNotes = {
  all: [keys.personalNotes] as const,
  list: () => [...queryKeysPersonalNotes.all, LIST] as const,
  details: (id: number) =>
    [...queryKeysPersonalNotes.all, DETAILS, id] as const,
};
