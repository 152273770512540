import { CHECK, DETAILS, LIST } from "@utils/constants";
import { ServiceType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";

const keys = {
  physiotherapistRatings: "physiotherapistRatings",
  physiotherapistProfile: "physiotherapistProfile",
  full: "full",
  services: "services",
};

export const queryKeysPhysiotherapistRatings = {
  all: [keys.physiotherapistRatings] as const,
  details: (id: number) => [
    ...queryKeysPhysiotherapistRatings.all,
    DETAILS,
    id,
  ],
  list: (id: number) => [...queryKeysPhysiotherapistRatings.all, LIST, id],
  check: (id: number) => [...queryKeysPhysiotherapistRatings.all, CHECK, id],
  answerDetails: (answer: number | undefined, review: number) => [
    ...queryKeysPhysiotherapistRatings.details(review),
    answer,
  ],
};

export const queryKeysPhysiotherapistProfileData = {
  all: [keys.physiotherapistProfile] as const,
  full: (id: number) =>
    [...queryKeysPhysiotherapistProfileData.all, keys.full, id] as const,
  services: (id: number, serviceType: ServiceType) =>
    [
      ...queryKeysPhysiotherapistProfileData.all,
      keys.services,
      id,
      serviceType,
    ] as const,
  serviceDetails: (id: number, serviceType: ServiceType, serviceId: number) =>
    [
      ...queryKeysPhysiotherapistProfileData.services(id, serviceType),
      serviceId,
    ] as const,
};
