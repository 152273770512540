import { Text } from "react-native-paper";
import ReportWrapper from "@components/Report/ReportWrapper";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { ReportProps } from "@components/Report/report.types";
import { useUserDetails } from "@hooks/user/useUserDetails";

type ExerciseTitleSectionType = ReportProps & {
  title: string;
  isAssistantExercise: boolean;
};

const InternalText: FC<{ title: string }> = ({ title }) => {
  const { t } = useTranslation();
  return (
    <>
      <Text variant="titleMedium">{t("T00179")}:</Text>
      <Text variant="bodyMedium">{title}</Text>
    </>
  );
};

const ExerciseTitle: FC<ExerciseTitleSectionType> = ({
  title,
  isAssistantExercise,
  ...props
}) => {
  const { isPatient } = useUserDetails();
  return isPatient && !isAssistantExercise ? (
    <ReportWrapper {...props}>
      <InternalText title={title} />
    </ReportWrapper>
  ) : (
    <InternalText title={title} />
  );
};

export default ExerciseTitle;
