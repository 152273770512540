import { View } from "react-native";
import { Text } from "react-native-paper";
import ReportWrapper from "@components/Report/ReportWrapper";
import { FC } from "react";
import { ReportProps } from "@components/Report/report.types";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { useMediaStyle } from "@hooks/ui/useMediaStyle";
import { globalStyles } from "@styles/global";
import { ResizeMode } from "expo-av";
import VideoComponent from "./VideoComponent";

type ExerciseVideoSectionType = ReportProps & {
  title: string;
  uri: string;
  isAssistantExercise: boolean;
  subtitlesUrl?: string;
  isThumbnail?: boolean;
};

const SingleSection: FC<{
  title: string;
  uri: string;
  subtitlesUrl?: string | null;
  isThumbnail?: boolean;
}> = ({ title, ...props }) => {
  const mediaStyle = useMediaStyle();
  return (
    <View style={globalStyles.gapMedium}>
      <Text variant="titleMedium">{title}:</Text>
      <VideoComponent
        resizeMode={ResizeMode.CONTAIN}
        style={mediaStyle}
        {...props}
      />
    </View>
  );
};

const ExerciseVideoSection: FC<ExerciseVideoSectionType> = ({
  title,
  uri,
  isAssistantExercise,
  subtitlesUrl,
  isThumbnail,
  ...props
}) => {
  const { isPatient } = useUserDetails();
  return isPatient && !isAssistantExercise ? (
    <ReportWrapper {...props}>
      <SingleSection
        title={title}
        uri={uri}
        subtitlesUrl={subtitlesUrl}
        isThumbnail={isThumbnail}
      />
    </ReportWrapper>
  ) : (
    <SingleSection
      title={title}
      uri={uri}
      subtitlesUrl={subtitlesUrl}
      isThumbnail={isThumbnail}
    />
  );
};

export default ExerciseVideoSection;
