import { AntDesign } from "@expo/vector-icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, Card, CardProps, Text } from "react-native-paper";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { CardElevationType } from "../cards/Card.types";

import { ProgressCircle } from "@components/ProgressCircle/ProgressCircle";
import { spacing24, spacing8 } from "@styles/spacing";
import { queryKeysPatientCompletion } from "@screens/Profiles/MyProfile/PatientCompletion/queryKeysPatientCompletion";
import {
  getPatientVerificationProgress,
  getRehabVerificationProgress,
} from "@services/ApiService/verifications";
import { useQuery } from "@tanstack/react-query";
import {
  getSubtitleForProfileCompletionTile,
  getSubtitleForProfileCompletionTileForPatient,
} from "@utils/getDataForVerificationTiles";
import { FetchError } from "@components/errors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { queryKeysVerficiations } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/queryKeysPhysiotherapistVerification";

type VerificationTileWithProgressType = Omit<CardProps, "children"> & {
  elevation?: CardElevationType;
};

const VerificationTileWithProgress: FC<VerificationTileWithProgressType> = ({
  elevation,
  onPress,
  ...props
}) => {
  const { isPhysiotherapist } = useUserDetails();
  const { t } = useTranslation();

  const {
    data: dataA,
    isLoading: isLoadingA,
    isError: isErrorA,
    refetch: refetchA,
  } = useQuery({
    queryKey: queryKeysVerficiations.verificationProgress(),
    queryFn: getRehabVerificationProgress,
    enabled: isPhysiotherapist,
  });

  const {
    data: dataB,
    isLoading: isLoadingB,
    isError: isErrorB,
    refetch: refetchB,
  } = useQuery({
    queryKey: queryKeysPatientCompletion.verificationProgressPatient(),
    queryFn: getPatientVerificationProgress,
    enabled: !isPhysiotherapist,
  });

  const isLoading = isPhysiotherapist ? isLoadingA : isLoadingB;
  const isError = isPhysiotherapist ? isErrorA : isErrorB;
  const refetch = isPhysiotherapist ? refetchA : refetchB;
  const data = isPhysiotherapist ? dataA : dataB;

  const subtitle = isPhysiotherapist
    ? getSubtitleForProfileCompletionTile(data?.progressValue || 0)
    : getSubtitleForProfileCompletionTileForPatient(data?.progressValue || 0);

  return (
    <Card
      {...props}
      onPress={onPress}
      mode="outlined"
      elevation={elevation as never}
      style={{ flex: 1 }}>
      <Card.Content style={styles.container}>
        {isLoading ? (
          <ActivityIndicator />
        ) : isError ? (
          <FetchError action={refetch} coverScreen={false} />
        ) : (
          <>
            <View style={[globalStyles.gapSmall, { flex: 1 }]}>
              <Text variant="titleMedium" numberOfLines={1}>
                {t("T00198")}
              </Text>
              <Text variant="bodySmall" numberOfLines={4}>
                {t(subtitle)}
              </Text>
            </View>
            <ProgressCircle percent={data?.progressValue || 0} />
            <View style={{ padding: spacing8 }}>
              <AntDesign
                name="arrowright"
                size={spacing24}
                color={palettes.primary[0]}
              />
            </View>
          </>
        )}
      </Card.Content>
    </Card>
  );
};

export default VerificationTileWithProgress;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ...globalStyles.gapLarge,
  },
  rightContainer: {
    alignSelf: "center",
  },
  leftContainer: {
    maxWidth: "90%",
  },
});
