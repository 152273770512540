import { StyleSheet, View } from "react-native";
import { FC, useEffect, useState } from "react";
import { IconButton, Text } from "react-native-paper";
import { palettes } from "@styles/colors";
import { spacing24, spacing36, spacing8 } from "@styles/spacing";

type Props = {
  rating?: number;
  editionMode?: boolean;
  onChangeRating?: (rating: number) => void;
  disableNumberRating?: boolean;
};

export const RatingStars: FC<Props> = ({
  rating = 0,
  editionMode,
  onChangeRating,
  disableNumberRating,
}) => {
  const [selectedStar, setSelectedStar] = useState(0);
  const stars: string[] = [];
  const calculatedRating = Math.round(rating * 2) / 2;

  for (let i = 1; i <= 5; i++) {
    if (editionMode) {
      stars.push(i <= selectedStar ? "star" : "star-outline");
    } else {
      stars.push(
        i <= calculatedRating
          ? "star"
          : i - calculatedRating === 0.5
          ? "star-half-full"
          : "star-outline",
      );
    }
  }

  const size = editionMode ? spacing36 : spacing24;

  const onPressStar = (star: number) => {
    setSelectedStar(prevState => {
      const result = prevState - 1 === star ? 0 : star + 1;
      onChangeRating(result);
      return result;
    });
  };

  useEffect(() => {
    rating && setSelectedStar(rating);
  }, [rating]);

  return (
    <View style={styles.container}>
      {!editionMode && !disableNumberRating && (
        <Text variant="bodyMedium" style={{ marginRight: spacing8 }}>
          {rating.toFixed(1)}
        </Text>
      )}
      {stars.map((e, i) => (
        <IconButton
          key={`star-${i}-${e}`}
          icon={e}
          onPress={editionMode ? () => onPressStar(i) : null}
          iconColor={palettes.warning[91]}
          style={[styles.icon, { width: size, height: size }]}
          size={size}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flexDirection: "row", alignItems: "center" },
  icon: { padding: 0, margin: 0 },
});
