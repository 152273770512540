import { SvgCalendar } from "@assets/svg";
import AddressWithMapRedirect from "@components/Address/AddressWithWebviewRedirect";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { StationaryPaymentsSection } from "@components/index";
import PermissionModal from "@components/Modals/PermissionModal";
import InfoTile from "@components/Tile/InfoTile";
import ServiceTile from "@components/Tile/service/ServiceTile";
import { useAuth } from "@contexts/AuthContext/auth";
import { PermissionType } from "@globalTypes/common.types";
import { useAddEventToCalendar } from "@hooks/index";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { checkIfEventEnded } from "@utils/calendar";
import { isWeb } from "@utils/constants";
import { getFormatDateForAppointmentDetails } from "@utils/date";
import { capitalize } from "lodash";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Appointment } from "../appointment.types";
import { DetailsItem } from "./DetailsItem";

type Props = {
  data: Appointment;
  customAppointment?: boolean;
};

export const InformationDetails: FC<Props> = ({ data, customAppointment }) => {
  const { t } = useTranslation();
  const {
    user: { language },
  } = useAuth();
  const {
    cancelled,
    physiotherapist,
    service,
    serviceType,
    dateFrom,
    dateTo,
    appointmentLanguage,
    payment,
    paymentType,
  } = data;

  const {
    isRefetching,
    isError,
    saveEventToSystemCalendar,
    handleConfirm,
    calendarPermissionsModalVisible,
    refetch,
    setCalendarPermissionsModalVisible,
  } = useAddEventToCalendar({ data, customAppointment });

  const { freeService } = service.serviceItem;

  const paymentSuccessful = payment?.paymentStatus === "succeeded";

  const showSaveEventToCalendarButton = useMemo(() => {
    const eventHasEnded = checkIfEventEnded(dateTo);
    return !isWeb && !eventHasEnded;
  }, [dateTo]);

  const priceToDisplayOnServiceTile = useMemo(
    () =>
      payment
        ? {
            amount: payment?.amount / 100,
            currency: payment?.currency,
          }
        : null,
    [payment],
  );

  return (
    <View style={globalStyles.gapLarge}>
      <DetailsItem
        title={`${t("T00583")}:`}
        extras={() => (
          <ServiceTile
            service={service}
            payment={priceToDisplayOnServiceTile}
          />
        )}
      />
      <InfoTile status="info" content={<Text>{t("T01303")}</Text>} />
      <DetailsItem
        title={`${t("T00122")}:`}
        subtitle={
          data ? getFormatDateForAppointmentDetails(dateFrom, language) : null
        }
      />
      {!customAppointment && isError ? (
        <FetchError action={refetch} coverScreen={false} />
      ) : (
        !cancelled &&
        showSaveEventToCalendarButton && (
          <PrimaryButton
            label="T01043"
            mode="outlined"
            icon={props => (
              <SvgCalendar fill={palettes.primary[40]} {...props} />
            )}
            onPress={saveEventToSystemCalendar}
            loading={isRefetching}
          />
        )
      )}
      {serviceType === "Stationary" && (
        <DetailsItem
          title={`${t("T00139")}:`}
          extras={() => <AddressWithMapRedirect physioId={physiotherapist} />}
        />
      )}
      <DetailsItem
        title={`${t("T00633")}:`}
        subtitle={capitalize(appointmentLanguage)}
      />
      <DetailsItem
        title={`${t("T00551")}:`}
        subtitle={t(serviceType === "Stationary" ? "T00484" : "T00483")}
      />

      {!freeService &&
        (paymentType === "Stationary" ? (
          <View style={globalStyles.gapSmall}>
            <Text variant="titleMedium">{t("T01144")}</Text>
            <StationaryPaymentsSection physiotherapistId={physiotherapist} />
          </View>
        ) : (
          <DetailsItem
            title={`${t("T00804")}:`}
            subtitle={
              // this 'payment' condition is temporary - finally always should be some payment with status
              `${t(
                payment
                  ? cancelled
                    ? "T00809"
                    : paymentSuccessful
                    ? "T00809"
                    : "T00810"
                  : "T00807",
              )}`
            }
          />
        ))}
      <PermissionModal
        isModalVisible={calendarPermissionsModalVisible}
        setIsModalVisible={setCalendarPermissionsModalVisible}
        onPressConfirm={handleConfirm}
        type={PermissionType.calendar}
      />
    </View>
  );
};
