import { LIST } from "@utils/constants";

const keys = {
  services: "services",
  countries: "countries",
};

export const queryKeysServices = {
  all: [keys.services] as const,
  list: (query?: string) => [...queryKeysServices.all, LIST, query],
};

export const queryKeysCountries = {
  all: [keys.countries] as const,
  list: () => [...queryKeysCountries.all, LIST],
};
