import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={60} height={60} fill="none" {...props}>
    <Path
      fill="#78767A"
      d="m26.4 41.7 14.01-10.5c.81-.6.81-1.8 0-2.4L26.4 18.3c-.99-.75-2.4-.03-2.4 1.2v21c0 1.23 1.41 1.95 2.4 1.2ZM30 0C13.44 0 0 13.44 0 30c0 16.56 13.44 30 30 30 16.56 0 30-13.44 30-30C60 13.44 46.56 0 30 0Zm0 54C16.77 54 6 43.23 6 30S16.77 6 30 6s24 10.77 24 24-10.77 24-24 24Z"
    />
  </Svg>
);
export default SvgComponent;
