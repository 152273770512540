import InfoTile from "@components/Tile/InfoTile";
import SwitchSection from "./SwitchSection";
import { ActivityIndicator, Text } from "react-native-paper";
import { Trans } from "react-i18next";
import { globalStyles } from "@styles/global";
import { ScrollView, View } from "react-native";
import { useTrainingsNotificationsSettings } from "@hooks/notifications/useTrainingsNotificationsSettings";
import CheckboxSimpleText from "@components/Checkboxes/CheckboxSimpleText";
import { spacing16, spacing8 } from "@styles/spacing";

import { FetchError } from "@components/errors";
import DayRemindersList from "./DayRemindersList";

const TrainingsPushSettings = () => {
  const {
    trainingsNotificationsSwitchOn,
    onTrainingsNotificationsSwitchChange,
    control,
    individualDayReminderSwitchOn,
    onIndividualDayReminderSwitchChange,
    isLoading,
    isError,
    refetch,
    notificationTimes,
    handleSubmitSingleDay,
    handleSubmitMultipleDays,
  } = useTrainingsNotificationsSettings();

  const { scrollContainer, gapMedium, fontBolded, loading } = globalStyles;

  if (isLoading) return <ActivityIndicator style={loading} />;

  if (isError) return <FetchError action={refetch} />;

  return (
    <ScrollView style={scrollContainer} contentContainerStyle={gapMedium}>
      <SwitchSection
        switchOn={trainingsNotificationsSwitchOn}
        onChange={onTrainingsNotificationsSwitchChange}
        title="T01475"
        description="T01476"
      />
      <InfoTile
        status="info"
        content={
          <Text variant="bodyMedium">
            <Trans
              i18nKey="T01477"
              components={{
                1: <Text style={fontBolded}>all trainings</Text>,
                2: (
                  <Text style={fontBolded}>
                    only on days when you have a scheduled training sessions
                  </Text>
                ),
              }}
            />
          </Text>
        }
      />
      {trainingsNotificationsSwitchOn && (
        <>
          <CheckboxSimpleText
            name="reminder"
            text="T01478"
            control={control}
            variant="bodyLarge"
            checkboxContainerStyle={{ padding: spacing16 }}
            additionalMarginForText={spacing16}
            handleSubmit={
              individualDayReminderSwitchOn
                ? handleSubmitMultipleDays
                : handleSubmitSingleDay
            }
          />
          <SwitchSection
            switchOn={individualDayReminderSwitchOn}
            onChange={onIndividualDayReminderSwitchChange}
            title="T01479"
          />
          <View style={{ marginTop: spacing8 }}>
            <DayRemindersList
              notificationTimes={notificationTimes}
              displayOnlyOneDay={!individualDayReminderSwitchOn}
              handleSubmitSingleDay={handleSubmitSingleDay}
              handleSubmitMultipleDays={handleSubmitMultipleDays}
              control={control}
            />
          </View>
        </>
      )}
    </ScrollView>
  );
};

export default TrainingsPushSettings;
