import { AppbarTitleWithBackAction } from "@components/Appbar";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { FlatList, ScrollView, StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";
import { Image } from "expo-image";
import { useMediaStyle } from "@hooks/ui/useMediaStyle";
import { spacing16, spacing8 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { queryKeysProphylacticWorkoutCategorySets } from "../queryKeysTrainingsAndExercises";
import { getWorkoutCategorySets } from "@services/ApiService/trainings";
import { ActivityIndicator, Chip, Text } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { FetchError } from "@components/errors";
import { CategorySetTile } from "@components/Tile/training/CategorySetTile";
import { WorkoutSetDetails } from "@components/Tile/training/prophylacticSets.type";
import CategoryName from "@components/Training/CategoryName";

export const GeneralWorkoutSets: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "GeneralWorkoutSets">
  >
> = ({ navigation: { setOptions, navigate }, route: { params } }) => {
  const [selectedFilter, setSelectedFilter] = useState<string>(null);
  const { id, name, image } = params.data;
  const { t } = useTranslation();
  const { width, height } = useMediaStyle();
  const { loading, gapLarge } = globalStyles;

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysProphylacticWorkoutCategorySets.list(id, selectedFilter),
    queryFn: async () => {
      const isFilterAmount =
        selectedFilter === "asc" || selectedFilter === "desc"
          ? selectedFilter
          : null;

      return await getWorkoutCategorySets(
        id,
        isFilterAmount ? (selectedFilter as "asc" | "desc") : null,
        !isFilterAmount ? selectedFilter : null,
      );
    },
  });

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack } }) => (
        <AppbarTitleWithBackAction onClose={goBack} title={t("T01529")} />
      ),
    });
  }, [setOptions, t, name]);

  const chipsList = [
    { title: t("T01065"), value: "asc" },
    { title: t("T01066"), value: "desc" },
    { title: t("T01067"), value: "frequency" },
    { title: t("T01068"), value: "-frequency" },
    { title: t("T01069"), value: "duration" },
    { title: t("T01070"), value: "-duration" },
  ];

  const onPressChip = (value: string) =>
    setSelectedFilter(prevState => (prevState === value ? null : value));

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <FlatList
      ListEmptyComponent={<Text variant="bodyMedium">{t("T01062")}</Text>}
      ListHeaderComponent={
        <View style={[gapLarge, { width, marginBottom: spacing8 }]}>
          <CategoryName name={name} />
          <Image source={{ uri: image }} style={{ width, height }} />
          <Text variant="titleMedium">{t("T00818")}</Text>
          <ScrollView
            horizontal
            contentContainerStyle={{ gap: spacing16 }}
            showsHorizontalScrollIndicator={false}>
            {chipsList.map(({ title, value }, i) => (
              <Chip
                key={`chip-sorting-${value}-${i}`}
                mode="outlined"
                icon={selectedFilter === value ? "check" : null}
                onPress={() => onPressChip(value)}>
                <Text>{title}</Text>
              </Chip>
            ))}
          </ScrollView>
        </View>
      }
      contentContainerStyle={styles.listContainer}
      data={data}
      keyExtractor={({ id }, index) => `training-category-sets-${id}-${index}`}
      renderItem={({ item: { id } }) => (
        <CategorySetTile
          id={id}
          onPress={(data: WorkoutSetDetails) =>
            navigate("GeneralWorkoutSetDetails", { data })
          }
        />
      )}
    />
  );
};

const styles = StyleSheet.create({
  listContainer: {
    alignItems: "center",
    paddingVertical: spacing16,
    gap: spacing8,
  },
});
