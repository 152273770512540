import { useUserDetails } from "@hooks/index";
import { useAssistant } from "@hooks/user/useAssistant";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import {
  getPatientBasicData,
  getPhysiotherapistBasicData,
} from "@services/ApiService/users";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { SvgAssistantAvatar } from "@assets/svg";
import Avatar from "@components/Avatar";
import StatusComponent from "@components/Status/StatusComponent";
import { setStatus } from "@components/Status/setStatus";
import { FetchError } from "@components/errors";
import { useUnreadMessages } from "@hooks/queryHooks/useUnreadMessages";
import { useUpcomingAppointment } from "@hooks/queryHooks/useUpcomingAppointment";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { theme } from "@styles/theme";
import { useTranslation } from "react-i18next";
import {
  ActivityIndicator,
  Card,
  CardProps as RNPCardProps,
  Text,
} from "react-native-paper";
import { CardElevationType } from "../Card.types";

type UserChatCardProps = Omit<RNPCardProps, "children"> & {
  interlocutorId: number;
  elevation?: CardElevationType;
};

const UserChatCard = ({
  interlocutorId,
  mode = "outlined",
  elevation,
  ...props
}: UserChatCardProps) => {
  const { isPhysiotherapist } = useUserDetails();
  const { isAssistantId } = useAssistant(interlocutorId);
  const { t } = useTranslation();

  const {
    containerOutlined,
    cardContentStyle,
    newMessagesBorder,
    newMessagesText,
  } = styles;
  const { gapLarge, gapSmall } = globalStyles;

  const {
    allUnreadMessages: newMessages,
    isLoading: isNewMessagesLoading,
    isError: isNewMessagesError,
    refetch: refetchNewMessages,
  } = useUnreadMessages(interlocutorId);

  const {
    data: userBasicData,
    isFetched: isUserBasicDataFetched,
    isError: isUserBasicDataError,
    refetch: refetchUserBasicData,
  } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.basicData(interlocutorId),
    queryFn: async () =>
      isPhysiotherapist
        ? await getPatientBasicData(interlocutorId)
        : await getPhysiotherapistBasicData(interlocutorId),
  });

  const {
    data: upcomingAppointmentData,
    isLoading: isUpcomingAppointmentDataLoading,
    isError: isUpcomingAppointmentDataError,
    refetch: refetchUpcomingAppointmentData,
  } = useUpcomingAppointment(interlocutorId);

  const refetchQueries = async () =>
    await Promise.all([
      refetchUserBasicData(),
      refetchUpcomingAppointmentData(),
      refetchNewMessages(),
    ]);

  const isAnyLoading = useMemo(
    () => isUpcomingAppointmentDataLoading || isNewMessagesLoading,
    [isNewMessagesLoading, isUpcomingAppointmentDataLoading],
  );
  const isAnyError = useMemo(
    () =>
      isUserBasicDataError ||
      isUpcomingAppointmentDataError ||
      isNewMessagesError,
    [isNewMessagesError, isUpcomingAppointmentDataError, isUserBasicDataError],
  );

  const hideStatus = useMemo(
    () =>
      upcomingAppointmentData?.[0]?.serviceType === "Stationary" &&
      upcomingAppointmentData?.[0]?.paymentType === "Online" &&
      !upcomingAppointmentData?.[0]?.payment.paymentSucceeded,
    [upcomingAppointmentData],
  );

  return (
    <Card
      elevation={elevation as never}
      mode={mode}
      style={[
        mode === "outlined" && containerOutlined,
        !!newMessages && newMessagesBorder,
      ]}
      {...props}>
      <Card.Content>
        {isAnyError ? (
          <FetchError action={refetchQueries} coverScreen={false} />
        ) : !isUserBasicDataFetched || isAnyLoading ? (
          <ActivityIndicator />
        ) : (
          <View style={[cardContentStyle, gapLarge]}>
            {isAssistantId ? (
              <SvgAssistantAvatar />
            ) : (
              <Avatar
                firstName={userBasicData?.firstName}
                userId={userBasicData?.id}
                image={userBasicData?.avatar}
              />
            )}
            <View style={[gapSmall, { flex: 1 }]}>
              <Text variant="titleMedium">{`${userBasicData?.firstName} ${userBasicData?.lastName}`}</Text>
              {!!upcomingAppointmentData?.length && !hideStatus && (
                <StatusComponent
                  status={setStatus(upcomingAppointmentData?.[0])}
                />
              )}
              {newMessages ? (
                <Text variant="titleSmall" style={newMessagesText}>
                  {t("T01169", { value: newMessages })}
                </Text>
              ) : null}
            </View>
          </View>
        )}
      </Card.Content>
    </Card>
  );
};

export default UserChatCard;

const styles = StyleSheet.create({
  containerOutlined: {
    borderColor: theme.colors.outlineVariant,
    backgroundColor: theme.colors.surface,
  },
  cardContentStyle: {
    flexDirection: "row",
    alignItems: "center",
  },
  newMessagesBorder: {
    borderColor: palettes.primary[35],
  },
  newMessagesText: {
    color: palettes.primary[35],
  },
});
