import { spacing16 } from "@styles/spacing";
import React, { FC } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Checkbox, HelperText, Text } from "react-native-paper";

type ProcessingDataAgreementProps = Pick<ControllerProps, "control"> & {
  name: string;
  actionEnabled?: boolean;
};

const ProcessingDataAgreement: FC<ProcessingDataAgreementProps> = ({
  control,
  name,
  actionEnabled,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <View>
            <View style={{ flexDirection: "row" }}>
              <Checkbox.Android
                onPress={() => field.onChange(!field.value)}
                status={field.value || actionEnabled ? "checked" : "unchecked"}
                disabled={actionEnabled}
              />
              <View style={styles.textContainer}>
                <Text variant="labelSmall">{t("T00557")}</Text>
              </View>
            </View>
            {error && <HelperText type="error">{t("T00014")}</HelperText>}
          </View>
        );
      }}
    />
  );
};

export default ProcessingDataAgreement;

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    marginRight: spacing16,
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
  },
});
