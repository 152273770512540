import { useAuth } from "@contexts/AuthContext/auth";
import { useFlags } from "@hooks/useFlags";
import { globalStyles } from "@styles/global";
import { config } from "@utils/config";
import { webAppUri } from "@utils/constants";
import { FC } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import AppleLogin from "./buttons/AppleLogin";
import GoogleLoginWeb from "./buttons/GoogleLoginWeb";
import { WEB_BUTTON_WIDTH } from "./helpers";
import { SocialSectionProps } from "./types";
import FacebookLoginWeb from "./buttons/FacebookLoginWeb";

const getRedirectUri = (slug: string) => `${webAppUri}${slug}`;

export const SocialSection: FC<SocialSectionProps> = ({
  title,
  redirectFrom,
}) => {
  const { appleLogin } = useAuth();
  const {
    flags: { FEATURE_APPLE_LOGIN, FEATURE_FACEBOOK_LOGIN },
  } = useFlags();

  return (
    <View style={[{ alignItems: "center" }, globalStyles.gapLarge]}>
      <Text variant="labelSmall">{title}</Text>
      <View
        style={[
          { width: "100%", alignItems: "center" },
          globalStyles.gapLarge,
        ]}>
        <GoogleLoginWeb />
        {FEATURE_APPLE_LOGIN && (
          <AppleLogin
            onPress={() => appleLogin(getRedirectUri(redirectFrom))}
            label="T01291"
            style={{ width: WEB_BUTTON_WIDTH }}
          />
        )}
        {/* todo: pass callback to facebook */}
        {FEATURE_FACEBOOK_LOGIN && (
          <FacebookLoginWeb
            callback={() => {}}
            appId={config.EXPO_PUBLIC_FACEBOOK_APP_ID}
          />
        )}
      </View>
    </View>
  );
};
