import { FC, useCallback } from "react";
import { FlatList, FlatListProps, ListRenderItem } from "react-native";
import { Hour } from "./PhysioAvailability.types";
import { HourButton } from "@components/buttons";
import { getTime } from "@utils/date";
import { useLocale } from "@hooks/useLocale";

type SingleColumnElementProps = Omit<FlatListProps<Hour>, "renderItem"> & {
  data: Hour[];
  isStationaryService: boolean;
  onHourPress: (dateFrom: string) => void;
};

const HourButtonPlaceholder = () => (
  <HourButton isStationary style={{ opacity: 0 }}>
    00:00
  </HourButton>
);

const SingleColumnSlotsList: FC<SingleColumnElementProps> = ({
  data,
  isStationaryService,
  onHourPress,
  ...rest
}) => {
  const { locale } = useLocale();
  const renderColumn: ListRenderItem<Hour> = useCallback(
    ({ item: { id, dateFrom, appointment }, index }) => {
      return (
        <HourButton
          key={`${id}-${index}`}
          isStationary={isStationaryService}
          onPress={() => onHourPress(dateFrom)}
          disabled={!!appointment}>
          {getTime(dateFrom, locale)}
        </HourButton>
      );
    },
    [isStationaryService, onHourPress],
  );
  return (
    <FlatList
      data={data}
      renderItem={renderColumn}
      scrollEnabled={false}
      ListEmptyComponent={HourButtonPlaceholder}
      {...rest}
    />
  );
};

export default SingleColumnSlotsList;
