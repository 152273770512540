import UpcomingAppointmentCard from "@components/cards/UpcomingAppointmentCard";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { FC } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { PatientCalendarItem as PatientCalendarItemType } from "./PatientCalendar";
import { TrainingTile } from "@components/Tile";
import { TrainingDay } from "@screens/TrainingsAndExercises/training.types";
import { TrainingDayStatus } from "@components/Status/status.types";
import { isToday } from "date-fns";

type Props = {
  item: PatientCalendarItemType;
  navToAvailability?: (initialDate?: Date) => void;
  navToAppointment: (id: number) => void;
  navToTraining: (id: number) => void;
};

export const PatientCalendarItem: FC<Props> = ({
  item,
  navToAppointment,
  navToTraining,
}) => {
  const { t } = useTranslation();

  const getTrainingTileStatus = (training: TrainingDay): TrainingDayStatus => {
    if (training.isCompleted) {
      return TrainingDayStatus.FINISHED;
    } else if (
      new Date(training.executionDate) > new Date() ||
      isToday(training.executionDate)
    ) {
      return TrainingDayStatus.SCHEDULED;
    } else {
      return TrainingDayStatus.NOT_FINISHED;
    }
  };

  return typeof item === "object" ? (
    <View style={[globalStyles.gapMedium, { marginBottom: spacing16 }]}>
      <Text variant="titleMedium">{item.date}</Text>
      {item.appointments.map((e, i) => (
        <UpcomingAppointmentCard
          key={`upcoming-appointment-${e.id}-${i}`}
          upcomingAppointmentData={e}
          onPress={() => navToAppointment(e.id)}
        />
      ))}
      {item.trainings.map((e, i) => (
        <TrainingTile
          key={`training-${e.workout}-${i}`}
          trainingId={e.workout}
          onPress={() => navToTraining(e.workout)}
          mode="outlined"
          status={getTrainingTileStatus(e)}
          showExercises={false}
        />
      ))}
    </View>
  ) : (
    <>
      <View style={{ marginBottom: spacing16 }}>
        <View style={globalStyles.gapMedium}>
          <Text variant="titleMedium">{item}</Text>
          <Text variant="bodyMedium">{t("T00177")}</Text>
        </View>
      </View>
    </>
  );
};
