import { useUserDetails } from "@hooks/user/useUserDetails";
import { useQuery } from "@tanstack/react-query";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import { getPatientBasicData, getPhysiotherapistBasicData } from "@api/users";

export const useTransactionParticipant = (participant: number) => {
  const { isPhysiotherapist } = useUserDetails();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.basicData(participant),
    queryFn: async () =>
      await (isPhysiotherapist
        ? getPatientBasicData(participant)
        : getPhysiotherapistBasicData(participant)),
  });
  return {
    participantFullName: `${data?.firstName} ${data?.lastName}`,
    isError,
    isLoading,
    refetch,
  };
};
