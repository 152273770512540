import { ReactNode, Component, ErrorInfo } from "react";
import * as Sentry from "@sentry/react-native";
import FetchError from "./FetchError";
import { restartApp } from "@utils/restart";
import { theme } from "@styles/theme";

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class CustomErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(capturedError: Error): ErrorBoundaryState {
    console.error({ capturedError });
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.captureException(error, { extra: errorInfo as never });
  }

  render() {
    if (this.state.hasError) {
      return (
        <FetchError
          action={restartApp}
          label="T01369"
          fallbackButtonColor={theme.colors.primary}
        />
      );
    }

    return this.props.children;
  }
}

export default CustomErrorBoundary;
