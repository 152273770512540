import { PrimaryButton } from "@components/buttons";
import { PhysiotherapistCardWithRating, UserCard } from "@components/cards";
import { FetchError } from "@components/errors";
import ExercisesInTrainingList from "@components/Lists/ExercisesInTrainingList";
import InfoTile from "@components/Tile/InfoTile";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, ScrollView, StyleSheet, View } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";

import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";

import { getAssignedTrainingData, getTrainingDays } from "@api/trainings";

import HumanModel from "@components/HumanModel/HumanModel";
import EquipmentsInTrainingList from "@components/Lists/EquipmentsInTrainingList";
import AbsoluteLoader from "@components/Loader/AbsoluteLoader";
import { SystemMessage } from "@components/Report/report.types";
import ReportWrapper from "@components/Report/ReportWrapper";
import ConditionChipsSection from "@components/Training/ConditionChipsSection";
import { useAssistant } from "@hooks/user/useAssistant";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { RootStackParamList } from "@navigators/navigation.types";
import ReportTraining from "@screens/TrainingsAndExercises/TrainingDetailsComponents/ReportTraining";
import { TrainingCalendar } from "@screens/TrainingsAndExercises/TrainingDetailsComponents/TrainingCalendar";
import { getDatesWithEvents } from "@screens/TrainingsAndExercises/TrainingDetailsComponents/utils";
import { Host } from "react-native-portalize";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  queryKeysTraining,
  queryKeysTrainingDay,
} from "./queryKeysTrainingsAndExercises";
import TrainingNotificationsBell from "./TrainingNotificationsBell";
import AssistantCard from "@components/cards/AssistantCard";

const AssignedTrainingDetails: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "AssignedTrainingDetails">
  >
> = ({ route, navigation: { navigate, goBack } }) => {
  const { id } = route.params;
  const gender = route.params?.gender;

  const { t } = useTranslation();
  const { isPhysiotherapist, gender: genderFromUserDetails } = useUserDetails();
  const { loading, gapLarge, gapMedium, gapSmall, scrollContainer } =
    globalStyles;
  const { headerContainer, mainViewStyles } = styles;

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysTraining.detail(id),
    queryFn: async () => await getAssignedTrainingData(id),
  });
  const { isAssistantId } = useAssistant(data?.author);
  const {
    data: trainingDays,
    isLoading: isTrainingDaysLoading,
    isError: isTrainingDaysError,
    refetch: refetchDays,
  } = useQuery({
    queryKey: queryKeysTrainingDay.daysForWorkoutList(id),
    queryFn: async () => await getTrainingDays({ workoutId: id }),
  });

  const refetchQueries = async () =>
    await Promise.all([refetch(), refetchDays()]);

  const handleStartTraining = async () => {
    await refetch();
    if (!isPhysiotherapist) navigate("TrainingPlayer", { id });
  };

  const isAbsoluteLoading = useMemo(
    () => isTrainingDaysLoading,
    [isTrainingDaysLoading],
  );

  if (isLoading) return <ActivityIndicator style={loading} />;

  if (isError || isTrainingDaysError)
    return <FetchError action={refetchQueries} />;

  const {
    author,
    name,
    exercises,
    patient,
    reportDescription,
    description: trainingDescription,
    equipments,
    conditionsTotal,
  } = data;

  return (
    <Host>
      <SafeAreaView edges={["bottom", "left", "right"]}>
        <ScrollView
          contentContainerStyle={[scrollContainer, gapLarge]}
          refreshControl={
            <RefreshControl refreshing={isLoading} onRefresh={refetchQueries} />
          }>
          <View style={mainViewStyles}>
            <View style={[headerContainer, gapLarge]}>
              <View style={{ flex: 1 }}>
                <ReportWrapper
                  systemMessage={
                    !isPhysiotherapist && SystemMessage.REPORT_TRAINING_TITLE
                  }
                  item={id}>
                  <Text
                    variant={isPhysiotherapist ? "titleLarge" : "titleMedium"}>
                    {name}
                  </Text>
                </ReportWrapper>
              </View>
              {!isPhysiotherapist && <TrainingNotificationsBell />}
            </View>
            <Divider bold />
            <TrainingCalendar
              datesWithEvents={getDatesWithEvents(trainingDays)}
              trainingId={id}
            />
            {!isPhysiotherapist && (
              <PrimaryButton label="T00232" onPress={handleStartTraining} />
            )}
            {reportDescription && (
              <ReportWrapper
                systemMessage={
                  isPhysiotherapist && SystemMessage.REPORT_TRAINING_DESCRIPTION
                }
                item={id}>
                <View style={gapMedium}>
                  <Text variant="titleMedium">{`${t("T00369")}:`}</Text>
                  <InfoTile
                    status="warning"
                    content={
                      <Text variant="bodyMedium">{reportDescription}</Text>
                    }
                  />
                </View>
              </ReportWrapper>
            )}
            <Divider bold />
            <View style={gapMedium}>
              {isPhysiotherapist ? (
                <>
                  <Text variant="titleMedium">{t("T01038")}:</Text>
                  <UserCard
                    initialData={{ id: patient }}
                    mode="outlined"
                    flat
                  />
                </>
              ) : (
                <>
                  <Text variant="titleMedium">{t("T01037")}:</Text>
                  {isAssistantId ? (
                    <AssistantCard />
                  ) : (
                    <PhysiotherapistCardWithRating
                      initialData={{ id: author }}
                      onPress={() =>
                        navigate("PhysiotherapistProfile", {
                          id: author,
                        })
                      }
                      flat={false}
                      mode="outlined"
                    />
                  )}
                </>
              )}
              <View style={gapLarge}>
                <ReportWrapper
                  systemMessage={
                    !isPhysiotherapist &&
                    SystemMessage.REPORT_TRAINING_DESCRIPTION
                  }
                  item={id}>
                  <View style={gapSmall}>
                    <Text variant="titleMedium">{t("T00777")}:</Text>
                    <Text variant="bodyMedium">{trainingDescription}</Text>
                  </View>
                </ReportWrapper>
                <Divider bold />
                <ConditionChipsSection
                  title="T01482"
                  conditions={conditionsTotal}
                />
                <Text variant="titleMedium">{t("T00916")}:</Text>
                <HumanModel
                  humanGeneral={data?.humanGeneralTotal}
                  gender={isPhysiotherapist ? gender : genderFromUserDetails}
                  humanBones={data?.humanBonesTotal}
                  humanMuscles={data?.humanMusclesTotal}
                />
              </View>
            </View>
            <ExercisesInTrainingList
              exercises={exercises}
              onExercisePress={(exerciseId: number) =>
                navigate("ExerciseDetails", { id: exerciseId })
              }
            />
            <EquipmentsInTrainingList selectedEquipment={equipments} />
            {!isPhysiotherapist && !isAssistantId && (
              <ReportTraining
                id={id}
                disabled={reportDescription?.length > 0}
                onSuccessCallback={goBack}
              />
            )}
          </View>
          {isAbsoluteLoading && <AbsoluteLoader />}
        </ScrollView>
      </SafeAreaView>
    </Host>
  );
};

const styles = StyleSheet.create({
  mainViewStyles: {
    ...globalStyles.gapLarge,
    paddingTop: spacing16,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default AssignedTrainingDetails;
