import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";
import { globalStyles } from "@styles/global";
import { OnBoardingSlider } from "@components/Slider";
import { PrimaryButton } from "@components/buttons";
import { spacing24 } from "@styles/spacing";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { isWeb } from "@utils/constants";

export const AboutTheApplication: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "AboutTheApplication">
  >
> = ({ navigation: { goBack } }) => {
  const { height } = useWindowDimensions();

  return (
    <View style={globalStyles.container}>
      <OnBoardingSlider
        carouselStyle={{ height: isWeb ? height / 1.25 : height / 1.5 }}
      />
      <PrimaryButton
        label="T00165"
        onPress={goBack}
        style={styles.footerButton}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  footerButton: { width: "100%", marginBottom: spacing24 },
});
