import { PrimaryButton } from "@components/buttons";
import { RootStackParamList } from "@navigators/navigation.types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { globalStyles } from "@styles/global";
import { spacing4, spacing8 } from "@styles/spacing";
import { capitalize } from "lodash";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { TextLayoutEventData, View } from "react-native";
import { Divider, Text } from "react-native-paper";

type SingleInformationSectionProps = {
  title?: string;
  content?: string | string[];
  showDivider?: boolean;
};

const MAX_LINES = 3;

const SingleInformationSection: FC<SingleInformationSectionProps> = ({
  title,
  content,
  showDivider,
}) => {
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const [numberOfLines, setNumberOfLines] = useState(0);
  const [showMoreButton, setShowMoreButton] = useState(false);

  const contentIsLongerThan3 = useMemo(
    () => Array.isArray(content) && content.length > 3,
    [content],
  );

  const onTextLayout = useCallback(
    (event: { nativeEvent: TextLayoutEventData }) => {
      const { lines } = event.nativeEvent;
      if (lines.length > MAX_LINES || contentIsLongerThan3) {
        setShowMoreButton(true);
        setNumberOfLines(MAX_LINES);
      }
    },
    [contentIsLongerThan3],
  );

  useEffect(() => {
    if (showMoreButton) {
      setNumberOfLines(MAX_LINES);
    }
  }, [showMoreButton]);

  return (
    <View style={globalStyles.gapSmall}>
      {title && <Text variant="titleMedium">{title}:</Text>}
      {typeof content === "string" ? (
        <Text
          onTextLayout={onTextLayout}
          variant="bodyMedium"
          numberOfLines={numberOfLines}>
          {content}
        </Text>
      ) : Array.isArray(content) ? (
        content
          .slice(0, 3)
          .map((e, i) => (
            <Text
              numberOfLines={numberOfLines}
              onTextLayout={onTextLayout}
              variant="bodyMedium"
              style={{ marginStart: spacing8 }}
              key={`education-${e}-${i}`}>{`\u2022 ${capitalize(e)}`}</Text>
          ))
      ) : null}
      {showMoreButton && (
        <PrimaryButton
          style={{
            alignSelf: "flex-start",
          }}
          mode="text"
          label="T01064"
          labelStyle={[
            {
              marginVertical: 0,
              marginHorizontal: spacing4,
            },
          ]}
          onPress={() =>
            navigate("PhysiotherapistInformationDetails", {
              content,
              title,
            })
          }
        />
      )}
      {showDivider && <Divider bold />}
    </View>
  );
};

export default SingleInformationSection;
