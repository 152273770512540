import CheckboxSimpleText from "@components/Checkboxes/CheckboxSimpleText";
import { PrimaryButton } from "@components/buttons";
import { PhysiotherapistCardWithRating } from "@components/cards";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrors } from "@hooks/useErrors";
import { postInvitationCode } from "@services/ApiService/common";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useMutation } from "@tanstack/react-query";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { AxiosError } from "axios";
import { useMemo } from "react";
import { Control, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { boolean, object } from "yup";

type Props = {
  physioId: number;
  invitationCode: string;
  hideModal: () => void;
  goBack?: () => void;
  onAlertConfirm?: () => void;
};

export const AddPhysiotherapistContent = ({
  physioId,
  invitationCode,
  hideModal,
  goBack,
  onAlertConfirm,
}: Props) => {
  const { gapMedium, gapLarge } = globalStyles;
  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();

  const { mutate: sendInvitationCode, isLoading } = useMutation({
    mutationFn: postInvitationCode,
    onSuccess: () => {
      hideModal();
      showAlertWithCustomButtons({
        title: t("T01351"),
        message: t("T01352"),
        confirmButton: {
          text: "Ok",
          onPress: onAlertConfirm && onAlertConfirm,
        },
      });
      goBack && goBack();
    },
    onError: ({ response }: AxiosError) =>
      setErrorsFromResponse(response, "❌ Error"),
  });

  const schema = useMemo(
    () =>
      object().shape({
        processingCheckbox: boolean()
          .oneOf([true], t("T00014"))
          .required(t("T00014")),
      }),
    [t],
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = () =>
    sendInvitationCode({ invitationCode, accept: isValid });
  return (
    <View style={[gapLarge, { paddingTop: spacing16 }]}>
      <View style={gapMedium}>
        <PhysiotherapistCardWithRating initialData={{ id: physioId }} />
        <CheckboxSimpleText
          name="processingCheckbox"
          control={control as never as Control}
          text="T01368"
        />
      </View>
      <PrimaryButton
        label="T01177"
        onPress={handleSubmit(onSubmit)}
        style={{ marginTop: "auto" }}
        disabled={isLoading}
        loading={isLoading}
      />
    </View>
  );
};
