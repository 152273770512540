import Svg, { Path } from "react-native-svg";

export const SvgComponent = props => (
  <Svg width={56} height={56} viewBox="0 0 56 56" fill="none" {...props}>
    <Path
      d="M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28z"
      fill="#6C63FF"
    />
    <Path
      d="M25.226 38.681a2.904 2.904 0 01-1.748-.58l-.03-.024-6.581-5.034a2.922 2.922 0 01.316-4.852 2.926 2.926 0 013.242.21l4.262 3.27L34.76 18.53a2.922 2.922 0 014.099-.542l-.062.087.064-.086a2.927 2.927 0 01.54 4.099L27.555 37.537a2.925 2.925 0 01-2.326 1.14l-.003.004z"
      fill="#fff"
    />
  </Svg>
);

export default SvgComponent;
