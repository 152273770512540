import { palettes } from "@styles/colors";
import { spacing24, spacing40, spacing8 } from "@styles/spacing";
import { Image } from "expo-image";
import { FC } from "react";
import { ImageProps, View } from "react-native";
import { IconButton } from "react-native-paper";
import { useMediaStyle } from "@hooks/ui/useMediaStyle";
import { isWeb } from "@utils/constants";

type Props = {
  uri?: string;
  onPress: () => void;
  source?: ImageProps["source"];
};

export const ImagePreview: FC<Props> = ({ uri, onPress, source }) => {
  const { width } = useMediaStyle();

  return (
    <View
      style={{
        width,
        height: width,
        alignItems: "flex-end",
      }}>
      <IconButton
        icon="close"
        onPress={onPress}
        iconColor={palettes.primary[100]}
        size={spacing24}
        style={{
          marginRight: isWeb ? 0 : -spacing8,
          marginTop: isWeb ? spacing40 : 0,
        }}
      />
      <Image
        source={source || uri}
        style={{
          width,
          height: width * 1.5,
        }}
        contentFit="contain"
      />
    </View>
  );
};
