import { globalStyles } from "@styles/global";
import { StyleSheet, View } from "react-native";
import { Divider, Text } from "react-native-paper";

const CategoryName = ({ name }: { name: string | undefined }) => {
  return (
    <View style={globalStyles.gapMedium}>
      <Text variant="titleLarge" style={styles.categoryName}>
        {name}
      </Text>
      {name && <Divider />}
    </View>
  );
};
const styles = StyleSheet.create({
  categoryName: { alignSelf: "center", textAlign: "center" },
});

export default CategoryName;
