import { AppbarTitleWithBackAction } from "@components/Appbar";
import BackgroundWithLottieAnimation from "@components/BackgroundWithLottieAnimation";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import {
  AddPhysiotherapistContent,
  EnterPromotionCodeBottomSheet,
} from "@components/BottomSheetContents";
import { Input } from "@components/Input";
import TextSectionWithTextButton from "@components/Text/TextSectionWithTextButton";
import { PrimaryButton } from "@components/buttons";
import { useUserDetails } from "@hooks/index";
import { useSubscriptionData } from "@hooks/subscriptions/useSubscriptionData";
import {
  ProfileStackParamsList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { spacing48 } from "@styles/spacing";
import { MAX_CODE_LENGTH, isIOS } from "@utils/constants";
import { useInvitationOrPromotionCodeContent } from "@hooks/promoCodes/useInvitationOrPromotionCodeContent";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { presentCodeRedemptionSheetIOS } from "react-native-iap";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { usePromoCodesLogic } from "@hooks/promoCodes/usePromoCodesLogic";

export type ScreenType = "invitationCode" | "promotionCode";

const EnterInvitationOrPromotionCode: FC<
  PropsWithChildren<
    CompositeScreenProps<
      NativeStackScreenProps<
        RootStackParamList,
        "EnterInvitationOrPromotionCode"
      >,
      NativeStackScreenProps<ProfileStackParamsList>
    >
  >
> = ({ navigation: { navigate, setOptions }, route }) => {
  const { screenType } = route.params;
  const { gapLarge, container } = globalStyles;

  const [promotionCodeBottomSheetVisible, setPromotionCodeBottomSheetVisible] =
    useState(false);

  const { isPatient } = useUserDetails();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    isLoading,
    currentInvitationCode,
    physioId,
    isBottomSheetVisible: addPhysiotherapistBottomSheetVisible,
    errors,
    clearErrors,
    setIsBottomSheetVisible: setAddPhysiotherapistBottomSheetVisible,
  } = usePromoCodesLogic();

  useEffect(() => {
    screenType === "promotionCode" &&
      setOptions({
        header: ({ navigation: { goBack } }) => (
          <AppbarTitleWithBackAction title={t("T01325")} onClose={goBack} />
        ),
      });
  }, [screenType, setOptions, t]);

  const { isSubActive } = useSubscriptionData();

  const onPromotionCodeButtonPress = async () => {
    if (!isSubActive) {
      showAlertWithCustomButtons({
        title: t("T01364"),
        message: t("T01365"),
        cancelButton: { text: t("T00540") },
        confirmButton: {
          text: t("T01366"),
          onPress: () =>
            navigate("ProfileCompletionStack", { screen: "Subscription" }),
        },
      });
    } else {
      if (isIOS) {
        await presentCodeRedemptionSheetIOS();
      } else {
        setPromotionCodeBottomSheetVisible(true);
      }
    }
  };

  const {
    animation,
    initialText,
    firstText,
    secondText,
    extraHelperText,
    buttonLabel,
  } = useInvitationOrPromotionCodeContent(screenType, isPatient);

  return (
    <KeyboardAwareScrollView>
      <View style={{ gap: spacing48 }}>
        <BackgroundWithLottieAnimation animation={animation} />
        <View style={[container, gapLarge]}>
          <TextSectionWithTextButton
            initialText={initialText}
            firstText={firstText}
            secondText={secondText}
            textButtonLabel="T01434"
            onTextButtonPress={() => navigate("InviteToTheApp")}
          />
          {screenType === "invitationCode" && (
            <Input
              key="invitationCode"
              name="invitationCode"
              label="T01367"
              placeholder="A00000"
              control={control as unknown as Control}
              extraHelperText={extraHelperText}
              errors={errors?.invitation_code}
              autoCapitalize="sentences"
              onChange={clearErrors}
              maxLength={MAX_CODE_LENGTH}
            />
          )}
          <PrimaryButton
            label={buttonLabel}
            onPress={
              screenType === "invitationCode"
                ? handleSubmit
                : onPromotionCodeButtonPress
            }
            loading={isLoading}
            disabled={isLoading}
          />
        </View>
      </View>
      <BottomModalContainer
        modalVisible={addPhysiotherapistBottomSheetVisible}
        setModalVisible={setAddPhysiotherapistBottomSheetVisible}>
        <AddPhysiotherapistContent
          physioId={physioId}
          invitationCode={currentInvitationCode}
          hideModal={() => setAddPhysiotherapistBottomSheetVisible(false)}
        />
      </BottomModalContainer>
      <BottomModalContainer
        modalVisible={promotionCodeBottomSheetVisible}
        setModalVisible={setPromotionCodeBottomSheetVisible}>
        <EnterPromotionCodeBottomSheet
          hideModal={() => setPromotionCodeBottomSheetVisible(false)}
        />
      </BottomModalContainer>
    </KeyboardAwareScrollView>
  );
};

export default EnterInvitationOrPromotionCode;
