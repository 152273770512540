import { palettes } from "@styles/colors";
import { FC } from "react";
import { TouchableOpacity } from "react-native";
import { List, ListItemProps } from "react-native-paper";

type DocumentListItemProps = ListItemProps & {
  onRemove?: () => void;
  isVerified?: boolean;
  actionEnabled?: boolean;
  removeEnabled?: boolean;
};

const DocumentListItem: FC<DocumentListItemProps> = ({
  onRemove,
  isVerified = true,
  actionEnabled = true,
  style,
  removeEnabled,
  ...rest
}) => {
  const canBeRemoved = (!isVerified && !actionEnabled) || removeEnabled;
  return (
    <List.Item
      titleNumberOfLines={2}
      right={props =>
        onRemove && canBeRemoved ? (
          <TouchableOpacity
            onPress={onRemove}
            style={{ flexDirection: "row", alignItems: "center" }}>
            <List.Icon icon="close" {...props} />
          </TouchableOpacity>
        ) : (
          <List.Icon icon="eye" {...props} />
        )
      }
      style={[{ backgroundColor: palettes.primary["99"] }, style]}
      {...rest}
    />
  );
};

export default DocumentListItem;
