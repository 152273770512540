import { FC, useEffect, useState } from "react";
import DropdownPicker, {
  MultiDropdownsType,
  PickerItem,
} from "@components/DropdownPicker/DropdownPicker";
import { useQuery } from "@tanstack/react-query";
import { ActivityIndicator } from "react-native-paper";
import { FetchError } from "@components/errors";
import { profileOptions } from "@api/profile";
import { useAuth } from "@contexts/AuthContext/auth";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import { capitalize } from "lodash";

type Props = {
  onSelectItem: (item: PickerItem) => void;
  defaultValue?: string;
} & MultiDropdownsType;

const AppLanguageDropdownPicker: FC<Props> = props => {
  const [items, setItems] = useState<PickerItem[]>([]);
  const {
    user: { id, language },
  } = useAuth();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [queryKeysForPatientAndPhysiotherapist.appLanguages()],
    queryFn: async () => await profileOptions(id),
  });

  useEffect(() => {
    if (!isError && !isLoading) {
      const items: PickerItem[] = data.data.actions.put.language.choices.map(
        ({ value, displayName }) => ({
          label: capitalize(displayName),
          value: value.toString(),
        }),
      );
      setItems(items);
    }
  }, [data, isError, isLoading]);

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} />;

  return (
    items?.length > 0 && (
      <DropdownPicker
        dropdownLabelText="T00696"
        items={items}
        defaultValue={props.defaultValue || language}
        containerZIndex={200}
        {...props}
      />
    )
  );
};

export default AppLanguageDropdownPicker;
