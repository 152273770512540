import {
  DefaultTheme,
  NavigationContainer,
  useNavigationContainerRef,
} from "@react-navigation/native";
import { FC, useContext, useRef } from "react";
import { ActivityIndicator, adaptNavigationTheme } from "react-native-paper";
import { theme } from "../styles/theme";
import MainStackNavigator from "./MainStackNavigator";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { globalStyles } from "@styles/global";
import { isWeb, linkingConfiguration } from "@utils/constants";
import { AppointmentSessionContext } from "@contexts/AppointmentSessionContext/appointmentSession.context";
import AppointmentSession from "@screens/Appointments/AppointmentSession";
import { StyleProp, View, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { spacing16 } from "@styles/spacing";
import FlashMessage from "react-native-flash-message";
import { useTranslation } from "react-i18next";
import { useLogUserScreenEvent } from "@hooks/index";

const { LightTheme } = adaptNavigationTheme({
  reactNavigationLight: DefaultTheme,
  materialLight: theme,
});

const NavigationWrapper: FC = () => {
  const {
    data: {
      containerSizes: { width, height },
      ...data
    },
  } = useContext(AppointmentSessionContext);
  const { top } = useSafeAreaInsets();
  const { t } = useTranslation();
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef<string>();

  const { logUserScreenEvent } = useLogUserScreenEvent({
    navigationRef,
    routeNameRef,
  });

  const sessionContainerStyle: StyleProp<ViewStyle> = {
    position: "absolute",
    width: width > 0 ? width : "100%",
    height: height > 0 ? height : "100%",
    zIndex: 1000,
  };

  Object.assign(
    sessionContainerStyle,
    data?.displayInFloatingView
      ? { marginLeft: spacing16, marginTop: 3 * top - spacing16 }
      : { top: 0 },
  );

  return (
    <NavigationContainer
      documentTitle={
        isWeb && {
          formatter: () => t("T01358"),
        }
      }
      linking={linkingConfiguration}
      fallback={<ActivityIndicator style={globalStyles.loading} />}
      theme={LightTheme}
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute().name;
      }}
      onStateChange={logUserScreenEvent}>
      <GestureHandlerRootView style={{ flex: 1 }}>
        {data?.sessionEnabled && (
          <View pointerEvents="box-none" style={sessionContainerStyle}>
            <AppointmentSession />
          </View>
        )}
        <MainStackNavigator />
        <FlashMessage position={isWeb ? "top" : "bottom"} floating />
      </GestureHandlerRootView>
    </NavigationContainer>
  );
};

export default NavigationWrapper;
