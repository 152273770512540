import { AnalyticsEvent } from "@globalTypes/analytics.types";
import { config } from "@utils/config";
import { isWeb } from "@utils/constants";

export const useAnalytics = () => {
  const handleLoginAnalytics = async (userID: string | null) => {
    if (config?.EXPO_PUBLIC_ENABLE_ANALYTICS === "true") {
      try {
        if (isWeb) {
          const { getAnalytics, setUserId, setUserProperties } =
            require("firebase/analytics") as typeof import("firebase/analytics");
          const analytics = getAnalytics();
          setUserId(analytics, userID);
          setUserProperties(analytics, { client_id: userID });
        } else {
          const analyticsModule =
            require("@react-native-firebase/analytics") as typeof import("@react-native-firebase/analytics");
          const analytics = analyticsModule.default();
          await analytics.setUserId(userID);
        }
      } catch (error) {
        console.error("Analytics login initalization:", error);
      }
    }
  };

  const handleLogEvent = async ({ eventName, eventParams }: AnalyticsEvent) => {
    if (config?.EXPO_PUBLIC_ENABLE_ANALYTICS === "true") {
      try {
        if (isWeb) {
          const { getAnalytics, logEvent } =
            require("firebase/analytics") as typeof import("firebase/analytics");
          const analytics = getAnalytics();
          logEvent(analytics, eventName, eventParams);
        } else {
          const analyticsModule =
            require("@react-native-firebase/analytics") as typeof import("@react-native-firebase/analytics");
          const analytics = analyticsModule.default();
          await analytics.logEvent(eventName, eventParams);
        }
      } catch (error) {
        console.error("Analytics log event Error:", error);
      }
    }
  };

  return {
    handleLoginAnalytics,
    handleLogEvent,
  };
};
