import { ImageSourcePropType, Pressable, View } from "react-native";
import { Text } from "react-native-paper";
import { FC } from "react";
import { ReportProps } from "@components/Report/report.types";
import { globalStyles } from "@styles/global";
import { Image } from "expo-image";
import { useMediaStyle } from "@hooks/ui/useMediaStyle";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { useTranslation } from "react-i18next";
import ReportWrapper from "@components/Report/ReportWrapper";

type ExercisePhotoType = ReportProps & {
  source: ImageSourcePropType;
  onPress: () => void;
  isAssistantExercise: boolean;
};

const ExercisePhoto: FC<ExercisePhotoType> = ({
  source,
  onPress,
  isAssistantExercise,
  ...props
}) => {
  const mediaStyle = useMediaStyle();
  const { t } = useTranslation();
  const { isPatient } = useUserDetails();
  return isPatient && !isAssistantExercise ? (
    <ReportWrapper {...props} onPress={onPress}>
      <View style={globalStyles.gapMedium}>
        <Text variant="titleMedium">{`${t("T00772")}:`}</Text>
        <Image
          source={source}
          style={{ width: mediaStyle.width, height: mediaStyle.width }}
        />
      </View>
    </ReportWrapper>
  ) : (
    <View style={globalStyles.gapMedium}>
      <Text variant="titleMedium">{`${t("T00772")}:`}</Text>
      <Pressable onPress={onPress}>
        <Image
          source={source}
          style={{ width: mediaStyle.width, height: mediaStyle.width }}
        />
      </Pressable>
    </View>
  );
};

export default ExercisePhoto;
