import { FC, useEffect, useRef } from "react";
import { Animated, StyleSheet, View } from "react-native";

import { DataType } from "@frontendData/physio_slider";
import { spacing12, spacing24, spacing8 } from "@styles/spacing";
import { palettes } from "@styles/colors";

type Props = {
  data: DataType[];
  currentIndex: number;
};

export const SliderDots: FC<Props> = ({ data, currentIndex }) => {
  const animation = useRef(new Animated.Value(0)).current;
  const { dotsContainer, dot, animatedDot } = styles;

  useEffect(() => {
    Animated.spring(animation, {
      toValue: currentIndex,
      useNativeDriver: true,
      tension: 60,
    }).start();
  }, [currentIndex, animation]);

  const itemWidth = spacing12 + spacing8 * 2;

  const animatedStyle = animation.interpolate({
    inputRange: [0, data.length],
    outputRange: [0, itemWidth * data.length],
  });

  return (
    <View style={dotsContainer}>
      {data.map((e, i) => (
        <View key={`dot-item-${i}`} style={[dot]} />
      ))}
      <Animated.View
        style={[
          animatedDot,
          {
            transform: [{ translateX: animatedStyle }],
          },
        ]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  dotsContainer: {
    flexDirection: "row",
    marginBottom: spacing24,
  },
  dot: {
    width: spacing12,
    height: spacing12,
    borderColor: palettes.primary[60],
    borderWidth: 2,
    borderRadius: 100,
    marginHorizontal: spacing8,
  },
  animatedDot: {
    width: spacing12,
    height: spacing12,
    backgroundColor: palettes.primary[60],
    borderRadius: spacing12,
    position: "absolute",
    marginLeft: spacing8,
  },
});
