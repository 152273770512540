import {
  createMaterialTopTabNavigator,
  MaterialTopTabScreenProps,
} from "@react-navigation/material-top-tabs";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { TrainingsAndExercisesStackParamList } from "@navigators/navigation.types";
import { theme } from "@styles/theme";
import { TrainingsAndExercisesTabsParamList } from "../tabsParamsList.types";
import Exercises from "./Exercises";
import Trainings from "./Trainings";
import { fontConfig } from "@styles/fontConfig";

const Tab = createMaterialTopTabNavigator<TrainingsAndExercisesTabsParamList>();

const TrainingsAndExercises: FC<
  PropsWithChildren<
    MaterialTopTabScreenProps<
      TrainingsAndExercisesStackParamList,
      "TrainingsAndExercises"
    >
  >
> = () => {
  const { t } = useTranslation();
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: {
          backgroundColor: theme.colors.surface,
        },
        tabBarLabelStyle: { textTransform: "none", ...fontConfig.titleSmall },
      }}>
      <Tab.Screen
        name="Trainings"
        component={Trainings}
        options={{ title: t("T00167") }}
      />
      <Tab.Screen
        name="Exercises"
        component={Exercises}
        options={{ title: t("T00169") }}
      />
    </Tab.Navigator>
  );
};

export default TrainingsAndExercises;
