import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { ThumbSlider } from "@components/Slider";
import { useEffect, useState } from "react";
import { globalStyles } from "@styles/global";
import { palettes } from "@styles/colors";
import { spacing12, spacing16 } from "@styles/spacing";
import { useTranslation } from "react-i18next";

export type PainTileProps = {
  disableSliding?: boolean;
  initialValue?: number;
  onSlidingStart?: () => void;
  onSlidingComplete?: () => void;
  onChange?: (value: number) => void;
};

export const PainTile = ({
  disableSliding = false,
  initialValue = 0,
  onSlidingComplete,
  onSlidingStart,
  onChange,
}: PainTileProps) => {
  const [currentValue, setCurrentValue] = useState(initialValue);

  useEffect(() => {
    setCurrentValue(initialValue);
  }, [initialValue]);

  const { t } = useTranslation();

  const { gapLarge, gapMedium } = globalStyles;

  const valueArray = Array.from({ length: 11 }).map((e, i) => {
    switch (i) {
      case 0:
        return {
          title: t("T00965"),
          description: t("T00966"),
        };
      case 1:
      case 2:
      case 3:
        return {
          title: t("T00967"),
          description: t("T00969"),
        };
      case 4:
      case 5:
      case 6:
        return {
          title: t("T00975"),
          description: t("T00970"),
        };
      case 7:
      case 8:
      case 9:
        return {
          title: t("T00971"),
          description: t("T00972"),
        };
      default:
        return {
          title: t("T00973"),
          description: t("T00974"),
        };
    }
  });

  useEffect(() => {
    onChange && onChange(currentValue);
  }, [currentValue, onChange]);

  return (
    <View style={[gapLarge, styles.container]}>
      <Text variant="titleMedium">{t("T00568")}</Text>
      <ThumbSlider
        setCurrentValue={setCurrentValue}
        disableSliding={disableSliding}
        initialValue={initialValue}
        onSlidingComplete={onSlidingComplete}
        onSlidingStart={onSlidingStart}
      />
      <View style={gapMedium}>
        <Text variant="titleMedium">{`${currentValue} - ${valueArray[currentValue].title}`}</Text>
        <Text variant="bodyMedium">{valueArray[currentValue].description}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: palettes.neutralVariant[99],
    padding: spacing16,
    borderRadius: spacing12,
  },
});
