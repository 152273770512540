import DropdownPicker from "@components/DropdownPicker/DropdownPicker";
import { useQuery } from "@tanstack/react-query";
import { ActivityIndicator, Text } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { FetchError } from "@components/errors";
import { getAvailableCountries } from "@services/ApiService/common";
import { queryKeysCountries } from "../queryKeysFilters";
import { Dispatch, FC, SetStateAction } from "react";
import { View } from "react-native";
import { useTranslation } from "react-i18next";

type Props = {
  setSelectedCountry: Dispatch<SetStateAction<string | null>>;
  selectedCountry: string;
};

export const FiltersCountrySection: FC<Props> = ({
  setSelectedCountry,
  selectedCountry,
}) => {
  const { t } = useTranslation();

  const { loading, gapLarge } = globalStyles;

  const {
    data: countries,
    isLoading: countriesLoading,
    isError: countriesError,
    refetch: refetchCountries,
  } = useQuery({
    queryKey: queryKeysCountries.list(),
    queryFn: getAvailableCountries,
  });

  if (countriesLoading) return <ActivityIndicator style={loading} />;
  if (countriesError)
    return <FetchError action={refetchCountries} coverScreen={false} />;

  return (
    <View style={gapLarge}>
      <Text variant="titleMedium">{`${t("T00463")}:`}</Text>
      <DropdownPicker
        dropdownLabelText="T00618"
        items={countries.map(({ displayName, value }) => ({
          label: displayName,
          value: value,
        }))}
        onSelectItem={({ value }) => setSelectedCountry(value)}
        defaultValue={selectedCountry}
        dropDownDirection="TOP"
        placeholder="T00933"
      />
    </View>
  );
};
