import {
  Children,
  cloneElement,
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import SliderWeb, {
  SliderProps as SliderWebProps,
} from "@react-native-community/slider";
import { Slider, SliderProps } from "@miblanchard/react-native-slider";
import { StyleSheet, View } from "react-native";
import { palettes } from "@styles/colors";
import { spacing2, spacing4, spacing8 } from "@styles/spacing";
import { impactAsync, ImpactFeedbackStyle } from "expo-haptics";
import { isWeb } from "@utils/constants";

type ContainerPropsType = {
  children: ReactElement;
  currentValue: number;
  trackMarks?: number[];
  vertical?: boolean;
};

type SliderComponentPropsType = {
  setCurrentValue?: Dispatch<SetStateAction<number>>;
  disableSliding?: boolean;
  initialValue?: number;
  onSlidingStart?: () => void;
  onSlidingComplete?: () => void;
};

const SliderContainer = ({
  currentValue,
  trackMarks,
  children,
}: ContainerPropsType) => {
  const renderTrackMarkComponent = (index: number) => {
    return (
      <View
        style={[
          styles.dotStyle,
          {
            backgroundColor:
              index <= currentValue && currentValue !== 0
                ? palettes.primary[100]
                : palettes.neutralVariant[30],
            opacity: index <= currentValue && currentValue !== 0 ? 0.5 : 1,
          },
        ]}
      />
    );
  };

  return Children.map(children, (child: ReactElement) => {
    if (child?.type === Slider) {
      return cloneElement(child, {
        renderTrackMarkComponent,
        trackMarks,
        value: currentValue,
      });
    }

    return child;
  });
};

export const ThumbSlider = ({
  setCurrentValue: propsSetCurrentValue,
  disableSliding,
  initialValue = 0,
  onSlidingComplete,
  onSlidingStart,
}: SliderComponentPropsType) => {
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [currentTintColor, setCurrentTintColor] = useState(
    palettes.primary[40],
  );

  useEffect(() => {
    setCurrentValue(initialValue);
  }, [initialValue]);

  const getRange = (value: number) => {
    if (value >= 0 && value <= 3) return "low";
    if (value >= 4 && value <= 6) return "medium";
    if (value >= 7 && value <= 10) return "high";
    return "outOfRange";
  };

  useEffect(() => {
    switch (getRange(currentValue)) {
      case "low":
        setCurrentTintColor(palettes.primary[40]);
        break;
      case "medium":
        setCurrentTintColor(palettes.warning[91]);
        break;
      case "high":
        setCurrentTintColor(palettes.error[40]);
        break;
      default:
        break;
    }
  }, [currentValue]);

  const playHaptic = async (value: number) => {
    if (isWeb) {
      return null;
    }

    let hapticTemplate = ImpactFeedbackStyle.Light;

    switch (getRange(value)) {
      case "low":
        hapticTemplate = ImpactFeedbackStyle.Light;
        break;
      case "medium":
        hapticTemplate = ImpactFeedbackStyle.Medium;
        break;
      case "high":
        hapticTemplate = ImpactFeedbackStyle.Heavy;
        break;
      default:
        break;
    }

    return await impactAsync(hapticTemplate);
  };

  useLayoutEffect(() => {
    if (!isWeb) return;
    setTimeout(() => {
      const event = new Event("resize");
      window.dispatchEvent(event);
    }, 1);
  }, []);

  const sliderProps: Pick<
    SliderProps & SliderWebProps,
    | "maximumValue"
    | "minimumValue"
    | "step"
    | "thumbTintColor"
    | "minimumTrackTintColor"
    | "maximumTrackTintColor"
    | "disabled"
    | "onSlidingComplete"
    | "onSlidingStart"
  > = {
    maximumValue: 10,
    minimumValue: 0,
    step: 1,
    thumbTintColor: currentTintColor,
    minimumTrackTintColor:
      initialValue === 0 ? palettes.neutralVariant[90] : currentTintColor,
    maximumTrackTintColor:
      initialValue === 10 ? currentTintColor : palettes.neutralVariant[90],
    disabled: disableSliding,
    onSlidingComplete,
    onSlidingStart,
  };

  const onValueChange = (value: number) => {
    setCurrentValue(prevState => {
      if (prevState !== value) {
        void playHaptic(value);
        return value;
      }
      return prevState;
    });
    propsSetCurrentValue && propsSetCurrentValue(value);
  };

  return (
    <SliderContainer
      currentValue={currentValue}
      trackMarks={Array.from({ length: 11 }, (_, i) => i)}>
      {isWeb ? (
        <SliderWeb
          onValueChange={value => onValueChange(value)}
          {...sliderProps}
        />
      ) : (
        <Slider
          trackStyle={{ height: spacing4 }}
          thumbStyle={disableSliding ? { height: 0 } : styles.thumbStyle}
          onValueChange={([value]) => onValueChange(value)}
          {...sliderProps}
        />
      )}
    </SliderContainer>
  );
};

const styles = StyleSheet.create({
  thumbStyle: {
    shadowColor: palettes.primary[0],
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  dotStyle: {
    width: spacing2,
    height: spacing2,
    borderRadius: spacing2,
    left: spacing8,
  },
});
