import { useAuth } from "@contexts/AuthContext/auth";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { palettes } from "@styles/colors";
import { spacing16, spacing20 } from "@styles/spacing";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  SafeAreaView,
  StatusBar,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { IconButton, Text } from "react-native-paper";
import { UseZoomUi } from "zoom/utils/types";
import { Icon } from "../../components/icon";
import { VideoView } from "../../components/video-view";
import { isWeb } from "@utils/constants";
import { AppointmentSessionContext } from "@contexts/AppointmentSessionContext/appointmentSession.context";
import OnlyPersonInfoText from "../../components/OnlyPersonInfoText";

export const useZoomUi = ({
  users,
  isInSession,
  isMuted,
  fullScreenUser,
  interlocutorMuted,
  onPressAudio,
  onPressLeave,
  onPressMore,
  onPressVideo,
  isVideoOn,
  videoRef,
  selfVideoCanvasRef,
}: UseZoomUi) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { user } = useAuth();
  const { data } = useContext(AppointmentSessionContext);

  const contentStyles: StyleProp<ViewStyle> = useMemo(
    () => ({
      ...styles.container,
      height: "100%",
    }),
    [],
  );

  const zoomFloatingViewSizes = useMemo(
    () => ({
      width: width / 3.5,
      height: width / 3.5,
    }),
    [width],
  );

  const showZoomUi = false;

  const renderMutedMic = useCallback(
    () => (
      <IconButton
        icon="microphone-off"
        size={spacing20}
        style={styles.mutedMic}
        iconColor={palettes.primary[100]}
      />
    ),
    [],
  );

  const renderMyCameraView = useCallback(() => {
    if (isWeb) return null;

    if (isInSession) {
      return (
        <>
          <VideoView
            user={users.find(({ apiUserId }) => apiUserId === user.id)}
            key="peo0"
            onPress={() => {}}
            floatingContainerStyle={styles.myCameraViewStyle}
            avatarStyle={zoomFloatingViewSizes}
            {...{
              users,
              isInSession,
              isMuted,
              fullScreenUser,
              interlocutorMuted,
              onPressAudio,
              onPressLeave,
              onPressMore,
              onPressVideo,
              isVideoOn,
              videoRef,
              selfVideoCanvasRef,
            }}
          />
          {isMuted && renderMutedMic()}
        </>
      );
    }
    return (
      <View style={styles.fullSize}>
        <Icon style={styles.fullSize} name="defaultAvatar" />
      </View>
    );
  }, [
    fullScreenUser,
    interlocutorMuted,
    isInSession,
    isMuted,
    isVideoOn,
    onPressAudio,
    onPressLeave,
    onPressMore,
    onPressVideo,
    renderMutedMic,
    selfVideoCanvasRef,
    user.id,
    users,
    videoRef,
    zoomFloatingViewSizes,
  ]);
  const renderInterlocutorCameraView = useCallback(() => {
    if (isWeb) return null;
    if (isInSession && fullScreenUser && users.length > 1) {
      return (
        <>
          <VideoView
            user={users.find(({ apiUserId }) => apiUserId !== user.id)}
            onPress={() => {}}
            sharing={false}
            preview={false}
            hasMultiCamera={false}
            multiCameraIndex="0"
            fullScreen
            floatingContainerStyle={styles.interlocutorCameraViewStyle}
            avatarStyle={zoomFloatingViewSizes}
            {...{
              users,
              isInSession,
              isMuted,
              fullScreenUser,
              interlocutorMuted,
              onPressAudio,
              onPressLeave,
              onPressMore,
              onPressVideo,
              isVideoOn,
              videoRef,
              selfVideoCanvasRef,
            }}
          />
          {interlocutorMuted && renderMutedMic()}
        </>
      );
    }

    return <OnlyPersonInfoText />;
  }, [
    isInSession,
    fullScreenUser,
    users,
    zoomFloatingViewSizes,
    isMuted,
    interlocutorMuted,
    onPressAudio,
    onPressLeave,
    onPressMore,
    onPressVideo,
    isVideoOn,
    videoRef,
    selfVideoCanvasRef,
    renderMutedMic,
    user.id,
  ]);

  const context = useMemo(
    () => (
      <View style={contentStyles}>
        <StatusBar hidden />
        <VideoView
          user={fullScreenUser}
          onPress={() => {}}
          sharing={false}
          preview={false}
          hasMultiCamera={false}
          multiCameraIndex="0"
          fullScreen
          floatingContainerStyle={styles.interlocutorCameraViewStyle}
          avatarStyle={{ width: width / 2, height: width / 2 }}
          {...{
            users,
            isInSession,
            isMuted,
            fullScreenUser,
            interlocutorMuted,
            onPressAudio,
            onPressLeave,
            onPressMore,
            onPressVideo,
            isVideoOn,
            videoRef,
            selfVideoCanvasRef,
          }}
        />
        <SafeAreaView style={styles.safeArea} pointerEvents="box-none">
          <View style={[styles.contents]} pointerEvents="box-none">
            <View style={styles.topWrapper} pointerEvents="box-none">
              {!data.displayInFloatingView &&
                isInSession &&
                users.length < 2 && (
                  <View style={{ maxWidth: "50%" }}>
                    <View style={styles.sessionInfo}>
                      <Text style={styles.sessionName}>{t("T01051")}</Text>
                    </View>
                  </View>
                )}

              {showZoomUi && (
                <View style={styles.topRightWrapper}>
                  <TouchableOpacity
                    style={styles.leaveButton}
                    onPress={() => onPressLeave()}>
                    <Text style={styles.leaveText}>LEAVE</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>

            {showZoomUi && (
              <View style={styles.middleWrapper} pointerEvents="box-none">
                <View style={styles.controls} />
                <View style={styles.controls}>
                  <Icon
                    containerStyle={styles.controlButton}
                    name={isMuted ? "unmute" : "mute"}
                    onPress={onPressAudio}
                  />
                  <Icon
                    containerStyle={styles.controlButton}
                    name={isVideoOn ? "videoOff" : "videoOn"}
                    onPress={onPressVideo}
                  />
                  <Icon
                    containerStyle={styles.controlButton}
                    name="more"
                    onPress={onPressMore}
                  />
                </View>
              </View>
            )}
          </View>

          {!isWeb && (
            <View style={styles.bottomWrapper} pointerEvents="box-none">
              {isInSession && fullScreenUser && users.length > 1 && (
                <VideoView
                  user={users.find(
                    item => item.userId !== fullScreenUser?.userId,
                  )}
                  key="peo0"
                  onPress={() => {}}
                  {...{
                    users,
                    isInSession,
                    isMuted,
                    fullScreenUser,
                    interlocutorMuted,
                    onPressAudio,
                    onPressLeave,
                    onPressMore,
                    onPressVideo,
                    isVideoOn,
                    videoRef,
                    selfVideoCanvasRef,
                  }}
                />
              )}
            </View>
          )}

          {!isInSession && (
            <View style={styles.connectingWrapper}>
              <Text style={styles.connectingText}>{t("T00898")}</Text>
            </View>
          )}
        </SafeAreaView>
      </View>
    ),
    [
      contentStyles,
      fullScreenUser,
      width,
      users,
      isInSession,
      isMuted,
      interlocutorMuted,
      onPressAudio,
      onPressLeave,
      onPressMore,
      onPressVideo,
      isVideoOn,
      videoRef,
      selfVideoCanvasRef,
      data?.displayInFloatingView,
      t,
      showZoomUi,
    ],
  );

  return { context, renderMyCameraView, renderInterlocutorCameraView };
};

const styles = StyleSheet.create({
  fullSize: { width: "100%", height: "100%" },
  mutedMic: { position: "absolute", top: 0, right: 0 },
  myCameraViewStyle: {
    width: "100%",
    height: "100%",
    borderTopStartRadius: spacing16,
    borderBottomStartRadius: spacing16,
    overflow: "hidden",
  },
  interlocutorCameraViewStyle: {
    width: "100%",
    height: "100%",
    borderTopEndRadius: spacing16,
    borderBottomEndRadius: spacing16,
    overflow: "hidden",
  },
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#232323",
  },
  fullScreenVideo: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  connectingWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  connectingText: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#FFF",
  },
  safeArea: {
    flex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  contents: {
    flex: 1,
    alignItems: "stretch",
  },
  sessionInfo: {
    padding: 8,
    borderRadius: 8,
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  sessionName: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFF",
  },
  numberOfUsers: {
    fontSize: 13,
    color: "#FFF",
  },
  topWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: 8,
    paddingTop: 16,
  },
  topRightWrapper: {
    paddingTop: 8,
    alignItems: "flex-end",
  },
  middleWrapper: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 8,
  },
  bottomWrapper: {
    paddingHorizontal: 8,
    paddingBottom: 55 + 16,
  },
  leaveButton: {
    paddingVertical: 4,
    paddingHorizontal: 24,
    marginBottom: 16,
    borderRadius: 24,
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  leaveText: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#E02828",
  },
  videoInfo: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 8,
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  videoInfoText: {
    fontSize: 12,
    color: "#FFF",
  },
  chatList: {
    paddingRight: 16,
  },
  chatMessage: {
    flexDirection: "row",
    alignSelf: "flex-start",
    padding: 8,
    marginBottom: 8,
    borderWidth: 2,
    borderRadius: 8,
    borderColor: "rgba(255,255,255,0.5)",
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  chatUser: {
    fontSize: 14,
    color: "#CCC",
  },
  chatContent: {
    fontSize: 14,
    color: "#FFF",
  },
  controls: {
    alignSelf: "center",
  },
  controlButton: {
    marginBottom: 12,
  },
  deleteButton: {
    fontSize: 10,
    paddingLeft: 4,
  },
  deleteText: {
    color: "#FFF",
  },
  userList: {
    width: "100%",
  },
  userListContentContainer: {
    flexGrow: 1,
    justifyContent: "center",
  },
  chatInputWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chatInput: {
    flex: 1,
    height: 40,
    marginVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#666",
    color: "#AAA",
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  chatSendButton: {
    height: 36,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modal: {
    paddingTop: 16,
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 16,
    borderRadius: 8,
    backgroundColor: "#FFF",
  },
  modalTitleText: {
    fontSize: 18,
    marginBottom: 8,
  },
  modalActionContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  modalAction: {
    marginTop: 16,
    paddingHorizontal: 24,
  },
  modalActionText: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#666",
  },
  moreItem: {
    flexDirection: "row",
    marginTop: 16,
    justifyContent: "space-between",
    alignItems: "center",
  },
  moreItemText: {
    fontSize: 16,
  },
  moreItemIcon: {
    width: 36,
    height: 36,
    marginLeft: 48,
  },
  moreModalTitle: {
    fontSize: 24,
  },
  renameInput: {
    width: 200,
    marginTop: 16,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: "#AAA",
    color: "#000",
  },
  keyboardArea: {
    height: 0,
    width: 0,
    zIndex: -100,
  },
});
