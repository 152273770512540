import ImageForTile from "@components/Image/ImageForTile";
import { globalStyles } from "@styles/global";
import { theme } from "@styles/theme";
import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Card, Text } from "react-native-paper";
import { DefaultTileProps } from "../types/Tile.types";

type ExerciseBaseTileProps = DefaultTileProps & {
  title: string;
  subtitles?: string[];
  right?: () => JSX.Element;
  image: string;
  displayExtraIcon?: boolean;
};

const ExerciseBaseTile: FC<ExerciseBaseTileProps> = ({
  mode = "outlined",
  elevation,
  title,
  subtitles,
  right,
  image,
  displayExtraIcon,
  style,
  ...props
}) => {
  const {
    borderWidth,
    borderColor,
    elevatedBackground,
    itemWithSubtitlesContainer,
  } = styles;

  return (
    <Card
      elevation={elevation as never}
      mode={mode}
      style={[
        (mode === "contained" || "elevated") && borderWidth,
        borderColor,
        mode === "elevated" && elevatedBackground,
        style,
      ]}
      {...props}>
      <Card.Content>
        <View style={itemWithSubtitlesContainer}>
          <ImageForTile image={image} displayExtraIcon={displayExtraIcon} />
          <View style={[globalStyles.gapSmall, { flex: 1 }]}>
            <Text variant="titleMedium">{title}</Text>
            {subtitles?.map((e, i) => (
              <Text key={`subtitle-${i}-${e}`} variant="bodyMedium">
                {e}
              </Text>
            ))}
          </View>
          {right?.()}
        </View>
      </Card.Content>
    </Card>
  );
};

export default ExerciseBaseTile;

const styles = StyleSheet.create({
  borderColor: {
    borderColor: theme.colors.outlineVariant,
  },
  borderWidth: {
    borderWidth: 0.5,
  },
  elevatedBackground: {
    backgroundColor: theme.colors.elevatedCardBackground,
  },
  itemWithSubtitlesContainer: {
    flexDirection: "row",
    alignItems: "center",
    ...globalStyles.gapLarge,
  },
});
