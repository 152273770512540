import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} fill="none" {...props}>
    <Path
      fill="#fff"
      d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5ZM9 15c-3.308 0-6-2.693-6-6 0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6 0 3.307-2.693 6-6 6Zm3.127-3.93-.825-.825c.533-.997.398-2.257-.442-3.097A2.612 2.612 0 0 0 9 6.375c-.023 0-.045.008-.068.008l.818.817-.795.795-2.123-2.123L8.956 3.75l.795.795-.72.72c.953.008 1.898.36 2.625 1.08a3.774 3.774 0 0 1 .473 4.725Zm-.96 1.057L9.046 14.25l-.795-.795.713-.713a3.727 3.727 0 0 1-2.61-1.095 3.755 3.755 0 0 1-.48-4.717l.824.825c-.532.997-.397 2.257.443 3.097.525.525 1.223.78 1.92.758l-.81-.81.795-.795 2.123 2.122Z"
    />
  </Svg>
);
export default SvgComponent;
