import { spacing40, spacing6 } from "@styles/spacing";
import { FC } from "react";
import { View, ViewProps, StyleSheet } from "react-native";

const EmptyIconButton: FC<ViewProps> = props => (
  <View {...props} style={styles.emptyIconButtonStyle} />
);

const styles = StyleSheet.create({
  emptyIconButtonStyle: {
    width: spacing40,
    height: spacing40,
    margin: spacing6,
  },
});
export default EmptyIconButton;
