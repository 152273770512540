import { CurrencyType } from "@globalTypes/common.types";
import { PricesType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { CurrencyChoices } from "@api/api.types";
import { currency } from "./constants";

const currencyRates = {
  PLN: {
    GBP: 0.2,
    USD: 0.25,
    EUR: 0.25,
    PLN: 1,
  },
  GBP: {
    PLN: 5,
    USD: 1.25,
    EUR: 1.25,
    GBP: 1,
  },
  USD: {
    PLN: 4,
    GBP: 0.8,
    EUR: 1,
    USD: 1,
  },
  EUR: {
    PLN: 4,
    GBP: 0.8,
    USD: 1,
    EUR: 1,
  },
};

export const setDefaultPricesForAvailableCurrencies = (
  value: number,
  mainCurrency: CurrencyType,
  choices: CurrencyChoices[],
): PricesType[] => {
  const defaultPrices = [] as PricesType[];
  defaultPrices.push({
    amount: findClosestValue(
      value * currencyRates[mainCurrency].PLN,
      filterChoicesForCurrency(choices, "PLN"),
    ),
    currency: "PLN",
  });
  defaultPrices.push({
    amount: findClosestValue(
      value * currencyRates[mainCurrency].EUR,
      filterChoicesForCurrency(choices, "EUR"),
    ),
    currency: "EUR",
  });
  defaultPrices.push({
    amount: findClosestValue(
      value * currencyRates[mainCurrency].USD,
      filterChoicesForCurrency(choices, "USD"),
    ),
    currency: "USD",
  });
  defaultPrices.push({
    amount: findClosestValue(
      value * currencyRates[mainCurrency].GBP,
      filterChoicesForCurrency(choices, "GBP"),
    ),
    currency: "GBP",
  });
  return defaultPrices;
};

const filterChoicesForCurrency = (
  choices: CurrencyChoices[],
  curr: CurrencyType,
): number[] | undefined =>
  choices.filter(({ currency }) => currency === curr)[0]?.value;

const findClosestValue = (
  value: number,
  valueChoices: number[] | undefined,
): number => {
  if (!valueChoices?.length) return value;
  let closestIndex = 0;
  let closestDifference = Math.abs(valueChoices[0] - value);

  valueChoices.forEach((choice, index) => {
    const difference = Math.abs(choice - value);
    if (difference < closestDifference) {
      closestIndex = index;
      closestDifference = difference;
    }
  });

  return valueChoices[closestIndex];
};

export const getPrice = (prices: PricesType[], userCurrency: string) => {
  const price = prices?.find(
    p => p.currency.toLowerCase() === userCurrency.toLowerCase(),
  );
  const baseAmount = price?.amount;
  const baseCurrency = price?.currency?.toUpperCase();
  return { baseAmount, baseCurrency };
};

export const freeServicesPrices: PricesType[] = (
  Object.keys(currency) as Array<keyof typeof currency>
).map(currency => ({
  amount: 0,
  currency,
}));
