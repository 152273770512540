import { FileType } from "./types";

export const imageTypes: FileType[] = ["JPG", "JPEG", "PNG", "HEIC", "HEIF"];
export const videoTypes: FileType[] = [
  "MP4",
  "AVI",
  "MKV",
  "MOV",
  "WMV",
  "HEVC",
];
export const imageAndPdfTypes: FileType[] = [...imageTypes, "PDF"];
export const allFileTypes: FileType[] = [...imageAndPdfTypes, ...videoTypes];
export const maxVideoSizeInBytes = 250 * 1024 * 1024;
export const maxImageSizeInBytes = 20 * 1024 * 1024;
export const maxPdfSizeInBytes = 20 * 1024 * 1024;
