import { FC } from "react";
import { ActivityIndicator, View } from "react-native";
import { Text } from "react-native-paper";
import { Input } from "@components/Input";
import { Control, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { editResponse, getReviewResponse, replyReview } from "@api/reviews";
import { queryKeysPhysiotherapistRatings } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useErrors } from "@hooks/useErrors";
import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { FetchError } from "@components/errors";
import { ReviewResponseBody } from "@screens/Profiles/Physiotherapist/evaluation.types";
import { MULTILINE_INPUT_MAX_LENGTH } from "@utils/constants";

type AddOrEditAnswerFormProps = {
  reviewId?: number;
  answer?: number;
  onMutateCallback: () => void;
};

type FormType = {
  content: string;
};

const AddOrEditAnswerForm: FC<AddOrEditAnswerFormProps> = ({
  reviewId,
  answer,
  onMutateCallback,
}) => {
  const { t } = useTranslation();
  const { setErrorsFromResponse, errors } = useErrors();
  const queryClient = useQueryClient();
  const editMode = !!answer;
  const { data, isFetching, isError, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapistRatings.answerDetails(answer, reviewId),
    queryFn: async () => await getReviewResponse(answer),
    enabled: editMode,
  });
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: ReviewResponseBody) =>
      await (editMode ? editResponse(answer, data) : replyReview(data)),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeysPhysiotherapistRatings.list(reviewId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeysPhysiotherapistRatings.details(reviewId),
        }),
      ]);
    },
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const schema = object().shape({
    content: string().required(t("T00014")).trim(),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      content: data?.content,
    },
  });
  const onSubmit = (data: FormType) => {
    mutate({ review: reviewId, content: data.content });
    onMutateCallback();
  };
  if (isFetching) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} />;
  return (
    <View style={globalStyles.gapMedium}>
      <Text variant="bodyLarge">{t("T01419")}</Text>
      <Input
        name="content"
        label="T00421"
        errors={errors?.content}
        control={control as unknown as Control}
        multiline
        isInBottomSheet
        maxLength={MULTILINE_INPUT_MAX_LENGTH}
      />
      <PrimaryButton
        label="T00419"
        onPress={handleSubmit(onSubmit)}
        loading={isLoading}
      />
    </View>
  );
};

export default AddOrEditAnswerForm;
