import { FC } from "react";
import { PrimaryButton } from "@components/buttons/index";
import { ButtonBasic } from "@components/Button/Button.types";
import { useAppTheme } from "@styles/theme";
import { spacing8 } from "@styles/spacing";

const ReportUserButton: FC<ButtonBasic> = props => {
  const {
    colors: { error },
  } = useAppTheme();
  return (
    <PrimaryButton
      mode="text"
      textColor={error}
      style={{
        alignSelf: "flex-start",
        marginStart: -spacing8,
        marginVertical: -spacing8,
      }}
      {...props}
    />
  );
};

export default ReportUserButton;
