import { showSnackbar } from "@utils/snackbarHelper";
import { hideMessage } from "react-native-flash-message";

export const showProfileVerificationSnackbar = (message: string) => {
  showSnackbar({
    message,
    rightIcon: "close",
    onPressRightIcon: hideMessage,
  });
};
