import { FC, PropsWithChildren, useState } from "react";

import { SvgProphylacticWorkout1 } from "@assets/svg/tutorials";
import { BottomTutorialComponent } from "@components/BottomSheet";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { AllPatientTrainingsTabsProps } from "@navigators/navigation.types";
import { SafeAreaView } from "react-native-safe-area-context";
import ActiveAndInactiveTrainingsList from "@components/Training/ActiveAndInactiveTrainingsList";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import { spacing12 } from "@styles/spacing";
import { TransKey } from "@globalTypes/i18next";

const subtitles: TransKey[] = ["T00653", "T00654", "T00655"];

const IndividualTrainings: FC<
  PropsWithChildren<AllPatientTrainingsTabsProps>
> = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "left"]}>
      <ActiveAndInactiveTrainingsList
        ListHeaderComponent={
          <TutorialTextButton
            label="T00651"
            onPress={() => setModalVisible(true)}
            subContainerStyle={{ padding: spacing12 }}
          />
        }
        isAssistant={false}
      />
      <BottomModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}>
        <BottomTutorialComponent
          title="T00652"
          subtitles={subtitles}
          onPress={() => setModalVisible(false)}
          content={[
            {
              svgComponent: () => <SvgProphylacticWorkout1 />,
            },
          ]}
        />
      </BottomModalContainer>
    </SafeAreaView>
  );
};

export default IndividualTrainings;
