import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={60} height={60} fill="none" {...props}>
    <Path
      fill="#78767A"
      d="M24 42c1.65 0 3-1.35 3-3V21c0-1.65-1.35-3-3-3s-3 1.35-3 3v18c0 1.65 1.35 3 3 3Zm6-42C13.44 0 0 13.44 0 30c0 16.56 13.44 30 30 30 16.56 0 30-13.44 30-30C60 13.44 46.56 0 30 0Zm0 54C16.77 54 6 43.23 6 30S16.77 6 30 6s24 10.77 24 24-10.77 24-24 24Zm6-12c1.65 0 3-1.35 3-3V21c0-1.65-1.35-3-3-3s-3 1.35-3 3v18c0 1.65 1.35 3 3 3Z"
    />
  </Svg>
);
export default SvgComponent;
