import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useCallback } from "react";
import {
  FlatList,
  ListRenderItem,
  RefreshControl,
  StyleSheet,
} from "react-native";

import { UserChatCard } from "@components/cards/UserChatCard";
import { FetchError } from "@components/errors";
import { OnlyIdProfile } from "@contexts/AuthContext/user.types";
import { useUnreadMessages } from "@hooks/queryHooks/useUnreadMessages";
import { useErrors } from "@hooks/useErrors";
import { MessageCenterScreenType } from "@navigators/MessageCenterStackNavigator.tsx";
import { useIsFocused } from "@react-navigation/native";
import { queryKeysPatient } from "@screens/Common/queryKeysMyPatients";
import { getMyPatients } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import MessageCenterPermissionModal from "./MessageCenterPermissionModal";
import { queryKeysUnreadMessages } from "./queryKeysMessageCenter";

const Patients: FC<PropsWithChildren<MessageCenterScreenType>> = ({
  navigation: { navigate },
}) => {
  const { t } = useTranslation();
  const { loading, gapLarge } = globalStyles;
  const { setErrorsFromResponse } = useErrors();
  const isFocused = useIsFocused();
  const queryClient = useQueryClient();
  const { isLoading: isUnreadMessagesLoading } = useUnreadMessages();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysPatient.myList(),
    queryFn: () => getMyPatients(),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    enabled: isFocused,
  });

  const renderItem: ListRenderItem<OnlyIdProfile> = useCallback(
    ({ item: { id } }) => (
      <UserChatCard
        interlocutorId={id}
        onPress={() => navigate("ConversationScreen", { interlocutorId: id })}
      />
    ),
    [navigate],
  );

  const renderHeader = useCallback(
    () => <Text variant="bodyMedium">{t("T00912")}</Text>,
    [t],
  );

  const refetchAll = async () =>
    await Promise.all([
      refetch(),
      await queryClient.invalidateQueries(queryKeysUnreadMessages.all),
    ]);

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <SafeAreaView edges={["right", "left"]} style={{ flex: 1 }}>
      <FlatList
        data={data}
        ListHeaderComponent={data?.length <= 1 ? renderHeader : null}
        renderItem={renderItem}
        keyExtractor={({ id }: OnlyIdProfile) => id.toString()}
        contentContainerStyle={[styles.contentContainerStyle, gapLarge]}
        refreshControl={
          <RefreshControl
            refreshing={isLoading || isUnreadMessagesLoading}
            onRefresh={refetchAll}
          />
        }
      />
      <MessageCenterPermissionModal />
    </SafeAreaView>
  );
};
const styles = StyleSheet.create({
  contentContainerStyle: {
    padding: spacing16,
  },
});

export default Patients;
