/* eslint-disable react-hooks/exhaustive-deps */
import { AbsoluteShowCalendarButton } from "@components/Button";
import { Calendar, DateArrayType, FABModal } from "@components/Calendar";
import { FetchError } from "@components/errors";
import { useAuth } from "@contexts/AuthContext/auth";
import { useErrors } from "@hooks/useErrors";
import {
  CalendarStackParamList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { useIsFocused } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Appointment } from "@screens/Appointments/appointment.types";
import { getAppointmentsList } from "@services/ApiService/appointments";
import { FlashList } from "@shopify/flash-list";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { isANDROID } from "@utils/constants";
import { formatDateForApi, formatDateWithDayName } from "@utils/date";
import { AxiosError } from "axios";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { RefreshControl } from "react-native";
import { ActivityIndicator, FAB } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { queryKeysAppointments } from "../Appointments/queryKeysAppointments";
import { PhysioCalendarItem } from "./PhysioCalendarItem";
import { getEmptyMonthForPhysio } from "./calendarUtils";

export type FlashListItemType = string | { date: string; data: Appointment[] };

const PhysiotherapistCalendar: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      CalendarStackParamList &
        Pick<RootStackParamList, "AppointmentDetails"> &
        Pick<RootStackParamList, "CustomAppointmentDetails">,
      "Calendar"
    >
  >
> = ({ navigation: { navigate } }) => {
  const firstDayOfTheMonth = new Date();
  firstDayOfTheMonth.setHours(0, 0, 0, 0);
  firstDayOfTheMonth.setDate(1);
  const [scrollValue, setScrollValue] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentMonth, setCurrentMonth] = useState<Date>(firstDayOfTheMonth);
  const [monthMap, setMonthMap] = useState<FlashListItemType[]>([]);
  const flashListRef = useRef<FlashList<FlashListItemType>>(null);
  const [calendarDates, setCalendarDates] = useState<DateArrayType[]>();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [currentDate, setCurrentDate] = useState<DateArrayType>({
    date: today,
  });
  const { setErrorsFromResponse } = useErrors();
  const isFocused = useIsFocused();
  const { loading, fab } = globalStyles;

  const { data, isLoading, isError, refetch, isFetched } = useQuery({
    queryKey: queryKeysAppointments.list(),
    queryFn: async () =>
      await getAppointmentsList(formatDateForApi(currentMonth)),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    refetchIntervalInBackground: true,
    refetchInterval: 120000,
  });

  const {
    user: { language },
  } = useAuth();

  useEffect(() => {
    isFocused && void refetch();
  }, [isFocused, currentMonth]);

  useEffect(() => {
    if (isFetched && !!data?.length)
      setCalendarDates(
        data.map(a => ({
          date: new Date(a.dateFrom),
          eventType: "blue",
        })),
      );
  }, [isFetched, data]);

  useEffect(() => {
    if (data) {
      const resultMonth = getEmptyMonthForPhysio(currentMonth, data, language);
      setMonthMap(resultMonth);
    }
  }, [data, currentMonth, language]);

  const navToIndex = useCallback(
    (date: string) => {
      const foundIndex = monthMap.findIndex(e => {
        if (typeof e === "string") {
          return e === date;
        } else {
          return e.date === date;
        }
      });

      if (foundIndex !== -1) {
        flashListRef?.current?.scrollToIndex({
          animated: true,
          index: foundIndex,
        });
      }
    },
    [monthMap],
  );

  useEffect(() => {
    if (!isLoading && !isANDROID) {
      const today = new Date().toLocaleDateString();
      navToIndex(today);
    }
  }, [isLoading]);

  const navToAvailability = useCallback(
    (initialDate?: Date) => navigate("AvailabilityHours", { initialDate }),
    [navigate],
  );

  const renderItem = useCallback(
    ({ item }: { item: FlashListItemType }) => {
      const navToAppointment = (id: number) =>
        navigate("AppointmentDetails", {
          id,
        });
      const navToCustomAppointment = (id: number) =>
        navigate("CustomAppointmentDetails", {
          appointmentId: id,
        });

      return (
        <PhysioCalendarItem
          item={item}
          navToAvailability={navToAvailability}
          navToAppointment={navToAppointment}
          navToCustomAppointment={navToCustomAppointment}
        />
      );
    },
    [navToAvailability, navigate],
  );

  useEffect(() => {
    !isANDROID &&
      monthMap?.length &&
      setTimeout(() => {
        navToIndex(formatDateWithDayName(language, new Date()));
      }, 1250);
  }, [monthMap?.length, navToIndex, language]);

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "left"]}>
      {scrollValue > 0 && (
        <AbsoluteShowCalendarButton
          onPress={() =>
            flashListRef.current.scrollToOffset({
              animated: true,
              offset: 0,
            })
          }
        />
      )}
      <FlashList
        ref={flashListRef}
        data={monthMap}
        renderItem={renderItem}
        contentContainerStyle={{ padding: spacing16 }}
        getItemType={item =>
          typeof item === "string" ? "sectionHeader" : "row"
        }
        onScroll={({ nativeEvent }) =>
          setScrollValue(nativeEvent.contentOffset.y)
        }
        estimatedItemSize={spacing16 * 3}
        ListHeaderComponent={
          <Calendar
            onSelectDate={(data: DateArrayType) => {
              const date = new Date(data.date);
              setCurrentDate(data);
              navToIndex(formatDateWithDayName(language, date));
            }}
            onChangeMonth={setCurrentMonth}
            datesWithEvents={calendarDates}
            initialDates={[{ date: currentDate.date }]}
            containerStyle={{ marginBottom: spacing16 }}
          />
        }
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={refetch} />
        }
      />
      <FAB
        icon="plus"
        onPress={() => setModalVisible(true)}
        style={fab}
        color={palettes.primary[100]}
      />
      <FABModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        navToAvailability={navToAvailability}
      />
    </SafeAreaView>
  );
};

export default PhysiotherapistCalendar;
