import Svg, { SvgProps, Path } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={170} height={80} fill="none" {...props}>
    <Path
      fill="#635BFF"
      fillRule="evenodd"
      d="M149.719 40.772c0-9.204-4.458-16.467-12.98-16.467-8.557 0-13.734 7.263-13.734 16.395 0 10.823 6.112 16.288 14.885 16.288 4.279 0 7.515-.971 9.959-2.337V47.46c-2.444 1.222-5.249 1.977-8.809 1.977-3.487 0-6.579-1.222-6.975-5.465h17.582c0-.467.072-2.337.072-3.2Zm-17.762-3.416c0-4.063 2.481-5.752 4.746-5.752 2.194 0 4.531 1.69 4.531 5.752h-9.277ZM109.126 24.305c-3.524 0-5.789 1.654-7.047 2.804l-.468-2.229h-7.91v41.924l8.989-1.906.036-10.175c1.294.934 3.2 2.265 6.364 2.265 6.436 0 12.297-5.178 12.297-16.575-.036-10.427-5.969-16.108-12.261-16.108Zm-2.157 24.773c-2.122 0-3.38-.755-4.243-1.69l-.036-13.34c.935-1.042 2.229-1.761 4.279-1.761 3.271 0 5.537 3.667 5.537 8.377 0 4.818-2.23 8.414-5.537 8.414ZM81.332 22.184l9.025-1.942v-7.299l-9.025 1.906v7.335Z"
      clipRule="evenodd"
    />
    <Path fill="#635BFF" d="M90.357 24.916h-9.025v31.46h9.025v-31.46Z" />
    <Path
      fill="#635BFF"
      fillRule="evenodd"
      d="m71.66 27.577-.575-2.66H63.32v31.46h8.989V35.056c2.121-2.769 5.717-2.265 6.831-1.87v-8.27c-1.15-.43-5.357-1.222-7.478 2.661ZM53.683 17.114l-8.773 1.87-.036 28.8c0 5.321 3.991 9.24 9.313 9.24 2.948 0 5.105-.539 6.292-1.186v-7.299c-1.15.467-6.832 2.121-6.832-3.2V32.575h6.832v-7.659h-6.832l.036-7.802ZM29.378 34.048c0-1.402 1.15-1.941 3.056-1.941 2.732 0 6.184.827 8.916 2.301v-8.45c-2.984-1.186-5.932-1.653-8.916-1.653-7.3 0-12.153 3.81-12.153 10.175 0 9.924 13.663 8.342 13.663 12.62 0 1.654-1.438 2.194-3.452 2.194-2.984 0-6.795-1.223-9.816-2.877v8.557c3.344 1.439 6.724 2.05 9.816 2.05 7.479 0 12.62-3.704 12.62-10.14-.036-10.714-13.735-8.809-13.735-12.836Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default SvgComponent;
