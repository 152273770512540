import { Calendar, DateArrayType } from "@components/Calendar";
import { View } from "react-native";
import { FC, useMemo, useState } from "react";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import { PrimaryButton } from "@components/buttons";
import { useUserDetails } from "@hooks/user/useUserDetails";
import TutorialTrainingDetails from "@components/Tutorials/TutorialTrainingDetails";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TrainingDayDate } from "@screens/TrainingsAndExercises/training.types";
import { changeLibraryTrainingDays } from "@api/trainings";
import { AxiosError } from "axios";
import {
  queryKeysIndividualTrainings,
  queryKeysTrainingDay,
} from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { formatDateForApi } from "@utils/date";
import { useErrors } from "@hooks/useErrors";
import { TrainingCalendarProps } from "@screens/TrainingsAndExercises/TrainingDetailsComponents/types";

export const TrainingCalendar: FC<TrainingCalendarProps> = ({
  datesWithEvents,
  trainingId,
}) => {
  const { isPhysiotherapist } = useUserDetails();
  const [tutorialModalVisible, setTutorialModalVisible] = useState(false);
  const [changeDaysModalVisible, setChangeDaysModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const disableExecutionDaysModal = () => setChangeDaysModalVisible(false);
  const onSubmitChangeExecutionDays = (days: DateArrayType[]) =>
    changeExecutionDays(
      days.map(({ date }) => {
        return {
          executionDate: formatDateForApi(date),
        };
      }),
    );
  const { setErrorsFromResponse } = useErrors();

  const {
    mutate: changeExecutionDays,
    isLoading: isChangingExecutionDaysLoading,
  } = useMutation({
    mutationFn: async (days: TrainingDayDate[]) =>
      await changeLibraryTrainingDays(trainingId, days),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async () => {
      disableExecutionDaysModal();
      await Promise.all([
        queryClient.invalidateQueries(queryKeysTrainingDay.list()),
        queryClient.invalidateQueries(queryKeysTrainingDay.closestDays()),
        queryClient.invalidateQueries(queryKeysIndividualTrainings.list()),
      ]);
    },
  });

  const initialDates = useMemo(
    () =>
      datesWithEvents?.filter(({ date }) => {
        const today = new Date();
        const tempDate = new Date(date);
        today.setHours(0, 0, 0, 0);
        tempDate.setHours(0, 0, 0, 0);

        return tempDate.getTime() >= today.getTime();
      }),
    [datesWithEvents],
  );

  return (
    <>
      <TutorialTextButton
        label="T00643"
        onPress={() => setTutorialModalVisible(true)}
        subContainerStyle={{ alignSelf: "flex-start" }}
      />
      <Calendar datesWithEvents={datesWithEvents} disableOnPress />
      {!isPhysiotherapist && (
        <PrimaryButton
          mode="text"
          label="T00650"
          loading={isChangingExecutionDaysLoading}
          onPress={() => setChangeDaysModalVisible(true)}
        />
      )}
      <TutorialTrainingDetails
        modalVisible={tutorialModalVisible}
        setModalVisible={setTutorialModalVisible}
      />
      <BottomModalContainer
        modalVisible={changeDaysModalVisible}
        setModalVisible={setChangeDaysModalVisible}>
        <View>
          <Calendar
            multiselect
            datesWithEvents={datesWithEvents}
            initialDates={initialDates}
            onDismiss={disableExecutionDaysModal}
            onSubmit={onSubmitChangeExecutionDays}
            blockPast
          />
        </View>
      </BottomModalContainer>
    </>
  );
};
