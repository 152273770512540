import { AnimationObject } from "lottie-react-native";
import { TransKey } from "@globalTypes/i18next";

export type DataType = {
  animation: AnimationObject;
  text: TransKey;
};

export const physioSliderData: DataType[] = [
  {
    animation:
      require("@assets/animations/sliders/Slider_06_Money.json") as AnimationObject,
    text: "T00399",
  },
  {
    animation:
      require("@assets/animations/sliders/Slider_07_Trainings.json") as AnimationObject,
    text: "T00400",
  },
  {
    animation:
      require("@assets/animations/sliders/Slider_08_Statistics.json") as AnimationObject,
    text: "T00401",
  },
  {
    animation:
      require("@assets/animations/sliders/Slider_09_Calendar.json") as AnimationObject,
    text: "T00402",
  },
  {
    animation:
      require("@assets/animations/sliders/Slider_10_Promote.json") as AnimationObject,
    text: "T00403",
  },
];
