import { Input } from "@components/Input";
import InfoTile from "@components/Tile/InfoTile";
import { PrimaryButton } from "@components/buttons";
import { yupResolver } from "@hookform/resolvers/yup";
import { PostCreatePhysiotherapistAppointment } from "@screens/Appointments/appointment.types";
import { queryKeysAppointments } from "@screens/Appointments/queryKeysAppointments";
import { createPhysiotherapistAppointment } from "@services/ApiService/appointments";
import { globalStyles } from "@styles/global";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { INPUT_MAX_LENGTH, MULTILINE_INPUT_MAX_LENGTH } from "@utils/constants";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { showSnackbar } from "@utils/snackbarHelper";
import { AxiosError } from "axios";
import { FC, useCallback } from "react";
import { useForm, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { string, object } from "yup";

type FormType = {
  appointmentName: string;
  additionalInformation?: string;
};

type Props = {
  selectedPatient?: number;
  dateFrom: string;
  appointmentLanguage: string;
  service: number;
  hideModal: () => void;
  popToTop: () => void;
};

export const CreateCustomAppointmentBottomSheet: FC<Props> = ({
  selectedPatient,
  dateFrom,
  service,
  appointmentLanguage,
  hideModal,
  popToTop,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const createSchema = useCallback(() => {
    const appointmentName = selectedPatient
      ? string().trim()
      : string().required(t("T01410")).trim();

    const schema = {
      appointmentName,
      additionalInformation: string().trim(),
    };
    return object().shape(schema);
  }, [t, selectedPatient]);

  const { control, handleSubmit } = useForm<FieldValues>({
    resolver: yupResolver(createSchema()),
    values: { appointmentName: "", additionalInformation: "" },
  });

  const onMutationError = ({ response }: AxiosError) => {
    let errorMsg = "";
    Object.values(response.data).forEach(
      (e, i) =>
        (errorMsg = `${errorMsg} ${e}${
          i < Object.keys(response.data).length - 1 ? "\n" : ""
        }`),
    );
    showAlertWithCustomButtons({
      title: t("T01392"),
      message: errorMsg,
    });
    hideModal();
  };

  const onMutationSuccess = async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKeysAppointments.list(),
    });
    hideModal();
    popToTop();
    showSnackbar({ message: t("T01393") });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: createPhysiotherapistAppointment,
    onSuccess: onMutationSuccess,
    onError: onMutationError,
  });

  const onSubmit = ({ additionalInformation, appointmentName }: FormType) => {
    let params: PostCreatePhysiotherapistAppointment = {
      service,
      dateFrom,
      appointmentLanguage,
      surveyOmitted: true,
    };
    if (selectedPatient) {
      params = { ...params, patient: selectedPatient };
    } else {
      params = { ...params, name: appointmentName };
    }

    if (additionalInformation.length) {
      params = { ...params, additionalInformation };
    }
    mutate(params);
  };

  return (
    <View style={globalStyles.gapMedium}>
      <InfoTile
        status="warning"
        content={<Text variant="bodyMedium">{t("T01394")}</Text>}
      />
      <View>
        {!selectedPatient && (
          <Input
            key="appointmentName"
            name="appointmentName"
            label="T01395"
            control={control}
            isInBottomSheet
            maxLength={INPUT_MAX_LENGTH}
          />
        )}
        <Input
          key="additionalInformation"
          name="additionalInformation"
          label="T01396"
          control={control}
          multiline
          isInBottomSheet
          maxLength={MULTILINE_INPUT_MAX_LENGTH}
        />
      </View>
      <PrimaryButton
        label="T01397"
        onPress={handleSubmit(onSubmit)}
        loading={isLoading}
      />
    </View>
  );
};
