import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={194} height={217} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#E6E6E6"
        d="M108.306 51.83h-1.183V19.317c0-4.99-1.977-9.777-5.496-13.306A18.741 18.741 0 0 0 88.357.5H19.664c-2.464 0-4.905.487-7.181 1.432a18.763 18.763 0 0 0-6.088 4.08 18.82 18.82 0 0 0-4.068 6.104 18.862 18.862 0 0 0-1.429 7.201v178.366c0 2.471.486 4.918 1.429 7.201a18.829 18.829 0 0 0 4.068 6.105 18.733 18.733 0 0 0 13.27 5.511h68.692c4.977 0 9.75-1.982 13.269-5.511a18.843 18.843 0 0 0 5.497-13.306V74.973h1.183V51.83Z"
      />
      <Path
        fill="#fff"
        d="M89.114 5.396h-8.967a6.694 6.694 0 0 1-.638 6.245 6.644 6.644 0 0 1-5.526 2.952H34.63a6.645 6.645 0 0 1-5.526-2.952 6.688 6.688 0 0 1-.639-6.245H20.09c-3.716 0-7.281 1.48-9.91 4.115a14.072 14.072 0 0 0-4.104 9.937v178.104a14.07 14.07 0 0 0 4.105 9.936 13.992 13.992 0 0 0 9.91 4.116h69.023a13.984 13.984 0 0 0 9.91-4.116 14.058 14.058 0 0 0 4.104-9.936V19.448a14.068 14.068 0 0 0-4.104-9.937 13.98 13.98 0 0 0-9.91-4.116Z"
      />
      <Path
        fill="#E6E6E6"
        d="M97.433 145.282H14.67a1.535 1.535 0 0 1-1.533-1.537v-20.586a1.54 1.54 0 0 1 1.533-1.537h82.762a1.535 1.535 0 0 1 1.532 1.537v20.586a1.541 1.541 0 0 1-1.532 1.537ZM14.67 122.237a.917.917 0 0 0-.92.922v20.586a.924.924 0 0 0 .92.922h82.762a.917.917 0 0 0 .92-.922v-20.586a.924.924 0 0 0-.92-.922H14.67Z"
      />
      <Path
        fill="#E6E6E6"
        d="M25.399 139.906c3.555 0 6.437-2.889 6.437-6.454s-2.882-6.455-6.437-6.455-6.437 2.89-6.437 6.455 2.882 6.454 6.437 6.454ZM40.266 129.149a1.077 1.077 0 0 0 0 2.151h50.576a1.073 1.073 0 0 0 1.094-1.055 1.071 1.071 0 0 0-1.052-1.096h-.007a.207.207 0 0 0-.035 0H40.266ZM40.266 135.604a1.077 1.077 0 0 0 0 2.151h50.576a1.072 1.072 0 0 0 1.088-1.058 1.077 1.077 0 0 0-1.088-1.093H40.266ZM97.433 179.707H14.67a1.535 1.535 0 0 1-1.533-1.537v-20.586a1.541 1.541 0 0 1 1.533-1.537h82.762a1.535 1.535 0 0 1 1.532 1.537v20.586a1.54 1.54 0 0 1-1.532 1.537ZM14.67 156.662a.917.917 0 0 0-.92.922v20.586a.924.924 0 0 0 .92.923h82.762a.923.923 0 0 0 .92-.923v-20.586a.917.917 0 0 0-.92-.922H14.67Z"
      />
      <Path
        fill="#E6E6E6"
        d="M25.399 174.332c3.555 0 6.437-2.89 6.437-6.455s-2.882-6.455-6.437-6.455-6.437 2.89-6.437 6.455 2.882 6.455 6.437 6.455ZM40.266 163.574a1.074 1.074 0 0 0-1.056 1.076 1.077 1.077 0 0 0 1.056 1.075h50.576a1.072 1.072 0 0 0 1.088-1.058 1.077 1.077 0 0 0-1.053-1.093H40.266ZM40.266 170.028a1.075 1.075 0 0 0-1.056 1.076 1.074 1.074 0 0 0 1.055 1.076h50.577a1.075 1.075 0 0 0 .787-1.823 1.074 1.074 0 0 0-.752-.329H40.266Z"
      />
      <Path
        fill="#3F3D56"
        d="M70.685 39.58a30.667 30.667 0 0 0-33.31-4.142 30.773 30.773 0 0 0-12.896 11.876 30.891 30.891 0 0 0 1.288 33.633 30.755 30.755 0 0 0 13.765 10.85A30.662 30.662 0 0 0 56.97 93.35a30.715 30.715 0 0 0 15.457-8.244l37.708 31.905a2.83 2.83 0 0 0 4.646-2.408 2.843 2.843 0 0 0-.988-1.931l-.004-.003-37.708-31.906a30.893 30.893 0 0 0 5.257-21.64 30.851 30.851 0 0 0-10.654-19.542Zm-2.43 38.111a23.026 23.026 0 0 1-11.22 7.296 22.972 22.972 0 0 1-13.37-.183 23.03 23.03 0 0 1-11.015-7.6 23.148 23.148 0 0 1-2.16-25.57 23.06 23.06 0 0 1 9.583-9.351 22.978 22.978 0 0 1 25.432 2.864 23.115 23.115 0 0 1 8.096 15.7 23.137 23.137 0 0 1-5.347 16.844Z"
      />
      <Path
        fill="#000"
        d="M35.799 80.448a23.145 23.145 0 0 1-3.908-31.047 23.113 23.113 0 0 0-5.621 9.286A23.156 23.156 0 0 0 27.757 76.3a23.083 23.083 0 0 0 5.605 7.043A23.012 23.012 0 0 0 41.23 87.7a22.97 22.97 0 0 0 17.565-1.492 23.043 23.043 0 0 0 8.182-7.118 23.003 23.003 0 0 1-15.324 6.781 22.993 22.993 0 0 1-15.854-5.422Z"
        opacity={0.3}
      />
      <Path
        fill="#FFB6B6"
        d="M169.515 123.525a4.637 4.637 0 0 0 .884-1.781 4.666 4.666 0 0 0-.713-3.828c-.386-.546-.886-1-1.463-1.328l-9.774-40.74-7.131 2.319 10.555 40.461a4.701 4.701 0 0 0-.259 3.278 4.619 4.619 0 0 0 1.944 2.627 4.482 4.482 0 0 0 3.167.648 4.53 4.53 0 0 0 2.79-1.656Z"
      />
      <Path
        fill="#3F3D56"
        d="m164.689 97.363-5.336 4.644-7.464 6.493-3.297-17.02-.345-1.77-.271-1.405 7.563-13.293 6.305.365.431.026.009.096.099.891 1.293 11.732.03.283.983 8.958Z"
      />
      <Path
        fill="#FFB6B6"
        d="M130.425 210.639h-5.095l-2.424-20.093 7.521.001-.002 20.092Z"
      />
      <Path
        fill="#2F2E41"
        d="m131.262 215.663-16.713-.001v-.21c0-1.721.685-3.37 1.905-4.587a6.518 6.518 0 0 1 4.6-1.9l10.208.001v6.697Z"
      />
      <Path
        fill="#FFB6B6"
        d="m184.744 211.477-5.096-.001-2.424-20.092h7.521l-.001 20.093Z"
      />
      <Path
        fill="#2F2E41"
        d="m186.416 216.5-17.549-.001v-.21c0-1.721.72-3.37 2.001-4.587 1.281-1.216 3.018-1.9 4.83-1.9l10.718.001v6.697ZM188.923 203.06l-14.033 2.13-20.416-69.227-21.684 69.653-15.734-2.13 20.412-80.953v-6.391l2.764-9.587-.289-2.594-2.012-18.147-.871-7.869-.057-.508a1.733 1.733 0 0 1 1.358-1.882l6.549-1.38h10.703l6.315 1.742.434.12.196.055-.098.818-1.48 12.432-.081.682-1.429 11.985-.314 2.629c12.332 8.948 12.757 17.895 12.757 17.895l17.01 80.527Z"
      />
      <Path
        fill="#FFB6B6"
        d="M149.229 68.314c5.769 0 10.446-4.685 10.446-10.465s-4.677-10.465-10.446-10.465-10.446 4.685-10.446 10.465 4.677 10.465 10.446 10.465Z"
      />
      <Path
        fill="#3F3D56"
        d="m159.877 102.084-20.706 2.715.387 2.966 20.705-2.715-.386-2.966Z"
      />
      <Path
        fill="#FFB6B6"
        d="M111.347 105.913c.197.131.383.278.557.439l20.326-5.348 1.387-5.062 7.673 1.162-1.611 9.207a3.519 3.519 0 0 1-1.027 1.938 3.337 3.337 0 0 1-1.948.915l-23.733 2.58a4.53 4.53 0 0 1-1.793 2.004 4.326 4.326 0 0 1-5.031-.425 4.6 4.6 0 0 1-1.453-2.279 4.725 4.725 0 0 1 .059-2.729 4.57 4.57 0 0 1 1.551-2.209 4.357 4.357 0 0 1 2.492-.909 4.334 4.334 0 0 1 2.551.716Z"
      />
      <Path
        fill="#3F3D56"
        d="m142.918 77.84-3.739-2.753c-1.011-.369-2.396.68-2.639 1.694l-5.278 20.748 10.996 7.622 1.539-2.329-.879-24.982Z"
      />
      <Path
        fill="#000"
        d="M163.682 88.215c-.904.36-1.826.673-2.761.94-.299.09-.606.175-.908.256a28.071 28.071 0 0 1-6.111.992c-.89.037-1.781.013-2.668-.073a14.984 14.984 0 0 1-2.808-.555l-.094-.026a12.321 12.321 0 0 1-5.093-2.901 10.16 10.16 0 0 1-.899-1.005 9.102 9.102 0 0 1-1.943-7.063 6.14 6.14 0 0 1 .145-.671c.161-.602.373-1.188.635-1.752.115-.265.243-.526.379-.782l4.816-1.633h9.17l6.329 1.748.426.026.009.094.196.055-.098.82 1.278 11.53Z"
        opacity={0.2}
      />
      <Path
        fill="#2F2E41"
        d="M140.81 49.17c-1.95-.24-4.09-.071-5.61 1.178-1.52 1.25-2.011 3.846-.572 5.189 1.275 1.188 3.267.909 4.973 1.246a6.725 6.725 0 0 1 3.741 2.318 6.772 6.772 0 0 1 1.518 4.14c-.026 4.334-4.172 7.617-4.916 11.887-.509 2.914.716 5.98 2.834 8.039 2.117 2.058 5.028 3.17 7.961 3.472 2.933.301 5.899-.159 8.749-.915 2.863-.76 5.68-1.837 8.082-3.574 2.402-1.737 4.37-4.186 5.142-7.054.771-2.868.217-6.157-1.77-8.359-2.275-2.521-6.061-3.389-8.029-6.158-2.206-3.105-1.39-7.527-3.176-10.893-1.192-2.248-3.449-3.78-5.856-4.588-2.406-.807-4.975-.967-7.512-1.045-1.333-.041-2.706-.055-3.948.433s-2.334 1.599-2.398 2.936c-.065 1.336.787 1.747.787 1.747Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M.898.5h192.203v216H.898z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgComponent;
