import { useQuery } from "@tanstack/react-query";
import { queryKeysUnreadMessages } from "@screens/MessageCenter/queryKeysMessageCenter";
import { getAllUnreadMessages } from "@services/ApiService/conversations";
import { useIsFocused } from "@react-navigation/native";

export const useUnreadMessages = (userId?: number) => {
  const isFocused = useIsFocused();
  const {
    data: allUnreadMessages,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryFn: async () => await getAllUnreadMessages({ user: userId }),
    queryKey: userId
      ? queryKeysUnreadMessages.details(userId)
      : queryKeysUnreadMessages.list(),
    select: ({ unreadMessageCount }) => unreadMessageCount,
    refetchInterval: 30000,
    enabled: isFocused,
  });

  return { allUnreadMessages, isError, isLoading, refetch };
};
