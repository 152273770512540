import { ScrollView } from "react-native";
import TutorialTextSection, {
  TutorialTextSectionProps,
} from "./TutorialTextSection";
import { FC } from "react";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";

type TutorialTextSectionsListProps = { paddingBottom: number };
type TutorialBottomSheetDataType = TutorialTextSectionProps[];

const tutorialBottomSheetData: TutorialBottomSheetDataType = [
  {
    title: "T01435",
    content: "T01436",
  },
  {
    title: "T01437",
    content: "T01438",
  },
  {
    title: "T01439",
    content: "T01440",
  },
  {
    title: "T01441",
    content: "T01442",
  },
  {
    title: "T01443",
    content: "T01444",
  },
];

const TutorialTextSectionsList: FC<TutorialTextSectionsListProps> = ({
  paddingBottom,
}) => {
  const { height } = useWindowDimensions();
  return (
    <ScrollView
      style={[{ maxHeight: height * 0.4 }]}
      showsVerticalScrollIndicator
      contentContainerStyle={{ paddingBottom }}>
      {tutorialBottomSheetData?.map((el, i) => (
        <TutorialTextSection key={`${el.title}-${i}`} {...el} />
      ))}
    </ScrollView>
  );
};

export default TutorialTextSectionsList;
