import { Appointment } from "@screens/Appointments/appointment.types";
import { getDaysAfterDate, getMinutesBeforeDate } from "@utils/date";
import { useEffect, useState } from "react";
import { TransKey } from "@globalTypes/i18next";

type UsePatientAbsentSwitchOptions = {
  appointment: Appointment;
};
const PATIENT_ABSENCE_BUFFER = { DAYS_AFTER: 7, MINUTES_BEFORE: 10 };

export const usePatientAbsentSwitch = ({
  appointment,
}: UsePatientAbsentSwitchOptions) => {
  const [alertText, setAlertText] = useState<TransKey>();

  useEffect(() => {
    const dateWithTimeBufferAfterEventStarts =
      appointment?.dateFrom &&
      getMinutesBeforeDate(
        appointment.dateFrom,
        PATIENT_ABSENCE_BUFFER.MINUTES_BEFORE,
      );
    if (
      appointment?.dateTo &&
      new Date(appointment.dateTo) > dateWithTimeBufferAfterEventStarts &&
      dateWithTimeBufferAfterEventStarts > new Date()
    )
      setAlertText("T01154");
    if (
      new Date() >
      getDaysAfterDate(appointment?.dateTo, PATIENT_ABSENCE_BUFFER.DAYS_AFTER)
    )
      setAlertText("T01153");

    if (appointment?.cancelled) {
      setAlertText("T01198");
    }

    return () => setAlertText(undefined);
  }, [appointment.dateTo, appointment?.cancelled, appointment.dateFrom]);

  return { alertText: alertText };
};
