import Svg, { ClipPath, Defs, G, Path, Rect, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <G clipPath="url(#clip0_54467_12924)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6C4 4.9 4.9 4 6 4C7.1 4 8 4.9 8 6C8 7.1 7.1 8 6 8C4.9 8 4 7.1 4 6ZM1 9H7L14 4L15.31 5.52L11.14 8.5H14L21.8 4L23 5.4L14.5 12L14 22H12L11.5 12L8 11H1V9Z"
        fill="#46464F"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_54467_12924">
        <Rect width="24" height="24" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgComponent;
