import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { FC } from "react";
import { Hour } from "./PhysioAvailability.types";
import SingleColumnSlotsList from "./SingleColumnSlotsList";

type SingleColumnProps = {
  columnData: Hour[];
  isFirst?: boolean;
  isLast?: boolean;
  isStationaryService: boolean;
  onHourPress: (dateFrom: string) => void;
};

const SingleColumn: FC<SingleColumnProps> = ({
  columnData,
  isFirst,
  isLast,
  isStationaryService,
  onHourPress,
}) => {
  return (
    <SingleColumnSlotsList
      data={columnData}
      isStationaryService={isStationaryService}
      onHourPress={onHourPress}
      contentContainerStyle={[
        globalStyles.gapMedium,
        {
          marginStart: isFirst ? spacing8 : 0,
          marginEnd: isLast ? spacing8 : 0,
        },
      ]}
    />
  );
};

export default SingleColumn;
