import { useUserDetails } from "@hooks/index";
import { FC, CSSProperties } from "react";
import { VideoComponentProps } from "./video.types";

const VideoComponent: FC<VideoComponentProps> = ({
  uri,
  subtitlesUrl,
  shouldAutoStartPlaying = true,
  style,
  controls = true,
}) => {
  const { language } = useUserDetails();

  return (
    <video
      style={style as CSSProperties}
      src={uri}
      controls={controls}
      autoPlay={shouldAutoStartPlaying}
      crossOrigin={!__DEV__ && "anonymous"}
      loop>
      {!!subtitlesUrl && (
        <track kind="subtitles" srcLang={language} src={subtitlesUrl} default />
      )}
    </video>
  );
};

export default VideoComponent;
