import { ProfileStackParamsList } from "@navigators/navigation.types";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";
import Earnings from "./Earnings";
import AppointmentsStatistics from "./AppointmentsStatistics";
import AppointmentsOccupancy from "./AppointmentsOccupancy";
import { useTranslation } from "react-i18next";
import { theme } from "@styles/theme";
import { fontConfig } from "@styles/fontConfig";

type RehabStatisticsTabs = {
  Earnings: undefined;
  Appointments: undefined;
  Occupancy: undefined;
};

const Tab = createMaterialTopTabNavigator<RehabStatisticsTabs>();

const RehabStatistics: FC<
  PropsWithChildren<
    NativeStackScreenProps<ProfileStackParamsList, "RehabStatistics">
  >
> = () => {
  const { t } = useTranslation();
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: { backgroundColor: theme.colors.surface },
        tabBarLabelStyle: { textTransform: "none", ...fontConfig.titleSmall },
      }}>
      <Tab.Screen
        name="Earnings"
        component={Earnings}
        options={{ title: t("T00679") }}
      />
      <Tab.Screen
        name="Appointments"
        component={AppointmentsStatistics}
        options={{ title: t("T00704") }}
      />
      <Tab.Screen
        name="Occupancy"
        options={{ title: t("T00683") }}
        component={AppointmentsOccupancy}
      />
    </Tab.Navigator>
  );
};

export default RehabStatistics;
