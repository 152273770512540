import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import React, { FC, PropsWithChildren } from "react";

import Forum from "@screens/Profiles/MyProfile/Forum/Forum";
import CreateThread from "@screens/TrainingsAndExercises/Physiotherapist/CreateThread";
import TopicPreview from "@screens/Profiles/MyProfile/Forum/TopicPreview";
import { useTranslation } from "react-i18next";
import { AppbarTitleWithBackAction } from "../components/Appbar";
import { ForumStackParamsList } from "./navigation.types";
import AppbarTitleWithDefaultBackAction from "@components/Appbar/AppbarTitleWithDefaultBackAction";

const Stack = createNativeStackNavigator<ForumStackParamsList>();

const ForumStackNavigator: FC<
  PropsWithChildren<NativeStackScreenProps<ForumStackParamsList, "Forum">>
> = () => {
  const { t } = useTranslation();
  return (
    <Stack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
      }}>
      <Stack.Screen
        name="Forum"
        component={Forum}
        options={{
          header: ({ navigation: { goBack } }) => (
            <AppbarTitleWithBackAction title={t("T00112")} onClose={goBack} />
          ),
        }}
      />
      <Stack.Screen
        name="TopicPreview"
        component={TopicPreview}
        options={{
          header: ({ navigation }) => (
            <AppbarTitleWithDefaultBackAction
              navigation={navigation}
              title={t("T00113")}
            />
          ),
        }}
      />
      <Stack.Screen
        name="CreateThread"
        component={CreateThread}
        options={{
          header: ({ navigation: { goBack } }) => (
            <AppbarTitleWithBackAction title={t("T00876")} onClose={goBack} />
          ),
          presentation: "card",
          animation: "slide_from_bottom",
        }}
      />
    </Stack.Navigator>
  );
};

export default ForumStackNavigator;
