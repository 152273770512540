import { LIST } from "@utils/constants";

const keys = {
  services: "services",
  verificationServices: "verificationServices",
  recommendedServices: "recommendedServices",
};

export const queryKeysServices = {
  all: [keys.services] as const,
  list: (params: { specializationId: number }) =>
    [...queryKeysServices.all, params, LIST] as const,
};

export const queryKeysVerificationServices = {
  all: [keys.verificationServices] as const,
  list: (params: { specializationId: number }) =>
    [...queryKeysVerificationServices.all, params, LIST] as const,
};

export const queryKeysRecommendedServices = {
  all: [keys.recommendedServices] as const,
  list: () => [...queryKeysRecommendedServices.all, LIST] as const,
};
