import {
  CancelAppointmentButton,
  JoinOnlineAppointmentButton,
  PatientAbsentSection,
  SpecialTrainingRequestTile,
} from "@components/Appointment";
import BottomSheetCommitmentInfo from "@components/BottomSheet/BottomSheetCommitmentInfo";
import { FetchError } from "@components/errors";
import { useErrors } from "@hooks/useErrors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import {
  PatientProfileStackParamsList,
  RootStackParamList,
  ScheduleAppointmentStackParamsList,
} from "@navigators/navigation.types";
import { MaterialTopTabScreenProps } from "@react-navigation/material-top-tabs";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { queryKeysSpecialTrainings } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { cancelAppointment } from "@services/ApiService/appointments";
import {
  createCommitment,
  deleteCommitment,
  getSpecialTrainingCommitments,
} from "@services/ApiService/trainings";
import {
  getPatientBasicData,
  getPhysiotherapistBasicData,
} from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isWeb } from "@utils/constants";
import { AxiosError, AxiosResponse } from "axios";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Platform, RefreshControl, ScrollView } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";
import { AppointmentDetailsTabsParamList } from ".";
import { queryKeysAppointments } from "../queryKeysAppointments";
import { InformationDetails } from "./InformationDetails";

import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { CancelAppointmentBottomSheetContent } from "@components/BottomSheetContents";
import { useAppointmentData, usePayments } from "@hooks/index";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import { config } from "@utils/config";
import { getDateFromString } from "@utils/date";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { showSnackbar } from "@utils/snackbarHelper";
import { differenceInHours } from "date-fns";
import { AppointmentDetailsAdditionalInfo } from "./AppointmentDetailsAdditionalInfo";
import { AppointmentDetailsHeader } from "./AppointmentDetailsHeader";
import { AppointmentDetailsInfoTiles } from "./AppointmentDetailsInfoTiles";

type initStripeType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("@stripe/stripe-react-native").initStripe;

let initStripe: initStripeType;

if (!isWeb) {
  initStripe = (
    require("@stripe/stripe-react-native") as typeof import("@stripe/stripe-react-native")
  ).initStripe;
}

export const Information: FC<
  PropsWithChildren<
    MaterialTopTabScreenProps<AppointmentDetailsTabsParamList, "Information">
  >
> = ({ route }) => {
  const [isCommitmentActive, setIsCommitmentActive] = useState(false);
  const [commitmentModalVisible, setCommitmentModalVisible] = useState(false);
  const [cancelAppointmentModalVisible, setCancelAppointmentModalVisible] =
    useState(false);
  const [switchOn, setSwitchOn] = useState(false);

  const { appointmentId, isIdFromNotification } = route.params;
  const { scrollContainer, gapLarge, loading } = globalStyles;

  const { t } = useTranslation();
  const { navigate } =
    useNavigation<
      NavigationProp<
        PatientProfileStackParamsList &
          RootStackParamList &
          ScheduleAppointmentStackParamsList,
        "AppointmentConfirmation"
      >
    >();
  const { setErrorsFromResponse } = useErrors();
  const queryClient = useQueryClient();
  const { isPhysiotherapist } = useUserDetails();

  useEffect(() => {
    if (isIdFromNotification)
      void queryClient.invalidateQueries(queryKeysAppointments.list());
  }, [isIdFromNotification, queryClient]);

  const {
    data: appointmentDetails,
    isLoading: isAppointmentDetailsLoading,
    isError: isAppointmentDetailsError,
    refetch: refetchAppointmentDetails,
    isSuccess: isAppointmentDetailsSuccess,
  } = useAppointmentData(appointmentId);

  useEffect(() => {
    if (isAppointmentDetailsSuccess && Object.keys(appointmentDetails).length) {
      setSwitchOn(appointmentDetails.patientAbsent);
    }
    if (isAppointmentDetailsError) {
      setSwitchOn(false);
    }
  }, [
    isAppointmentDetailsSuccess,
    isAppointmentDetailsError,
    appointmentDetails,
  ]);

  const {
    data: specialTrainings,
    isLoading: isSpecialTrainingsLoading,
    isError: isSpecialTrainingsError,
    refetch: refetchSpecialTrainings,
  } = useQuery({
    queryKey:
      queryKeysSpecialTrainings.commitmentsToAppointmentList(appointmentId),
    queryFn: async () => await getSpecialTrainingCommitments(appointmentId),
    onSuccess: data => setIsCommitmentActive(!!data.length),
  });

  const dataQueryKey = useCallback(
    () =>
      queryKeysForPatientAndPhysiotherapist.basicData(
        !isPhysiotherapist
          ? appointmentDetails?.physiotherapist
          : appointmentDetails?.patient,
      ),
    [
      appointmentDetails?.physiotherapist,
      appointmentDetails?.patient,
      isPhysiotherapist,
    ],
  );

  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
    refetch: refetchUserData,
  } = useQuery({
    queryKey: dataQueryKey(),
    queryFn: async () =>
      !isPhysiotherapist
        ? await getPhysiotherapistBasicData(appointmentDetails?.physiotherapist)
        : await getPatientBasicData(appointmentDetails?.patient),
    enabled: !!appointmentDetails,
  });

  const { mutate } = useMutation({
    mutationFn: async () => {
      if (specialTrainings?.length === 0 && isCommitmentActive)
        await createCommitment(appointmentId);
      if (specialTrainings?.length > 0 && !isCommitmentActive)
        await deleteCommitment(specialTrainings[0].id);
    },
    onSuccess: async () =>
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeysSpecialTrainings.detail(appointmentId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeysSpecialTrainings.list(),
        }),
      ]),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const { mutate: cancel, isLoading: isCanceling } = useMutation({
    mutationFn: cancelAppointment,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async ({ data: { detail: alertMessage } }: AxiosResponse) => {
      await refreshQuery();
      showSnackbar({ message: t("T01175") });
      alertMessage && alert(alertMessage);
    },
  });

  const refreshQuery = async () => {
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: queryKeysAppointments.detail(appointmentId),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysAppointments.list(),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysAppointments.listTodayAppointments(),
      }),
    ]);
  };

  const getAlertTexts = useCallback(() => {
    const title = t("T01155", {
      value: `${userData?.firstName} ${userData?.lastName}`,
    });
    let subtitle = isPhysiotherapist ? t("T01164") : t("T01156");

    if (appointmentDetails?.paymentType === "Online") {
      if (isPhysiotherapist) {
        subtitle = t("T01163");
      } else {
        const startDate = getDateFromString(appointmentDetails?.dateFrom);
        const endDate = new Date();
        const hoursDiff = differenceInHours(startDate, endDate);

        if (hoursDiff >= 24) {
          subtitle = t("T01161");
        } else {
          subtitle = t("T01162");
        }
      }
    }

    return {
      title,
      subtitle,
    };
  }, [
    userData?.firstName,
    userData?.lastName,
    appointmentDetails?.dateFrom,
    appointmentDetails?.paymentType,
    isPhysiotherapist,
    t,
  ]);

  const navigateAfterPayment = useCallback(
    (error?: boolean) =>
      navigate("ScheduleAppointmentStack", {
        screen: "AppointmentConfirmation",
        params: {
          id: appointmentDetails.id,
          paymentType: "Online",
          paymentError: !!error,
        },
      }),
    [appointmentDetails?.id, navigate],
  );

  const { initializePaymentSheet, openPaymentSheet, wasPaymentInitialized } =
    usePayments({
      paymentIntentClientSecret:
        appointmentDetails?.payment?.paymentIntentClientSecret,
      onPaymentSuccess: async () => {
        await refreshQuery();
        navigateAfterPayment();
      },
      onPaymentError: () => navigateAfterPayment(true),
      returnURL: `${config.EXPO_PUBLIC_DEEP_LINK_URL}appointmentDetails/${appointmentId}`,
    });

  const paymentSuccessful =
    appointmentDetails?.payment?.paymentStatus === "succeeded";

  useEffect(() => {
    if (
      !appointmentDetails?.payment?.paymentIntent ||
      wasPaymentInitialized ||
      paymentSuccessful ||
      isWeb
    )
      return;
    void (async () => {
      await initStripe({
        publishableKey: appointmentDetails?.payment?.stripePublishableKey,
        stripeAccountId: appointmentDetails?.payment?.sellerStripeId,
      });
      await initializePaymentSheet(true);
    })();
  }, [
    initializePaymentSheet,
    openPaymentSheet,
    wasPaymentInitialized,
    appointmentDetails,
    paymentSuccessful,
  ]);

  const onChangeSwitch = () => {
    setIsCommitmentActive(prevState => !prevState);
    setTimeout(mutate, 250);
  };

  const isAnyLoading = useMemo(
    () =>
      isAppointmentDetailsLoading ||
      isSpecialTrainingsLoading ||
      isUserDataLoading,
    [isAppointmentDetailsLoading, isSpecialTrainingsLoading, isUserDataLoading],
  );

  const isAnyError = useMemo(
    () =>
      isAppointmentDetailsError || isSpecialTrainingsError || isUserDataError,
    [isAppointmentDetailsError, isSpecialTrainingsError, isUserDataError],
  );

  const refetchAll = async () =>
    await Promise.all([
      refetchAppointmentDetails(),
      refetchSpecialTrainings(),
      refetchUserData(),
    ]);

  const showAlert = useCallback(() => {
    const { title, subtitle: message } = getAlertTexts();
    showAlertWithCustomButtons({
      title,
      message,
      confirmButton: {
        text: t("T01157"),
        onPress: () => cancel(appointmentDetails?.id),
      },
      cancelButton: {
        text: t("T01176"),
      },
    });
  }, [appointmentDetails?.id, cancel, getAlertTexts, t]);

  if (isAnyLoading) return <ActivityIndicator style={loading} />;
  if (isAnyError) return <FetchError action={refetchAll} />;

  const { cancelled, physiotherapist, patient, serviceType } =
    appointmentDetails;
  return (
    <ScrollView
      contentContainerStyle={[scrollContainer, gapLarge]}
      refreshControl={
        <RefreshControl refreshing={isAnyLoading} onRefresh={refetchAll} />
      }>
      <AppointmentDetailsInfoTiles
        cancelled={cancelled}
        switchOn={switchOn}
        paymentSuccessful={paymentSuccessful}
        wasPaymentInitialized={wasPaymentInitialized}
        openPaymentSheet={openPaymentSheet}
      />
      <AppointmentDetailsHeader
        physiotherapist={physiotherapist}
        patient={patient}
      />
      <Text variant="titleLarge">{t("T00123")}</Text>
      <Divider bold />
      {!cancelled && (
        <SpecialTrainingRequestTile
          onButtonPress={() => setCommitmentModalVisible(true)}
          appointmentDetails={appointmentDetails}
          isPhysiotherapist={isPhysiotherapist}
          onChangeSwitch={onChangeSwitch}
          isCommitmentActive={isCommitmentActive}
        />
      )}
      {appointmentDetails?.additionalInformation && (
        <AppointmentDetailsAdditionalInfo
          additionalInfo={appointmentDetails?.additionalInformation}
          appointmentName={appointmentDetails?.name}
        />
      )}
      {!cancelled && serviceType === "Online" && paymentSuccessful && (
        <JoinOnlineAppointmentButton
          appointmentDetails={appointmentDetails}
          appointmentId={appointmentId}
          isPhysiotherapist={isPhysiotherapist}
        />
      )}
      {isPhysiotherapist && (
        <PatientAbsentSection
          switchOn={switchOn}
          setSwitchOn={setSwitchOn}
          appointment={appointmentDetails}
        />
      )}
      <InformationDetails data={appointmentDetails} />
      <CancelAppointmentButton
        appointmentDetails={appointmentDetails}
        setCancelAppointmentModalVisible={setCancelAppointmentModalVisible}
        onConfirmedCancel={showAlert}
        isPhysiotherapist={isPhysiotherapist}
        isCanceling={isCanceling}
      />
      <BottomSheetCommitmentInfo
        modalVisible={commitmentModalVisible}
        setModalVisible={setCommitmentModalVisible}
      />
      <BottomModalContainer
        modalVisible={cancelAppointmentModalVisible}
        setModalVisible={setCancelAppointmentModalVisible}>
        <CancelAppointmentBottomSheetContent
          setModalVisible={setCancelAppointmentModalVisible}
          onCancel={showAlert}
        />
      </BottomModalContainer>
    </ScrollView>
  );
};
