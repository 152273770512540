import { FC } from "react";
import { useQuery } from "@tanstack/react-query";
import { getWorkoutCategorySet } from "@services/ApiService/trainings";
import { queryKeysProphylacticWorkoutCategorySets } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { ActivityIndicator, Card, Text } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { FetchError } from "@components/errors";
import { View } from "react-native";
import { SvgAssignment } from "@assets/svg/SvgAssignment";
import { useMediaStyle } from "@hooks/ui/useMediaStyle";
import { spacing20, spacing32 } from "@styles/spacing";
import { useTranslation } from "react-i18next";
import { WorkoutSetDetails } from "./prophylacticSets.type";
import { theme } from "@styles/theme";

type Props = {
  id: number;
  onPress: (data: WorkoutSetDetails) => void;
};

export const CategorySetTile: FC<Props> = ({ id, onPress }) => {
  const { width } = useMediaStyle();
  const { t } = useTranslation();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysProphylacticWorkoutCategorySets.detail(id),
    queryFn: async () => await getWorkoutCategorySet(id),
  });

  if (isLoading) return <ActivityIndicator style={globalStyles.loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <Card
      mode="outlined"
      style={{ width, borderColor: theme.colors.outlineVariant }}
      onPress={() => onPress(data)}>
      <Card.Content style={{ flexDirection: "row", alignItems: "center" }}>
        <SvgAssignment />
        <View style={{ marginLeft: spacing20, marginRight: spacing32 }}>
          <Text variant="titleMedium">{data?.name}</Text>
          <Text variant="bodyMedium">
            {t("T01063", {
              value: data?.prophylacticWorkoutSetOrder?.length,
            })}
          </Text>
        </View>
      </Card.Content>
    </Card>
  );
};
