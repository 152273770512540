import { Text } from "react-native-paper";
import ReportWrapper from "@components/Report/ReportWrapper";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { ReportProps } from "@components/Report/report.types";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { spacing8 } from "@styles/spacing";

type ExerciseInstructionType = ReportProps & {
  instruction: string;
  disableReportWrapper: boolean;
};

const InternalText: FC<{ instruction: string }> = ({ instruction }) => {
  const { t } = useTranslation();
  return (
    <>
      <Text variant="titleMedium" style={{ marginBottom: spacing8 }}>
        {t("T00556")}:
      </Text>
      <Text variant="bodyMedium">{instruction}</Text>
    </>
  );
};

const ExerciseInstruction: FC<ExerciseInstructionType> = ({
  instruction,
  disableReportWrapper,
  ...props
}) => {
  const { isPatient } = useUserDetails();
  return isPatient && !disableReportWrapper ? (
    <ReportWrapper {...props}>
      <InternalText instruction={instruction} />
    </ReportWrapper>
  ) : (
    <InternalText instruction={instruction} />
  );
};

export default ExerciseInstruction;
