import { PrimaryButton } from "@components/buttons";
import { FileAttachment } from "@globalTypes/common.types";
import { TransKey } from "@globalTypes/i18next";
import { FC, PropsWithChildren } from "react";
import { UseFieldArrayRemove } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Text } from "react-native-paper";
import { AttachedFilesList } from "./AttachedFilesList";

type AttachedFilesSectionProps = {
  title?: TransKey;
  onListItemRemove: UseFieldArrayRemove;
  listData?: FileAttachment[];
  onPressButton: () => void;
  emptyListInfo: TransKey;
  loading?: boolean;
  disabled?: boolean;
};

export const AttachedFilesSection: FC<
  PropsWithChildren<AttachedFilesSectionProps>
> = ({ onPressButton, title, disabled, loading, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <Text variant="titleMedium">{t(title)}:</Text>
      <AttachedFilesList {...props} />
      <PrimaryButton
        label="T00870"
        onPress={onPressButton}
        icon="plus"
        mode="outlined"
        disabled={disabled}
        loading={loading}
      />
    </>
  );
};
