import { useAuth } from "@contexts/AuthContext/auth";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { getDate, getTime } from "@utils/date";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Card, HelperText, Text } from "react-native-paper";
import { MessageTileType, MessageType } from "./types/MessageTile.types";
import { getTileStyleByType } from "./utils/MessageTile.utils";
import { useLocale } from "@hooks/useLocale";

export const MessageTile: FC<MessageTileType> = ({
  content,
  type,
  date,
  elevation,
  index,
  readAt,
  disableExtraText,
  sender,
  ...props
}) => {
  const { container, tileWidth, whiteFont } = styles;
  const tileStyles = getTileStyleByType(type);
  const { locale } = useLocale();
  const dateAndTime = `${getDate(date)}, ${getTime(date, locale)}`;
  const isReporter = type === MessageType.REPORTER;
  const { t } = useTranslation();
  const {
    user: { id: userId },
  } = useAuth();

  return (
    <View style={[isReporter ? { alignItems: "flex-end" } : null, container]}>
      <Text variant="labelSmall">{dateAndTime}</Text>
      <Card
        {...props}
        mode="elevated"
        elevation={elevation as never}
        style={[tileStyles, tileWidth]}>
        <Card.Content>
          <Text variant="bodySmall" style={isReporter ? whiteFont : null}>
            {content}
          </Text>
        </Card.Content>
      </Card>
      {index === 0 && !disableExtraText && sender === userId && (
        <View style={{ alignItems: "flex-end", marginTop: -spacing8 }}>
          <HelperText type="info">{t(readAt ? "T01170" : "T01171")}</HelperText>
        </View>
      )}
    </View>
  );
};

export default MessageTile;

const styles = StyleSheet.create({
  container: {
    ...globalStyles.gapSmall,
    padding: spacing8,
  },
  whiteFont: {
    color: palettes.primary[100],
  },
  tileWidth: { width: "75%" },
});
