import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill="#000"
      d="M20 3.333C10.8 3.333 3.333 10.8 3.333 20c0 9.2 7.467 16.667 16.667 16.667 9.2 0 16.666-7.467 16.666-16.667C36.666 10.8 29.2 3.334 20 3.334Zm0 30C12.65 33.334 6.666 27.35 6.666 20 6.666 12.65 12.65 6.667 20 6.667S33.333 12.65 33.333 20c0 7.35-5.983 13.334-13.333 13.334ZM21.483 18.5c-2.967-.983-4.4-1.6-4.4-3.166 0-1.7 1.85-2.317 3.017-2.317 2.183 0 2.983 1.65 3.166 2.233l2.634-1.117c-.25-.733-1.367-3.183-4.434-3.716V8.334H18.55v2.1c-4.334.933-4.367 4.75-4.367 4.933 0 3.783 3.75 4.85 5.583 5.517 2.634.933 3.8 1.783 3.8 3.383 0 1.883-1.75 2.683-3.3 2.683-3.033 0-3.9-3.116-4-3.483L13.5 24.584c1.05 3.65 3.8 4.633 5.033 4.933v2.15h2.917V29.6c.866-.15 5.033-.983 5.033-5.367.017-2.316-1-4.35-5-5.733Z"
    />
  </Svg>
);
export default SvgComponent;
