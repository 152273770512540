import { AbsoluteBlurredFooter } from "@components/Footers/AbsoluteBlurredFooter";
import { Choice, ResponseOptions } from "@services/ApiService/api.types";
import { palettes } from "@styles/colors";
import { isIOS } from "@utils/constants";
import { Dispatch, FC, SetStateAction, useState } from "react";
import {
  KeyboardAvoidingView,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";
import {
  SafeAreaView,
  useSafeAreaInsets,
} from "react-native-safe-area-context";
import { FullScreenModal } from "..";
import { HeaderWithSearchbar } from "../HeaderWithSearchbar";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { useThrottle } from "ahooks";
import { Condition } from "@screens/TrainingsAndExercises/exercise.types";
import { TransKey } from "@globalTypes/i18next";
import { AxiosResponse } from "axios";

type ListComponentType<T> = {
  searchQuery: string;
  setSelectedItems: Dispatch<SetStateAction<T[]>>;
  selectedItems: T[];
  style?: StyleProp<ViewStyle>;
};

type Props<T> = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  selectedItems: T[];
  setSelectedItems: Dispatch<SetStateAction<T[]>>;
  ListComponent: FC<ListComponentType<T>>;
  returnLabel: (val: number) => string | TransKey;
  data?: AxiosResponse<ResponseOptions>;
  isLoading?: boolean;
  mutatePickedItems?: (language: string) => void;
  savedItems?: string[];
  enableSearching?: boolean;
  onItemPress?: (id: number) => void;
};

export const SearchModal = <T extends string | number | Choice | Condition>({
  isModalVisible,
  setIsModalVisible,
  setSelectedItems,
  selectedItems,
  ListComponent,
  returnLabel,
  data,
  isLoading,
  mutatePickedItems,
  savedItems,
  enableSearching,
  onItemPress,
}: Props<T>) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [footerHeight, setFooterHeight] = useState<number>(0);

  const throttledQuery = useThrottle(searchQuery, {
    leading: false,
  });

  const { top } = useSafeAreaInsets();
  const { width, height } = useWindowDimensions();

  const { container } = styles;

  const goBack = () => {
    setIsModalVisible(false);
    setSearchQuery("");
  };

  const componentProps = {
    data,
    searchQuery: throttledQuery,
    setSelectedItems,
    selectedItems,
    onItemPress,
  };

  const saveAndGoBack = () => {
    mutatePickedItems &&
      selectedItems
        ?.filter(e => {
          const choiceItem = e as Choice;
          return !savedItems.some(el => el === choiceItem.value);
        })
        .map(item => {
          const choiceItem = item as Choice;
          mutatePickedItems(`${choiceItem.value}`);
        });
    goBack();
  };

  const renderContent = () => {
    return (
      <SafeAreaView style={container} edges={["top", "left", "right"]}>
        <KeyboardAvoidingView
          behavior={isIOS ? "padding" : "height"}
          style={{
            width,
            height: height - (isIOS ? top : 0),
            marginTop: isIOS ? top : 0,
            backgroundColor: palettes.primary[100],
          }}>
          <HeaderWithSearchbar
            searchQuery={searchQuery}
            onClear={() => setSearchQuery("")}
            onChangeText={text => setSearchQuery(text)}
            goBack={goBack}
            enableSearching={enableSearching}
          />
          <ListComponent
            {...componentProps}
            style={{
              paddingBottom: footerHeight,
            }}
          />
          {selectedItems?.length > 0 && (
            <AbsoluteBlurredFooter
              title={
                returnLabel(
                  selectedItems?.filter(e => !savedItems?.includes(`${e}`))
                    .length,
                ) as TransKey
              }
              onPress={saveAndGoBack}
              onLayout={height => setFooterHeight(height)}
              buttonLoading={isLoading}
              buttonDisabled={isLoading}
            />
          )}
        </KeyboardAvoidingView>
      </SafeAreaView>
    );
  };

  return (
    <FullScreenModal
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      renderContent={renderContent}
    />
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: palettes.primary[100] },
});
