import { FC, useEffect } from "react";
import SubscriptionScreenContent from "@screens/Profiles/MyProfile/PhysiotherapistVerification/Subscription/SubscriptionScreenContent";
import { SubscriptionScreenProps } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/Subscription/Subscription";
import { useSubscriptionData } from "@hooks/subscriptions/useSubscriptionData";
import { useAuth } from "@contexts/AuthContext/auth";
import { useTranslation } from "react-i18next";
import AppbarTitleWithDefaultBackAction from "@components/Appbar/AppbarTitleWithDefaultBackAction";

const SubscriptionWeb: FC<SubscriptionScreenProps> = ({
  navigation: { setOptions, navigate },
}) => {
  const { isSubActive } = useSubscriptionData();
  const { updateUser } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    updateUser();
  }, [updateUser]);

  useEffect(() => {
    setOptions({
      header: ({ navigation }) => (
        <AppbarTitleWithDefaultBackAction
          title={t("T00936")}
          filledIcon={isSubActive}
          navigation={navigation}
        />
      ),
    });
  }, [setOptions, t, isSubActive, navigate]);

  return <SubscriptionScreenContent isSubActive={isSubActive} />;
};

export default SubscriptionWeb;
