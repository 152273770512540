import { useNotificationMainSettings } from "./useNotificationMainSettings";
import { useQuery } from "@tanstack/react-query";
import { checkDevicePushNotificationsEnabled } from "@services/ApiService/appSettings";
import { queryKeysNotificationSettings } from "@screens/Profiles/MyProfile/queryKeysSettings";

type UseCheckNotificationsForBellOptions = {
  specificPushNotificationsEnabled: boolean;
};

export const useCheckNotificationsForBell = ({
  specificPushNotificationsEnabled,
}: UseCheckNotificationsForBellOptions) => {
  const { data: devicePushNotificationsEnabled } = useQuery({
    queryFn: checkDevicePushNotificationsEnabled,
    queryKey: queryKeysNotificationSettings.devicePushNotifications(),
  });

  const { data } = useNotificationMainSettings();

  return {
    enabled:
      devicePushNotificationsEnabled &&
      data?.notificationsActive &&
      specificPushNotificationsEnabled,
  };
};
