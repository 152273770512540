import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";

import { BottomModalWithButton } from "@components/BottomSheet";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import RatingAnswer from "./RatingAnswer";

import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { ButtonBottomSheet } from "@components/Button/Button.types";
import AddOrEditAnswerForm from "@components/Rating/AddOrEditAnswerForm";
import RatingAnswerSheetContentPhysio from "@components/Rating/RatingAnswerSheetContentPhysio";
import { RatingStars } from "@components/Rating/RatingStars";
import CardWithPhotoAndDate from "@components/cards/CardWithPhotoAndDate";
import { useAuth } from "@contexts/AuthContext/auth";
import { useErrors } from "@hooks/useErrors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import {
  queryKeysPhysiotherapistProfileData,
  queryKeysPhysiotherapistRatings,
} from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { getReviewDetails, removeReview } from "@services/ApiService/reviews";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing16, spacing24 } from "@styles/spacing";
import { getDate } from "@utils/date";
import RatingReportForm from "./RatingReportForm";

type RatingProps = {
  reviewId: number;
  disableReply?: boolean;
  isLast?: boolean;
  navigateToEdit?: () => void;
  displaySnackbar: () => void;
  disableNumberRating?: boolean;
};
const Rating: FC<RatingProps> = ({
  reviewId,
  disableReply,
  isLast,
  navigateToEdit,
  displaySnackbar,
  disableNumberRating,
}) => {
  const [modalMenuVisible, setModalMenuVisible] = useState<boolean>(false);
  const [modalReportVisible, setModalReportVisible] = useState<boolean>(false);
  const [addOrEditAnswerSheetVisible, setAddOrEditAnswerSheetVisible] =
    useState(false);
  const [answerActionsSheetVisible, setAnswerActionsSheetVisible] =
    useState(false);
  const [reportAnswerSheetVisible, setReportAnswerSheetVisible] =
    useState(false);

  const { sheetContentContainer, responseButton, responseContainer } = styles;
  const { setErrorsFromResponse } = useErrors();
  const queryClient = useQueryClient();
  const {
    user: { id },
  } = useAuth();
  const { isPhysiotherapist } = useUserDetails();
  const { details, check, list } = queryKeysPhysiotherapistRatings;

  const { data, isFetched, refetch, isError } = useQuery({
    queryKey: details(reviewId),
    queryFn: async () => await getReviewDetails(reviewId),
  });

  const onEditPress = () => {
    setAnswerActionsSheetVisible(false);
    setTimeout(() => setAddOrEditAnswerSheetVisible(true), 500);
  };

  const onReportPress = () => {
    setAnswerActionsSheetVisible(false);
    setTimeout(() => setReportAnswerSheetVisible(true), 500);
  };

  const isMyOwn = isPhysiotherapist
    ? data?.physiotherapist === id
    : data?.patient === id;

  const isResponse = data?.response.length > 0;

  const handleCloseMenuModal = (cb?: () => void) => {
    setModalMenuVisible(false);
    cb && setTimeout(cb, 500);
  };

  const onDeleteSuccess = async () =>
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: list(data?.physiotherapist),
      }),
      queryClient.invalidateQueries({
        queryKey: check(data?.physiotherapist),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysPhysiotherapistProfileData.full(
          data?.physiotherapist,
        ),
      }),
    ]);

  const { mutate: deleteReview, isLoading: isRemoveReviewLoading } =
    useMutation({
      mutationFn: removeReview,
      onSuccess: onDeleteSuccess,
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    });

  const userReviewMenuButtons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: "T00724",
        onPress: () => handleCloseMenuModal(navigateToEdit),
        mode: "outlined",
        loading: false,
      },
      {
        label: "T00725",
        onPress: () => handleCloseMenuModal(() => deleteReview(reviewId)),
        mode: "outlined",
        loading: isRemoveReviewLoading,
      },
      {
        label: "T00726",
        onPress: () => handleCloseMenuModal(() => setModalReportVisible(true)),
        loading: false,
      },
    ],
    [deleteReview, isRemoveReviewLoading, navigateToEdit, reviewId],
  );
  return (
    <View>
      {!isFetched ? (
        <ActivityIndicator />
      ) : isError ? (
        <FetchError action={refetch} coverScreen={false} />
      ) : (
        <View style={globalStyles.gapMedium}>
          <CardWithPhotoAndDate
            userId={data?.patient}
            userName={data?.patientName}
            createdAt={data && getDate(data.createdAt)}
            onMenuPress={() => setModalMenuVisible(true)}
            rating={
              <RatingStars
                rating={data.score}
                disableNumberRating={disableNumberRating}
              />
            }
          />
          {data?.review && <Text variant="bodyMedium">{data.review}</Text>}
        </View>
      )}
      {disableReply ? null : (
        <View
          style={[
            responseContainer,
            {
              borderLeftWidth: isResponse ? 2 : 0,
            },
          ]}>
          {isResponse ? (
            <RatingAnswer
              answer={data.response[0]}
              reviewId={reviewId}
              isMyAnswer={id === data?.physiotherapist}
              onDotsPress={() => setAnswerActionsSheetVisible(true)}
            />
          ) : isPhysiotherapist ? (
            <PrimaryButton
              mode="outlined"
              label="T00418"
              onPress={() => setAddOrEditAnswerSheetVisible(true)}
              style={responseButton}
            />
          ) : null}
        </View>
      )}
      {!isLast && (
        <Divider
          bold
          style={{
            marginVertical: spacing24,
          }}
        />
      )}
      <BottomModalWithButton
        modalVisible={modalMenuVisible}
        setModalVisible={setModalMenuVisible}
        buttons={userReviewMenuButtons.filter(button =>
          isMyOwn && !isPhysiotherapist
            ? button.label !== "T00725"
            : button.label === "T00726",
        )}
      />
      <BottomModalContainer
        modalVisible={modalReportVisible}
        setModalVisible={setModalReportVisible}>
        <RatingReportForm
          onSuccessCallback={() => {
            setModalReportVisible(false);
            displaySnackbar && setTimeout(displaySnackbar, 500);
          }}
          reportedId={reviewId}
          isReview
        />
      </BottomModalContainer>
      <BottomModalContainer
        modalVisible={answerActionsSheetVisible}
        setModalVisible={setAnswerActionsSheetVisible}>
        <View style={sheetContentContainer}>
          {isPhysiotherapist ? (
            <RatingAnswerSheetContentPhysio
              id={data?.response[0]}
              reviewId={reviewId}
              onEditPress={onEditPress}
              onDeletePress={() => setAnswerActionsSheetVisible(false)}
            />
          ) : (
            <PrimaryButton label="T00734" onPress={onReportPress} />
          )}
        </View>
      </BottomModalContainer>
      <BottomModalContainer
        modalVisible={addOrEditAnswerSheetVisible}
        setModalVisible={setAddOrEditAnswerSheetVisible}>
        <AddOrEditAnswerForm
          onMutateCallback={() => setAddOrEditAnswerSheetVisible(false)}
          reviewId={reviewId}
          answer={data?.response[0]}
        />
      </BottomModalContainer>
      <BottomModalContainer
        modalVisible={reportAnswerSheetVisible}
        setModalVisible={setReportAnswerSheetVisible}>
        <RatingReportForm
          reportedId={data?.response[0]}
          onSuccessCallback={() => {
            setReportAnswerSheetVisible(false);
            displaySnackbar && setTimeout(displaySnackbar, 500);
          }}
        />
      </BottomModalContainer>
    </View>
  );
};

export default Rating;

const styles = StyleSheet.create({
  responseContainer: {
    borderColor: palettes.neutralVariant["80"],
    marginTop: spacing16,
  },
  responseButton: { alignSelf: "flex-end" },
  sheetContentContainer: {
    marginTop: spacing16,
  },
});
