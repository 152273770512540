import { SvgAssistantAvatar } from "@assets/svg";
import Avatar from "@components/Avatar";
import { PublicUserProfile } from "@contexts/AuthContext/user.types";
import { globalStyles } from "@styles/global";
import { spacing4 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { FC, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, Card as RNPCard, Text } from "react-native-paper";
import StatusComponent from "../Status/StatusComponent";
import { FetchError } from "../errors";
import { CardProps } from "./Card.types";

const Card: FC<CardProps<PublicUserProfile>> = ({
  initialData,
  mode,
  elevation,
  subtitle,
  status,
  flat = false,
  isLoading,
  isError,
  isFetched,
  refetch,
  subtitles,
  isAssistantCard = false,
  ...props
}) => {
  const {
    bottomGap,
    containerOutlined,
    flat: styleFlat,
    itemWithSubtitlesContainer,
  } = styles;

  const cardTitle = (
    <Text variant="titleMedium">{`${initialData?.firstName} ${initialData?.lastName}`}</Text>
  );

  const cardSubtitle = useMemo(
    () => (
      <View>
        {subtitle ? (
          typeof subtitle === "string" ? (
            <Text variant="bodyMedium" style={status && bottomGap}>
              {subtitle}
            </Text>
          ) : (
            subtitle
          )
        ) : null}
        {status ? <StatusComponent status={status} /> : null}
      </View>
    ),
    [bottomGap, status, subtitle],
  );

  const avatarProps = useMemo(
    () => ({
      firstName: initialData?.firstName,
      userId: initialData?.id,
      image: initialData?.avatar,
    }),
    [initialData?.avatar, initialData?.firstName, initialData?.id],
  );

  const avatar = useMemo(
    () =>
      isAssistantCard ? <SvgAssistantAvatar /> : <Avatar {...avatarProps} />,
    [avatarProps, isAssistantCard],
  );

  const subtitlesList = useMemo(
    () =>
      subtitles?.length ? (
        subtitles.map((e, i) => (
          <Text key={`subtitle-${i}-${e}`} variant="bodyMedium">
            {e}
          </Text>
        ))
      ) : subtitle || status ? (
        <Text variant="bodyMedium">{cardSubtitle}</Text>
      ) : null,
    [cardSubtitle, status, subtitle, subtitles],
  );

  return (
    <RNPCard
      elevation={elevation as never}
      mode={mode}
      style={[mode === "outlined" && containerOutlined, flat && styleFlat]}
      {...props}>
      {isError || !isFetched || isLoading ? (
        <RNPCard.Content>
          {isError ? (
            <FetchError action={refetch} coverScreen={false} />
          ) : (
            <ActivityIndicator />
          )}
        </RNPCard.Content>
      ) : (
        <RNPCard.Content>
          <View style={itemWithSubtitlesContainer}>
            {avatar}
            <View style={[globalStyles.gapSmall, { flex: 1 }]}>
              <Text variant="titleMedium">{cardTitle}</Text>
              {subtitlesList}
            </View>
          </View>
        </RNPCard.Content>
      )}
    </RNPCard>
  );
};

export default Card;

const styles = StyleSheet.create({
  containerOutlined: {
    borderColor: theme.colors.outlineVariant,
  },
  bottomGap: { marginBottom: spacing4 },
  flat: {
    backgroundColor: theme.colors.background,
  },
  itemWithSubtitlesContainer: {
    flexDirection: "row",
    alignItems: "center",
    ...globalStyles.gapLarge,
  },
});
