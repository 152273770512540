import { FC } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { SearchbarButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { useTranslation } from "react-i18next";
import { TransKey } from "@globalTypes/i18next";

type Props = {
  title?: TransKey;
  onPress: () => void;
  searchLabel: TransKey;
};

export const SectionWithSearchbar: FC<Props> = ({
  title,
  onPress,
  searchLabel,
}) => {
  const { t } = useTranslation();

  return (
    <View style={globalStyles.gapLarge}>
      {title && <Text variant="titleMedium">{t(title)}:</Text>}
      <SearchbarButton label={searchLabel} onPress={onPress} />
    </View>
  );
};
