import { checkFutureSlots } from "@services/ApiService/users";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { queryKeysPhysiotherapist } from "@screens/Appointments/queryKeysAppointments";
import { formatDateForApi } from "@utils/date";
import { useErrors } from "@hooks/useErrors";
import { useUserDetails } from "..";

export const useCheckFutureSlots = () => {
  const [hasFutureSlots, setHasFutureSlots] = useState(false);
  const { setErrorsFromResponse } = useErrors();
  const { id } = useUserDetails();
  const dateFrom = formatDateForApi(new Date());
  const { isSuccess } = useQuery({
    queryKey: queryKeysPhysiotherapist.availabilityAnyFutureSlots({
      physioId: id,
      dateFrom,
    }),
    queryFn: async () => await checkFutureSlots(dateFrom),
    onError: ({ response }: AxiosError) => {
      if (response.status === 404) return setHasFutureSlots(false);
      setErrorsFromResponse(response);
    },
    retry: false,
  });
  useEffect(() => {
    isSuccess && setHasFutureSlots(true);
  }, [isSuccess]);
  return { hasFutureSlots };
};
