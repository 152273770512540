import { ProgressCircle } from "@components/ProgressCircle/ProgressCircle";
import { FetchError } from "@components/errors";
import {
  getPatientVerificationProgress,
  getRehabVerificationProgress,
} from "@services/ApiService/verifications";
import { useQuery } from "@tanstack/react-query";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { queryKeysPatientCompletion } from "./PatientCompletion/queryKeysPatientCompletion";
import { useTranslation } from "react-i18next";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { queryKeysVerficiations } from "./PhysiotherapistVerification/queryKeysPhysiotherapistVerification";

export const ProfileCompletionInfo = () => {
  const { isPhysiotherapist } = useUserDetails();
  const { t } = useTranslation();

  const {
    data: dataA,
    isLoading: isLoadingA,
    isError: isErrorA,
    refetch: refetchA,
  } = useQuery({
    queryKey: queryKeysVerficiations.verificationProgressRehabInSubscreens(),
    queryFn: getRehabVerificationProgress,
    enabled: isPhysiotherapist,
  });
  const {
    data: dataB,
    isLoading: isLoadingB,
    isError: isErrorB,
    refetch: refetchB,
  } = useQuery({
    queryKey:
      queryKeysPatientCompletion.verificationProgressPatientInSubscreens(),
    queryFn: getPatientVerificationProgress,
    enabled: !isPhysiotherapist,
  });

  const isError = isPhysiotherapist ? isErrorA : isErrorB;
  const refetch = () => (isPhysiotherapist ? refetchA() : refetchB());
  const isLoading = isPhysiotherapist ? isLoadingA : isLoadingB;
  const data = isPhysiotherapist ? dataA : dataB;

  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <View style={[styles.container, globalStyles.gapMedium]}>
      <Text variant="titleMedium">{t("T00204")}</Text>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <ProgressCircle percent={data?.progressValue || 0} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: spacing8,
  },
});
