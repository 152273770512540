import { FC } from "react";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import QRCode from "react-native-qrcode-svg";
import { PrimaryButton } from "@components/buttons";
import { View } from "react-native";
import { globalStyles } from "@styles/global";
import { Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";
import { useInvitationCode } from "@hooks/index";

type QrCodeBottomSheetProps = {
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
};

const logo = require("@assets/images/small_logo.png") as ImageSourcePropType;

const QrCodeBottomSheet: FC<QrCodeBottomSheetProps> = ({
  modalVisible,
  setModalVisible,
}) => {
  const { invitationUrl } = useInvitationCode();
  const { t } = useTranslation();
  const { gapLarge, scrollContainer } = globalStyles;
  return (
    <BottomModalContainer
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      scrollableContent
      disablePanHandlers>
      <View style={[scrollContainer, gapLarge]}>
        <Text variant="bodySmall" style={{ textAlign: "center" }}>
          {t("T01389")}
        </Text>
        <View style={{ alignSelf: "center" }}>
          <QRCode value={invitationUrl} size={240} logo={logo} />
        </View>
        <PrimaryButton onPress={() => setModalVisible(false)} label="T00540" />
      </View>
    </BottomModalContainer>
  );
};

export default QrCodeBottomSheet;
