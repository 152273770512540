import { isEqual } from "lodash";
import { AppointmentSlots } from "@screens/Calendar/availability.types";
import { addDays, set } from "date-fns";
import { VisitDatePickerOptions } from "./VisitDatePicker";
import { GetPhysiotherapistParams } from "../filters.types";
import { formatDateForApi } from "@utils/date";

export const getDateWithHour00 = () => {
  return set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
};

export const getDateWithHour2345 = () => {
  return set(new Date(), {
    hours: 23,
    minutes: 45,
    seconds: 0,
    milliseconds: 0,
  });
};

export const setHourAndMinute = (
  hours: number,
  minutes: number,
  date?: Date,
) => {
  const tempDate = date ? new Date(date) : new Date();
  tempDate.setHours(hours);
  tempDate.setMinutes(minutes);
  return tempDate;
};

export const defaultFilters: GetPhysiotherapistParams = {
  available: true,
  availabilityType: AppointmentSlots.ONLINE_OR_STATIONARY,
  visitDatePicker: VisitDatePickerOptions.ASAP,
  hourFrom: getDateWithHour00(),
  hourTo: getDateWithHour2345(),
};

export const checkIsDefault = (filters: GetPhysiotherapistParams) => {
  const { country, serviceName, spokenLanguage, ...rest } = filters;
  if (!country && !serviceName.length && !spokenLanguage.length) {
    const selectedBaseFilters = { ...rest };
    return isEqual(selectedBaseFilters, defaultFilters);
  }
  return false;
};

export const getDateFromAndDateToFromSelectedVisitDateType = (
  type: VisitDatePickerOptions,
  selectedDate: Date,
) => {
  if (type === VisitDatePickerOptions.ASAP) return {};
  if (type === VisitDatePickerOptions.SELECTED_DAY) {
    const date = selectedDate || new Date();
    const dateFromWithTime = setHourAndMinute(0, 0, date);
    const dateToWithTime = setHourAndMinute(23, 45, date);
    return {
      dateFrom: formatDateForApi(dateFromWithTime, "iso"),
      dateTo: formatDateForApi(dateToWithTime, "iso"),
    };
  }
  let daysToAdd = 3;
  if (type === VisitDatePickerOptions.SEVEN_DAYS_AHEAD) {
    daysToAdd = 7;
  }
  const dateToWithTime = setHourAndMinute(23, 45, new Date());
  return {
    dateTo: formatDateForApi(addDays(dateToWithTime, daysToAdd), "iso"),
  };
};
