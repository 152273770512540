import { AbsoluteBlurredFooter } from "@components/Footers";
import { FC, useState } from "react";
import TutorialTextSectionsList from "./TutorialTextSectionsList";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { isWeb } from "@utils/constants";

type InviteToTheAppBottomSheetProps = {
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
};

const InviteToTheAppBottomSheet: FC<InviteToTheAppBottomSheetProps> = ({
  modalVisible,
  setModalVisible,
}) => {
  const [footerHeight, setFooterHeight] = useState(0);
  return (
    <BottomModalContainer
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      scrollableContent
      disablePanHandlers>
      <>
        <TutorialTextSectionsList
          paddingBottom={isWeb ? footerHeight : footerHeight / 2}
        />
        <AbsoluteBlurredFooter
          onPress={() => setModalVisible(false)}
          title="T00540"
          onLayout={height => setFooterHeight(height)}
        />
      </>
    </BottomModalContainer>
  );
};

export default InviteToTheAppBottomSheet;
