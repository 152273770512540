import { showProfileVerificationSnackbar } from "@components/snackbars";
import { useInvalidateProfileDetails } from "@hooks/index";
import {
  queryKeysServices,
  queryKeysVerificationServices,
} from "@screens/Common/queryKeysServices";
import { queryKeysVerficiations } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/queryKeysPhysiotherapistVerification";
import {
  PricesType,
  ServiceType,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { ServiceDataType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { setService } from "@services/ApiService/verifications";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { useErrors } from "@hooks/useErrors";
import { deleteService } from "@services/ApiService/verifications";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

type UseServiceMutationOptions = {
  serviceType: ServiceType;
  prices: PricesType[];
  hideBottomSheet: () => void;
  service?: ServiceDataType;
  slots?: number;
  selectedSlots?: number;
};

export const useServiceMutation = ({
  serviceType,
  prices,
  hideBottomSheet,
  service,
  slots,
  selectedSlots,
}: UseServiceMutationOptions) => {
  const { invalidateProfileDetails } = useInvalidateProfileDetails();
  const queryClient = useQueryClient();
  const { setErrorsFromResponse } = useErrors();
  const { t } = useTranslation();

  const handleServiceMutationSuccess = useCallback(
    async ({
      slots,
      hideBottomSheet,
      service,
    }: {
      slots: number;
      hideBottomSheet: () => void;
      service?: ServiceDataType;
    }) => {
      service &&
        (await setService({
          serviceType,
          serviceItem: service.serviceItem.id,
          slots,
          prices,
        }));
      await Promise.all([
        invalidateProfileDetails(),
        queryClient.invalidateQueries(queryKeysServices.all),
        queryClient.invalidateQueries(queryKeysVerificationServices.all),
        queryClient.invalidateQueries(
          queryKeysVerficiations.verificationProgressRehabInSubscreens(),
        ),
      ]);
      hideBottomSheet();
      showProfileVerificationSnackbar(t("T01311"));
    },
    [invalidateProfileDetails, prices, queryClient, serviceType, t],
  );

  const { mutate, isLoading: isMutationLoading } = useMutation({
    mutationFn: service ? () => deleteService(service.id) : setService,
    onSuccess: () =>
      handleServiceMutationSuccess({
        service,
        slots: slots || selectedSlots + 1,
        hideBottomSheet,
      }),
    onError: ({ response }: AxiosError) => {
      setErrorsFromResponse(response);
      hideBottomSheet();
    },
  });

  return { mutate, isMutationLoading };
};
