import { spacing12, spacing8 } from "@styles/spacing";
import { FC } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Checkbox, IconButton, Text } from "react-native-paper";

type Props = {
  title: string;
  checked: boolean;
  onPress: () => void;
  isPriority?: boolean;
};

export const SearchListItem: FC<Props> = ({
  title,
  checked,
  onPress,
  isPriority,
}) => {
  const { container, icon, item } = style;
  return (
    <TouchableOpacity onPress={onPress} style={container}>
      <View style={item}>
        {isPriority && <IconButton icon="star-outline" style={icon} />}
        <Text>{title}</Text>
      </View>
      <Checkbox.Android
        status={checked ? "checked" : "unchecked"}
        style={{ marginRight: spacing8 }}
      />
    </TouchableOpacity>
  );
};

const style = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: spacing8,
  },
  item: { flexDirection: "row", alignItems: "center" },
  icon: { padding: 0, margin: 0, marginLeft: -spacing12 },
});
