import { ScrollView, View } from "react-native";
import { Chip, Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { globalStyles } from "@styles/global";
import { Dispatch, FC, SetStateAction } from "react";

type Props = {
  setSelectedFilter: Dispatch<SetStateAction<string>>;
  selectedFilter: string;
  data: { title: string; value: string }[];
  selectedChipMode?: "flat" | "outlined";
};

export const ScrollSectionWithChips: FC<Props> = ({
  setSelectedFilter,
  selectedFilter,
  data,
  selectedChipMode = "outlined",
}) => {
  const { t } = useTranslation();

  const { gapMedium } = globalStyles;

  const onPressChip = (value: string) =>
    setSelectedFilter(prevState => (prevState === value ? null : value));

  return (
    <View style={gapMedium}>
      <Text variant="titleMedium">{t("T00818")}</Text>
      <ScrollView
        horizontal
        contentContainerStyle={gapMedium}
        showsHorizontalScrollIndicator={false}>
        {data.map(({ title, value }, i) => (
          <Chip
            key={`chip-sorting-${value}-${i}`}
            mode={selectedFilter === value ? selectedChipMode : "outlined"}
            icon={selectedFilter === value ? "check" : null}
            onPress={() => onPressChip(value)}>
            <Text>{title}</Text>
          </Chip>
        ))}
      </ScrollView>
    </View>
  );
};
