import { useErrors } from "@hooks/useErrors";
import { usePatientAbsentSwitch } from "@hooks/appointments/usePatientAbsentSwitch";
import { Appointment } from "@screens/Appointments/appointment.types";
import { palettes } from "@styles/colors";
import { spacing16, spacing8 } from "@styles/spacing";
import { useMutation } from "@tanstack/react-query";
import { showAlert } from "@utils/showAlert";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Switch, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import { showSnackbar } from "@utils/snackbarHelper";
import { setAppointmentDetails } from "@api/appointments";

type Props = {
  switchOn: boolean;
  appointment: Appointment;
  setSwitchOn: Dispatch<SetStateAction<boolean>>;
};

export const PatientAbsentSection = ({
  switchOn,
  setSwitchOn,
  appointment,
}: Props) => {
  const [thumbColor, setThumbColor] = useState(palettes.neutralVariant[50]);

  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const { alertText } = usePatientAbsentSwitch({ appointment });
  useEffect(() => {
    setThumbColor(() =>
      switchOn ? palettes.primary[100] : palettes.neutralVariant[50],
    );
  }, [switchOn]);

  const { container, switchStyle } = styles;

  const onError = ({ response }: AxiosError) => {
    setErrorsFromResponse(response);
    setThumbColor(
      switchOn ? palettes.primary[100] : palettes.neutralVariant[50],
    );
    setSwitchOn(appointment?.patientAbsent);
  };

  const { mutate } = useMutation({
    mutationFn: async (patientAbsent: boolean) =>
      await setAppointmentDetails(appointment?.id, { patientAbsent }),
    onSuccess: ({ data: { patientAbsent } }) => {
      patientAbsent && showSnackbar({ message: t("T01409") });
    },
    onError,
  });

  const onChangeValue = (value: boolean) => {
    {
      setThumbColor(
        value ? palettes.primary[100] : palettes.neutralVariant[50],
      );
      setSwitchOn(prev => !prev);
      mutate(value);
    }
  };

  return (
    <>
      <View style={container}>
        <Text variant="bodyLarge">{t("T01151")}</Text>
        <TouchableOpacity onPress={() => alertText && showAlert(t(alertText))}>
          <Switch
            value={switchOn}
            thumbColor={thumbColor}
            trackColor={{
              false: palettes.neutralVariant[50],
              true: palettes.error[35],
            }}
            style={[
              switchStyle,
              {
                borderColor: switchOn
                  ? palettes.error[35]
                  : palettes.neutralVariant[50],
              },
            ]}
            onValueChange={onChangeValue}
            disabled={!!alertText}
          />
        </TouchableOpacity>
      </View>
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: spacing16,
  },
  switchStyle: {
    borderWidth: 1,
    borderRadius: spacing16,
    marginRight: spacing8,
  },
});
