import { NotificationSetting } from "@globalTypes/messagesNotificationSetting";
import { updateNotificationMainSettings } from "@services/ApiService/appSettings";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useErrors } from "../useErrors";

type UseUpdateNotificationMainOptions = {
  settingId: number;
  onMutate?: (val: boolean) => void;
  onSuccess: () => void;
};

export const useUpdateNotificationMain = ({
  onMutate,
  onSuccess,
  settingId,
}: UseUpdateNotificationMainOptions) => {
  const { setErrorsFromResponse } = useErrors();
  const { mutate: updateNotificationMain } = useMutation({
    mutationFn: async (params: NotificationSetting) => {
      onMutate?.(params.notificationsActive);
      await updateNotificationMainSettings(params);
    },
    onSuccess,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const update = (active: boolean) =>
    updateNotificationMain({
      id: settingId,
      notificationsActive: active,
    });

  return { update };
};
