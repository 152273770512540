import { FC, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-paper";
import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { exportRehabilitationStatistics } from "@api/common";
import { spacing16 } from "@styles/spacing";
import Snackbar from "@components/Snackbar/Snackbar";
import { useErrors } from "@hooks/useErrors";
import { AxiosError } from "axios";

type ExportStatisticsProps = {
  patientId?: number;
};

const ExportStatistics: FC<ExportStatisticsProps> = ({ patientId }) => {
  const [enableExport, setEnableExport] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const { setErrorsFromResponse } = useErrors();
  const { isFetching, isSuccess } = useQuery({
    queryKey: [],
    queryFn: async () => await exportRehabilitationStatistics(patientId),
    enabled: enableExport,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  useEffect(() => {
    isSuccess && setSnackbarVisible(true);
  }, [isSuccess]);

  const hideSnackbar = () => setSnackbarVisible(false);
  const { container } = styles;
  return (
    <View style={container}>
      <PrimaryButton
        label="T00730"
        mode="text"
        onPress={() => setEnableExport(true)}
        loading={isFetching}
      />
      <Divider bold />
      <Snackbar
        visible={snackbarVisible}
        onDismiss={hideSnackbar}
        onIconPress={hideSnackbar}
        text="T00731"
      />
    </View>
  );
};

export default ExportStatistics;

const styles = StyleSheet.create({
  container: { ...globalStyles.gapLarge, marginTop: spacing16 },
});
