import { formatDateForApi } from "@utils/date";
import {
  addDays,
  differenceInCalendarDays,
  endOfMonth,
  getDaysInMonth,
} from "date-fns";
import {
  AvailableAppointmentsColumns,
  AvailableAppointments,
} from "./PhysioAvailability.types";
import { MutableRefObject } from "react";
import { FlatList } from "react-native";

export const fillSectionWithApiData = (
  sectionData: AvailableAppointmentsColumns,
  apiData: AvailableAppointments,
) => {
  if (!sectionData?.length) return;
  const filledSection: AvailableAppointmentsColumns = [];
  sectionData.forEach((data, index) => {
    if (apiData.map(d => d.date).includes(data.date)) {
      filledSection[index] = {
        ...apiData.find(apiItem => apiItem.date === data.date),
        wasFilled: true,
      };
    } else
      filledSection[index] = { date: data.date, hours: [], wasFilled: true };
  });
  return filledSection;
};

export const getCalendarRange = () => {
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();
  const lastDayOfMonth = endOfMonth(today);
  const daysRemainingThisMonth =
    differenceInCalendarDays(lastDayOfMonth, today) + 1;
  const daysInNextMonth = getDaysInMonth(new Date(year, month + 1));
  const daysInMonthAfterNextMonth = getDaysInMonth(new Date(year, month + 2));
  let calendarRange =
    daysRemainingThisMonth + daysInNextMonth + daysInMonthAfterNextMonth;
  while (calendarRange % 3) calendarRange++;
  return calendarRange;
};

export const generateEmptySections = (range: number) => {
  const sections: AvailableAppointmentsColumns[] = [];
  for (let i = 0; i < range; i += 3) {
    const singleSection: AvailableAppointmentsColumns = [];
    for (let j = 0; j < 3; j++) {
      singleSection.push({
        date: formatDateForApi(addDays(new Date(), i + j)),
        hours: [],
      });
    }
    sections.push(singleSection);
  }
  return sections;
};

export const keyExtractor = (item: AvailableAppointments[], index: number) =>
  `calendar-section-${index}-${item?.[0]?.[0]?.date}`;

export const scrollToIndex = (
  index: number,
  ref: MutableRefObject<FlatList<unknown>>,
  range?: number,
) => {
  if (range && index > range) return;
  if ((index !== 0 && !index) || index < 0) return;
  ref.current?.scrollToIndex({ index });
};

export const isDateInRange = (date: Date, range: number) => {
  const maxDate = addDays(new Date(), range);
  if (differenceInCalendarDays(date, maxDate) > 0) return false;
  return true;
};
