import { NavigationProp, useNavigation } from "@react-navigation/native";
import { AxiosResponse } from "axios";
import { useCallback, useState } from "react";
import { FieldError, Message } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RootTabsParamList } from "@navigators/navigation.types";
import { showAlert } from "../utils";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { TranslationKey } from "@globalTypes/i18next";

type FormErrorResponse<T = { [key: string]: string | string[] }> = {
  [key in keyof Partial<T>]: string[];
} & { detail?: string; code?: string; file?: string };

type UseErrors<T> = {
  setErrorsFromResponse: (response: AxiosResponse, title?: string) => void;
  errors: { [key in keyof Partial<T>]: FieldError[] };
  generalError?: string;
  clearErrors: () => void;
};

type UseErrorsOptions = {
  showModalOnGeneralError?: boolean;
  undefinedError?: () => void;
};

export const useErrors = <T = { [key: string]: string }>(
  options: UseErrorsOptions = { showModalOnGeneralError: true },
): UseErrors<T> => {
  const [errors, setErrors] = useState<UseErrors<T>["errors"]>(
    {} as UseErrors<T>["errors"],
  );
  const [generalError, setGeneralError] = useState<string>();
  const { showModalOnGeneralError, undefinedError } = options;
  const { t } = useTranslation();

  const { navigate } = useNavigation<NavigationProp<RootTabsParamList>>();

  const setErrorsFromResponse = (
    { data, status }: AxiosResponse<FormErrorResponse<T>>,
    title?: TranslationKey,
  ) => {
    if (status === 500)
      return undefinedError ? undefinedError() : showAlert(t("T00042"));

    if (typeof data?.detail === "string") {
      setGeneralError(data.detail);
    }
    const errorData = data?.detail || (data[0] as string) || data?.file;

    if (errorData && showModalOnGeneralError)
      showAlertWithCustomButtons({
        title: title ? t(title) : t("T01422"),
        message: typeof errorData === "string" ? errorData : errorData[0],
        confirmButton: {
          text: t("T00163"),
          onPress:
            status === 403
              ? () => navigate("HomeStack", { screen: "HomeScreen" })
              : null,
        },
      });

    setErrors(
      Object.keys(data).reduce((acc, field) => {
        const fieldErrors: Partial<FieldError>[] = Array.isArray(data[field])
          ? (data[field] as Message[]).map(message => ({ message }))
          : [{ message: data[field] as Message }];
        return { ...acc, [field]: fieldErrors };
      }, {}) as UseErrors<T>["errors"],
    );
  };

  const clearErrors = useCallback(() => {
    setErrors({} as UseErrors<T>["errors"]);
    setGeneralError(undefined);
  }, []);

  return { errors, setErrorsFromResponse, generalError, clearErrors };
};
