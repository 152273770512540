import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { SvgErrorOutline } from "@assets/svg";
import { Trans, useTranslation } from "react-i18next";
import { globalStyles } from "@styles/global";

const AppointmentPaymentError: FC = () => {
  const { t } = useTranslation();
  const { errorContainer, errorText } = styles;
  return (
    <View style={errorContainer}>
      <SvgErrorOutline width={56} height={56} />
      <Text variant="titleLarge">{t("T01148")}</Text>
      <Text variant="bodyMedium" style={errorText}>
        <Trans
          i18nKey="T01149"
          components={{
            1: (
              <Text style={{ fontWeight: "bold" }}>
                make the payment within 30 minutes
              </Text>
            ),
          }}
        />
      </Text>
    </View>
  );
};

export default AppointmentPaymentError;

const styles = StyleSheet.create({
  errorContainer: {
    ...globalStyles.gapLarge,
    justifyContent: "center",
    alignItems: "center",
  },
  errorText: { textAlign: "center" },
});
