import { QueryCache } from "@tanstack/react-query";
import { config } from "@utils/config";
import { convertTokenToUserData } from "@utils/convertTokenToUserData";
import { getClientIdForGA } from "@utils/getClientIdForGA";
import { restartApp } from "@utils/restart";
import { showAlert } from "@utils/showAlert";
import { storageManager } from "@utils/storageManager";
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { getCalendars, getLocales } from "expo-localization";
import { camelizeKeys, decamelizeKeys } from "humps";
import { lowerCase } from "lodash";
import { Platform } from "react-native";
import { refreshToken } from "./auth";
import { endpoints } from "./endpoints";
import { fallbackLanguage } from "@utils/constants";

export const api = axios.create({
  baseURL: `${config.EXPO_PUBLIC_API_BASE_URL}/api/`,
  responseType: "json",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async config => {
    const newConfig = { ...config };
    const accessToken = await storageManager.getItem("accessToken");
    // I added a check to see if there is a function due to crashing on android:
    const languageArr = await Promise.resolve(getLocales?.());
    const languageCode = languageArr?.[0]?.languageCode || fallbackLanguage;
    const currencyCode = languageArr?.[0]?.currencyCode || "PLN";
    const timeZone = getCalendars?.()[0]?.timeZone || "Europe/Warsaw";

    if (accessToken) {
      const tokenData = convertTokenToUserData(accessToken);
      config.headers.Authorization = "Bearer " + accessToken;
      if (!config.headers.Language)
        config.headers.Language = tokenData?.language || languageCode;
      if (!config.headers.Currency)
        config.headers.Currency = tokenData?.currency || currencyCode;
      if (!config.headers.Timezone)
        config.headers.Timezone = tokenData?.timezone || timeZone;
    }
    if (!accessToken) {
      config.headers.Language = languageCode;
      config.headers.Currency = currencyCode;
      config.headers.Timezone = timeZone;
    }
    config.headers.gaClientId = await getClientIdForGA();
    newConfig.headers.Platform = Platform.OS;

    if (newConfig.headers["Content-Type"] === "multipart/form-data")
      return newConfig;
    if (config.params) {
      newConfig.params = decamelizeKeys(config.params, {});
    }
    if (config.data) {
      newConfig.data = decamelizeKeys(config.data);
    }
    return newConfig;
  },
  async error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (
      response.data &&
      response.headers["content-type"] === "application/json"
    ) {
      response.data = camelizeKeys(
        response.data,
        function (key, convert, options) {
          if (/^\d{4}-\d{2}-\d{2}$/.test(key)) {
            return key;
          }
          return /^[A-Z0-9_]+$/.test(key)
            ? lowerCase(key)
            : convert(key, options);
        },
      );
    }
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest: InternalAxiosRequestConfig<unknown> & {
      _retry?: boolean;
    } = error.config;
    if (error?.response) {
      const { status, config } = error.response;

      if (config.url === endpoints.REFRESH) {
        await storageManager.clear();
      } else if (
        (status === 401 || status === 402 || status === 403) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const oldToken = await storageManager.getItem("refreshToken");
        if (!oldToken) return Promise.reject(error);
        try {
          await refreshToken(oldToken);
        } catch {
          /**/
        }
        return api(originalRequest);
      }
    }

    return Promise.reject(error);
  },
);

export const queryCache = new QueryCache({
  onError: (error: AxiosError) => {
    if (error?.response?.status === 503) {
      showAlert("Server error", restartApp);
    }
  },
});
