import { getPhysiotherapistFullData } from "@api/users";
import DropdownPicker, {
  MultiDropdownsType,
  PickerItem,
} from "@components/DropdownPicker/DropdownPicker";
import { FetchError } from "@components/errors";
import { queryKeysPhysiotherapistProfileData } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { useQuery } from "@tanstack/react-query";
import { capitalize } from "lodash";
import { FC, useEffect, useState } from "react";
import { ActivityIndicator } from "react-native-paper";

type Props = {
  physioId: number;
  onSelectItem: (item: PickerItem) => void;
} & MultiDropdownsType;

const PhysioLanguageDropdownPicker: FC<Props> = props => {
  const { onSelectItem, physioId } = props;
  const [items, setItems] = useState<PickerItem[]>([]);
  const [defaultLanguage, setDefaultLanguage] = useState<PickerItem>();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapistProfileData.full(physioId),
    queryFn: async () => await getPhysiotherapistFullData(physioId),
  });

  useEffect(() => {
    if (items?.length) {
      const selectedLanguage =
        items.find(item => item.value === "pl") || items[0];
      setDefaultLanguage(selectedLanguage);
      onSelectItem(selectedLanguage);
    }
  }, [items, onSelectItem]);

  useEffect(() => {
    if (!isError && !isLoading) {
      const items: PickerItem[] = data?.spokenLanguages?.map(
        ({ languageFull, language }) => ({
          label: capitalize(languageFull),
          value: language,
        }),
      );
      setItems(items);
    }
  }, [data, isError, isLoading]);

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    items?.length > 0 && (
      <DropdownPicker
        dropdownLabelText="T00640"
        items={items}
        defaultValue={defaultLanguage?.value}
        {...props}
      />
    )
  );
};

export default PhysioLanguageDropdownPicker;
