import { AppbarTitleWithBackAction } from "@components/Appbar";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { capitalize } from "lodash";
import { FC, PropsWithChildren, useEffect } from "react";
import { ScrollView } from "react-native";
import { Text } from "react-native-paper";

const PhysiotherapistInformationDetails: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      RootStackParamList,
      "PhysiotherapistInformationDetails"
    >
  >
> = ({ route, navigation: { setOptions } }) => {
  const { content, title } = route.params;
  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack } }) => (
        <AppbarTitleWithBackAction title={title} onClose={goBack} />
      ),
    });
  }, [setOptions, title]);
  const { gapMedium, scrollContainer } = globalStyles;
  return (
    <ScrollView contentContainerStyle={[gapMedium, scrollContainer]}>
      {typeof content === "string" ? (
        <Text>{content}</Text>
      ) : Array.isArray(content) ? (
        content.map((e, i) => (
          <Text
            variant="bodyMedium"
            style={{ marginStart: spacing8 }}
            key={`education-${e}-${i}`}>{`\u2022 ${capitalize(e)}`}</Text>
        ))
      ) : null}
    </ScrollView>
  );
};

export default PhysiotherapistInformationDetails;
