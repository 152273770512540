import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { Appointment } from "./appointment.types";
import { ServiceType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { useAuth } from "@contexts/AuthContext/auth";
import { HumanModelType } from "@globalTypes/common.types";

type DataForContextType = Partial<Appointment> & {
  hasDisfunction?: string;
  recordIds?: number[];
  shareUntilDate?: string;
  serviceId?: number;
  serviceType?: ServiceType;
  languageLabel?: string;
} & Partial<HumanModelType>;

type AppointmentContextType = {
  data: DataForContextType;
  updateData: (data: DataForContextType) => void;
  setData: Dispatch<SetStateAction<DataForContextType>>;
};

const AppointmentContext = createContext<AppointmentContextType>(
  {} as AppointmentContextType,
);

const AppointmentContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    user: { gender },
  } = useAuth();
  const [data, setData] = useState<DataForContextType>({
    humanGeneral: [],
    humanBones: [],
    humanMuscles: [],
    gender,
  });

  const updateData = useCallback((data: DataForContextType) => {
    setData(previousData => ({ ...previousData, ...data }));
  }, []);

  const contextValue = {
    data,
    updateData,
    setData,
  };

  return (
    <AppointmentContext.Provider value={contextValue}>
      {children}
    </AppointmentContext.Provider>
  );
};
export { AppointmentContext, AppointmentContextProvider };
