import { FC } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { globalStyles } from "@styles/global";

type Props = {
  title: string;
  desc: string;
};

export const SectionTitleWithInfo: FC<Props> = ({ title, desc }) => (
  <View style={globalStyles.gapMedium}>
    <Text variant="titleMedium">{title}</Text>
    <Text variant="bodyMedium">{desc}</Text>
  </View>
);
