import { View } from "react-native";
import { Text } from "react-native-paper";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { globalStyles } from "@styles/global";
import { useTranslation } from "react-i18next";
import { useAuth } from "@contexts/AuthContext/auth";
import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { object, string } from "yup";
import { Control, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validateAge } from "@utils/validators";
import { Input } from "@components/Input";
import { useAppTheme } from "@styles/theme";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { spacing24 } from "@styles/spacing";
import { Calendar, DateArrayType } from "@components/Calendar";
import { PrimaryButton } from "@components/buttons";
import {
  formatDateForApi,
  getDate,
  getDateFromString,
  getInitialDateForBirthdayPicker,
} from "@utils/date";
import { useMutation } from "@tanstack/react-query";
import { updateProfile } from "@services/ApiService/profile";
import { useErrors } from "@hooks/useErrors";
import { AxiosError } from "axios";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import { useInvalidateProfileDetails } from "@hooks/index";
import { showProfileVerificationSnackbar } from "@components/snackbars";

export type PatientUpdateProfileDetails = {
  firstName: string;
  lastName: string;
  birthday: string;
};

export const PatientDetails: FC<
  PropsWithChildren<
    NativeStackScreenProps<ProfileCompletionStackParamList, "PatientDetails">
  >
> = ({ navigation: { goBack } }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { t } = useTranslation();
  const {
    user: { firstName, lastName, birthday, id },
    updateUser,
  } = useAuth();
  const {
    colors: { outline },
  } = useAppTheme();
  const { errors, setErrorsFromResponse } = useErrors();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();

  const { scrollContainer, gapLarge } = globalStyles;

  const stringRule = string().required(t("T00014")).trim();

  const schema = useMemo(
    () =>
      object().shape({
        firstName: stringRule,
        lastName: stringRule,
        birthday: stringRule.test("is18", t("T00013"), validateAge),
      }),
    [t, stringRule],
  );

  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: firstName || "",
      lastName: lastName || "",
      birthday: getDate(birthday) || "",
    },
  });

  const currentFirstName = watch("firstName");
  const currentLastName = watch("lastName");
  const currentBirthday = watch("birthday");

  const isFormChanged = useCallback(
    () =>
      currentFirstName !== firstName ||
      currentLastName !== lastName ||
      currentBirthday !== getDate(birthday),
    [
      currentFirstName,
      currentLastName,
      currentBirthday,
      firstName,
      lastName,
      birthday,
    ],
  );

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: PatientUpdateProfileDetails) => updateProfile(id, data),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async () => {
      updateUser();
      await invalidateProfileDetails();
      goBack();
      showProfileVerificationSnackbar(t("T01311"));
    },
  });

  const onSelectDate = useCallback(
    (data: DateArrayType, dontClose?: boolean) => {
      !dontClose && setModalVisible(false);
      setValue("birthday", getDate(data.date));
    },
    [setValue],
  );
  const onSubmit = (data: PatientUpdateProfileDetails) =>
    mutate({ ...data, birthday: formatDateForApi(data.birthday) });

  return (
    <>
      <KeyboardAwareScrollView
        contentContainerStyle={[scrollContainer, gapLarge]}>
        <Text>{t("T01029")}</Text>
        <Input
          key="firstName"
          name="firstName"
          label="T00718"
          control={control as unknown as Control}
          errors={errors?.["firstName"]}
          autoCapitalize="words"
        />
        <Input
          key="lastName"
          name="lastName"
          label="T00719"
          control={control as unknown as Control}
          errors={errors?.["lastName"]}
          autoCapitalize="words"
        />
        <Input
          key="birthday"
          name="birthday"
          label="T01039"
          control={control as unknown as Control}
          errors={errors?.["birthday"]}
          calendarInput
          onPress={() => setModalVisible(true)}
          borderColor={outline}
        />
        <PrimaryButton
          label="T00164"
          disabled={!isFormChanged() || isLoading}
          loading={isLoading}
          onPress={handleSubmit(onSubmit)}
        />
      </KeyboardAwareScrollView>
      <BottomModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}>
        <View style={[gapLarge, { paddingVertical: spacing24 }]}>
          <Calendar
            initialDates={
              (currentBirthday
                ? [{ date: getDateFromString(currentBirthday) }]
                : [
                    { date: getInitialDateForBirthdayPicker() },
                  ]) as DateArrayType[]
            }
            onSelectDate={onSelectDate}
            yearsOption="pastMinusMinAge"
          />
        </View>
      </BottomModalContainer>
    </>
  );
};
