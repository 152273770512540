import { getDateFromString } from "@utils/date";
import {
  addMonths,
  eachDayOfInterval,
  getDaysInMonth,
  getMonth,
  getYear,
  isEqual,
  parse,
} from "date-fns";
import { BasicChartItemType } from "./BarChartNew";
import { ChartItemType } from "./GroupedStackedBarChart";
import { ApiDataForBarChart } from "./chart.types";
import { StackedBarItemType } from "@components/Charts/StackedBarChart";

const createLabels = (amount: number) =>
  Array(amount)
    .fill("")
    .map((_, index) =>
      index < 9 ? `0${(index + 1).toString()}` : (index + 1).toString(),
    );

export const createDataForChart = (dataFromApi: ApiDataForBarChart) => {
  if (!dataFromApi.labels?.length || !dataFromApi.datasets[0].data.length) {
    return;
  }
  const formattedDate = getDateFromString(dataFromApi.labels[0]);

  const labels = createLabels(getDaysInMonth(formattedDate));
  const labelsToDisplay = labels.map(e => (+e % 2 === 0 ? "" : e.toString()));

  const days = dataFromApi.labels.map(date =>
    parse(date, "yyyy-MM-dd", new Date()).getDate(),
  );

  const data = labels?.map(label => {
    const index = days.indexOf(+label);
    return index !== -1 ? dataFromApi.datasets[0].data[index] : 0;
  });

  return { labelsToDisplay, data };
};

export const checkSelectedMonthAndYear = (
  date: Date,
  isToday = true,
  allowOneMore?: boolean,
) => {
  const currentDate = isToday ? new Date() : new Date(2022, 0);
  const selectedYear = getYear(currentDate);
  const selectedMonth = getMonth(currentDate);

  let comparisonDate = new Date(selectedYear, selectedMonth);

  if (allowOneMore) {
    comparisonDate = addMonths(comparisonDate, 1);
  }

  return isEqual(new Date(getYear(date), getMonth(date)), comparisonDate);
};

export const completeMonthsWithMissingDays = (
  data: BasicChartItemType[] | ChartItemType[],
  monthOccupancyDate: Date,
  removeMaxValue?: boolean,
): StackedBarItemType[] => {
  if (!data?.length) return null;

  const tempDate = new Date(monthOccupancyDate);
  tempDate.setDate(+data[0]?.label);
  const currentDate = new Date(tempDate || new Date());
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);

  const daysInMonth = eachDayOfInterval({
    start: firstDayOfMonth,
    end: lastDayOfMonth,
  });

  return daysInMonth.map(day => {
    const tempDay = day.getDate();
    const found = data.findIndex(({ label }) => +label === tempDay);

    if (found !== -1) {
      return { ...data[found] };
    } else {
      const result = {
        label: tempDay < 10 ? `0${tempDay}` : `${tempDay}`,
        value: null,
      };

      if (!removeMaxValue) Object.assign(result, { maxValue: null });

      return result;
    }
  });
};
