import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect } from "react";

type UseWelcomeToProductModalOptions = { openModal: () => void };

const SKIP_WELCOME_TO_FIXME_MODAL = "skipWelcomeToProductModal";

const checkIfSkipFlagExists = async () =>
  await AsyncStorage.getItem(SKIP_WELCOME_TO_FIXME_MODAL);

const setSkipFlagValue = async (val: string) =>
  await AsyncStorage.setItem(SKIP_WELCOME_TO_FIXME_MODAL, val);

export const useWelcomeToProductModal = ({
  openModal,
}: UseWelcomeToProductModalOptions) => {
  useEffect(() => {
    void checkIfSkipFlagExists().then(res => {
      if (!res || res === "false") {
        openModal();
        void setSkipFlagValue("true");
      }
    });
  }, [openModal]);
};
