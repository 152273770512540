import { PrimaryButton } from "@components/buttons";
import { spacing16 } from "@styles/spacing";
import { FC } from "react";
import { StyleSheet, View } from "react-native";

type Props = {
  onPress: () => void;
};

export const AbsoluteShowCalendarButton: FC<Props> = ({ onPress }) => {
  return (
    <View style={styles.button}>
      <PrimaryButton label="T00639" onPress={onPress} />
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    position: "absolute",
    zIndex: 1,
    alignSelf: "center",
    padding: spacing16,
  },
});
