import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FlatList, StyleProp, ViewStyle } from "react-native";
import { SearchListItem } from "@components/ListItems";
import { GetServicesResponse } from "../filters.types";
import { useQuery } from "@tanstack/react-query";
import { queryKeysServices } from "../queryKeysFilters";
import { getServices } from "@services/ApiService/common";
import { globalStyles } from "@styles/global";
import { ActivityIndicator, Divider, Text } from "react-native-paper";
import { FetchError } from "@components/errors";
import { useTranslation } from "react-i18next";
import { spacing8 } from "@styles/spacing";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";

export type ServicesModalProps = {
  searchQuery: string;
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
  selectedItems: string[];
  style?: StyleProp<ViewStyle>;
};

export const ServicesModal: FC<ServicesModalProps> = ({
  searchQuery,
  setSelectedItems,
  selectedItems,
  style,
}) => {
  const [servicesList, setServicesList] = useState<GetServicesResponse[]>([]);
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, gapMedium, scrollContainer, flatListSeparator } =
    globalStyles;

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysServices.list(),
    queryFn: async () => await getServices(),
  });

  useEffect(() => {
    if (searchQuery.length > 0) {
      setServicesList(() =>
        data.filter(e =>
          e.name.toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      );
    } else {
      setServicesList(data);
    }
  }, [data, searchQuery]);

  const onPressItem = useCallback(
    (serviceName: string) =>
      setSelectedItems(prevState =>
        prevState.includes(serviceName)
          ? prevState.filter(e => e !== serviceName)
          : [...prevState, serviceName],
      ),
    [setSelectedItems],
  );

  const renderItem = useCallback(
    ({ item: { name } }: { item: GetServicesResponse }) => {
      return (
        <SearchListItem
          title={name}
          checked={selectedItems.includes(name)}
          onPress={() => onPressItem(name)}
        />
      );
    },
    [onPressItem, selectedItems],
  );

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <FlatList
      data={servicesList}
      keyExtractor={(item, index) => `service-item-${item}-${index}`}
      contentContainerStyle={[
        gapMedium,
        scrollContainer,
        { paddingTop: spacing8 },
        style,
      ]}
      renderItem={renderItem}
      ListEmptyComponent={<Text variant="bodyMedium">{t("T00636")}</Text>}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ItemSeparatorComponent={
        <Divider
          bold
          style={[flatListSeparator, { width, marginTop: spacing8 }]}
        />
      }
    />
  );
};
