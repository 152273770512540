import { FC, ReactNode } from "react";
import { Platform, StyleSheet, View } from "react-native";
import {
  Control,
  Controller,
  ControllerProps,
  FieldError,
} from "react-hook-form";
import DropdownPicker from "@components/DropdownPicker/DropdownPicker";
import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { DateInputs } from "@screens/TrainingsAndExercises/SetASchedule/SetASchedule";
import { useAppTheme } from "@styles/theme";
import { spacing8 } from "@styles/spacing";
import { globalStyles } from "@styles/global";
import { useFrequencyOptions } from "@hooks/useFrequencyValues";

type SetFrequencyModalContentProps = Pick<ControllerProps, "control"> & {
  pickerError: ReactNode | ReactNode[];
  onPress: () => void;
  disabled: boolean;
  dateFromError: FieldError[];
  dateToError: FieldError[];
  onInputPress: (inputs: DateInputs) => void;
};

const SetFrequencyModalContent: FC<SetFrequencyModalContentProps> = ({
  control,
  pickerError,
  onPress,
  disabled,
  dateFromError,
  dateToError,
  onInputPress,
}) => {
  const {
    colors: { outline },
  } = useAppTheme();
  const { inputsContainer } = styles;
  const frequencyOptions = useFrequencyOptions();
  return (
    <>
      <Controller
        name="frequency"
        control={control as unknown as Control}
        render={({ field: { onChange, value } }) => (
          <View>
            <DropdownPicker<number>
              onValueChange={onChange}
              defaultValue={value as number}
              dropdownLabelText="T00253"
              items={frequencyOptions}
              isRequired
              isInBottomSheet
              dropDownDirection="TOP"
            />
            {pickerError}
          </View>
        )}
      />
      <View style={inputsContainer}>
        <View style={{ flex: 1 }}>
          <Input
            control={control as unknown as Control}
            key="dateFrom"
            name="dateFrom"
            label="T00251"
            errors={dateFromError}
            keyboardType={
              Platform.OS === "ios" ? "numbers-and-punctuation" : "number-pad"
            }
            calendarInput
            editable={false}
            borderColor={outline}
            onPress={() => onInputPress("dateFrom")}
          />
        </View>
        <View style={{ flex: 1 }}>
          <Input
            control={control as unknown as Control}
            key="dateTo"
            name="dateTo"
            label="T00252"
            errors={dateToError}
            keyboardType={
              Platform.OS === "ios" ? "numbers-and-punctuation" : "number-pad"
            }
            calendarInput
            editable={false}
            borderColor={outline}
            onPress={() => onInputPress("dateTo")}
          />
        </View>
      </View>
      <PrimaryButton disabled={disabled} label="T00254" onPress={onPress} />
    </>
  );
};

export default SetFrequencyModalContent;

const styles = StyleSheet.create({
  inputsContainer: {
    flexDirection: "row",
    paddingBottom: spacing8,
    ...globalStyles.gapMedium,
  },
});
