import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={250} height={214} fill="none" {...props}>
    <Path
      fill="#F2F2F2"
      d="M177.429 213.026a10.604 10.604 0 0 1-5.104-1.313L27.129 132.306a10.68 10.68 0 0 1-4.24-14.477L84.161 5.789a10.668 10.668 0 0 1 14.477-4.24l145.197 79.407a10.68 10.68 0 0 1 4.24 14.477l-61.274 112.04a10.587 10.587 0 0 1-6.36 5.118c-.979.288-1.993.435-3.013.435Zm-4.227-2.917a8.838 8.838 0 0 0 11.996-3.514l61.274-112.039a8.847 8.847 0 0 0-3.514-11.995L97.762 3.153a8.839 8.839 0 0 0-11.996 3.514L24.492 118.706a8.848 8.848 0 0 0 3.514 11.996l145.196 79.407Z"
    />
    <Path
      fill="#F2F2F2"
      d="M180.423 173.841a9.968 9.968 0 0 1-4.78-1.224L58.256 108.419a9.897 9.897 0 0 1-3.938-13.443l36.595-66.914a9.904 9.904 0 0 1 13.443-3.937l117.387 64.198a9.902 9.902 0 0 1 3.938 13.443l-36.595 66.914a9.857 9.857 0 0 1-8.663 5.161Z"
    />
    <Path
      fill="#fff"
      d="M176.779 212.997H11.287a9.917 9.917 0 0 1-9.905-9.905v-127.7a9.917 9.917 0 0 1 9.905-9.905H176.78a9.918 9.918 0 0 1 9.905 9.905v127.7a9.921 9.921 0 0 1-2.904 7.001 9.921 9.921 0 0 1-7.001 2.904Z"
    />
    <Path
      fill="#E6E6E6"
      d="M176.779 213.759H11.287A10.682 10.682 0 0 1 .62 203.092v-127.7a10.68 10.68 0 0 1 10.667-10.667H176.78a10.68 10.68 0 0 1 10.667 10.667v127.7a10.678 10.678 0 0 1-10.667 10.667ZM11.287 66.553a8.849 8.849 0 0 0-8.838 8.839v127.7a8.847 8.847 0 0 0 8.838 8.838H176.78a8.851 8.851 0 0 0 8.838-8.838v-127.7a8.845 8.845 0 0 0-8.838-8.839H11.287Z"
    />
    <Path
      fill="#E6E6E6"
      d="M119.787 107.48a12.435 12.435 0 0 0-9.921 4.934 8.171 8.171 0 0 0-7.73.68 8.176 8.176 0 0 0-3.683 6.831h33.779a12.452 12.452 0 0 0-3.645-8.799 12.445 12.445 0 0 0-8.8-3.646Z"
    />
    <Path
      fill="#4666FF"
      d="M45.786 156.193c11.069 0 20.041-8.972 20.041-20.041 0-11.068-8.972-20.041-20.04-20.041-11.069 0-20.042 8.973-20.042 20.041 0 11.069 8.973 20.041 20.041 20.041Z"
    />
    <Path
      fill="#3F3D56"
      d="M163.174 177.454a9.735 9.735 0 0 1-2.243.259H27.135a9.706 9.706 0 0 1-4.712-1.21l.357-.512 14.928-21.495 22.12-31.846.323-.466 3.48-5.011a2.544 2.544 0 0 1 4.175 0l11.564 16.644v.003l6.803 9.795 16.238 23.376 24.629-35.155a2.54 2.54 0 0 1 3.26-.798c.363.189.675.463.909.798l15.8 22.55.908 1.295 15.257 21.773Z"
    />
    <Path
      fill="#CCC"
      d="M93.189 128.261a15.326 15.326 0 0 0-12.24 6.086 10.083 10.083 0 0 0-9.538.84 10.096 10.096 0 0 0-4.544 8.428h41.676a15.35 15.35 0 0 0-9.479-14.185 15.348 15.348 0 0 0-5.875-1.169Z"
    />
    <Path
      fill="#3F3D56"
      d="M160.931 178.018H27.135a10.053 10.053 0 0 1-9.294-6.207 10.049 10.049 0 0 1-.763-3.85V91.694a10.069 10.069 0 0 1 10.057-10.057h133.796a10.068 10.068 0 0 1 10.057 10.057v76.267a10.032 10.032 0 0 1-2.942 7.115 10.05 10.05 0 0 1-7.115 2.942ZM27.135 82.246a9.458 9.458 0 0 0-9.448 9.448v76.267a9.44 9.44 0 0 0 9.448 9.448h133.796a9.432 9.432 0 0 0 6.684-2.764 9.445 9.445 0 0 0 2.764-6.684V91.694a9.46 9.46 0 0 0-9.448-9.448H27.135Z"
    />
  </Svg>
);
export default SvgComponent;
