import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItem, RefreshControl, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";

import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { MedicalRecordTile } from "@components/Tile";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { BottomTutorialComponent } from "@components/BottomSheet";

import { MedicalRecordsTabProps } from "@navigators/navigation.types";
import { globalStyles } from "@styles/global";
import { MedicalRecordsSimpleGet } from "./MedicalRecords.types";
import { getMedicalRecords } from "@services/ApiService/medicalRecords";
import { queryKeysMedicalRecord } from "./queryKeysMedicalRecord";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import { SvgMedicalRecords1, SvgMedicalRecords2 } from "@assets/svg/tutorials";
import { useUserDetails } from "@hooks/user/useUserDetails";
import InfoTile from "@components/Tile/InfoTile";

const RecordsCreatedByCurrentUser: FC<
  PropsWithChildren<MedicalRecordsTabProps>
> = ({ navigation: { navigate } }) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const { createdByMeList } = queryKeysMedicalRecord;
  const { scrollContainer, gapMedium, gapLarge, loading } = globalStyles;
  const { isPatient } = useUserDetails();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: createdByMeList(),
    queryFn: () => getMedicalRecords(),
  });

  const renderItem: ListRenderItem<MedicalRecordsSimpleGet> = useCallback(
    ({ item: { id } }) => (
      <MedicalRecordTile
        showNumberOfShares
        recordId={id}
        onPress={() => navigate("RecordPreview", { id })}
      />
    ),
    [navigate],
  );

  if (isLoading) return <ActivityIndicator style={loading} />;

  if (isError) return <FetchError action={refetch} />;

  return (
    <>
      <FlatList
        data={data}
        keyExtractor={({ id }, index) =>
          `medical-record-created-by-me-${id}-${index}`
        }
        ListHeaderComponent={
          <View style={gapLarge}>
            <PrimaryButton
              label="T00931"
              onPress={() =>
                navigate(
                  `${
                    isPatient
                      ? "CreateAndEditRecord"
                      : "PatientToAssignMedicalRecord"
                  }`,
                )
              }
            />
            {!isPatient && (
              <InfoTile
                status="info"
                content={<Text variant="bodyMedium">{t("T01309")}</Text>}
              />
            )}
            <TutorialTextButton
              label="T00475"
              onPress={() => setModalVisible(true)}
            />
          </View>
        }
        renderItem={renderItem}
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={refetch} />
        }
        contentContainerStyle={[gapMedium, scrollContainer]}
        ListEmptyComponent={<Text variant="labelLarge">{t("T00325")}</Text>}
      />
      <BottomModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}>
        <BottomTutorialComponent
          title="T00476"
          content={[
            {
              text: "T00477",
              svgComponent: () => <SvgMedicalRecords1 />,
            },
            {
              text: isPatient ? "T00837" : "T00478",
              svgComponent: () => <SvgMedicalRecords2 />,
            },
          ]}
          onPress={() => setModalVisible(false)}
        />
      </BottomModalContainer>
    </>
  );
};

export default RecordsCreatedByCurrentUser;
