import { SvgCalendar, SvgHome, SvgProfile, SvgTrainings } from "@assets/svg";
import { useAuth } from "@contexts/AuthContext/auth";
import { AccountType } from "@contexts/AuthContext/user.types";
import { useUnreadMessages } from "@hooks/queryHooks/useUnreadMessages";
import {
  BottomTabBarButtonProps,
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from "@react-navigation/bottom-tabs";
import { getFocusedRouteNameFromRoute, Route } from "@react-navigation/native";
import { spacing4, spacing8 } from "@styles/spacing";
import { isIOS, isWeb } from "@utils/constants";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import { Text } from "react-native-paper";
import {
  CalendarStackNavigator,
  HomeStackNavigator,
  MyProfileStackNavigator,
  TrainingsStackNavigator,
} from "./";
import MessageCenterStackNavigator from "./MessageCenterStackNavigator.tsx";
import { RootTabsParamList } from "./navigation.types";
import { getTabBarOptions, shouldHideTabBar } from "./tabBarHelpers";
import { RouteProp } from "@react-navigation/core/lib/typescript/src/types";
import { TransKey } from "@globalTypes/i18next";

const Tab = createBottomTabNavigator<RootTabsParamList>();

const getTabNavigatorOptions = (
  route: RouteProp<RootTabsParamList, keyof RootTabsParamList>,
  isPhysiotherapist: boolean,
  t: (val: TransKey) => string,
  unreadMessages?: boolean,
): BottomTabNavigationOptions => {
  const { tabBarText, tabContainerIos, tabContainerAndroid, buttonContainer } =
    styles;
  const routeName = getFocusedRouteNameFromRoute(
    route as Partial<Route<string>>,
  );

  const renderTabBarButton = (props: BottomTabBarButtonProps) => {
    const isRouteSelected = props.accessibilityState.selected;
    const { icon, name } = getTabBarOptions(
      props.to,
      isRouteSelected,
      t,
      isPhysiotherapist,
      unreadMessages,
    );

    return (
      <TouchableOpacity
        key={`button-${isRouteSelected ? "selected" : "notselected"}`}
        activeOpacity={1}
        {...props}
        style={[
          props.style,
          buttonContainer,
          isWeb && { flexDirection: "column" },
        ]}>
        {icon}
        <Text style={tabBarText} variant="labelMedium" numberOfLines={1}>
          {name}
        </Text>
      </TouchableOpacity>
    );
  };

  const tabBarStyle: StyleProp<ViewStyle> = isIOS
    ? { ...tabContainerIos }
    : { ...tabContainerAndroid };
  if (shouldHideTabBar(routeName)) {
    Object.assign(tabBarStyle, { display: "none" });
  }

  return {
    tabBarStyle,
    tabBarButton: renderTabBarButton,
    header: () => null,
  };
};

const BottomTabNavigator: FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const isPhysiotherapist = user?.accountType === AccountType.THERAPIST;
  const { allUnreadMessages } = useUnreadMessages();

  return (
    <Tab.Navigator
      screenListeners={({ navigation }) => {
        if (__DEV__) {
          return {
            //this fixes bottom tabs onPress for android debugging
            tabLongPress: e => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
              navigation.jumpTo(e.target.split("-")[0]);
            },
          };
        }
      }}
      screenOptions={({ route }) =>
        getTabNavigatorOptions(route, isPhysiotherapist, t, !!allUnreadMessages)
      }>
      <Tab.Screen
        name="HomeStack"
        component={HomeStackNavigator}
        options={{ title: t("T00162"), tabBarIcon: SvgHome }}
      />
      <Tab.Screen
        name="CalendarStack"
        component={CalendarStackNavigator}
        options={{ title: t("T00067"), tabBarIcon: SvgCalendar }}
      />
      <Tab.Screen
        name="TrainingsStack"
        component={TrainingsStackNavigator}
        options={{ title: t("T00167"), tabBarIcon: SvgTrainings }}
      />
      <Tab.Screen
        name="MessageCenterStack"
        component={MessageCenterStackNavigator}
      />
      <Tab.Screen
        name="ProfileStack"
        component={MyProfileStackNavigator}
        options={{ title: t("T00120"), tabBarIcon: SvgProfile }}
      />
    </Tab.Navigator>
  );
};

export default BottomTabNavigator;

const styles = StyleSheet.create({
  tabContainerIos: {
    borderTopWidth: 0,
    backgroundColor: "#274BE614",
    minHeight: "11%",
    paddingTop: spacing8,
  },
  tabContainerAndroid: {
    minHeight: "11%",
    paddingTop: spacing8,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#274BE614",
    borderTopWidth: 0,
    elevation: 0,
  },
  tabBarText: { marginTop: spacing4, textAlign: "center" },
  buttonContainer: { alignItems: "center", justifyContent: "flex-start" },
});
