import StatusComponent from "@components/Status/StatusComponent";
import { useAuth } from "@contexts/AuthContext/auth";
import { TransactionHistoryStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { getDate } from "@utils/date";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { useTransactionParticipant } from "@hooks/payments/useTransactionParticipant";
import { FetchError } from "@components/errors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { TransKey } from "@globalTypes/i18next";

export const TransactionDetails: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      TransactionHistoryStackParamList,
      "TransactionDetails"
    >
  >
> = ({
  route: {
    params: { data },
  },
}) => {
  const { t } = useTranslation();
  const {
    user: { firstName, lastName },
  } = useAuth();
  const { isPatient } = useUserDetails();

  const { container, gapLarge, gapSmall } = globalStyles;
  const {
    paymentStatus,
    servicePrice,
    currency,
    serviceName,
    transactionType,
    participant,
    createdAt,
    availableOn,
    paymentProviderFee,
    finalPrice,
  } = data;
  const { isError, isLoading, refetch, participantFullName } =
    useTransactionParticipant(participant);
  const type = t(
    {
      Income: "T01209",
      Expense: "T01212",
    }[transactionType] as TransKey,
  );

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} />;

  const myName = `${firstName} ${lastName}`;
  const formattedCurrency = currency.toUpperCase();
  const isNegative = Math.sign(finalPrice) === (isPatient ? 1 : -1);

  const getSender = () => {
    if (isPatient) return isNegative ? participantFullName : myName;
    return isNegative ? myName : participantFullName;
  };

  const getRecipient = () => {
    if (isPatient) return isNegative ? myName : participantFullName;
    return isNegative ? participantFullName : myName;
  };

  const dataToDisplay = [
    { title: t("T01205"), content: getDate(createdAt) },
    { title: t("T01206"), content: getDate(availableOn) },
    { title: t("T00795"), content: getSender() },
    {
      title: t("T00796"),
      content: getRecipient(),
    },
    { title: t("T00797"), content: serviceName },
    {
      title: t("T00798"),
      content: isNegative ? (isPatient ? t("T01209") : t("T01212")) : type,
    },
    {
      title: t("T00799"),
      content: () => <StatusComponent status={paymentStatus} />,
    },
    {
      title: isNegative ? t("T01211") : t("T00545"),
      content: `${servicePrice / 100} ${formattedCurrency}`,
    },
  ];

  if (!isPatient && !isNegative) {
    dataToDisplay.push({
      title: t("T01207"),
      content: `${paymentProviderFee / 100} ${formattedCurrency}`,
    });
    dataToDisplay.push({
      title: t("T01208"),
      content: `${finalPrice / 100} ${formattedCurrency}`,
    });
  }

  return (
    <View style={[container, gapLarge]}>
      {dataToDisplay.map(({ title, content }, i) => (
        <View key={`transaction-details-${title}-${i}`} style={gapSmall}>
          <Text variant="titleMedium">{title}:</Text>
          {typeof content === "string" ? (
            <Text variant="bodyMedium">{content}</Text>
          ) : typeof content === "function" ? (
            content()
          ) : null}
        </View>
      ))}
    </View>
  );
};
