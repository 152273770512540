import {
  HumanModelType,
  PaginationParams,
  PaginationResponse,
} from "@globalTypes/common.types";
import { AxiosRequestConfig } from "axios";
import { ImagePickerResult } from "expo-image-picker";
import { ExercisesFiltersParamsType } from "./Physiotherapist/SearchExercises/SearchExercises";

export type Condition = { id: number; name: string };

export type ExerciseBase = {
  id: number;
  name: string;
  workout: number | null;
  instruction: string;
  equipments?: number[];
  exerciseVideoId: number;
  exerciseVideo: number;
  exerciseVideoUrl: string;
  exerciseVideoSubtitlesUrl?: string | null;
  instructionExerciseVideoId?: number | null;
  instructionExerciseVideo?: number | null;
  instructionExerciseVideoUrl?: string | null;
  instructionExerciseVideoSubtitlesUrl?: string | null;
  exerciseImageUrl?: string | null;
  exerciseImage?: number | null;
  exerciseImageId?: number | null;
  originalExerciseId?: number | null;
  conditions: Condition[];
} & HumanModelType;

type AlternativeExerciseType = ExerciseBase & {
  author: number;
  series: BaseSeries[];
};

type NestedAlternativeExerciseType = Omit<AlternativeExerciseType, "author">;

export type Exercise = ExerciseBase & {
  author: number;
  series: number[];
  alternativeExercise?: AlternativeExerciseType;
};

export type CtxExerciseType = ExerciseBase & {
  series: BaseSeries[];
  alternativeExercise?: null | NestedAlternativeExerciseType;
};

export type CreateAndEditExerciseResponse = ExerciseBase;

export type CreateAndEditExerciseType = Pick<
  ExerciseBase,
  | "name"
  | "humanBones"
  | "humanGeneral"
  | "humanMuscles"
  | "instruction"
  | "equipments"
  | "gender"
> & {
  exerciseVideo: ImagePickerResult;
  instructionExerciseVideo?: ImagePickerResult;
  exerciseImage?: ImagePickerResult;
  exerciseVideoUrl?: string;
  exerciseImageUrl?: string;
  instructionExerciseVideoUrl?: string;
  conditions: number[];
};

export enum SeriesType {
  TIME = "T",
  REPETITIONS = "R",
}

export type BaseSeries = {
  id: number;
  seriesBreak: number;
  value: number;
  seriesType: SeriesType;
};

export type Series = BaseSeries & { exercise: number };

export type EquipmentType = {
  id: number;
  name: string;
  description: string;
  image?: string;
};

export type ExerciseDetailsScreenType =
  | "exercise"
  | "libraryExercise"
  | "fixmeExercise";

export type Actions = "delete" | "copy" | "edit";

export type DeleteExerciseOptionalAttachments = {
  instructionVideo: boolean;
  exerciseImage: boolean;
};

export type GetLibraryExercisesParams = Partial<
  Omit<ExercisesFiltersParamsType, "conditions">
> & {
  multiLanguageNameOrdering?: boolean;
  conditions?: number[];
} & PaginationParams;

export type GetLibraryExercisesResponse = PaginationResponse<{ id: number }>;

export type PostExerciseParams = {
  data: CreateAndEditExerciseType;
} & Pick<
  AxiosRequestConfig,
  "onUploadProgress" | "timeout" | "timeoutErrorMessage" | "signal"
>;

export type PatchExerciseParams = PostExerciseParams & {
  id: number;
};
