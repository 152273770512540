import { Dispatch, SetStateAction } from "react";
import { TransKey } from "@globalTypes/i18next";
import { AppointmentSlots } from "@screens/Calendar/availability.types";
import { Choice } from "@services/ApiService/api.types";
import { VisitDatePickerOptions } from "./FiltersComponents/VisitDatePicker";
import { UseFormHandleSubmit } from "react-hook-form";

export type GetPhysiotherapistParams = {
  available?: boolean;
  dateFrom?: string;
  dateTo?: string;
  availabilityType?: AppointmentSlots;
  serviceName?: string[];
  search?: string;
  spokenLanguage?: Choice[];
  country?: string;
  date?: Date;
  lat?: number;
  lng?: number;
  visitDatePicker?: VisitDatePickerOptions;
  hourFrom?: Date;
  hourTo?: Date;
};

export type GetServicesResponse = {
  name: string;
  id: number;
};

export type SetFiltersType = Dispatch<SetStateAction<GetPhysiotherapistParams>>;

export type TimeInputsProps = {
  hourFrom: Date;
  hourTo: Date;
  setHourTo: Dispatch<SetStateAction<Date>>;
  setHourFrom: Dispatch<SetStateAction<Date>>;
};

type VisitTypes = {
  value: AppointmentSlots;
  label: TransKey;
}[];

export const visitTypes: VisitTypes = [
  {
    value: AppointmentSlots.ONLINE_OR_STATIONARY,
    label: "T00485",
  },
  {
    value: AppointmentSlots.STATIONARY,
    label: "T00495",
  },
  {
    value: AppointmentSlots.ONLINE,
    label: "T00496",
  },
];

export type FiltersFooterProps = {
  setFilters: SetFiltersType;
  goBack: ({ filtersActive }: { filtersActive: boolean }) => void;
  setFooterHeight: Dispatch<SetStateAction<number>>;
  handleSubmit: UseFormHandleSubmit<
    {
      visitDateType?: string;
      availabilityType?: string;
    },
    undefined
  >;
  selectedServices: string[];
  selectedLanguages: Choice[];
  selectedCountry: string;
  onClearFilters: () => void;
  hourFrom: Date;
  hourTo: Date;
  selectedDate: Date;
};
