import { SvgBulletList, SvgClose, SvgHelpOutline } from "@assets/svg";

import { globalStyles } from "@styles/global";
import { spacing12 } from "@styles/spacing";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

type VideoSectionTopBarProps = {
  onXPress: () => void;
  onExercisesListPress: () => void;
  onInstructionPress: () => void;
};

const VideoSectionTopBar: FC<VideoSectionTopBarProps> = ({
  onXPress,
  onExercisesListPress,
  onInstructionPress,
}) => {
  const { t } = useTranslation();
  const { container, rightContainer, iconWithTextContainer } = styles;
  const { gapLarge, gapMedium } = globalStyles;
  const { top } = useSafeAreaInsets();
  return (
    <View style={[container, { top, padding: spacing12 }]}>
      <TouchableOpacity onPress={onXPress}>
        <SvgClose />
      </TouchableOpacity>
      <View style={[rightContainer, gapLarge]}>
        <TouchableOpacity
          style={[iconWithTextContainer, gapMedium]}
          onPress={onExercisesListPress}>
          <SvgBulletList />
          <Text variant="bodyLarge">{t("T01242")}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[iconWithTextContainer, gapMedium]}
          onPress={onInstructionPress}>
          <SvgHelpOutline />
          <Text variant="bodyLarge">{t("T01243")}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 1,
    backgroundColor: "#fff",
    width: "100%",
  },
  iconWithTextContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  rightContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default VideoSectionTopBar;
