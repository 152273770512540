/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from "react";
import { showAlert } from "@utils/showAlert";
import { useAuth } from "@contexts/AuthContext/auth";
import { Linking, Platform } from "react-native";
import { isWeb } from "@utils/constants";

type useStripeType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("@stripe/stripe-react-native").useStripe;

let useStripe: useStripeType;

if (!isWeb) {
  useStripe = (
    require("@stripe/stripe-react-native") as typeof import("@stripe/stripe-react-native")
  ).useStripe;
}

type UsePaymentsOptions = {
  paymentIntentClientSecret: string;
  onPaymentSuccess: () => Promise<void>;
  onPaymentError?: () => void;
  returnURL: string;
};

export const usePayments = ({
  onPaymentSuccess,
  paymentIntentClientSecret,
  onPaymentError,
  returnURL,
}: UsePaymentsOptions) => {
  if (!isWeb) {
    const [wasPaymentInitialized, setWasPaymentInitialized] = useState(false);
    const { initPaymentSheet, presentPaymentSheet, handleURLCallback } =
      useStripe();
    const {
      user: { firstName: merchantDisplayName },
    } = useAuth();

    const openPaymentSheet = useCallback(async () => {
      setWasPaymentInitialized(true);
      const { error } = await presentPaymentSheet();
      if (error)
        onPaymentError ? onPaymentError() : showAlert(error.localizedMessage);
      else await onPaymentSuccess();
    }, [onPaymentError, onPaymentSuccess, presentPaymentSheet]);

    const initializePaymentSheet = useCallback(
      async (disableOpenOnInitalize?: boolean) => {
        const { error } = await initPaymentSheet({
          paymentIntentClientSecret,
          merchantDisplayName,
          returnURL,
        });
        if (error) showAlert(error.localizedMessage);
        else {
          setWasPaymentInitialized(true);
          !disableOpenOnInitalize && (await openPaymentSheet());
        }
      },
      [
        initPaymentSheet,
        merchantDisplayName,
        openPaymentSheet,
        paymentIntentClientSecret,
        returnURL,
      ],
    );

    const handleDeepLink = useCallback(
      async (url: string | null) => {
        if (url) {
          await handleURLCallback(url);
        }
      },
      [handleURLCallback],
    );

    useEffect(() => {
      const getUrlAsync = async () => {
        const initialUrl = await Linking.getInitialURL();
        void handleDeepLink(initialUrl);
      };

      void getUrlAsync();

      const deepLinkListener = Linking.addEventListener(
        "url",
        (event: { url: string }) => {
          void handleDeepLink(event.url);
        },
      );

      return () => deepLinkListener.remove();
    }, [handleDeepLink]);

    return { openPaymentSheet, initializePaymentSheet, wasPaymentInitialized };
  } else {
    return {
      openPaymentSheet: () => {},
      initializePaymentSheet: () => {},
      wasPaymentInitialized: false,
    };
  }
};
