import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill="#4666FF"
      d="M20.169 0H3.832A3.832 3.832 0 0 0 0 3.832v16.337A3.831 3.831 0 0 0 3.832 24h16.337A3.831 3.831 0 0 0 24 20.169V3.832A3.831 3.831 0 0 0 20.169 0Z"
    />
    <Path
      fill="#fff"
      d="M9.883 4.6c0-1.136.849-1.91 2.044-1.91 1.196 0 2.001.774 2.001 1.91 0 1.134-.827 1.885-2 1.885-1.175 0-2.045-.798-2.045-1.886Z"
    />
    <Path
      fill="#fff"
      d="m15.693 15.001-1.737-2.186 5.286-6.524h-3.72l-3.457 4.446L8.607 6.29h-3.87l5.305 6.596-5.392 6.717h3.718l3.567-4.567 1.705 2.212L15.693 15ZM17.53 17.313h-3.84l1.767 2.29h3.893l-1.82-2.29Z"
    />
  </Svg>
);
export default SvgComponent;
