import { TransactionHistoryResponse } from "@screens/Profiles/Transactions/transaction.types";
import { api } from "./api";
import { endpoints } from "./endpoints";
import { formatDateForApi } from "@utils/date";
import { subDays } from "date-fns";

type TransactionsParams = {
  dateFrom?: string;
  dateTo?: string;
};

export const getTransactionsHistory = async (params?: TransactionsParams) => {
  const resultParams = {};

  if (params?.dateFrom !== null) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    Object.assign(resultParams, {
      dateFrom: formatDateForApi(subDays(today, +params.dateFrom)),
    });
  }

  if (params?.dateTo) {
    Object.assign(resultParams, { dateTo: formatDateForApi(params.dateTo) });
  }

  const { data } = await api.get<TransactionHistoryResponse>(
    endpoints.TRANSACTIONS_HISTORY,
    { params: resultParams },
  );
  return data;
};
