import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { IconButton, Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { getDate } from "@utils/date";

type RatingAnswerContentProps = {
  text: string;
  isMyAnswer: boolean;
  createdAt: string;
  onDotsPress: () => void;
};

const RatingAnswerContent: FC<RatingAnswerContentProps> = ({
  text,
  isMyAnswer,
  createdAt,
  onDotsPress,
}) => {
  const { responseHeaderContainer } = styles;
  const { t } = useTranslation();
  return (
    <>
      <View style={responseHeaderContainer}>
        <View>
          <Text variant="titleMedium">
            {t(isMyAnswer ? "T00421" : "T00524")}
          </Text>
          <Text variant="bodyMedium">{getDate(createdAt)}</Text>
        </View>
        <IconButton icon="dots-vertical" onPress={onDotsPress} />
      </View>
      <Text variant="bodyMedium">{text}</Text>
    </>
  );
};

export default RatingAnswerContent;

const styles = StyleSheet.create({
  responseHeaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
