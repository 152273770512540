import Svg, { Path, G, Defs, Rect, ClipPath, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <G clipPath="url(#clip0_54306_7464)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2003 19V14H14.2003V19C14.2003 19.55 14.6503 20 15.2003 20H18.2003C18.7503 20 19.2003 19.55 19.2003 19V12H20.9003C21.3603 12 21.5803 11.43 21.2303 11.13L12.8703 3.59997C12.4903 3.25997 11.9103 3.25997 11.5303 3.59997L3.17029 11.13C2.83029 11.43 3.04029 12 3.50029 12H5.20029V19C5.20029 19.55 5.65029 20 6.20029 20H9.20029C9.75029 20 10.2003 19.55 10.2003 19Z"
        fill="#46464F"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_54306_7464">
        <Rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.200195)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgComponent;
