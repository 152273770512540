import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { IconButton, Text } from "react-native-paper";
import { Trans, useTranslation } from "react-i18next";

import { spacing16, spacing24, spacing8 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { TransKey } from "@globalTypes/i18next";

type Props = {
  icon: string;
  title: TransKey;
  subtitles?: TransKey[];
  renderSubtitles?: () => JSX.Element;
};

export const ExpectItem: FC<Props> = ({
  icon,
  title,
  subtitles,
  renderSubtitles,
}) => {
  const { t } = useTranslation();
  const { headerContainer, icon: iconStyle, subtitlesContainer } = styles;

  return (
    <>
      <View style={headerContainer}>
        <IconButton icon={icon} size={spacing24} style={iconStyle} />
        <Text variant="titleMedium">{t(title)}</Text>
      </View>
      <View style={subtitlesContainer}>
        {renderSubtitles?.() ||
          subtitles.map((e, i) => (
            <Text
              variant="bodyMedium"
              key={`subtitle-${i}-${e}`}
              style={{
                marginLeft: spacing8,
                marginTop: subtitles.length > 1 && i !== 0 ? spacing8 : 0,
              }}>
              <Trans
                i18nKey={e as unknown}
                components={{
                  1: (
                    <Text
                      style={{
                        fontWeight: "bold",
                      }}>
                      {e}
                    </Text>
                  ),
                }}
              />
            </Text>
          ))}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#4666FF14",
  },
  icon: { margin: 0, padding: 0 },
  subtitlesContainer: {
    margin: spacing16,
    borderColor: theme.colors.outlineVariant,
    borderLeftWidth: 1,
  },
});
