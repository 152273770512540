import { ScrollView, StyleSheet } from "react-native";
import { useCallback, useMemo, useState } from "react";
import { IconButton, SegmentedButtons, Text } from "react-native-paper";

import { GenderType } from "@contexts/AuthContext/user.types";
import { globalStyles } from "@styles/global";

import { spacing56, spacing64, spacing8 } from "@styles/spacing";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";
import { useDataForHumanModel } from "@hooks/humanModel/useDataForHumanModel";
import {
  ModelType,
  Orientation,
} from "@components/HumanModel/humanModel.types";

import GeneralModel from "@components/HumanModel/General";
import MuscleModel from "@components/HumanModel/Muscle";
import BoneModel from "@components/HumanModel/Bone";

const initialData = ["G1", "G5"];
const initialMusclesData = ["M1", "M5"];
const initialBoneData = ["B1", "B5"];

const HumanModelDevScreen = () => {
  const [gender, setGender] = useState<GenderType>(GenderType.MALE);
  const [orientation, setOrientation] = useState<Orientation>("front");
  const [modelType, setModelType] = useState<ModelType>(ModelType.GENERAL);

  const { modelData: generalModelData, selectedItems: selectedPoints } =
    useDataForHumanModel({
      gender,
      initialData,
    });

  const { modelData: muscleModelData, selectedItems: selectedMuscles } =
    useDataForHumanModel({
      gender,
      initialData: initialMusclesData,
    });
  const { modelData: boneModelData, selectedItems: selectedBones } =
    useDataForHumanModel({
      gender,
      initialData: initialBoneData,
    });

  const { t } = useTranslation();

  const genderButtons = useMemo(
    () => [
      {
        value: GenderType.MALE,
        label: capitalize(t("T00054")),
      },
      {
        value: GenderType.FEMALE,
        label: capitalize(t("T00055")),
      },
    ],
    [t],
  );

  const modelTypeButtons = useMemo(
    () => [
      {
        value: ModelType.GENERAL,
        label: `${t("T00388")} ${selectedPoints?.length || ""}`,
      },
      {
        value: ModelType.MUSCLE,
        label: `${t("T00844")} ${selectedMuscles?.length || ""}`,
        style: { flexGrow: selectedMuscles?.length > 1 ? 2 : 1 },
      },
      {
        value: ModelType.BONE,
        label: `${t("T00843")} ${selectedBones?.length || ""}`,
      },
    ],
    [selectedBones?.length, selectedMuscles?.length, selectedPoints?.length, t],
  );

  const renderModel = useCallback(
    (modelType: ModelType) => {
      switch (modelType) {
        case ModelType.GENERAL:
          return (
            <GeneralModel orientation={orientation} {...generalModelData} />
          );
        case ModelType.MUSCLE:
          return <MuscleModel orientation={orientation} {...muscleModelData} />;
        case ModelType.BONE:
          return <BoneModel orientation={orientation} {...boneModelData} />;
        default:
          return (
            <GeneralModel orientation={orientation} {...generalModelData} />
          );
      }
    },
    [boneModelData, generalModelData, muscleModelData, orientation],
  );

  const { scrollContainer, gapLarge } = globalStyles;
  const { iconButton, container } = styles;

  return (
    <ScrollView contentContainerStyle={[scrollContainer, gapLarge, container]}>
      <SegmentedButtons
        value={gender}
        onValueChange={(value: GenderType) => setGender(value)}
        buttons={genderButtons}
      />
      <SegmentedButtons
        value={modelType}
        onValueChange={(value: ModelType) => setModelType(value)}
        buttons={modelTypeButtons}
      />
      {renderModel(modelType)}
      <IconButton
        icon="cached"
        size={24}
        style={iconButton}
        onPress={() =>
          setOrientation(orientation === "front" ? "back" : "front")
        }
      />
      <Text>{`Selected points: ${selectedPoints.join(", ")}`}</Text>
      <Text>{`Selected muscles: ${selectedMuscles.join(", ")}`}</Text>
      <Text>{`Selected bones: ${selectedBones.join(", ")}`}</Text>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    position: "absolute",
    right: spacing8,
    top: spacing64 + spacing56,
  },
});

export default HumanModelDevScreen;
