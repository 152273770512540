import { ApiDataForBarChart } from "@components/Charts/chart.types";
import { createFormDataForExercise } from "@utils/files";
import {
  Condition,
  CreateAndEditExerciseResponse,
  DeleteExerciseOptionalAttachments,
  EquipmentType,
  Exercise,
  GetLibraryExercisesParams,
  GetLibraryExercisesResponse,
  PatchExerciseParams,
  PostExerciseParams,
  Series,
} from "../../screens/TrainingsAndExercises/exercise.types";
import { api } from "./api";
import { ResponseOptions } from "./api.types";
import { endpoints } from "./endpoints";
import { createAndEditExerciseTimeoutMs } from "@utils/constants";
import { GenericAbortSignal } from "axios";

export const getExercise = async (id: number) => {
  const { data } = await api.get<Exercise>(endpoints.EXERCISE(id));
  return data;
};

export const getLibraryExercisesList = async ({
  ...restParams
}: GetLibraryExercisesParams) => {
  const params = {
    isLibrary: true,
    ...restParams,
  };
  const { data } = await api.get<GetLibraryExercisesResponse>(
    endpoints.EXERCISES_LIST,
    {
      params,
      paramsSerializer: (
        params: Record<string, string | number | boolean | (string | number)[]>,
      ): string => {
        return Object.keys(params)
          .map(key => {
            const value = params[key];
            if (Array.isArray(value)) {
              return value.map(val => `${key}=${val}`).join("&");
            }
            return value ? `${key}=${value}` : "";
          })
          .filter(part => part !== "")
          .join("&");
      },
    },
  );
  return data;
};

export const getLibraryExercisesOptions = async () =>
  await api.options<ResponseOptions>(endpoints.EXERCISES_LIST);

export const editLibraryExercise = async ({
  id,
  data,
  onUploadProgress,
  timeout = createAndEditExerciseTimeoutMs,
  timeoutErrorMessage,
  signal,
}: PatchExerciseParams) => {
  const { formData, options } = createFormDataForExercise(data, true);
  return api.patch<CreateAndEditExerciseResponse>(
    endpoints.EXERCISE(id),
    formData,
    {
      ...options,
      onUploadProgress,
      timeout,
      timeoutErrorMessage,
      signal,
    },
  );
};

export const createLibraryExercise = async ({
  data,
  onUploadProgress,
  timeout = createAndEditExerciseTimeoutMs,
  timeoutErrorMessage,
  signal,
}: PostExerciseParams) => {
  const { formData, options } = createFormDataForExercise(data);
  return api.post<CreateAndEditExerciseResponse>(
    endpoints.EXERCISES_LIST,
    formData,
    {
      ...options,
      onUploadProgress,
      timeout,
      timeoutErrorMessage,
      signal,
    },
  );
};

export const deleteLibraryExercise = (id: number) =>
  api.delete(endpoints.EXERCISE(id));

export const copyLibraryExercise = (id: number) =>
  api.post(endpoints.EXERCISE_COPY(id));

export const getSeries = async (id: number) => {
  const { data } = await api.get<Series>(endpoints.SERIES(id));
  return data;
};

export const getExerciseStats = async (
  id: number,
  month?: number,
  year?: number,
) => {
  const { data } = await api.get<ApiDataForBarChart>(
    endpoints.EXERCISE_STATS(id),
    { params: { month, year } },
  );
  return data;
};

export const getEquipmentDetails = async (id: number) => {
  const { data } = await api.get<EquipmentType>(
    endpoints.EQUIPMENT_DETAILS(id),
  );
  return data;
};

export const getEquipmentList = async () => {
  const { data } = await api.get<EquipmentType[]>(endpoints.EQUIPMENT_LIST);
  return data;
};

export const deleteExerciseAttachment = async ({
  id,
  params,
  signal,
}: {
  id: number;
  params: DeleteExerciseOptionalAttachments;
  signal?: GenericAbortSignal;
}) =>
  await api.delete(endpoints.DELETE_EXERCISE_ATTACHMENTS(id), {
    params,
    signal,
  });
export const getConditionsList = async () => {
  const { data } = await api.get<Condition[]>(endpoints.CONDITIONS_LIST);
  return data;
};
