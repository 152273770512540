import { PrimaryButton } from "@components/buttons";
import Snackbar from "@components/Snackbar/Snackbar";
import { useAuth } from "@contexts/AuthContext/auth";
import { ProfileStatus } from "@contexts/AuthContext/user.types";
import { TransKey } from "@globalTypes/i18next";
import { useErrors } from "@hooks/useErrors";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { resendConfirmationLink } from "@services/ApiService/profile";
import i18n from "@services/i18n";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing32 } from "@styles/spacing";
import { useMutation } from "@tanstack/react-query";
import { getLang } from "@utils/lang";
import { showAlert } from "@utils/showAlert";
import { Timeout } from "ahooks/lib/useRequest/src/types";
import { AxiosError } from "axios";
import { getLocales } from "expo-localization";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { AppState, StyleSheet } from "react-native";
import { Button, Text } from "react-native-paper";
import { Host } from "react-native-portalize";
import { SafeAreaView } from "react-native-safe-area-context";

const EmailConfirmation: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "EmailConfirmation">
  >
> = () => {
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const [currentSnackbarText, setCurrentSnackbarText] =
    useState<TransKey>("T01026");

  const { t } = useTranslation();
  const {
    user: { email, profileStatus },
    signOut,
    updateUser,
  } = useAuth();
  const { setErrorsFromResponse } = useErrors();

  const appState = useRef(AppState.currentState);
  const { container, gapLarge } = globalStyles;

  const checkIfEmailIsConfirmed = () => {
    profileStatus === ProfileStatus.EmailUnconfirmed
      ? showAlert(t("T00955"))
      : updateUser();
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: resendConfirmationLink,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: () => setSnackbarVisible(true),
  });

  const changeLanguage = useCallback(async () => {
    const localeLanguageArr = await Promise.resolve(getLocales?.());
    const localeLanguage = localeLanguageArr[0].languageCode;

    await i18n.changeLanguage(getLang(localeLanguage));
  }, []);

  useEffect(() => {
    changeLanguage().catch(err => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLanguage]);

  useEffect(() => {
    let blockButtonTimeout: Timeout = null;

    if (blockButton) {
      blockButtonTimeout = setTimeout(() => {
        setBlockButton(false);
      }, 60000);
    }

    return () => {
      blockButtonTimeout && clearTimeout(blockButtonTimeout);
    };
  }, [blockButton]);

  useEffect(() => {
    if (profileStatus !== ProfileStatus.EmailUnconfirmed) return;

    const subscription = AppState.addEventListener("change", nextAppState => {
      if (appState.current === "background" && nextAppState === "active")
        updateUser();

      appState.current = nextAppState;
    });

    const updateInterval = setInterval(() => updateUser(), 15000);

    return () => {
      subscription.remove();
      clearInterval(updateInterval);
    };
  }, [profileStatus, updateUser]);

  const hideSnackbar = () => setSnackbarVisible(false);

  const onPress = () => {
    if (!blockButton) {
      mutate();
      setCurrentSnackbarText("T01026");
      setBlockButton(true);
    } else {
      if (snackbarVisible) {
        return null;
      } else {
        setCurrentSnackbarText("T01027");
        setSnackbarVisible(true);
      }
    }
  };

  return (
    <Host>
      <SafeAreaView style={[container, gapLarge, styles.safeAreaViewStyle]}>
        <Text variant="titleLarge">{t("T00790")}</Text>
        <Text variant="bodyLarge">{t("T00791")}</Text>
        <Text variant="bodyLarge" style={{ color: palettes.primary[40] }}>
          {email}
        </Text>
        <Text variant="bodyLarge">{t("T00792")}</Text>
        <PrimaryButton label="T00954" onPress={checkIfEmailIsConfirmed} />
        <Button onPress={onPress} icon="restore" loading={isLoading}>
          {t("T01025")}
        </Button>
        <Button onPress={signOut} icon="keyboard-backspace">
          {t("T00953")}
        </Button>
        <Snackbar
          visible={snackbarVisible}
          onDismiss={hideSnackbar}
          onIconPress={hideSnackbar}
          text={currentSnackbarText}
        />
      </SafeAreaView>
    </Host>
  );
};

const styles = StyleSheet.create({
  safeAreaViewStyle: {
    flex: 1,
    marginTop: spacing32,
  },
});

export default EmailConfirmation;
