import { globalStyles } from "@styles/global";
import { View } from "react-native";
import { Divider, Text } from "react-native-paper";
import { useTherapistPaymentMethods } from "@hooks/index";
import { useTranslation } from "react-i18next";
import { StationaryPaymentMethod } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { TransKey } from "@globalTypes/i18next";

export const StationaryPaymentsSection = ({
  physiotherapistId,
}: {
  physiotherapistId?: number;
}) => {
  const { t } = useTranslation();
  const paymentMethods = useTherapistPaymentMethods(physiotherapistId);

  const { gapSmall } = globalStyles;

  if (!paymentMethods?.stationaryMethods?.length) return null;

  return (
    <View style={gapSmall}>
      {Object.keys(paymentMethods.stationaryMethods[0])
        .filter(
          e =>
            typeof paymentMethods.stationaryMethods[0][e] === "boolean" &&
            e !== "isActive" &&
            paymentMethods.stationaryMethods[0][e],
        )
        .map((key, index) => (
          <Text
            variant="bodyMedium"
            key={`stationary-payment-method-${key}-${index}`}>{`\u2022 ${t(
            {
              [StationaryPaymentMethod.blik]: "T01136",
              [StationaryPaymentMethod.card]: "T01135",
              [StationaryPaymentMethod.cash]: "T01134",
              [StationaryPaymentMethod.phoneTransfer]: "T01137",
            }[key] as TransKey,
          )}`}</Text>
        ))}
    </View>
  );
};

export const AcceptablePaymentsSection = ({
  physiotherapistId,
  displayDivider,
}: {
  physiotherapistId?: number;
  displayDivider?: boolean;
}) => {
  const { t } = useTranslation();
  const { acceptableOnlinePaymentsDescription, ...paymentMethods } =
    useTherapistPaymentMethods(physiotherapistId);

  const { gapSmall, gapMedium, gapLarge } = globalStyles;

  return (
    <View style={displayDivider ? gapMedium : gapLarge}>
      {acceptableOnlinePaymentsDescription !== null && (
        <>
          <Text variant="titleMedium">
            {acceptableOnlinePaymentsDescription}
          </Text>
          <Divider bold />
        </>
      )}
      {paymentMethods?.stationaryMethods?.[0].isActive && (
        <View style={gapSmall}>
          <Text variant="titleMedium">{t("T01143")}</Text>
          <StationaryPaymentsSection physiotherapistId={physiotherapistId} />
          <Divider bold />
        </View>
      )}
    </View>
  );
};
