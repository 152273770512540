import { StyleSheet } from "react-native";
import { palettes } from "./colors";
import { spacing16, spacing24, spacing4, spacing8 } from "./spacing";

export const globalStyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: spacing16,
  },
  scrollContainer: {
    padding: spacing16,
  },

  flex: {
    flex: 1,
  },

  marginBottomSmall: { marginBottom: spacing4 },
  marginBottomMedium: { marginBottom: spacing8 },
  marginTopLarge: { marginTop: spacing16 },

  gapSmall: { gap: spacing4 },
  gapMedium: { gap: spacing8 },
  gapLarge: { gap: spacing16 },

  loading: { flex: 1, justifyContent: "center", alignContent: "center" },
  flatListSeparator: {
    backgroundColor: palettes.neutralVariant[80],
    alignSelf: "center",
  },
  backdrop: {
    backgroundColor: palettes.primary[0],
    opacity: 0.5,
  },
  fab: {
    position: "absolute",
    bottom: spacing24,
    right: spacing24,
    backgroundColor: palettes.primary[40],
  },
  fontBolded: {
    fontWeight: "bold",
    fontFamily: "Poppins",
  },
});
