import Avatar from "@components/Avatar";
import { globalStyles } from "@styles/global";
import { getDate } from "@utils/date";
import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { IconButton, Text } from "react-native-paper";

type Props = {
  userName: string;
  userId: number;
  createdAt: string;
  avatar?: string;
  rating?: JSX.Element;
  onMenuPress?: () => void;
};
const CardWithPhotoAndDate: FC<Props> = ({
  userName,
  userId,
  createdAt,
  avatar,
  rating,
  onMenuPress,
}) => {
  const { gapSmall, gapMedium } = globalStyles;
  const { container, flexDirectionRow } = styles;

  return (
    <View style={[container, gapMedium]}>
      <View style={[flexDirectionRow, gapMedium, { flex: 1 }]}>
        <Avatar firstName={userName} userId={userId} image={avatar} />
        <View style={[gapSmall, { flex: 1 }]}>
          <Text variant="titleMedium">{userName}</Text>
          <View style={[flexDirectionRow, gapMedium]}>
            {rating}
            <Text variant="bodyMedium">{getDate(createdAt)}</Text>
          </View>
        </View>
      </View>
      {onMenuPress && (
        <View>
          <IconButton icon="dots-vertical" onPress={onMenuPress} />
        </View>
      )}
    </View>
  );
};

export default CardWithPhotoAndDate;

const styles = StyleSheet.create({
  container: { flexDirection: "row", justifyContent: "space-between" },
  flexDirectionRow: { flexDirection: "row", alignItems: "center" },
});
