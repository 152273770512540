import { TransKey } from "@globalTypes/i18next";
import { globalStyles } from "@styles/global";
import { useAppTheme } from "@styles/theme";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, View, ViewStyle } from "react-native";
import { List, ListItemProps as RNPListItemProps } from "react-native-paper";

export type ListItemProps = Omit<RNPListItemProps, "description" | "title"> & {
  name: TransKey;
  icon: string | ReactElement;
  description?: TransKey;
  hidden?: boolean;
};

type ListItemsProps = { items: ListItemProps[]; style?: StyleProp<ViewStyle> };

const ListItems: FC<ListItemsProps> = ({ items, style }) => {
  const { t } = useTranslation();
  const {
    colors: { surface },
  } = useAppTheme();
  return (
    <View style={[globalStyles.gapMedium, style]}>
      {items.map((e, i) => {
        const { name, icon, style, description, hidden, ...props } = e;
        if (hidden) return null;
        return (
          <List.Item
            key={`section-${name}-${i}`}
            title={t(name)}
            description={t(description)}
            left={props =>
              typeof icon === "string" ? (
                <List.Icon {...props} icon={icon} />
              ) : (
                icon
              )
            }
            right={props => <List.Icon {...props} icon="menu-right" />}
            style={[
              {
                backgroundColor: surface,
              },
              style,
            ]}
            {...props}
          />
        );
      })}
    </View>
  );
};

export default ListItems;
