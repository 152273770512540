import { FC } from "react";
import GeneralFront from "./GeneralFront";
import GeneralBack from "./GeneralBack";
import { BackAndFrontModelProps } from "../humanModel.types";

const GeneralModel: FC<BackAndFrontModelProps> = ({ orientation, ...props }) =>
  orientation === "front" ? (
    <GeneralFront
      key={`general-model-front-${props.defaultSelected?.length}`}
      {...props}
    />
  ) : (
    <GeneralBack
      key={`general-model-back-${props.defaultSelected?.length}`}
      {...props}
    />
  );

export default GeneralModel;
