import AddressWithMapRedirect from "@components/Address/AddressWithWebviewRedirect";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import ReportBottomSheetContent from "@components/BottomSheetContents/ReportBottomSheetContent";
import Snackbar from "@components/Snackbar/Snackbar";
import ReportUserButton from "@components/buttons/ReportUserButton";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { capitalize } from "lodash";
import { FC, ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";
import { Host } from "react-native-portalize";
import { FetchError } from "../../../components/errors";
import { TabItemProps, usePhysiotherapist } from "./PhysiotherapistProfile";
import SingleInformationSection from "./SingleInformationSection";
import { AcceptablePaymentsSection } from "@components/index";
import { MASSAGE_TECHNICIAN_SPECIALIZATION_ID } from "@utils/constants";

type Section = {
  title: string;
  content?: string;
  otherContent?: ReactElement;
};

const InformationTab: FC<TabItemProps> = ({ id }) => {
  const { t } = useTranslation();
  const { gapMedium, gapSmall, loading } = globalStyles;
  const { data, isLoading, isError, refetch } = usePhysiotherapist(id);
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  const renderSection = useCallback(
    ({ title, content, otherContent }: Section) => (
      <View style={gapSmall} key={title}>
        <Text variant="titleMedium">{title}:</Text>
        {content && <Text variant="bodyMedium">{content}</Text>}
        {otherContent}
        <Divider bold />
      </View>
    ),
    [gapSmall],
  );

  if (isLoading) return <ActivityIndicator style={loading} />;

  const { address, aboutMe, education, experience, spokenLanguages, licence } =
    data;

  const handleReportSuccess = () => {
    setReportModalVisible(false);
    setSnackbarVisible(true);
  };

  const hideSnackbar = () => setSnackbarVisible(false);
  return (
    <Host>
      <View style={{ flex: 1 }}>
        <View style={[gapMedium, { flex: 1 }]}>
          {isError ? (
            <FetchError action={refetch} coverScreen={false} />
          ) : (
            <>
              {address &&
                renderSection({
                  title: t("T00139"),
                  otherContent: <AddressWithMapRedirect physioId={id} />,
                })}
              {aboutMe && (
                <SingleInformationSection
                  title={t("T00258")}
                  content={aboutMe}
                  showDivider
                />
              )}
              {spokenLanguages &&
                renderSection({
                  title: t("T00604"),
                  otherContent: (
                    <>
                      {spokenLanguages.map(({ language, languageFull }) => (
                        <Text
                          variant="bodyMedium"
                          style={{ marginStart: spacing8 }}
                          key={`language-${language}`}>{`\u2022 ${capitalize(
                          languageFull,
                        )}`}</Text>
                      ))}
                    </>
                  ),
                })}
              {!!licence?.licenceNumber &&
                licence?.specialization?.id !==
                  MASSAGE_TECHNICIAN_SPECIALIZATION_ID && (
                  <View style={gapMedium}>
                    <View style={gapSmall}>
                      <Text variant="titleMedium">{t("T01234")}:</Text>
                      <Text variant="bodyMedium">{licence.licenceNumber}</Text>
                    </View>
                    <Divider bold />
                  </View>
                )}
              {experience && (
                <View style={gapMedium}>
                  <View style={gapSmall}>
                    <Text variant="titleMedium">{t("T00265")}:</Text>
                    <Text variant="bodyMedium">{`${t("T00268", {
                      years: experience.years,
                    })}`}</Text>
                    {!!experience?.additionalExperience?.length && (
                      <SingleInformationSection
                        content={experience.additionalExperience.map(
                          ({ description }) => description,
                        )}
                      />
                    )}
                  </View>
                  <Divider bold />
                </View>
              )}
              {education && (
                <SingleInformationSection
                  content={education}
                  title={t("T00269")}
                  showDivider
                />
              )}
              <AcceptablePaymentsSection
                physiotherapistId={id}
                displayDivider
              />
              <ReportUserButton
                label="T00558"
                onPress={() => setReportModalVisible(true)}
              />
            </>
          )}
        </View>
        <BottomModalContainer
          modalVisible={reportModalVisible}
          setModalVisible={setReportModalVisible}>
          <ReportBottomSheetContent
            recipient={id}
            onSuccess={handleReportSuccess}
          />
        </BottomModalContainer>
        <Snackbar
          visible={snackbarVisible}
          onDismiss={hideSnackbar}
          onIconPress={hideSnackbar}
          text="T00560"
          style={{ marginHorizontal: 0, marginBottom: 0 }}
        />
      </View>
    </Host>
  );
};

export default InformationTab;
