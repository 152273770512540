import { Appointment } from "@screens/Appointments/appointment.types";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import {
  getPhysiotherapistBasicData,
  getPatientBasicData,
} from "@services/ApiService/users";
import { useQuery } from "@tanstack/react-query";
import { createDataForCalendarEvent, RNCalendarEvents } from "@utils/calendar";
import { showSnackbar } from "@utils/snackbarHelper";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "react-native";
import { AuthorizationStatus } from "react-native-calendar-events";
import { openSettings } from "react-native-permissions";
import { useUserDetails } from "./user/useUserDetails";

type UseAddEventToCalendarOptions = {
  data: Appointment;
  customAppointment?: boolean;
};

export const useAddEventToCalendar = ({
  data,
  customAppointment,
}: UseAddEventToCalendarOptions) => {
  const [calendarPermissionsModalVisible, setCalendarPermissionsModalVisible] =
    useState(false);

  const { t } = useTranslation();
  const { isPatient } = useUserDetails();

  const {
    data: userBasicData,
    isRefetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.basicData(
      isPatient ? data?.physiotherapist : data?.patient,
    ),
    queryFn: async () => {
      if (isPatient)
        return await getPhysiotherapistBasicData(data?.physiotherapist);
      return await getPatientBasicData(data?.patient);
    },
    enabled: false,
  });

  const displayAlert = useCallback(() => {
    Alert.alert(t("T01125"), t("T01126"), [
      {
        text: t("T00145"),
        onPress: () => {},
        style: "cancel",
      },
      {
        text: t("T00002"),
        onPress: () =>
          openSettings().catch(err => {
            console.error(err);
            alert(t("T00832"));
          }),
      },
    ]);
  }, [t]);

  const handleCalendarPermissions = useCallback(
    async (calendarPermissionStatus: AuthorizationStatus) => {
      if (calendarPermissionStatus === "authorized") {
        !customAppointment && (await refetch());
        try {
          const { title, ...other } = await createDataForCalendarEvent(
            data,
            userBasicData,
          );

          await RNCalendarEvents.default.saveEvent(title, other);
          showSnackbar({
            message: t("T01049"),
          });
          setCalendarPermissionsModalVisible(false);
        } catch (e) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          alert(e?.message);
        }
        return;
      }

      return displayAlert();
    },
    [customAppointment, data, displayAlert, refetch, t, userBasicData],
  );

  const handleConfirm = useCallback(async () => {
    const calendarPermissionStatus =
      await RNCalendarEvents.default.checkPermissions(false);
    if (calendarPermissionStatus === "undetermined") {
      const permission = await RNCalendarEvents.default.requestPermissions(
        false,
      );
      return handleCalendarPermissions(permission);
    }
    return handleCalendarPermissions(calendarPermissionStatus);
  }, [handleCalendarPermissions]);

  const saveEventToSystemCalendar = useCallback(async () => {
    const calendarPermissionStatus =
      await RNCalendarEvents.default.checkPermissions(false);

    if (
      calendarPermissionStatus === "undetermined" ||
      calendarPermissionStatus === "denied"
    ) {
      return setCalendarPermissionsModalVisible(true);
    }
    return handleCalendarPermissions(calendarPermissionStatus);
  }, [handleCalendarPermissions]);

  return {
    isRefetching,
    isError,
    saveEventToSystemCalendar,
    handleConfirm,
    calendarPermissionsModalVisible,
    refetch,
    setCalendarPermissionsModalVisible,
  };
};
