import AsyncStorage from "@react-native-async-storage/async-storage";
import { useCallback, useEffect, useState } from "react";

const SPECIALIZATION_ID_KEY = "specialization_id";

type UseDisplayDefaultSpecializationOptions = {
  specializationData: { id: number; name: string };
  onSelectSpecialization: (specialization: string) => void;
};

export const useDisplayDefaultSpecialization = ({
  specializationData,
  onSelectSpecialization,
}: UseDisplayDefaultSpecializationOptions) => {
  const [defaultValueForDropdown, setDefaultValueForDropdown] =
    useState<string>(null);

  const setSpecId = useCallback(
    async (specializationId: string) => {
      if (!specializationId) return;
      if (!specializationData?.id) {
        await AsyncStorage.setItem(
          SPECIALIZATION_ID_KEY,
          specializationId.toString(),
        );
      }
      onSelectSpecialization(specializationId);
    },
    [onSelectSpecialization, specializationData?.id],
  );

  const getDefaultSpecializationValue = useCallback(async () => {
    const verifiedSpecializationIdExists = !!specializationData?.id;
    const specializationInAsyncStorage = await AsyncStorage.getItem(
      SPECIALIZATION_ID_KEY,
    );

    if (verifiedSpecializationIdExists) {
      if (specializationInAsyncStorage) {
        await AsyncStorage.removeItem(SPECIALIZATION_ID_KEY);
      }
      return specializationData?.id.toString();
    } else if (specializationInAsyncStorage) {
      return specializationInAsyncStorage;
    }
    return null;
  }, [specializationData?.id]);

  useEffect(() => {
    void getDefaultSpecializationValue().then(setDefaultValueForDropdown);
  }, [getDefaultSpecializationValue]);

  return {
    setSpecId,
    defaultValueForDropdown,
  };
};
