import { AppbarTitleWithBackAction } from "@components/Appbar";
import { ButtonBasic } from "@components/Button/Button.types";
import { AbsoluteBlurredFooter } from "@components/Footers";
import HumanModelWithController from "@components/HumanModel/HumanModelWithController";
import { GenderType } from "@contexts/AuthContext/user.types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHumanModelSchemas } from "@hooks/humanModel/useHumanModelSchemas";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { ExercisesFiltersParamsType } from "@screens/TrainingsAndExercises/Physiotherapist/SearchExercises/SearchExercises";
import { globalStyles } from "@styles/global";
import { isANDROID } from "@utils/constants";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Control, FieldError, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Modal } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Text } from "react-native-paper";

type HumanModelModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  humanGeneral: string[];
  humanMuscles: string[];
  humanBones: string[];
  setBodyParts: Dispatch<SetStateAction<ExercisesFiltersParamsType>>;
  clearBodyParts: boolean;
  setNumberOfBodyParts: Dispatch<SetStateAction<number | null>>;
};

const statusBarHeightPropValue = isANDROID ? { statusBarHeight: 0 } : {};

const HumanModelModal: FC<HumanModelModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  humanGeneral,
  humanMuscles,
  humanBones,
  setBodyParts,
  clearBodyParts = false,
  setNumberOfBodyParts,
}) => {
  const [footerHeight, setFooterHeight] = useState(0);

  const { humanModelSchema } = useHumanModelSchemas();

  const { t } = useTranslation();
  const { gender } = useUserDetails();

  const { scrollContainer, gapLarge } = globalStyles;

  const {
    control,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(humanModelSchema),
    defaultValues: {
      humanGeneral: humanGeneral || [],
      humanBones: humanBones || [],
      humanMuscles: humanMuscles || [],
      gender,
    },
  });

  useEffect(() => {
    if (clearBodyParts) reset();
  }, [clearBodyParts, reset]);

  const humanBonesField = watch("humanBones");
  const humanMusclesField = watch("humanMuscles");
  const humanGeneralField = watch("humanGeneral");

  const selectedPointsTotalNumber =
    humanGeneralField.length +
    humanMusclesField.length +
    humanBonesField.length;

  useEffect(() => {
    setNumberOfBodyParts(selectedPointsTotalNumber);
  }, [selectedPointsTotalNumber, setNumberOfBodyParts]);

  const footerButtons: ButtonBasic[] = useMemo(
    () => [
      {
        key: "clear",
        onPress: () => {
          reset();
          setBodyParts(prevState => {
            return {
              ...prevState,
              humanGeneral: [],
              humanMuscles: [],
              humanBones: [],
            };
          });
          setIsModalVisible(false);
        },
        label: "T01343",
        mode: "outlined",
      },
      {
        key: "select",
        onPress: () => {
          setBodyParts(prevState => {
            return {
              ...prevState,
              humanGeneral: humanGeneralField,
              humanMuscles: humanMusclesField,
              humanBones: humanBonesField,
            };
          });
          setIsModalVisible(false);
        },
        label: selectedPointsTotalNumber
          ? t("T01414", { value: selectedPointsTotalNumber })
          : t("T00552"),
      },
    ],
    [
      selectedPointsTotalNumber,
      t,
      reset,
      setIsModalVisible,
      setBodyParts,
      humanGeneralField,
      humanMusclesField,
      humanBonesField,
    ],
  );

  const onModalClose = useCallback(() => {
    setValue("humanGeneral", humanGeneral);
    setValue("humanMuscles", humanMuscles);
    setValue("humanBones", humanBones);
    setIsModalVisible(false);
  }, [humanBones, humanGeneral, humanMuscles, setIsModalVisible, setValue]);

  return (
    <Modal
      visible={isModalVisible}
      animationType="slide"
      onRequestClose={() => setIsModalVisible(false)}>
      <AppbarTitleWithBackAction
        title={t("T01415")}
        onClose={onModalClose}
        {...statusBarHeightPropValue}
      />
      <KeyboardAwareScrollView
        contentContainerStyle={[
          scrollContainer,
          gapLarge,
          { paddingBottom: footerHeight },
        ]}>
        <Text variant="titleMedium">{t("T01416")}</Text>
        <HumanModelWithController
          gender={watch("gender")}
          onGenderChange={(value: GenderType) => setValue("gender", value)}
          humanBones={humanBonesField}
          humanMuscles={humanMusclesField}
          humanGeneral={humanGeneralField}
          control={control as unknown as Control}
          errors={errors as { [x: string]: FieldError[] }}
        />
      </KeyboardAwareScrollView>
      <AbsoluteBlurredFooter
        onLayout={height => setFooterHeight(height)}
        buttons={footerButtons}
      />
    </Modal>
  );
};

export default HumanModelModal;
