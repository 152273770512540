import { TrainingDayDate } from "@screens/TrainingsAndExercises/training.types";
import { addDays, differenceInDays, format } from "date-fns";

export const getDatesFromPeriodByFrequency = ({
  frequency,
  dateFrom,
  dateTo,
}: {
  frequency: number;
  dateFrom: string;
  dateTo: string;
}): TrainingDayDate[] => {
  const daysDifference = differenceInDays(new Date(dateTo), new Date(dateFrom));
  if (daysDifference < 0) return [];
  if (daysDifference === 1) return [{ executionDate: dateFrom }];
  const dates: string[] = [];
  for (let i = 0; i <= daysDifference; i++) {
    if (i % frequency === 0) {
      const nextDay = format(
        addDays(new Date(dateFrom).getTime(), i),
        "yyyy-MM-dd",
      );
      dates.push(nextDay);
    }
  }
  return dates.map(day => ({
    executionDate: day,
  }));
};
