import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";
import { RefreshControl, ScrollView, StyleSheet, View } from "react-native";
import { ActivityIndicator, FAB, Text } from "react-native-paper";

import NoteTile from "@components/Tile/NoteTile";
import { FetchError } from "@components/errors";
import { getPersonalNotes } from "@services/ApiService/profile";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { RootStackParamList } from "../../../../navigators/navigation.types";
import { globalStyles } from "../../../../styles/global";
import { queryKeysPersonalNotes } from "./queryKeysPersonalNotes";
import { spacing24 } from "@styles/spacing";

type PersonalNotesProps = PropsWithChildren<
  NativeStackScreenProps<RootStackParamList, "PersonalNotes">
>;

const PersonalNotes: FC<PersonalNotesProps> = ({
  navigation: { navigate },
}) => {
  const { t } = useTranslation();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysPersonalNotes.list(),
    queryFn: async () => await getPersonalNotes(),
  });
  const { container, gapLarge } = globalStyles;
  return (
    <View style={container}>
      <ScrollView
        contentContainerStyle={gapLarge}
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={refetch} />
        }>
        <Text variant="titleMedium">{t("T00397")}</Text>
        <Text variant="titleSmall">{t("T00398")}</Text>
        {isLoading ? (
          <ActivityIndicator style={globalStyles.loading} />
        ) : isError ? (
          <FetchError action={refetch} coverScreen={false} />
        ) : (
          data?.map(({ id }) => (
            <NoteTile
              key={id}
              noteId={id}
              mode="outlined"
              onPress={() => navigate("CreateAndEditNote", { id })}
            />
          ))
        )}
      </ScrollView>
      <FAB
        icon="plus"
        onPress={() => navigate("CreateAndEditNote")}
        style={styles.fab}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  fab: {
    position: "absolute",
    bottom: spacing24,
    right: spacing24,
  },
});

export default PersonalNotes;
