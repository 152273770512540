export enum StatusType {
  STATIONARY = "stationary",
  DURING_THE_DURATION_STATIONARY = "during the duration stationary",
  STATIONARY_ENDED = "stationary ended",
  ONLINE = "online",
  DURING_THE_DURATION_ONLINE = "during the duration online",
  ONLINE_ENDED = "online ended",
  SCHEDULED = "scheduled",
  NOT_PAID = "not_paid",
  PATIENT_ABSENT = "patient_absent",
  CANCELED = "canceled",
}

export type StatusAppointmentType = "Stationary" | "Online";
export enum TrainingDayStatus {
  FINISHED = "finished",
  NOT_FINISHED = "not finished",
  SCHEDULED = "scheduled",
}

export type StatusInfoType = "info" | "warning" | "error" | "success";
