import {
  SvgLastPageArrowKeyboard,
  SvgLeftArrowKeyboard,
  SvgRightArrowKeyboard,
} from "@assets/svg";
import { checkSelectedMonthAndYear } from "@components/Charts/chartUtils";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing12, spacing16, spacing20 } from "@styles/spacing";
import { addMonths, subMonths } from "date-fns";
import { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { UseQueryResult } from "@tanstack/react-query";
import { getMonthWithYear } from "@utils/date";
import { TransKey } from "@globalTypes/i18next";
import { useUserDetails } from "@hooks/index";

type BarChartMonthsProps = {
  currentDate?: Date;
  setCurrentDate?: Dispatch<SetStateAction<Date>>;
  chartTitle: TransKey;
  chartDescription: TransKey;
  toolTipText?: string;
  refetchData?: UseQueryResult["refetch"];
  isLoading?: boolean;
  renderChart?: () => ReactNode;
  renderChartHeader?: () => ReactNode;
  emptyData?: boolean;
  allowOneMore?: boolean;
};

const BartChartMonths: FC<BarChartMonthsProps> = ({
  currentDate,
  setCurrentDate,
  chartTitle,
  chartDescription,
  refetchData,
  isLoading,
  renderChart,
  emptyData,
  allowOneMore,
  renderChartHeader,
}) => {
  const { t } = useTranslation();

  const { language } = useUserDetails();

  const leftArrowDisabled = checkSelectedMonthAndYear(currentDate, false);
  const rightArrowDisabled = checkSelectedMonthAndYear(
    currentDate,
    true,
    allowOneMore,
  );

  return (
    <>
      <View style={styles.descriptionContainer}>
        <Text variant="titleMedium">{t(chartTitle)}</Text>
        <Text variant="labelMedium">{t(chartDescription)}</Text>
        {setCurrentDate && (
          <View style={styles.arrowContainer}>
            <Text variant="titleMedium">
              {getMonthWithYear(currentDate, language)}
            </Text>
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                onPress={async () => {
                  setCurrentDate(prevDate => subMonths(prevDate, 1));
                  await refetchData?.();
                }}
                disabled={leftArrowDisabled}
                style={{ opacity: leftArrowDisabled ? 0.5 : 1 }}>
                <SvgLeftArrowKeyboard />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={async () => {
                  setCurrentDate(prevDate => addMonths(prevDate, 1));
                  await refetchData?.();
                }}
                disabled={rightArrowDisabled}
                style={{ opacity: rightArrowDisabled ? 0.5 : 1 }}>
                <SvgRightArrowKeyboard />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => setCurrentDate(new Date())}
                disabled={rightArrowDisabled}
                style={{ opacity: rightArrowDisabled ? 0.5 : 1 }}>
                <SvgLastPageArrowKeyboard />
              </TouchableOpacity>
            </View>
          </View>
        )}
        {renderChartHeader?.()}
        <View>
          {isLoading ? (
            <ActivityIndicator style={globalStyles.loading} />
          ) : renderChart && !emptyData ? (
            renderChart()
          ) : (
            emptyData && (
              <Text style={{ alignSelf: "center" }} variant="bodyMedium">
                {t("T00541")}
              </Text>
            )
          )}
        </View>
      </View>
    </>
  );
};

export default BartChartMonths;

const styles = StyleSheet.create({
  tooltipContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: spacing20,
  },
  toolTipStyle: {
    paddingHorizontal: 4,
    paddingVertical: 2,
    borderRadius: 2,
  },
  descriptionContainer: {
    borderWidth: 1,
    borderRadius: spacing12,
    borderColor: palettes.neutral[80],
    padding: spacing12,
    gap: spacing16,
  },
  arrowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
