import PermissionModal from "@components/Modals/PermissionModal";
import { PermissionType } from "@globalTypes/common.types";
import { useCheckNotificationsInMessageCenter } from "@hooks/index";
import { useState } from "react";

const MessageCenterPermissionModal = () => {
  const [isPermissionModalVisible, setIsPermissionModalVisible] =
    useState(false);

  const { handleCheckNotifications } = useCheckNotificationsInMessageCenter({
    setIsPermissionModalVisible,
  });

  return (
    <PermissionModal
      isModalVisible={isPermissionModalVisible}
      setIsModalVisible={setIsPermissionModalVisible}
      onPressConfirm={handleCheckNotifications}
      type={PermissionType.notifications}
    />
  );
};

export default MessageCenterPermissionModal;
