import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  ListRenderItem,
  SectionList,
  View,
  RefreshControl,
} from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";

import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import RecordSharedFor from "./RecordSharedFor";

import { RecordTitleWithIcon } from "@components/MedicalRecord/RecordTitle";
import { useAuth } from "@contexts/AuthContext/auth";
import { AccountType } from "@contexts/AuthContext/user.types";
import { RootStackParamList } from "@navigators/navigation.types";
import {
  getMedicalRecord,
  updateSharedMedicalRecord,
} from "@services/ApiService/medicalRecords";
import { globalStyles } from "@styles/global";
import { SafeAreaView } from "react-native-safe-area-context";
import { queryKeysMedicalRecord } from "./queryKeysMedicalRecord";
import { SectionsType } from "@globalTypes/genericTypes.types";
import { ShareMedicalRecord } from "./MedicalRecords.types";
import { BottomModalWithButton } from "@components/BottomSheet";
import { ButtonBottomSheet } from "@components/Button/Button.types";
import { AxiosError } from "axios";
import { useErrors } from "@hooks/useErrors";
import ShareRecordForm from "@components/Forms/ShareRecordForm";

const ShareRecord: FC<
  PropsWithChildren<NativeStackScreenProps<RootStackParamList, "ShareRecord">>
> = ({
  navigation: { navigate },
  route: {
    params: { id: medicalRecordId },
  },
}) => {
  const { loading, flex, gapLarge, gapMedium, scrollContainer } = globalStyles;

  const {
    user: { id, accountType },
  } = useAuth();
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);

  const [currentSelectedSharedId, setCurrentSelectedSharedId] =
    useState<number>(null);
  const [currentSelectedUserId, setCurrentSelectedUserId] =
    useState<number>(null);
  const [shareFormModalVisible, setShareFormModalVisible] = useState(false);
  const [calendarModalVisible, setCalendarModalVisible] = useState(false);

  const [stopSharingModalVisible, setStopSharingModalVisible] = useState(false);
  const queryClient = useQueryClient();

  const isPatient = accountType === AccountType.PATIENT;
  const {
    recordDetails,
    sharedBetweenMeAndOtherUserList,
    sharedToOtherUserDetails,
  } = queryKeysMedicalRecord;
  const {
    data,
    isLoading,
    isError,
    refetch: queryRefetch,
  } = useQuery({
    queryKey: recordDetails(medicalRecordId),
    queryFn: async () => await getMedicalRecord(medicalRecordId),
  });

  const { setErrorsFromResponse } = useErrors();

  const onChangeShareDateSuccess = useCallback(async () => {
    setStopSharingModalVisible(false);
    await Promise.all([
      queryClient.invalidateQueries(recordDetails(medicalRecordId)),
      queryClient.invalidateQueries(
        sharedBetweenMeAndOtherUserList(currentSelectedSharedId),
      ),
      queryClient.invalidateQueries(
        sharedToOtherUserDetails(currentSelectedSharedId),
      ),
    ]);
  }, [
    currentSelectedSharedId,
    medicalRecordId,
    queryClient,
    recordDetails,
    sharedBetweenMeAndOtherUserList,
    sharedToOtherUserDetails,
  ]);

  const { mutate: changeShareDate, isLoading: isStopSharingRecordLoading } =
    useMutation({
      mutationFn: async (dueDute?: string | null) =>
        await updateSharedMedicalRecord(currentSelectedSharedId, dueDute),
      onSuccess: onChangeShareDateSuccess,
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    });

  const sections: SectionsType<ShareMedicalRecord> = useMemo(() => {
    const sectionData: SectionsType<ShareMedicalRecord> = [];
    const currentRecords = data?.sharedMedicalRecords?.filter(
      r => new Date(r.dueDate) >= new Date(),
    );

    const pastRecords = data?.sharedMedicalRecords?.filter(
      r => new Date(r.dueDate) < new Date(),
    );

    currentRecords?.length &&
      sectionData.push({
        title: "T00979",
        data: currentRecords,
      });
    pastRecords?.length &&
      sectionData.push({
        title: "T00980",
        data: pastRecords,
      });

    return sectionData;
  }, [data?.sharedMedicalRecords]);

  const renderSectionHeader = useCallback(
    ({
      section: { title, data },
    }: {
      section: SectionsType<ShareMedicalRecord>[0];
    }) =>
      data?.length ? <Text variant="titleMedium">{`${t(title)}`}</Text> : null,
    [t],
  );

  const renderHeaderComponent = useCallback(
    () => (
      <View style={gapLarge}>
        <RecordTitleWithIcon text={data?.title} />
        <Divider />
        {data.owner === id && (
          <PrimaryButton
            label="T00349"
            onPress={() =>
              navigate("PhysioListForRecordSharing", { medicalRecordId })
            }
          />
        )}
      </View>
    ),
    [data.owner, data?.title, gapLarge, id, medicalRecordId, navigate],
  );

  const renderEmptyComponent = () => (
    <Text variant="titleMedium">{t("T00981")}</Text>
  );

  const handleTilePress = (
    setterFunc: Dispatch<SetStateAction<boolean>>,
    { id, asignee }: ShareMedicalRecord,
  ) => {
    setterFunc(true);
    setCurrentSelectedSharedId(id);
    setCurrentSelectedUserId(asignee);
  };

  const renderItem: ListRenderItem<ShareMedicalRecord> = useCallback(
    ({ item: { id, asignee, dueDate } }) => {
      const isPastOrDataNotSet = Boolean(new Date(dueDate) < new Date());
      return (
        <RecordSharedFor
          id={id}
          isPatient={isPatient}
          onPress={() =>
            isPastOrDataNotSet
              ? handleTilePress(setShareFormModalVisible, { id, asignee })
              : handleTilePress(setModalVisible, { id, asignee })
          }
        />
      );
    },
    [isPatient],
  );

  const buttons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: "T00866",
        onPress: () => {
          setModalVisible(false);
          setTimeout(() => setStopSharingModalVisible(true), 500);
        },
        loading: false,
        mode: "outlined",
      },
      {
        label: "T00982",
        onPress: () => {
          setModalVisible(false);
          setTimeout(() => setShareFormModalVisible(true), 500);
        },
        mode: "outlined",
        loading: false,
      },
    ],
    [],
  );

  const stopSharingModalButtons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: "T00145",
        onPress: () => {
          setStopSharingModalVisible(false);
        },
        loading: false,
        mode: "outlined",
      },
      {
        label: "T00866",
        onPress: () => changeShareDate(null),
        loading: isStopSharingRecordLoading,
      },
    ],
    [isStopSharingRecordLoading, changeShareDate],
  );

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={queryRefetch} />;

  return (
    <SafeAreaView style={flex} edges={["right", "bottom", "left"]}>
      <SectionList
        sections={sections}
        renderItem={renderItem}
        keyExtractor={({ id }, i) => `sharedMedicalRecord-to:${id}-${i}`}
        renderSectionHeader={renderSectionHeader}
        contentContainerStyle={[gapMedium, scrollContainer]}
        stickySectionHeadersEnabled={false}
        ListHeaderComponent={renderHeaderComponent()}
        ListEmptyComponent={renderEmptyComponent()}
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={queryRefetch} />
        }
      />
      <BottomModalWithButton
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        buttons={buttons}
        title="T00344">
        <RecordSharedFor
          id={currentSelectedSharedId}
          isPatient={isPatient}
          isInBottomSheet
        />
      </BottomModalWithButton>
      <BottomModalWithButton
        modalVisible={stopSharingModalVisible}
        setModalVisible={setStopSharingModalVisible}
        buttons={stopSharingModalButtons}
        title="T00983"
        subtitles={["T00984"]}
      />
      <ShareRecordForm
        sharedRecordId={currentSelectedSharedId}
        asignee={currentSelectedUserId}
        medicalRecord={medicalRecordId}
        setShareFormModalVisible={setShareFormModalVisible}
        shareFormModalVisible={shareFormModalVisible}
        setCalendarModalVisible={setCalendarModalVisible}
        calendarModalVisible={calendarModalVisible}
      />
    </SafeAreaView>
  );
};

export default ShareRecord;
