import {
  SvgHeaderLogo,
  SvgProfileCompletedIcon,
  SvgWarningAppbar,
} from "@assets/svg";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import VerificationActionTile from "@components/Tile/VerificationActionTile";
import { VerificationInfoTileType } from "@hooks/verification/useVerification";
import { useVerificationStatus } from "@hooks/verification/useVerificationStatus";
import { FC, useState } from "react";
import { Appbar } from "react-native-paper";
import { TitleWithLogo } from "./Appbar.types";
import { spacing48, spacing8 } from "@styles/spacing";

const AppbarTitleWithLogoForPhysiotherapist: FC<TitleWithLogo> = ({
  title,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const onPressHandler = () => setModalVisible(prev => !prev);
  const verificationStatus = useVerificationStatus();

  return (
    <>
      <Appbar.Header elevated mode="center-aligned">
        <Appbar.Action
          icon={SvgHeaderLogo}
          size={spacing48}
          style={{ marginStart: -spacing8 }}
        />
        <Appbar.Content title={title} style={{ marginStart: -spacing8 }} />
        <Appbar.Action
          onPress={onPressHandler}
          icon={
            verificationStatus === VerificationInfoTileType.COMPLETED
              ? SvgProfileCompletedIcon
              : SvgWarningAppbar
          }
          size={spacing48}
          style={{ marginEnd: -spacing8 }}
          rippleColor="transparent"
        />
      </Appbar.Header>
      <BottomModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}>
        <VerificationActionTile cb={onPressHandler} showCompletedTile />
      </BottomModalContainer>
    </>
  );
};

export default AppbarTitleWithLogoForPhysiotherapist;
