import { LIST } from "@utils/constants";

const keys = {
  transactionsHistory: "transactionsHistory",
};

export const queryKeysTransactionsHistory = {
  all: [keys.transactionsHistory] as const,
  list: (dateFrom?: string) =>
    [...queryKeysTransactionsHistory.all, LIST, dateFrom] as const,
};
