import { queryKeysRecommendedServices } from "@screens/Common/queryKeysServices";
import { getRecommendedServices } from "@services/ApiService/common";
import { useQuery } from "@tanstack/react-query";

const useRecommendedServices = () =>
  useQuery({
    queryKey: queryKeysRecommendedServices.list(),
    queryFn: getRecommendedServices,
  });

export default useRecommendedServices;
