import BartChartMonths from "@components/Charts/BarChartMonths";
import EarningsTile, { StatsTileType } from "@components/Tile/EarningsTile";
import { FetchError } from "@components/errors";
import {
  getEarningsStatsMonthChart,
  getEarningsStatsYearChart,
} from "@services/ApiService/charts";
import { globalStyles } from "@styles/global";
import { spacing32 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { getMonthAndYearAsNumber } from "@utils/date";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";
import { Divider, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { queryKeysEarningsStatsChart } from "./queryKeysRehabilitatorStatistics";
import { BarChartNew, ChartHeader } from "@components/Charts";
import { completeMonthsWithMissingDays } from "@components/Charts/chartUtils";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { BottomTutorialComponent } from "@components/BottomSheet";
import { TransKey } from "@globalTypes/i18next";

const tutorialSubtitles: TransKey[] = ["T01536", "T01537", "T01538", "T01539"];

const Earnings: FC = () => {
  const [currentDateForMonths, setCurrentDateForMonths] = useState(new Date());
  const { MONTHLY, TOTAL } = StatsTileType;
  const { t } = useTranslation();
  const { scrollContainer, gapLarge } = globalStyles;
  const [tutorialBottomSheetVisible, setTutorialBottomSheetVisible] =
    useState(false);

  const {
    selectedMonthNumber: selectedMonthNumberForMonth,
    selectedYearNumber: selectedYearNumberForMonth,
  } = useMemo(
    () => getMonthAndYearAsNumber(currentDateForMonths),
    [currentDateForMonths],
  );

  const {
    data: monthData,
    isLoading: monthDataLoading,
    isError: monthDataError,
    refetch: refetchMonthData,
  } = useQuery({
    queryFn: async () =>
      await getEarningsStatsMonthChart(
        selectedMonthNumberForMonth,
        selectedYearNumberForMonth,
      ),
    queryKey: queryKeysEarningsStatsChart.month(
      selectedMonthNumberForMonth,
      selectedYearNumberForMonth,
    ),
  });
  const {
    data: yearlyData,
    isLoading: yearlyDataLoading,
    isError: yearlyDataError,
    refetch: refetchYearlyData,
  } = useQuery({
    queryFn: getEarningsStatsYearChart,
    queryKey: queryKeysEarningsStatsChart.year(),
  });

  const renderChartHeader = useCallback(
    () => <ChartHeader data={yearlyData} />,
    [yearlyData],
  );

  const isAnyError = useMemo(
    () => monthDataError || yearlyDataError,
    [monthDataError, yearlyDataError],
  );

  const refetchEverything = async () =>
    await Promise.all([refetchMonthData(), refetchYearlyData()]);

  if (isAnyError) return <FetchError action={refetchEverything} />;

  const monthsEarningsResult = completeMonthsWithMissingDays(
    monthData?.map(e => ({ ...e, value: e.value / 100 })),
    currentDateForMonths,
    true,
  );

  const resultYearData = yearlyData?.map(e => ({
    ...e,
    groupItems: e.groupItems.map(el => ({
      ...el,
      value: el.value / 100,
    })),
  }));

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["bottom", "left", "right"]}>
      <ScrollView
        style={scrollContainer}
        contentContainerStyle={[gapLarge, { paddingBottom: spacing32 }]}>
        <TutorialTextButton
          label="T01540"
          onPress={() => setTutorialBottomSheetVisible(true)}
        />
        <EarningsTile key={`earnings-${MONTHLY}`} type={MONTHLY} />
        <EarningsTile key={`earnings-${TOTAL}`} type={TOTAL} />
        <Divider bold />
        <Text variant="titleMedium">{`${t("T00693")}:`}</Text>
        <BartChartMonths
          currentDate={currentDateForMonths}
          setCurrentDate={setCurrentDateForMonths}
          chartTitle="T00748"
          chartDescription="T00749"
          isLoading={monthDataLoading}
          refetchData={refetchMonthData}
          emptyData={!monthData?.length}
          renderChart={() => (
            <BarChartNew
              data={monthsEarningsResult}
              currentDate={currentDateForMonths}
            />
          )}
        />
        <BartChartMonths
          chartTitle="T00750"
          chartDescription="T00751"
          isLoading={yearlyDataLoading}
          refetchData={refetchYearlyData}
          renderChartHeader={renderChartHeader}
          emptyData={yearlyData?.every(e => e && e[0] === 0 && e[1] === 0)}
          renderChart={() => <BarChartNew data={resultYearData} grouped />}
        />
      </ScrollView>
      <BottomModalContainer
        modalVisible={tutorialBottomSheetVisible}
        setModalVisible={setTutorialBottomSheetVisible}>
        <BottomTutorialComponent
          title="T01535"
          subtitles={tutorialSubtitles}
          onPress={() => setTutorialBottomSheetVisible(false)}
        />
      </BottomModalContainer>
    </SafeAreaView>
  );
};

export default Earnings;
