import { GetLibraryExercisesParams } from "@screens/TrainingsAndExercises/exercise.types";
import { queryKeysExercises } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { getLibraryExercisesList } from "@services/ApiService/exercises";
import {
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { EXERCISES_LIST_PAGE_SIZE } from "@utils/constants";
import { getPageParam } from "@utils/pagination";

type UseExercisesInfiniteQueryOptions = {
  params?: GetLibraryExercisesParams;
  multiLanguageNameOrdering?: boolean;
};

const useExercisesInfiniteQuery = ({
  params,
  multiLanguageNameOrdering,
}: UseExercisesInfiniteQueryOptions) => {
  const { hasNextPage, isFetching, fetchNextPage, ...rest } = useInfiniteQuery({
    queryKey: queryKeysExercises.list(params),
    queryFn: ({ pageParam = 1 }: QueryFunctionContext<QueryKey, number>) =>
      getLibraryExercisesList({
        page: pageParam,
        pageSize: EXERCISES_LIST_PAGE_SIZE,
        multiLanguageNameOrdering,
        ...params,
      }),
    getNextPageParam: lastPage => getPageParam(lastPage.next),
    getPreviousPageParam: firstPage => getPageParam(firstPage.previous),
  });
  const loadMore = async () =>
    hasNextPage && !isFetching && (await fetchNextPage());

  return {
    query: { hasNextPage, isFetching, fetchNextPage, ...rest },
    loadMore,
  };
};

export default useExercisesInfiniteQuery;
