import { FC, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "react-native-paper";
import { spacing8 } from "@styles/spacing";
import { PrimaryButton } from "../buttons";
import BottomModalContainer from "./BottomModalContainer";
import { ButtonBottomSheet } from "../Button/Button.types";
import { TransKey } from "@globalTypes/i18next";

export type BottomModalWithButtonProps = {
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
  title?: TransKey;
  subtitles?: TransKey[];
  buttons?: ButtonBottomSheet[];
  children?: ReactNode;
};

const BottomModalWithButton: FC<BottomModalWithButtonProps> = ({
  modalVisible,
  setModalVisible,
  title,
  subtitles = [],
  buttons = [],
  children,
}) => {
  const { t } = useTranslation();

  const buttonsToDisplay: ButtonBottomSheet[] = useMemo(() => {
    const anyLoading = buttons.some(({ loading }) => loading);
    return buttons.map(({ disabled, ...other }) => {
      return {
        ...other,
        disabled: disabled || anyLoading,
      };
    });
  }, [buttons]);

  return (
    <BottomModalContainer
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}>
      <>
        {title && <Text variant="bodyLarge">{t(title)}</Text>}
        {subtitles.map((subtitle, index) => (
          <Text variant="bodyMedium" key={`${subtitle}-${index}`}>
            {t(subtitle)}
          </Text>
        ))}
        {children}
        {buttonsToDisplay.map((button, index) => (
          <PrimaryButton
            {...button}
            style={{ marginTop: spacing8 }}
            key={`${button.label.toString()}-${index}`}
          />
        ))}
      </>
    </BottomModalContainer>
  );
};

export default BottomModalWithButton;
