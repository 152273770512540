import {
  CancelAppointmentButton,
  PatientAbsentSection,
} from "@components/Appointment";
import { FetchError } from "@components/errors";
import { useErrors } from "@hooks/useErrors";
import { deleteCustomAppointment } from "@services/ApiService/appointments";
import { globalStyles } from "@styles/global";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, ScrollView } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";
import { queryKeysAppointments } from "../queryKeysAppointments";
import { InformationDetails } from "./InformationDetails";

import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { CancelAppointmentBottomSheetContent } from "@components/BottomSheetContents";
import { useAppointmentData } from "@hooks/index";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { showSnackbar } from "@utils/snackbarHelper";
import { AppointmentDetailsAdditionalInfo } from "./AppointmentDetailsAdditionalInfo";
import { AppointmentDetailsHeader } from "./AppointmentDetailsHeader";
import { AppointmentDetailsInfoTiles } from "./AppointmentDetailsInfoTiles";

export const CustomAppointmentDetails: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "CustomAppointmentDetails">
  >
> = ({
  route: {
    params: { appointmentId },
  },
  navigation: { goBack },
}) => {
  const [cancelAppointmentModalVisible, setCancelAppointmentModalVisible] =
    useState(false);
  const [switchOn, setSwitchOn] = useState(false);

  const { scrollContainer, gapLarge } = globalStyles;

  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const queryClient = useQueryClient();

  const {
    data: appointmentDetails,
    isLoading: isAppointmentDetailsLoading,
    isError: isAppointmentDetailsError,
    refetch: refetchAppointmentDetails,
    isSuccess: isAppointmentDetailsSuccess,
  } = useAppointmentData(appointmentId);

  const { mutate: deleteAppointment, isLoading: isDeleting } = useMutation({
    mutationFn: deleteCustomAppointment,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async () => {
      await refreshQuery();
      showSnackbar({ message: t("T01361") });
      goBack();
    },
  });

  useEffect(() => {
    if (isAppointmentDetailsSuccess && Object.keys(appointmentDetails).length) {
      setSwitchOn(appointmentDetails.patientAbsent);
    }
    if (isAppointmentDetailsError) {
      setSwitchOn(false);
    }
  }, [
    isAppointmentDetailsSuccess,
    isAppointmentDetailsError,
    appointmentDetails,
  ]);

  const refreshQuery = async () => {
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: queryKeysAppointments.list(),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysAppointments.listTodayAppointments(),
      }),
    ]);
  };

  const isAnyLoading = useMemo(
    () => isAppointmentDetailsLoading,
    [isAppointmentDetailsLoading],
  );

  const isAnyError = useMemo(
    () => isAppointmentDetailsError,
    [isAppointmentDetailsError],
  );

  const refetchAll = async () =>
    await Promise.all([refetchAppointmentDetails()]);

  if (isAnyLoading) return <ActivityIndicator />;
  if (isAnyError) return <FetchError action={refetchAll} />;

  const { cancelled, physiotherapist } = appointmentDetails;

  return (
    <ScrollView
      contentContainerStyle={[scrollContainer, gapLarge]}
      refreshControl={
        <RefreshControl refreshing={isAnyLoading} onRefresh={refetchAll} />
      }>
      <AppointmentDetailsInfoTiles
        cancelled={cancelled}
        switchOn={switchOn}
        paymentSuccessful
        wasPaymentInitialized
        openPaymentSheet={() => {}}
      />
      <AppointmentDetailsHeader
        physiotherapist={physiotherapist}
        appointmentName={appointmentDetails?.name}
      />
      <Text variant="titleLarge">{t("T00123")}</Text>
      <Divider bold />
      {appointmentDetails?.additionalInformation && (
        <AppointmentDetailsAdditionalInfo
          additionalInfo={appointmentDetails?.additionalInformation}
          appointmentName={appointmentDetails?.name}
        />
      )}
      <PatientAbsentSection
        switchOn={switchOn}
        setSwitchOn={setSwitchOn}
        appointment={appointmentDetails}
      />
      <InformationDetails data={appointmentDetails} customAppointment />
      <CancelAppointmentButton
        appointmentDetails={appointmentDetails}
        setCancelAppointmentModalVisible={setCancelAppointmentModalVisible}
        isPhysiotherapist
        isCanceling={isDeleting}
        onConfirmedDelete={() => deleteAppointment(appointmentId)}
        customAppointment
      />
      <BottomModalContainer
        modalVisible={cancelAppointmentModalVisible}
        setModalVisible={setCancelAppointmentModalVisible}>
        <CancelAppointmentBottomSheetContent
          setModalVisible={setCancelAppointmentModalVisible}
          onCancel={() => {}}
        />
      </BottomModalContainer>
    </ScrollView>
  );
};
