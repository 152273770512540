import { queryKeysPersonalNotes } from "@screens/Profiles/MyProfile/PersonalNotes/queryKeysPersonalNotes";
import { getPersonalNoteDetails } from "@services/ApiService/profile";
import { palettes } from "@styles/colors";
import { useQuery } from "@tanstack/react-query";
import { getDate } from "@utils/date";
import { t } from "i18next";
import { FC } from "react";
import { StyleSheet } from "react-native";
import { ActivityIndicator, Card as RNPCard, Text } from "react-native-paper";
import { theme } from "@styles/theme";
import { FetchError } from "../errors";
import { NoteTileProps } from "./types/Tile.types";

const NoteTile: FC<NoteTileProps> = ({
  noteId,
  mode,
  elevation,
  onPress,
  ...props
}) => {
  const { data, isFetched, isError, refetch } = useQuery({
    queryKey: queryKeysPersonalNotes.details(noteId),
    queryFn: async () => await getPersonalNoteDetails(noteId),
  });

  const cardTitle = <Text variant="titleMedium">{data?.title}</Text>;

  const cardSubtitle = (
    <>
      <Text variant="bodyMedium">{t("T00405")}: </Text>
      <Text variant="bodyMedium" style={{ color: palettes.primary[40] }}>
        {data && getDate(data.editedAt)}
      </Text>
    </>
  );
  const { borderWidth, borderColor, elevatedBackground } = styles;

  return (
    <RNPCard
      {...props}
      elevation={elevation as never}
      mode={mode}
      style={[
        (mode === "contained" || "elevated") && borderWidth,
        borderColor,
        mode === "elevated" && elevatedBackground,
      ]}
      onPress={!isError && onPress}>
      <RNPCard.Content>
        {!isFetched ? (
          <ActivityIndicator />
        ) : isError ? (
          <FetchError action={refetch} coverScreen={false} />
        ) : (
          <RNPCard.Title
            title={cardTitle}
            subtitle={cardSubtitle}
            titleNumberOfLines={2}
          />
        )}
      </RNPCard.Content>
    </RNPCard>
  );
};

export default NoteTile;

const styles = StyleSheet.create({
  borderColor: {
    borderColor: theme.colors.outlineVariant,
  },
  borderWidth: {
    borderWidth: 0.5,
  },

  elevatedBackground: {
    backgroundColor: theme.colors.elevatedCardBackground,
  },
});
