import { StyleSheet, View } from "react-native";
import { globalStyles } from "@styles/global";
import { Divider, List, Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "@components/buttons";
import { Dispatch, FC, SetStateAction } from "react";
import { palettes } from "@styles/colors";
import { spacing16, spacing8 } from "@styles/spacing";
import { AdditionalExperienceType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";

type Props = {
  onPressAddExperience: Dispatch<SetStateAction<boolean>>;
  onPressEditExperience: Dispatch<SetStateAction<boolean>>;
  setCurrentIdToEdit: Dispatch<SetStateAction<number>>;
  data: AdditionalExperienceType[];
};

export const AdditionalExperience: FC<Props> = ({
  onPressAddExperience,
  onPressEditExperience,
  setCurrentIdToEdit,
  data,
}) => {
  const { t } = useTranslation();

  const { gapLarge, gapMedium } = globalStyles;

  return (
    <View style={gapLarge}>
      <Divider />
      <Text variant="bodyMedium">{t("T01190")}</Text>
      <PrimaryButton
        label="T01191"
        onPress={() => onPressAddExperience(true)}
        mode="outlined"
      />
      <View style={gapMedium}>
        <Text variant="titleMedium">{t("T01192")}</Text>
        {data?.length > 0 ? (
          <View style={gapMedium}>
            {data?.map(({ id, description }, i) => (
              <List.Item
                key={`additional-experience-${id}-${i}`}
                title={description}
                onPress={() => {
                  setCurrentIdToEdit(id);
                  onPressEditExperience(true);
                }}
                right={props => <List.Icon {...props} icon="pencil-outline" />}
                style={styles.itemContainer}
                titleNumberOfLines={50}
              />
            ))}
          </View>
        ) : (
          <Text variant="bodyMedium">{t("T01193")}</Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: palettes.primary[99],
    paddingHorizontal: spacing16,
    paddingVertical: spacing8,
  },
});
