import { PrimaryButton } from "@components/buttons";
import { spacing16 } from "@styles/spacing";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Chip } from "react-native-paper";

type SurveyHeaderProps = {
  step: "1" | "2" | "3";
  onSkip: () => void;
  disableSkip: boolean;
};

const SurveyHeader: FC<SurveyHeaderProps> = ({ onSkip, step, disableSkip }) => {
  const { t } = useTranslation();

  const onSkipPress = useCallback(
    () =>
      showAlertWithCustomButtons({
        title: t("T01220"),
        message: t("T01221"),
        cancelButton: {
          text: t("T01222"),
        },
        confirmButton: {
          text: t("T01223"),
          onPress: onSkip,
        },
      }),
    [onSkip, t],
  );

  return (
    <View style={styles.stepContainer}>
      <Chip>{`${t("T00957")} ${step}/3`}</Chip>
      {!disableSkip && (
        <PrimaryButton label="T01224" mode="text" onPress={onSkipPress} />
      )}
    </View>
  );
};

export default SurveyHeader;

const styles = StyleSheet.create({
  stepContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: spacing16,
  },
});
