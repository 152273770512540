const keys = {
  appointmentsYear: "appointmentsYear",
  appointmentsMonth: "appointmentsMonth",
};

export const queryKeysChart = {
  appointmentsYear: () => [keys.appointmentsYear] as const,
  appointmentsMonth: (month: number, year: number) =>
    [keys.appointmentsMonth, month, year] as const,
};
