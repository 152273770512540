import { View } from "react-native";
import { Chip, Text } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { useTranslation } from "react-i18next";
import { FC, PropsWithChildren, useContext, useState } from "react";
import { PrimaryButton } from "@components/buttons";
import { TrainingSelectionAssistantContext } from "./trainingSelectionAssistant.context";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { TrainingSelectionAssistantStackParamsList } from "@navigators/navigation.types";

export const InjuriesOrMedicalConditions: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      TrainingSelectionAssistantStackParamsList,
      "InjuriesOrMedicalConditions"
    >
  >
> = ({ navigation: { navigate } }) => {
  const [selectedAnswer, setSelectedAnswer] = useState<boolean>(null);
  const { t } = useTranslation();

  const { updateData } = useContext(TrainingSelectionAssistantContext);

  const { container, gapLarge, gapSmall } = globalStyles;

  return (
    <View style={[container, { justifyContent: "space-between" }]}>
      <View style={gapLarge}>
        <View style={gapSmall}>
          <Text variant="titleMedium">{t("T01336")}</Text>
          <Text variant="bodyMedium">{t("T01010")}</Text>
        </View>
        <View style={gapLarge}>
          <Chip
            mode="outlined"
            style={{ alignSelf: "flex-start" }}
            selected={!!selectedAnswer}
            onPress={() => {
              setSelectedAnswer(prevState => (prevState ? null : true));
            }}>
            {t("T00575")}
          </Chip>
          <Chip
            mode="outlined"
            style={{ alignSelf: "flex-start" }}
            selected={selectedAnswer === false}
            onPress={() => {
              setSelectedAnswer(prevState =>
                prevState === false ? null : false,
              );
            }}>
            {t("T00574")}
          </Chip>
        </View>
      </View>
      <PrimaryButton
        label="T00472"
        disabled={selectedAnswer === null}
        onPress={() => {
          updateData({ hasInjuriesOrMedicalConditions: selectedAnswer });
          navigate(
            selectedAnswer
              ? "TrainingAssistantSummary"
              : "ExerciseForSpecificGroup",
          );
        }}
      />
    </View>
  );
};
