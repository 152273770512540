import DocumentListItem from "@components/ListItems/DocumentListItem";
import { FileAttachment } from "@globalTypes/common.types";
import { TransKey } from "@globalTypes/i18next";
import { FC } from "react";
import { UseFieldArrayRemove } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Linking } from "react-native";
import { Text } from "react-native-paper";

type AttachedFilesListProps = {
  listData?: FileAttachment[];
  onListItemRemove: UseFieldArrayRemove;
  emptyListInfo: TransKey;
};

export const AttachedFilesList: FC<AttachedFilesListProps> = ({
  listData = [],
  onListItemRemove,
  emptyListInfo,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {listData?.length ? (
        listData.map(({ name, file }, i) => (
          <DocumentListItem
            title={name}
            key={`document-${name}-${i}`}
            onPress={async () => {
              await Linking.openURL(file);
            }}
            onRemove={() => onListItemRemove(i)}
            removeEnabled
          />
        ))
      ) : (
        <Text variant="bodyMedium">{t(emptyListInfo)}</Text>
      )}
    </>
  );
};
