import { FC, PropsWithChildren, useMemo } from "react";

import TitleSectionWithContent from "../../Common/TitleSectionWithContent";
import { TransKey } from "@globalTypes/i18next";
import ListItems, { ListItemProps } from "@components/Lists/ListItems";

export type ProfileSectionProps = {
  data: {
    title: TransKey;
    items: ListItemProps[];
  };
};

const ProfileSection: FC<PropsWithChildren<ProfileSectionProps>> = ({
  data: { title, items },
}) => {
  const renderContent = useMemo(() => <ListItems items={items} />, [items]);

  return (
    <TitleSectionWithContent title={title} renderContent={renderContent} />
  );
};

export default ProfileSection;
