import { palettes } from "@styles/colors";
import { FC } from "react";
import { Circle, CircleProps } from "react-native-svg";

type CustomCircleProps = {
  id: string;
  onPressHandler?: (id: string) => void;
  isSelected: boolean;
} & CircleProps;

const CustomCircle: FC<CustomCircleProps> = ({
  onPressHandler,
  id,
  isSelected,
  ...props
}) => {
  return (
    <Circle
      {...props}
      onPress={() => onPressHandler?.(id)}
      onClick={() => onPressHandler?.(id)}
      fill={isSelected ? palettes.primary[40] : "transparent"}
      style={{ outline: "none" }}
    />
  );
};

export default CustomCircle;
