import { GenderType } from "@contexts/AuthContext/user.types";
import { FC } from "react";
import MaleGeneralBack from "./MaleGeneralBack";
import FemaleGeneralBack from "./FemaleGeneralBack";
import { ModelProps } from "../humanModel.types";
import { useHumanModel } from "@hooks/humanModel/useHumanModel";

const GeneralBack: FC<ModelProps> = ({
  defaultSelected = [],
  onChange,
  readOnly = false,
  gender,
}) => {
  const { modelProps } = useHumanModel({
    onChange,
    readOnly,
    defaultSelected,
  });

  return gender === GenderType.MALE ? (
    <MaleGeneralBack {...modelProps} />
  ) : (
    <FemaleGeneralBack {...modelProps} />
  );
};
export default GeneralBack;
