import BackgroundWithLottieAnimation from "@components/BackgroundWithLottieAnimation";
import { AbsoluteBlurredFooter } from "@components/Footers";
import {
  RootStackParamList,
  RootTabsParamList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { isANDROID } from "@utils/constants";
import { AnimationObject } from "lottie-react-native";
import { FC, PropsWithChildren } from "react";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import FormSection from "./FormSection";
import WelcomeTextSection from "./WelcomeTextSection";
import { useDisplayBlurredFooter, useUserDetails } from "@hooks/index";

const animation =
  require("@assets/animations/promo_codes_and_invitations/AnimWelcomeInApp.json") as AnimationObject;

type WelcomeToProductScreen = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<RootStackParamList, "WelcomeToProduct">,
    NativeStackScreenProps<RootTabsParamList>
  >
>;

const WelcomeToProduct: FC<WelcomeToProductScreen> = ({
  navigation: { navigate, goBack },
}) => {
  const {
    footerHeight,
    setFooterHeight,
    displayBlurredFooter,
    keyboardHeight,
  } = useDisplayBlurredFooter();

  const { isPhysiotherapist } = useUserDetails();

  const handleAlertConfirm = () =>
    isPhysiotherapist ? goBack() : navigate("MessageCenterStack");

  return (
    <>
      <KeyboardAwareScrollView
        contentContainerStyle={
          !keyboardHeight && { paddingBottom: footerHeight }
        }
        extraHeight={isANDROID ? footerHeight : 0}
        enableOnAndroid>
        <BackgroundWithLottieAnimation animation={animation} />
        <WelcomeTextSection />
        <FormSection onAlertConfirm={handleAlertConfirm} />
      </KeyboardAwareScrollView>
      {displayBlurredFooter && (
        <AbsoluteBlurredFooter
          title="T01369"
          onPress={goBack}
          onLayout={height => setFooterHeight(height)}
        />
      )}
    </>
  );
};

export default WelcomeToProduct;
