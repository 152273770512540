import AddressWithMapRedirect from "@components/Address/AddressWithWebviewRedirect";
import PhysioLanguageDropdownPicker from "@components/DropdownPicker/PhysioLanguageDropdownPicker";
import ServiceDropdownPicker from "@components/DropdownPicker/ServiceDropdownPicker";
import EmptyHoursInformation from "@components/PhysioAvailability/EmptyHoursInformation";
import PhysioAvailabilityHoursSection from "@components/PhysioAvailability/PhysioAvailabilityHoursSection";
import { PhysiotherapistCardWithRating } from "@components/cards";
import { AppointmentSlots } from "@screens/Calendar/availability.types";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing12, spacing8 } from "@styles/spacing";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View, ScrollView } from "react-native";
import { Divider, SegmentedButtons } from "react-native-paper";
import { PickerItem } from "@components/DropdownPicker/DropdownPicker";
import { isANDROID } from "@utils/constants";
import { OnHourButtonPress } from "./appointment.types";

type Props = {
  physiotherapistId: number;
  serviceType: AppointmentSlots;
  onHourPress: (args: OnHourButtonPress) => void;
  hidePhysioTile?: boolean;
  onSegmentedButtonChange?: (value: AppointmentSlots) => void;
};

export const PhysiotherapistAppointmentCalendar: FC<Props> = ({
  physiotherapistId,
  serviceType: paramServiceType,
  onHourPress,
  hidePhysioTile,
  onSegmentedButtonChange,
}) => {
  const { t } = useTranslation();
  const [serviceType, setServiceType] = useState<AppointmentSlots>(
    paramServiceType &&
      paramServiceType !== AppointmentSlots.ONLINE_OR_STATIONARY
      ? paramServiceType
      : AppointmentSlots.STATIONARY,
  );
  const [serviceId, setServiceId] = useState<number>(null);
  const [selectedItem, setSelectedItem] = useState<PickerItem>(null);
  const [dropdownLanguageOpen, setDropdownLanguageOpen] = useState(false);
  const [dropdownServiceOpen, setDropdownServiceOpen] = useState(false);

  const buttons = useMemo(
    () => [
      {
        value: AppointmentSlots.STATIONARY,
        label: `${t("T00484")}`,
        showSelectedCheck: true,
      },
      {
        value: AppointmentSlots.ONLINE,
        label: `${t("T00483")}`,
        showSelectedCheck: true,
        style: serviceType === AppointmentSlots.ONLINE && {
          backgroundColor: palettes.tertiary[90],
        },
      },
    ],
    [serviceType, t],
  );
  const { gapLarge, container, gapMedium } = globalStyles;

  useEffect(() => setServiceId(0), [serviceType]);

  return (
    <View style={[gapLarge, container]}>
      {!hidePhysioTile && (
        <PhysiotherapistCardWithRating
          initialData={{ id: physiotherapistId }}
        />
      )}
      <ScrollView
        contentContainerStyle={[gapLarge, { paddingTop: spacing8 }]}
        showsVerticalScrollIndicator={false}>
        <View style={{ zIndex: 101 }}>
          <PhysioLanguageDropdownPicker
            physioId={physiotherapistId}
            onSelectItem={setSelectedItem}
            onOpen={() => {
              setDropdownServiceOpen(false);
              setDropdownLanguageOpen(true);
            }}
            onClose={() => setDropdownLanguageOpen(false)}
            isDropdownOpen={dropdownLanguageOpen}
            multiDropdownContent
          />
        </View>
        <View style={gapMedium}>
          <SegmentedButtons
            buttons={buttons}
            value={serviceType as string}
            onValueChange={(value: AppointmentSlots) => {
              onSegmentedButtonChange
                ? onSegmentedButtonChange(value)
                : setServiceType(value);
            }}
          />
          {serviceType === AppointmentSlots.STATIONARY && (
            <AddressWithMapRedirect
              physioId={physiotherapistId}
              style={{ paddingStart: spacing12 }}
            />
          )}
        </View>
        <ServiceDropdownPicker
          physioId={physiotherapistId}
          onValueChange={(value: string) => setServiceId(+value)}
          selectedServiceType={
            serviceType === AppointmentSlots.ONLINE ? "Online" : "Stationary"
          }
          onOpen={() => {
            setDropdownLanguageOpen(false);
            setDropdownServiceOpen(true);
          }}
          onClose={() => setDropdownServiceOpen(false)}
          isDropdownOpen={dropdownServiceOpen}
          multiDropdownContent
          changeLabelZIndex={isANDROID && dropdownLanguageOpen}
        />
        <Divider />
        {!serviceId ? (
          <EmptyHoursInformation />
        ) : (
          <PhysioAvailabilityHoursSection
            physiotherapistId={physiotherapistId}
            serviceId={serviceId}
            serviceType={serviceType}
            onHourPress={(dateFrom: string) =>
              onHourPress({ dateFrom, serviceType, serviceId, selectedItem })
            }
          />
        )}
      </ScrollView>
    </View>
  );
};
