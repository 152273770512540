import { Alert, AlertButton } from "react-native";
import { t } from "i18next";
import { isWeb } from "@utils/constants";
import { TransKey } from "@globalTypes/i18next";

export const showAlert = (
  message: string | string[] = "Error",
  cb?: () => void,
) => {
  if (isWeb) {
    const isConfirmed = window.confirm(`${message}`);
    // This console.log must be here to fix the eslint error above
    console.debug({ isConfirmed });
    cb?.();
  } else {
    Alert.alert(
      "Alert",
      `${message}`,
      [
        {
          text: "OK",
          onPress: cb,
          style: "cancel",
        },
      ],
      {
        cancelable: true,
        onDismiss: cb,
      },
    );
  }
};

type showAlertWithCustomButtonsType = {
  title: string | TransKey;
  message: string;
  cancelButton?: AlertButton;
  confirmButton?: AlertButton;
};

export const showAlertWithCustomButtons = ({
  title,
  message,
  cancelButton,
  confirmButton,
}: showAlertWithCustomButtonsType) => {
  const alertButtons: AlertButton[] = [
    cancelButton ? cancelButton : null,
    confirmButton ? confirmButton : null,
  ].filter(e => e !== null);

  if (isWeb) {
    if (window.confirm(`${title} ${message}`)) return confirmButton?.onPress();
  }
  Alert.alert(title, message, alertButtons);
};

export const inactiveUserAlert = () => Alert.alert(t("T00729"));
