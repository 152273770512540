import { FC } from "react";
import { ActivityIndicator, List } from "react-native-paper";
import { palettes } from "@styles/colors";
import { useQuery } from "@tanstack/react-query";
import { queryKeysFaq } from "@screens/Common/queryKeysHelpCenter";
import { getFaqCategory } from "@services/ApiService/helpCenter";
import { FetchError } from "@components/errors";
import { globalStyles } from "@styles/global";

type Props = {
  id: number;
  onPress: (id: number, name: string) => void;
};

export const FAQCategoryItem: FC<Props> = ({ id, onPress }) => {
  const {
    data: category,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryFn: () => getFaqCategory(id),
    queryKey: queryKeysFaq.categoryDetails(id),
  });

  if (isLoading) return <ActivityIndicator style={globalStyles.loading} />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <List.Item
      onPress={() => onPress(category.id, category.name)}
      title={category.name}
      left={props => <List.Icon {...props} icon="folder-outline" />}
      right={props => <List.Icon {...props} icon="menu-right" />}
      style={{ backgroundColor: palettes.primary[99] }}
    />
  );
};
