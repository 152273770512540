import { useCallback, useEffect, useState } from "react";

type UseHumanModelOptions = {
  onChange: (items: string[]) => void;
  readOnly?: boolean;
  defaultSelected?: string[];
};

export const useHumanModel = ({
  onChange,
  readOnly = false,
  defaultSelected = [],
}: UseHumanModelOptions) => {
  const [selectedElements, setSelectedElements] =
    useState<string[]>(defaultSelected);

  useEffect(() => {
    readOnly ? null : onChange?.(selectedElements);
  }, [onChange, readOnly, selectedElements]);

  const onPressItem = useCallback(
    (item: string) => {
      setSelectedElements(prevState => {
        const newItems = prevState?.includes(item)
          ? prevState.filter(e => e !== item)
          : [...prevState, item];
        return newItems;
      });
    },
    [setSelectedElements],
  );

  const checkIfItemIsSelected = useCallback(
    (id: string) => selectedElements?.includes(id),
    [selectedElements],
  );

  const modelProps = {
    onPressHandler: readOnly ? null : onPressItem,
    checkIfItemIsSelected,
  };

  return {
    selectedElements,
    setSelectedElements,
    onPressItem,
    checkIfItemIsSelected,
    modelProps,
  };
};
