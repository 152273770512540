import { config } from "@utils/config";
import { useUserDetails } from "../user/useUserDetails";

export const useInvitationCode = () => {
  const { invitationCode } = useUserDetails();

  return {
    invitationUrl: `${config.EXPO_PUBLIC_API_BASE_URL}/invites?code=${invitationCode}`,
    invitationCode,
  };
};
