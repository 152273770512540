import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, ScrollView, View, BackHandler } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";
import { RehabilitationStatisticsStackParamsList } from "@navigators/navigation.types";
import { globalStyles } from "@styles/global";
import { FetchError } from "../../components/errors";
import { getAssignedTrainingData, getSessionTraining } from "@api/trainings";
import {
  queryKeysSessionTraining,
  queryKeysTraining,
} from "./queryKeysTrainingsAndExercises";
import { SvgCheckCircle } from "@assets/svg";
import { formatMillisecondsToTime, getDate } from "@utils/date";
import ExercisesStatistics from "@screens/Profiles/MyProfile/Statistics/ExercisesStatistics";
import { AppbarTitleWithBackAction } from "@components/Appbar";
import PrimaryButton from "@components/buttons/PrimaryButton";
import { SafeAreaView } from "react-native-safe-area-context";
import { UserCard } from "@components/cards";
import { useUserDetails } from "@hooks/user/useUserDetails";

export type TrainingSummaryScreen = PropsWithChildren<
  NativeStackScreenProps<
    RehabilitationStatisticsStackParamsList,
    "TrainingSummary"
  >
>;

const TrainingSummary: FC<TrainingSummaryScreen> = ({
  route,
  route: {
    params: { sessionId },
  },
  navigation: { setOptions, goBack, popToTop },
}) => {
  const { t } = useTranslation();
  const { loading, gapLarge, scrollContainer, gapMedium, gapSmall } =
    globalStyles;
  const fromPlayer = route.params?.fromPlayer;
  const {
    data: sessionData,
    isLoading: isSessionLoading,
    isError: isSessionError,
    refetch: sessionRefetch,
  } = useQuery({
    queryKey: queryKeysSessionTraining.detail(sessionId),
    queryFn: async () => await getSessionTraining(sessionId),
  });
  const trainingId = sessionData?.workout;
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysTraining.detail(trainingId),
    queryFn: async () => await getAssignedTrainingData(trainingId),
    enabled: !!trainingId,
  });
  const { isPhysiotherapist } = useUserDetails();
  const patientId = route.params?.patientId;
  const pop = route.params?.pop;

  const refetchQueries = async () =>
    await Promise.all([sessionRefetch(), refetch()]);

  const time = useMemo(() => {
    if (sessionData) {
      const start = new Date(sessionData.startedAt).getTime();
      const end = new Date(sessionData.finishedAt).getTime();
      return end - start;
    }
  }, [sessionData]);

  useEffect(() => {
    if (fromPlayer) {
      const onBackPress = () => true;

      BackHandler.addEventListener("hardwareBackPress", onBackPress);

      return () =>
        BackHandler.removeEventListener("hardwareBackPress", onBackPress);
    }
  }, [fromPlayer]);

  useEffect(() => {
    setOptions({
      header: () => (
        <AppbarTitleWithBackAction
          title={t("T00195")}
          onClose={fromPlayer ? popToTop : () => pop()}
          onBack={!fromPlayer && goBack}
        />
      ),
      gestureEnabled: !fromPlayer,
    });
  }, [setOptions, fromPlayer, goBack, t, pop, popToTop]);

  if (isSessionLoading || isLoading)
    return <ActivityIndicator style={loading} />;

  if (isSessionError || isError) return <FetchError action={refetchQueries} />;

  return (
    <SafeAreaView edges={["bottom", "left", "right"]}>
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={isSessionLoading || isLoading}
            onRefresh={refetchQueries}
          />
        }
        contentContainerStyle={[gapLarge, scrollContainer]}>
        {isPhysiotherapist ? (
          <UserCard initialData={{ id: patientId }} mode="outlined" />
        ) : (
          <View style={[gapMedium, { alignItems: "center" }]}>
            <SvgCheckCircle />
            <Text variant="titleLarge">{t("T00511")}</Text>
          </View>
        )}
        <Divider />
        <View style={gapSmall}>
          <Text variant="titleMedium">{t("T00178")}:</Text>
          <Text variant="bodyMedium">{data.name}</Text>
        </View>
        <View style={gapSmall}>
          <Text variant="titleMedium">{t("T00512")}:</Text>
          <Text variant="bodyMedium">{getDate(sessionData.finishedAt)}</Text>
        </View>
        <View style={gapSmall}>
          <Text variant="titleMedium">{t("T00513")}:</Text>
          <Text variant="bodyMedium">{formatMillisecondsToTime(time)}</Text>
        </View>
        <View style={gapSmall}>
          <Text variant="titleMedium">{t("T00514")}:</Text>
          <Text variant="bodyMedium">{sessionData.percentageFinished} %</Text>
        </View>
        <Divider />
        <Text variant="titleMedium">{t("T00539")}:</Text>
        <ExercisesStatistics seriesStats={sessionData.seriesStats} />
        <PrimaryButton
          label="T00540"
          onPress={() => (fromPlayer ? popToTop() : goBack())}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

export default TrainingSummary;
