import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AppbarTitleWithLogo } from "@components/Appbar";
import TrainingsAndExercises from "@screens/TrainingsAndExercises/Physiotherapist";
import { TrainingsAndExercisesStackParamList } from "./navigation.types";
import { useUserDetails } from "@hooks/index";
import AppbarTitleWithLogoForPhysiotherapist from "@components/Appbar/AppbarTitleWithLogoForPhysiotherapist";
import AllPatientTrainings from "@screens/TrainingsAndExercises/Patient/AllPatientTrainings";

const Stack = createNativeStackNavigator<TrainingsAndExercisesStackParamList>();

const TrainingsAndExercisesStackNavigator: FC = () => {
  const { t } = useTranslation();
  const { isPatient, isPhysiotherapist } = useUserDetails();
  return (
    <Stack.Navigator>
      {isPhysiotherapist && (
        <Stack.Screen
          name="TrainingsAndExercises"
          component={TrainingsAndExercises}
          options={{
            header: () => (
              <AppbarTitleWithLogoForPhysiotherapist title={t("T00076")} />
            ),
          }}
        />
      )}
      {isPatient && (
        <Stack.Screen
          name="AllPatientTrainings"
          component={AllPatientTrainings}
          options={{
            header: () => <AppbarTitleWithLogo title={t("T00075")} />,
          }}
        />
      )}
    </Stack.Navigator>
  );
};

export default TrainingsAndExercisesStackNavigator;
