import { updateHeaders } from "@api/auth";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { TextButton } from "@components/Button";
import { Calendar, DateArrayType } from "@components/Calendar";
import PrivacyAndTermsAgreement from "@components/Checkboxes/PrivacyAndTermsAgreement";
import AppLanguageDropdownPicker from "@components/DropdownPicker/AppLanguageDropdownPicker";
import { AbsoluteBlurredFooter } from "@components/Footers";
import { RadioButtonsGroup } from "@components/FormElements";
import { Input } from "@components/Input";
import { Logo } from "@components/Logo";
import { SegmentedButtonsWithController } from "@components/SegmentedButtonsWithController";
import InfoTile from "@components/Tile/InfoTile";
import { FetchError, GeneralError } from "@components/errors";
import { useAuth } from "@contexts/AuthContext/auth";
import {
  AccountType,
  GenderType,
  LocaleHeaders,
  LoginType,
  ProfileUpdateType,
} from "@contexts/AuthContext/user.types";
import { TransKey } from "@globalTypes/i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrors } from "@hooks/useErrors";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import {
  getProfile,
  profileOptions,
  profileUpdate,
  setProfileEmail,
} from "@services/ApiService/profile";
import i18n from "@services/i18n";
import { globalStyles } from "@styles/global";
import {
  spacing16,
  spacing24,
  spacing32,
  spacing4,
  spacing8,
} from "@styles/spacing";
import { theme, useAppTheme } from "@styles/theme";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { config } from "@utils/config";
import { fallbackLanguage, isWeb } from "@utils/constants";
import {
  formatDateForApi,
  getDate,
  getInitialDateForBirthdayPicker,
} from "@utils/date";
import { getLang } from "@utils/lang";
import { storageManager } from "@utils/storageManager";
import { AxiosError } from "axios";
import { getCalendars } from "expo-localization";
import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { Controller, FieldValues, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Linking, StyleSheet, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import {
  ActivityIndicator,
  Divider,
  HelperText,
  Text,
} from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { boolean, object, string } from "yup";
import { validateAge } from "../../utils";

export type ProfileUpdateForm = {
  id: number;
  accountType: AccountType;
  gender: GenderType;
  firstName: string;
  lastName: string;
  birthday: string | null;
  overrideEmail?: string;
  language: string;
  offersServicesAs?: string;
};

const radioButtonsData = [
  { value: "Individual", label: "T01184" as TransKey },
  { value: "Company", label: "T01183" as TransKey },
];

const ProfileUpdateScreen: FC<
  PropsWithChildren<NativeStackScreenProps<RootStackParamList, "ProfileUpdate">>
> = () => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [footerHeight, setFooterHeight] = useState(0);
  const [selectedDays, setSelectedDays] = useState<DateArrayType[]>([
    {
      date: getInitialDateForBirthdayPicker(),
    },
  ]);
  const [stackChanging, setStackChanging] = useState(false);

  const {
    colors: { outline },
  } = useAppTheme();
  const {
    updateUser,
    signOut,
    user: { id, email, firstName, lastName, loginType },
  } = useAuth();
  const { setErrorsFromResponse, errors, generalError } = useErrors();
  const queryClient = useQueryClient();

  const {
    safeAreaViewStyle,
    container,
    logoContainer,
    inputs,
    infoStyle,
    errorText,
    checkboxContainer,
    radioContainerStyle,
  } = styles;

  const {
    data: optionData,
    isLoading: optionsLoading,
    isError: optionsError,
    refetch,
    isSuccess: optionsSuccess,
  } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.profileOptions(),
    queryFn: async () => {
      const { data } = await profileOptions(id);
      const { accountType, gender, language } = data.actions.put;
      return {
        gender,
        accountType,
        language,
      };
    },
  });

  const {
    data: initialData,
    isSuccess: initialDataSuccess,
    isLoading: initialDataLoading,
  } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.profileData(),
    queryFn: async () => {
      const { data } = await getProfile(id);
      return data;
    },
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: async (params: ProfileUpdateType) => {
      const headers = { Language: params.language };
      updateHeader(headers);
      await profileUpdate(params, headers);
    },
    onError: ({ response }: AxiosError) => {
      setStackChanging(false);
      setErrorsFromResponse(response);
    },
    onSuccess: () => {
      setStackChanging(true);
      isEmailHidden && setEmail(currentOverrideEmail);
    },
  });

  const { mutate: setEmail, isLoading: isSetEmailLoading } = useMutation({
    mutationFn: setProfileEmail,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const { mutate: updateHeader, isLoading: isLoadingUpdateHeader } =
    useMutation({
      mutationFn: async (headers: LocaleHeaders) => {
        const token = await storageManager.getItem("refreshToken");
        await updateHeaders(token, headers);
      },
      onSuccess: () => {
        updateUser();
        queryClient.clear();
      },
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    });

  const checkboxRules = boolean()
    .oneOf([true], t("T00014"))
    .required(t("T00014"));

  const createSchema = useCallback(() => {
    const fieldIsRequired = t("T00014");
    const stringRules = string().required(fieldIsRequired).trim();
    const radioButtonValues = radioButtonsData.map(r => r.value);

    const schema = {
      firstName: stringRules,
      lastName: stringRules,
      overrideEmail: string().email(t("T00006")).required(fieldIsRequired),
      language: stringRules,
      birthday: stringRules.test("is18", t("T00013"), validateAge),
      agreementCheckbox: checkboxRules,
      offersServicesAs: string().when("accountType", {
        is: AccountType.THERAPIST,
        then: s => s.oneOf(radioButtonValues, fieldIsRequired),
      }),
    };
    for (const key in optionData)
      schema[key] = string().required(fieldIsRequired);
    return object().shape(schema);
  }, [checkboxRules, optionData, t]);

  const isEmailHidden = email.split("@")[1] === "fixme-anonymous.com";

  const { control, handleSubmit, setValue, watch } = useForm<FieldValues>({
    resolver: optionsSuccess && yupResolver(createSchema()),
    values: initialDataSuccess && {
      ...initialData,
      overrideEmail: isEmailHidden ? "" : email,
      agreementCheckbox: false,
      offersServicesAs: "",
    },
    defaultValues: { firstName, lastName },
  });
  const currentOverrideEmail = useWatch({
    control,
    name: "overrideEmail",
  }) as string;

  const currentAppLang = useWatch({
    control,
    name: "language",
  }) as string;

  const onSelectDate = useCallback(
    (data: DateArrayType, dontClose?: boolean) => {
      !dontClose && setModalVisible(false);
      setValue("birthday", getDate(data.date));
      setSelectedDays([data]);
    },
    [setValue],
  );

  const onSubmit = ({
    birthday,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    overrideEmail,
    accountType,
    language,
    offersServicesAs,
    ...data
  }: ProfileUpdateForm) => {
    const timezone = getCalendars?.()[0]?.timeZone || "Europe/Warsaw";
    const params: ProfileUpdateType = {
      id,
      birthday: formatDateForApi(birthday),
      accountType,
      ...data,
      timezone,
      language,
    };
    if (accountType === AccountType.THERAPIST && !!offersServicesAs) {
      params["isCompany"] = offersServicesAs === "Company";
    }
    mutate(params);
  };

  const changeLanguage = useCallback(
    async (lang: string) => {
      setValue("language", lang);
      await i18n.changeLanguage(getLang(lang));
      updateHeader({ Language: lang });
    },
    [setValue, updateHeader],
  );

  const onChangeDropdown = useCallback(
    async (lang: string) => {
      if (i18n.language !== lang) {
        const optionLanguages = optionData?.language.choices.map(
          ({ value }) => value as string,
        );
        const isAppLanguage = optionLanguages?.some(l => l === lang);
        const resultLang = isAppLanguage ? lang : fallbackLanguage;
        await changeLanguage(resultLang);
      }
    },
    [changeLanguage, optionData?.language.choices],
  );

  const renderSegmentedController = useCallback(
    () => (
      <View style={[globalStyles.gapLarge, { marginVertical: spacing8 }]}>
        {Object.entries(optionData).map(([key, value]) => {
          if (key === "language") return;
          return (
            <SegmentedButtonsWithController
              control={control}
              name={key}
              label={value.label as TransKey}
              buttonsData={value.choices}
              buttons={[]}
              key={key}
              errors={errors?.[key]}
            />
          );
        })}
      </View>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionData],
  );
  const shouldDisplayNameAndLastNameInputs = useMemo(
    () => !(!!firstName && !!lastName && loginType === LoginType.APPLE),
    [firstName, lastName, loginType],
  );

  const isMutating = useMemo(
    () => isLoading || isSetEmailLoading || isLoadingUpdateHeader,
    [isLoading, isSetEmailLoading, isLoadingUpdateHeader],
  );

  if (optionsLoading || initialDataLoading || stackChanging)
    return <ActivityIndicator style={globalStyles.loading} />;
  if (optionsError) return <FetchError action={refetch} />;

  return (
    <SafeAreaView edges={["left", "right", "top"]} style={safeAreaViewStyle}>
      <KeyboardAwareScrollView
        style={container}
        contentContainerStyle={{ paddingBottom: footerHeight }}>
        <View style={logoContainer}>
          <Logo />
          <View style={{ width: "50%" }}>
            <TextButton
              title="T00045"
              onPress={signOut}
              textStyle={{ textAlign: "center" }}
            />
          </View>
        </View>
        <Text variant="titleLarge">{t("T00811")}</Text>
        {generalError ? <GeneralError error={generalError} /> : null}
        <View style={inputs}>
          {shouldDisplayNameAndLastNameInputs && (
            <>
              <Input
                key="firstName"
                name="firstName"
                label="T00718"
                control={control}
                errors={errors?.["firstName"]}
                autoCapitalize="words"
              />
              <Input
                key="lastName"
                name="lastName"
                label="T00719"
                control={control}
                errors={errors?.["lastName"]}
                autoCapitalize="words"
                style={{ marginBottom: 6 }}
              />
            </>
          )}
          <Input
            key="birthday"
            name="birthday"
            label="T01039"
            control={control}
            errors={errors?.["birthday"]}
            calendarInput
            onPress={() => setModalVisible(true)}
            borderColor={outline}
          />
          {isEmailHidden && (
            <Input
              key="overrideEmail"
              name="overrideEmail"
              label="T00720"
              control={control}
              errors={errors?.["overrideEmail"]}
            />
          )}
        </View>
        <Controller
          name="language"
          control={control}
          render={({ field: { onChange }, fieldState: { error } }) => {
            const dropdownError =
              errors?.language?.[0]?.message || (error && t("T00014"));

            return (
              <View style={{ zIndex: 100 }}>
                <AppLanguageDropdownPicker
                  onSelectItem={async ({ value }) => {
                    onChange(value);
                    await onChangeDropdown(value);
                  }}
                  defaultValue={currentAppLang}
                />
                {dropdownError && (
                  <HelperText type="error" style={errorText}>
                    {dropdownError}
                  </HelperText>
                )}
              </View>
            );
          }}
        />
        {renderSegmentedController()}
        {isWeb && (
          <View style={{ paddingVertical: spacing8 }}>
            <InfoTile
              status="info"
              content={
                <Text>
                  {t("T00871")}
                  <Text
                    onPress={() =>
                      Linking.openURL(`${config.EXPO_PUBLIC_API_BASE_URL}/home`)
                    }
                    style={infoStyle}>
                    {t("T00872")}
                  </Text>
                  {t("T00873")}
                </Text>
              }
            />
          </View>
        )}
        {watch("accountType") === AccountType.THERAPIST && (
          <Controller
            control={control}
            name="offersServicesAs"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const radioButtonErrorMessage =
                error?.message || errors?.isCompany?.[0]?.message;
              return (
                <RadioButtonsGroup
                  errorMessage={radioButtonErrorMessage}
                  onChange={onChange}
                  value={value as string}
                  data={radioButtonsData}
                  title="T01185"
                  errorMessagePlacement="BOTTOM"
                  radioContainerStyle={radioContainerStyle}
                />
              );
            }}
          />
        )}
        <View style={checkboxContainer}>
          <Divider horizontalInset />
          <PrivacyAndTermsAgreement
            control={control}
            text="T01186"
            name="agreementCheckbox"
          />
        </View>
      </KeyboardAwareScrollView>
      <AbsoluteBlurredFooter
        title="T00062"
        onPress={handleSubmit(onSubmit)}
        onLayout={height => setFooterHeight(height)}
        buttonLoading={isMutating}
        buttonDisabled={isMutating}
      />
      <BottomModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}>
        <View style={[globalStyles.gapLarge, { paddingVertical: spacing24 }]}>
          <Calendar
            initialDates={selectedDays}
            onSelectDate={onSelectDate}
            yearsOption="pastMinusMinAge"
            editMode={!watch("birthday")}
          />
        </View>
      </BottomModalContainer>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  logoContainer: {
    marginBottom: spacing16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputs: {
    paddingVertical: spacing8,
  },
  safeAreaViewStyle: {
    flex: 1,
  },
  container: { padding: spacing16, paddingBottom: spacing32 },
  errorText: { marginTop: -spacing4, paddingVertical: 0 },
  infoStyle: {
    color: theme.colors.primary,
    textDecorationLine: "underline",
    marginHorizontal: spacing4,
  },
  checkboxContainer: {
    ...globalStyles.gapLarge,
    marginVertical: spacing16,
  },
  radioContainerStyle: {
    ...globalStyles.gapLarge,
    marginEnd: spacing32,
  },
});

export default ProfileUpdateScreen;
