import { UserCard } from "@components/cards";
import { UseQueryResult } from "@tanstack/react-query";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";

import { SpecialTrainingsResponse } from "@screens/TrainingsAndExercises/training.types";
import { globalStyles } from "@styles/global";
import { FetchError } from "@components/errors";

type Props = {
  data: SpecialTrainingsResponse[];
  onPressPlan: (id: number) => void;
  isLoading: boolean;
  isError: boolean;
  refetch: UseQueryResult["refetch"];
};

export const WaitingForPlan: FC<Props> = ({
  data,
  onPressPlan,
  isLoading,
  isError,
  refetch,
}) => {
  const { t } = useTranslation();

  return (
    <View style={globalStyles.gapMedium}>
      <Text variant="titleMedium">{t("T00367")}:</Text>
      {isLoading ? (
        <ActivityIndicator />
      ) : isError ? (
        <FetchError action={refetch} coverScreen={false} />
      ) : data?.length > 0 ? (
        data.map(({ patient, id }, i) => (
          <UserCard
            key={`patientWaitingForPlan-${patient}-${id}-${i}`}
            initialData={{ id: patient }}
            onPress={() => onPressPlan(patient)}
            mode="outlined"
          />
        ))
      ) : (
        <Text variant="bodyMedium">{t("T00326")}</Text>
      )}
    </View>
  );
};
