import { FC, useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { useTranslation } from "react-i18next";
import { SeriesType } from "@screens/TrainingsAndExercises/exercise.types";
import SuggestedParametersData from "@components/BottomSheetContents/components/SuggestedParametersData";
import {
  PredictedParametersResponse,
  PredictedValues,
} from "@screens/TrainingsAndExercises/training.types";

type SuggestedParametersSheetContentProps = {
  onMoreInformationPress: () => void;
  onClosePress: () => void;
  onApplyPress: (seriesData: PredictedValues[], seriesType: SeriesType) => void;
  data: PredictedParametersResponse;
};

const SuggestedParametersSheetContent: FC<
  SuggestedParametersSheetContentProps
> = ({ onMoreInformationPress, onClosePress, onApplyPress, data }) => {
  const { t } = useTranslation();

  const { button } = styles;
  const { gapLarge, gapMedium } = globalStyles;

  const renderEmptySuggestion = useCallback(
    () => <Text variant="bodyMedium">{t("T00770")}</Text>,
    [t],
  );

  return (
    <View style={gapLarge}>
      <View style={gapMedium}>
        <Text variant="bodyLarge">{t("T00759")}</Text>
        <Text variant="titleSmall">{`${t("T00414")}:`}</Text>
        {data.repetitions ? (
          <>
            <SuggestedParametersData data={data.repetitions} />
            <PrimaryButton
              label="T00765"
              onPress={() =>
                onApplyPress(data.repetitions, SeriesType.REPETITIONS)
              }
              style={button}
            />
          </>
        ) : (
          renderEmptySuggestion()
        )}
        <Text variant="titleSmall">{`${t("T00415")}:`}</Text>
        {data.time ? (
          <>
            <SuggestedParametersData data={data.time} />
            <PrimaryButton
              label="T00766"
              onPress={() => onApplyPress(data.time, SeriesType.TIME)}
              style={button}
            />
          </>
        ) : (
          renderEmptySuggestion()
        )}
      </View>
      <PrimaryButton
        label="T00760"
        mode="outlined"
        onPress={onMoreInformationPress}
      />
      <PrimaryButton label="T00540" onPress={onClosePress} />
    </View>
  );
};

export default SuggestedParametersSheetContent;

const styles = StyleSheet.create({ button: { alignSelf: "flex-start" } });
