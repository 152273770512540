import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { ExerciseTileProps } from "../types/Tile.types";
import ExerciseBaseTile from "@components/Tile/exercise/ExerciseBaseTile";
import { spacing16 } from "@styles/spacing";
import RefetchIconButton from "@components/Tile/components/RefetchIconButton";
import { ActivityIndicator } from "react-native-paper";
import { getExercise } from "@api/exercises";
import { queryKeysExercises } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { useAssistant } from "@hooks/user/useAssistant";

const ExerciseTile: FC<ExerciseTileProps> = ({
  exerciseId,
  onPress,
  ...props
}) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysExercises.detail(exerciseId),
    queryFn: async () => await getExercise(exerciseId),
  });

  const isErrorOrIsLoading = isError || isLoading;
  const { isAssistantId } = useAssistant(data?.author);

  return (
    <ExerciseBaseTile
      title={isErrorOrIsLoading ? "" : data?.name}
      right={
        isError
          ? () => <RefetchIconButton onPress={refetch} />
          : isLoading
          ? () => <ActivityIndicator style={{ marginRight: spacing16 }} />
          : null
      }
      image={data?.exerciseImageUrl}
      onPress={!isError && !isLoading && onPress}
      displayExtraIcon={isAssistantId}
      {...props}
    />
  );
};

export default ExerciseTile;
