import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { yupResolver } from "@hookform/resolvers/yup";
import { SeriesType } from "@screens/TrainingsAndExercises/exercise.types";
import { EXERCISE_LIMITS } from "@utils/constants";
import { FC, useMemo } from "react";
import { Control, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { number, object } from "yup";

type AddSeriesFormProps = {
  seriesType: SeriesType;
  handleAddSeries: (series: AddSeriesFormType) => void;
};

export type AddSeriesFormType = {
  value?: number;
  break?: number;
  seriesNumber?: number;
};

const AddSeriesForm: FC<AddSeriesFormProps> = ({
  seriesType,
  handleAddSeries,
}) => {
  const { t } = useTranslation();
  const schema = useMemo(() => {
    const {
      maxBreakTime,
      maxExerciseTime,
      maxRepetitions,
      maxSeries,
      minSeries,
    } = EXERCISE_LIMITS;
    const commonRules = number().typeError(t("T00025")).required(t("T00014"));

    const seriesNumberRule = commonRules
      .max(
        maxSeries,
        t("T00037", {
          value: maxSeries,
        }),
      )
      .min(
        minSeries,
        t("T00038", {
          value: minSeries,
        }),
      );

    const breakRule = commonRules.max(
      maxBreakTime,
      t("T00039", {
        value: maxBreakTime,
      }),
    );

    let valueRule = commonRules.max(
      maxRepetitions,
      t("T00040", {
        value: maxRepetitions,
      }),
    );
    if (seriesType === SeriesType.TIME) {
      valueRule = commonRules.max(
        maxExerciseTime,
        t("T00041", {
          value: maxExerciseTime,
        }),
      );
    }

    return object().shape({
      value: valueRule,
      break: breakRule,
      seriesNumber: seriesNumberRule,
    });
  }, [seriesType, t]);

  const { control, handleSubmit, setValue } = useForm<AddSeriesFormType>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      seriesNumber: 1,
    },
  });

  const valueLabel =
    seriesType === SeriesType.REPETITIONS ? "T00423" : "T00424";

  const onSubmit = (data: AddSeriesFormType) => handleAddSeries({ ...data });

  return (
    <>
      <Input
        name="value"
        label={valueLabel}
        control={control as unknown as Control}
        keyboardType="numeric"
        isInBottomSheet
      />
      <Input
        name="break"
        label="T00425"
        control={control as unknown as Control}
        keyboardType="numeric"
        isInBottomSheet
      />
      <Input
        name="seriesNumber"
        label="T01182"
        control={control as unknown as Control}
        keyboardType="numeric"
        isInBottomSheet
        defaultValue="1"
        onFocus={() => setValue("seriesNumber", 1)}
      />
      <PrimaryButton label="T00422" onPress={handleSubmit(onSubmit)} />
    </>
  );
};

export default AddSeriesForm;
