import {
  GenderType,
  PublicUserProfile,
} from "@contexts/AuthContext/user.types";
import { t } from "i18next";

export const getGenderName = (gender: GenderType) => {
  switch (gender) {
    case GenderType.MALE:
      return t("T00054");
    case GenderType.FEMALE:
      return t("T00055");
    case GenderType.OTHER:
      return t("T00458");
    default:
      return "";
  }
};

export const getSubtitles = (
  { gender, age, weight, height }: Partial<PublicUserProfile>,
  disableHeightAndWeight?: boolean,
): string[] => {
  const subtitles: string[] = [];

  if (gender && age) {
    const genderAndAge = `${getGenderName(gender)}, ${age} ${t("T00459")}`;
    subtitles.push(genderAndAge);
  }

  if ((height || weight) && !disableHeightAndWeight) {
    let heightAndWeight = "";

    if (height) {
      heightAndWeight += `${height} cm`;
    }

    if (weight) {
      heightAndWeight += heightAndWeight ? `, ${weight} kg` : `${weight} kg`;
    }

    if (heightAndWeight) {
      subtitles.push(heightAndWeight);
    }
  }

  return subtitles;
};
