import { DocumentPickerResult, getDocumentAsync } from "expo-document-picker";
import { t } from "i18next";
import { Alert } from "react-native";

export const pickFile = async (
  multiple = false,
): Promise<DocumentPickerResult> => {
  try {
    return await getDocumentAsync({ multiple });
  } catch (error) {
    Alert.alert(t("T00160"), t("T00591"), [
      {
        text: "Ok",
        style: "cancel",
      },
    ]);
    __DEV__ && console.error("Error picking a file: ", error);
  }
};
