import { queryKeysAppointments } from "@screens/Appointments/queryKeysAppointments";
import { getAppointmentDetails } from "@services/ApiService/appointments";
import { useQuery } from "@tanstack/react-query";

export const useAppointmentData = (id: number) => {
  return useQuery({
    queryKey: queryKeysAppointments.detail(id),
    queryFn: async () => await getAppointmentDetails(id),
  });
};
