import { View } from "react-native";
import { globalStyles } from "@styles/global";
import { BaseSyntheticEvent, FC, useMemo } from "react";
import { ControllerProps } from "react-hook-form";
import { daysOfWeek } from "./trainingsPushSettingsData";

import { spacing32 } from "@styles/spacing";
import DayReminderWithController from "./DayReminderWithController";

type DayRemindersListProps = {
  notificationTimes: string[];
  displayOnlyOneDay: boolean;
  handleSubmitSingleDay: (e?: BaseSyntheticEvent) => Promise<void>;
  handleSubmitMultipleDays: (e?: BaseSyntheticEvent) => Promise<void>;
} & Pick<ControllerProps, "control">;

const DayRemindersList: FC<DayRemindersListProps> = ({
  notificationTimes,
  displayOnlyOneDay,
  control,
  handleSubmitSingleDay,
  handleSubmitMultipleDays,
}) => {
  const mergedData = useMemo(
    () =>
      daysOfWeek.map(({ transKey, weekDayName }, index) => ({
        transKey,
        weekDayName,
        notificationTime: notificationTimes[index],
      })),
    [notificationTimes],
  );
  return displayOnlyOneDay ? (
    <DayReminderWithController
      name="T01480"
      control={control}
      fieldName="notificationTimeMonday"
      notificationTime={mergedData?.[0].notificationTime}
      handleSubmit={handleSubmitSingleDay}
    />
  ) : (
    <View style={[globalStyles.gapLarge, { marginBottom: spacing32 }]}>
      {mergedData.map(({ weekDayName, transKey, notificationTime }) => (
        <DayReminderWithController
          name={transKey}
          control={control}
          key={transKey}
          fieldName={`notificationTime${weekDayName}`}
          notificationTime={notificationTime}
          handleSubmit={handleSubmitMultipleDays}
        />
      ))}
    </View>
  );
};

export default DayRemindersList;
