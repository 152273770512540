import { yupResolver } from "@hookform/resolvers/yup";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useEffect } from "react";
import { Control, FieldValues, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Divider, Headline } from "react-native-paper";
import { boolean, object, ref, string } from "yup";

import { Input } from "@components/Input";
import { Logo } from "@components/Logo";
import { PrimaryButton } from "@components/buttons";
import GeneralError from "@components/errors/GeneralError";

import { useAuth } from "@contexts/AuthContext/auth";
import {
  UserAuthenticationData,
  UserSignUpForm,
} from "@contexts/AuthContext/user.types";
import { useErrors } from "@hooks/useErrors";
import { RootStackParamList } from "@navigators/navigation.types";
import { spacing16, spacing8 } from "@styles/spacing";
import PrivacyAndTermsAgreement from "@components/Checkboxes/PrivacyAndTermsAgreement";
import { globalStyles } from "@styles/global";
import MultiColorText from "@components/Text/MultiColorText";
import { isWeb } from "@utils/constants";
import { Settings } from "react-native-fbsdk-next";
import { config } from "@utils/config";
import { validationRegex } from "@utils/validators";
import { SocialSection } from "@components/index";
import { RedirectScreens } from "@components/social/types";

type GoogleSigninType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("@react-native-google-signin/google-signin").GoogleSignin;

let GoogleSignin: GoogleSigninType;

if (!isWeb) {
  GoogleSignin = (
    require("@react-native-google-signin/google-signin") as typeof import("@react-native-google-signin/google-signin")
  ).GoogleSignin;
}

const SignUpScreen: FC<
  PropsWithChildren<NativeStackScreenProps<RootStackParamList, "SignUp">>
> = ({ navigation: { navigate } }) => {
  const {
    safeAreaViewStyle,
    container,
    logoContainer,
    formContainer,
    inputsContainer,
    submitButton,
    switchToSignIn,
  } = styles;
  const { signUp } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isWeb) {
      GoogleSignin.configure({
        webClientId: config.EXPO_PUBLIC_GOOGLE_WEB_CLIENT_ID,
        iosClientId: config.EXPO_PUBLIC_GOOGLE_IOS_CLIENT_ID,
      });
      Settings.initializeSDK();
      Settings.setAppID(config.EXPO_PUBLIC_FACEBOOK_APP_ID);
      Settings.setClientToken(config.EXPO_PUBLIC_FACEBOOK_CLIENT_TOKEN);
    }
  }, []);

  const checkboxRules = boolean()
    .oneOf([true], t("T00014"))
    .required(t("T00014"));
  const schema = object().shape({
    email: string()
      .email(t("T00006"))
      .matches(validationRegex.email, t("T01349"))
      .required(t("T00005")),
    password: string()
      .matches(validationRegex.password, t("T00009"))
      .required(t("T00008")),
    passwordConfirmation: string()
      .oneOf([ref("password"), null], t("T00012"))
      .required(t("T00008")),
    privacyCheckbox: checkboxRules,
    agreementCheckbox: checkboxRules,
  });

  const {
    control,
    handleSubmit,
    reset,
  }: UseFormReturn<Partial<UserSignUpForm>> = useForm({
    resolver: yupResolver(schema),
  });
  const { errors, setErrorsFromResponse, generalError } =
    useErrors<UserSignUpForm>({
      showModalOnGeneralError: true,
    });

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: signUp,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const onSubmit = (data: UserSignUpForm) => {
    const newUserData: UserAuthenticationData = {
      email: data.email,
      password: data.password,
    };
    mutate(newUserData);
    if (isSuccess) reset();
  };

  return (
    <SafeAreaView style={safeAreaViewStyle}>
      <KeyboardAwareScrollView contentContainerStyle={container}>
        <View style={logoContainer}>
          <Logo />
        </View>
        <View style={[formContainer, globalStyles.gapLarge]}>
          <Headline>{t("T00003")}</Headline>
          {generalError && <GeneralError error={generalError} />}
          <View style={inputsContainer}>
            <Input
              key="email"
              name="email"
              label="T00004"
              control={control as unknown as Control<FieldValues>}
              keyboardType="email-address"
              errors={errors?.["email"]}
            />
            <Input
              key="password"
              name="password"
              label="T00007"
              control={control as unknown as Control<FieldValues>}
              errors={errors?.["password"]}
              secureTextEntry
            />
            <Input
              key="passwordConfirmation"
              name="passwordConfirmation"
              label="T00011"
              control={control as unknown as Control}
              errors={errors?.["passwordConfirmation"]}
              secureTextEntry
            />
          </View>
          <PrivacyAndTermsAgreement
            name="privacyCheckbox"
            control={control as unknown as Control}
          />
          <PrivacyAndTermsAgreement
            text="T01187"
            control={control as unknown as Control}
            name="agreementCheckbox"
          />
          <PrimaryButton
            loading={isLoading}
            disabled={isLoading}
            label="T00003"
            onPress={handleSubmit(onSubmit)}
            style={submitButton}
          />
          <Divider bold />
          <SocialSection
            title={t("T00833")}
            redirectFrom={RedirectScreens.SignUp}
          />
          <TouchableOpacity
            onPress={() => navigate("SignIn")}
            style={switchToSignIn}>
            <MultiColorText leftText={t("T00537")} rightText={t("T00044")} />
          </TouchableOpacity>
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  logoContainer: {
    marginBottom: spacing16,
  },
  formContainer: {
    paddingTop: spacing16,
  },
  inputsContainer: {
    marginBottom: spacing8,
  },
  submitButton: {
    marginVertical: spacing16,
  },
  safeAreaViewStyle: {
    flex: 1,
  },
  container: { padding: spacing16 },
  switchToSignIn: {
    width: "100%",
    alignItems: "center",
  },
});

export default SignUpScreen;
