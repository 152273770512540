import Svg, { SvgProps, Rect, Path } from "react-native-svg";

export const SvgOnlinePaymentsIcon4 = (props: SvgProps) => (
  <Svg width={56} height={56} fill="none" {...props}>
    <Rect width={56} height={56} fill="#DEE1FF" rx={28} />
    <Path
      fill="#4666FF"
      d="M34 24.5h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5v2h-1c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2Zm-9-2c0-1.66 1.34-3 3-3s3 1.34 3 3v2h-6v-2Zm9 14H22v-10h12v10Zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Z"
    />
  </Svg>
);
