import { TimePicker } from "@components/Picker";
import { DateTimePickerEvent } from "@react-native-community/datetimepicker";
import { HoursConfirmType } from "@screens/Calendar/availability.types";
import { globalStyles } from "@styles/global";
import { isANDROID, isIOS } from "@utils/constants";
import { roundTimeToMatchSlot } from "@utils/date";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { TimeInputsProps } from "../filters.types";
import { styles } from "./filters.styles";
import { getDateWithHour00, setHourAndMinute } from "./utils";

export const TimeInputs: FC<TimeInputsProps> = ({
  hourFrom,
  hourTo,
  setHourTo,
  setHourFrom,
}) => {
  const [hourFromModalVisible, setHourFromModalVisible] = useState(!isANDROID);
  const [hourToModalVisible, setHourToModalVisible] = useState(!isANDROID);
  const [displayHourFromWebModal, setDisplayHourFromWebModal] = useState(false);
  const [displayHourToWebModal, setDisplayHourToWebModal] = useState(false);

  const { t } = useTranslation();

  const handleHourFromPress = () =>
    !isIOS ? setDisplayHourFromWebModal(true) : setHourFromModalVisible(true);

  const handleHourToPress = () =>
    !isIOS ? setDisplayHourToWebModal(true) : setHourToModalVisible(true);

  const onChangeTime = (selectedDate: Date, direction: string) => {
    direction === "from" ? setHourFrom(selectedDate) : setHourTo(selectedDate);
    if (isANDROID) {
      direction === "from"
        ? setHourFromModalVisible(false)
        : setHourToModalVisible(false);
    }
  };

  const minimumDate = getDateWithHour00();

  const hourFromTimePickerProps = {
    visible: hourFromModalVisible,
    visibleWebModal: displayHourFromWebModal,
    onConfirm: ({ hours, minutes }: HoursConfirmType) => {
      const { roundedHours, roundedMinutes } = roundTimeToMatchSlot(
        hours,
        minutes,
      );
      setHourFrom(setHourAndMinute(roundedHours, roundedMinutes));
      !isIOS && setDisplayHourFromWebModal(false);
    },
    hours: hourFrom.getHours(),
    minutes: hourFrom.getMinutes(),
    value: hourFrom,
    minimumDate,
    onChange: (_: DateTimePickerEvent, selectedDate: Date) =>
      onChangeTime(selectedDate, "from"),
    onPress: handleHourFromPress,
    onDismiss: () => setDisplayHourFromWebModal(false),
  };

  const hourToTimePickerProps = {
    visibleWebModal: displayHourToWebModal,
    visible: hourToModalVisible,
    onConfirm: ({ hours, minutes }: HoursConfirmType) => {
      const { roundedHours, roundedMinutes } = roundTimeToMatchSlot(
        hours,
        minutes,
      );
      setHourTo(setHourAndMinute(roundedHours, roundedMinutes));
      !isIOS && setDisplayHourToWebModal(false);
    },
    hours: hourTo.getHours(),
    minutes: hourTo.getMinutes(),
    value: hourTo,
    minimumDate,
    onChange: (_: DateTimePickerEvent, selectedDate: Date) =>
      onChangeTime(selectedDate, "to"),
    onPress: handleHourToPress,
    onDismiss: () => setDisplayHourToWebModal(false),
  };

  return (
    <>
      <View style={globalStyles.gapLarge}>
        <View style={styles.timeContainer}>
          <Text variant="bodyLarge">{t("T00589")}</Text>
          <TimePicker {...hourFromTimePickerProps} />
        </View>
        <View style={styles.timeContainer}>
          <Text variant="bodyLarge">{t("T00590")}</Text>
          <TimePicker {...hourToTimePickerProps} />
        </View>
      </View>
    </>
  );
};
