import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { ScrollView, View } from "react-native";
import { ActivityIndicator, SegmentedButtons, Text } from "react-native-paper";
import { palettes } from "@styles/colors";
import {
  getCurrentDatePlus30min,
  getFormattedDayWithMonth,
  getTime,
} from "@utils/date";
import { HourButton } from "../buttons";
import { globalStyles } from "@styles/global";
import { useTranslation } from "react-i18next";
import { FetchError } from "../errors";
import { AppointmentSlots } from "@screens/Calendar/availability.types";
import { useFutureAvailability } from "@hooks/availability/useFutureAvailability";
import { useLocale } from "@hooks/useLocale";

type PhysioProfileAvailabilityProps = {
  onHourPress: (selectedService: AppointmentSlots) => void;
  id: number;
  setSelectedServiceType: Dispatch<SetStateAction<AppointmentSlots>>;
  selectedServiceType: AppointmentSlots;
};

const PhysioProfileAvailability: FC<PhysioProfileAvailabilityProps> = ({
  onHourPress,
  id,
  selectedServiceType,
  setSelectedServiceType,
}) => {
  const { t } = useTranslation();
  const { gapMedium, gapLarge } = globalStyles;
  const scrollViewRef = useRef<ScrollView>(null);
  const { locale } = useLocale();

  const buttons = useMemo(
    () => [
      {
        value: AppointmentSlots.STATIONARY,
        label: `${t("T00546")}`,
        showSelectedCheck: true,
      },
      {
        value: AppointmentSlots.ONLINE,
        label: `${t("T00547")}`,
        showSelectedCheck: true,
        style: selectedServiceType === AppointmentSlots.ONLINE && {
          backgroundColor: palettes.tertiary[90],
        },
      },
    ],
    [selectedServiceType, t],
  );

  const { data, isLoading, isError, refetch } = useFutureAvailability({
    physiotherapistId: id,
    dateFrom: getCurrentDatePlus30min(),
  });

  const earliestDay = useMemo(() => {
    const day =
      selectedServiceType === AppointmentSlots.STATIONARY
        ? data?.stationary
        : data?.online;
    return day?.[0];
  }, [data?.online, data?.stationary, selectedServiceType]);

  useEffect(() => {
    scrollViewRef.current?.scrollTo({ x: 0 });
  }, [selectedServiceType]);

  if (isError) return <FetchError coverScreen={false} action={refetch} />;

  const translatedType = t(
    selectedServiceType === AppointmentSlots.STATIONARY ? "T00546" : "T00547",
  );

  return (
    <View pointerEvents="box-none" style={gapMedium}>
      <View pointerEvents="box-none" style={gapMedium}>
        <Text variant="titleMedium">{t("T00625")}: </Text>
        <SegmentedButtons
          buttons={buttons}
          value={selectedServiceType as string}
          onValueChange={
            setSelectedServiceType as Dispatch<SetStateAction<string>>
          }
        />
      </View>
      <View
        pointerEvents="box-none"
        style={{
          flexDirection: "row",
          ...gapMedium,
        }}>
        <Text variant="titleMedium">{`${t(
          "T00378",
        )} ${translatedType.toLowerCase()}:`}</Text>
        <Text variant="titleMedium" style={{ color: palettes.primary[40] }}>
          {earliestDay && getFormattedDayWithMonth(new Date(earliestDay.date))}
        </Text>
      </View>
      <View pointerEvents="box-none">
        {isLoading ? (
          <ActivityIndicator />
        ) : earliestDay?.hours?.length ? (
          <ScrollView
            ref={scrollViewRef}
            horizontal
            contentContainerStyle={gapLarge}
            showsHorizontalScrollIndicator={false}>
            {earliestDay?.hours
              ?.filter(
                ({ appointment, serviceType }) =>
                  (serviceType === AppointmentSlots.ONLINE_OR_STATIONARY ||
                    serviceType === selectedServiceType) &&
                  !appointment,
              )
              .map(({ dateFrom }) => (
                <View key={dateFrom}>
                  <HourButton
                    isStationary={
                      selectedServiceType === AppointmentSlots.STATIONARY
                    }
                    onPress={() => onHourPress(selectedServiceType)}>
                    {getTime(dateFrom, locale)}
                  </HourButton>
                </View>
              ))}
          </ScrollView>
        ) : (
          <Text variant="bodyMedium">
            {t("T00632", {
              value: translatedType.toLowerCase(),
            })}
          </Text>
        )}
      </View>
    </View>
  );
};

export default PhysioProfileAvailability;
