import BaseStatisticInfoTile from "@components/Tile/BaseStatisticInfoTile";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, IconButton } from "react-native-paper";
import { StatsTileProps, StatsTileType } from "@components/Tile/EarningsTile";
import { FetchError } from "@components/errors";
import { useQuery } from "@tanstack/react-query";
import { getAppointmentsStatistics } from "@api/charts";
import { queryKeysAppointmentsStatistics } from "@screens/Appointments/queryKeysAppointments";
import { TransKey } from "@globalTypes/i18next";

const getTitle = (type: StatsTileType) => {
  switch (type) {
    case StatsTileType.TOTAL:
      return "T00687";
    case StatsTileType.MONTHLY:
      return "T00686";
    case StatsTileType.MONTHLY_SHORT:
      return "T00680";
  }
};

const AppointmentAmountTile: FC<StatsTileProps> = ({ type }) => {
  const { t } = useTranslation();
  const { isLoading, isError, refetch, data } = useQuery({
    queryFn: getAppointmentsStatistics,
    queryKey: queryKeysAppointmentsStatistics.all,
    refetchIntervalInBackground: true,
    refetchInterval: 120000,
  });
  const getValue = useCallback(() => {
    const { allAppointments, currentMonthAppointments } = data;
    const value =
      type === StatsTileType.TOTAL ? allAppointments : currentMonthAppointments;
    return value.toString();
  }, [data, type]);

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;
  return (
    <BaseStatisticInfoTile
      key={`appointments-${type}`}
      title={getTitle(type)}
      leftIcon={() => <IconButton icon="calendar-today" />}
      value={getValue()}
      label={t("T00704").toLowerCase() as TransKey}
    />
  );
};

export default AppointmentAmountTile;
