import { FC, useEffect } from "react";
import { PreviewScreenType } from "./PreviewScreen";
import { Linking } from "react-native";

export const PreviewScreen: FC<PreviewScreenType> = ({
  navigation: { goBack },
  route: {
    params: { uri },
  },
}) => {
  useEffect(() => {
    void Linking.openURL(uri);
    goBack();
  }, [goBack, uri]);
  return null;
};
