import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, ViewStyle, TextStyle } from "react-native";
import { ActivityIndicator, Chip, Text } from "react-native-paper";

import UpcomingAppointmentCard from "@components/cards/UpcomingAppointmentCard";
import { FetchError } from "@components/errors";

import { Appointment } from "@screens/Appointments/appointment.types";
import { globalStyles } from "@styles/global";
import { useUpcomingAppointmentDate } from "@hooks/appointments/useUpcomingAppointmentDate";
import { spacing16 } from "@styles/spacing";
import { theme } from "@styles/theme";

type Props = {
  error: boolean;
  loading: boolean;
  refetch: () => void;
  upcomingAppointment: Appointment;
  onPress: () => void;
};
type ChipProps = {
  style: ViewStyle;
  textStyle: TextStyle;
};

export const PatientUpcomingAppointment: FC<Props> = ({
  error,
  loading,
  refetch,
  upcomingAppointment,
  onPress,
}) => {
  const { t } = useTranslation();
  const { gapMedium } = globalStyles;

  const { chipContainer, chipText } = styles;

  const commonChipsProps: ChipProps = {
    style: { ...chipContainer },
    textStyle: { ...chipText },
  };

  const { firstUpcomingDate } = useUpcomingAppointmentDate<Appointment>({
    data: [upcomingAppointment],
  });

  return (
    <View style={gapMedium}>
      <Text variant="titleMedium">{t("T00047")}:</Text>
      {loading ? (
        <ActivityIndicator />
      ) : error ? (
        <FetchError action={refetch} coverScreen={false} />
      ) : (
        <>
          <Chip {...commonChipsProps}>
            {upcomingAppointment && !upcomingAppointment.cancelled
              ? firstUpcomingDate
              : t("T00048")}
          </Chip>
          {upcomingAppointment && !upcomingAppointment.cancelled ? (
            <UpcomingAppointmentCard
              upcomingAppointmentData={upcomingAppointment}
              onPress={onPress}
            />
          ) : null}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  chipContainer: {
    alignSelf: "flex-start",
    borderRadius: spacing16,
    backgroundColor: theme.colors.secondaryContainer,
  },
  chipText: { color: theme.colors.onSecondaryContainer },
});
