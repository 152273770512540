import { PrimaryButton } from "@components/buttons";
import { useTherapistPaymentMethods, useUserDetails } from "@hooks/index";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
import { queryKeysVerificationServices } from "@screens/Common/queryKeysServices";
import { queryKeysVerficiations } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/queryKeysPhysiotherapistVerification";
import { VerificationStatus } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import {
  getLicence,
  getRehabVerificationProgress,
  getServices,
} from "@services/ApiService/verifications";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigation, NavigationProp } from "@react-navigation/native";
import { RootStackParamList } from "@navigators/navigation.types";
import { spacing24, spacing8 } from "@styles/spacing";
import { Modal, View, TouchableOpacity, StyleSheet } from "react-native";
import { FAB } from "react-native-paper";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { isWeb } from "@utils/constants";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { FetchError } from "@components/errors";
import { useFutureAvailability } from "@hooks/availability/useFutureAvailability";
import { formatDateForApi } from "@utils/date";
import AbsoluteLoader from "@components/Loader/AbsoluteLoader";
import { useAppTheme } from "@styles/theme";
import { useFlags } from "@hooks/useFlags";

type Props = {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  navToAvailability: (initialDate?: Date) => void;
};

export const FABModal: FC<Props> = ({
  modalVisible,
  setModalVisible,
  navToAvailability,
}) => {
  const { contentContainer, buttonsContainer, pencilButton } = styles;
  const { gapLarge } = globalStyles;
  const [specializationId, setSpecializationId] = useState<number>(null);
  const { height, width } = useWindowDimensions();
  const tabBarHeight = useBottomTabBarHeight();
  const { id } = useUserDetails();
  const {
    data: availabilityData,
    isLoading: isAnyFutureLoading,
    isError: isAnyFutureError,
    refetch: refetchAllFutureData,
  } = useFutureAvailability({
    dateFrom: formatDateForApi(new Date(), "iso"),
    physiotherapistId: id,
  });
  const {
    stationaryMethods,
    isLoading: isStationaryMethodsLoading,
    isError: isStationaryMethodsError,
    refetch: refetchStationaryMethods,
  } = useTherapistPaymentMethods(id);
  const { t } = useTranslation();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const {
    colors: { backdrop, white, primary },
  } = useAppTheme();

  const {
    data,
    isLoading: isRehabVerificationLoading,
    isError: isRehabVerificationError,
    refetch: refetchRehabVerification,
  } = useQuery({
    queryFn: getRehabVerificationProgress,
    queryKey: queryKeysVerficiations.verificationProgress(),
  });

  const {
    data: specializationData,
    isSuccess,
    isLoading: isLicenceLoading,
    isError: isLicenceError,
    refetch: refetchLicence,
  } = useQuery({
    queryKey: queryKeysVerficiations.licenceSpecialization(),
    queryFn: getLicence,
    select: data =>
      data.find(
        licence => licence.verificationStatus === VerificationStatus.VERIFIED,
      )?.specialization,
  });
  const servicesQueryEnabled = !!specializationId;
  const {
    data: verificationServices,
    isLoading: isServicesLoading,
    isError: isServicesError,
    refetch: refetchServices,
  } = useQuery({
    queryKey: queryKeysVerificationServices.list({
      specializationId,
    }),
    queryFn: async () => getServices({ specializationId }),
    enabled: servicesQueryEnabled,
  });
  const {
    flags: { SMS_VERIFICATION_ENABLED },
  } = useFlags();

  useEffect(() => {
    if (isSuccess && !!specializationData?.id)
      setSpecializationId(specializationData.id);
  }, [isSuccess, specializationData?.id]);

  const hideModal = () => setModalVisible(false);

  const onPencilPress = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    navToAvailability(today);
    hideModal();
  };

  const isSomeStationaryService = useMemo(
    () =>
      verificationServices?.some(
        ({ archived, serviceType }) =>
          !archived && serviceType === "Stationary",
      ),
    [verificationServices],
  );

  const disableAddNewAppointment = useMemo(
    () =>
      data?.licenceVerification !== VerificationStatus.VERIFIED ||
      data?.addressVerification !== VerificationStatus.VERIFIED ||
      !data?.subscriptionVerification ||
      !stationaryMethods[0].isActive ||
      !isSomeStationaryService ||
      !availabilityData?.stationary.length,
    [
      availabilityData?.stationary,
      data?.addressVerification,
      data?.licenceVerification,
      data?.subscriptionVerification,
      isSomeStationaryService,
      stationaryMethods,
    ],
  );

  const onCalendarPress = () => {
    if (disableAddNewAppointment) {
      showAlertWithCustomButtons({
        title: t("T01411"),
        message: t(SMS_VERIFICATION_ENABLED ? "T01526" : "T01412"),
        confirmButton: { text: t("T00394"), onPress: hideModal },
      });
    } else {
      hideModal();
      navigate("ServiceAndDate", { physiotherapistId: id });
    }
  };

  const isAnyLoading = useMemo(
    () =>
      isRehabVerificationLoading ||
      (servicesQueryEnabled && isServicesLoading) ||
      isLicenceLoading ||
      isStationaryMethodsLoading ||
      isAnyFutureLoading,
    [
      isRehabVerificationLoading,
      servicesQueryEnabled,
      isServicesLoading,
      isLicenceLoading,
      isStationaryMethodsLoading,
      isAnyFutureLoading,
    ],
  );
  const isAnyError = useMemo(
    () =>
      isRehabVerificationError ||
      isServicesError ||
      isLicenceError ||
      isStationaryMethodsError ||
      isAnyFutureError,
    [
      isRehabVerificationError,
      isServicesError,
      isLicenceError,
      isStationaryMethodsError,
      isAnyFutureError,
    ],
  );
  const refetchEverything = useCallback(
    async () =>
      await Promise.all([
        refetchAllFutureData(),
        refetchStationaryMethods(),
        refetchRehabVerification(),
        refetchLicence(),
        refetchServices(),
      ]),
    [
      refetchAllFutureData,
      refetchStationaryMethods,
      refetchRehabVerification,
      refetchLicence,
      refetchServices,
    ],
  );

  return (
    <Modal
      visible={modalVisible}
      animationType="fade"
      style={{ flex: 1 }}
      transparent>
      {isAnyLoading ? (
        <AbsoluteLoader />
      ) : isAnyError ? (
        <FetchError action={refetchEverything} />
      ) : (
        <View style={[isWeb && { width, alignSelf: "center" }, { flex: 1 }]}>
          <TouchableOpacity
            onPress={hideModal}
            style={{
              height,
              width,
              position: "absolute",
              backgroundColor: backdrop,
            }}
          />
          <View
            style={[
              { bottom: tabBarHeight + spacing24 },
              gapLarge,
              contentContainer,
            ]}>
            <View style={buttonsContainer}>
              <PrimaryButton label="T00101" onPress={onPencilPress} />
              <FAB
                icon="pencil-outline"
                onPress={onPencilPress}
                size="small"
                style={pencilButton}
                color={white}
              />
            </View>
            <View style={buttonsContainer}>
              <PrimaryButton label="T01413" onPress={onCalendarPress} />
              <FAB
                icon="calendar"
                onPress={onCalendarPress}
                style={{ backgroundColor: primary }}
                color={white}
              />
            </View>
          </View>
        </View>
      )}
    </Modal>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    position: "absolute",
    right: spacing24,
    width: "100%",
    alignItems: "flex-end",
  },
  buttonsContainer: {
    flexDirection: "row",
    alignItems: "center",
    ...globalStyles.gapLarge,
  },
  pencilButton: {
    backgroundColor: palettes.primary[40],
    marginHorizontal: spacing8,
  },
});
