import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill="#6FABE6"
      d="M21 2v7.5l-2.5-1h-5A1.5 1.5 0 0 1 12 7V2a1.5 1.5 0 0 1 1-1.41c.33-.13 0-.09 6.5-.09A1.5 1.5 0 0 1 21 2Z"
    />
    <Path
      fill="#82BCF4"
      d="M21 2v5.5h-6a2 2 0 0 1-2-2V.59c.16-.06.33-.091.5-.09h6A1.5 1.5 0 0 1 21 2Z"
    />
    <Path
      fill="#DB5669"
      d="M12 3c0 5.365.035 5.17-.09 5.5a1.5 1.5 0 0 1-1.41 1h-5l-2.5 1V3a1.5 1.5 0 0 1 1.5-1.5h6A1.5 1.5 0 0 1 12 3Z"
    />
    <Path
      fill="#F26674"
      d="M12 3c0 5.365.035 5.17-.09 5.5H6a2 2 0 0 1-2-2V1.59c.16-.06.33-.091.5-.09h6A1.5 1.5 0 0 1 12 3Z"
    />
    <Path fill="#A87E6B" d="M21.5 15v4.77h-7V15a3.5 3.5 0 1 1 7 0Z" />
    <Path
      fill="#BE927C"
      d="M21.5 15v4.77h-6V15a3.5 3.5 0 0 1 3-3.46 3.5 3.5 0 0 1 3 3.46Z"
    />
    <Path
      fill="#DB5669"
      d="M20.5 19.5a2 2 0 0 1 2 2v2h-9v-2a2 2 0 0 1 2-2h5Z"
    />
    <Path
      fill="#F26674"
      d="M22.5 21.5v1h-7a1 1 0 0 1-1-1c0-.66-.055-1.325.64-1.965.28-.035 5.41-.035 5.36-.035a2 2 0 0 1 2 2Z"
    />
    <Path
      fill="#6FABE6"
      d="M10.5 21.5v2h-9v-2a2 2 0 0 1 1.64-1.965c.25-.035.14-.035 5.36-.035a2 2 0 0 1 2 2Z"
    />
    <Path
      fill="#82BCF4"
      d="M10.5 21.5v1h-7a1 1 0 0 1-1-1c0-.66-.055-1.325.64-1.965.28-.035 5.41-.035 5.36-.035a2 2 0 0 1 2 2Z"
    />
    <Path
      fill="#F6CCAF"
      d="M8 14.5c0 1.81.18 2.705-.64 3.465A2 2 0 0 1 4 16.5v-2h4Z"
    />
    <Path
      fill="#FFDEC7"
      d="M8 14.5c0 1.81.18 2.705-.64 3.465A2 2 0 0 1 5 16v-1.5h3Z"
    />
    <Path fill="#374F68" d="M8 13.5v1H4c0-1.12.05-.89-.5-2H7a1 1 0 0 1 1 1Z" />
    <Path
      fill="#425B72"
      d="M8 13.5H4.955a5.194 5.194 0 0 0-.455-1H7a1 1 0 0 1 1 1Z"
    />
    <Path
      fill="#F6CCAF"
      d="M20 15c-.28 1.965-.245 2.465-.775 3a1.724 1.724 0 0 1-2.935-1L16 15a1.625 1.625 0 0 0 1.5-1 3.42 3.42 0 0 0 2.5 1Z"
    />
    <Path
      fill="#FFDEC7"
      d="M20 15c-.28 1.965-.245 2.465-.775 3a1.73 1.73 0 0 1-1.935-1.5c-.385-2.665-.36-1.425.21-2.5a3.42 3.42 0 0 0 2.5 1Z"
    />
    <Path
      fill="#F6CCAF"
      d="M19 18.18v1.32l-1 1-1-1v-1.32h2ZM7 18.23v1.27l-1 1-1-1v-1.27h2Z"
    />
    <Path
      fill="#FFDEC7"
      d="M7 18.23v1.27l-.75.75-.75-.75v-1.27H7ZM19 18.18v1.27l-.75.75-.75-.75v-1.27H19Z"
    />
    <Path
      fill="#EDEBF2"
      d="M10 4H5a.5.5 0 1 1 0-1h5a.5.5 0 0 1 0 1ZM10 8H5a.5.5 0 1 1 0-1h5a.5.5 0 0 1 0 1ZM10 6H5a.5.5 0 1 1 0-1h5a.5.5 0 0 1 0 1ZM19 3h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1ZM19 7h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1ZM19 5h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1Z"
    />
  </Svg>
);
export default SvgComponent;
