import { StatusType } from "@components/Status/status.types";
import { Appointment } from "@screens/Appointments/appointment.types";

export const setStatus = ({
  dateFrom: beginDate,
  dateTo: endDate,
  service,
  serviceType,
  payment,
  paymentType,
  patientAbsent,
  cancelled,
}: Appointment) => {
  if (!beginDate || !serviceType) return null;
  const currentTime = new Date().getTime();
  const dateFrom = new Date(beginDate).getTime();
  const dateTo = new Date(endDate).getTime();

  const isStationary = serviceType === "Stationary";
  const scheduled = currentTime < dateFrom;
  const ended = dateTo < currentTime;
  const inProgress = dateFrom < currentTime && currentTime < dateTo && !ended;

  const { freeService } = service.serviceItem;

  let status: StatusType;

  switch (true) {
    case cancelled:
      status = StatusType.CANCELED;
      break;
    case patientAbsent:
      status = StatusType.PATIENT_ABSENT;
      break;
    case paymentType === "Online" && !payment?.paymentSucceeded && !freeService:
      status = StatusType.NOT_PAID;
      break;
    case isStationary &&
      scheduled &&
      (paymentType === "Stationary" ||
        (paymentType === "Online" && payment?.paymentSucceeded)):
      status = StatusType.STATIONARY;
      break;
    case isStationary && inProgress:
      status = StatusType.DURING_THE_DURATION_STATIONARY;
      break;
    case isStationary && ended:
      status = StatusType.STATIONARY_ENDED;
      break;
    case !isStationary && scheduled:
      status = StatusType.ONLINE;
      break;
    case !isStationary && inProgress:
      status = StatusType.DURING_THE_DURATION_ONLINE;
      break;
    case !isStationary && ended:
      status = StatusType.ONLINE_ENDED;
      break;
    default:
      break;
  }

  return status;
};
