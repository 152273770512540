import { isWeb } from "@utils/constants";
import { getFileSizeFromBase64 } from "@utils/files";
import { showAlert } from "@utils/showAlert";
import { isFileSizeLessThanMax } from "@utils/validators";
import {
  ImagePickerResult,
  launchImageLibraryAsync,
  MediaTypeOptions,
} from "expo-image-picker";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { maxVideoSizeInBytes } from "./useResources/constants";

type useImageOrVideoOptions = { fileType: "video" | "image" };

export const useImageOrVideo = (
  { fileType }: useImageOrVideoOptions = { fileType: "image" },
) => {
  const [pickedFile, setPickedFile] = useState<ImagePickerResult>();
  const [isFilePickLoading, setIsFilePickLoading] = useState<boolean>(false);
  const isVideo = fileType === "video";
  const { t } = useTranslation();

  const mediaType = isVideo ? MediaTypeOptions.Videos : MediaTypeOptions.Images;

  const pickFileFromDevice = useCallback(async () => {
    setIsFilePickLoading(true);
    const result = await launchImageLibraryAsync({
      mediaTypes: mediaType,
      allowsEditing: false,
      aspect: [4, 4],
      quality: 0.7,
    });
    let isResultValid = true;
    if (result.canceled) {
      setIsFilePickLoading(false);
    } else {
      const fileSize = isWeb
        ? getFileSizeFromBase64(result.assets[0]?.uri)
        : result.assets[0]?.fileSize;
      if (isVideo && !!fileSize) {
        isResultValid = isFileSizeLessThanMax(fileSize, maxVideoSizeInBytes);
      }
    }

    setTimeout(() => {
      if (!result.canceled) {
        setIsFilePickLoading(false);
        if (!isResultValid) {
          showAlert(t("T01502"));
        } else setPickedFile(result);
      }
    });
  }, [isVideo, mediaType, t]);

  const removeFile = useCallback(() => setPickedFile(null), []);

  return {
    pickedFile,
    pickFileFromDevice,
    isFilePickLoading,
    removeFile,
  };
};
