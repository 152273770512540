import { SvgLocation, SvgVideoChat } from "@assets/svg";
import { AvailabilityGroup } from "@components/PhysioAvailability/PhysioAvailability.types";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { getTime } from "@utils/date";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LayoutChangeEvent, View } from "react-native";
import { Card, CardProps, Text } from "react-native-paper";
import { AppointmentSlots } from "./availability.types";
import { TransKey } from "@globalTypes/i18next";
import { useLocale } from "@hooks/useLocale";

type AvailabilityTileProps = Omit<CardProps, "children"> & {
  data: AvailabilityGroup;
  onLayout?: (e: LayoutChangeEvent) => void;
};

export const setIcon = (type: AppointmentSlots) => {
  const stationaryColor = palettes.primary["40"];
  const onlineColor = palettes.tertiary["40"];
  switch (type) {
    case AppointmentSlots.STATIONARY:
      return <SvgLocation customColor={stationaryColor} />;
    case AppointmentSlots.ONLINE:
      return <SvgVideoChat customColor={onlineColor} />;
    case AppointmentSlots.ONLINE_OR_STATIONARY:
      return (
        <View style={[{ flexDirection: "row" }, globalStyles.gapSmall]}>
          <SvgLocation customColor={stationaryColor} />
          <SvgVideoChat customColor={onlineColor} />
        </View>
      );
  }
};

const AvailabilityTile: FC<AvailabilityTileProps> = ({
  data,
  mode,
  onPress,
  onLayout,
}) => {
  const { t } = useTranslation();
  const { hours, serviceType } = data;
  const dateFrom = hours[0].dateFrom;
  const dateTo = hours[hours.length - 1].dateTo;
  const { locale } = useLocale();

  const serviceTypeText = (
    <Text variant="titleSmall" style={{ marginRight: spacing8 }}>{`${t(
      {
        [AppointmentSlots.ONLINE]: "T00483",
        [AppointmentSlots.STATIONARY]: "T00484",
        [AppointmentSlots.ONLINE_OR_STATIONARY]: "T00485",
      }[serviceType] as TransKey,
    )} `}</Text>
  );

  const serviceTimeframe = (
    <Text variant="bodyMedium">
      {`${getTime(dateFrom, locale)} - ${getTime(dateTo, locale)}`}
    </Text>
  );

  return (
    <View onLayout={onLayout}>
      <Card
        onPress={onPress}
        mode={mode || "outlined"}
        style={[
          mode === "contained" && {
            backgroundColor: "transparent",
          },
          { borderColor: theme.colors.outlineVariant },
        ]}>
        <Card.Content
          style={{
            flexDirection: "row",
            paddingVertical: spacing8,
            flexWrap: "wrap",
          }}>
          <View style={{ marginRight: spacing8 }}>{setIcon(serviceType)}</View>
          {serviceTypeText}
          {serviceTimeframe}
        </Card.Content>
      </Card>
    </View>
  );
};

export default AvailabilityTile;
