import DropdownPicker from "@components/DropdownPicker/DropdownPicker";
import { useAuth } from "@contexts/AuthContext/auth";
import { CurrencyType } from "@globalTypes/common.types";
import { TransKey } from "@globalTypes/i18next";
import { useServiceMutation } from "@hooks/verification/useServiceMutation";
import { PricesType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { ServiceDataType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { CurrencyChoices } from "@services/ApiService/api.types";
import { globalStyles } from "@styles/global";
import { setDefaultPricesForAvailableCurrencies } from "@utils/prices";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import PrimaryButton from "../buttons/PrimaryButton";
import { AddingServiceData } from "./AddServiceBottomSheetContent";

type Props = {
  serviceName: string;
  data: AddingServiceData;
  disableModal: () => void;
  currencyAmount: CurrencyChoices[];
  service?: ServiceDataType;
  serviceItem?: number;
};

export const AddServicePricesBottomSheetContent: FC<Props> = ({
  serviceName,
  data: { price, selectedCurrency, ...otherData },
  disableModal,
  currencyAmount,
  service = null,
  serviceItem,
}) => {
  const {
    user: { currency },
  } = useAuth();
  const dropdownStatesHidden = new Array(currencyAmount.length).fill(false);
  const [prices, setPrices] = useState<PricesType[]>([]);
  const [dropdownOpenStates, setDropdownOpenStates] =
    useState<boolean[]>(dropdownStatesHidden);
  const { mutate, isMutationLoading } = useServiceMutation({
    ...otherData,
    prices,
    service,
    hideBottomSheet: disableModal,
  });

  const {
    user: { currency: userCurrency },
  } = useAuth();
  const { t } = useTranslation();

  const { gapLarge, gapMedium } = globalStyles;
  const infos = [t("T00780"), t("T00781"), t("T00782")];

  const getDefaultValue = useCallback(
    (selectedCurrency: CurrencyType): number => {
      const defaultValue: number | undefined = prices.find(
        price => price.currency === selectedCurrency,
      )?.amount;
      return defaultValue || currencyAmount[0].value[0];
    },
    [currencyAmount, prices],
  );

  const onChangeValue = (value: number, selectedCurrency: CurrencyType) =>
    setPrices(prevPrices => [
      ...prevPrices.filter(
        prevPrice => prevPrice.currency !== selectedCurrency,
      ),
      { amount: value, currency: selectedCurrency },
    ]);

  const onPressButton = () => mutate({ ...otherData, serviceItem, prices });

  useEffect(() => {
    if (service) {
      const prices = service.prices.map(e =>
        e.currency === selectedCurrency ? { ...e, amount: price } : e,
      );
      setPrices(prices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !service &&
      setPrices(
        setDefaultPricesForAvailableCurrencies(price, currency, currencyAmount),
      );
  }, [currency, currencyAmount, price, service]);

  return (
    <View style={gapLarge}>
      <View style={gapMedium}>
        <Text variant="bodyLarge">{serviceName}</Text>
        {infos.map((e, i) => (
          <Text key={`info-${e}-${i}`} variant="bodyMedium">
            {e}
          </Text>
        ))}
        <Text variant="titleSmall">
          {t("T00783", { value: `${price} ${userCurrency}` })}
        </Text>
      </View>
      {currencyAmount.map(({ currency: selectedCurrency, value }, i) => (
        <DropdownPicker<number>
          key={`picker-${currency}-${i}`}
          items={value.map(e => ({ value: e, label: `${e}` }))}
          defaultValue={getDefaultValue(selectedCurrency)}
          onSelectItem={item => onChangeValue(item.value, selectedCurrency)}
          dropdownLabelText={selectedCurrency as TransKey}
          onOpen={() => {
            const newDropdownOpenStates = dropdownStatesHidden;
            newDropdownOpenStates[i] = true;
            setDropdownOpenStates(newDropdownOpenStates);
          }}
          onClose={() => {
            const newDropdownOpenStates = { ...dropdownOpenStates };
            newDropdownOpenStates[i] = false;
            setDropdownOpenStates(newDropdownOpenStates);
          }}
          isDropdownOpen={dropdownOpenStates[i] || false}
          multiDropdownContent
          isInBottomSheet
          dropDownDirection="TOP"
        />
      ))}
      <PrimaryButton
        label={service ? "T01052" : "T00250"}
        onPress={onPressButton}
        loading={isMutationLoading}
        disabled={isMutationLoading}
      />
    </View>
  );
};
