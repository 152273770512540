import {
  SvgCheckCircleCustom,
  SvgPauseGreyIcon,
  SvgPlayGreyIcon,
} from "@assets/svg";
import { spacing24 } from "@styles/spacing";
import { FC, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View, ViewProps } from "react-native";
import { Text } from "react-native-paper";
import { formatTime, getTextAndStylesForPlayerSection } from "./player.helpers";
import { PlayerMode } from "./player.types";
import { useTranslation } from "react-i18next";
import {
  BaseSeries,
  SeriesType,
} from "@screens/TrainingsAndExercises/exercise.types";

type PlayerSectionProps = Pick<ViewProps, "onLayout"> & {
  playerMode: PlayerMode;
  currentSeries: BaseSeries;
  isPlaying: boolean;
  onAutoStart: () => void;
  onLeftButtonPress: () => void;
  onRightButtonPress: (totalValue: number) => void;
  getInitialTimeValue: (mode: PlayerMode) => number;
};

const PlayerSection: FC<PlayerSectionProps> = ({
  playerMode,
  currentSeries,
  isPlaying,
  onLayout,
  onAutoStart,
  onLeftButtonPress,
  onRightButtonPress,
  getInitialTimeValue,
}) => {
  const { t } = useTranslation();

  const [totalValue, setTotalValue] = useState(0);

  const [currentValue, setCurrentValue] = useState(
    getInitialTimeValue(playerMode),
  );
  const [iconDimensions, setIconDimensions] = useState({
    width: 0,
    height: 0,
  });
  const { seriesType, value } = currentSeries;

  const { text, style } = getTextAndStylesForPlayerSection(playerMode);

  useEffect(() => {
    if (playerMode === PlayerMode.NOT_STARTED) {
      const interval = setInterval(() => {
        setCurrentValue(prevTime => prevTime - 1);
      }, 1000);

      if (currentValue === 0) {
        clearInterval(interval);
        onAutoStart();
        onLeftButtonPress();
      }

      return () => clearInterval(interval);
    }
  }, [currentValue, onLeftButtonPress, onAutoStart, playerMode]);
  useEffect(() => {
    setCurrentValue(getInitialTimeValue(playerMode));
    setTotalValue(0);
  }, [getInitialTimeValue, playerMode]);

  useEffect(() => {
    if (
      (isPlaying && seriesType === SeriesType.TIME) ||
      playerMode === PlayerMode.BREAK_BETWEEN_SERIES ||
      playerMode === PlayerMode.BREAK_BETWEEN_EXERCISES
    ) {
      const interval = setInterval(() => {
        setCurrentValue(prevTime => {
          const nextTime = prevTime - 1;
          setTotalValue(prevTotal => prevTotal + 1);
          return nextTime;
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [seriesType, isPlaying, playerMode]);

  const { container, titleContainer, titleStyle } = styles;
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: style.backgroundColor,
      }}
      onLayout={onLayout}>
      <View style={titleContainer}>
        <Text
          variant="headlineMedium"
          style={[titleStyle, { color: style.color }]}>
          {t(text)}
        </Text>
      </View>
      <View style={container}>
        {playerMode === PlayerMode.EXERCISE &&
        seriesType === SeriesType.TIME ? (
          <TouchableOpacity onPress={onLeftButtonPress}>
            {isPlaying ? <SvgPauseGreyIcon /> : <SvgPlayGreyIcon />}
          </TouchableOpacity>
        ) : (
          <View
            style={{
              height: iconDimensions.height,
              width: iconDimensions.width,
            }}
          />
        )}
        <Text
          variant="displayMedium"
          style={{
            color: style.color,
            textAlign: "center",
          }}>
          {playerMode === PlayerMode.EXERCISE &&
          seriesType === SeriesType.REPETITIONS
            ? `x ${value}`
            : formatTime(currentValue)}
        </Text>
        <TouchableOpacity
          onPress={() => onRightButtonPress(totalValue)}
          onLayout={({ nativeEvent }) =>
            setIconDimensions({
              height: nativeEvent.layout.height,
              width: nativeEvent.layout.width,
            })
          }>
          <SvgCheckCircleCustom />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleContainer: { flex: 4, justifyContent: "flex-end" },
  container: {
    flex: 6,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginHorizontal: spacing24,
  },
  titleStyle: {
    textAlign: "center",
    textTransform: "uppercase",
  },
});

export default PlayerSection;
