import Svg, {
  ClipPath,
  Defs,
  G,
  Mask,
  Path,
  Rect,
  SvgProps,
} from "react-native-svg";
import { spacing16 } from "@styles/spacing";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { tutorialSvgRatio } from "@assets/svg/tutorials/index";

export const SvgWorkoutStatistics = (props: SvgProps) => {
  const { width } = useWindowDimensions();
  const svgWidth = width - spacing16 * 2;
  return (
    <Svg
      width={svgWidth}
      height={svgWidth / tutorialSvgRatio}
      viewBox="0 0 329 136"
      fill="none"
      {...props}>
      <G clip-path="url(#clip0_62319_45367)">
        <Mask
          id="mask0_62319_45367"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="329"
          height="136">
          <Path d="M328.252 0H0.251953V136H328.252V0Z" fill="white" />
        </Mask>
        <G>
          <Path
            d="M8.26172 88.3496H0.261719V136.35H8.26172V88.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M18.2617 68.3496H10.2617V136.35H18.2617V68.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M48.2617 106.35H40.2617V136.35H48.2617V106.35Z"
            fill="#DFE1F9"
          />
          <Path
            d="M58.2617 72.3496H50.2617V136.35H58.2617V72.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M68.2617 106.35H60.2617V136.35H68.2617V106.35Z"
            fill="#DFE1F9"
          />
          <Path
            d="M98.2617 78.3496H90.2617V136.35H98.2617V78.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M108.262 42.3496H100.262V136.35H108.262V42.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M118.262 42.3496H110.262V136.35H118.262V42.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M128.262 81.3496H120.262V136.35H128.262V81.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M268.262 81.3496H260.262V136.35H268.262V81.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M138.262 97.3496H130.262V136.35H138.262V97.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M278.262 97.3496H270.262V136.35H278.262V97.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M148.262 60.3496H140.262V136.35H148.262V60.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M288.262 60.3496H280.262V136.35H288.262V60.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M168.262 50.3496H160.262V136.35H168.262V50.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M308.262 50.3496H300.262V136.35H308.262V50.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M178.262 72.3496H170.262V136.35H178.262V72.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M188.262 106.35H180.262V136.35H188.262V106.35Z"
            fill="#DFE1F9"
          />
          <Path
            d="M208.262 106.35H200.262V136.35H208.262V106.35Z"
            fill="#DFE1F9"
          />
          <Path
            d="M218.262 93.3496H210.262V136.35H218.262V93.3496Z"
            fill="#DFE1F9"
          />
          <Path
            d="M228.262 106.35H220.262V136.35H228.262V106.35Z"
            fill="#DFE1F9"
          />
          <Path
            d="M8.26172 121.03H0.261719V136.35H8.26172V121.03Z"
            fill="#BAFAAA"
          />
          <Path
            d="M18.2617 114.65H10.2617V136.35H18.2617V114.65Z"
            fill="#BAFAAA"
          />
          <Path
            d="M48.2617 126.78H40.2617V136.35H48.2617V126.78Z"
            fill="#BAFAAA"
          />
          <Path
            d="M58.2617 115.92H50.2617V136.35H58.2617V115.92Z"
            fill="#BAFAAA"
          />
          <Path
            d="M68.2617 126.78H60.2617V136.35H68.2617V126.78Z"
            fill="#BAFAAA"
          />
          <Path
            d="M98.2617 117.84H90.2617V136.35H98.2617V117.84Z"
            fill="#BAFAAA"
          />
          <Path
            d="M108.262 106.35H100.262V136.35H108.262V106.35Z"
            fill="#BAFAAA"
          />
          <Path
            d="M118.262 106.35H110.262V136.35H118.262V106.35Z"
            fill="#BAFAAA"
          />
          <Path
            d="M128.262 118.8H120.262V136.35H128.262V118.8Z"
            fill="#BAFAAA"
          />
          <Path
            d="M268.262 118.8H260.262V136.35H268.262V118.8Z"
            fill="#BAFAAA"
          />
          <Path
            d="M138.262 123.9H130.262V136.35H138.262V123.9Z"
            fill="#BAFAAA"
          />
          <Path
            d="M278.262 123.9H270.262V136.35H278.262V123.9Z"
            fill="#BAFAAA"
          />
          <Path
            d="M148.262 112.09H140.262V136.35H148.262V112.09Z"
            fill="#BAFAAA"
          />
          <Path
            d="M288.262 112.09H280.262V136.35H288.262V112.09Z"
            fill="#BAFAAA"
          />
          <Path
            d="M168.262 108.9H160.262V136.35H168.262V108.9Z"
            fill="#BAFAAA"
          />
          <Path
            d="M308.262 108.9H300.262V136.35H308.262V108.9Z"
            fill="#BAFAAA"
          />
          <Path
            d="M178.262 115.92H170.262V136.35H178.262V115.92Z"
            fill="#BAFAAA"
          />
          <Path
            d="M188.262 126.78H180.262V136.35H188.262V126.78Z"
            fill="#BAFAAA"
          />
          <Path
            d="M208.262 126.78H200.262V136.35H208.262V126.78Z"
            fill="#BAFAAA"
          />
          <Path
            d="M218.262 122.63H210.262V136.35H218.262V122.63Z"
            fill="#BAFAAA"
          />
          <Path
            d="M228.262 126.78H220.262V136.35H228.262V126.78Z"
            fill="#BAFAAA"
          />
          <Path
            d="M304.932 15.2805L233.662 5.77054C228.982 5.15054 224.682 8.43054 224.052 13.1105L210.392 115.491C209.772 120.171 213.052 124.471 217.732 125.101L289.002 134.611C293.682 135.231 297.982 131.951 298.612 127.271L312.272 24.8905C312.892 20.2105 309.612 15.9105 304.932 15.2805Z"
            fill="#E6E6E6"
          />
          <Path
            d="M301.262 19.0697L236.202 10.3897C231.522 9.76968 227.222 13.0497 226.592 17.7297L214.052 111.7C213.432 116.38 216.712 120.68 221.392 121.31L286.452 129.99C291.132 130.61 295.432 127.33 296.062 122.65L308.602 28.6797C309.222 23.9997 305.942 19.6997 301.262 19.0697Z"
            fill="white"
          />
          <Path
            d="M296.362 38.2204L236.172 30.1904C235.682 30.1204 235.242 29.8704 234.942 29.4804C234.642 29.0904 234.512 28.6004 234.572 28.1104C234.632 27.6204 234.892 27.1804 235.282 26.8804C235.672 26.5804 236.162 26.4504 236.652 26.5104L296.842 34.5404C297.332 34.6104 297.772 34.8604 298.072 35.2504C298.372 35.6404 298.502 36.1304 298.442 36.6204C298.372 37.1104 298.122 37.5504 297.732 37.8504C297.342 38.1504 296.842 38.2804 296.362 38.2204Z"
            fill="#E6E6E6"
          />
          <Path
            d="M293.842 57.1501L233.652 49.1201C233.162 49.0501 232.722 48.8001 232.422 48.4101C232.122 48.0201 231.992 47.5201 232.052 47.0401C232.122 46.5501 232.372 46.1101 232.762 45.8101C233.152 45.5101 233.642 45.3801 234.132 45.4401L294.322 53.4701C294.812 53.5401 295.252 53.7901 295.552 54.1801C295.852 54.5701 295.982 55.0701 295.912 55.5501C295.852 56.0401 295.592 56.4801 295.202 56.7801C294.812 57.0801 294.312 57.2101 293.832 57.1501H293.842Z"
            fill="#E6E6E6"
          />
          <Path
            d="M291.312 76.07L231.122 68.04C230.632 67.97 230.192 67.72 229.892 67.33C229.592 66.94 229.462 66.44 229.522 65.96C229.592 65.47 229.842 65.03 230.232 64.73C230.622 64.43 231.112 64.3 231.602 64.36L291.792 72.39C292.282 72.46 292.722 72.71 293.022 73.1C293.322 73.49 293.452 73.99 293.392 74.47C293.322 74.96 293.072 75.4 292.682 75.7C292.292 76 291.792 76.13 291.312 76.07Z"
            fill="#E6E6E6"
          />
          <Path
            d="M288.792 94.9899L228.602 86.9599C228.112 86.8899 227.672 86.6399 227.372 86.2499C227.072 85.8599 226.942 85.3699 227.002 84.8799C227.072 84.3899 227.322 83.9499 227.712 83.6499C228.102 83.3499 228.592 83.2199 229.082 83.2799L289.272 91.3099C289.762 91.3799 290.202 91.6299 290.502 92.0199C290.802 92.4099 290.932 92.8999 290.872 93.3899C290.802 93.8799 290.552 94.3199 290.162 94.6199C289.772 94.9199 289.272 95.0499 288.792 94.9899Z"
            fill="#E6E6E6"
          />
          <Path
            d="M286.262 113.92L226.072 105.89C225.582 105.82 225.142 105.57 224.842 105.18C224.542 104.79 224.412 104.29 224.472 103.81C224.542 103.32 224.792 102.88 225.182 102.58C225.572 102.28 226.062 102.15 226.552 102.21L286.742 110.24C287.232 110.31 287.672 110.56 287.972 110.95C288.272 111.34 288.402 111.84 288.332 112.32C288.272 112.81 288.012 113.25 287.622 113.55C287.232 113.85 286.732 113.98 286.252 113.92H286.262Z"
            fill="#E6E6E6"
          />
          <Path
            d="M285.852 36.8201L235.912 30.1601C235.422 30.0901 234.982 29.8401 234.682 29.4501C234.382 29.0601 234.252 28.5701 234.312 28.0801C234.382 27.5901 234.632 27.1501 235.022 26.8501C235.412 26.5501 235.912 26.4201 236.392 26.4801L286.332 33.1401C286.822 33.2101 287.262 33.4601 287.562 33.8501C287.862 34.2401 287.992 34.7301 287.922 35.2201C287.862 35.7101 287.602 36.1501 287.212 36.4501C286.822 36.7501 286.322 36.8801 285.842 36.8201H285.852Z"
            fill="#4666FF"
          />
          <Path
            d="M267.822 53.67L233.392 49.08C232.902 49.01 232.462 48.76 232.162 48.37C231.862 47.98 231.732 47.48 231.792 47C231.862 46.51 232.112 46.07 232.502 45.77C232.892 45.47 233.392 45.34 233.872 45.4L268.302 49.99C268.792 50.06 269.232 50.31 269.532 50.7C269.832 51.09 269.962 51.59 269.902 52.07C269.832 52.56 269.582 53 269.192 53.3C268.802 53.6 268.302 53.73 267.822 53.67Z"
            fill="#4666FF"
          />
          <Path
            d="M246.372 70.07L230.862 68C230.372 67.93 229.932 67.68 229.632 67.29C229.332 66.9 229.202 66.4 229.272 65.92C229.332 65.43 229.592 64.99 229.982 64.69C230.372 64.39 230.872 64.26 231.352 64.32L246.862 66.39C247.352 66.46 247.792 66.71 248.092 67.1C248.392 67.49 248.522 67.99 248.452 68.47C248.382 68.96 248.132 69.4 247.742 69.7C247.352 70 246.852 70.13 246.372 70.07Z"
            fill="#4666FF"
          />
          <Path
            d="M283.012 94.2199L228.342 86.9199C227.852 86.8499 227.412 86.5999 227.112 86.2099C226.812 85.8199 226.682 85.3299 226.742 84.8399C226.812 84.3499 227.062 83.9099 227.452 83.6099C227.842 83.3099 228.332 83.1799 228.822 83.2399L283.492 90.5399C283.982 90.6099 284.422 90.8599 284.722 91.2499C285.022 91.6399 285.152 92.1299 285.092 92.6199C285.022 93.1099 284.772 93.5499 284.382 93.8499C283.992 94.1499 283.492 94.2799 283.012 94.2199Z"
            fill="#4666FF"
          />
          <Path
            d="M268.392 111.531L225.812 105.851C225.322 105.781 224.882 105.531 224.582 105.141C224.282 104.751 224.152 104.251 224.212 103.771C224.272 103.291 224.532 102.841 224.922 102.541C225.312 102.241 225.812 102.111 226.292 102.171L268.872 107.851C269.362 107.921 269.802 108.171 270.102 108.561C270.402 108.951 270.532 109.451 270.462 109.931C270.402 110.421 270.142 110.861 269.752 111.161C269.362 111.461 268.862 111.591 268.382 111.531H268.392Z"
            fill="#4666FF"
          />
          <Path
            d="M76.5819 42.0805C77.2919 42.7905 78.2319 43.2205 79.2319 43.2805C80.2319 43.3405 81.2219 43.0305 82.0119 42.4105C82.8019 41.7905 83.3319 40.9005 83.5119 39.9205C83.6919 38.9305 83.5019 37.9205 82.9719 37.0605L87.6019 24.9705L79.1619 22.3105L77.1019 35.8605C76.6119 36.2005 76.2119 36.6505 75.9119 37.1605C75.6119 37.6805 75.4419 38.2505 75.3919 38.8405C75.3419 39.4305 75.4219 40.0305 75.6319 40.5905C75.8419 41.1505 76.1619 41.6505 76.5919 42.0705L76.5819 42.0805Z"
            fill="#FFB8B8"
          />
          <Path
            d="M77.1517 32.3598L78.8017 8.51984L63.9817 -0.770164L54.2217 -16.8402L58.7017 -21.0702H58.7717C62.1817 -21.2302 65.5417 -20.2402 68.3217 -18.2602C71.2817 -16.1702 79.8517 -8.07017 86.5217 -1.64017C88.2417 0.00983429 89.5117 2.06984 90.2117 4.34984C90.9117 6.62984 91.0117 9.04984 90.5017 11.3798L85.2917 35.6998L77.1617 32.3498L77.1517 32.3598Z"
            fill="#4666FF"
          />
          <Path
            d="M88.9115 2.65039H67.0415C66.0971 2.65039 65.3315 3.41598 65.3315 4.36039V4.37039C65.3315 5.3148 66.0971 6.08039 67.0415 6.08039H88.9115C89.856 6.08039 90.6215 5.3148 90.6215 4.37039V4.36039C90.6215 3.41598 89.856 2.65039 88.9115 2.65039Z"
            fill="#CCCCCC"
          />
          <Path
            d="M88.9115 39.9297H67.0415C66.0971 39.9297 65.3315 40.6953 65.3315 41.6397V41.6497C65.3315 42.5941 66.0971 43.3597 67.0415 43.3597H88.9115C89.856 43.3597 90.6215 42.5941 90.6215 41.6497V41.6397C90.6215 40.6953 89.856 39.9297 88.9115 39.9297Z"
            fill="#CCCCCC"
          />
          <Path
            d="M77.7517 133.37C77.5417 133.37 77.3317 133.29 77.1717 133.14C77.0117 132.99 76.9117 132.79 76.9017 132.58L66.2817 4.43966C66.2817 4.21966 66.3517 4.00966 66.5017 3.83966C66.6517 3.67966 66.8517 3.56966 67.0717 3.54966C67.2917 3.52966 67.5117 3.59966 67.6817 3.72966C67.8517 3.86966 67.9617 4.06966 67.9917 4.27966L77.7717 122.68L87.8317 4.28966C87.8317 4.16966 87.8617 4.05966 87.9117 3.94966C87.9617 3.83966 88.0317 3.74966 88.1217 3.66966C88.2117 3.58966 88.3117 3.52966 88.4217 3.49966C88.5317 3.45966 88.6517 3.44966 88.7617 3.45966C88.8817 3.46966 88.9917 3.49966 89.0917 3.55966C89.1917 3.61966 89.2817 3.68966 89.3617 3.77966C89.4317 3.86966 89.4917 3.97966 89.5217 4.08966C89.5517 4.19966 89.5617 4.31966 89.5417 4.43966L78.6117 132.58C78.5917 132.79 78.4917 132.99 78.3317 133.14C78.1717 133.28 77.9717 133.36 77.7517 133.37Z"
            fill="#CCCCCC"
          />
          <Path
            d="M80.1719 107.6L88.0619 105.08L92.0919 126.52L86.7419 128.23L80.1719 107.6Z"
            fill="#FFB8B8"
          />
          <Path
            d="M84.8418 126.07L95.6018 122.64C97.3118 122.1 99.1718 122.25 100.772 123.08C102.372 123.9 103.572 125.33 104.122 127.04L104.262 127.49L87.0418 132.98L84.8418 126.08V126.07Z"
            fill="#2F2E41"
          />
          <Path
            d="M47.8315 106.47H56.1115L53.4415 128.12H47.8315V106.47Z"
            fill="#FFB8B8"
          />
          <Path
            d="M64.0515 133.08C64.0515 131.4 63.3815 129.78 62.1915 128.59C61.0015 127.4 59.3915 126.73 57.7015 126.73H46.8315V133.12H64.0415V133.08H64.0515Z"
            fill="#2F2E41"
          />
          <Path
            d="M48.0017 121.08C47.5217 121.15 47.0417 121.04 46.6417 120.77C46.2417 120.5 45.9517 120.09 45.8417 119.62C42.2617 104.67 36.3417 38.46 40.4617 35.2C41.4817 34.39 38.8317 34.44 40.0017 33.89C41.7317 33.06 43.3717 32.28 44.4717 30.89C44.7517 30.54 45.1517 30.29 45.5917 30.2C50.0517 29.31 54.6217 28.35 59.0417 27.43C63.1417 26.58 65.6617 25.69 69.8117 24.85C70.0617 24.8 70.3217 24.8 70.5717 24.85C70.8217 24.9 71.0617 25 71.2717 25.14C71.4817 25.28 71.6617 25.46 71.8017 25.68C71.9417 25.89 72.0417 26.13 72.0917 26.38C73.5017 33.69 91.9117 110.64 92.9217 115.38C93.0217 115.87 92.9317 116.37 92.6717 116.79C92.4117 117.21 91.9917 117.52 91.5117 117.64C85.9517 119.08 86.2117 119.49 82.3017 120.47C81.8317 120.59 81.3417 120.52 80.9117 120.29C80.4917 120.06 80.1617 119.68 80.0017 119.22C79.1817 116.81 77.8717 112.8 76.2217 107.71C71.4717 93.09 64.3017 70.99 60.0317 61.12C59.8917 60.81 59.6617 60.56 59.3617 60.4C59.0617 60.24 58.7217 60.19 58.3917 60.25C58.0617 60.31 57.7617 60.48 57.5317 60.73C57.3117 60.98 57.1717 61.3 57.1517 61.63C56.6517 70.16 56.9117 86.04 57.1217 98.8C57.4017 116.18 57.3717 119.28 56.9017 119.48C55.9917 119.88 55.6417 119.93 49.9017 120.79L48.0117 121.08H48.0017Z"
            fill="#2F2E41"
          />
          <Path
            d="M76.2717 37.5901L75.8317 37.5301C75.5517 37.4901 47.3517 33.8401 40.0517 35.9301L39.7817 36.0101V35.7201C39.7817 35.5001 39.7317 13.4001 35.0317 5.06007C30.2717 -3.39993 32.5917 -15.9099 32.6217 -16.0399V-16.1099L32.6817 -16.1599C36.9817 -19.8399 42.2517 -22.2199 47.8517 -23.0099C53.4617 -23.7999 59.1717 -22.9599 64.3217 -20.6099C64.7517 -20.4199 65.1817 -20.2199 65.6117 -20.0199C66.0317 -19.8199 66.3817 -19.5199 66.6517 -19.1399C66.9217 -18.7599 67.0817 -18.3299 67.1417 -17.8699C67.8117 -12.2199 69.1617 -1.15993 70.7017 9.73006C72.9717 25.6701 74.8117 35.1701 76.0317 37.2001L76.2617 37.5801L76.2717 37.5901Z"
            fill="#4666FF"
          />
          <Path
            d="M55.4819 8.21973H33.6119C32.6674 8.21973 31.9019 8.98532 31.9019 9.92973V9.93973C31.9019 10.8841 32.6674 11.6497 33.6119 11.6497H55.4819C56.4263 11.6497 57.1919 10.8841 57.1919 9.93973V9.92973C57.1919 8.98532 56.4263 8.21973 55.4819 8.21973Z"
            fill="#CCCCCC"
          />
          <Path
            d="M55.4819 45.5098H33.6119C32.6674 45.5098 31.9019 46.2754 31.9019 47.2198V47.2298C31.9019 48.1742 32.6674 48.9398 33.6119 48.9398H55.4819C56.4263 48.9398 57.1919 48.1742 57.1919 47.2298V47.2198C57.1919 46.2754 56.4263 45.5098 55.4819 45.5098Z"
            fill="#CCCCCC"
          />
          <Path
            d="M44.3215 133.8C44.1115 133.8 43.9015 133.72 43.7415 133.57C43.5815 133.42 43.4815 133.22 43.4715 133.01L32.8515 10.0097C32.8315 9.88974 32.8515 9.77974 32.8715 9.66974C32.9015 9.55974 32.9515 9.44974 33.0315 9.35974C33.1015 9.26974 33.1915 9.18974 33.3015 9.13974C33.4015 9.07974 33.5215 9.04974 33.6315 9.03974C33.7515 9.02974 33.8615 9.03974 33.9715 9.07974C34.0815 9.11974 34.1815 9.17974 34.2715 9.24974C34.3615 9.32974 34.4315 9.41974 34.4815 9.52974C34.5315 9.63974 34.5615 9.74974 34.5615 9.86974L44.3415 123.12L54.4015 9.85974C54.4315 9.63974 54.5515 9.44974 54.7215 9.30974C54.8915 9.16974 55.1115 9.10974 55.3315 9.12974C55.5515 9.14974 55.7515 9.24974 55.9015 9.41974C56.0515 9.57974 56.1215 9.79974 56.1115 10.0197L45.1815 133.02C45.1615 133.23 45.0615 133.43 44.9115 133.58C44.7515 133.72 44.5515 133.81 44.3315 133.81L44.3215 133.8Z"
            fill="#CCCCCC"
          />
          <Path
            d="M51.3818 46.0498C51.1718 47.0298 50.6118 47.8998 49.8018 48.4898C48.9918 49.0798 48.0018 49.3598 47.0018 49.2698C46.0018 49.1798 45.0718 48.7198 44.3918 47.9898C43.7118 47.2598 43.3118 46.2998 43.2918 45.2998L32.8418 37.6598L38.4818 30.8398L47.5718 41.0998C48.1618 41.1198 48.7518 41.2798 49.2818 41.5498C49.8118 41.8198 50.2718 42.2098 50.6318 42.6798C50.9918 43.1498 51.2518 43.6998 51.3818 44.2798C51.5118 44.8598 51.5118 45.4598 51.3818 46.0398V46.0498Z"
            fill="#FFB8B8"
          />
          <Path
            d="M41.0817 46.1403L23.4917 28.5503C21.8017 26.8703 20.5717 24.7803 19.9117 22.4903C19.2617 20.2003 19.2017 17.7703 19.7517 15.4503C21.8617 6.43027 24.6517 -5.01973 26.0017 -8.38973C27.2617 -11.5597 29.5417 -14.2197 32.4817 -15.9397L32.5417 -15.9697L38.6017 -14.8497L39.1417 3.94027L31.7517 19.7803L46.0917 38.9003L41.0817 46.1403Z"
            fill="#4666FF"
          />
          <Path
            d="M106.202 128.48C106.302 127.7 106.242 126.91 106.032 126.16C105.812 125.41 105.442 124.71 104.942 124.1C102.262 120.88 96.2916 120.47 96.2316 120.47H96.0816L85.6716 80.9701C85.6216 80.7601 85.5216 80.5701 85.3816 80.4001C85.2416 80.2301 85.0716 80.1001 84.8816 80.0001C84.6616 79.8901 84.4116 79.8201 84.1716 79.8101C83.9216 79.8001 83.6716 79.8401 83.4416 79.9201L68.6916 85.5501C68.4916 85.6201 68.3116 85.7301 68.1516 85.8801C68.0016 86.0201 67.8716 86.2001 67.7916 86.3901C67.7216 86.5601 67.6816 86.7501 67.6816 86.9401C67.6816 87.1301 67.7216 87.3101 67.7916 87.4901L84.6416 132.3C84.8116 132.66 85.0916 132.94 85.4516 133.11C85.8116 133.28 86.2116 133.31 86.5916 133.21L106.212 128.49L106.202 128.48Z"
            fill="#CCCCCC"
          />
          <Path
            d="M92.0041 103.36L74.1143 105.256L74.2049 106.111L92.0947 104.215L92.0041 103.36Z"
            fill="#B3B3B3"
          />
          <Path
            d="M93.5554 110.414L79.1655 116.89L79.5185 117.674L93.9083 111.198L93.5554 110.414Z"
            fill="#B3B3B3"
          />
          <Path
            d="M71.8716 98.4696L88.8416 92.8496L89.1916 93.6296L72.2316 99.2496L71.8716 98.4696Z"
            fill="#B3B3B3"
          />
          <Path
            d="M70.1616 94.6099L87.1216 86.4199L87.4816 87.2099L70.5116 95.3999L70.1616 94.6099Z"
            fill="#B3B3B3"
          />
          <Path
            d="M95.0601 117.134L82.8252 127.584L83.3837 128.238L95.6187 117.788L95.0601 117.134Z"
            fill="#B3B3B3"
          />
          <Path
            d="M98.5178 120.586L93.606 131.414L94.3891 131.769L99.301 120.941L98.5178 120.586Z"
            fill="#B3B3B3"
          />
        </G>
      </G>
      <Defs>
        <ClipPath id="clip0_62319_45367">
          <Rect
            width="328"
            height="136"
            fill="white"
            transform="translate(0.251953)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
