import { FC, PropsWithChildren } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Chip } from "react-native-paper";
import { View, StyleSheet } from "react-native";
import { Condition } from "../exercise.types";
import { SelectedConditionsListProps } from "./SelectedConditionsList";
import { spacing8 } from "@styles/spacing";

type SelectedConditionsListPropsWithControllerProps = {
  onXIconPress?: (condition: Condition) => void;
} & SelectedConditionsListProps &
  Pick<ControllerProps, "control">;

const SelectedConditionsListWithController: FC<
  PropsWithChildren<SelectedConditionsListPropsWithControllerProps>
> = ({ control, selectedConditions, onXIconPress }) => {
  return (
    <View style={styles.container}>
      {selectedConditions?.map(c => (
        <Controller
          key={c.id}
          name={`condition.${c.id}`}
          control={control}
          defaultValue={c.name}
          render={() => (
            <Chip
              onPress={() => onXIconPress(c)}
              onClose={() => onXIconPress(c)}>
              {c.name}
            </Chip>
          )}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: spacing8,
  },
});

export default SelectedConditionsListWithController;
