import {
  StyleSheet,
  TextInput as RNTextInput,
  TouchableOpacity,
  View,
} from "react-native";
import {
  List,
  ListItemProps,
  TextInput,
  TextInputProps,
} from "react-native-paper";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { spacing16, spacing4, spacing8 } from "@styles/spacing";
import { FlashList, FlashListProps } from "@shopify/flash-list";
import { useThrottle } from "ahooks";
import { useTranslation } from "react-i18next";
import { bottomSheetBgColor, palettes } from "@styles/colors";

type Props = {
  dropdownInVisible: boolean;
  setDropdownInVisible: Dispatch<SetStateAction<boolean>>;
  inputProps?: TextInputProps;
  itemProps?: ListItemProps;
  listProps?: FlashListProps<unknown>;
  dropdownData: string[];
  label?: string;
};

export const SearchingDropdown: FC<Props> = ({
  dropdownInVisible,
  setDropdownInVisible,
  inputProps,
  itemProps,
  listProps,
  dropdownData,
  label,
}) => {
  const [data, setData] = useState<string[]>([]);
  const [itemHeight, setItemHeight] = useState<number>(null);
  const [searchQuery, setSearchQuery] = useState<string>(null);
  const [currentItem, setCurrentItem] = useState<string>(null);
  const [inputHeight, setInputHeight] = useState<number>(null);
  const textInputRef = useRef<RNTextInput>(null);

  const { t } = useTranslation();
  const throttledQuery = useThrottle(searchQuery, {
    leading: false,
  });

  useEffect(() => {
    setData([...dropdownData]);
  }, [dropdownData]);

  useEffect(() => {
    setData([...dropdownData]);
    setSearchQuery(null);
  }, [dropdownInVisible, dropdownData]);

  useEffect(() => {
    throttledQuery?.length > 3
      ? setData([...dropdownData].filter(e => e.includes(throttledQuery)))
      : setData([...dropdownData]);
  }, [dropdownData, throttledQuery]);

  const onChangeText = (text: string) => {
    setSearchQuery(text);
    setCurrentItem(text);
  };

  const onPressMenuItem = useCallback(
    (title: string) => {
      setSearchQuery(title);
      setCurrentItem(title);
      textInputRef?.current?.blur();
      setDropdownInVisible(false);
    },
    [setDropdownInVisible],
  );

  const onPressChevron = () =>
    setDropdownInVisible(prev => {
      if (!prev) {
        textInputRef?.current?.blur();
      }
      return !prev;
    });

  const renderMenuItem = useCallback(
    (title: string) => (
      <TouchableOpacity
        onPress={() => onPressMenuItem(title)}
        onLayout={({ nativeEvent }) =>
          setItemHeight(nativeEvent.layout.height)
        }>
        <List.Item title={title} style={{ width: "100%" }} {...itemProps} />
      </TouchableOpacity>
    ),
    [itemProps, onPressMenuItem],
  );

  const currentLength = data.length > 4 ? 4 : data.length;

  return (
    <View style={styles.mainContainer}>
      <TextInput
        ref={textInputRef}
        mode="outlined"
        label={label}
        value={currentItem}
        onChangeText={onChangeText}
        right={
          <TextInput.Icon
            icon={dropdownInVisible ? "chevron-down" : "chevron-up"}
            onPress={onPressChevron}
          />
        }
        onFocus={() => setDropdownInVisible(false)}
        onBlur={() => setDropdownInVisible(true)}
        selectTextOnFocus
        onLayout={({ nativeEvent }) =>
          setInputHeight(nativeEvent.layout.height)
        }
        {...inputProps}
      />
      {!dropdownInVisible && (
        <View
          style={[
            styles.menuContainer,
            {
              top: inputHeight + spacing8,
            },
            itemHeight
              ? { height: itemHeight * currentLength, width: "100%" }
              : { flex: 1 },
          ]}>
          <FlashList
            data={data}
            renderItem={({ item }: { item: string }) => renderMenuItem(item)}
            keyExtractor={(item, index) =>
              `searchingDropdown-item-${item}-${index}`
            }
            estimatedItemSize={spacing16 * 3}
            keyboardShouldPersistTaps="always"
            ListEmptyComponent={<List.Item title={t("T00617")} />}
            {...listProps}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: { flex: 1, zIndex: 999 },
  menuContainer: {
    borderRadius: spacing4,
    backgroundColor: bottomSheetBgColor,
    shadowColor: palettes.primary[0],
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    position: "absolute",
  },
});
