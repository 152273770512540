import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Chip, Text } from "react-native-paper";
import { View, StyleSheet } from "react-native";
import { Condition } from "../exercise.types";
import { spacing8 } from "@styles/spacing";

export type SelectedConditionsListProps = {
  selectedConditions: Condition[];
};

const SelectedConditionsList: FC<
  PropsWithChildren<SelectedConditionsListProps>
> = ({ selectedConditions }) => {
  const { t } = useTranslation();
  return (
    <>
      {selectedConditions?.length ? (
        <View style={styles.container}>
          {selectedConditions.map(({ id, name }) => (
            <Chip key={id}>{name}</Chip>
          ))}
        </View>
      ) : (
        <Text variant="bodyMedium">{t("T01348")}</Text>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: spacing8,
  },
});

export default SelectedConditionsList;
