import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { PrimaryButton } from "@components/buttons";
import PermissionModal from "@components/Modals/PermissionModal";
import { PermissionType } from "@globalTypes/common.types";
import { useMediaPermissions } from "@hooks/media/useMediaPermissions";
import {
  MediaType,
  PickFilesFromDevice,
} from "@hooks/media/useResources/types";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { FC, useState } from "react";
import { StyleSheet, View } from "react-native";

type AddAttachmentBottomSheetProps = {
  visible: boolean;
  setVisible: (val: boolean) => void;
  pickFilesFromDevice: PickFilesFromDevice;
};

const AddAttachmentBottomSheet: FC<AddAttachmentBottomSheetProps> = ({
  visible,
  setVisible,
  pickFilesFromDevice,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { checkPermissions, onPressConfirm } = useMediaPermissions({
    isModalVisible,
    setIsModalVisible,
  });

  const openPicker = async (type: MediaType) =>
    await checkPermissions(
      () => pickFilesFromDevice(type),
      type === MediaType.gallery,
    );

  return (
    <BottomModalContainer modalVisible={visible} setModalVisible={setVisible}>
      <>
        <View style={styles.container}>
          <PrimaryButton
            label="T01097"
            onPress={() => openPicker(MediaType.file)}
          />
          <PrimaryButton
            label="T01098"
            onPress={() => openPicker(MediaType.gallery)}
          />
        </View>
        <PermissionModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          onPressConfirm={onPressConfirm}
          type={PermissionType.galleryAndFiles}
        />
      </>
    </BottomModalContainer>
  );
};

export default AddAttachmentBottomSheet;

const styles = StyleSheet.create({
  container: {
    ...globalStyles.gapLarge,
    marginVertical: spacing16,
  },
});
