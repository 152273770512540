import { FC, PropsWithChildren, ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { useTranslation } from "react-i18next";
import { Image, ImageProps } from "expo-image";
import { isWeb } from "@utils/constants";
import { TransKey } from "@globalTypes/i18next";

type Content = {
  img?: ImageProps["source"];
  svgComponent?: () => JSX.Element;
  text?: TransKey | TransKey[];
  displayHorizontally?: boolean;
  transComponent?: () => ReactElement;
};

type Props = {
  title: TransKey;
  content?: Content[];
  onPress: () => void;
  subtitles?: TransKey[];
} & PropsWithChildren;

export const BottomTutorialComponent: FC<Props> = ({
  title,
  content,
  onPress,
  subtitles,
  children,
}) => {
  const { t } = useTranslation();
  const { gapLarge, gapMedium } = globalStyles;
  const { image, svg, horizontalItem, text90PercentLength } = styles;
  return (
    <View style={gapLarge}>
      <Text variant="bodyLarge">{t(title)}</Text>
      {!!content?.length &&
        content?.map(
          (
            { img, svgComponent, text, displayHorizontally, transComponent },
            i,
          ) => (
            <View
              key={`bottom-tutorial-component-${text}-${i}`}
              style={displayHorizontally && horizontalItem}>
              {img && (
                <Image
                  source={img}
                  alt={Array.isArray(text) ? text[0] : text}
                  style={image}
                />
              )}
              {svgComponent && (
                <View style={!displayHorizontally && svg}>
                  {svgComponent()}
                </View>
              )}
              {transComponent && (
                <Text variant="bodyMedium">{transComponent()}</Text>
              )}
              {text && (
                <View style={[gapMedium, isWeb && { flex: 1 }]}>
                  {Array.isArray(text) ? (
                    text.map(txt => (
                      <Text variant="bodyMedium" key={txt}>
                        {t(txt)}
                      </Text>
                    ))
                  ) : (
                    <Text
                      variant="bodyMedium"
                      style={
                        displayHorizontally && !isWeb && text90PercentLength
                      }>
                      {t(text)}
                    </Text>
                  )}
                </View>
              )}
            </View>
          ),
        )}
      {!!subtitles?.length && (
        <View style={gapMedium}>
          {subtitles?.map((s, i) => (
            <Text variant="bodyMedium" key={`paragraph-${s}-${i}`}>
              {t(s)}
            </Text>
          ))}
        </View>
      )}
      {children}
      <PrimaryButton label="T00394" onPress={onPress} />
    </View>
  );
};

const styles = StyleSheet.create({
  image: { width: "100%", marginBottom: spacing8 },
  svg: {
    marginBottom: spacing8,
    alignItems: "center",
  },
  horizontalItem: {
    flexDirection: "row",
    alignItems: "center",
    gap: spacing8,
  },
  text90PercentLength: { maxWidth: "90%" },
});
