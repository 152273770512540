import Bell from "@components/Bell";
import { useCheckNotificationsForBell } from "@hooks/notifications/useCheckNotificationsForBell";
import { useTrainingsNotificationsSettings } from "@hooks/notifications/useTrainingsNotificationsSettings";

const TrainingNotificationsBell = () => {
  const {
    trainingsNotificationsSwitchOn: specificPushNotificationsEnabled,
    todaysReminderTime,
  } = useTrainingsNotificationsSettings();

  const { enabled } = useCheckNotificationsForBell({
    specificPushNotificationsEnabled,
  });
  return <Bell active={enabled} text={todaysReminderTime} />;
};

export default TrainingNotificationsBell;
