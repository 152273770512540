import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { ShareRecordFormContent } from "@components/BottomSheetContents/ShareRecordFormContent";
import { Calendar, DateArrayType } from "@components/Calendar";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrors } from "@hooks/useErrors";
import { queryKeysMedicalRecord } from "@screens/Profiles/MyProfile/MedicalRecords/queryKeysMedicalRecord";
import {
  shareMedicalRecords,
  updateSharedMedicalRecord,
} from "@services/ApiService/medicalRecords";
import { globalStyles } from "@styles/global";
import { spacing24 } from "@styles/spacing";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getDate } from "@utils/date";
import { AxiosError } from "axios";
import { format, isFuture, parse } from "date-fns";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Control, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { boolean, object, string } from "yup";
import { useUserDetails } from "@hooks/index";

type ShareRecordFormProps = {
  asignee: number;
  medicalRecord: number;
  onRecordShareSuccess?: () => void;
  setCalendarModalVisible: Dispatch<SetStateAction<boolean>>;
  calendarModalVisible: boolean;
  setShareFormModalVisible: Dispatch<SetStateAction<boolean>>;
  shareFormModalVisible: boolean;
  sharedRecordId?: number;
};

const ShareRecordForm: FC<ShareRecordFormProps> = ({
  asignee,
  medicalRecord,
  onRecordShareSuccess,
  setCalendarModalVisible,
  calendarModalVisible,
  setShareFormModalVisible,
  shareFormModalVisible,
  sharedRecordId,
}) => {
  const {
    setErrorsFromResponse: setShareErrorsFromResponse,
    errors: errorsShare,
  } = useErrors();
  const {
    setErrorsFromResponse: setUpdateShareErrorsFromResponse,
    errors: errorsUpdateShare,
  } = useErrors();
  const { t } = useTranslation();
  const { isPatient } = useUserDetails();
  const queryClient = useQueryClient();
  const [selectedDay, setSelectedDay] = useState<DateArrayType>({
    date: new Date(),
  });

  const schema = useMemo(
    () =>
      object().shape({
        dueDate: string()
          .required(t("T00014"))
          .test("isFuture", t("T00017"), value =>
            isFuture(new Date(parse(value, "dd.MM.yyyy", new Date()))),
          ),
        processingCheckbox:
          isPatient &&
          boolean().oneOf([true], t("T00014")).required(t("T00014")),
      }),
    [t],
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const {
    createdByMeList,
    sharedBetweenMeAndOtherUserList,
    recordDetails,
    sharedToOtherUserDetails,
    sharedToMeList,
  } = queryKeysMedicalRecord;

  const onShareRecordsSuccess = useCallback(async () => {
    await Promise.all([
      queryClient.invalidateQueries(recordDetails(medicalRecord)),
      queryClient.invalidateQueries(createdByMeList()),
      queryClient.invalidateQueries(sharedBetweenMeAndOtherUserList(asignee)),
    ]);
    setShareFormModalVisible(prev => !prev);
    onRecordShareSuccess();
  }, [
    asignee,
    createdByMeList,
    medicalRecord,
    onRecordShareSuccess,
    queryClient,
    recordDetails,
    setShareFormModalVisible,
    sharedBetweenMeAndOtherUserList,
  ]);

  const onUpdateSharingSuccess = useCallback(async () => {
    await Promise.all([
      queryClient.invalidateQueries(recordDetails(medicalRecord)),
      queryClient.invalidateQueries(sharedToMeList()),
      queryClient.invalidateQueries(
        sharedBetweenMeAndOtherUserList(sharedRecordId),
      ),
      queryClient.invalidateQueries(sharedToOtherUserDetails(sharedRecordId)),
    ]);
    setShareFormModalVisible(prev => !prev);
    setValue("dueDate", "");
    setSelectedDay({
      date: new Date(),
    });
    onRecordShareSuccess && onRecordShareSuccess();
  }, [
    medicalRecord,
    onRecordShareSuccess,
    queryClient,
    recordDetails,
    setShareFormModalVisible,
    setValue,
    sharedBetweenMeAndOtherUserList,
    sharedRecordId,
    sharedToMeList,
    sharedToOtherUserDetails,
  ]);
  const { mutate, isLoading: isMutationLoading } = useMutation({
    mutationFn: shareMedicalRecords,
    onSuccess: onShareRecordsSuccess,
    onError: ({ response }: AxiosError) => setShareErrorsFromResponse(response),
  });

  const { mutate: updateSharedRecord, isLoading: isUpdateSharedRecordLoading } =
    useMutation({
      mutationFn: async (dueDate: string | null) =>
        await updateSharedMedicalRecord(sharedRecordId, dueDate),
      onSuccess: onUpdateSharingSuccess,
      onError: ({ response }: AxiosError) =>
        setUpdateShareErrorsFromResponse(response),
    });

  const onSubmit = ({ dueDate }: { dueDate: string }) => {
    const newDueDate = dueDate.split(".").reverse().join("-");
    sharedRecordId
      ? updateSharedRecord(newDueDate)
      : mutate({
          asignee,
          medicalRecords: [medicalRecord],
          dueDate: newDueDate,
        });
  };
  const onSelectDate = useCallback(
    async (data: DateArrayType, dontClose?: boolean) => {
      const date = format(data.date, "yyyy-MM-dd");
      if (!dontClose) {
        setCalendarModalVisible(false);
        setTimeout(() => setShareFormModalVisible(true), 750);
      }
      setValue("dueDate", getDate(date));
      await trigger("dueDate");
      setSelectedDay(data);
    },
    [setCalendarModalVisible, setValue, trigger, setShareFormModalVisible],
  );

  const onInputPress = () => {
    setShareFormModalVisible(false);
    setTimeout(() => setCalendarModalVisible(true), 750);
  };

  return (
    <>
      <BottomModalContainer
        modalVisible={shareFormModalVisible}
        setModalVisible={setShareFormModalVisible}>
        <ShareRecordFormContent
          asignee={asignee}
          control={control as unknown as Control}
          errors={errorsShare?.dueDate || errorsUpdateShare?.dueDate}
          isValid={isValid}
          loading={isMutationLoading || isUpdateSharedRecordLoading}
          onInputPress={onInputPress}
          onHandleSubmitPress={handleSubmit(onSubmit)}
        />
      </BottomModalContainer>
      <BottomModalContainer
        modalVisible={calendarModalVisible}
        setModalVisible={setCalendarModalVisible}>
        <View style={[globalStyles.gapLarge, { paddingVertical: spacing24 }]}>
          <Calendar
            initialDates={[selectedDay]}
            onSelectDate={onSelectDate}
            blockPast
            yearsOption="future"
          />
        </View>
      </BottomModalContainer>
    </>
  );
};

export default ShareRecordForm;
