import { FC } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { useTranslation } from "react-i18next";

import { PhysiotherapistCardWithRating, UserCard } from "@components/cards";

import { globalStyles } from "@styles/global";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { useAssistant } from "@hooks/user/useAssistant";

type Props = {
  interlocutorId: number;
  onPressPatient: () => void;
  onPressPhysiotherapist: () => void;
};

export const ConversationHeader: FC<Props> = ({
  interlocutorId,
  onPressPatient,
  onPressPhysiotherapist,
}) => {
  const { gapMedium } = globalStyles;
  const { t } = useTranslation();
  const { isPhysiotherapist } = useUserDetails();
  const { isAssistantId } = useAssistant(interlocutorId);

  return (
    <>
      {isPhysiotherapist ? (
        <View style={gapMedium}>
          {!isAssistantId && <Text variant="titleMedium">{t("T00275")}:</Text>}
          <UserCard
            initialData={{ id: interlocutorId }}
            onPress={onPressPatient}
            mode="outlined"
          />
        </View>
      ) : (
        <View style={gapMedium}>
          {!isAssistantId && <Text variant="titleMedium">{t("T00060")}:</Text>}
          <PhysiotherapistCardWithRating
            initialData={{ id: interlocutorId }}
            onPress={onPressPhysiotherapist}
            flat={false}
            mode="outlined"
          />
        </View>
      )}
    </>
  );
};
