import { spacing16 } from "@styles/spacing";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";

const THUMB_WIDTH = 984;
const THUMB_HEIGHT = 708;

const getThumbHeight = (width: number) => (THUMB_WIDTH / THUMB_HEIGHT) * width;

export const useMediaStyle = () => {
  const { width } = useWindowDimensions();

  const mediaWidth = width - spacing16 * 2;
  return {
    width: mediaWidth,
    height: mediaWidth / 2,
    thumbnailHeight: getThumbHeight(width),
  };
};
