import { SvgAssignWorkoutToPatient } from "@assets/svg/tutorials";
import { BottomTutorialComponent } from "@components/BottomSheet";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { Dispatch, FC, SetStateAction } from "react";

type Props = {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
};

const BottomSheetCommitmentInfo: FC<Props> = ({
  modalVisible,
  setModalVisible,
}) => {
  return (
    <BottomModalContainer
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}>
      <BottomTutorialComponent
        title="T00825"
        content={[
          {
            svgComponent: () => <SvgAssignWorkoutToPatient />,
          },
        ]}
        subtitles={["T00826", "T00827", "T00828"]}
        onPress={() => setModalVisible(false)}
      />
    </BottomModalContainer>
  );
};

export default BottomSheetCommitmentInfo;
