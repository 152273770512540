import { AbsoluteBlurredFooter } from "@components/Footers";
import HumanModelWithController from "@components/HumanModel/HumanModelWithController";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHumanModelSchemas } from "@hooks/humanModel/useHumanModelSchemas";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { TrainingSelectionAssistantStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { FC, PropsWithChildren, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";
import { Text } from "react-native-paper";
import { TrainingSelectionAssistantContext } from "./trainingSelectionAssistant.context";
import { HumanModelType } from "@globalTypes/common.types";

export const TrainingSelectionModel: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      TrainingSelectionAssistantStackParamsList,
      "TrainingSelectionModel"
    >
  >
> = ({ navigation: { navigate } }) => {
  const [footerHeight, setFooterHeight] = useState(0);
  const { gender } = useUserDetails();

  const { t } = useTranslation();
  const { updateData } = useContext(TrainingSelectionAssistantContext);

  const { humanModelSchema } = useHumanModelSchemas();
  const {
    control,
    handleSubmit,
    formState: { isValid },
    getValues,
  } = useForm({
    resolver: yupResolver(humanModelSchema),
    defaultValues: { gender },
  });

  const onSubmit = (data: HumanModelType) => {
    updateData({ bodyParts: data.humanGeneral });
    navigate("DailyTimeForTraining");
  };

  const { scrollContainer, gapSmall } = globalStyles;

  return (
    <>
      <ScrollView
        contentContainerStyle={[
          scrollContainer,
          gapSmall,
          { paddingBottom: footerHeight },
        ]}>
        <Text variant="titleMedium">{t("T01011")}</Text>
        <Text variant="bodyMedium">{t("T01010")}</Text>
        <HumanModelWithController
          gender={gender}
          humanBones={[]}
          humanMuscles={[]}
          humanGeneral={getValues("humanGeneral")}
          control={control}
          errors={null}
          disableModelChange
        />
      </ScrollView>
      <AbsoluteBlurredFooter
        intensity={1}
        onPress={handleSubmit(onSubmit)}
        title="T00472"
        buttonDisabled={!isValid}
        onLayout={height => setFooterHeight(height)}
        buttonStyle={{ backgroundColor: palettes.primary[100] }}
      />
    </>
  );
};
