import { FC } from "react";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { useQuery } from "@tanstack/react-query";
import { queryKeysSessionTraining } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { getSessionTrainings } from "@api/trainings";
import { FetchError } from "@components/errors";
import TrainingSessionTile from "@components/Tile/training/TrainingSessionTile";
import { globalStyles } from "@styles/global";
import { useTranslation } from "react-i18next";

type TrainingSessionsListProps = {
  trainingId: number;
  onSessionTilePress: (id: number) => void;
};

const TrainingSessionsList: FC<TrainingSessionsListProps> = ({
  trainingId,
  onSessionTilePress,
}) => {
  const { t } = useTranslation();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysSessionTraining.list(trainingId),
    queryFn: async () => await getSessionTrainings(trainingId),
    select: data => data.sort((a, b) => b.id - a.id),
  });
  if (isLoading) return <ActivityIndicator style={globalStyles.loading} />;
  if (isError) return <FetchError action={refetch} />;

  if (data.length === 0) return <Text variant="bodyMedium">{t("T00193")}</Text>;

  return (
    <View style={globalStyles.gapMedium}>
      {data.map(({ id }, index) => (
        <TrainingSessionTile
          key={id}
          sessionId={id}
          sessionOrder={data.length - index}
          onPress={() => onSessionTilePress(id)}
        />
      ))}
    </View>
  );
};

export default TrainingSessionsList;
