import { DETAILS, LIST } from "../../utils/constants";

const keys = {
  conversations: "conversations",
  unreadMessages: "unreadMessages",
  blacklist: "blacklist",
};

export const queryKeysConversations = {
  all: [keys.conversations] as const,
  list: () => [...queryKeysConversations.all, LIST] as const,
  details: (id: number) =>
    [...queryKeysConversations.all, DETAILS, id] as const,
};

export const queryKeysUnreadMessages = {
  all: [keys.unreadMessages] as const,
  list: () => [...queryKeysUnreadMessages.all, LIST] as const,
  details: (interlocutorId: number) =>
    [...queryKeysUnreadMessages.all, DETAILS, interlocutorId] as const,
};

export const queryKeysMessagesBlacklist = {
  all: [keys.blacklist] as const,
  details: (patientId: number) =>
    [...queryKeysMessagesBlacklist.all, DETAILS, patientId] as const,
};
