import { api } from "./api";
import {
  ConversationsListResponse,
  ConversationsMessagesResponse,
} from "@screens/Appointments/appointment.types";
import { endpoints } from "./endpoints";

export const getAllUnreadMessages = async (params?: { user?: number }) => {
  const { data } = await api.get<{ unreadMessageCount: number }>(
    endpoints.ALL_UNREAD_MESSAGES,
    {
      params,
    },
  );
  return data;
};

export const getConversationMessages = async (id: number) => {
  const { data } = await api.get<ConversationsMessagesResponse>(
    endpoints.CONVERSATION(id),
  );
  return data;
};

export const markMessagesAsRead = async (id: number) =>
  await api.patch(endpoints.CONVERSATION_MARK_AS_READ(id));

export const sendConversationMessage = (id: number, message: string) =>
  api.post<ConversationsMessagesResponse>(endpoints.CONVERSATION(id), {
    message,
  });

export const getConversations = async () => {
  const { data } = await api.get<ConversationsListResponse[]>(
    endpoints.CONVERSATIONS,
  );
  return data;
};
