import { PrimaryButton } from "@components/buttons";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { Dispatch, SetStateAction } from "react";

type Props = {
  onCancel: () => void;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
};

export const CancelAppointmentBottomSheetContent = ({
  onCancel,
  setModalVisible,
}: Props) => {
  const { t } = useTranslation();

  const { gapLarge, gapMedium } = globalStyles;

  const onCancelAppointment = () => {
    setModalVisible(false);
    setTimeout(() => {
      onCancel();
    }, 500);
  };

  return (
    <View style={gapLarge}>
      <View style={gapMedium}>
        <Text variant="bodyLarge">{t("T01158")}</Text>
        <Text variant="bodyMedium">{t("T01159")}</Text>
        <Text variant="bodyMedium">{t("T01160")}</Text>
      </View>
      <PrimaryButton
        mode="outlined"
        label="T01176"
        onPress={() => setModalVisible(false)}
      />
      <PrimaryButton label="T01150" onPress={onCancelAppointment} />
    </View>
  );
};
