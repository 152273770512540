import { roundTimeToMatchSlot } from "./date";

export const roundUpToNearest15Minutes = (dateStr: string): string => {
  // Extract the time components and the timezone offset from the date string
  const [datePart, timePart] = dateStr.split("T");

  if (!timePart || !datePart) return dateStr;

  const [time, timezoneOffset] = timePart.split(/([+-]\d{2}:\d{2})$/);
  let [hours, minutes] = time.split(":").map(Number);

  // If time is after 23:45, do not round up
  if (hours === 23 && minutes > 45) {
    return dateStr; // Return the original date string if it's after 23:45
  }

  // Round using the provided function
  const { roundedHours, roundedMinutes } = roundTimeToMatchSlot(hours, minutes);

  // Adjust the hours and minutes
  hours = roundedHours;
  minutes = roundedMinutes;

  // Format the rounded time back to the original format
  const pad = (n: number) => n.toString().padStart(2, "0");
  const roundedTime = `${pad(hours)}:${pad(minutes)}:00`;

  return `${datePart}T${roundedTime}${timezoneOffset}`;
};
