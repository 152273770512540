import { SvgRightArrowFilled } from "@assets/svg";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { FetchError } from "@components/errors";
import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { getServicesForSpecialization } from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, StyleSheet, View } from "react-native";
import { ActivityIndicator, Divider, List, Text } from "react-native-paper";
import { queryKeysServices } from "@screens/Common/queryKeysServices";
import AddServicesBottomSheetContent, {
  AddingServiceData,
} from "@components/BottomSheetContents/AddServiceBottomSheetContent";
import { AddServicePricesBottomSheetContent } from "@components/BottomSheetContents";
import { getCurrencyAmount } from "@services/ApiService/common";
import { queryKeysVerficiations } from "../queryKeysPhysiotherapistVerification";
import { useErrors } from "@hooks/useErrors";
import { SafeAreaView } from "react-native-safe-area-context";
import { HeaderWithSearchbar } from "@components/HeaderWithSearchbar";
import { useAuth } from "@contexts/AuthContext/auth";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { useThrottle } from "ahooks";
import { ServiceItem } from "../verification.types";
import AddFreeServiceBottomSheetContent from "@components/BottomSheetContents/AddFreeServiceBottomSheetContent";
import { useFlags } from "@hooks/useFlags";

const AddServices: FC<
  PropsWithChildren<
    NativeStackScreenProps<ProfileCompletionStackParamList, "AddServices">
  >
> = ({ navigation: { goBack }, route: { params } }) => {
  const { t } = useTranslation();
  const [addServicesModalVisible, setAddServicesModalVisible] = useState(false);
  const [addPricesModalVisible, setAddPricesModalVisible] = useState(false);
  const [title, setTitle] = useState<string>();
  const [searchQuery, setSearchQuery] = useState("");
  const [savedData, setSavedData] = useState<AddingServiceData>(
    {} as AddingServiceData,
  );
  const [pickedServiceItem, setPickedServiceItem] = useState<number>(null);
  const [isFreeServiceFlag, setIsFreeServiceFlag] = useState(false);
  const [services, setServices] = useState<ServiceItem[]>([]);

  const {
    flags: { FREE_SERVICE_ENABLED },
  } = useFlags();

  const {
    user: { currency },
  } = useAuth();
  const { errors } = useErrors();
  const { width } = useWindowDimensions();
  const throttledQuery = useThrottle(searchQuery, {
    leading: false,
  });

  const {
    data,
    isLoading: isLoadingOptions,
    isError: isErrorOptions,
    refetch: refetchOptions,
    isSuccess,
  } = useQuery({
    queryKey: queryKeysServices.list(params),
    queryFn: async () => await getServicesForSpecialization(params),
  });

  useEffect(() => {
    isSuccess && setServices(data);
  }, [isSuccess, data]);

  useEffect(() => {
    setServices(() =>
      data?.filter(({ name }) =>
        name.toLowerCase().includes(throttledQuery.toLowerCase()),
      ),
    );
  }, [data, throttledQuery]);

  const {
    data: currencyAmount,
    isLoading: currencyAmountLoading,
    isError: currencyAmountError,
    refetch: refetchCurrencyAmount,
  } = useQuery({
    queryFn: getCurrencyAmount,
    queryKey: queryKeysVerficiations.currencyOptions(),
  });

  const openModal = useCallback(
    (name: string, serviceItemId: number, freeService?: boolean) => {
      setTitle(name);
      setPickedServiceItem(serviceItemId);
      setIsFreeServiceFlag(!!freeService);
      setAddServicesModalVisible(true);
    },
    [],
  );

  const renderItem = useCallback(
    ({
      item: { name, id, freeService },
      index,
    }: {
      item: ServiceItem;
      index: number;
    }) => {
      const isLastItem = index === services.length - 1;
      if (freeService && !FREE_SERVICE_ENABLED) return;
      return (
        <View>
          <List.Item
            title={
              <Text variant="bodyLarge" style={styles.title}>
                {name}
              </Text>
            }
            right={() => (
              <View style={styles.svg}>
                <SvgRightArrowFilled />
              </View>
            )}
            onPress={() => openModal(name, id, freeService)}
            titleNumberOfLines={0}
          />
          {!isLastItem && (
            <Divider bold style={[globalStyles.flatListSeparator, { width }]} />
          )}
        </View>
      );
    },
    [FREE_SERVICE_ENABLED, openModal, services?.length, width],
  );

  const onClose = (data: AddingServiceData) => {
    setSavedData(data);
    setAddServicesModalVisible(false);
    setTimeout(() => setAddPricesModalVisible(true), 500);
  };

  const onChangeText = (text: string) => setSearchQuery(text);

  const refetchAll = async () =>
    await Promise.all([refetchOptions(), refetchCurrencyAmount()]);

  if (isLoadingOptions || currencyAmountLoading)
    return <ActivityIndicator style={globalStyles.loading} />;

  if (isErrorOptions || currencyAmountError)
    return <FetchError action={refetchAll} />;

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["left", "top", "right"]}>
      <HeaderWithSearchbar
        goBack={goBack}
        onChangeText={onChangeText}
        searchQuery={searchQuery}
        enableSearching
        onClear={() => setServices(() => data)}
      />
      <FlatList
        data={services}
        renderItem={renderItem}
        ListEmptyComponent={() => (
          <Text style={{ margin: spacing16 }}>{t("T00247")}</Text>
        )}
        keyExtractor={item => `service-item-${item.id}-${item?.name}`}
      />

      <BottomModalContainer
        modalVisible={addServicesModalVisible}
        setModalVisible={setAddServicesModalVisible}>
        {isFreeServiceFlag ? (
          <AddFreeServiceBottomSheetContent
            serviceName={title}
            serviceItem={pickedServiceItem}
            errors={errors}
            hideBottomSheet={() => {
              setAddServicesModalVisible(false);
              goBack();
            }}
          />
        ) : (
          <AddServicesBottomSheetContent
            onClose={onClose}
            serviceName={title}
            errors={errors}
            currencyAmount={
              currencyAmount.choices.filter(e => e.currency === currency)[0]
            }
          />
        )}
      </BottomModalContainer>
      <BottomModalContainer
        modalVisible={addPricesModalVisible}
        setModalVisible={setAddPricesModalVisible}>
        <AddServicePricesBottomSheetContent
          serviceName={title}
          data={savedData}
          disableModal={() => {
            setAddPricesModalVisible(false);
            goBack();
          }}
          currencyAmount={currencyAmount.choices.filter(
            e => e.currency !== currency,
          )}
          serviceItem={pickedServiceItem}
        />
      </BottomModalContainer>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  title: {
    marginRight: spacing16,
  },
  svg: { justifyContent: "center" },
});

export default AddServices;
