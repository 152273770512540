import { useEffect, useState } from "react";
import { config } from "@utils/config";
import { useAuth } from "@contexts/AuthContext/auth";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OneSignalDeferred: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OneSignal: any;
  }
}

const useOneSignal = () => {
  const {
    user: { id: userId },
  } = useAuth();
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!userId || init) {
      return;
    }

    const script = document.createElement("script");
    script.src = "https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Initialize OneSignal
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const OneSignalDeferred = window.OneSignalDeferred || [];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      OneSignalDeferred.push(function (OneSignal) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        OneSignal.Debug.setLogLevel(__DEV__ ? 6 : 2);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        OneSignal.init({
          appId: config.EXPO_PUBLIC_ONESIGNAL_APP_ID,
          safari_web_id: config.EXPO_PUBLIC_OPEN_SIGNAL_SAFARI_WEB_ID,
          notifyButton: {
            enable: false,
          },
          allowLocalhostAsSecureOrigin: true,
          serviceWorkerPath: "OneSignalSDKWorker.js",
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        OneSignal.Notifications.requestPermission();
      });

      const to = setTimeout(() => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          window.OneSignal.login(userId.toString());
          console.log("OneSignal.login", userId.toString());
          setInit(true);
        } catch (error) {
          console.error("OneSignal.login error:", error);
        }
      }, 1000);

      return () => clearTimeout(to);
    };
  }, [init, userId]);

  return null;
};

export default useOneSignal;
