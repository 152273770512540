import { OPTIONS, STATUS } from "@utils/constants";

const keys = {
  subscription: "subscription",
  apple: "apple",
  google: "google",
};

export const queryKeysSubscription = {
  all: [keys.subscription] as const,
  apple: () => [...queryKeysSubscription.all, keys.apple] as const,
  google: () => [...queryKeysSubscription.all, keys.google] as const,
  appleStatus: () => [...queryKeysSubscription.apple(), STATUS] as const,
  googleStatus: () => [...queryKeysSubscription.google(), STATUS] as const,
  appleOptions: () => [...queryKeysSubscription.apple(), OPTIONS] as const,
  googleOptions: () => [...queryKeysSubscription.google(), OPTIONS] as const,
};
