import { FetchError } from "@components/errors";

import {
  useDataForVerificationActionTileProfile,
  useSubscriptionStatus,
  useUserDetails,
} from "@hooks/index";
import {
  useVerification,
  VerificationInfoTileType,
} from "@hooks/verification/useVerification";
import { globalStyles } from "@styles/global";
import { FC, useMemo } from "react";
import { ActivityIndicator } from "react-native-paper";
import ActionTile, { ActionTileProps } from "./ActionTile";
import InfoTile from "./InfoTile";
import { useVerificationStatus } from "@hooks/verification/useVerificationStatus";
import { useFutureAvailability } from "@hooks/availability/useFutureAvailability";
import { getCurrentDatePlus30min } from "@utils/date";

type VerificationActionTileProps = {
  showCompletedTile?: boolean;
  cb?: () => void;
};

const VerificationActionTile: FC<VerificationActionTileProps> = ({
  cb,
  showCompletedTile,
}) => {
  const {
    isAnyLoading: isActionTileTypeProfileVisibleLoading,
    isAnyError: isActionTileTypeProfileVisibleError,
    refetchUserData: refetchActionTileTypeProfileVisible,
    isLicenceLoading,
    isLicenceError,
    refetchLicence,
  } = useDataForVerificationActionTileProfile();

  const {
    isAnySubscriptionStatusLoading,
    isAnySubscriptionStatusError,
    refetchAllSubscriptionStatusData,
  } = useSubscriptionStatus();
  const { id } = useUserDetails();
  const {
    isLoading: isAnyFutureLoading,
    isError: isAnyFutureError,
    refetch: refetchAllFutureData,
  } = useFutureAvailability({
    dateFrom: getCurrentDatePlus30min(),
    physiotherapistId: id,
  });

  const verificationType = useVerificationStatus();

  const result = useVerification({ verificationType, cb });

  const refetchAllData = () =>
    Promise.all([
      refetchLicence(),
      refetchActionTileTypeProfileVisible(),
      refetchAllSubscriptionStatusData(),
      refetchAllFutureData(),
    ]);

  const isAnyLoading = useMemo(
    () =>
      isLicenceLoading ||
      isActionTileTypeProfileVisibleLoading ||
      isAnySubscriptionStatusLoading ||
      isAnyFutureLoading,
    [
      isActionTileTypeProfileVisibleLoading,
      isAnyFutureLoading,
      isAnySubscriptionStatusLoading,
      isLicenceLoading,
    ],
  );

  const isAnyError = useMemo(
    () =>
      isLicenceError ||
      isActionTileTypeProfileVisibleError ||
      isAnySubscriptionStatusError ||
      isAnyFutureError,
    [
      isLicenceError,
      isActionTileTypeProfileVisibleError,
      isAnySubscriptionStatusError,
      isAnyFutureError,
    ],
  );

  const shouldDisplayInfoTile =
    verificationType &&
    Object.values(VerificationInfoTileType).includes(
      verificationType as VerificationInfoTileType,
    );

  const verificationCompleted =
    verificationType === VerificationInfoTileType.COMPLETED;

  const shouldDisplayCompletedTile = showCompletedTile && verificationCompleted;

  return shouldDisplayInfoTile ? (
    isAnyLoading ? (
      <InfoTile content={<ActivityIndicator style={globalStyles.loading} />} />
    ) : isAnyError ? (
      <InfoTile
        content={<FetchError action={refetchAllData} coverScreen={false} />}
      />
    ) : shouldDisplayCompletedTile ? (
      <InfoTile {...result} />
    ) : (
      !verificationCompleted && <InfoTile {...result} />
    )
  ) : isAnyLoading ? (
    <ActivityIndicator style={globalStyles.loading} />
  ) : isAnyError ? (
    <FetchError action={refetchAllData} coverScreen={false} />
  ) : (
    <ActionTile {...(result as ActionTileProps)} />
  );
};

export default VerificationActionTile;
