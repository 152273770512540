import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import { queryKeysSpecialTrainings } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { getSpecialTrainingCommitmentsForSpecificPatient } from "@services/ApiService/trainings";
import { useQuery } from "@tanstack/react-query";
import { differenceInHours } from "date-fns";
import { useTranslation } from "react-i18next";
import { getPatientBasicData } from "@services/ApiService/users";
import { Card } from "../index";
import { UserCardProps } from "./UserCard.types";
import { getSubtitles } from "./userCardHelpers";
import { GestureResponderEvent } from "react-native";
import { inactiveUserAlert } from "@utils/showAlert";
import { useAssistant } from "@hooks/user/useAssistant";
import AssistantCard from "@components/cards/AssistantCard";
import { useMemo } from "react";

const UserCard = ({
  initialData: { id },
  onPress,
  redirectsToProfile = true,
  disableHeightAndWeight,
  ...props
}: UserCardProps) => {
  const { isAssistantId } = useAssistant(id);
  const { data, isFetched, isError, refetch } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.basicData(id),
    queryFn: async () => await getPatientBasicData(id),
    enabled: !isAssistantId,
  });

  const {
    data: commitmentData,
    refetch: refetchCommitment,
    isFetched: isCommitmentFetched,
    isError: isCommitmentError,
  } = useQuery({
    queryKey: queryKeysSpecialTrainings.commitmentsListForSpecificPatient(id),
    queryFn: async () =>
      await getSpecialTrainingCommitmentsForSpecificPatient(id),
    enabled: !isAssistantId,
  });

  const { t } = useTranslation();

  const refetchQueries = async () =>
    await Promise.all([refetch(), refetchCommitment()]);

  const recentCommitmentDate =
    commitmentData?.length > 0 && commitmentData[0].createdAt;

  const commitmentInfo = t("T00379", {
    value: differenceInHours(new Date(), new Date(recentCommitmentDate)),
  });

  const subtitles = data && getSubtitles(data, disableHeightAndWeight);
  recentCommitmentDate && subtitles && subtitles.push(commitmentInfo);

  const handleOnPress = (e: GestureResponderEvent) => {
    if (!data.isActive && redirectsToProfile) return inactiveUserAlert();
    return onPress(e);
  };

  const isAllFetched = useMemo(
    () => isFetched && isCommitmentFetched,
    [isFetched, isCommitmentFetched],
  );

  const isAnyError = useMemo(
    () => isError || isCommitmentError,
    [isError, isCommitmentError],
  );

  if (isAssistantId) return <AssistantCard onPress={onPress} />;

  return (
    <Card
      initialData={{
        id,
        firstName: data?.firstName,
        lastName: data?.lastName,
        avatar: data?.avatar,
      }}
      subtitles={subtitles}
      isFetched={isAllFetched}
      isError={isAnyError}
      refetch={refetchQueries}
      onPress={!isAnyError && onPress && handleOnPress}
      {...props}
    />
  );
};

export default UserCard;
