import { GenderType } from "@contexts/AuthContext/user.types";
import { FC } from "react";

import MaleBoneFront from "./MaleBoneFront";
import FemaleBoneFront from "./FemaleBoneFront";
import { ModelProps } from "../humanModel.types";
import { useHumanModel } from "@hooks/humanModel/useHumanModel";

const BoneFront: FC<ModelProps> = ({
  defaultSelected = [],
  onChange,
  readOnly = false,
  gender,
}) => {
  const { modelProps } = useHumanModel({
    onChange,
    readOnly,
    defaultSelected,
  });
  return gender === GenderType.MALE ? (
    <MaleBoneFront {...modelProps} />
  ) : (
    <FemaleBoneFront {...modelProps} />
  );
};
export default BoneFront;
