import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { spacing32 } from "@styles/spacing";
import { t } from "i18next";
import { Dispatch, FC, SetStateAction } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";

type AbortSchedulingAppointmentBottomSheetProps = {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  pop: (count?: number) => void;
};

const AbortSchedulingAppointmentBottomSheet: FC<
  AbortSchedulingAppointmentBottomSheetProps
> = ({ modalVisible, setModalVisible, pop }) => (
  <BottomModalContainer
    modalVisible={modalVisible}
    setModalVisible={setModalVisible}>
    <View style={styles.bottomSheetContentContainer}>
      <Text variant="bodyLarge">{t("T01275")}</Text>
      <Text variant="bodyMedium">{t("T01276")}</Text>
      <PrimaryButton mode="outlined" label="T01264" onPress={() => pop()} />
      <PrimaryButton label="T00472" onPress={() => setModalVisible(false)} />
    </View>
  </BottomModalContainer>
);

const styles = StyleSheet.create({
  bottomSheetContentContainer: {
    marginTop: spacing32,
    ...globalStyles.gapLarge,
  },
});

export default AbortSchedulingAppointmentBottomSheet;
