import {
  AbsoluteBlurredFooter,
  AbsoluteBlurredFooterProps,
} from "@components/Footers";
import { spacing16 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { isWeb, subSettingsUrl } from "@utils/constants";
import { FC, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Linking, StyleProp, TextStyle, View } from "react-native";
import { Text } from "react-native-paper";

type Props = AbsoluteBlurredFooterProps & {
  subCanceled: boolean;
  expireDate: string;
};

const SubscriptionActiveFooter: FC<Props> = ({
  subCanceled,
  expireDate,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    colors: { onPrimaryContainer },
  } = useAppTheme();

  const footerText: ReactNode = useMemo(() => {
    const footerTextStyle: StyleProp<TextStyle> = {
      color: onPrimaryContainer,
      textAlign: "center",
    };
    const text = subCanceled ? "T01314" : "T00952";
    return (
      <View style={{ marginTop: spacing16 }}>
        <Text variant="labelLarge" style={footerTextStyle}>
          {`${t("T00951")}.`}
        </Text>
        <Text variant="labelLarge" style={footerTextStyle}>
          {`${t(text)}: ${expireDate}`}
        </Text>
      </View>
    );
  }, [onPrimaryContainer, subCanceled, t, expireDate]);

  const openSubscriptionSettings = async () => {
    if (isWeb) return;
    await Linking.openURL(subSettingsUrl);
  };

  return (
    <AbsoluteBlurredFooter
      topText={footerText}
      buttons={
        !isWeb && [
          { label: "T01450", mode: "text", onPress: openSubscriptionSettings },
          { label: "T01451", mode: "text", onPress: openSubscriptionSettings },
        ]
      }
      {...props}
    />
  );
};

export default SubscriptionActiveFooter;
