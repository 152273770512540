import { useUserDetails } from "@hooks/user/useUserDetails";
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import {
  AppbarTitleWithLogo,
  AppBarTitleWithLogoForPhysiotherapist,
} from "@components/Appbar";
import Patients from "@screens/MessageCenter/Patients";
import Physiotherapists from "@screens/MessageCenter/Physiotherapists";
import {
  MessageCenterStackParamList,
  RootStackParamList,
} from "./navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";

const Stack = createNativeStackNavigator<MessageCenterStackParamList>();

export type MessageCenterScreenType = CompositeScreenProps<
  NativeStackScreenProps<MessageCenterStackParamList, "MessageCenter">,
  NativeStackScreenProps<
    Pick<RootStackParamList, "ConversationScreen" | "WhatCanYouExpect">
  >
>;

const MessageCenterStackNavigator: FC<
  PropsWithChildren<
    NativeStackScreenProps<MessageCenterStackParamList, "MessageCenter">
  >
> = () => {
  const { t } = useTranslation();
  const { isPhysiotherapist } = useUserDetails();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="MessageCenter"
        component={isPhysiotherapist ? Patients : Physiotherapists}
        options={{
          header: () =>
            isPhysiotherapist ? (
              <AppBarTitleWithLogoForPhysiotherapist title={t("T00083")} />
            ) : (
              <AppbarTitleWithLogo title={t("T00064")} />
            ),
        }}
      />
    </Stack.Navigator>
  );
};

export default MessageCenterStackNavigator;
