import { globalStyles } from "@styles/global";
import { spacing16, spacing4 } from "@styles/spacing";
import { FC, ReactElement, ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";

type Props = {
  chartComponent: () => ReactElement;
  border: string;
  title: string;
  subtitle: string;
  tooltip?: ReactNode;
  legendItems?: ReactNode[];
};

export const BaseChartComponent: FC<Props> = ({
  chartComponent,
  border,
  title,
  subtitle,
  legendItems,
  tooltip,
}) => {
  const { container, legendContainer, textContainer } = styles;

  return (
    <View style={[container, { borderColor: border }]}>
      <View style={textContainer}>
        <Text variant="titleMedium">{title}</Text>
        <Text variant="labelSmall">{subtitle}</Text>
        <View style={legendContainer}>{legendItems}</View>
      </View>
      {tooltip}
      {chartComponent()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 0.5,
    borderRadius: spacing16,
    padding: spacing4,
    paddingLeft: spacing16,
    justifyContent: "center",
  },
  textContainer: {
    paddingVertical: spacing16,
    ...globalStyles.gapMedium,
  },
  legendContainer: { flexDirection: "row", ...globalStyles.gapSmall },
});
