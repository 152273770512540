import Svg, { Path } from "react-native-svg";

const SvgComponent = props => (
  <Svg width={48} height={48} viewBox="0 0 48 48" fill="none" {...props}>
    <Path
      d="M27.78 17.4L24 21.18l-3.78-3.78c-.78-.78-2.04-.78-2.82 0-.78.78-.78 2.04 0 2.82L21.18 24l-3.78 3.78c-.78.78-.78 2.04 0 2.82.78.78 2.04.78 2.82 0L24 26.82l3.78 3.78c.78.78 2.04.78 2.82 0 .78-.78.78-2.04 0-2.82L26.82 24l3.78-3.78c.78-.78.78-2.04 0-2.82-.78-.76-2.06-.76-2.82 0zM24 4C12.94 4 4 12.94 4 24s8.94 20 20 20 20-8.94 20-20S35.06 4 24 4zm0 36c-8.82 0-16-7.18-16-16S15.18 8 24 8s16 7.18 16 16-7.18 16-16 16z"
      fill="#BA1A1A"
    />
  </Svg>
);

export default SvgComponent;
