import { useUpcomingAppointmentDate } from "@hooks/appointments/useUpcomingAppointmentDate";
import { StyleSheet, TextStyle, ViewStyle } from "react-native";
import { Chip } from "react-native-paper";

import { spacing16 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { Appointment } from "@screens/Appointments/appointment.types";
import { TrainingDay } from "@screens/TrainingsAndExercises/training.types";

type Props<T> = {
  firstCard: JSX.Element;
  secondCard?: JSX.Element;
  data: T[];
};

export type ChipProps = {
  style: ViewStyle;
  textStyle: TextStyle;
};

export const UpcomingDatesSection = ({
  firstCard,
  secondCard,
  data,
}: Props<Appointment | TrainingDay>): JSX.Element => {
  const { chipContainer, chipText } = chipsStyles;
  const commonChipsProps: ChipProps = {
    style: { ...chipContainer },
    textStyle: { ...chipText },
  };
  const { firstUpcomingDate, secondUpcomingDate, twoChipsEqual } =
    useUpcomingAppointmentDate<Appointment | TrainingDay>({ data });
  return (
    <>
      {firstUpcomingDate && (
        <>
          <Chip {...commonChipsProps}>{firstUpcomingDate}</Chip>
          {firstCard}
          {twoChipsEqual && secondCard}
        </>
      )}
      {secondUpcomingDate && !twoChipsEqual && (
        <>
          <Chip {...commonChipsProps}>{secondUpcomingDate}</Chip>
          {secondCard}
        </>
      )}
    </>
  );
};

export const chipsStyles = StyleSheet.create({
  chipContainer: {
    alignSelf: "flex-start",
    borderRadius: spacing16,
    backgroundColor: theme.colors.secondaryContainer,
  },
  chipText: { color: theme.colors.onSecondaryContainer },
});
