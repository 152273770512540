import { TransKey } from "@globalTypes/i18next";

type Data = {
  textSection: TransKey[];
  additionalText: TransKey;
  buttonLabel: TransKey;
};

export const patientData: Data = {
  textSection: ["T01371", "T01372", "T01373"],
  additionalText: "T01377",
  buttonLabel: "T01354",
};

export const rehabData: Data = {
  textSection: ["T01374", "T01375", "T01376"],
  additionalText: "T01378",
  buttonLabel: "T01177",
};
