export const palettes = {
  primary: {
    "5": "#00093F",
    "0": "#000000",
    "10": "#001159",
    "20": "#00208D",
    "25": "#0029A9",
    "30": "#0031C5",
    "35": "#103DDB",
    "40": "#4666FF",
    "50": "#4868FF",
    "60": "#7087FF",
    "70": "#95A6FF",
    "80": "#BAC3FF",
    "90": "#DEE1FF",
    "95": "#F0EFFF",
    "98": "#FBF8FF",
    "99": "#FEFBFF",
    "100": "#FFFFFF",
  },
  secondary: {
    "0": "#000000",
    "5": "#0D1021",
    "10": "#171A2C",
    "20": "#2C2F42",
    "25": "#373A4D",
    "30": "#434659",
    "35": "#4E5165",
    "40": "#5A5D72",
    "50": "#73768B",
    "60": "#8D8FA6",
    "70": "#A8AAC1",
    "80": "#C3C5DD",
    "90": "#DFE1F9",
    "91": "#505266",
    "95": "#F0EFFF",
    "98": "#FBF8FF",
    "99": "#FEFBFF",
    "100": "#FFFFFF",
  },
  warning: {
    "90": "#FFC22D1A",
    "91": "#FFC22D",
  },
  complete: {
    "5": "#4ACB291A",
    "10": "#4ACB29",
    "20": "#BAFAAA",
    "25": "#58c138",
    "30": "#2C9D0F",
  },
  tertiary: {
    "0": "#000000",
    "5": "#21071D",
    "10": "#2D1228",
    "20": "#44263E",
    "25": "#503149",
    "30": "#5D3C55",
    "35": "#694861",
    "36": "#6750A4",
    "40": "#76546D",
    "50": "#916C87",
    "60": "#AC85A1",
    "70": "#C89FBC",
    "80": "#E5BAD8",
    "90": "#FFD7F2",
    "95": "#FFEBF6",
    "98": "#FFF7F9",
    "99": "#FFFBFF",
    "100": "#FFFFFF",
  },
  error: {
    "0": "#000000",
    "5": "#2D0001",
    "10": "#410002",
    "20": "#690005",
    "25": "#7E0007",
    "30": "#93000A",
    "35": "#A80710",
    "40": "#BA1A1A",
    "50": "#DE3730",
    "60": "#FF5449",
    "70": "#FF897D",
    "80": "#FFB4AB",
    "90": "#FFDAD6",
    "95": "#FFEDEA",
    "98": "#FFF8F7",
    "99": "#FFFBFF",
    "100": "#FFFFFF",
  },
  neutral: {
    "0": "#000000",
    "5": "#101114",
    "10": "#1B1B1F",
    "20": "#303034",
    "25": "#3B3B3F",
    "26": "#3f5afa",
    "30": "#47464A",
    "35": "#535256",
    "40": "#5F5E62",
    "50": "#78767A",
    "60": "#919094",
    "70": "#ACAAAF",
    "80": "#C8C5CA",
    "90": "#E4E1E6",
    "95": "#F3F0F4",
    "98": "#FBF8FD",
    "99": "#FEFBFF",
    "100": "#FFFFFF",
  },
  neutralVariant: {
    "0": "#000000",
    "5": "#0F1018",
    "10": "#1A1B23",
    "20": "#2F3038",
    "25": "#3A3B43",
    "30": "#46464F",
    "35": "#51525B",
    "40": "#5D5E67",
    "50": "#767680",
    "60": "#90909A",
    "70": "#ABAAB4",
    "80": "#C6C5D0",
    "90": "#E3E1EC",
    "95": "#F1EFFA",
    "98": "#FBF8FF",
    "99": "#FEFBFF",
    "100": "#FFFFFF",
  },
};

export const avatarColors = [
  palettes.primary[70],
  palettes.error[70],
  palettes.tertiary[70],
];

export const bottomSheetBgColor = palettes.primary[95];
export const white = palettes.primary[100];

export const chartColors = {
  primary: palettes.primary[80],
  secondary: palettes.complete[20],
  primarySelected: palettes.neutral[26],
  secondarySelected: palettes.complete[25],
  max: palettes.secondary[90],
  maxSelected: palettes.secondary[91],
};

export const calendarBackgroundColor =
  "linear-gradient(0deg, rgba(39, 75, 230, 0.11), rgba(39, 75, 230, 0.11)), #FEFBFF";

export const zoomFocusedBorderColor = "#0FFF13";
