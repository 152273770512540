import { DETAILS, LIST, OPTIONS } from "@utils/constants";

const keys = {
  medicalRecord: "medicalRecord",
  createdByMe: "createdByMe",
  sharedToMe: "sharedToMe",
  sharedBetweenMeAndOtherUserList: "sharedBetweenMeAndOtherUserList",
  sharedToOtherUserDetails: "sharedToOtherUser",
  muscles: "muscles",
  createdFor: "createdFor",
};

export const queryKeysMedicalRecord = {
  all: [keys.medicalRecord] as const,
  recordLists: () => [...queryKeysMedicalRecord.all, LIST] as const,
  createdByMeList: () =>
    [...queryKeysMedicalRecord.recordLists(), keys.createdByMe] as const,
  sharedToMeList: () =>
    [...queryKeysMedicalRecord.recordLists(), keys.sharedToMe] as const,
  createdForList: (sharedId: number) =>
    [
      ...queryKeysMedicalRecord.recordLists(),
      keys.createdFor,
      sharedId,
    ] as const,
  sharedBetweenMeAndOtherUserList: (sharedId: number) =>
    [
      ...queryKeysMedicalRecord.recordLists(),
      keys.sharedBetweenMeAndOtherUserList,
      sharedId,
    ] as const,
  recordDetails: (recordId: number) =>
    [...queryKeysMedicalRecord.all, DETAILS, recordId] as const,
  sharedToOtherUserDetails: (id: number) =>
    [
      ...queryKeysMedicalRecord.all,
      DETAILS,
      keys.sharedToOtherUserDetails,
      id,
    ] as const,
  musclesOptions: () =>
    [...queryKeysMedicalRecord.all, keys.muscles, OPTIONS] as const,
};
