import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.1685 12H15.8315C13.7154 12 12 13.7154 12 15.8315V32.1685C12 34.2846 13.7154 36 15.8315 36H32.1685C34.2846 36 36 34.2846 36 32.1685V15.8315C36 13.7154 34.2846 12 32.1685 12Z"
      fill="#4666FF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8828 16.5986C21.8828 15.4636 22.7317 14.6904 23.9274 14.6904C25.1231 14.6904 25.9284 15.4636 25.9284 16.5986C25.9284 17.7337 25.1013 18.484 23.9274 18.484C22.7535 18.484 21.8828 17.6863 21.8828 16.5986Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.691 27.0009L25.9543 24.8142L31.2402 18.29H27.5205L24.0632 22.7364L20.606 18.29H16.7347L22.0404 24.8862L16.6484 31.6037H20.3663L23.9334 27.037L25.6388 29.2483L27.691 27.0009Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.5266 29.3125H25.6875L27.4545 31.6034H31.3467L29.5266 29.3125Z"
      fill="white"
    />
  </Svg>
);

export default SvgComponent;
