import { spacing6 } from "@styles/spacing";
import { View } from "react-native";
import { Badge } from "react-native-paper";
import Svg, { ClipPath, Defs, G, Path, Rect, SvgProps } from "react-native-svg";

type Props = SvgProps & { badge?: boolean };
const SvgComponent = ({ badge = false, ...props }: Props) => (
  <View>
    {badge && <Badge size={spacing6} style={{ position: "absolute" }} />}

    <Svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <G clip-path="url(#clip0_56735_16536)">
        <Path
          d="M16.4 11C18.06 11 19.39 9.66 19.39 8C19.39 6.34 18.06 5 16.4 5C14.74 5 13.4 6.34 13.4 8C13.4 9.66 14.74 11 16.4 11ZM8.40002 11C10.06 11 11.39 9.66 11.39 8C11.39 6.34 10.06 5 8.40002 5C6.74002 5 5.40002 6.34 5.40002 8C5.40002 9.66 6.74002 11 8.40002 11ZM8.40002 13C6.07002 13 1.40002 14.17 1.40002 16.5V19H15.4V16.5C15.4 14.17 10.73 13 8.40002 13ZM16.4 13C16.11 13 15.78 13.02 15.43 13.05C16.59 13.89 17.4 15.02 17.4 16.5V19H23.4V16.5C23.4 14.17 18.73 13 16.4 13Z"
          fill="#46464F"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_56735_16536">
          <Rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.400024)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  </View>
);
export default SvgComponent;
