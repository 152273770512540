import { FC, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { queryKeysTraining } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { getAssignedTrainingData } from "@api/trainings";
import { DefaultTileProps } from "@components/Tile/types/Tile.types";
import BaseTrainingTile from "@components/Tile/training/BaseTrainingTile";
import { queryKeysPhysiotherapistProfileData } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { getPhysiotherapistFullData } from "@api/users";

type TrainingTileWithAuthorNameProps = DefaultTileProps & {
  trainingId: number;
};

const TrainingTileWithAuthorName: FC<TrainingTileWithAuthorNameProps> = ({
  trainingId,
  mode,
  onPress,
  ...props
}) => {
  const { data, isFetched, isError, refetch } = useQuery({
    queryKey: queryKeysTraining.detail(trainingId),
    queryFn: async () => await getAssignedTrainingData(trainingId),
  });

  const trainingAuthorId = data?.author;

  const {
    data: authorData,
    isFetched: isAuthorDataFetched,
    isError: isAuthorDataError,
    refetch: refetchAuthorData,
  } = useQuery({
    queryKey: queryKeysPhysiotherapistProfileData.full(trainingAuthorId),
    queryFn: async () => await getPhysiotherapistFullData(trainingAuthorId),
    enabled: !!trainingAuthorId,
  });

  const refetchAll = async () =>
    await Promise.all([refetch(), refetchAuthorData()]);

  const isAnyFetched = useMemo(
    () => isFetched || isAuthorDataFetched,
    [isFetched, isAuthorDataFetched],
  );
  const isAnyError = useMemo(
    () => isError || isAuthorDataError,
    [isAuthorDataError, isError],
  );

  return (
    <BaseTrainingTile
      mode={mode || "outlined"}
      data={data}
      isFetched={isAnyFetched}
      isError={isAnyError}
      refetch={refetchAll}
      subtitle={`${authorData?.firstName} ${authorData?.lastName}`}
      showExercises={false}
      onPress={!isAnyError && onPress}
      {...props}
    />
  );
};

export default TrainingTileWithAuthorName;
