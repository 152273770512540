import { endpoints } from "@api/endpoints";
import {
  HelpCenterSubmission,
  HelpCenterSubmissionResponse,
} from "@screens/Profiles/MyProfile/CommonProfile/HelpCenter/helpCenter.types";
import { api } from "./api";
import { ResponseOptions } from "@api/api.types";

export const createSubmission = (params: HelpCenterSubmission) =>
  api.post<HelpCenterSubmissionResponse>(
    endpoints.HELP_CENTER_SUBMISSION,
    params,
  );

export const getHelpCenterCategories = () =>
  api.options<ResponseOptions>(endpoints.HELP_CENTER_SUBMISSION);

export const getFaqCategories = async () => {
  const { data } = await api.get<{ id: number }[]>(endpoints.CATEGORIES);
  return data;
};

export const getFaqCategory = async (id: number) => {
  const { data } = await api.get<{ id: number; name: string }>(
    endpoints.CATEGORY_DETAILS(id),
  );
  return data;
};

export const getCategorySubjects = async (id: number) => {
  const { data } = await api.get<{ id: number; name: string }[]>(
    endpoints.CATEGORIES_SUBJECTS(id),
  );
  return data;
};

export const getCategorySubjectDetails = async (
  catID: number,
  subID: number,
) => {
  const { data } = await api.get<{
    id: number;
    name: string;
    subtitle: string;
  }>(endpoints.CATEGORY_SUBJECT_DETAILS(catID, subID));
  return data;
};
