import AppbarTitleWithDefaultBackAction from "@components/Appbar/AppbarTitleWithDefaultBackAction";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { AbsoluteBlurredFooter } from "@components/Footers";
import { AppleSubscriptionStatusCodes } from "@contexts/AuthContext/user.types";
import { useSubscription } from "@hooks/subscriptions/useSubscription";
import { useSubscriptionData } from "@hooks/subscriptions/useSubscriptionData";
import { useSubscriptionStatus } from "@hooks/subscriptions/useSubscriptionStatus";
import {
  ProfileCompletionStackParamList,
  ProfileStackParamsList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import SubscriptionScreenContent from "@screens/Profiles/MyProfile/PhysiotherapistVerification/Subscription/SubscriptionScreenContent";
import { globalStyles } from "@styles/global";
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { withIAPContext } from "react-native-iap";
import { ActivityIndicator } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import SubscriptionActiveFooter from "./SubscriptionActiveFooter";
import SubSheetContent from "./SubSheetContent";
import { spacing8 } from "@styles/spacing";
import { PrimaryButton } from "@components/buttons";

export type SubscriptionScreenProps = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<ProfileCompletionStackParamList, "Subscription">,
    NativeStackScreenProps<ProfileStackParamsList, "Profile">
  >
>;

const Subscription: FC<SubscriptionScreenProps> = ({
  navigation: { setOptions, navigate },
}) => {
  const { t } = useTranslation();
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const { onSubscribe, connected, displaySubscriptionPrice, loading } =
    useSubscription();
  const {
    isSubActive,
    getFormattedExpireDate,
    subscriptionExpires,
    endOfFreeTrialChargeDate,
  } = useSubscriptionData();
  const { googleSubscriptionStatus, appleSubscriptionStatus } =
    useSubscriptionStatus();
  const [subSheetVisible, setSubSheetVisible] = useState(false);

  const subStatusCanceled = useMemo(
    () =>
      (googleSubscriptionStatus?.length &&
        googleSubscriptionStatus[0]?.subscriptionStatus ===
          "SUBSCRIPTION_STATE_CANCELED") ||
      (appleSubscriptionStatus?.length &&
        appleSubscriptionStatus[0]?.subscriptionStatus ===
          AppleSubscriptionStatusCodes.AUTO_RENEWABLE_SUBSCRIPTION_CANCELED),
    [appleSubscriptionStatus, googleSubscriptionStatus],
  );

  useEffect(() => {
    setOptions({
      header: ({ navigation }) => (
        <AppbarTitleWithDefaultBackAction
          title={t("T00936")}
          filledIcon={isSubActive}
          navigation={navigation}
        />
      ),
    });
  }, [setOptions, t, isSubActive, navigate]);

  const freeTrialAvailable = subscriptionExpires === null;
  const price = displaySubscriptionPrice();

  const subButtonLabel = useMemo(
    () => (freeTrialAvailable ? "T01452" : `${t("T00950")} ${price || ""}`),
    [price, freeTrialAvailable, t],
  );

  if (!connected) return <ActivityIndicator style={globalStyles.loading} />;

  return (
    <SafeAreaView edges={["bottom", "left", "right"]}>
      <SubscriptionScreenContent
        footerHeight={footerHeight}
        isSubActive={isSubActive}
      />
      {isSubActive ? (
        <SubscriptionActiveFooter
          onLayout={setFooterHeight}
          subCanceled={subStatusCanceled}
          expireDate={getFormattedExpireDate()}
        />
      ) : (
        <AbsoluteBlurredFooter
          onLayout={setFooterHeight}
          buttons={[
            {
              label: subButtonLabel,
              onPress: () => setSubSheetVisible(true),
              loading,
              disabled: loading,
            },
          ]}
        />
      )}
      <BottomModalContainer
        modalVisible={subSheetVisible}
        setModalVisible={setSubSheetVisible}>
        <SubSheetContent
          subPrice={price}
          freeTrialAvailable={freeTrialAvailable}
          endOfFreeTrialChargeDate={endOfFreeTrialChargeDate}
          subButton={
            <PrimaryButton
              label={subButtonLabel}
              onPress={() => {
                onSubscribe();
                setSubSheetVisible(false);
              }}
              style={{ marginTop: spacing8 }}
              loading={loading}
              disabled={loading}
            />
          }
        />
      </BottomModalContainer>
    </SafeAreaView>
  );
};

export default withIAPContext(Subscription);
