import {
  SvgCalendar,
  SvgHome,
  SvgProfile,
  SvgTrainings,
  SvgUsers,
} from "@assets/svg";
import { StyleSheet, View } from "react-native";
import { palettes } from "@styles/colors";
import { spacing16, spacing24, spacing4 } from "@styles/spacing";
import { TransKey } from "@globalTypes/i18next";

const renderTabIcon = (icon: JSX.Element, selected: boolean) => (
  <View
    style={[
      { backgroundColor: selected ? palettes.secondary[90] : " transparent" },
      styles.iconContainer,
    ]}>
    {icon}
  </View>
);

export const getTabBarOptions = (
  route: string,
  isSelected: boolean,
  t: (str: TransKey) => string,
  isPhysiotherapist: boolean,
  unreadMessages?: boolean,
) => {
  const tempRoute = route.split("/");
  const resultRoute = tempRoute[tempRoute.length - 1].split("?");

  switch (resultRoute[0]) {
    case "profile-stack":
    case "ProfileStack":
      return {
        name: t("T00120"),
        icon: renderTabIcon(<SvgProfile />, isSelected),
      };
    case "message-center-stack":
    case "MessageCenterStack":
      return {
        name: t(isPhysiotherapist ? "T00083" : "T00064"),
        icon: renderTabIcon(<SvgUsers badge={unreadMessages} />, isSelected),
      };
    case "trainings-stack":
    case "TrainingsStack":
      return {
        name: t("T00167"),
        icon: renderTabIcon(<SvgTrainings />, isSelected),
      };
    case "calendar-stack":
    case "CalendarStack":
      return {
        name: t("T00067"),
        icon: renderTabIcon(<SvgCalendar />, isSelected),
      };
    default:
      return {
        name: t("T00162"),
        icon: renderTabIcon(<SvgHome />, isSelected),
      };
  }
};

export const shouldHideTabBar = (route: string) => {
  switch (route) {
    case "ProfileCompletionStack":
    case "TransactionHistoryStack":
    case "RehabStatistics":
    case "HelpCenterStack":
    case "ForumStack":
      return true;
    default:
      return false;
  }
};

const styles = StyleSheet.create({
  iconContainer: {
    paddingHorizontal: spacing24,
    paddingVertical: spacing4,
    borderRadius: spacing16,
  },
});
