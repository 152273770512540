import { PickerItem } from "@components/DropdownPicker/DropdownPicker";
import { useTranslation } from "react-i18next";

export const useFrequencyOptions = (): PickerItem<number>[] => {
  const { t } = useTranslation();
  return [
    {
      label: `${t("T00857")}`,
      value: 1,
    },
    {
      label: `${t("T00858")}`,
      value: 2,
    },
    {
      label: `${t("T00859")}`,
      value: 3,
    },
  ];
};
