import { spacing16 } from "@styles/spacing";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Card, Text } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { palettes } from "@styles/colors";
import { TransKey } from "@globalTypes/i18next";

export type StatisticInfoTileProps = {
  leftIcon: () => ReactElement;
  title: TransKey;
  value: string;
  label: TransKey;
};

const BaseStatisticInfoTile: FC<StatisticInfoTileProps> = ({
  leftIcon,
  title,
  value,
  label,
}) => {
  const { t } = useTranslation();
  const {
    card,
    contentContainer,
    leftIconContainer,
    titleStyle,
    rightComponentContainer,
    rightComponentInnerContainer,
    valueTextColor,
  } = styles;
  return (
    <Card mode="outlined" style={card}>
      <View style={contentContainer}>
        <View style={leftIconContainer}>{leftIcon()}</View>
        <Text variant="titleMedium" numberOfLines={3} style={titleStyle}>
          {t(title)}
        </Text>
        <View style={rightComponentContainer}>
          <View style={rightComponentInnerContainer}>
            <Text variant="titleMedium" style={valueTextColor}>
              {value}
            </Text>
            <Text variant="titleSmall">{label}</Text>
          </View>
        </View>
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    backgroundColor: palettes.primary[100],
  },
  contentContainer: {
    flexDirection: "row",
    paddingVertical: spacing16,
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 80,
  },
  leftIconContainer: { width: "20%", alignItems: "center" },
  titleStyle: { width: "40%" },
  rightComponentContainer: {
    width: "40%",
    alignItems: "flex-end",
    paddingRight: spacing16,
  },
  valueTextColor: {
    color: palettes.primary[40],
  },
  rightComponentInnerContainer: {
    ...globalStyles.gapMedium,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default BaseStatisticInfoTile;
