import { FC, useCallback } from "react";
import { Modal, Pressable, StyleSheet, View } from "react-native";
import { palettes } from "@styles/colors";
import { Calendar, CalendarProps, DateArrayType } from "@components/Calendar";
import { spacing16 } from "@styles/spacing";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { globalStyles } from "@styles/global";
import { CalendarDates } from "@screens/Calendar/availability.types";
import { isNewDateInPrevDates } from "@screens/Calendar/calendarUtils";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { useTranslation } from "react-i18next";

type NestedCalendarModalProps = CalendarDates & {
  visible: boolean;
  onDismiss: CalendarProps["onDismiss"];
  onConfirmSelection: CalendarProps["onSubmit"];
};

const NestedCalendarModal: FC<NestedCalendarModalProps> = ({
  visible,
  onDismiss,
  onConfirmSelection,
  initialDate,
  datesWithEvents,
  data,
}) => {
  const { height } = useWindowDimensions();
  const { calendarContainer, backdrop, container } = styles;
  const { t } = useTranslation();

  const showAlert = useCallback(
    (dates: DateArrayType[]) =>
      showAlertWithCustomButtons({
        title: t("T01166"),
        message: t("T01167"),
        cancelButton: {
          text: t("T00145"),
        },
        confirmButton: {
          text: t("T01168"),
          onPress: () => onConfirmSelection(dates),
        },
      }),
    [onConfirmSelection, t],
  );

  const submitCalendarDates: CalendarProps["onSubmit"] = dates => {
    const existingDates = data.availableAppointments.map(
      appointment => appointment.date,
    );
    const showReplaceHoursDialog = isNewDateInPrevDates({
      prevDates: existingDates,
      newDates: dates,
    });
    if (showReplaceHoursDialog) {
      return showAlert(dates);
    }
    onConfirmSelection(dates);
  };

  return (
    <Modal
      visible={visible}
      onDismiss={onDismiss}
      animationType="fade"
      transparent>
      <Pressable onPress={onDismiss} style={backdrop}></Pressable>
      <View
        style={[
          container,
          {
            marginTop: height / 4,
          },
        ]}>
        <Calendar
          containerStyle={calendarContainer}
          initialDates={[initialDate]}
          datesWithEvents={datesWithEvents}
          multiselect
          onDismiss={onDismiss}
          onSubmit={submitCalendarDates}
          limitRange
          disableModeChange
        />
      </View>
    </Modal>
  );
};

export default NestedCalendarModal;

const styles = StyleSheet.create({
  backdrop: {
    ...globalStyles.backdrop,
    ...StyleSheet.absoluteFillObject,
  },
  container: {
    backgroundColor: palettes.primary["95"],
    marginHorizontal: spacing16,
    borderRadius: spacing16,
  },
  calendarContainer: { borderRadius: 0 },
});
