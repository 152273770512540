import { CheckboxListItem } from "@components/ListItems/CheckboxListItem";
import { FetchError } from "@components/errors";
import { queryKeysMedicalRecord } from "@screens/Profiles/MyProfile/MedicalRecords/queryKeysMedicalRecord";
import { getMedicalRecord } from "@services/ApiService/medicalRecords";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { ImageSourcePropType, StyleSheet, View } from "react-native";
import { ActivityIndicator, CheckboxProps } from "react-native-paper";

type Props = {
  id: number;
  onPress: () => void;
  checkboxProps: CheckboxProps;
};

const MedicalRecordListItem: FC<Props> = ({ id, onPress, checkboxProps }) => {
  const { gapLarge } = globalStyles;
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysMedicalRecord.recordDetails(id),
    queryFn: async () => await getMedicalRecord(id),
  });

  if (isLoading) return <ActivityIndicator />;

  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <View style={[gapLarge, styles.container]}>
      <CheckboxListItem
        title={data?.title}
        img={
          require("@assets/images/equipment_default_avatar.png") as ImageSourcePropType
        }
        onPress={onPress}
        checkboxProps={checkboxProps}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default MedicalRecordListItem;
