import { PermissionType } from "@globalTypes/common.types";
import { TransKey } from "@globalTypes/i18next";
import {
  SvgCalendarIllustration,
  SvgCameraAndMicrophoneIllustration,
  SvgLocationIllustration,
  SvgNotificationsIllustration,
  SvgGalleryAndFilesIllustration,
} from "@assets/svg/permissions";

type PermissionModalContentProps = {
  image: JSX.Element;
  title: TransKey;
  subtitle: TransKey;
  confirmButtonLabel: TransKey;
};

export const getContentForPermissionModal = (
  type: PermissionType,
  isPatient: boolean,
): PermissionModalContentProps => {
  switch (type) {
    case PermissionType.calendar:
      return {
        image: <SvgCalendarIllustration />,
        title: "T01495",
        subtitle: "T01496",
        confirmButtonLabel: "T01490",
      };
    case PermissionType.cameraAndMicrophone:
      return {
        image: <SvgCameraAndMicrophoneIllustration />,
        title: "T01493",
        subtitle: "T01494",
        confirmButtonLabel: "T01490",
      };
    case PermissionType.location:
      return {
        image: <SvgLocationIllustration />,
        title: "T01497",
        subtitle: "T01498",
        confirmButtonLabel: "T01497",
      };
    case PermissionType.notifications:
      return {
        image: <SvgNotificationsIllustration />,
        title: "T01486",
        subtitle: "T01488",
        confirmButtonLabel: "T01486",
      };
    case PermissionType.galleryAndFiles:
      return {
        image: <SvgGalleryAndFilesIllustration />,
        title: "T01489",
        subtitle: isPatient ? "T01492" : "T01491",
        confirmButtonLabel: "T01490",
      };
  }
};
