import { ButtonBasic } from "@components/Button/Button.types";
import { PrimaryButton } from "@components/buttons";
import { palettes } from "@styles/colors";
import { spacing24 } from "@styles/spacing";
import { FC } from "react";
import { Icon } from "react-native-paper";

const AppleLogin: FC<ButtonBasic> = props => {
  return (
    <PrimaryButton
      icon={() => (
        <Icon source="apple" size={spacing24} color={palettes.primary[100]} />
      )}
      buttonColor={palettes.primary[0]}
      {...props}
    />
  );
};

export default AppleLogin;
