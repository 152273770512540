import { getHelpCenterCategories } from "@api/helpCenter";
import DropdownPicker, {
  PickerItem,
} from "@components/DropdownPicker/DropdownPicker";
import { FetchError } from "@components/errors";
import { queryKeysHelpCenter } from "@screens/Common/queryKeysHelpCenter";
import { HelpCenterIssue } from "@screens/Profiles/MyProfile/CommonProfile/HelpCenter/helpCenter.types";
import { spacing4 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { ActivityIndicator, HelperText } from "react-native-paper";

type Props = {
  onSelectItem: (item: PickerItem) => void;
  errorMessage: string;
  defaultValue?: HelpCenterIssue;
};

const FormCategoriesDropdownPicker: FC<Props> = ({
  onSelectItem,
  errorMessage,
  defaultValue,
}) => {
  const [items, setItems] = useState<PickerItem[]>([]);
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysHelpCenter.options(),
    queryFn: getHelpCenterCategories,
  });

  useEffect(() => {
    if (!isError && !isLoading) {
      const items: PickerItem[] =
        data.data.actions.post.submissionCategory.choices.map(
          ({ value, displayName }) => ({
            label: displayName,
            value: value.toString(),
          }),
        );
      setItems(items);
    }
  }, [data, isError, isLoading]);

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} />;
  const { errorText } = styles;
  return (
    items?.length > 0 && (
      <>
        <DropdownPicker
          dropdownLabelText="T01318"
          items={items}
          onSelectItem={onSelectItem}
          placeholder="T01318"
          hasError={!!errorMessage}
          isRequired
          defaultValue={defaultValue}
        />
        {errorMessage && (
          <HelperText type="error" style={errorText}>
            {errorMessage}
          </HelperText>
        )}
      </>
    )
  );
};

export default FormCategoriesDropdownPicker;

const styles = StyleSheet.create({
  errorText: { marginTop: -spacing4, paddingVertical: 0 },
});
