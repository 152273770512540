import { NotificationSetting } from "@globalTypes/messagesNotificationSetting";
import {
  systemPushPermissionDeniedAlert,
  turnOnMainNotificationAlert,
} from "@screens/Profiles/MyProfile/Settings/utils";
import { queryKeysNotificationSettings } from "@screens/Profiles/MyProfile/queryKeysSettings";
import { useQueryClient } from "@tanstack/react-query";
import { isANDROID } from "@utils/constants";
import { showAlert } from "@utils/showAlert";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppState, Platform } from "react-native";
import {
  checkNotifications as RNP_checkNotifications,
  requestNotifications as RNP_requestNotifications,
  openSettings,
} from "react-native-permissions";
import { useUpdateNotificationMain } from "./useUpdateNotificationMain";

type UseCheckNotificationsOptions = {
  mainNotification: NotificationSetting;
  onPermissionsGranted: (withGoBackAction?: boolean) => void;
};

const isAndroidVersionLessOrEqualThan32 = isANDROID && +Platform.Version <= 32; //android <=12 is granted by default; requestNotifications must be called so user can change push permissions in phone settings

export const useCheckNotifications = ({
  mainNotification,
  onPermissionsGranted,
}: UseCheckNotificationsOptions) => {
  const { t } = useTranslation();
  const [isNotificationGranted, setIsNotificationGranted] = useState(false);
  const [isCheckFinished, setIsCheckFinished] = useState(false);
  const queryClient = useQueryClient();
  const appState = useRef(AppState.currentState);

  const { update } = useUpdateNotificationMain({
    settingId: mainNotification?.id,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeysNotificationSettings.all,
      }),
  });

  const onConfirmPermissionDeniedAlert = useCallback(() => {
    if (!mainNotification?.notificationsActive) update(true);
    openSettings().catch(err => {
      console.error(err);
      showAlert(t("T00832"));
    });
  }, [mainNotification?.notificationsActive, t, update]);

  const requestNotifications = useCallback(() => {
    if (!mainNotification) showAlert(t("T00676"));
    RNP_requestNotifications(["alert"])
      .then(({ status }) => {
        const isGranted = status === "granted";
        setIsNotificationGranted(isGranted);
        return { active: isGranted };
      })
      .then(({ active }) => {
        active && onPermissionsGranted();
        update(active);
      })
      .catch(err => {
        console.error(err);
        alert(t("T00832"));
      });
  }, [mainNotification, onPermissionsGranted, t, update]);

  const handleCheckIsNotificationsGranted = useCallback(async () => {
    try {
      const { status } = await RNP_checkNotifications();
      const isGranted = status === "granted";
      setIsNotificationGranted(isGranted);
      return isGranted;
    } catch (err) {
      console.error(err);
      alert(t("T00832"));
      return false;
    } finally {
      setIsCheckFinished(true);
    }
  }, [t]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void handleCheckIsNotificationsGranted(), []);

  useEffect(() => {
    const subscription = AppState.addEventListener(
      "change",
      async nextAppState => {
        if (appState.current === "background" && nextAppState === "active") {
          const isGranted = await handleCheckIsNotificationsGranted();
          isGranted &&
            !isAndroidVersionLessOrEqualThan32 &&
            onPermissionsGranted(false);
        }
        appState.current = nextAppState;
      },
    );

    return () => {
      subscription.remove();
    };
  }, [
    handleCheckIsNotificationsGranted,
    isNotificationGranted,
    onPermissionsGranted,
  ]);

  const handleCheckNotifications = useCallback(() => {
    RNP_checkNotifications()
      .then(({ status }) => {
        const isGranted = status === "granted";
        const isBlocked = status === "blocked";
        if (!isGranted || isAndroidVersionLessOrEqualThan32)
          requestNotifications();

        if (!isGranted && isBlocked)
          systemPushPermissionDeniedAlert(t, onConfirmPermissionDeniedAlert);

        if (isGranted && !mainNotification.notificationsActive)
          turnOnMainNotificationAlert(t, () => update(true));
      })
      .catch(err => {
        console.error(err);
        alert(t("T00832"));
      });
  }, [
    mainNotification?.notificationsActive,
    onConfirmPermissionDeniedAlert,
    requestNotifications,
    t,
    update,
  ]);

  return {
    handleCheckNotifications,
    handleCheckIsNotificationsGranted,
    isNotificationGranted,
    isCheckFinished,
  };
};
