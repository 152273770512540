import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType, StyleSheet } from "react-native";
import { Card, Text } from "react-native-paper";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { TileProps } from "./types/Tile.types";

const Tile: FC<TileProps> = ({ tileType, elevation, mode, ...props }) => {
  const { t } = useTranslation();

  const { containerOutlined, cardContent, textContainer, fontColor, image } =
    styles;

  const getTileData = () => {
    switch (tileType) {
      case "training":
        return {
          title: t("T00051"),
          subtitle: t("T00052"),
          img: require("@assets/images/tiles/training.png") as ImageSourcePropType,
        };
      case "assistant":
        return {
          title: t("T00801"),
          subtitle: t("T00802"),
          img: require("@assets/images/tiles/assistant.png") as ImageSourcePropType,
        };
      default:
        return {
          title: t("T00049"),
          subtitle: t("T00050"),
          img: require("@assets/images/tiles/medic.png") as ImageSourcePropType,
        };
    }
  };

  const { title, subtitle, img } = getTileData();

  return (
    <Card
      style={[styles[tileType], mode === "outlined" && containerOutlined]}
      contentStyle={cardContent}
      elevation={elevation as never}
      mode={mode}
      {...props}>
      <Card.Content style={[globalStyles.gapSmall, textContainer]}>
        <Text variant="titleMedium" style={fontColor}>
          {title}
        </Text>
        <Text variant="bodySmall" style={fontColor}>
          {subtitle}
        </Text>
      </Card.Content>
      <Card.Cover style={image} source={img} />
    </Card>
  );
};

const styles = StyleSheet.create({
  cardContent: {
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  textContainer: {
    flex: 1,
  },
  image: {
    marginTop: spacing16,
    marginRight: spacing16,
    backgroundColor: "transparent",
    width: 110,
    height: 128,
  },
  fontColor: {
    color: palettes.primary[100],
  },
  medic: { backgroundColor: palettes.primary[40] },
  training: { backgroundColor: palettes.tertiary[50] },
  assistant: { backgroundColor: palettes.tertiary[36] },
  containerOutlined: {
    borderColor: theme.colors.outlineVariant,
  },
});

export default Tile;
