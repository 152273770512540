import { StyleSheet } from "react-native";
import { theme } from "../../../styles/theme";
import { MessageType } from "../types/MessageTile.types";

export const getTileStyleByType = (type: MessageType) => {
  if (!type) return null;
  switch (type) {
    case MessageType.REPORTER:
      return styles.reporterTile;
    case MessageType.INTERLOCUTOR:
      return styles.interlocutorTile;
  }
};

const styles = StyleSheet.create({
  reporterTile: {
    backgroundColor: theme.colors.primary,
  },
  interlocutorTile: {
    backgroundColor: theme.colors.background,
  },
});
