import { View } from "react-native";
import { spacing8 } from "@styles/spacing";
import { FC, useState } from "react";
import { chartColors } from "@styles/colors";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import {
  GroupedChartLegend,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryStack,
} from ".";

export type ChartItemType = {
  label: string;
  value: number;
  maxValue?: number;
};

export type GroupedStackedBarItemType = {
  group: string;
  groupItems: ChartItemType[];
};

type Props = {
  data: GroupedStackedBarItemType[];
};

export const GroupedStackedBarChart: FC<Props> = ({ data }) => {
  const [displayedMonth, setDisplayedMonth] = useState<string[]>([]);
  const { width } = useWindowDimensions();

  const onPressData = ({ groupItems, group }: GroupedStackedBarItemType) => ({
    target: "data",
    mutation: () => {
      return setDisplayedMonth(prevState => {
        const newState = groupItems.map(
          ({ label, value, maxValue }) =>
            `${group}.${label} ${value}/${maxValue}`,
        );
        const isArraysEqual =
          prevState.length === newState.length &&
          prevState.every((item, index) => item === newState[index]);
        return isArraysEqual ? [] : newState;
      });
    },
  });

  const barEvents = ({ groupItems, group }: GroupedStackedBarItemType) => [
    {
      target: "data",
      eventHandlers: {
        onPressIn: () => {
          return [onPressData({ groupItems, group })];
        },
        onClick: () => {
          return [onPressData({ groupItems, group })];
        },
      },
    },
  ];

  const getYTickValues = () => {
    const tempData = [...data];
    const tempChartYValues: number[] = [];

    tempData.forEach(({ groupItems }) => {
      tempChartYValues.push(groupItems[0].value);
      tempChartYValues.push(groupItems[0].maxValue);
    });

    tempData.forEach(({ groupItems }) => {
      tempChartYValues.push(groupItems[1].value);
      tempChartYValues.push(groupItems[1].maxValue);
    });

    const resultChartYValues = tempChartYValues
      .filter(e => e !== null)
      .sort((a, b) => a - b);

    const lastYValue = resultChartYValues[resultChartYValues.length - 1];

    return [0, Math.round(lastYValue / 2), lastYValue];
  };

  return (
    <View>
      <GroupedChartLegend displayedMonth={displayedMonth} />
      <VictoryChart
        width={width}
        height={width / 2}
        domainPadding={{ x: 50 }}
        padding={{ bottom: 30, right: 70, left: 40, top: 20 }}>
        {data.map((groupItem, index) => (
          <VictoryGroup
            key={`group-${groupItem.group}-${index}`}
            offset={spacing8 + 2}
            style={{ data: { width: spacing8 } }}>
            {groupItem.groupItems.map(({ label, value, maxValue }, i) => {
              const shouldBeSelected = displayedMonth.some(
                el => el === `${groupItem.group}.${label} ${value}/${maxValue}`,
              );

              const firstValueFill = shouldBeSelected
                ? chartColors.primarySelected
                : chartColors.primary;
              const maxValueFill = shouldBeSelected
                ? chartColors.maxSelected
                : chartColors.max;
              const secondValueFill = shouldBeSelected
                ? chartColors.secondarySelected
                : chartColors.secondary;

              return (
                <VictoryStack key={`stack-${label}-${i}`}>
                  <VictoryBar
                    data={[{ x: groupItem.group, y: value }]}
                    style={{
                      data: {
                        fill: i === 0 ? firstValueFill : secondValueFill,
                      },
                    }}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    events={barEvents(groupItem)}
                  />
                  <VictoryBar
                    data={[{ x: groupItem.group, y: maxValue }]}
                    style={{
                      data: {
                        fill: maxValueFill,
                      },
                    }}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    events={barEvents(groupItem)}
                  />
                </VictoryStack>
              );
            })}
          </VictoryGroup>
        ))}
        <VictoryAxis />
        <VictoryAxis dependentAxis tickValues={getYTickValues()} />
      </VictoryChart>
    </View>
  );
};
