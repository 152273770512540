import { SvgBellActive, SvgBellInactive } from "@assets/svg";
import { RootStackParamList } from "@navigators/navigation.types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { spacing12, spacing16 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { FC } from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import { Text } from "react-native-paper";

type BellProps = {
  size?: number;
  active?: boolean;
  text?: string;
};

const Bell: FC<BellProps> = ({ size = 24, active = false, text }) => {
  const {
    colors: { primary, outlineVariant, surface },
  } = useAppTheme();
  const { navigate } =
    useNavigation<
      NavigationProp<RootStackParamList, "PushNotificationsSettingsStack">
    >();
  const { container } = styles;
  return (
    <TouchableOpacity
      onPress={() =>
        navigate("PushNotificationsSettingsStack", {
          screen: "PushNotificationsSettings",
        })
      }
      style={[
        {
          borderColor: active ? primary : outlineVariant,
          backgroundColor: surface,
        },
        container,
      ]}>
      {text && active && <Text variant="titleLarge">{text}</Text>}
      {active ? (
        <SvgBellActive width={size} height={size} />
      ) : (
        <SvgBellInactive width={size} height={size} />
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: spacing12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: spacing16,
    padding: spacing16,
  },
});

export default Bell;
