import { FC } from "react";
import { Snackbar as SnackbarPaper, SnackbarProps } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { TranslationKey } from "@globalTypes/i18next";
import { Portal } from "react-native-portalize";

export type Props = Omit<SnackbarProps, "children"> & {
  text: TranslationKey;
};

const Snackbar: FC<Props> = ({ text, ...props }) => {
  const { t } = useTranslation();
  return (
    <Portal>
      <SnackbarPaper {...props}>{t(text)}</SnackbarPaper>
    </Portal>
  );
};

export default Snackbar;
