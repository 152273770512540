import { useMediaStyle } from "@hooks/ui/useMediaStyle";
import { HelpCenterStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { FC, PropsWithChildren } from "react";
import { ScrollView } from "react-native";
import { fontConfig } from "@styles/fontConfig";
import { Text } from "react-native-paper";
import RenderHtml from "react-native-render-html";

export const HelpCenterSelectedTopic: FC<
  PropsWithChildren<
    NativeStackScreenProps<HelpCenterStackParamList, "HelpCenterSelectedTopic">
  >
> = ({
  route: {
    params: { name, subtitle },
  },
}) => {
  const { scrollContainer, gapMedium } = globalStyles;
  const { width } = useMediaStyle();
  const source = {
    html: subtitle,
  };

  return (
    <ScrollView style={[scrollContainer, gapMedium]}>
      <Text variant="titleMedium">{name}</Text>
      <RenderHtml
        contentWidth={width}
        source={source}
        baseStyle={fontConfig.bodyMedium}
      />
    </ScrollView>
  );
};
