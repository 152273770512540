import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import HumanModelModal from "@components/Modals/ModalComponents/HumanModelModal";
import { ScrollFilteringChipsSection } from "@components/Section/ScrollFilteringChipsSection";
import ExerciseTile from "@components/Tile/exercise/ExerciseTile";
import { FetchError } from "@components/errors";
import { HeaderWithSearchbar } from "@components/index";
import useExercisesInfiniteQuery from "@hooks/pagination/useExercisesInfiniteQuery";
import {
  CreateTrainingStackParamsList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { CompositeScreenProps, useFocusEffect } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { useThrottle } from "ahooks";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItem, RefreshControl } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  Condition,
  Exercise,
  GetLibraryExercisesParams,
} from "../../exercise.types";
import TypeFiltersBottomSheetContent from "./AuthorTypeFiltersBottomSheetContent";
import ConditionFiltersBottomSheetContent from "./ConditionFiltersBottomSheetContent";
import NoResultsInfo from "./NoResultsInfo";
import PaginationBottomLoader from "@components/Loader/PaginationBottomLoader";
import { useKeyboard } from "@hooks/ui/useKeyboard";

export type ExercisesFiltersParamsType = {
  name: string;
  conditions: Condition[];
  authorId: number;
  authorName: string;
  humanGeneral: string[];
  humanMuscles: string[];
  humanBones: string[];
};

type ChipListType = { title: string; onPress: () => void; selected: boolean }[];

export const defaultExerciseFilters = {
  conditions: [],
  authorId: null,
  authorName: "",
  humanGeneral: [],
  humanMuscles: [],
  humanBones: [],
};

const transformParams = ({
  name,
  conditions,
  authorId,
  humanGeneral,
  humanMuscles,
  humanBones,
}: ExercisesFiltersParamsType): GetLibraryExercisesParams => {
  return {
    name,
    authorId,
    conditions: conditions.map(({ id }) => id),
    humanGeneral,
    humanMuscles,
    humanBones,
  };
};

const SearchExercises: FC<
  PropsWithChildren<
    CompositeScreenProps<
      NativeStackScreenProps<CreateTrainingStackParamsList, "SearchExercises">,
      NativeStackScreenProps<RootStackParamList, "ExerciseDetails">
    >
  >
> = ({ navigation: { navigate, goBack }, route }) => {
  const [humanModelModalVisible, setHumanModelModalVisible] = useState(false);
  const isFromExerciseList = route.params?.isFromExerciseList;
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [params, setParams] = useState<ExercisesFiltersParamsType>({
    name: "",
    ...defaultExerciseFilters,
  });
  const [conditionsBottomSheetVisible, setConditionsBottomSheetVisible] =
    useState(false);
  const [typeBottomSheetVisible, setTypeBottomSheetVisible] = useState(false);
  const [numberOfBodyParts, setNumberOfBodyParts] = useState<number>(null);
  const throttledQuery = useThrottle(searchQuery, {
    leading: false,
  });
  const keyboard = useKeyboard();
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const conditionFilterActive = useMemo(
    () => !!params?.conditions.length,
    [params?.conditions.length],
  );
  const authorIdFilterActive = useMemo(
    () => !!params?.authorId,
    [params?.authorId],
  );
  const bodyPartsFilterActive = useMemo(
    () =>
      !!params?.humanGeneral?.length ||
      !!params?.humanBones?.length ||
      !!params?.humanMuscles?.length,
    [
      params?.humanBones?.length,
      params?.humanGeneral?.length,
      params?.humanMuscles?.length,
    ],
  );

  const anyFilterActive = useMemo(
    () =>
      conditionFilterActive || authorIdFilterActive || bodyPartsFilterActive,
    [authorIdFilterActive, bodyPartsFilterActive, conditionFilterActive],
  );

  const chipsList: ChipListType = useMemo(
    () => [
      {
        title: conditionFilterActive ? params.conditions[0].name : t("T00328"),
        onPress: () => setConditionsBottomSheetVisible(true),
        selected: conditionFilterActive,
      },
      {
        title: authorIdFilterActive ? params.authorName : t("T00798"),
        onPress: () => setTypeBottomSheetVisible(true),
        selected: authorIdFilterActive,
      },
      {
        title: numberOfBodyParts
          ? t("T01347", { value: numberOfBodyParts })
          : t("T01481"),
        onPress: () => setHumanModelModalVisible(true),
        selected: bodyPartsFilterActive,
      },
    ],
    [
      authorIdFilterActive,
      bodyPartsFilterActive,
      conditionFilterActive,
      numberOfBodyParts,
      params.authorName,
      params.conditions,
      t,
    ],
  );

  const correctThrottledQuery = useMemo(
    () => throttledQuery?.length >= 3,
    [throttledQuery?.length],
  );

  const queryParams = useMemo(() => transformParams(params), [params]);

  useEffect(() => {
    setParams(prevState => ({
      ...prevState,
      name: correctThrottledQuery ? throttledQuery : "",
    }));
  }, [correctThrottledQuery, throttledQuery, setParams]);

  const {
    query: {
      data,
      refetch,
      isInitialLoading,
      isLoadingError,
      isFetching,
      isFetchingNextPage,
    },
    loadMore,
  } = useExercisesInfiniteQuery({
    params: queryParams,
    multiLanguageNameOrdering: true,
  });

  const handleNavigate = useCallback(
    (id: number) => {
      if (isFromExerciseList) {
        setShouldRefetch(true);
        navigate("ExerciseDetails", { id });
      } else
        navigate("EditParameters", {
          exerciseId: id,
        });
    },
    [isFromExerciseList, navigate],
  );

  const renderItem: ListRenderItem<Exercise> = useCallback(
    ({ item: { id } }) => (
      <ExerciseTile exerciseId={id} onPress={() => handleNavigate(id)} />
    ),
    [handleNavigate],
  );
  const { gapMedium, loading, scrollContainer } = globalStyles;

  const clearInput = useCallback(() => setSearchQuery(""), []);
  const clearConditions = useCallback(() => {
    setParams(prevState => ({
      ...prevState,
      conditions: [],
    }));
    setConditionsBottomSheetVisible(false);
  }, []);
  const clearAuthorId = useCallback(() => {
    setParams(prevState => ({
      ...prevState,
      authorId: null,
    }));
    setTypeBottomSheetVisible(false);
  }, []);

  const clearAllFilters = useCallback(() => {
    setParams(prevState => ({
      ...prevState,
      ...defaultExerciseFilters,
    }));
    setNumberOfBodyParts(null);
  }, []);
  const onChangeText = (text: string) => setSearchQuery(text.trim());

  useFocusEffect(() => {
    if (shouldRefetch) {
      void refetch();
      setShouldRefetch(false);
    }
  });

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <HeaderWithSearchbar
        onClear={clearInput}
        onChangeText={onChangeText}
        searchQuery={searchQuery}
        goBack={goBack}
        enableSearching
      />
      <ScrollFilteringChipsSection
        data={chipsList}
        clearAllFilters={clearAllFilters}
      />
      {isInitialLoading ? (
        <ActivityIndicator style={loading} />
      ) : isLoadingError ? (
        <FetchError action={refetch} />
      ) : (
        <FlatList
          data={data.pages.flatMap(page => page.results)}
          renderItem={renderItem}
          keyExtractor={item => item.id.toString()}
          refreshControl={
            <RefreshControl refreshing={isFetching} onRefresh={refetch} />
          }
          ListEmptyComponent={
            !keyboard && (
              <NoResultsInfo
                anyFilterActive={anyFilterActive}
                clearAllFilters={clearAllFilters}
              />
            )
          }
          onEndReached={loadMore}
          ListFooterComponent={() =>
            isFetchingNextPage && <PaginationBottomLoader />
          }
          contentContainerStyle={[
            gapMedium,
            scrollContainer,
            { paddingTop: 0, flexGrow: 1 },
          ]}
        />
      )}
      <HumanModelModal
        isModalVisible={humanModelModalVisible}
        setIsModalVisible={setHumanModelModalVisible}
        humanGeneral={params?.humanGeneral}
        humanMuscles={params?.humanMuscles}
        humanBones={params?.humanBones}
        setBodyParts={setParams}
        clearBodyParts={!numberOfBodyParts}
        setNumberOfBodyParts={setNumberOfBodyParts}
      />
      <BottomModalContainer
        modalVisible={conditionsBottomSheetVisible}
        setModalVisible={setConditionsBottomSheetVisible}
        scrollableContent>
        <ConditionFiltersBottomSheetContent
          onClear={clearConditions}
          params={params}
          setParams={setParams}
          setConditionsBottomSheetVisible={setConditionsBottomSheetVisible}
        />
      </BottomModalContainer>
      <BottomModalContainer
        modalVisible={typeBottomSheetVisible}
        setModalVisible={setTypeBottomSheetVisible}>
        <TypeFiltersBottomSheetContent
          onClear={clearAuthorId}
          params={params}
          setParams={setParams}
          setTypeBottomSheetVisible={setTypeBottomSheetVisible}
        />
      </BottomModalContainer>
    </SafeAreaView>
  );
};

export default SearchExercises;
