import ZoomVideo from "@zoom/videosdk";
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";
import { ZoomClient } from "zoom/utils/types.web";
import {
  AppointmentContextType,
  DataForContextType,
  initialSessionData,
  MediaContext,
} from "./types";

const AppointmentSessionContext = createContext<AppointmentContextType>(
  {} as AppointmentContextType,
);

const AppointmentSessionContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [data, setData] = useState(initialSessionData);

  const zmClient: ZoomClient = ZoomVideo.createClient();

  const [mediaContext, setMediaContext] = useState<MediaContext>(null);

  const [isTabsVisible, setIsTabsVisible] = useState(false);

  const updateData = useCallback((data: DataForContextType) => {
    setData(prevData => ({ ...prevData, ...data }));
  }, []);

  const contextValue = {
    data,
    updateData,
    setData,
    zmClient,
    mediaContext,
    setMediaContext,
    isTabsVisible,
    setIsTabsVisible,
  };

  return (
    <AppointmentSessionContext.Provider value={contextValue}>
      {children}
    </AppointmentSessionContext.Provider>
  );
};
export { AppointmentSessionContext, AppointmentSessionContextProvider };
