import Svg, { SvgProps, G, Rect, Path, Defs, ClipPath } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={48} height={48} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Rect width={24} height={24} x={12} y={12} fill="#fff" rx={12} />
      <Path
        fill="#4ACB29"
        d="M24 14c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10Zm-2 15-5-5 1.41-1.41L22 26.17l7.59-7.59L31 20l-9 9Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Rect width={40} height={40} x={4} y={4} fill="#fff" rx={8} />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgComponent;
