import { useAuth } from "@contexts/AuthContext/auth";
import { getDayName, getFormattedDayWithMonth } from "@utils/date";
import { isToday, isTomorrow } from "date-fns";
import { useTranslation } from "react-i18next";

type UseUpcomingAppointmentDateOptions<T extends object> = {
  data: T[];
};

type ConvertedData = { date: string };

export const useUpcomingAppointmentDate = <T extends object>({
  data,
}: UseUpcomingAppointmentDateOptions<T>) => {
  const {
    user: { language },
  } = useAuth();
  const { t } = useTranslation();

  const getChipTitle = (date: Date): string => {
    switch (true) {
      case isToday(date):
        return t("T01004");
      case isTomorrow(date):
        return t("T01005");
      default:
        return `${getDayName(date, language)} ${getFormattedDayWithMonth(
          date,
        )}`;
    }
  };

  const convertData = (data: T[]): ConvertedData[] => {
    const unifiedData: ConvertedData[] = [];

    data?.forEach(item => {
      let date: string;
      if ("dateTo" in item) {
        date = item.dateTo as string;
      }
      if ("executionDate" in item) {
        date = item.executionDate as string;
      }

      if (date) {
        unifiedData.push({ date });
      }
    });
    return unifiedData;
  };

  const convertedData = data?.[0] !== undefined && convertData(data);

  const firstUpcomingDate = convertedData?.length
    ? getChipTitle(new Date(convertedData[0].date))
    : null;

  const secondUpcomingDate =
    convertedData?.length > 1 && convertedData[1]
      ? getChipTitle(new Date(convertedData[1].date))
      : null;

  const twoChipsEqual = firstUpcomingDate === secondUpcomingDate;

  return {
    firstUpcomingDate,
    secondUpcomingDate,
    twoChipsEqual,
  };
};
