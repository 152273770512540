import PostContent from "@components/Forum/PostContent";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { ForumStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { queryKeysForumTopics } from "@screens/Profiles/MyProfile/Forum/queryKeysForum";
import { getTopic } from "@services/ApiService/forum";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import Snackbar from "@components/Snackbar/Snackbar";
import { Host } from "react-native-portalize";

const TopicPreview: FC<
  PropsWithChildren<
    NativeStackScreenProps<ForumStackParamsList, "TopicPreview">
  >
> = ({ navigation: { navigate }, route }) => {
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  const { t } = useTranslation();
  const { gapLarge, scrollContainer, loading } = globalStyles;
  const { id: topicId } = route.params;

  const toggleSnackBar = () => setSnackbarVisible(prev => !prev);
  const onBlockSuccess = () => navigate("Forum");

  const {
    data: topicDetails,
    isLoading: isTopicDetailsLoading,
    isError: isTopicDetailsError,
    refetch: refetchTopicDetails,
  } = useQuery({
    queryFn: async () => await getTopic(topicId),
    queryKey: queryKeysForumTopics.details(topicId),
  });

  if (isTopicDetailsLoading) return <ActivityIndicator style={loading} />;
  if (isTopicDetailsError) return <FetchError action={refetchTopicDetails} />;

  const { title, posts } = topicDetails;

  return (
    <Host>
      <SafeAreaView edges={["right", "bottom", "left"]} style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={[scrollContainer, gapLarge]}>
          {posts?.length && (
            <PostContent
              title={title}
              postId={posts[0]}
              key={`${posts[0]}-0`}
              toggleSnackBar={toggleSnackBar}
              onBlockSuccess={onBlockSuccess}
            />
          )}
          <Text variant="titleMedium">
            {t("T00878", { value: posts?.length - 1 })}
          </Text>
          <PrimaryButton
            label="T00879"
            onPress={() => navigate("CreateThread", { topicId })}
          />
          {posts?.length > 1 && (
            <>
              {posts.slice(1).map((postId, index) => (
                <PostContent
                  postId={postId}
                  key={`${postId}-${index}`}
                  toggleSnackBar={toggleSnackBar}
                  onBlockSuccess={onBlockSuccess}
                />
              ))}
            </>
          )}
        </ScrollView>
        <Snackbar
          visible={snackbarVisible}
          onDismiss={toggleSnackBar}
          onIconPress={toggleSnackBar}
          text="T00560"
          style={{}}
        />
      </SafeAreaView>
    </Host>
  );
};

export default TopicPreview;
