import { yupResolver } from "@hookform/resolvers/yup";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ActivityIndicator, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { number, object } from "yup";
import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { useErrors } from "@hooks/useErrors";
import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import {
  getExperience,
  setExperience,
  updateExperience,
} from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { MAX_EXPERIENCE_VALUE } from "@utils/constants";
import { queryKeysVerficiations } from "./queryKeysPhysiotherapistVerification";
import { ExperienceApiType } from "./verification.types";
import InfoTile from "@components/Tile/InfoTile";
import { AdditionalExperience } from "@components/index";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import {
  AddExperienceBottomSheet,
  EditExperienceBottomSheet,
} from "@components/BottomSheetContents";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import { useInvalidateProfileDetails } from "@hooks/index";

const Experience: FC<
  PropsWithChildren<
    NativeStackScreenProps<ProfileCompletionStackParamList, "Experience">
  >
> = () => {
  const [addExperienceModalVisible, setAddExperienceModalVisible] =
    useState(false);
  const [editExperienceModalVisible, setEditExperienceModalVisible] =
    useState(false);
  const [currentIdToEdit, setCurrentIdToEdit] = useState<number>(null);
  const { t } = useTranslation();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();

  const { loading, scrollContainer, gapLarge } = globalStyles;

  const schema = object().shape({
    years: number()
      .typeError(t("T00025"))
      .max(
        MAX_EXPERIENCE_VALUE,
        t("T00024", {
          max: MAX_EXPERIENCE_VALUE,
        }),
      ),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors: formErrors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onUpdateExperienceSuccess = async () => {
    await Promise.all([
      invalidateProfileDetails(),
      queryClient.invalidateQueries({
        queryKey: queryKeysVerficiations.experience(),
      }),
    ]);
    showProfileVerificationSnackbar(t("T01311"));
  };

  const { errors, setErrorsFromResponse, clearErrors } = useErrors();
  const queryClient = useQueryClient();

  const {
    data: experienceData,
    refetch: refetchExperience,
    isLoading: isExperienceLoading,
    isError: isErrorExperience,
  } = useQuery({
    queryKey: queryKeysVerficiations.experience(),
    queryFn: getExperience,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: ExperienceApiType) =>
      experienceData?.length > 0
        ? updateExperience(data, experienceData[0].id)
        : setExperience(data),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: onUpdateExperienceSuccess,
  });

  const onSubmit = (data: ExperienceApiType) => {
    mutate(data);
  };

  if (isExperienceLoading) return <ActivityIndicator style={loading} />;
  if (isErrorExperience) return <FetchError action={refetchExperience} />;

  return (
    <>
      <SafeAreaView style={{ flex: 1 }} edges={["right", "bottom", "left"]}>
        <KeyboardAwareScrollView
          contentContainerStyle={[scrollContainer, gapLarge]}>
          {!experienceData?.length && (
            <InfoTile
              status="info"
              content={<Text variant="bodyMedium">{t("T01189")}</Text>}
            />
          )}
          <Text variant="bodyMedium">{t("T00266")}</Text>
          <Input
            name="years"
            label="T00267"
            control={control as unknown as Control<FieldValues>}
            errors={errors?.years}
            defaultValue={experienceData?.[0]?.years.toString()}
            keyboardType="numeric"
            extraOnChange={() => {
              if (errors.length || Object.keys(formErrors).length) {
                clearErrors();
                reset();
              }
            }}
          />
          <PrimaryButton
            label="T00164"
            onPress={handleSubmit(onSubmit)}
            disabled={isLoading}
            loading={isLoading}
          />
          {experienceData?.length ? (
            <AdditionalExperience
              onPressAddExperience={setAddExperienceModalVisible}
              onPressEditExperience={setEditExperienceModalVisible}
              setCurrentIdToEdit={setCurrentIdToEdit}
              data={experienceData?.[0].additionalExperience}
            />
          ) : null}
        </KeyboardAwareScrollView>
      </SafeAreaView>
      <BottomModalContainer
        modalVisible={addExperienceModalVisible}
        setModalVisible={setAddExperienceModalVisible}>
        <AddExperienceBottomSheet
          onSave={async () => {
            await onUpdateExperienceSuccess();
            setAddExperienceModalVisible(false);
          }}
          experience={experienceData?.[0]?.id}
        />
      </BottomModalContainer>
      <BottomModalContainer
        modalVisible={editExperienceModalVisible}
        setModalVisible={setEditExperienceModalVisible}>
        <EditExperienceBottomSheet
          data={experienceData?.[0]?.additionalExperience?.filter(
            e => e.id === currentIdToEdit,
          )}
          onSuccess={async () => {
            await onUpdateExperienceSuccess();
            setEditExperienceModalVisible(false);
          }}
        />
      </BottomModalContainer>
    </>
  );
};

export default Experience;
