import Avatar from "@components/Avatar";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { theme } from "@styles/theme";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { RatingStars } from "@components/Rating/RatingStars";
import { spacing8 } from "@styles/spacing";
import { PublicPhysiotherapistFullProfile } from "@contexts/AuthContext/user.types";
import { Text } from "react-native-paper";

type Props = {
  data: PublicPhysiotherapistFullProfile;
};

export const PhysiotherapistDataWithRating: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { contentContainer, ratingContainer, ratingText } = styles;
  const { firstName, id, lastName, avgRating, licence, ratingsCount } = data;
  return (
    <View style={contentContainer}>
      <Avatar firstName={firstName} userId={id} image={data.avatar} />
      <View style={[globalStyles.gapSmall, { flex: 1 }]}>
        <Text variant="titleMedium">{`${firstName} ${lastName}`}</Text>
        {licence?.specialization && (
          <Text variant="bodyMedium">{licence?.specialization?.name}</Text>
        )}
        <View style={ratingContainer}>
          <RatingStars rating={+avgRating} />
          <Text style={ratingText}>{`(${ratingsCount} ${t(
            "T00373",
          ).toLowerCase()})`}</Text>
        </View>
      </View>
    </View>
  );
};

export default PhysiotherapistDataWithRating;

const styles = StyleSheet.create({
  containerOutlined: {
    borderColor: theme.colors.outlineVariant,
  },
  flat: {
    backgroundColor: palettes.primary[100],
  },
  contentContainer: {
    flexDirection: "row",
    alignItems: "center",
    ...globalStyles.gapLarge,
  },
  ratingContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  ratingText: { color: palettes.primary[40], marginLeft: spacing8 },
});
