import { FC } from "react";
import { ActivityIndicator } from "react-native-paper";
import {
  PricesType,
  ServiceDetails,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { FetchError } from "@components/errors";
import ServiceTile from "@components/Tile/service/ServiceTile";
import { usePhysioServiceDetails } from "@hooks/queryHooks/usePhysioServiceDetails";

type ServiceTileWithQueryProps = ServiceDetails & {
  payment?: PricesType;
};

const ServiceTileWithQuery: FC<ServiceTileWithQueryProps> = ({
  physioId,
  serviceId,
  serviceType,
  payment,
}) => {
  const { data, isError, isLoading, refetch } = usePhysioServiceDetails({
    physioId,
    serviceType,
    serviceId,
  });

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  if (data?.length) {
    const { serviceType, serviceItem, slots, prices } = data[0];

    return (
      <ServiceTile
        service={{
          serviceType,
          serviceItem,
          slots,
          prices,
        }}
        payment={payment}
      />
    );
  }
  return null;
};

export default ServiceTileWithQuery;
