import { FC, useMemo } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { DataForBottomSection, PlayerMode } from "../player.types";
import { palettes } from "@styles/colors";
import { useTranslation } from "react-i18next";
import { SvgChevronDown, SvgChevronUp } from "@assets/svg";
import { spacing12 } from "@styles/spacing";

import { setExerciseTileSubtitles } from "@components/Tile/exercise/exerciseTileHelpers";
import { getDataForBottomSection } from "../player.helpers";
import { CtxExerciseType } from "@screens/TrainingsAndExercises/exercise.types";

type VideoBottomSectionProps = {
  currentExercise: CtxExerciseType;
  currentExerciseIndex: number;
  currentSeriesIndex: number;
  numberOfExercises: number;
  extraDataVisible: boolean;
  playerMode: PlayerMode;
  onSectionPress: () => void;
};

const VideoBottomSection: FC<VideoBottomSectionProps> = ({
  currentExercise,
  currentExerciseIndex,
  currentSeriesIndex,
  extraDataVisible,
  playerMode,
  numberOfExercises,
  onSectionPress,
}) => {
  const { series, name: exerciseName } = currentExercise;

  const { t } = useTranslation();
  const { exerciseText, serieText } = getDataForBottomSection(
    playerMode,
  ) as DataForBottomSection;
  const { infoAboutExerciseContainer, seriesDataTextStyle } = styles;

  const seriesValues = useMemo(
    () => setExerciseTileSubtitles(series),
    [series],
  );

  return (
    <Pressable
      style={{
        backgroundColor: palettes.primary[100],
      }}
      onPress={onSectionPress}>
      <View style={infoAboutExerciseContainer}>
        {exerciseText && (
          <Text variant="titleMedium" style={{ color: palettes.primary[40] }}>
            {`${t(exerciseText)} ${
              currentExerciseIndex + 1
            }/${numberOfExercises}`}
          </Text>
        )}
        {serieText && (
          <Text variant="titleMedium" style={{ color: palettes.primary[40] }}>
            {`${t(serieText)} ${currentSeriesIndex + 1}/${series.length}`}
          </Text>
        )}
        {extraDataVisible ? <SvgChevronDown /> : <SvgChevronUp />}
      </View>
      {extraDataVisible && exerciseName && (
        <View style={{ marginHorizontal: spacing12 }}>
          <Text
            variant="titleMedium"
            style={{ textAlign: "center" }}
            numberOfLines={2}>
            {exerciseName}
          </Text>
          {!!seriesValues?.length && (
            <Text
              variant="titleMedium"
              numberOfLines={1}
              style={seriesDataTextStyle}>
              {seriesValues}
            </Text>
          )}
        </View>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  infoAboutExerciseContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginHorizontal: spacing12,
    gap: spacing12,
  },
  seriesDataTextStyle: { color: palettes.primary[40], textAlign: "center" },
});

export default VideoBottomSection;
