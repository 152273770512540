import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { DefaultTileProps } from "@components/Tile/types/Tile.types";
import { TrainingCategoryTile } from "../TrainingCategoryTile";
import { getTrainingCategory } from "@services/ApiService/trainings";
import { queryKeysWorkoutCategories } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { ActivityIndicator } from "react-native-paper";
import { FetchError } from "@components/errors";
import { globalStyles } from "@styles/global";

type Props = DefaultTileProps & {
  trainingId: number;
  onPress: () => void;
};

const ProphylacticCategoryTile: FC<Props> = ({ trainingId, onPress }) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysWorkoutCategories.detail(trainingId),
    queryFn: async () => await getTrainingCategory(trainingId),
  });

  if (isLoading) return <ActivityIndicator style={globalStyles.loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <TrainingCategoryTile
      title={data.name}
      subtitle={data.description}
      uri={data.image}
      onPress={onPress}
    />
  );
};

export default ProphylacticCategoryTile;
