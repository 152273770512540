import { SvgArticle } from "@assets/svg";
import { FetchError } from "@components/errors";
import { queryKeysForumTopics } from "@screens/Profiles/MyProfile/Forum/queryKeysForum";
import { getTopic } from "@services/ApiService/forum";
import { palettes } from "@styles/colors";
import { fontConfig } from "@styles/fontConfig";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { getDate } from "@utils/date";
import { FC } from "react";
import { View } from "react-native";
import { ActivityIndicator, List, Text } from "react-native-paper";

type TopicItemType = {
  id: number;
  onPress: () => void;
};

const TopicListItem: FC<TopicItemType> = ({ id, onPress }) => {
  const { loading } = globalStyles;

  const {
    data: topicDetails,
    isLoading: isTopicDetailsLoading,
    isError: isTopicDetailsError,
    refetch: refetchTopicDetails,
  } = useQuery({
    queryFn: async () => await getTopic(id),
    queryKey: queryKeysForumTopics.details(id),
  });

  if (isTopicDetailsLoading) return <ActivityIndicator style={loading} />;
  if (isTopicDetailsError) return <FetchError action={refetchTopicDetails} />;

  const { createdAt, title, userName } = topicDetails;

  return (
    <List.Item
      title={title}
      titleNumberOfLines={2}
      titleStyle={fontConfig.bodyLarge}
      description={() => (
        <View>
          <Text variant="bodyMedium">{getDate(createdAt)}</Text>
          <Text variant="bodyMedium">{userName}</Text>
        </View>
      )}
      left={props => <List.Icon {...props} icon={() => <SvgArticle />} />}
      right={props => <List.Icon {...props} icon="menu-right" />}
      style={{ backgroundColor: palettes.primary[99] }}
      onPress={onPress}
    />
  );
};

export default TopicListItem;
