import { FC, useEffect, useState } from "react";
import DropdownPicker, {
  MultiDropdownsType,
  OnPickerValueChange,
  PickerItem,
} from "@components/DropdownPicker/DropdownPicker";
import { useQuery } from "@tanstack/react-query";
import { ActivityIndicator } from "react-native-paper";
import { getPhysiotherapistServices } from "@api/appointments";
import { FetchError } from "@components/errors";
import { queryKeysPhysiotherapistProfileData } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { ServiceType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";

type ServiceDropdownPickerProps = {
  physioId: number;
  onValueChange: OnPickerValueChange;
  selectedServiceType: ServiceType;
} & MultiDropdownsType;

const ServiceDropdownPicker: FC<ServiceDropdownPickerProps> = props => {
  const { physioId, selectedServiceType, changeLabelZIndex } = props;
  const [items, setItems] = useState<PickerItem[]>();
  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapistProfileData.services(
      physioId,
      selectedServiceType,
    ),
    queryFn: async () =>
      await getPhysiotherapistServices(physioId, {
        serviceType: selectedServiceType,
        isArchived: false,
      }),
  });

  useEffect(() => {
    if (!isError && !isLoading) {
      const items: PickerItem[] = data?.map(({ serviceItem, id }) => ({
        label: serviceItem.name,
        value: `${id}`,
      }));
      setItems(items);
    }
  }, [data, isError, isLoading, selectedServiceType]);

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    items?.length > 0 && (
      <DropdownPicker
        dropdownLabelText="T00608"
        dropdownLabelContainerStyle={changeLabelZIndex && { zIndex: 1 }}
        items={items}
        {...props}
      />
    )
  );
};

export default ServiceDropdownPicker;
