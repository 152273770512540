import { getPhysiotherapistServices } from "@api/appointments";
import { ServiceDetails } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { queryKeysPhysiotherapistProfileData } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { useQuery } from "@tanstack/react-query";

export const usePhysioServiceDetails = ({
  physioId,
  serviceType,
  serviceId,
  queryEnabled = true,
}: ServiceDetails) => {
  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapistProfileData.serviceDetails(
      physioId,
      serviceType,
      serviceId,
    ),
    queryFn: async () =>
      await getPhysiotherapistServices(physioId, {
        serviceType,
        isArchived: false,
      }),
    select: data => data.filter(({ id }) => id === serviceId),
    enabled: queryEnabled,
  });
  return { data, isError, isLoading, refetch };
};
