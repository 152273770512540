import Svg, { Path, SvgProps } from "react-native-svg";

export const ProductLogo = (props: SvgProps) => (
  <Svg width={980} height={280} fill="none" viewBox="0 0 980 280" {...props}>
    <Path
      d="M234.66 0H44.5796C19.959 0 0 19.959 0 44.5796V234.66C0 259.281 19.959 279.24 44.5796 279.24H234.66C259.281 279.24 279.24 259.281 279.24 234.66V44.5796C279.24 19.959 259.281 0 234.66 0Z"
      fill="#4666FF"
    />
    <Path
      d="M114.988 53.5085C114.988 40.3022 124.865 31.3069 138.777 31.3069C152.689 31.3069 162.059 40.3022 162.059 53.5085C162.059 66.7148 152.435 75.4456 138.777 75.4456C125.119 75.4456 114.988 66.1637 114.988 53.5085Z"
      fill="white"
    />
    <Path
      d="M182.585 174.537L162.378 149.095L223.879 73.1858H180.6L140.375 124.92L100.15 73.1858H55.1072L116.84 149.932L54.104 228.09H97.3608L138.865 174.956L158.707 200.685L182.585 174.537Z"
      fill="white"
    />
    <Path
      d="M203.959 201.435H159.292L179.851 228.09H225.136L203.959 201.435Z"
      fill="white"
    />
    <Path
      d="M373.302 102.574V134.564H455.406V161.815H373.302V213.549H344.861V75.3232H470.685V102.574H373.291H373.302Z"
      fill="black"
    />
    <Path d="M510.767 213.56H483.219V104.757H510.767V213.56Z" fill="black" />
    <Path
      d="M599.849 158.068L643.932 213.55H612.118L583.324 176.235L554.167 213.55H523.786L567.858 158.652L524.502 104.747H556.14L584.394 141.081L612.647 104.747H643.039L599.838 158.068H599.849Z"
      fill="black"
    />
    <Path
      d="M848.157 150.363V213.551H820.785V151.939C820.785 134.952 813.135 125.086 797.15 125.086C783.106 125.086 772.623 133.376 765.876 147.993V192.033L766.053 213.551H738.505V151.939C738.505 134.952 730.854 125.086 714.87 125.086C700.826 125.086 690.695 132.99 683.772 147.398V213.551H656.224V104.747H683.772V124.105C691.423 109.487 705.985 100.801 723.931 100.801C745.075 100.801 757.873 112.453 763.032 129.826C769.779 112.64 785.609 100.801 806.212 100.801C835.006 100.801 848.157 122.33 848.157 150.363Z"
      fill="black"
    />
    <Path
      d="M924 194.598C940.888 194.598 951.548 189.66 961.678 180.576L976.428 198.545C962.737 211.972 945.154 217.891 922.577 217.891C882.242 217.891 858.96 195.381 858.96 159.246C858.96 123.11 882.066 100.401 920.637 100.401C956.178 100.401 980 120.542 980 159.047C980 162.597 979.812 164.383 979.283 166.157H885.45C887.941 185.713 901.269 194.598 924.022 194.598H924ZM886.684 146.613L953.675 146.216C949.42 130.617 938.22 123.705 920.803 123.705C903.385 123.705 890.587 131.212 886.684 146.613Z"
      fill="black"
    />
    <Path
      d="M567.34 90.9217C567.34 81.6398 574.274 75.3232 584.052 75.3232C593.83 75.3232 600.411 81.6398 600.411 90.9217C600.411 100.204 593.654 106.333 584.052 106.333C574.451 106.333 567.34 99.8178 567.34 90.9217Z"
      fill="#4666FF"
    />
  </Svg>
);
