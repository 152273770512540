import Bell from "@components/Bell";
import { TransKey } from "@globalTypes/i18next";
import { useAppointmentsNotificationsSettings } from "@hooks/notifications/useAppointmentsNotificationsSettings";
import { useCheckNotificationsForBell } from "@hooks/notifications/useCheckNotificationsForBell";
import { globalStyles } from "@styles/global";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";
import { MD3TypescaleKey, Text } from "react-native-paper";
import { VariantProp } from "react-native-paper/lib/typescript/components/Typography/types";

type AppointmentTitleSectionProps = {
  title: TransKey;
  variant?: VariantProp<MD3TypescaleKey>;
};

const AppointmentTitleSection: FC<AppointmentTitleSectionProps> = ({
  title,
  variant,
}) => {
  const { switchOn: specificPushNotificationsEnabled } =
    useAppointmentsNotificationsSettings();
  const { enabled } = useCheckNotificationsForBell({
    specificPushNotificationsEnabled,
  });
  const { t } = useTranslation();

  return (
    <View style={[styles.headerContainer, globalStyles.gapLarge]}>
      <View style={{ flex: 1 }}>
        <Text variant={variant || "headlineSmall"}>{t(title)}</Text>
      </View>
      <Bell active={enabled} />
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default AppointmentTitleSection;
