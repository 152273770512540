import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";
import { TrainingSelectionAssistantStackParamsList } from "./navigation.types";
import {
  DailyTimeForTraining,
  ExerciseForSpecificGroup,
  InjuriesOrMedicalConditions,
  TrainingAssistantSummary,
  TrainingSelectionAssistantContextProvider,
  TrainingSelectionModel,
} from "@screens/TrainingsAndExercises/Patient/TrainingSelectionAssistant";
import { AppbarTitleWithBackAction } from "@components/Appbar";
import { useTranslation } from "react-i18next";

const Stack =
  createNativeStackNavigator<TrainingSelectionAssistantStackParamsList>();

const TrainingSelectionAssistantStackNavigator: FC<
  PropsWithChildren<
    NativeStackScreenProps<TrainingSelectionAssistantStackParamsList>
  >
> = () => {
  const { t } = useTranslation();

  return (
    <TrainingSelectionAssistantContextProvider>
      <Stack.Navigator
        screenOptions={{
          header: ({ navigation: { popToTop, goBack, pop, getState } }) => {
            const { index } = getState();

            return (
              <AppbarTitleWithBackAction
                onBack={goBack}
                onClose={() => {
                  index > 0 && popToTop();
                  pop();
                }}
                title={t("T00114")}
              />
            );
          },
        }}>
        <Stack.Screen
          name="InjuriesOrMedicalConditions"
          component={InjuriesOrMedicalConditions}
        />
        <Stack.Screen
          name="ExerciseForSpecificGroup"
          component={ExerciseForSpecificGroup}
        />
        <Stack.Screen
          name="TrainingSelectionModel"
          component={TrainingSelectionModel}
        />
        <Stack.Screen
          name="DailyTimeForTraining"
          component={DailyTimeForTraining}
        />
        <Stack.Screen
          name="TrainingAssistantSummary"
          component={TrainingAssistantSummary}
        />
      </Stack.Navigator>
    </TrainingSelectionAssistantContextProvider>
  );
};

export default TrainingSelectionAssistantStackNavigator;
