import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { format } from "date-fns";
import { spacing32, spacing8 } from "@styles/spacing";
import { useAuth } from "@contexts/AuthContext/auth";
import { getDayName } from "@utils/date";
import { AvailableAppointmentsColumns } from "./PhysioAvailability.types";

type PhysioAvailabilityHeaderProps = {
  item: AvailableAppointmentsColumns;
};

const PhysioAvailabilityHeader: FC<PhysioAvailabilityHeaderProps> = ({
  item,
}) => {
  const {
    user: { language },
  } = useAuth();
  return (
    <View style={styles.headerContainer}>
      {item
        ?.map(({ date }) => date)
        ?.map((date, index) => {
          const dateFromString = new Date(date);
          const isFirst = index === 0;
          const isLast = index === item.length - 1;
          return (
            <View
              key={index}
              style={[
                isFirst && styles.firstColumn,
                isLast && styles.lastColumn,
              ]}>
              <Text style={styles.shortDayText} variant="titleMedium">
                {getDayName(dateFromString, language, "iii").toUpperCase()}
              </Text>
              <Text variant="bodySmall">{format(dateFromString, "dd.MM")}</Text>
            </View>
          );
        })}
    </View>
  );
};

export default PhysioAvailabilityHeader;

const styles = StyleSheet.create({
  firstColumn: { marginStart: spacing8 },
  lastColumn: { marginEnd: spacing8 },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: spacing32,
    alignItems: "center",
  },
  shortDayText: { textAlign: "center" },
});
