/* eslint-disable react-hooks/exhaustive-deps */
import { getAppointmentsList } from "@api/appointments";
import { getTrainingDays } from "@api/trainings";
import { AbsoluteShowCalendarButton } from "@components/Button";
import { Calendar, DateArrayType } from "@components/Calendar";
import { FetchError } from "@components/errors";
import {
  CalendarStackParamList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { spacing16 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RefreshControl } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { queryKeysAppointments } from "../Appointments/queryKeysAppointments";
import { queryKeysTrainingDay } from "../TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { getEmptyMonthForPatient } from "./calendarUtils";
import { FlashList } from "@shopify/flash-list";
import { PatientCalendarItem } from "./PatientCalendarItem";
import { TrainingDay } from "../TrainingsAndExercises/training.types";
import { Appointment } from "@screens/Appointments/appointment.types";
import { ActivityIndicator } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { formatDateForApi, formatDateWithDayName } from "@utils/date";
import { useAuth } from "@contexts/AuthContext/auth";
import { useIsFocused } from "@react-navigation/native";
import { isANDROID } from "@utils/constants";

export type PatientCalendarItem =
  | string
  | { date: string; appointments: Appointment[]; trainings: TrainingDay[] };

export type CalendarFilter = {
  dateFrom: string;
  dateTo: string;
};

const PatientCalendar: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      CalendarStackParamList &
        Pick<
          RootStackParamList,
          "AppointmentDetails" | "AssignedTrainingDetails"
        >,
      "Calendar"
    >
  >
> = ({ navigation: { navigate } }) => {
  const firstDayOfTheMonth = new Date();
  firstDayOfTheMonth.setHours(0, 0, 0, 0);
  firstDayOfTheMonth.setDate(1);
  const [calendarDates, setCalendarDates] = useState<DateArrayType[]>();
  const flashListRef = useRef<FlashList<PatientCalendarItem>>(null);
  const [monthMap, setMonthMap] = useState<PatientCalendarItem[]>([]);
  const [currentMonth, setCurrentMonth] = useState<Date>(firstDayOfTheMonth);
  const [scrollValue, setScrollValue] = useState<number>(0);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [currentDate, setCurrentDate] = useState<DateArrayType>({
    date: today,
  });

  const isFocused = useIsFocused();

  const {
    data: scheduledAppointments,
    isFetched: scheduledAppointmentsFetched,
    isLoading: isScheduledAppointmentsLoading,
    isError: isScheduledAppointmentsError,
    refetch: refetchScheduledAppointments,
  } = useQuery({
    queryKey: queryKeysAppointments.list(),
    queryFn: async () =>
      await getAppointmentsList(formatDateForApi(currentMonth)),
    refetchIntervalInBackground: true,
    refetchInterval: 120000,
  });

  useEffect(() => {
    isFocused && void refetchScheduledAppointments();
  }, [isFocused, currentMonth]);

  const {
    data: trainingDays,
    isLoading: isTrainingDaysLoading,
    isError: isTrainingDaysError,
    refetch: refetchTrainingDays,
  } = useQuery({
    queryKey: queryKeysTrainingDay.list(),
    queryFn: async () =>
      await getTrainingDays({ dateFrom: formatDateForApi(currentMonth) }),
  });

  const {
    user: { language },
  } = useAuth();

  useEffect(() => {
    if (scheduledAppointments) {
      const resultMonth = getEmptyMonthForPatient(
        currentMonth,
        scheduledAppointments,
        trainingDays,
        language,
      );
      setMonthMap(resultMonth);
    }
  }, [scheduledAppointments, currentMonth, trainingDays, language]);

  useEffect(() => {
    if (scheduledAppointmentsFetched) {
      setCalendarDates(
        scheduledAppointments?.map(a => ({
          date: new Date(a.dateFrom),
          eventType: "blue",
        })),
      );
    }

    if (trainingDays) {
      const tempTrainingDays: DateArrayType[] = trainingDays?.map(e => ({
        date: new Date(e.executionDate),
        eventType: "blue",
      }));
      setCalendarDates(prevState => {
        const temp = prevState
          ? [...prevState, ...tempTrainingDays]
          : [...tempTrainingDays];
        return temp;
      });
    }
  }, [scheduledAppointmentsFetched, scheduledAppointments, trainingDays]);

  const renderItem = useCallback(
    ({ item }: { item: PatientCalendarItem }) => {
      const navToAppointment = (id: number) =>
        navigate("AppointmentDetails", {
          id,
        });
      const navToTraining = (id: number) =>
        navigate("AssignedTrainingDetails", { id });

      return (
        <PatientCalendarItem
          item={item}
          navToAppointment={navToAppointment}
          navToTraining={navToTraining}
        />
      );
    },
    [navigate],
  );

  const navToIndex = useCallback(
    (date: string) => {
      const foundIndex = monthMap.findIndex(e => {
        if (typeof e === "string") {
          return e === date;
        } else {
          return e.date === date;
        }
      });

      if (foundIndex !== -1) {
        flashListRef?.current?.scrollToIndex({
          animated: true,
          index: foundIndex,
        });
      }
    },
    [monthMap],
  );

  useEffect(() => {
    !isANDROID &&
      monthMap?.length &&
      setTimeout(() => {
        navToIndex(formatDateWithDayName(language, new Date()));
      }, 1250);
  }, [language, monthMap?.length, navToIndex]);

  const isAnyLoading = useMemo(
    () => isTrainingDaysLoading || isScheduledAppointmentsLoading,
    [isTrainingDaysLoading, isScheduledAppointmentsLoading],
  );
  const isAnyError = useMemo(
    () => isTrainingDaysError || isScheduledAppointmentsError,
    [isTrainingDaysError, isScheduledAppointmentsError],
  );
  const refetchAllData = async () =>
    await Promise.all([refetchTrainingDays(), refetchScheduledAppointments()]);

  if (isAnyLoading) return <ActivityIndicator style={globalStyles.loading} />;
  if (isAnyError) return <FetchError action={refetchAllData} />;

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "left"]}>
      {scrollValue > 0 && (
        <AbsoluteShowCalendarButton
          onPress={() =>
            flashListRef.current.scrollToOffset({
              animated: true,
              offset: 0,
            })
          }
        />
      )}
      <FlashList
        ref={flashListRef}
        data={monthMap}
        renderItem={renderItem}
        contentContainerStyle={{ padding: spacing16 }}
        getItemType={item =>
          typeof item === "string" ? "sectionHeader" : "row"
        }
        onScroll={({ nativeEvent }) =>
          setScrollValue(nativeEvent.contentOffset.y)
        }
        estimatedItemSize={spacing16 * 3}
        ListHeaderComponent={
          <Calendar
            onSelectDate={(data: DateArrayType) => {
              const date = new Date(data.date);
              setCurrentDate(data);
              navToIndex(formatDateWithDayName(language, date));
            }}
            onChangeMonth={setCurrentMonth}
            datesWithEvents={calendarDates}
            initialDates={[{ date: currentDate.date }]}
            containerStyle={{ marginBottom: spacing16 }}
          />
        }
        refreshControl={
          <RefreshControl
            refreshing={isScheduledAppointmentsLoading}
            onRefresh={refetchScheduledAppointments}
          />
        }
      />
    </SafeAreaView>
  );
};

export default PatientCalendar;
