import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      fill="#4666FF"
      d="M13.446 0H2.554A2.554 2.554 0 0 0 0 2.554v10.892A2.554 2.554 0 0 0 2.554 16h10.892A2.554 2.554 0 0 0 16 13.446V2.554A2.554 2.554 0 0 0 13.446 0Z"
    />
    <Path
      fill="#fff"
      d="M6.588 3.066c0-.757.566-1.272 1.363-1.272s1.334.515 1.334 1.272-.551 1.257-1.334 1.257-1.363-.532-1.363-1.257Z"
    />
    <Path
      fill="#fff"
      d="M10.461 10 9.304 8.544l3.524-4.35h-2.48L8.043 7.158 5.738 4.193h-2.58l3.536 4.398L3.1 13.069h2.478l2.378-3.044 1.137 1.474L10.461 10ZM11.686 11.542H9.127l1.178 1.527H12.9l-1.214-1.527Z"
    />
  </Svg>
);
export default SvgComponent;
