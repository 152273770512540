import { TrainingDay } from "@screens/TrainingsAndExercises/training.types";
import { DateWithEvent } from "@screens/TrainingsAndExercises/TrainingDetailsComponents/types";

export const getDatesWithEvents = (
  trainingDays: TrainingDay[],
): DateWithEvent[] =>
  trainingDays?.map(({ executionDate, isCompleted }) => {
    const trainingDate = new Date(executionDate);
    trainingDate.setHours(0, 0, 0, 0);
    const result = {
      date: trainingDate,
    };
    let eventType = "green";

    if (!isCompleted) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      eventType = today.getTime() > trainingDate.getTime() ? "red" : "blue";
    }
    return { ...result, eventType };
  });
