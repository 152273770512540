import { getLocales } from "expo-localization";
import i18next, { Module } from "i18next";
import { initReactI18next } from "react-i18next";

import enBase from "@assets/translations/en.json";
import noBase from "@assets/translations/no.json";
import deBase from "@assets/translations/de.json";
import esBase from "@assets/translations/es.json";
import ruBase from "@assets/translations/ru.json";
import frBase from "@assets/translations/fr.json";
import itBase from "@assets/translations/it.json";
import plBase from "@assets/translations/pl.json";

import plGymsy from "@assets/translations/gymsy/pl.json";
import enGymsy from "@assets/translations/gymsy/en.json";
import { flags } from "@utils/flags";
import { ProductName } from "@globalTypes/common.types";
import { fallbackLanguage } from "@utils/constants";

type MergeOptions = {
  appendKeyToValue?: boolean;
};

export const loadAndMergeJsons = (
  json1: object,
  json2: object,
  isGymsy: boolean,
  options?: MergeOptions,
) => {
  let finalJson = { ...json1 };

  if (isGymsy) {
    finalJson = { ...finalJson, ...json2 };
  }
  if (options?.appendKeyToValue) {
    finalJson = Object.entries(finalJson).reduce((acc, [key, value]) => {
      acc[key] = `${value}_${key}`;
      return acc;
    }, {});
  }
  return finalJson;
};

const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: async (cb: (arg0: string) => string) => {
    const deviceLanguageArr = await Promise.resolve(getLocales?.());
    const deviceLanguage = deviceLanguageArr?.[0]?.languageCode;
    cb(deviceLanguage);
  },
  init: () => {
    /**/
  },
  cacheUserLanguage: () => {
    /**/
  },
};

export const getResources = () => {
  const isGymsy = flags.PRODUCT_NAME === ProductName.GYMSY;
  const options: MergeOptions = {
    appendKeyToValue: false,
  };
  const pl = loadAndMergeJsons(plBase, plGymsy, isGymsy, options);
  const en = loadAndMergeJsons(enBase, enGymsy, isGymsy, options);

  //use gymsy en for all other langs
  const no = loadAndMergeJsons(noBase, enGymsy, isGymsy, options);
  const de = loadAndMergeJsons(deBase, enGymsy, isGymsy, options);
  const es = loadAndMergeJsons(esBase, enGymsy, isGymsy, options);
  const ru = loadAndMergeJsons(ruBase, enGymsy, isGymsy, options);
  const fr = loadAndMergeJsons(frBase, enGymsy, isGymsy, options);
  const it = loadAndMergeJsons(itBase, enGymsy, isGymsy, options);

  return {
    en: {
      translation: en,
    },
    pl: {
      translation: pl,
    },
    no: {
      translation: no,
    },
    de: {
      translation: de,
    },
    es: {
      translation: es,
    },
    ru: {
      translation: ru,
    },
    fr: {
      translation: fr,
    },
    it: {
      translation: it,
    },
  };
};

const resources = getResources();

void i18next
  .use(languageDetector as Module)
  .use(initReactI18next)
  .init({
    compatibilityJSON: "v3",
    fallbackLng: fallbackLanguage,
    debug: true,
    resources,
  });

export default i18next;
