import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { postFile } from "@api/common";
import { endpoints } from "@api/endpoints";
import { AppbarTitleWithBackAction } from "@components/Appbar";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { ImagePreview } from "@components/Image";
import MediaWithButton from "@components/Image/MediaWithButton";
import { FullScreenModal } from "@components/index";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import { useInvalidateProfileDetails } from "@hooks/index";
import { imageTypes } from "@hooks/media/useResources/constants";
import { useResources } from "@hooks/media/useResources/useResources";
import { useErrors } from "@hooks/useErrors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import {
  ProfileCompletionStackParamList,
  ProfileStackParamsList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { ProfileImageForm } from "@screens/Profiles/MyProfile/CommonProfile/verification.types";
import { queryKeysVerficiations } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/queryKeysPhysiotherapistVerification";
import {
  deleteProfileImage,
  getProfileImages,
  setProfileImage,
} from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { MediaTypeOptions } from "expo-image-picker";
import { useFieldArray, useForm } from "react-hook-form";

import PermissionModal from "@components/Modals/PermissionModal";
import { PermissionType } from "@globalTypes/common.types";
import { MediaType } from "@hooks/media/useResources/types";
import { useMediaPermissions } from "@hooks/media/useMediaPermissions";

type AddPhotoScreen = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<ProfileCompletionStackParamList, "AddPhoto">,
    NativeStackScreenProps<ProfileStackParamsList, "Profile">
  >
>;

export const AddPhoto: FC<AddPhotoScreen> = ({
  navigation: { setOptions, navigate },
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [imagePreviewModalVisible, setImagePreviewModalVisible] =
    useState(false);
  const [imageToPreview, setImageToPreview] = useState<string>("");
  const [isAddImageLoading, setIsAddImageLoading] = useState(false);
  const [isPermissionModalVisible, setIsPermissionModalVisible] =
    useState(false);
  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const { isPhysiotherapist } = useUserDetails();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();
  const { container, gapLarge, loading, gapMedium } = globalStyles;
  const { data, isLoading, isError, refetch, isSuccess } = useQuery({
    queryFn: getProfileImages,
    queryKey: queryKeysVerficiations.profilePhoto(),
    select: data => data[0],
  });

  const { pickFilesFromDevice, pickedFiles, removeFile } = useResources({
    supportedFileTypes: imageTypes,
    mediaTypes: MediaTypeOptions.Images,
  });

  const { checkPermissions, onPressConfirm } = useMediaPermissions({
    isModalVisible: isPermissionModalVisible,
    setIsModalVisible: setIsPermissionModalVisible,
  });

  const { control } = useForm<ProfileImageForm>();
  const { append, remove, fields } = useFieldArray({ name: "files", control });

  const onSuccessHandler = async () => {
    await invalidateProfileDetails();
    await refetch();
    remove();
    showProfileVerificationSnackbar(t("T01311"));
  };

  const { mutate: postImageFile } = useMutation({
    mutationFn: postFile,
    onError: ({ response }: AxiosError) => {
      setIsAddImageLoading(false);
      setErrorsFromResponse(response);
    },
    onSuccess: ({ id }) => setImage({ attachments: [id], id: data?.id }),
  });

  const { mutate: setImage } = useMutation({
    mutationFn: setProfileImage,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: onSuccessHandler,
  });

  const { mutate: deleteImage, isLoading: isDeleting } = useMutation({
    mutationFn: deleteProfileImage,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async () => {
      remove();
      await onSuccessHandler();
      setModalVisible(false);
    },
  });

  useEffect(() => {
    if (pickedFiles?.length) {
      setIsAddImageLoading(true);
      postImageFile({ file: pickedFiles[0], endpoint: endpoints.IMAGE_FILES });
      removeFile(pickedFiles[0].uri);
    }
  }, [pickedFiles, postImageFile, removeFile]);

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack, popToTop, pop } }) => (
        <AppbarTitleWithBackAction
          onBack={goBack}
          onClose={() => {
            popToTop();
            pop();
          }}
          title={t("T00528")}
          filledIcon={!!data}
        />
      ),
    });
  }, [setOptions, t, navigate, data]);

  useEffect(() => {
    if (isSuccess && data && fields.length === 0) {
      remove();
      append(data?.attachments[0]);
      setIsAddImageLoading(false);
    }
  }, [
    append,
    data,
    data?.attachments,
    fields,
    fields.length,
    isSuccess,
    remove,
  ]);

  const renderImagePreview = useCallback(
    () => (
      <ImagePreview
        uri={imageToPreview}
        onPress={() => setImagePreviewModalVisible(prevState => !prevState)}
      />
    ),
    [imageToPreview],
  );

  const isImageLoading = useMemo(
    () => isAddImageLoading || isDeleting,
    [isAddImageLoading, isDeleting],
  );

  if (isLoading) return <ActivityIndicator style={loading} />;

  if (isError) return <FetchError action={refetch} />;
  return (
    <View style={[container, gapLarge]}>
      <Text variant="bodyMedium">
        {t(isPhysiotherapist ? "T00530" : "T00532")}
      </Text>
      <Text variant="bodyMedium">
        {t(isPhysiotherapist ? "T00531" : "T00533")}
      </Text>
      <PrimaryButton
        mode="outlined"
        label="T00534"
        onPress={async () =>
          await checkPermissions(
            () => pickFilesFromDevice(MediaType.gallery),
            true,
          )
        }
        loading={isImageLoading}
        disabled={isImageLoading}
      />
      <Text variant="bodyMedium">{`${t("T00535")}${imageTypes.map(
        t => ` .${t.toLowerCase()}`,
      )}.`}</Text>
      <View style={gapMedium}>
        <Text variant="titleMedium">{t("T00569")}</Text>
        {!!data && fields?.length ? (
          fields.map(({ id, file }) => (
            <MediaWithButton
              key={id}
              imgUri={file}
              onPressButton={() => setModalVisible(true)}
              buttonLabel="T00146"
              onPressItem={() => {
                setImageToPreview(file);
                setImagePreviewModalVisible(true);
              }}
            />
          ))
        ) : (
          <Text variant="bodyMedium">{t("T00570")}</Text>
        )}
      </View>
      <BottomModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}>
        <View style={gapMedium}>
          <Text variant="bodyLarge">{t("T00577")}</Text>
          <Text variant="bodyMedium">{t("T00306")}</Text>
          <PrimaryButton
            label="T00146"
            onPress={() => deleteImage(data.id)}
            loading={isDeleting}
            disabled={isDeleting}
          />
        </View>
      </BottomModalContainer>
      <FullScreenModal
        isModalVisible={imagePreviewModalVisible}
        setIsModalVisible={setImagePreviewModalVisible}
        renderContent={renderImagePreview}
      />
      <PermissionModal
        isModalVisible={isPermissionModalVisible}
        setIsModalVisible={setIsPermissionModalVisible}
        onPressConfirm={onPressConfirm}
        type={PermissionType.galleryAndFiles}
      />
    </View>
  );
};
