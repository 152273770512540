import { useErrors } from "@hooks/useErrors";
import { setPhone } from "@services/ApiService/verifications";
import { useMutation } from "@tanstack/react-query";
import { showAlert } from "@utils/showAlert";
import { AxiosError } from "axios";

type UseSetPhoneOptions = {
  onSuccessCallback?: () => void;
};

export const useSetPhone = ({ onSuccessCallback }: UseSetPhoneOptions = {}) => {
  const { setErrorsFromResponse } = useErrors();

  const mutation = useMutation({
    mutationFn: setPhone,
    onSuccess: () => onSuccessCallback && onSuccessCallback(),
    onError: ({ response }: AxiosError<{ phone_number: string[] }>) => {
      response?.data?.phone_number?.[0]
        ? showAlert(response.data.phone_number[0])
        : setErrorsFromResponse(response);
    },
  });

  return mutation;
};
