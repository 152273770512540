import { Dispatch, FC, SetStateAction } from "react";
import { Modal, ModalProps, StyleSheet, TouchableOpacity } from "react-native";
import { Dialog } from "react-native-paper";

import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { globalStyles } from "@styles/global";
import { ModalAnimationType } from "@globalTypes/common.types";

export type FullScreenModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  renderContent: () => JSX.Element;
  withoutBackDrop?: boolean;
  modalAnimation?: ModalAnimationType;
} & ModalProps;

export const FullScreenModal: FC<FullScreenModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  renderContent,
  withoutBackDrop,
  modalAnimation,
  ...props
}) => {
  const { height, width } = useWindowDimensions();
  const { backdrop, dialogContainer } = styles;

  return (
    <Modal
      {...props}
      visible={isModalVisible}
      transparent
      animationType={modalAnimation || "fade"}
      onRequestClose={() => setIsModalVisible(false)}>
      <Dialog.Content style={dialogContainer}>
        {!withoutBackDrop && (
          <TouchableOpacity
            activeOpacity={0}
            onPress={() => setIsModalVisible(false)}
            style={[backdrop, { height, width }]}
          />
        )}
        {renderContent()}
      </Dialog.Content>
    </Modal>
  );
};

const styles = StyleSheet.create({
  dialogContainer: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  backdrop: {
    ...globalStyles.backdrop,
    position: "absolute",
  },
});
