import { AppointmentSessionContext } from "@contexts/AppointmentSessionContext/appointmentSession.context";
import { FC, useContext } from "react";
import { MedicalRecordsListForAppointmentDetailsAndSession } from "../MedicalRecordsListForAppointmentDetailsAndSession";

const SharedRecords: FC<{ appointmentId?: number }> = ({ appointmentId }) => {
  const { updateData } = useContext(AppointmentSessionContext);

  const updateDataAndNavigateToRecordPreview = (id: number) => {
    updateData({
      displayInFloatingView: true,
      navigateTo: "RecordPreview",
      navigateData: { id },
    });
  };

  return (
    <MedicalRecordsListForAppointmentDetailsAndSession
      appointmentId={appointmentId}
      onTilePress={updateDataAndNavigateToRecordPreview}
      sectionListTitle="T00080"
    />
  );
};

export default SharedRecords;
