import { useUserDetails } from "@hooks/user/useUserDetails";
import { SvgTrainings } from "@assets/svg";
import { spacing16, spacing8 } from "@styles/spacing";
import ListItems, { ListItemProps } from "@components/Lists/ListItems";
import { FC, useMemo } from "react";
import { PushNotificationsSettingsStackParamsList } from "@navigators/navigation.types";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

type PushNotificationsListItemsProps = {
  navigation: NativeStackNavigationProp<PushNotificationsSettingsStackParamsList>;
};

const PushNotificationsListItems: FC<PushNotificationsListItemsProps> = ({
  navigation: { navigate },
}) => {
  const { isPhysiotherapist } = useUserDetails();
  const items: ListItemProps[] = useMemo(
    () => [
      {
        name: "T00680",
        onPress: () => navigate("Appointments"),
        icon: "calendar-blank-outline",
      },
      {
        name: "T00167",
        onPress: () => navigate("TrainingsPushSettings"),
        icon: <SvgTrainings style={{ marginLeft: spacing16 }} />,
        hidden: isPhysiotherapist,
      },
      {
        name: "T00362",
        onPress: () => navigate("Messages"),
        icon: "message-outline",
      },
    ],
    [isPhysiotherapist, navigate],
  );

  return <ListItems items={items} style={{ marginTop: spacing8 }} />;
};

export default PushNotificationsListItems;
