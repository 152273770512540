import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={56} height={56} viewBox="0 0 56 56" fill="none" {...props}>
    <Path
      d="M28 16.334a2.34 2.34 0 012.333 2.333V28A2.34 2.34 0 0128 30.334 2.34 2.34 0 0125.666 28v-9.333A2.34 2.34 0 0128 16.334zm-.023-11.667C15.097 4.667 4.667 15.12 4.667 28c0 12.88 10.43 23.334 23.31 23.334C40.88 51.334 51.333 40.88 51.333 28c0-12.88-10.453-23.333-23.356-23.333zm.023 42C17.686 46.667 9.333 38.314 9.333 28 9.333 17.687 17.686 9.334 28 9.334c10.313 0 18.666 8.353 18.666 18.666 0 10.314-8.353 18.667-18.666 18.667zm2.333-7h-4.666V35h4.666v4.667z"
      fill="#FFC22D"
    />
  </Svg>
);

export default SvgComponent;
