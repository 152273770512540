import { AppointmentsNotificationSetting } from "@globalTypes/messagesNotificationSetting";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrors } from "@hooks/useErrors";
import {
  transformAppointmentsNotificationsSettingsToArray,
  getUserNotificationTime,
} from "@screens/Profiles/MyProfile/Settings/utils";
import { queryKeysNotificationSettings } from "@screens/Profiles/MyProfile/queryKeysSettings";
import {
  getAppointmentsNotificationsSettings,
  getAppointmentsNotificationsSettingsOptions,
  updateAppointmentsNotificationsSettings,
} from "@services/ApiService/appSettings";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

type FormType = {
  appointmentTimeReminder?: string;
};

const schema = object().shape({
  appointmentTimeReminder: string(),
});

export const useAppointmentsNotificationsSettings = () => {
  const [switchOn, setSwitchOn] = useState(false);
  const { setErrorsFromResponse } = useErrors();

  const { data, isSuccess, isError, isLoading, refetch } = useQuery({
    queryKey: queryKeysNotificationSettings.appointments(),
    queryFn: getAppointmentsNotificationsSettings,
    select: data => data.length && data[0],
  });

  const {
    data: appointmentsNotificationsSettingsOptions,
    isLoading: isAppointmentsNotificationsSettingsOptionsLoading,
    isError: isAppointmentsNotificationsSettingsOptionsError,
    refetch: refetchAppointmentsNotificationsSettingsOptions,
  } = useQuery({
    queryKey: queryKeysNotificationSettings.appointmentsOptions(),
    queryFn: getAppointmentsNotificationsSettingsOptions,
    select: transformAppointmentsNotificationsSettingsToArray,
  });

  const queryClient = useQueryClient();

  const onSuccessHandler = useCallback(
    async () =>
      await queryClient.invalidateQueries(
        queryKeysNotificationSettings.appointments(),
      ),
    [queryClient],
  );

  const onErrorHandler = useCallback(
    ({ response }: AxiosError) => setErrorsFromResponse(response),
    [setErrorsFromResponse],
  );

  const { mutate: updateAppointmentsNotifications } = useMutation({
    mutationFn: async ({
      id,
      notificationsActive,
    }: Omit<AppointmentsNotificationSetting, "notificationTime">) => {
      await updateAppointmentsNotificationsSettings({
        id,
        notificationsActive,
      });
    },
    onError: onErrorHandler,
    onSuccess: onSuccessHandler,
  });

  const { mutate: updateAppointmentsNotificationsTime } = useMutation({
    mutationFn: async ({
      id,
      notificationTime,
    }: Omit<AppointmentsNotificationSetting, "notificationsActive">) => {
      await updateAppointmentsNotificationsSettings({ id, notificationTime });
    },
    onError: onErrorHandler,
    onSuccess: onSuccessHandler,
  });

  const refetchQueries = async () =>
    await Promise.all([
      refetch(),
      refetchAppointmentsNotificationsSettingsOptions(),
    ]);

  const defaultNotificationTime = useMemo(
    () =>
      (data &&
        !!appointmentsNotificationsSettingsOptions?.length &&
        getUserNotificationTime(
          data?.notificationTime,
          appointmentsNotificationsSettingsOptions,
        ).value) ||
      appointmentsNotificationsSettingsOptions?.[1].value,
    [appointmentsNotificationsSettingsOptions, data],
  );

  useEffect(() => {
    if (isSuccess) setSwitchOn(data?.notificationsActive);
  }, [data?.notificationsActive, isSuccess]);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver<FormType>(schema),
    defaultValues: {
      appointmentTimeReminder: defaultNotificationTime,
    },
  });

  const onSubmit = useCallback(
    ({ appointmentTimeReminder }: FormType) => {
      const { id } = data;
      updateAppointmentsNotificationsTime({
        id,
        notificationTime: Number(appointmentTimeReminder),
      });
    },
    [data, updateAppointmentsNotificationsTime],
  );

  const onSwitchChange = useCallback(
    (val: boolean) => {
      setSwitchOn(prev => !prev);
      updateAppointmentsNotifications({
        id: data?.id,
        notificationsActive: val,
      });
    },
    [data?.id, updateAppointmentsNotifications],
  );
  return {
    onSwitchChange,
    handleSubmit: handleSubmit(onSubmit),
    refetchQueries,
    control,
    errors,
    switchOn,
    isLoading: isLoading || isAppointmentsNotificationsSettingsOptionsLoading,
    isError: isError || isAppointmentsNotificationsSettingsOptionsError,
    defaultNotificationTime,
    appointmentsNotificationsSettingsOptions,
  };
};
