import { AntDesign } from "@expo/vector-icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Card, CardProps, Text } from "react-native-paper";
import {
  VerificationStatus,
  VerificationStatusType,
} from "../../screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { palettes } from "../../styles/colors";
import { globalStyles } from "../../styles/global";
import { CardElevationType } from "../cards/Card.types";
import StatusComponent from "../Status/StatusComponent";
import { TransKey } from "@globalTypes/i18next";

type VerificationTileType = Omit<CardProps, "children"> & {
  title: TransKey;
  content: TransKey;
  status?: VerificationStatus | null;
  elevation?: CardElevationType;
  type?: VerificationStatusType;
};

const VerificationTile: FC<VerificationTileType> = ({
  title,
  content,
  status,
  elevation,
  mode,
  onPress,
  type,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Card
      {...props}
      onPress={onPress}
      mode={mode}
      elevation={elevation as never}>
      <Card.Content style={styles.container}>
        <View style={[styles.leftContainer, globalStyles.gapSmall]}>
          <Text variant="titleMedium" numberOfLines={1}>
            {t(title)}
          </Text>
          <Text variant="bodySmall" numberOfLines={4}>
            {t(content)}
          </Text>
          {status || status === null ? (
            <StatusComponent status={status} type={type} />
          ) : null}
        </View>
        <View style={styles.rightContainer}>
          <AntDesign name="arrowright" size={16} color={palettes.primary[0]} />
        </View>
      </Card.Content>
    </Card>
  );
};

export default VerificationTile;

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    flexDirection: "row",
  },
  rightContainer: {
    alignSelf: "center",
  },
  leftContainer: {
    maxWidth: "90%",
  },
});
