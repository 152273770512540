import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { spacing4, spacing8 } from "@styles/spacing";
import { useState } from "react";
import { chartColors, palettes } from "@styles/colors";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryStack,
} from "@components/Charts";
import { VictoryBarProps } from "victory";

const data = [
  {
    group: "01",
    groupItems: [
      {
        label: "2022",
        value: 60,
        maxValue: 100,
      },
      {
        label: "2023",
        value: 60,
        maxValue: 100,
      },
    ],
  },
  {
    group: "02",
    groupItems: [
      {
        label: "2022",
        value: 20,
        maxValue: 100,
      },
      {
        label: "2023",
        value: 20,
        maxValue: 100,
      },
    ],
  },
  {
    group: "03",
    groupItems: [
      {
        label: "2022",
        value: 60,
        maxValue: 100,
      },
      {
        label: "2023",
        value: 60,
        maxValue: 100,
      },
    ],
  },
  {
    group: "04",
    groupItems: [
      {
        label: "2022",
        value: 20,
        maxValue: 100,
      },
      {
        label: "2023",
        value: 20,
        maxValue: 100,
      },
    ],
  },
  {
    group: "05",
    groupItems: [
      {
        label: "2022",
        value: 60,
        maxValue: 100,
      },
      {
        label: "2023",
        value: 60,
        maxValue: 100,
      },
    ],
  },
  {
    group: "06",
    groupItems: [
      {
        label: "2022",
        value: 20,
        maxValue: 100,
      },
      {
        label: "2023",
        value: 20,
        maxValue: 100,
      },
    ],
  },
  {
    group: "07",
    groupItems: [
      {
        label: "2022",
        value: 60,
        maxValue: 100,
      },
      {
        label: "2023",
        value: 60,
        maxValue: 100,
      },
    ],
  },
  {
    group: "08",
    groupItems: [
      {
        label: "2022",
        value: 20,
        maxValue: 100,
      },
      {
        label: "2023",
        value: 20,
        maxValue: 100,
      },
    ],
  },
  {
    group: "09",
    groupItems: [
      {
        label: "2022",
        value: 60,
        maxValue: 100,
      },
      {
        label: "2023",
        value: 60,
        maxValue: 100,
      },
    ],
  },
  {
    group: "10",
    groupItems: [
      {
        label: "2022",
        value: 20,
        maxValue: 100,
      },
      {
        label: "2023",
        value: 20,
        maxValue: 100,
      },
    ],
  },
  {
    group: "11",
    groupItems: [
      {
        label: "2022",
        value: 60,
        maxValue: 100,
      },
      {
        label: "2023",
        value: 60,
        maxValue: 100,
      },
    ],
  },
  {
    group: "12",
    groupItems: [
      {
        label: "2022",
        value: 20,
        maxValue: 100,
      },
      {
        label: "2023",
        value: 20,
        maxValue: 100,
      },
    ],
  },
];

type GroupItemType = {
  group: string;
  groupItems: {
    label: string;
    value: number;
    maxValue: number;
  }[];
};

export const VictoryCharts = () => {
  const [displayedMonth, setDisplayedMonth] = useState<string[]>([]);
  const { width } = useWindowDimensions();

  const onPressData = ({ groupItems, group }: GroupItemType) => ({
    target: "data",
    mutation: () => {
      return setDisplayedMonth(prevState => {
        const newState = groupItems.map(
          ({ label, value, maxValue }) =>
            `${group}.${label} ${value}/${maxValue}`,
        );
        const isArraysEqual =
          prevState.length === newState.length &&
          prevState.every((item, index) => item === newState[index]);
        return isArraysEqual ? [] : newState;
      });
    },
  });

  const barEvents = ({ groupItems, group }: GroupItemType) =>
    [
      {
        target: "data",
        eventHandlers: {
          onPressIn: () => [onPressData({ groupItems, group })],
          onClick: () => [onPressData({ groupItems, group })],
        },
      },
    ] as VictoryBarProps["events"];

  return (
    <View style={{ alignItems: "center" }}>
      {displayedMonth.length > 0 && (
        <View style={styles.legendContainer}>
          {displayedMonth.map((e, i) => (
            <View
              key={`legend-item-${e}-${i}`}
              style={[
                styles.legendItem,
                {
                  backgroundColor:
                    i === 0 ? palettes.neutral[26] : palettes.complete[25],
                },
              ]}>
              <Text
                style={{
                  color: i === 0 ? palettes.primary[100] : palettes.primary[0],
                }}>
                {e}
              </Text>
            </View>
          ))}
        </View>
      )}
      <VictoryChart width={width} domainPadding={{ x: 50, y: [0, 20] }}>
        {data.map((groupItem, index) => (
          <VictoryGroup
            key={`group-${groupItem.group}-${index}`}
            offset={spacing8 + 2}
            style={{ data: { width: spacing8 } }}>
            {groupItem.groupItems.map(({ label, value, maxValue }, i) => {
              const shouldBeSelected = displayedMonth.some(
                el => el === `${groupItem.group}.${label} ${value}/${maxValue}`,
              );

              const firstValueFill = shouldBeSelected
                ? chartColors.primarySelected
                : chartColors.primary;
              const maxValueFill = shouldBeSelected
                ? chartColors.maxSelected
                : chartColors.max;
              const secondValueFill = shouldBeSelected
                ? chartColors.secondarySelected
                : chartColors.secondary;

              return (
                <VictoryStack key={`stack-${label}-${i}`}>
                  <VictoryBar
                    data={[{ x: groupItem.group, y: value }]}
                    style={{
                      data: {
                        fill: i === 0 ? firstValueFill : secondValueFill,
                      },
                    }}
                    events={barEvents(groupItem)}
                  />
                  <VictoryBar
                    data={[{ x: groupItem.group, y: maxValue }]}
                    style={{
                      data: {
                        fill: maxValueFill,
                      },
                    }}
                    events={barEvents(groupItem)}
                  />
                </VictoryStack>
              );
            })}
          </VictoryGroup>
        ))}
        <VictoryAxis />
        <VictoryAxis dependentAxis />
      </VictoryChart>
    </View>
  );
};

const styles = StyleSheet.create({
  legendContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: spacing4,
  },
  legendItem: {
    padding: spacing4,
    borderRadius: spacing4,
  },
});
