import {
  InvitationCodeResponse,
  InvitationCodeType,
} from "@globalTypes/common.types";
import { postInvitationCode } from "@services/ApiService/common";
import { useMutation } from "@tanstack/react-query";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { AxiosError } from "axios";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useErrors } from "../useErrors";
import { useUserDetails } from "../user/useUserDetails";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

export const usePromoCodesLogic = (onAlertConfirm?: () => void) => {
  const { t } = useTranslation();
  const { isPatient } = useUserDetails();
  const [currentInvitationCode, setCurrentInvitationCode] = useState("");
  const [physioId, setPhysioId] = useState<number>(null);
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);
  const { setErrorsFromResponse, errors, clearErrors } = useErrors();

  const handleSuccess = useCallback(
    ({ id }: InvitationCodeResponse) => {
      if (isPatient) {
        setPhysioId(id);
        setIsBottomSheetVisible(true);
      } else {
        showAlertWithCustomButtons({
          title: t("T01351"),
          message: t("T01352"),
          confirmButton: {
            text: "Ok",
            onPress: onAlertConfirm && onAlertConfirm,
          },
        });
      }
    },
    [isPatient, onAlertConfirm, t],
  );

  const { mutate: sendInvitationCode, isLoading } = useMutation({
    mutationFn: postInvitationCode,
    onSuccess: ({ data }) => handleSuccess(data),
    onError: ({ response }: AxiosError) =>
      setErrorsFromResponse(response, "❌ Error"),
  });

  const onSubmit = useCallback(
    ({ invitationCode }: InvitationCodeType) => {
      sendInvitationCode({ invitationCode });
      setCurrentInvitationCode(invitationCode);
    },
    [sendInvitationCode],
  );

  const schema = useMemo(() => {
    const regex = /^[A-Z]\d{5}$/;
    return object().shape({
      invitationCode: string()
        .matches(regex, t("T01350"))
        .required(t("T00014"))
        .trim(),
    });
  }, [t]);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  return {
    handleSubmit: handleSubmit(onSubmit),
    isLoading,
    currentInvitationCode,
    physioId,
    isBottomSheetVisible,
    errors,
    clearErrors,
    setIsBottomSheetVisible,
    control,
  };
};
