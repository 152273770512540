import { Appointment } from "../Appointments/appointment.types";
import { TrainingDay } from "../TrainingsAndExercises/training.types";
import { getDaysInMonth } from "date-fns";
import { formatDateWithDayName, getDate } from "@utils/date";
import { DateArrayType } from "@components/Calendar";

const getEmptyMonth = (date: Date): Date[] => {
  const day = new Date(date);
  const days = getDaysInMonth(day);

  day.setDate(1);
  day.setHours(0, 0, 0, 0);

  const thisEmptyMonth: Date[] = [];
  for (let i = 1; i <= days; i) {
    const tempDay = new Date(day);
    thisEmptyMonth.push(tempDay);
    day.setDate(++i);
  }

  return thisEmptyMonth;
};

export const getEmptyMonthForPhysio = (
  date: Date,
  data: Appointment[],
  language: string,
) => {
  const thisEmptyMonth = getEmptyMonth(date);

  const resultMonth = thisEmptyMonth.flatMap((e: Date) => {
    const tempDate = new Date(e);
    tempDate.setHours(0, 0, 0, 0);
    const tempData = [...data];
    const dateString = formatDateWithDayName(language, e);

    const resultData = tempData.filter(({ dateFrom }) => {
      const tempAppointmentDay = new Date(dateFrom);
      tempAppointmentDay.setHours(0, 0, 0, 0);

      return tempAppointmentDay.getTime() === tempDate.getTime();
    });

    return resultData.length > 0
      ? { date: dateString, data: resultData }
      : dateString;
  });

  return resultMonth;
};

export const getEmptyMonthForPatient = (
  date: Date,
  appointments: Appointment[] = [],
  trainings: TrainingDay[] = [],
  language: string,
) => {
  const thisEmptyMonth = getEmptyMonth(date);

  const resultMonth = thisEmptyMonth.flatMap((e: Date) => {
    const tempDate = new Date(e);
    tempDate.setHours(0, 0, 0, 0);
    const tempAppointments = [...appointments];
    const tempTrainigs = [...trainings];
    const dateString = formatDateWithDayName(language, e);

    const resultAppointments = tempAppointments?.filter(({ dateFrom }) => {
      const tempAppointmentDay = new Date(dateFrom);
      tempAppointmentDay.setHours(0, 0, 0, 0);

      return tempAppointmentDay.getTime() === tempDate.getTime();
    });
    const resultTrainings = tempTrainigs?.filter(({ executionDate }) => {
      const tempTrainingDay = new Date(executionDate);
      tempTrainingDay.setHours(0, 0, 0, 0);

      return tempTrainingDay.getTime() === tempDate.getTime();
    });

    return resultAppointments.length > 0 || resultTrainings.length > 0
      ? {
          date: dateString,
          appointments: resultAppointments,
          trainings: resultTrainings,
        }
      : dateString;
  });

  return resultMonth;
};

export const isNewDateInPrevDates = ({
  prevDates,
  newDates,
}: {
  prevDates: string[];
  newDates: DateArrayType[];
}) => {
  const prevDatesFormatted = prevDates.map(d => getDate(d));
  const newDatesFormatted = newDates.map(({ date }) => getDate(date));
  for (let i = 0; i < newDatesFormatted.length; i++) {
    if (prevDatesFormatted.includes(newDatesFormatted[i])) return true;
  }
  return false;
};
