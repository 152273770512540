import { Alert } from "react-native";
import { t } from "i18next";
import { ImagePickerAsset } from "expo-image-picker";

type SupportedImageExtension = "jpg" | "jpeg" | "png";

type SupportedVideoExtension = "mp4" | "mov" | "hevc";

type AllowedExtensions = SupportedImageExtension | SupportedVideoExtension;

type MediaType = ImagePickerAsset["type"];

const videoExtensionRegex = /\.(mp4|mov|hevc)$/i;
const imageExtensionRegex = /\.(jpeg|jpg|png)$/i;

const unsupportedFormatAlert = (type: MediaType) => {
  Alert.alert(
    t("T00160"),
    `${t("T00761")}: ${
      type === "video" ? ".mp4, .mov, .hevc" : ".jpg, .jpeg, .png"
    }`,
    [{ text: t("T00163") }],
    {
      cancelable: false,
    },
  );
};

const isSupportedExtension = (
  type: MediaType,
  extension: AllowedExtensions,
): boolean => {
  if (type === "video") {
    switch (extension) {
      case "mp4":
      case "mov":
      case "hevc":
        return true;
      default:
        return false;
    }
  }
  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
      return true;
    default:
      return false;
  }
};

export const createProperFileType = (type: MediaType, uri: string) => {
  const extension = uri.match(
    type === "video" ? videoExtensionRegex : imageExtensionRegex,
  );
  const extractedExtension = extension[0].substring(1) as AllowedExtensions;
  if (!isSupportedExtension(type, extractedExtension))
    return unsupportedFormatAlert(type);
  return `${type}/${extractedExtension}`;
};
