import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={250} height={180} fill="none" {...props}>
    <Path
      fill="#3F3D56"
      d="M236.026 179.279H13.974A13.367 13.367 0 0 1 .62 165.925V33.151a13.369 13.369 0 0 1 13.354-13.353h222.052a13.367 13.367 0 0 1 13.354 13.354v132.773a13.365 13.365 0 0 1-3.916 9.438 13.365 13.365 0 0 1-9.438 3.916ZM13.974 22.087A11.077 11.077 0 0 0 2.909 33.151v132.774a11.076 11.076 0 0 0 11.065 11.065h222.052a11.076 11.076 0 0 0 11.065-11.065V33.151a11.077 11.077 0 0 0-11.065-11.064H13.974Z"
    />
    <Path
      fill="#3F3D56"
      d="M43.733 51.084a9.538 9.538 0 1 1 9.539-9.539 9.549 9.549 0 0 1-9.539 9.538Zm0-16.788a7.25 7.25 0 1 0 7.25 7.25 7.257 7.257 0 0 0-7.25-7.25ZM204.741 51.084a9.54 9.54 0 0 1-9.356-11.4 9.546 9.546 0 0 1 7.495-7.494 9.54 9.54 0 0 1 11.399 9.355 9.55 9.55 0 0 1-9.538 9.538Zm0-16.788a7.25 7.25 0 0 0-5.126 12.375 7.245 7.245 0 0 0 7.9 1.572 7.254 7.254 0 0 0 4.475-6.698 7.257 7.257 0 0 0-7.249-7.249Z"
    />
    <Path
      fill="#E6E6E6"
      d="M66.244 109.077H25.038a6.494 6.494 0 0 1-6.486-6.486V78.173a6.494 6.494 0 0 1 6.486-6.487h41.206a6.494 6.494 0 0 1 6.486 6.487v24.418a6.495 6.495 0 0 1-6.486 6.486ZM145.603 109.077h-41.206a6.494 6.494 0 0 1-6.486-6.486V78.173a6.493 6.493 0 0 1 6.486-6.487h41.206a6.495 6.495 0 0 1 6.486 6.487v24.418a6.494 6.494 0 0 1-6.486 6.486ZM183.756 72.45a5.73 5.73 0 0 0-5.723 5.723v24.418a5.731 5.731 0 0 0 5.723 5.723h41.206a5.731 5.731 0 0 0 5.723-5.723V78.173a5.73 5.73 0 0 0-5.723-5.723h-41.206ZM66.625 157.913H25.42a6.493 6.493 0 0 1-6.486-6.486v-24.418a6.493 6.493 0 0 1 6.486-6.486h41.205a6.493 6.493 0 0 1 6.486 6.486v24.418a6.493 6.493 0 0 1-6.486 6.486ZM145.984 157.913h-41.205a6.493 6.493 0 0 1-6.486-6.486v-24.418a6.492 6.492 0 0 1 6.486-6.486h41.205a6.493 6.493 0 0 1 6.486 6.486v24.418a6.493 6.493 0 0 1-6.486 6.486ZM225.343 157.913h-41.205a6.493 6.493 0 0 1-6.486-6.486v-24.418a6.493 6.493 0 0 1 6.486-6.486h41.205a6.493 6.493 0 0 1 6.486 6.486v24.418a6.493 6.493 0 0 1-6.486 6.486Z"
    />
    <Path
      fill="#3F3D56"
      d="M43.733 44.216a2.674 2.674 0 0 1-2.67-2.67V3.391a2.67 2.67 0 1 1 5.341 0v38.153a2.673 2.673 0 0 1-2.67 2.671ZM204.741 44.216a2.674 2.674 0 0 1-2.671-2.67V3.391a2.67 2.67 0 1 1 5.341 0v38.153a2.673 2.673 0 0 1-2.67 2.671Z"
    />
    <Path
      fill="#4666FF"
      d="M44.083 100.279a5.178 5.178 0 0 1-3.116-1.035l-.055-.042-11.737-8.978a5.215 5.215 0 1 1 6.347-8.278l7.602 5.83 17.963-23.437a5.213 5.213 0 0 1 7.31-.965l.001.001-.111.155.114-.155a5.22 5.22 0 0 1 .965 7.31L48.236 98.24a5.217 5.217 0 0 1-4.148 2.034l-.005.006ZM125.732 148.353a5.176 5.176 0 0 1-3.116-1.036l-.056-.042-11.737-8.978a5.217 5.217 0 0 1 6.347-8.278l7.602 5.829 17.964-23.435a5.196 5.196 0 0 1 3.454-1.997 5.205 5.205 0 0 1 3.855 1.031l.002.001-.112.155.115-.155a5.224 5.224 0 0 1 .964 7.311l-21.129 27.554a5.222 5.222 0 0 1-4.149 2.034l-.004.006ZM202.801 100.279a5.18 5.18 0 0 1-3.116-1.035l-.055-.042-11.737-8.978a5.214 5.214 0 0 1-.966-7.313 5.217 5.217 0 0 1 7.313-.965l7.602 5.83 17.963-23.437a5.23 5.23 0 0 1 3.455-1.996 5.215 5.215 0 0 1 3.855 1.03l.001.002-.111.155.114-.155a5.218 5.218 0 0 1 .965 7.31l-21.13 27.554a5.21 5.21 0 0 1-4.148 2.034l-.005.006Z"
    />
  </Svg>
);
export default SvgComponent;
