import { RadioButtonsGroup } from "@components/FormElements";
import InfoTile from "@components/Tile/InfoTile";
import { FetchError } from "@components/errors";
import { PushNotificationsSettingsStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { spacing16, spacing24 } from "@styles/spacing";
import { FC, PropsWithChildren } from "react";
import { Controller } from "react-hook-form";
import { Trans } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import SwitchSection from "./SwitchSection";
import { useAppointmentsNotificationsSettings } from "@hooks/notifications/useAppointmentsNotificationsSettings";

type AppointmentsPushSettingsProps = PropsWithChildren<
  NativeStackScreenProps<
    PushNotificationsSettingsStackParamsList,
    "Appointments"
  >
>;

const Appointments: FC<AppointmentsPushSettingsProps> = () => {
  const {
    onSwitchChange,
    handleSubmit,
    refetchQueries,
    control,
    errors,
    switchOn,
    isError,
    isLoading,
    defaultNotificationTime,
    appointmentsNotificationsSettingsOptions,
  } = useAppointmentsNotificationsSettings();

  const { container, gapMedium, loading, fontBolded } = globalStyles;

  if (isLoading) return <ActivityIndicator style={loading} />;

  if (isError) return <FetchError action={refetchQueries} />;

  return (
    <View style={[container, gapMedium]}>
      <SwitchSection
        switchOn={switchOn}
        onChange={onSwitchChange}
        title="T01461"
        description="T01462"
      />
      <InfoTile
        status="info"
        content={
          <Text variant="bodyMedium">
            <Trans
              i18nKey="T01463"
              components={{
                1: <Text style={fontBolded}>all appointments</Text>,
              }}
            />
          </Text>
        }
      />
      {switchOn && (
        <Controller
          control={control}
          name="appointmentTimeReminder"
          defaultValue={defaultNotificationTime}
          render={({ field: { onChange, value } }) => (
            <RadioButtonsGroup
              errorMessage={errors?.appointmentTimeReminder?.message}
              onChange={value => {
                onChange(value);
                void handleSubmit();
              }}
              value={value}
              data={appointmentsNotificationsSettingsOptions}
              radioItemStyle={styles.radioItemStyle}
              ignoreRadioButtonTranslations
            />
          )}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  radioItemStyle: {
    paddingTop: spacing24,
    paddingBottom: spacing16,
  },
});

export default Appointments;
