import { FC } from "react";
import { BackAndFrontModelProps } from "../humanModel.types";
import BoneFront from "./BoneFront";
import BoneBack from "./BoneBack";

const BoneModel: FC<BackAndFrontModelProps> = ({ orientation, ...props }) =>
  orientation === "front" ? (
    <BoneFront
      key={`bone-model-front-${props.defaultSelected?.length}`}
      {...props}
    />
  ) : (
    <BoneBack
      key={`bone-model-back-${props.defaultSelected?.length}`}
      {...props}
    />
  );

export default BoneModel;
