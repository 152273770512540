import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrors } from "@hooks/useErrors";
import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  getAboutMeInformation,
  setAboutMeInformation,
} from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { spacing12 } from "@styles/spacing";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MAX_ABOUT_ME_DESCRIPTION_INPUT_LENGTH } from "@utils/constants";
import { AxiosError } from "axios";
import { FC, PropsWithChildren } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ActivityIndicator, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { object, string } from "yup";
import { queryKeysVerficiations } from "./queryKeysPhysiotherapistVerification";
import { ProfileInformationDataType } from "./verification.types";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import { useInvalidateProfileDetails } from "@hooks/index";

const AboutMe: FC<
  PropsWithChildren<
    NativeStackScreenProps<ProfileCompletionStackParamList, "AboutMe">
  >
> = ({ navigation: { goBack } }) => {
  const { t } = useTranslation();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();

  const { scrollContainer, gapLarge, loading } = globalStyles;

  const schema = object().shape({
    description: string().max(
      MAX_ABOUT_ME_DESCRIPTION_INPUT_LENGTH,
      t("T00018", {
        max: MAX_ABOUT_ME_DESCRIPTION_INPUT_LENGTH,
      }),
    ),
  });

  const {
    data: aboutMeData,
    refetch: refetchAboutMe,
    isLoading: isAboutMeLoading,
    isError: isErrorAboutMe,
  } = useQuery({
    queryKey: queryKeysVerficiations.aboutMe(),
    queryFn: getAboutMeInformation,
    onError: ({ response }: AxiosError) => setAboutMeDataGetingError(response),
  });

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { description: aboutMeData?.[0]?.description },
  });

  const onUpdateAboutMeSuccess = async () => {
    await Promise.all([
      invalidateProfileDetails(),
      queryClient.invalidateQueries({
        queryKey: queryKeysVerficiations.aboutMe(),
      }),
      queryClient.invalidateQueries(
        queryKeysVerficiations.verificationProgressRehabInSubscreens(),
      ),
    ]);
    goBack();
    reset();
    showProfileVerificationSnackbar(t("T01311"));
  };

  const { errors, setErrorsFromResponse } = useErrors();
  const { setErrorsFromResponse: setAboutMeDataGetingError } = useErrors();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: setAboutMeInformation,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: onUpdateAboutMeSuccess,
  });

  const onSubmit = (data: ProfileInformationDataType) => {
    mutate(data);
  };

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "bottom", "left"]}>
      {isAboutMeLoading ? (
        <ActivityIndicator style={loading} />
      ) : isErrorAboutMe ? (
        <FetchError action={refetchAboutMe} />
      ) : (
        <KeyboardAwareScrollView
          contentContainerStyle={[scrollContainer, gapLarge]}>
          <Text variant="bodyMedium">{t("T00848")}</Text>
          <Text variant="bodyMedium">{t("T00849")}</Text>
          <>
            <Input
              name="description"
              label="T00258"
              control={control as unknown as Control<FieldValues>}
              errors={errors?.description}
              multiline
              defaultValue={aboutMeData?.[0]?.description}
              autoCapitalize="sentences"
              maxLength={MAX_ABOUT_ME_DESCRIPTION_INPUT_LENGTH}
            />
            <PrimaryButton
              label="T00164"
              onPress={handleSubmit(onSubmit)}
              disabled={isLoading}
              loading={isLoading}
              style={{ marginTop: -spacing12 }}
            />
          </>
        </KeyboardAwareScrollView>
      )}
    </SafeAreaView>
  );
};

export default AboutMe;
