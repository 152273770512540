import { FC, useCallback } from "react";
import {
  ActivityIndicator,
  Avatar,
  Card,
  IconButton,
  Text,
} from "react-native-paper";
import { AVATAR_SIZE } from "@utils/constants";
import { palettes } from "@styles/colors";
import { spacing16, spacing32 } from "@styles/spacing";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { View } from "react-native";
import { getSeries } from "@services/ApiService/exercises";
import {
  Series,
  SeriesType,
} from "@screens/TrainingsAndExercises/exercise.types";
import { queryKeysExercises } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import RefetchIconButton from "@components/Tile/components/RefetchIconButton";
import { SvgCheckCircle, SvgHighlightOff } from "@assets/svg";

type SeriesTileProps = {
  id: number;
  exercise: number;
  order?: number;
  onClose?: () => void;
  seriesData?: Omit<Series, "id">;
  valueDone?: number;
  breakValue?: number;
  isLast?: boolean;
};

const setIcon = (valueDone: number) =>
  valueDone === 0 ? <SvgHighlightOff /> : <SvgCheckCircle />;

const SeriesTile: FC<SeriesTileProps> = ({
  id,
  order,
  exercise,
  onClose,
  seriesData,
  valueDone,
  breakValue,
  isLast,
}) => {
  const { t } = useTranslation();
  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: queryKeysExercises.seriesDetails({
      seriesId: id,
      exerciseId: exercise,
    }),
    queryFn: async () => await getSeries(id),
    enabled: !seriesData,
    initialData: { ...seriesData, id },
  });

  const haveInitialSeries = seriesData && Object.values(seriesData)?.length > 0;
  const titleSecondText =
    seriesData?.seriesType === SeriesType.TIME ||
    data?.seriesType === SeriesType.TIME
      ? t("T00408")
      : t("T00409");

  const valueInTitle =
    valueDone !== undefined ? `${valueDone}/${data?.value}` : `${data?.value}`;

  const title = haveInitialSeries ? (
    <Text variant="titleMedium">{`${seriesData.value} ${titleSecondText}`}</Text>
  ) : isLoading || isError ? (
    ""
  ) : (
    <Text variant="titleMedium">{`${valueInTitle} ${titleSecondText}`}</Text>
  );

  const subtitle = haveInitialSeries
    ? `${t("T00410")}: ${seriesData.seriesBreak} ${t("T00408")}`
    : isLoading || isError || isLast
    ? ""
    : breakValue !== undefined
    ? `${t("T00410")}: ${breakValue} ${t("T00408")}`
    : `${t("T00410")}: ${data.seriesBreak} ${t("T00408")}`;

  const setCardRightElement = useCallback(
    (props: { size: number }) => {
      if (isLoading)
        return (
          <View style={{ flexDirection: "row", flexWrap: "nowrap" }}>
            <ActivityIndicator />
            <IconButton {...props} icon="close" onPress={onClose} />
          </View>
        );
      if (isError) return <RefetchIconButton onPress={refetch} />;
      if (onClose)
        return <IconButton {...props} icon="close" onPress={onClose} />;
      return;
    },
    [isLoading, isError, onClose, refetch],
  );

  const setCardLeftElement = useCallback(
    (props: { size: number }) =>
      valueDone !== undefined ? (
        setIcon(valueDone)
      ) : (
        <Avatar.Text
          {...props}
          size={AVATAR_SIZE}
          label={order?.toString()}
          labelStyle={{ color: palettes.primary[100] }}
          style={{
            backgroundColor: palettes.primary[70],
            marginRight: spacing16,
          }}
        />
      ),
    [valueDone, order],
  );

  return (
    <Card
      mode="outlined"
      contentStyle={{ padding: spacing16 }}
      style={{ backgroundColor: palettes.primary[100] }}>
      <Card.Title
        style={{ paddingLeft: 0 }}
        leftStyle={{ marginRight: spacing32 }}
        title={title}
        subtitle={subtitle}
        left={setCardLeftElement}
        right={setCardRightElement}
      />
    </Card>
  );
};

export default SeriesTile;
