import { FC, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { SvgCheckIllustration } from "@assets/svg";
import { ExpectItem } from "@screens/MessageCenter";
import { useTranslation } from "react-i18next";
import { spacing16 } from "@styles/spacing";
import { globalStyles } from "@styles/global";
import { StatusAppointmentType } from "@components/Status/status.types";
import { TransKey } from "@globalTypes/i18next";

type AppointmentSuccessConfirmationProps = {
  wasPaidFor: boolean;
  appointmentType: StatusAppointmentType;
};

const AppointmentSuccessConfirmation: FC<
  AppointmentSuccessConfirmationProps
> = ({ wasPaidFor, appointmentType }) => {
  const { t } = useTranslation();

  const subtitles = useMemo(() => {
    const subtitles: TransKey[] = [];
    if (appointmentType === "Stationary") subtitles.push("T00470");
    if (appointmentType === "Online") subtitles.push("T00471");
    return subtitles;
  }, [appointmentType]);

  return (
    <View>
      <View style={[globalStyles.gapMedium, styles.iconAndTextContainer]}>
        <SvgCheckIllustration />
        <Text variant="titleLarge">{t(wasPaidFor ? "T00592" : "T01172")}</Text>
        <Text variant="bodyMedium" style={{ textAlign: "center" }}>
          {t(wasPaidFor ? "T00593" : "T01173")}
        </Text>
      </View>
      <ExpectItem icon="hail" title="T00479" subtitles={subtitles} />
    </View>
  );
};

export default AppointmentSuccessConfirmation;

const styles = StyleSheet.create({
  iconAndTextContainer: {
    alignItems: "center",
    marginBottom: spacing16,
  },
});
