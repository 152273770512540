import {
  GetMedicalRecordsParams,
  MedicalRecord,
  MedicalRecordsSimpleGet,
  PostMedicalRecords,
  SharedMedicalRecordResponse,
  ShareMedicalRecordsParams,
  ShareMedicalRecordsResponse,
} from "@screens/Profiles/MyProfile/MedicalRecords/MedicalRecords.types";
import { CreateAndEditExerciseResponse } from "@screens/TrainingsAndExercises/exercise.types";
import { createFormDataForMedicalRecord } from "@utils/files";
import { AxiosRequestConfig } from "axios";
import { api } from "./api";
import { ResponseOptions } from "./api.types";
import { endpoints } from "./endpoints";

export const getMedicalRecordsOptions = async () =>
  await api.options<ResponseOptions>(endpoints.MEDICAL_RECORDS);

export const createMedicalRecordAsPatient = (
  data: PostMedicalRecords<number>,
) => {
  const { formData, options } = createFormDataForMedicalRecord(data);
  return api.post<MedicalRecord>(endpoints.MEDICAL_RECORDS, formData, options);
};

export const createMedicalRecordAsPhysio = (
  data: PostMedicalRecords<number>,
  patientId?: number,
) =>
  api.post<MedicalRecord>(endpoints.MEDICAL_RECORD_AS_PHYSIO, {
    ...data,
    sharedMedicalRecord: { asignee: patientId },
  });

export const editMedicalRecord = (
  id: number,
  data: PostMedicalRecords<number>,
) => {
  const { formData, options } = createFormDataForMedicalRecord(data);

  return api.patch<CreateAndEditExerciseResponse>(
    endpoints.MEDICAL_RECORD(id),
    formData,
    options,
  );
};

export const getMedicalRecord = async (id: number) => {
  const { data } = await api.get<MedicalRecord>(endpoints.MEDICAL_RECORD(id));
  return data;
};

export const deleteMedicalRecord = (id: number) =>
  api.delete(endpoints.MEDICAL_RECORD(id));

export const getMedicalRecords = async (params?: GetMedicalRecordsParams) => {
  const requestConfig: AxiosRequestConfig<GetMedicalRecordsParams> = {
    params: { ...params },
  };
  const { data } = await api.get<MedicalRecordsSimpleGet[]>(
    endpoints.MEDICAL_RECORDS,
    requestConfig,
  );
  return data;
};

export const shareMedicalRecords = (params: ShareMedicalRecordsParams) =>
  api.post<ShareMedicalRecordsResponse>(
    endpoints.SHARE_MEDICAL_RECORDS,
    params,
  );

export const getSharedMedicalRecord = (id: number) =>
  api.get<SharedMedicalRecordResponse>(endpoints.SHARE_MEDICAL_RECORD(id));

export const updateSharedMedicalRecord = (id: number, dueDate: string | null) =>
  api.patch<SharedMedicalRecordResponse>(endpoints.SHARE_MEDICAL_RECORD(id), {
    dueDate,
  });

export const addAttachmentToMedicalRecord = () => {};
