import { ReactNode } from "react";

export enum SystemMessage {
  REPORT_FORUM_TOPIC = "report_forum_topic",
  REPORT_FORUM_POST = "report_forum_post",
  REPORT_CHAT_MESSAGE = "report_chat_message",
  REPORT_MEDICAL_RECORD_TITLE = "report_medical_record_title",
  REPORT_MEDICAL_RECORD_DESCRIPTION = "report_medical_record_description",
  REPORT_MEDICAL_RECORD_ATTACHMENT = "report_medical_record_attachment",
  REPORT_TRAINING_TITLE = "report_training_title",
  REPORT_TRAINING_DESCRIPTION = "report_training_description",
  REPORT_TRAINING_INSTRUCTION = "report_training_instruction",
  REPORT_EXERCISE_TITLE = "report_exercise_title",
  REPORT_EXERCISE_INSTRUCTION = "report_exercise_instruction",
  REPORT_EXERCISE_VIDEO = "report_exercise_video",
  REPORT_EXERCISE_INSTRUCTION_VIDEO = "report_exercise_instruction_video",
  REPORT_EXERCISE_PHOTO = "report_exercise_photo",
}

export type ReportContent = {
  systemMessage: SystemMessage;
  item?: number;
  description: string;
};

export type ReportProps = Omit<ReportContent, "description">;

export type ReportContentResponse = ReportContent & {
  id: number;
};

export type ReportWrapperProps = ReportProps & {
  children: ReactNode;
  onPress?: () => void;
};
