import { isWeb } from "./constants";
import { getCookie } from "./getCookie";

const GA_COOKIE_NAME = "_ga";

export const getClientIdForGA = async () => {
  let clientId: string;
  if (isWeb) {
    const { getAnalytics, setUserProperties } =
      require("firebase/analytics") as typeof import("firebase/analytics");
    const analytics = getAnalytics();
    clientId = getCookie(GA_COOKIE_NAME);
    setUserProperties(analytics, { client_id: clientId });
  } else {
    const analyticsModule =
      require("@react-native-firebase/analytics") as typeof import("@react-native-firebase/analytics");
    const analytics = analyticsModule.default();
    clientId = await analytics.getAppInstanceId();
    await analytics.setUserProperty("client_id", clientId);
  }
  return clientId;
};
