import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = ({ fill = "#1B1B1F", ...props }: SvgProps) => (
  <Svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6001 4H18.6001V2H16.6001V4H8.6001V2H6.6001V4H5.6001C4.4901 4 3.6001 4.9 3.6001 6V20C3.6001 21.1 4.4901 22 5.6001 22H19.6001C20.7001 22 21.6001 21.1 21.6001 20V6C21.6001 4.9 20.7001 4 19.6001 4ZM19.6001 20H5.6001V9H19.6001V20ZM7.1001 13C7.1001 11.62 8.2201 10.5 9.6001 10.5C10.9801 10.5 12.1001 11.62 12.1001 13C12.1001 14.38 10.9801 15.5 9.6001 15.5C8.2201 15.5 7.1001 14.38 7.1001 13Z"
      fill={fill}
    />
  </Svg>
);

export default SvgComponent;
