import { TextButton } from "@components/Button";
import { useUserDetails } from "@hooks/index";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing40 } from "@styles/spacing";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { TransKey } from "@globalTypes/i18next";

type Props = {
  initialText?: TransKey;
  firstText: TransKey;
  secondText?: TransKey;
  textButtonLabel: TransKey;
  onTextButtonPress: () => void;
};
const TextSectionWithTextButton = ({
  initialText,
  firstText,
  secondText,
  textButtonLabel,
  onTextButtonPress,
}: Props) => {
  const { isPhysiotherapist } = useUserDetails();
  const { t } = useTranslation();
  return (
    <View style={globalStyles.gapMedium}>
      {isPhysiotherapist && (
        <TextButton
          title={textButtonLabel}
          onPress={onTextButtonPress}
          iconName="open-in-new"
          iconColor={palettes.primary[40]}
          containerStyle={{
            height: spacing40,
            justifyContent: "center",
          }}
        />
      )}
      {initialText && <Text variant="bodyMedium">{t(initialText)}</Text>}
      <Text variant="labelMedium">{t(firstText)}</Text>
      {secondText && <Text variant="labelMedium">{t(secondText)}</Text>}
    </View>
  );
};

export default TextSectionWithTextButton;
