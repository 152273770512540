import { AddressType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";

export const formatAddressToDisplay = (address: AddressType) => {
  if (!address) return null;

  const { street, buildingNumber, apartmentNumber, postalCode, town } = address;

  return `${street} ${buildingNumber}${
    apartmentNumber ? "/" + apartmentNumber : ""
  }, ${postalCode} ${town}`;
};
