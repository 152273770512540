import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={250} height={228} fill="none" {...props}>
    <Path
      fill="#F2F2F2"
      d="M7.122 26.107H134.13a6.51 6.51 0 0 1 6.502 6.503v188.33a6.51 6.51 0 0 1-6.502 6.503H7.122A6.51 6.51 0 0 1 .62 220.94V32.61a6.51 6.51 0 0 1 6.502-6.503Z"
    />
    <Path
      fill="#fff"
      d="M8.698 30.836h123.855a3.352 3.352 0 0 1 3.349 3.35v185.178a3.355 3.355 0 0 1-3.349 3.35H8.698a3.353 3.353 0 0 1-3.35-3.35V34.186a3.353 3.353 0 0 1 3.35-3.35Z"
    />
    <Path
      fill="#2F2E41"
      d="M94.769 100.001c0 11.904-11.008 10.104-24.586 10.104-13.579 0-24.587 1.8-24.587-10.104 0-11.905 5.377-33.006 24.587-33.006 19.87 0 24.586 21.101 24.586 33.006Z"
    />
    <Path
      fill="#FFB9B9"
      d="M123.692 200.77a7.244 7.244 0 0 0 .29-6.116 7.227 7.227 0 0 0-4.502-4.15l-28.167-59.586-12.885 9.035 31.739 55.73a7.276 7.276 0 0 0 13.525 5.087Z"
    />
    <Path
      fill="#CCC"
      d="m99.182 136.153-1.61-2.52-.822-1.293-6.325-9.915a9.005 9.005 0 0 0-16.625.428 9.008 9.008 0 0 0 .156 6.88l1.448 5.167 2.957 10.562c.18.65.562 1.226 1.091 1.643a3.219 3.219 0 0 0 3.314.465c.014-.007.034-.013.048-.02l7.652-3.422.013-.007 5.84-2.613 1.455-.647c.417-.185.79-.459 1.091-.801.32-.354.555-.775.687-1.233a3.203 3.203 0 0 0-.37-2.674Z"
    />
    <Path
      fill="#2F2E41"
      d="M58.813 220.635a3.247 3.247 0 0 0-5.53-.054c-.128.193-.23.401-.303.62l-.384 1.057h7.194l-.977-1.623Z"
    />
    <Path
      fill="#FFB9B9"
      d="M70.028 109.421c9.137 0 16.544-7.407 16.544-16.544s-7.407-16.544-16.544-16.544-16.544 7.407-16.544 16.544 7.407 16.544 16.544 16.544Z"
    />
    <Path
      fill="#3F3D56"
      d="M99.027 141.811a36.274 36.274 0 0 0-.162-1.751 36.762 36.762 0 0 0-1.293-6.426 20.82 20.82 0 0 0-.424-1.341 35.91 35.91 0 0 0-9.942-15.115 9.812 9.812 0 0 0-6.555-2.506H57.345a9.816 9.816 0 0 0-7.512 3.495 9.819 9.819 0 0 0-2.168 7.996c-.007.007-.007.007 0 .014.165.942.467 1.854.896 2.708l.943 1.879 6.318 12.637 7.861 15.722.458.916 29.295 8.056.121-.276c.108-.243.223-.492.324-.735.161-.363.316-.727.471-1.084a57.424 57.424 0 0 0 4.688-18.733v-.006c.11-1.815.106-3.636-.013-5.45Z"
    />
    <Path
      fill="#2F2E41"
      d="M94.352 166a3.158 3.158 0 0 0-.465-.957 12.579 12.579 0 0 0-2.674-2.64l-.006-.007c-3.55-2.694-10.596-5.833-24.21-5.914h-.02a3.246 3.246 0 0 0-2.957 1.906l-.337.734-2.169 4.769c-.185.408-.475.76-.842 1.018-3.442 2.438-20.113 16.112-10.326 46.134a325.243 325.243 0 0 1 3.422 11.216H83.11l-.627-4.715a2.498 2.498 0 0 1 .048-.923l11.808-48.728.054-.209a3.245 3.245 0 0 0-.04-1.684Z"
    />
    <Path
      fill="#3F3D56"
      d="M103.56 111.452h12.799a2.02 2.02 0 0 1 2.02 2.02v3.368a.678.678 0 0 1 .674.674v1.347a.678.678 0 0 1-.674.674v1.347a.678.678 0 0 1 .674.674v1.347a.673.673 0 0 1-.674.673v18.188a2.02 2.02 0 0 1-2.02 2.02H103.56a2.02 2.02 0 0 1-2.021-2.02v-28.292a2.02 2.02 0 0 1 2.021-2.02Z"
    />
    <Path
      fill="#4666FF"
      d="M102.887 141.764v-28.292a2.02 2.02 0 0 1 2.02-2.02h-1.347a2.02 2.02 0 0 0-2.021 2.02v28.292a2.02 2.02 0 0 0 2.021 2.02h1.347a2.02 2.02 0 0 1-2.02-2.02Z"
    />
    <Path
      fill="#fff"
      d="M109.623 123.577a2.695 2.695 0 1 0-.001-5.39 2.695 2.695 0 0 0 .001 5.39Z"
    />
    <Path
      fill="#FFB9B9"
      d="M106.204 130.322c-.219.301-.414.62-.582.952l-33.168 3.985-4.877-6.487-10.751 6.082 7.518 12.322a5.393 5.393 0 0 0 6.008 2.395l36.686-9.926a7.076 7.076 0 0 0 7.944 1.23 7.074 7.074 0 0 0 2.354-10.886 7.067 7.067 0 0 0-11.132.333Z"
    />
    <Path
      fill="#3F3D56"
      d="m72.177 131.108-9.295-12.044a8.417 8.417 0 0 0-15.217 7.099c-.007.007-.007.007 0 .014.139.367.305.723.498 1.064l1.341 3.523 4.075 10.744a3.015 3.015 0 0 0 2.243 1.893c.706.144 1.44.029 2.069-.324l2.95-1.65 10.414-5.82a3.066 3.066 0 0 0 1.488-2.041 3.037 3.037 0 0 0-.566-2.458Z"
    />
    <Path
      fill="#2F2E41"
      d="M50.985 95.959h6.544l2.886-9.949.578 9.949h3.127l1.684-5.803.337 5.803h23.24c0-11.905-7.842-21.555-17.514-21.555h-3.368c-9.673 0-17.514 9.65-17.514 21.555Z"
    />
    <Path
      fill="#CCC"
      d="M242.878 83.313H105.741a6.51 6.51 0 0 1-6.503-6.502V7.06a6.509 6.509 0 0 1 6.503-6.502h137.137a6.508 6.508 0 0 1 6.502 6.502v69.75a6.51 6.51 0 0 1-6.502 6.503Z"
    />
    <Path
      fill="#fff"
      d="M241.301 78.584H107.317a3.355 3.355 0 0 1-3.35-3.35V8.636a3.353 3.353 0 0 1 3.35-3.35h133.984a3.353 3.353 0 0 1 3.35 3.35v66.599a3.353 3.353 0 0 1-3.35 3.35Z"
    />
    <Path
      fill="#CCC"
      d="M113.963 96.134c-.333 0-.662-.067-.969-.196a2.45 2.45 0 0 1-1.537-2.3v-12.82l19.319-.488L115.707 95.4a2.452 2.452 0 0 1-1.744.735ZM224.988 38.949h-67.781a3.15 3.15 0 0 1-2.916-4.36A3.143 3.143 0 0 1 156 32.882c.383-.158.793-.239 1.207-.238h67.781a3.152 3.152 0 1 1 0 6.305ZM202.92 25.55h-45.712a3.154 3.154 0 0 1-2.23-5.381 3.154 3.154 0 0 1 2.23-.924h45.712a3.152 3.152 0 0 1 0 6.305Z"
    />
    <Path
      fill="#4666FF"
      d="M129.149 38.949c5.441 0 9.852-4.411 9.852-9.852 0-5.441-4.411-9.852-9.852-9.852-5.441 0-9.851 4.41-9.851 9.852 0 5.44 4.41 9.852 9.851 9.852Z"
    />
    <Path
      fill="#E6E6E6"
      d="M204.1 64.626a3.153 3.153 0 1 0 0-6.307 3.153 3.153 0 0 0 0 6.306ZM215.134 64.626a3.153 3.153 0 1 0 0-6.307 3.153 3.153 0 0 0 0 6.306ZM226.168 64.626a3.153 3.153 0 1 0 0-6.307 3.153 3.153 0 0 0 0 6.306Z"
    />
  </Svg>
);
export default SvgComponent;
