import { queryKeysVerficiations } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/queryKeysPhysiotherapistVerification";
import {
  getOnlinePaymentMethods,
  getStationaryPaymentMethods,
} from "@services/ApiService/verifications";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useTherapistPaymentMethods = (physiotherapistId: number) => {
  const { t } = useTranslation();
  const {
    data: stationaryPaymentMethods,
    isLoading: isStationaryPaymentMethodsLoading,
    isError: isStationaryPaymentMethodsError,
    refetch: refetchStationaryPaymentMethods,
  } = useQuery({
    queryKey:
      queryKeysVerficiations.stationaryPaymentMethods(physiotherapistId),
    queryFn: async () =>
      await getStationaryPaymentMethods({ physiotherapistId }),
    enabled: !!physiotherapistId,
  });

  const {
    data: onlinePaymentMethods,
    isLoading: isOnlinePaymentMethodsLoading,
    isError: isOnlinePaymentMethodsError,
    refetch: refetchOnlinePaymentMethods,
  } = useQuery({
    queryKey: queryKeysVerficiations.onlinePaymentMethods(physiotherapistId),
    queryFn: async () => await getOnlinePaymentMethods({ physiotherapistId }),
    enabled: !!physiotherapistId,
  });

  const isAnyLoading = useMemo(
    () => isStationaryPaymentMethodsLoading || isOnlinePaymentMethodsLoading,
    [isStationaryPaymentMethodsLoading, isOnlinePaymentMethodsLoading],
  );
  const isAnyError = useMemo(
    () => isStationaryPaymentMethodsError || isOnlinePaymentMethodsError,
    [isStationaryPaymentMethodsError, isOnlinePaymentMethodsError],
  );
  const refetchEverything = useCallback(
    async () =>
      await Promise.all([
        refetchStationaryPaymentMethods(),
        refetchOnlinePaymentMethods(),
      ]),
    [refetchStationaryPaymentMethods, refetchOnlinePaymentMethods],
  );

  let acceptableOnlinePaymentsDescription: string | null = null;

  onlinePaymentMethods?.forEach(
    ({ onlineAppointments, stationaryAppointments }) => {
      switch (true) {
        case onlineAppointments && stationaryAppointments:
          acceptableOnlinePaymentsDescription = t("T01140");
          break;
        case onlineAppointments:
          acceptableOnlinePaymentsDescription = t("T01141");
          break;
        case stationaryAppointments:
          acceptableOnlinePaymentsDescription = t("T01142");
          break;
        default:
          break;
      }
    },
  );

  return {
    stationaryMethods: stationaryPaymentMethods,
    onlineMethods: onlinePaymentMethods,
    isLoading: isAnyLoading,
    isError: isAnyError,
    refetch: refetchEverything,
    acceptableOnlinePaymentsDescription,
  };
};
