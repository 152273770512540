import { FC } from "react";
import BaseTrainingTile from "./BaseTrainingTile";
import { getLibraryTraining } from "@api/trainings";
import { TrainingTileProps } from "./trainingTile.types";
import { queryKeysTraining } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { useQuery } from "@tanstack/react-query";

const TrainingTile: FC<TrainingTileProps> = ({
  trainingId,
  onPress,
  ...props
}) => {
  const { isFetched, isError, refetch, data } = useQuery({
    queryKey: queryKeysTraining.detail(trainingId),
    queryFn: async () => await getLibraryTraining(trainingId),
  });

  return (
    <BaseTrainingTile
      data={data}
      isFetched={isFetched}
      isError={isError}
      refetch={refetch}
      onPress={!isError && onPress}
      {...props}
    />
  );
};

export default TrainingTile;
