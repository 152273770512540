import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { Calendar, DateArrayType } from "@components/Calendar";
import DropdownPicker from "@components/DropdownPicker/DropdownPicker";
import { getDate } from "@utils/date";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

export enum VisitDatePickerOptions {
  ASAP = "ASAP",
  THREE_DAYS_AHEAD = "THREE_DAYS_AHEAD",
  SEVEN_DAYS_AHEAD = "SEVEN_DAYS_AHEAD",
  SELECTED_DAY = "SELECTED_DAY",
}

type VisitDatePickerProps = {
  value: VisitDatePickerOptions;
  setValue: (val: VisitDatePickerOptions) => void;
  selectedDate: Date | null;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
};

const VisitDatePicker: FC<VisitDatePickerProps> = ({
  value,
  setValue,
  selectedDate,
  setSelectedDate,
}) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const defaultDate = useMemo(() => selectedDate || new Date(), [selectedDate]);

  const items = useMemo(() => {
    return [
      { value: VisitDatePickerOptions.ASAP, label: t("T00542") },
      {
        value: VisitDatePickerOptions.THREE_DAYS_AHEAD,
        label: t("T01266", { value: 3 }),
      },
      {
        value: VisitDatePickerOptions.SEVEN_DAYS_AHEAD,
        label: t("T01266", { value: 7 }),
      },
      {
        value: VisitDatePickerOptions.SELECTED_DAY,
        label: `${t("T00543")} (${getDate(defaultDate)})`,
      },
    ];
  }, [t, defaultDate]);

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const onSelectDate = useCallback(
    ({ date }: DateArrayType, dontClose?: boolean) => {
      !dontClose && setIsModalVisible(false);
      date && setSelectedDate(date);
    },
    [setSelectedDate],
  );

  return (
    <>
      <DropdownPicker<VisitDatePickerOptions>
        dropdownLabelText="T00544"
        items={items}
        defaultValue={value}
        onSelectItem={({ value }) => {
          setValue(value);
          value === VisitDatePickerOptions.SELECTED_DAY && openModal();
        }}
      />
      <BottomModalContainer
        modalVisible={isModalVisible}
        setModalVisible={setIsModalVisible}>
        <Calendar
          limitRange
          blockPast
          initialDates={[{ date: defaultDate }]}
          onDismiss={closeModal}
          onSelectDate={onSelectDate}
          yearsOption="future"
        />
      </BottomModalContainer>
    </>
  );
};

export default VisitDatePicker;
