import { appleAuthHelpers, AppleAuthResponse } from "react-apple-signin-auth";
import { SignInWithApple } from "./user.types";
import { v4 as uuid } from "uuid";

export const loginWithAppleWeb = async (
  redirectURI: string,
  onSuccess: SignInWithApple,
  onError: (err: unknown) => void,
) => {
  const script = document.createElement("script");
  script.src =
    "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
  script.defer = true;
  document.body.appendChild(script);

  const rawNonce = uuid();
  const state = uuid();

  await appleAuthHelpers.signIn({
    authOptions: {
      clientId: "com.fixmephysioapp.client-web",
      /** Requested scopes, seperated by spaces - eg: 'email name' */
      scope: "email name",
      nonce: rawNonce,
      state,
      redirectURI,
      usePopup: true, //redirect doesn't work without popup
    },
    onSuccess: async (response: AppleAuthResponse) => {
      const firstName = response?.user?.name?.firstName;
      const lastName = response?.user?.name?.lastName;
      const identityToken = response?.authorization.id_token;
      await onSuccess(firstName, lastName, identityToken);
    },
    onError,
  });
};
