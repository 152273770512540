import { StripeLogo, SvgCheckIllustration } from "@assets/svg";
import { PrimaryButton } from "@components/buttons";
import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { spacing32 } from "@styles/spacing";
import { isWeb } from "@utils/constants";
import { showAlert } from "@utils/index";
import { maybeCompleteAuthSession } from "expo-web-browser";
import { FC, PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";

const SuccessStripeModal: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      ProfileCompletionStackParamList,
      "SuccessStripeModal"
    >
  >
> = ({ navigation: { goBack } }) => {
  const { t } = useTranslation();
  const { container, gapLarge } = globalStyles;
  const { contentContainer, logoContainer, textAlign } = styles;

  useEffect(() => {
    if (isWeb) {
      const { type, message } = maybeCompleteAuthSession({
        skipRedirectCheck: true,
      });
      if (type === "failed") return showAlert(message);
    }
  }, []);
  return (
    <SafeAreaView edges={["bottom", "left", "right"]} style={container}>
      <View style={contentContainer}>
        <View style={logoContainer}>
          <SvgCheckIllustration />
          <StripeLogo />
          <Text variant="bodyLarge" style={textAlign}>
            {t("T01327")}
          </Text>
        </View>
        <View style={gapLarge}>
          <Text variant="bodyMedium">{t("T01328")}</Text>
          <Text variant="bodyMedium">{t("T01329")}</Text>
        </View>
      </View>
      <PrimaryButton label="T01330" onPress={goBack} />
    </SafeAreaView>
  );
};

export default SuccessStripeModal;

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    justifyContent: "center",
    gap: spacing32,
  },
  logoContainer: { alignItems: "center" },
  textAlign: { textAlign: "center" },
});
