export const setLanguagesText = (data: string[]): string => {
  switch (data.length) {
    case 0:
      return "";
    case 1:
      return data[0];
    default:
      return `${data[0]}, ${data[1]}`;
  }
};
