import { getExercise, getSeries } from "@api/exercises";
import { useQueries, useQuery } from "@tanstack/react-query";
import { FC, useMemo, useRef, useState } from "react";
import ExerciseBaseTile from "./ExerciseBaseTile";

import RefetchIconButton from "@components/Tile/components/RefetchIconButton";
import { setExerciseTileSubtitles } from "@components/Tile/exercise/exerciseTileHelpers";
import { queryKeysExercises } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { ActivityIndicator } from "react-native-paper";
import { DefaultTileProps } from "@components/Tile/types/Tile.types";
import { BaseSeries } from "@screens/TrainingsAndExercises/exercise.types";
import { PrimaryButton } from "@components/buttons";
import { useAppTheme } from "@styles/theme";
import { Animated, Easing, View } from "react-native";
import { globalStyles } from "@styles/global";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { RootStackParamList } from "@navigators/navigation.types";
import { spacing16 } from "@styles/spacing";
import { useAssistant } from "@hooks/user/useAssistant";

type ExerciseWithSeriesTileProps = DefaultTileProps & {
  onPress?: (exerciseId: number) => void;
  exerciseId: number;
  appliedSeries?: BaseSeries[];
  displayAlternative?: boolean;
};

const ExerciseWithSeriesTile: FC<ExerciseWithSeriesTileProps> = ({
  exerciseId,
  onPress,
  appliedSeries,
  displayAlternative = true,
  ...props
}) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysExercises.detail(exerciseId),
    queryFn: async () => await getExercise(exerciseId),
    enabled: !!exerciseId,
  });
  const {
    colors: { primary },
  } = useAppTheme();
  const [isAltHidden, setIsAltHidden] = useState(true);
  const [tileHeight, setTileHeight] = useState(0);
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { isAssistantId } = useAssistant(data?.author);
  const animatedHeight = useRef(new Animated.Value(0)).current;

  const queries = useMemo(
    () =>
      data?.series.length > 0
        ? data.series.map(seriesId => ({
            queryKey: queryKeysExercises.seriesDetails({
              seriesId,
              exerciseId,
            }),
            queryFn: async () => await getSeries(seriesId),
          }))
        : [],
    [data, exerciseId],
  );

  const series = useQueries({ queries });

  const seriesData = useMemo(() => series.map(s => s.data), [series]);

  const anyLoading = useMemo(
    () => isLoading || series?.some(({ isLoading }) => isLoading),
    [isLoading, series],
  );

  const anyError = useMemo(
    () => isError || series?.some(({ isError }) => isError),
    [isError, series],
  );

  const seriesRefetch = series?.map(({ refetch }) => refetch);

  const refetchAll = async () =>
    await Promise.all([refetch(), ...seriesRefetch]);

  const toggleSection = () => {
    setIsAltHidden(prev => !prev);
    Animated.timing(animatedHeight, {
      toValue: !isAltHidden ? 0 : tileHeight + spacing16,
      duration: 250,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();
  };

  if (anyLoading)
    return (
      <ExerciseBaseTile
        title=""
        subtitles={[""]}
        right={() => <ActivityIndicator />}
        image=""
        {...props}
      />
    );

  if (anyError)
    return (
      <ExerciseBaseTile
        title=""
        subtitles={[""]}
        right={() => <RefetchIconButton onPress={refetchAll} />}
        image=""
        onPress={null}
        {...props}
      />
    );

  return (
    <View style={globalStyles.gapMedium}>
      <ExerciseBaseTile
        title={data?.name}
        subtitles={setExerciseTileSubtitles(appliedSeries || seriesData)}
        onPress={onPress ? () => onPress(exerciseId) : null}
        image={data?.exerciseImageUrl}
        style={data?.alternativeExercise && { borderColor: primary }}
        onLayout={e => setTileHeight(e.nativeEvent.layout.height)}
        displayExtraIcon={isAssistantId}
        {...props}
      />

      {displayAlternative && data?.alternativeExercise && (
        <>
          <Animated.View style={{ height: animatedHeight, overflow: "hidden" }}>
            <ExerciseBaseTile
              title={data.alternativeExercise.name}
              image={data.alternativeExercise.exerciseImageUrl}
              style={{ borderColor: primary }}
              subtitles={setExerciseTileSubtitles(
                data.alternativeExercise.series,
              )}
              onPress={
                onPress
                  ? () =>
                      navigate("ExerciseDetails", {
                        id: data.alternativeExercise.id,
                      })
                  : null
              }
              displayExtraIcon={isAssistantId}
              {...props}
            />
          </Animated.View>
          <PrimaryButton
            label={isAltHidden ? "T00934" : "T00935"}
            mode="text"
            icon={isAltHidden ? "eye-outline" : "eye-off-outline"}
            onPress={toggleSection}
          />
        </>
      )}
    </View>
  );
};

export default ExerciseWithSeriesTile;
