import { FC } from "react";
import { IconButton } from "react-native-paper";
import { useAppTheme } from "@styles/theme";

type RefetchIconButtonProps = {
  onPress: () => void;
};

const RefetchIconButton: FC<RefetchIconButtonProps> = ({ onPress }) => {
  const {
    colors: { error },
  } = useAppTheme();
  return (
    <IconButton icon="reload" size={36} onPress={onPress} iconColor={error} />
  );
};

export default RefetchIconButton;
