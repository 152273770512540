import {
  createMaterialTopTabNavigator,
  MaterialTopTabScreenProps,
} from "@react-navigation/material-top-tabs";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { RootStackParamList } from "@navigators/navigation.types";
import { theme } from "@styles/theme";
import Exercises from "./Physiotherapist/Exercises";
import Trainings from "./Physiotherapist/Trainings";
import { TrainingsAndExercisesTabsParamList } from "./tabsParamsList.types";
import { fontConfig } from "@styles/fontConfig";

const Tab = createMaterialTopTabNavigator<TrainingsAndExercisesTabsParamList>();

const TrainingsAndExercisesModal: FC<
  PropsWithChildren<
    MaterialTopTabScreenProps<RootStackParamList, "TrainingsAndExercisesModal">
  >
> = ({ route }) => {
  const { patientId } = (route || {}).params;
  const { t } = useTranslation();

  //todo: move navigator to separate component - same navigator used 2x
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: {
          backgroundColor: theme.colors.surface,
        },
        tabBarLabelStyle: { textTransform: "none", ...fontConfig.titleSmall },
      }}>
      <Tab.Screen
        initialParams={{ patientId }}
        name="Trainings"
        component={Trainings}
        options={{ title: t("T00167") }}
      />
      <Tab.Screen
        name="Exercises"
        component={Exercises}
        options={{ title: t("T00169") }}
      />
    </Tab.Navigator>
  );
};

export default TrainingsAndExercisesModal;
