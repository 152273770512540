export const calculateMonth = (
  firstDay: number,
  currentMonth: number,
  currentYear: number,
  mode: "week" | "month",
): Date[][] => {
  const days = [
    "T00438",
    "T00439",
    "T00440",
    "T00441",
    "T00442",
    "T00443",
    "T00444",
  ];

  const monthMap: Date[][] = [];
  let currentDayNumber = 0;

  const calculateMonthDays = new Date();
  calculateMonthDays.setHours(0, 0, 0, 0);
  calculateMonthDays.setFullYear(currentYear);
  calculateMonthDays.setDate(1);
  calculateMonthDays.setMonth(currentMonth + 1);
  calculateMonthDays.setDate(0);

  const numberOfDays = calculateMonthDays.getDate();

  calculateMonthDays.setDate(1);

  const getNewDate = (date: Date, day: number): Date => {
    const temp = new Date(date);
    temp.setHours(0, 0, 0, 0);
    temp.setDate(day);
    return temp;
  };

  const getWeekBeforeFirst = (): Date[] => {
    const tempWeekBeforeFirst = days.slice(0, firstDay - 1).map((_, i) => {
      const dateBefore = new Date(
        calculateMonthDays.getFullYear(),
        calculateMonthDays.getMonth(),
        1 - i - 1,
      );
      return mode === "week"
        ? getNewDate(dateBefore, dateBefore.getDate())
        : null;
    });

    return tempWeekBeforeFirst.reverse();
  };

  const getFirstWeek = (): Date[] => {
    return days.slice(firstDay - 1).map(() => {
      currentDayNumber++;
      const temp = getNewDate(calculateMonthDays, currentDayNumber);
      return temp;
    });
  };
  const firstWeek = getFirstWeek();

  const resultWeek = [...getWeekBeforeFirst(), ...firstWeek];
  monthMap.push(resultWeek);

  const lastDayOfFirstWeek = resultWeek[resultWeek.length - 1].getDate();
  const howManyFullWeeksLeft = Math.floor(
    (numberOfDays - lastDayOfFirstWeek) / 7,
  );

  const getNextWeeks = (): void => {
    for (let i = 0; i < howManyFullWeeksLeft; i++) {
      const temp = days.map(() => {
        currentDayNumber++;
        const tempDate = new Date(
          calculateMonthDays.getFullYear(),
          calculateMonthDays.getMonth(),
          currentDayNumber,
        );
        return tempDate;
      });

      monthMap.push(temp);
    }
  };

  getNextWeeks();

  if (currentDayNumber < numberOfDays) {
    const tempLastWeek = days.map(() => {
      currentDayNumber++;
      if (currentDayNumber <= numberOfDays) {
        const tempDate = new Date(
          calculateMonthDays.getFullYear(),
          calculateMonthDays.getMonth(),
          currentDayNumber,
        );
        return tempDate;
      }
      return null;
    });

    const getWeekAfterLastWeek = (): Date[] => {
      const tempWeekAfterLastWeek = tempLastWeek
        .filter(e => e === null)
        .map((_, i) => {
          const tempDate = new Date(calculateMonthDays);
          tempDate.setHours(0, 0, 0, 0);
          const resultDate = new Date(
            tempDate.getFullYear(),
            tempDate.getMonth() + 1,
            1 + i,
          );
          return mode === "week" ? resultDate : null;
        });

      return tempWeekAfterLastWeek;
    };

    const resultLastWeek = [
      ...tempLastWeek.filter(e => e !== null),
      ...getWeekAfterLastWeek(),
    ];

    monthMap.push(resultLastWeek);
  }

  return monthMap;
};
