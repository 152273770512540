import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill="#000"
      d="m5.795 7.41 4.59 4.59-4.59 4.59L7.205 18l6-6-6-6-1.41 1.41ZM16.205 6h2v12h-2V6Z"
    />
  </Svg>
);
export default SvgComponent;
