import { JWTToken, User } from "../contexts/AuthContext/user.types";
import jwt_decode from "jwt-decode";
import { camelizeKeys } from "humps";
import { omit } from "lodash";

export const convertTokenToUserData = (token: string): User => {
  const decodedUserData: JWTToken = jwt_decode(token);

  const userData = omit(decodedUserData, [
    "exp",
    "token_type",
    "iat",
    "jti",
    "user_id",
  ] as (keyof JWTToken)[]);
  return camelizeKeys(userData) as User;
};
