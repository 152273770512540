export * from "./SvgMedicalRecords1";
export * from "./SvgMedicalRecords2";
export * from "./SvgAssignWorkoutToPatient";
export * from "./SvgWorkoutStatistics";
export * from "./SvgProphylacticWorkout1";
export * from "./SvgProphylacticWorkout2";
export * from "./SvgWorkoutCalendar1";
export * from "./SvgWorkoutCalendar2";
export * from "./SvgOnlinePaymentsIcon1";
export * from "./SvgOnlinePaymentsIcon2";
export * from "./SvgOnlinePaymentsIcon3";
export * from "./SvgOnlinePaymentsIcon4";

export const tutorialSvgRatio = 329 / 136;
