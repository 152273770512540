import { RootStackParamList } from "@navigators/navigation.types";
import { MaterialTopTabScreenProps } from "@react-navigation/material-top-tabs";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { FC, PropsWithChildren } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { AppointmentDetailsTabsParamList } from ".";
import { MedicalRecordsListForAppointmentDetailsAndSession } from "../MedicalRecordsListForAppointmentDetailsAndSession";

export const Files: FC<
  PropsWithChildren<
    MaterialTopTabScreenProps<AppointmentDetailsTabsParamList, "Files">
  >
> = ({ route }) => {
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { appointmentId } = route.params;

  const navigateToRecordPreview = (id: number) => {
    navigate("RecordPreview", { id });
  };

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "bottom", "left"]}>
      <MedicalRecordsListForAppointmentDetailsAndSession
        appointmentId={appointmentId}
        onTilePress={navigateToRecordPreview}
        sectionListTitle="T00334"
      />
    </SafeAreaView>
  );
};
