import Svg, {
  SvgProps,
  G,
  Path,
  Circle,
  Defs,
  ClipPath,
} from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#000"
        d="M10 1.667c-3.5 0-6.667 2.683-6.667 6.833 0 2.65 2.042 5.767 6.117 9.359a.848.848 0 0 0 1.108 0c4.067-3.592 6.109-6.709 6.109-9.359 0-4.15-3.167-6.833-6.667-6.833ZM10 10c-.917 0-1.667-.75-1.667-1.666 0-.917.75-1.667 1.667-1.667s1.667.75 1.667 1.667C11.667 9.25 10.917 10 10 10Z"
      />
      <Circle cx={16} cy={16} r={4} fill="#4ACB29" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h20v20H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgComponent;
