import { palettes, zoomFocusedBorderColor } from "@styles/colors";
import { spacing12, spacing2, spacing8 } from "@styles/spacing";
import { AVATAR_SIZE } from "@utils/constants";
import { StyleSheet } from "react-native";

export const videoViewStyles = StyleSheet.create({
  fullScreen: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  smallView: {
    width: 100,
    height: 100,
    marginHorizontal: 4,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: palettes.neutral[40],
  },
  zoomView: {
    width: "100%",
    height: "100%",
  },
  focusedBorder: {
    borderColor: zoomFocusedBorderColor,
  },
  userInfo: {
    display: "none",
    flexDirection: "row",
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    paddingVertical: spacing2,
    paddingHorizontal: spacing8,
    borderBottomLeftRadius: spacing8,
    borderBottomRightRadius: spacing8,
  },
  userName: {
    fontSize: spacing12,
    color: palettes.primary["100"],
  },
  audioStatusIcon: {
    width: spacing12,
    height: spacing12,
  },
  additionalContainerStyles: {
    justifyContent: "center",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  avatarLarge: {
    width: AVATAR_SIZE * 2,
    height: AVATAR_SIZE * 2,
    borderRadius: AVATAR_SIZE * 2,
  },
  smallCameraWindow: {
    position: "absolute",
    width: 160,
    height: 90,
    bottom: 100,
    left: 50,
    zIndex: 2,
    backgroundColor: palettes.neutral[40],
    justifyContent: "center",
    alignItems: "center",
  },
  singleSelfView: {
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
  floatingAvatarWeb: { width: 80, height: 80, borderRadius: 80 },
  floatingAvatarContainerWeb: {
    flexDirection: "row",
    width: 160,
    height: 90,
    justifyContent: "space-evenly",
    alignItems: "center",
  },
});
