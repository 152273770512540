import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { globalStyles } from "../../../styles/global";
import StatusComponent from "../../Status/StatusComponent";
import { CardContentProps } from "./UpcomingAppointmentCard.types";
import Avatar from "@components/Avatar";

const CardContent: FC<CardContentProps> = ({ subtitle, status, data }) => (
  <View style={[styles.cardContentStyle, globalStyles.gapLarge]}>
    <Avatar
      firstName={data?.firstName}
      userId={data?.id}
      image={data?.avatar}
    />
    <View style={[globalStyles.gapSmall, { flex: 1 }]}>
      <Text variant="titleMedium">{`${data?.firstName} ${data?.lastName}`}</Text>
      {subtitle ? <Text variant="bodyMedium">{subtitle}</Text> : null}
      {status ? <StatusComponent status={status} /> : null}
    </View>
  </View>
);

const styles = StyleSheet.create({
  cardContentStyle: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default CardContent;
