import { Button as PaperButton } from "react-native-paper";
import React from "react";
import { ButtonBasic } from "./Button.types";
import { useTranslation } from "react-i18next";
import { TransKey } from "@globalTypes/i18next";

export const Button = ({ label, ...props }: ButtonBasic) => {
  const { t } = useTranslation();
  return <PaperButton {...props}>{t(label as TransKey)}</PaperButton>;
};

export default Button;
