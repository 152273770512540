import { FetchError } from "@components/errors";
import ExerciseTile from "@components/Tile/exercise/ExerciseTile";
import useExercisesInfiniteQuery from "@hooks/pagination/useExercisesInfiniteQuery";
import { globalStyles } from "@styles/global";
import { spacing16, spacing8 } from "@styles/spacing";
import { FC, PropsWithChildren, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  FlatList,
  ListRenderItem,
  RefreshControl,
  StyleSheet,
} from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { PrimaryButton, SearchbarButton } from "../../../components/buttons";
import { PhysiotherapistTrainingsTabProps } from "../training.types";
import PaginationBottomLoader from "@components/Loader/PaginationBottomLoader";

const Exercises: FC<PropsWithChildren<PhysiotherapistTrainingsTabProps>> = ({
  navigation: { navigate },
}) => {
  const { t } = useTranslation();

  const {
    query: {
      data,
      isInitialLoading,
      isLoadingError,
      refetch,
      isFetching,
      isFetchingNextPage,
    },
    loadMore,
  } = useExercisesInfiniteQuery({});

  const renderItem: ListRenderItem<{ id: number }> = useCallback(
    ({ item: { id } }) => (
      <ExerciseTile
        exerciseId={id}
        onPress={() => navigate("ExerciseDetails", { id })}
      />
    ),
    [navigate],
  );

  const renderListHeader = () => (
    <>
      <PrimaryButton
        label="T00070"
        onPress={() => navigate("CreateAndEditExercise")}
        style={{ marginVertical: spacing8 }}
      />
      <SearchbarButton
        onPress={() =>
          navigate("CreateTrainingStack", {
            screen: "SearchExercises",
            params: { isFromExerciseList: true },
          })
        }
        label="T01340"
      />
      <Text variant="titleMedium" style={{ marginTop: spacing8 }}>
        {t("T01503")}:
      </Text>
    </>
  );

  if (isInitialLoading) return <ActivityIndicator />;

  if (isLoadingError) return <FetchError action={refetch} />;

  return (
    <FlatList
      data={data.pages.flatMap(page => page.results)}
      renderItem={renderItem}
      keyExtractor={item => item.id.toString()}
      refreshControl={
        <RefreshControl refreshing={isFetching} onRefresh={refetch} />
      }
      ListHeaderComponent={renderListHeader}
      ListHeaderComponentStyle={styles.listHeaderStyle}
      contentContainerStyle={[styles.listStyles, globalStyles.gapMedium]}
      onEndReached={loadMore}
      ListFooterComponent={() =>
        isFetchingNextPage && <PaginationBottomLoader />
      }
    />
  );
};

const styles = StyleSheet.create({
  listStyles: {
    paddingHorizontal: spacing16,
    paddingBottom: spacing16,
  },
  listHeaderStyle: {
    marginTop: spacing8,
    ...globalStyles.gapMedium,
  },
});

export default Exercises;
