import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { palettes } from "@styles/colors";
import { SeriesType } from "@screens/TrainingsAndExercises/exercise.types";
import { useTranslation } from "react-i18next";
import { spacing28, spacing4, spacing8 } from "@styles/spacing";
import { globalStyles } from "@styles/global";

type SeriesInfoProps = {
  order: number;
  value: number;
  seriesBreak: number;
  seriesType: SeriesType;
};

const SeriesInfo: FC<SeriesInfoProps> = ({
  order,
  value,
  seriesType,
  seriesBreak,
}) => {
  const { container, orderStyle } = styles;
  const { t } = useTranslation();
  const seriesTimeUnit = t("T00763");
  return (
    <View style={container}>
      <View style={orderStyle}>
        <Text variant="bodyMedium">{order}</Text>
      </View>
      <Text variant="bodyMedium">{`${value}${
        seriesType === SeriesType.REPETITIONS ? t("T00764") : seriesTimeUnit
      }`}</Text>
      <Text variant="bodyMedium">{`${seriesBreak}${seriesTimeUnit}`}</Text>
    </View>
  );
};

export default SeriesInfo;

const styles = StyleSheet.create({
  container: {
    ...globalStyles.gapMedium,
    flexDirection: "row",
    alignItems: "center",
  },
  orderStyle: {
    backgroundColor: palettes.primary[80],
    width: spacing28,
    height: spacing28,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: spacing4,
    marginRight: spacing8,
  },
});
