import { palettes } from "@styles/colors";
import { fontConfig } from "@styles/fontConfig";
import { theme } from "@styles/theme";
import { TouchableOpacity } from "react-native";
import { MessageOptions, showMessage } from "react-native-flash-message";
import { Icon } from "react-native-paper";

type CustomMessageProps = {
  rightIcon?: string;
  onPressRightIcon?: () => void;
  message: string;
};

type ShowSnackbarProps = CustomMessageProps & MessageOptions;

export const showSnackbar = ({
  message,
  rightIcon,
  onPressRightIcon,
  autoHide = true,
  ...props
}: ShowSnackbarProps) => {
  showMessage({
    message,
    icon: () => (
      <TouchableOpacity onPress={onPressRightIcon}>
        <Icon size={24} source={rightIcon} color={theme.colors.white} />
      </TouchableOpacity>
    ),
    type: "default",
    backgroundColor: palettes.neutral[20],
    duration: 2000,
    style: {
      flexDirection: "row-reverse",
      alignItems: "center",
    },
    titleStyle: fontConfig.bodyMedium,
    autoHide,
    ...props,
  });
};
