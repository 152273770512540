import { getPhysiotherapistAvailabilityGroups } from "@api/users";
import { AvailabilityGroup } from "@components/PhysioAvailability/PhysioAvailability.types";
import { FetchError } from "@components/errors";
import { useAuth } from "@contexts/AuthContext/auth";
import { queryKeysPhysiotherapist } from "@screens/Appointments/queryKeysAppointments";
import { DeleteAvailabilityRecordBottomSheet } from "@screens/Calendar/Availability";
import AvailabilityTile from "@screens/Calendar/AvailabilityTile";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { formatDateWithDayName, getDays } from "@utils/date";
import { format } from "date-fns";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { AppointmentSlots } from "./availability.types";

type AvailabilityListProps = {
  date: Date;
  displayTitle?: boolean;
};

const AvailabilityList: FC<AvailabilityListProps> = ({
  date,
  displayTitle = true,
}) => {
  const { startOfDayDate, endOfDayDate } = getDays(date);
  const {
    user: { id },
  } = useAuth();
  const { t } = useTranslation();
  const [
    deleteAvailabilityRecordModalVisible,
    setDeleteAvailabilityRecordModalVisible,
  ] = useState(false);
  const [groupDetails, setGroupDetails] = useState<AvailabilityGroup>({
    serviceType: AppointmentSlots.ONLINE_OR_STATIONARY,
    hours: [{ id: null, dateFrom: "", dateTo: "" }],
  });
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapist.availabilityGroupsForSelectedDay({
      physioId: id,
      date,
    }),
    queryFn: () =>
      getPhysiotherapistAvailabilityGroups({
        dateFrom: startOfDayDate,
        dateTo: endOfDayDate,
      }),
    select: data => {
      const currentSelectedDate = format(date, "yyyy-MM-dd");
      return data[currentSelectedDate];
    },
  });
  const {
    user: { language },
  } = useAuth();

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <>
      <View style={globalStyles.gapMedium}>
        {displayTitle && (
          <>
            <Text variant="titleMedium">
              {formatDateWithDayName(language, date)}
            </Text>
            <Text variant="bodyMedium">{t("T00482")}</Text>
          </>
        )}
        {data?.map((element, index) => {
          return (
            <AvailabilityTile
              mode={!displayTitle ? "contained" : null}
              key={`availability-list-${element.serviceType}-${index}`}
              onPress={() => {
                setGroupDetails(element);
                setDeleteAvailabilityRecordModalVisible(true);
              }}
              data={element}
            />
          );
        })}
      </View>
      <DeleteAvailabilityRecordBottomSheet
        modalVisible={deleteAvailabilityRecordModalVisible}
        setModalVisible={setDeleteAvailabilityRecordModalVisible}
        groupDetails={groupDetails}
      />
    </>
  );
};

export default AvailabilityList;
