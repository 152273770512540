import Svg, { G, Path, Defs, ClipPath, Rect, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
    <G clipPath="url(#clip0_54544_41058)">
      <Path
        d="M25.885 11.77L24.115 10l-10 10 10 10 1.77-1.77-8.23-8.23 8.23-8.23z"
        fill={props.color || "#000"}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_54544_41058">
        <Rect width={40} height={40} rx={20} fill="#fff" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgComponent;
