import { SvgOpenLink } from "@assets/svg";
import { BottomTutorialComponent } from "@components/BottomSheet";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";

import { palettes } from "@styles/colors";
import { spacing8 } from "@styles/spacing";
import { FC } from "react";

import { Trans, useTranslation } from "react-i18next";
import { Linking, StyleSheet, View } from "react-native";
import { Button, Text } from "react-native-paper";
import { TutorialBottomSheetProps } from "./Payments/OnlinePaymentsTutorial";

const ComissionTutorial: FC<TutorialBottomSheetProps> = ({
  modalVisible,
  setModalVisible,
}) => {
  const { t } = useTranslation();
  return (
    <BottomModalContainer
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}>
      <BottomTutorialComponent
        title="T01117"
        content={[
          {
            text: "T01118",
          },
          {
            text: "T01119",
          },
          {
            transComponent: () => (
              <Text variant="bodyMedium">
                <Trans
                  i18nKey="T01120"
                  components={{
                    1: (
                      <Text
                        style={{
                          fontWeight: "bold",
                        }}>
                        will be reduced by the operator&apos commission
                      </Text>
                    ),
                  }}
                />
              </Text>
            ),
          },
        ]}
        onPress={() => setModalVisible(false)}>
        <Button
          onPress={async () => {
            await Linking.openURL("https://stripe.com/en-pl/pricing");
          }}>
          <View style={styles.buttonContentContainer}>
            <SvgOpenLink />
            <Text variant="bodyMedium" style={{ color: palettes.primary[40] }}>
              {t("T01121")}
            </Text>
          </View>
        </Button>
      </BottomTutorialComponent>
    </BottomModalContainer>
  );
};

const styles = StyleSheet.create({
  buttonContentContainer: {
    gap: spacing8,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default ComissionTutorial;
