import { Appointment } from "@screens/Appointments/appointment.types";
import { t } from "i18next";
import { Alarm, ISODateString } from "react-native-calendar-events";
import { formatAddressToDisplay } from "./formatAddressToDisplay";
import {
  PublicPhysiotherapistProfile,
  PublicUserProfile,
} from "@contexts/AuthContext/user.types";

import { isANDROID, isIOS, isWeb } from "./constants";
import { Platform } from "react-native";

type RNCalendarEvents = Platform["OS"] extends "web"
  ? unknown
  : typeof import("react-native-calendar-events");
export type AuthorizationStatus = Platform["OS"] extends "web"
  ? unknown
  : import("react-native-calendar-events").AuthorizationStatus;

export let RNCalendarEvents: RNCalendarEvents;

export const loadCalendarEvents = () => {
  if (!isWeb) {
    RNCalendarEvents =
      require("react-native-calendar-events") as typeof import("react-native-calendar-events");
  }
};

type SystemCalendarData = {
  title: string;
  startDate: ISODateString;
  endDate: ISODateString;
  location: string;
  alarms: Array<Alarm<ISODateString | number>>;
};

type SystemCalendarDataIOS = SystemCalendarData & {
  notes?: string;
};

type SystemCalendarDataAndroid = SystemCalendarData & {
  description?: string;
  calendarId?: string;
};

type AppointmentPartialType = Pick<
  Appointment,
  "serviceType" | "address" | "name"
>;
type UserBasicData = Pick<
  PublicPhysiotherapistProfile | PublicUserProfile,
  "firstName" | "lastName"
>;

const createCalendarEventTitleAndLocationInfo = (
  { serviceType, address, name }: AppointmentPartialType,
  profileData: UserBasicData,
) => ({
  title:
    name || serviceType === "Online"
      ? t("T01045", {
          value: `${profileData.firstName} ${profileData.lastName}`,
        })
      : t("T01044", {
          value: `${profileData.firstName} ${profileData.lastName}`,
        }),
  location:
    serviceType === "Online"
      ? t("T01046")
      : `${formatAddressToDisplay(address)}`,
  description: t(serviceType === "Online" ? "T01047" : "T01048"),
});

const getFirstPrimaryCalendarId = async () =>
  (await RNCalendarEvents.default.findCalendars()).filter(c => c.isPrimary)[0]
    .id;

export const checkIfEventEnded = (endDate: string) => {
  const currentTime = new Date().getTime();
  const dateTo = new Date(endDate).getTime();
  return dateTo < currentTime;
};

export const createDataForCalendarEvent = async (
  { serviceType, dateFrom, dateTo, address, name }: Appointment,
  profileData: PublicPhysiotherapistProfile | PublicUserProfile,
): Promise<SystemCalendarDataIOS | SystemCalendarDataAndroid> => {
  const { description, ...rest } = createCalendarEventTitleAndLocationInfo(
    { serviceType, address, name },
    profileData,
  );
  const calendarData: SystemCalendarDataIOS | SystemCalendarDataAndroid = {
    startDate: new Date(dateFrom).toISOString(),
    endDate: new Date(dateTo).toISOString(),
    alarms: [{ date: isIOS ? -30 : 30 }],
    ...rest,
  };
  if (isIOS) {
    (calendarData as SystemCalendarDataIOS).notes = description;
  }
  if (isANDROID) {
    const calendarId = await getFirstPrimaryCalendarId();
    (calendarData as SystemCalendarDataAndroid).description = description;
    (calendarData as SystemCalendarDataAndroid).calendarId = calendarId;
  }
  return calendarData;
};
