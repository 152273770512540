import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { RefreshControl, ScrollView } from "react-native";
import { Divider } from "react-native-paper";

import {
  PhysiotherapistRatings,
  WaitingForPlan,
  WelcomeSection,
} from "./Physiotherapist";

import { globalStyles } from "@styles/global";

import Snackbar from "@components/Snackbar/Snackbar";
import { useErrors } from "@hooks/useErrors";
import {
  CreateTrainingStackParamsList,
  PatientProfileStackParamsList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import MonthlyStatisticsSection from "@screens/Home/Physiotherapist/MonthlyStatisticsSection";
import { getSpecialTrainings } from "@services/ApiService/trainings";
import { Host } from "react-native-portalize";
import { queryKeysSpecialTrainings } from "../TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { queryKeysAppointments } from "@screens/Appointments/queryKeysAppointments";
import { formatDateForApi, getCurrentDatePlus30min } from "@utils/date";
import { getAppointmentsList } from "@services/ApiService/appointments";
import { useTranslation } from "react-i18next";
import {
  useAnalytics,
  useDataForVerificationActionTileProfile,
  useSubscriptionStatus,
  useUserDetails,
} from "@hooks/index";
import { useUpdateUser } from "@hooks/user/useUpdateUser";
import { useWelcomeToProductModal } from "@hooks/index";
import { useFutureAvailability } from "@hooks/availability/useFutureAvailability";
import { AnalyticsEventName } from "@globalTypes/analytics.types";

type HomeScreenPhysiotherapistScreen = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<
      RootStackParamList & PatientProfileStackParamsList,
      "Home"
    >,
    NativeStackScreenProps<
      CreateTrainingStackParamsList,
      "CreateAndEditTraining"
    >
  >
>;

export const HomeScreenPhysiotherapist: FC<HomeScreenPhysiotherapistScreen> = ({
  navigation: { navigate },
}) => {
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setErrorsFromResponse } = useErrors();
  const { t } = useTranslation();
  const { handleLogEvent } = useAnalytics();

  useEffect(() => {
    void handleLogEvent({
      eventName: AnalyticsEventName.VISIT_HOME_SCREEN_AS_THERAPIST,
    });
  }, []);

  const { scrollContainer, gapLarge } = globalStyles;
  const { id } = useUserDetails();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const {
    data: specialTrainings,
    isLoading: specialTrainingsLoading,
    isError: specialTrainingsError,
    refetch: refetchSpecialTrainings,
  } = useQuery({
    queryKey: queryKeysSpecialTrainings.list(),
    queryFn: getSpecialTrainings,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  useWelcomeToProductModal({ openModal: () => navigate("WelcomeToProduct") });

  const {
    data: appointments,
    isLoading: appointmentsLoading,
    isError: appointmentsError,
    refetch: refetchAppointments,
  } = useQuery({
    queryKey: queryKeysAppointments.listTodayAppointments(),
    queryFn: async () => await getAppointmentsList(formatDateForApi(today)),
    refetchIntervalInBackground: true,
    refetchInterval: 120000,
  });

  const { refetch: refetchAllFutureData } = useFutureAvailability({
    dateFrom: getCurrentDatePlus30min(),
    physiotherapistId: id,
  });
  const { refetchAllSubscriptionStatusData } = useSubscriptionStatus();
  const {
    refetchLicence,
    refetchUserData: refetchActionTileTypeProfileVisible,
  } = useDataForVerificationActionTileProfile();

  const onTryRefresh = async () => {
    try {
      setLoading(true);
      await Promise.all([
        refetchSpecialTrainings(),
        refetchAppointments(),
        refetchAllFutureData(),
        refetchAllSubscriptionStatusData(),
        refetchActionTileTypeProfileVisible(),
        refetchLicence(),
      ]);
      setLoading(false);
    } catch (error) {
      alert(t("T00832"));
      setLoading(false);
    }
  };

  const onPressPlan = (id: number) =>
    navigate("PatientProfileStack", {
      screen: "PatientProfile",
      params: {
        id,
      },
    });

  const onPressAppointment = (id: number) =>
    navigate("AppointmentDetails", {
      id,
    });

  const hideSnackbar = () => setSnackbarVisible(false);

  useUpdateUser();

  return (
    <Host>
      <ScrollView
        contentContainerStyle={[scrollContainer, gapLarge]}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onTryRefresh} />
        }>
        <WelcomeSection
          onPressAppointment={onPressAppointment}
          data={appointments}
          isLoading={appointmentsLoading}
          isError={appointmentsError}
          refetch={refetchAppointments}
          today={today}
        />
        <Divider bold />
        <WaitingForPlan
          data={specialTrainings}
          onPressPlan={onPressPlan}
          isLoading={specialTrainingsLoading}
          isError={specialTrainingsError}
          refetch={refetchSpecialTrainings}
        />
        <Divider bold />
        <MonthlyStatisticsSection />
        <Divider bold />
        <PhysiotherapistRatings
          displaySnackbar={() => setSnackbarVisible(true)}
        />
        <Snackbar
          visible={snackbarVisible}
          onDismiss={hideSnackbar}
          onIconPress={hideSnackbar}
          text="T00560"
        />
      </ScrollView>
    </Host>
  );
};

export default HomeScreenPhysiotherapist;
