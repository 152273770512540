import { loadVictoryComponents } from "@components/Charts";
import CustomErrorBoundary from "@components/errors/CustomErrorBoundary";
import WebBanner from "@components/WebBanner";
import { AppointmentSessionContextProvider } from "@contexts/AppointmentSessionContext/appointmentSession.context";
import { AuthProvider, useAuth } from "@contexts/AuthContext/auth";
import useNetworkStatus from "@hooks/useNetworkStatus";
import { useMetadata } from "@hooks/web/useMetadata";
import * as Sentry from "@sentry/react-native";
import { queryCache } from "@services/ApiService/api";
import { theme } from "@styles/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { loadCalendarEvents } from "@utils/calendar";
import { config } from "@utils/config";
import { isANDROID, isIOS, isWeb, webScreenWidth } from "@utils/constants";
import * as Device from "expo-device";
import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { initialize as initializeRnClarity } from "react-native-clarity";
import "react-native-devsettings/withAsyncStorage";
import { Provider as PaperProvider } from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { enableScreens } from "react-native-screens";
import StatusBar from "./src/components/StatusBar";
import NavigationWrapper from "./src/navigators/NavigationWrapper";
import "./src/services/i18n";
import "react-native-get-random-values";

type ZoomVideoSdkProviderType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("@zoom/react-native-videosdk").ZoomVideoSdkProvider;

type GoogleOAuthProviderType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("@react-oauth/google").GoogleOAuthProvider;

let ZoomVideoSdkProvider: ZoomVideoSdkProviderType;
let GoogleOAuthProvider: GoogleOAuthProviderType;

if (!isWeb) {
  ZoomVideoSdkProvider = (
    require("@zoom/react-native-videosdk") as typeof import("@zoom/react-native-videosdk")
  ).ZoomVideoSdkProvider;
} else {
  GoogleOAuthProvider = (
    require("@react-oauth/google") as typeof import("@react-oauth/google")
  ).GoogleOAuthProvider;
}

Sentry.init({
  dsn: config.EXPO_PUBLIC_SENTRY_DSN,
  debug: false,
});

if (!isWeb) {
  // Initalization zoom provider:
  ZoomVideoSdkProvider = (
    require("@zoom/react-native-videosdk") as typeof import("@zoom/react-native-videosdk")
  ).ZoomVideoSdkProvider;
} else {
  const { initializeApp } =
    require("firebase/app") as typeof import("firebase/app");
  const { getAnalytics } =
    require("firebase/analytics") as typeof import("firebase/analytics");
  const firebaseConfig = {
    apiKey: config?.EXPO_PUBLIC_FIREBASE_API_KEY,
    authDomain: config?.EXPO_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: config?.EXPO_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: config?.EXPO_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: config?.EXPO_PUBLIC_FIREBASE_MESSAGING_ID,
    appId: config?.EXPO_PUBLIC_FIREBASE_WEB_APP_ID,
    measurementId: config?.EXPO_PUBLIC_FIREBASE_MEASUREMENT_ID,
  };
  // Initalization Firebase and analytics:
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
}

const queryClient = new QueryClient({ queryCache });
if (__DEV__ && !isWeb) {
  void import("./ReactotronConfig").then(() =>
    console.log("Reactotron Configured"),
  );
}

if (__DEV__ && isWeb) {
  void import("./ReactotronConfig.web").then(() =>
    console.log("Reactotron Configured"),
  );
}

void SplashScreen.preventAutoHideAsync();

function AppContent() {
  const [fontsLoaded] = useFonts({
    "Poppins-Medium": require("./assets/fonts/Poppins-Medium.ttf") as string,
    "Poppins-Regular": require("./assets/fonts/Poppins-Regular.ttf") as string,
    "Poppins-Bold": require("./assets/fonts/Poppins-Bold.ttf") as string,
    "Poppins-Black": require("./assets/fonts/Poppins-Black.ttf") as string,
    "Poppins-BlackItalic":
      require("./assets/fonts/Poppins-BlackItalic.ttf") as string,
    "Poppins-BoldItalic":
      require("./assets/fonts/Poppins-BoldItalic.ttf") as string,
    "Poppins-ExtraBold":
      require("./assets/fonts/Poppins-ExtraBold.ttf") as string,
    "Poppins-ExtraBoldItalic":
      require("./assets/fonts/Poppins-ExtraBoldItalic.ttf") as string,
    "Poppins-ExtraLight":
      require("./assets/fonts/Poppins-ExtraLight.ttf") as string,
    "Poppins-ExtraLightItalic":
      require("./assets/fonts/Poppins-ExtraLightItalic.ttf") as string,
    "Poppins-Italic": require("./assets/fonts/Poppins-Italic.ttf") as string,
    "Poppins-Light": require("./assets/fonts/Poppins-Light.ttf") as string,
    "Poppins-LightItalic":
      require("./assets/fonts/Poppins-LightItalic.ttf") as string,
    "Poppins-MediumItalic":
      require("./assets/fonts/Poppins-MediumItalic.ttf") as string,
    "Poppins-SemiBold":
      require("./assets/fonts/Poppins-SemiBold.ttf") as string,
    "Poppins-SemiBoldItalic":
      require("./assets/fonts/Poppins-SemiBoldItalic.ttf") as string,
    "Poppins-Thin": require("./assets/fonts/Poppins-Thin.ttf") as string,
    "Poppins-ThinItalic":
      require("./assets/fonts/Poppins-ThinItalic.ttf") as string,
    MaterialCommunityIcons:
      require("./node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf") as string,
    MaterialIcons:
      require("./node_modules/react-native-vector-icons/Fonts/MaterialIcons.ttf") as string,
    AntDesign:
      require("./node_modules/react-native-vector-icons/Fonts/AntDesign.ttf") as string,
    FontAwesome:
      require("./node_modules/react-native-vector-icons/Fonts/FontAwesome.ttf") as string,
  });
  const { user } = useAuth();
  const metadata = useMetadata();

  useNetworkStatus();

  useEffect(() => {
    console.log("DEVICE: ", Device.manufacturer, " ", Device.modelName);
    console.log("CONFIG: ", config);
    console.log({ isWeb, isIOS, isANDROID, __DEV__ });
  }, []);

  const { splashContainer, webContainerStyle } = styles;

  const onLayoutRootView = useCallback(() => {
    setTimeout(SplashScreen.hideAsync, user && fontsLoaded ? 1000 : 2000);
  }, [fontsLoaded, user]);

  if (!fontsLoaded) return null;

  loadVictoryComponents();
  loadCalendarEvents();
  if (isANDROID) initializeRnClarity(config.EXPO_PUBLIC_CLARITY_ID);

  return (
    <PaperProvider theme={theme}>
      <AppointmentSessionContextProvider>
        {isWeb && metadata}
        <View onLayout={onLayoutRootView} style={splashContainer}>
          <View style={isWeb ? webContainerStyle : { flex: 1 }}>
            <StatusBar />
            <NavigationWrapper />
          </View>
        </View>
      </AppointmentSessionContextProvider>
    </PaperProvider>
  );
}

function App() {
  useEffect(() => {
    isIOS && enableScreens(false);
  }, []);
  if (isWeb) {
    return (
      <SafeAreaProvider>
        <CustomErrorBoundary>
          <WebBanner />
          <GoogleOAuthProvider
            clientId={config.EXPO_PUBLIC_GOOGLE_WEB_CLIENT_ID}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <AppContent />
              </AuthProvider>
            </QueryClientProvider>
          </GoogleOAuthProvider>
        </CustomErrorBoundary>
      </SafeAreaProvider>
    );
  } else {
    return (
      <SafeAreaProvider>
        <CustomErrorBoundary>
          <ZoomVideoSdkProvider
            config={{
              appGroupId: "com.fixmephysioapp.pl",
              domain: "zoom.us",
              enableLog: true,
            }}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <AppContent />
              </AuthProvider>
              {/* uncomment this if you want react query dev tools bubble  */}
              {/* {__DEV__ && <DevToolsBubble />} */}
            </QueryClientProvider>
          </ZoomVideoSdkProvider>
        </CustomErrorBoundary>
      </SafeAreaProvider>
    );
  }
}

export default Sentry.wrap(App);

const styles = StyleSheet.create({
  splashContainer: { width: "100%", height: "100%" },
  webContainerStyle: {
    width: "100%",
    maxWidth: webScreenWidth,
    height: "100%",
    alignSelf: "center",
    margin: "auto",
  },
});
