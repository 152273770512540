import { PlayerMode, UsePlayerOptions } from "@components/Player/player.types";
import {
  CtxExerciseType,
  SeriesType,
} from "@screens/TrainingsAndExercises/exercise.types";
import {
  queryKeysIndividualTrainings,
  queryKeysSessionTraining,
  queryKeysTrainingDay,
} from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { StatType } from "@screens/TrainingsAndExercises/training.types";
import { finishTraining } from "@services/ApiService/trainings";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  createInitialStatsFromExercises,
  filterStats,
} from "@components/Player/player.helpers";
import { useErrors } from "@hooks/useErrors";

const COUNTDOWN_VALUE = 30;

export const usePlayer = ({
  exercises,
  trainingId,
  navigation,
}: UsePlayerOptions) => {
  const { setErrorsFromResponse } = useErrors();
  const queryClient = useQueryClient();

  const navigate = navigation?.navigate;
  const goBack = navigation?.goBack;

  const [isAlternative, setIsAlternative] = useState(false);
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  const [currentSeriesIndex, setCurrentSeriesIndex] = useState(0);

  const currentExercise: CtxExerciseType & { parentExerciseId?: number } =
    isAlternative
      ? {
          ...exercises?.[currentExerciseIndex].alternativeExercise,
          parentExerciseId: exercises?.[currentExerciseIndex].id,
        }
      : exercises?.[currentExerciseIndex];

  const [playerMode, setPlayerMode] = useState<PlayerMode>(
    PlayerMode.NOT_STARTED,
  );

  const [isPlaying, setIsPlaying] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  const [stats, setStats] = useState<StatType[]>([]);

  const currentSeries = currentExercise?.series[currentSeriesIndex];
  const series = currentExercise?.series;
  const isLastSerie = currentSeriesIndex === series?.length - 1;
  const isLastExercise = currentExerciseIndex === exercises?.length - 1;

  const [shouldFinishTraining, setShouldFinishTraining] = useState(false);
  useEffect(() => {
    exercises && setStats(createInitialStatsFromExercises(exercises));
  }, [exercises]);

  const startedAt = useMemo(() => new Date(), []);

  const filteredStats = filterStats(stats);
  const { mutate: saveWorkoutSession, isLoading: isSaveWorkoutSessionLoading } =
    useMutation({
      mutationFn: async () =>
        await finishTraining(trainingId, {
          stats: filteredStats,
          startedAt,
        }),
      onSuccess: async response => {
        const {
          data: { sessionWorkout },
        } = response;
        const { list, closestDays } = queryKeysTrainingDay;
        await Promise.all([
          queryClient.invalidateQueries(queryKeysIndividualTrainings.list()),
          queryClient.invalidateQueries(
            queryKeysSessionTraining.list(trainingId),
          ),
          queryClient.invalidateQueries(list()),
          queryClient.invalidateQueries(closestDays()),
        ]);
        setShouldFinishTraining(false);
        setAudioMuted(true);
        navigate("RehabilitationStatisticsStack", {
          screen: "TrainingSummary",
          params: {
            sessionId: sessionWorkout,
            fromPlayer: true,
          },
        });
      },
      onError: ({ response }: AxiosError) => {
        setErrorsFromResponse(response);
        goBack();
      },
    });

  useEffect(() => {
    if (shouldFinishTraining) {
      saveWorkoutSession();
    }
  }, [saveWorkoutSession, shouldFinishTraining]);

  const saveSeriesData = useCallback(
    (result: number) => {
      if (currentSeries) {
        setStats(prev => {
          const seriesIdToFind = currentSeries?.id;
          const existingIndex = prev.findIndex(
            stat => stat.seriesId === seriesIdToFind,
          );

          if (existingIndex !== -1) {
            return prev.map((stat, index) =>
              index === existingIndex
                ? {
                    ...stat,
                    finishDate: new Date(),
                    value: stat.value + result,
                    exerciseId: currentExercise?.id,
                    parentExerciseId: currentExercise?.parentExerciseId || null,
                  }
                : stat,
            );
          } else {
            return [
              ...prev,
              {
                seriesId: seriesIdToFind,
                value: result,
                finishDate: new Date(),
                breakValue: 0,
                exerciseId: currentExercise?.id,
                parentExerciseId: currentExercise?.parentExerciseId || null,
              },
            ];
          }
        });
      }

      if (!isLastSerie) {
        setCurrentSeriesIndex(prevIndex => ++prevIndex);
        return setPlayerMode(PlayerMode.BREAK_BETWEEN_SERIES);
      }

      if (isLastSerie && !isLastExercise) {
        setCurrentSeriesIndex(0);
        setCurrentExerciseIndex(prevIndex => ++prevIndex);
        setIsAlternative(false);
        return setPlayerMode(PlayerMode.BREAK_BETWEEN_EXERCISES);
      }

      if (isLastExercise) setShouldFinishTraining(true);
    },
    [
      currentExercise?.id,
      currentExercise?.parentExerciseId,
      currentSeries,
      isLastExercise,
      isLastSerie,
    ],
  );
  const togglePlay = () => setIsPlaying(prev => !prev);

  const updateSeriesBreakValue = useCallback(
    (totalValue: number, cb?: () => void) => {
      if (
        playerMode === PlayerMode.BREAK_BETWEEN_EXERCISES ||
        playerMode === PlayerMode.BREAK_BETWEEN_SERIES
      ) {
        setStats(prev => {
          const updatedStats = [...prev];
          let foundPreviousSeries = false;

          for (let i = updatedStats.length - 1; i >= 0; i--) {
            const prevSeries = updatedStats[i];

            if (prevSeries.parentExerciseId !== null) {
              if (prevSeries.value > 0) {
                updatedStats[i] = {
                  ...prevSeries,
                  breakValue: prevSeries.breakValue + totalValue,
                };
                foundPreviousSeries = true;
                break;
              }
            } else {
              updatedStats[i] = {
                ...prevSeries,
                breakValue: prevSeries.breakValue + totalValue,
              };
              foundPreviousSeries = true;
              break;
            }
          }

          if (!foundPreviousSeries) {
            if (updatedStats.length > 0) {
              const lastSeriesIndex = updatedStats.length - 1;
              updatedStats[lastSeriesIndex] = {
                ...updatedStats[lastSeriesIndex],
                breakValue:
                  updatedStats[lastSeriesIndex].breakValue + totalValue,
              };
            }
          }

          return updatedStats;
        });
      }

      if (playerMode !== PlayerMode.EXERCISE) {
        setIsPlaying(true);
        return setPlayerMode(PlayerMode.EXERCISE);
      }
      cb?.();
      setIsPlaying(false);
      setTotalValue(totalValue);
    },
    [playerMode],
  );

  const handleSkipCurrentExercise = useCallback(
    (id: number) => {
      const selectedExerciseIndex = exercises.findIndex(
        exercise => exercise.id === id,
      );
      setCurrentExerciseIndex(selectedExerciseIndex);
      setIsAlternative(false);
      setPlayerMode(PlayerMode.BREAK_BETWEEN_EXERCISES);
      setCurrentSeriesIndex(0);
    },
    [exercises],
  );
  const handleChangeCurrentExercise = useCallback(() => {
    setCurrentSeriesIndex(0);
    setIsAlternative(true);
  }, []);

  const initialValue = useMemo(
    () =>
      currentSeries?.seriesType === SeriesType.REPETITIONS
        ? currentSeries?.value
        : totalValue,
    [currentSeries, totalValue],
  );

  const getInitialTimeValue = useCallback(
    (mode: PlayerMode) => {
      switch (mode) {
        case PlayerMode.EXERCISE:
          return currentSeries?.value;
        case PlayerMode.BREAK_BETWEEN_EXERCISES:
        case PlayerMode.BREAK_BETWEEN_SERIES:
          return currentSeries?.seriesBreak;
        default:
          return COUNTDOWN_VALUE;
      }
    },
    [currentSeries?.seriesBreak, currentSeries?.value],
  );
  return {
    currentSeries,
    currentExercise,
    currentExerciseIndex,
    currentSeriesIndex,
    series,
    isPlaying,
    playerMode,
    initialValue,
    getInitialTimeValue,
    togglePlay,
    setPlayerMode,
    updateSeriesBreakValue,
    handleSkipCurrentExercise,
    handleChangeCurrentExercise,
    saveSeriesData,
    setIsPlaying,
    saveWorkoutSession,
    isLastSerie,
    isLastExercise,
    isFinishTrainingLoading: isSaveWorkoutSessionLoading,
    audioMuted,
  };
};
