import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, ScrollView, View } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";

import { getProphylacticTrainingDetails } from "@api/trainings";
import HumanModel from "@components/HumanModel/HumanModel";
import EquipmentsInTrainingList from "@components/Lists/EquipmentsInTrainingList";
import ExercisesInTrainingList from "@components/Lists/ExercisesInTrainingList";
import { PrimaryButton } from "@components/buttons";
import AssistantCard from "@components/cards/AssistantCard";
import { FetchError } from "@components/errors";
import { useAuth } from "@contexts/AuthContext/auth";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { RootStackParamList } from "@navigators/navigation.types";
import { queryKeysProphylacticTraining } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { globalStyles } from "@styles/global";
import { SafeAreaView } from "react-native-safe-area-context";
import { ButtonBasic } from "@components/Button/Button.types";
import ConditionChipsSection from "@components/Training/ConditionChipsSection";

const GeneralWorkoutDetails: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      RootStackParamList,
      "GeneralWorkoutCategories" | "SetAProphylacticSchedule"
    >
  >
> = ({ route, navigation: { navigate } }) => {
  const {
    user: { id },
  } = useAuth();
  const { trainingId } = route.params;
  const { t } = useTranslation();
  const { gender } = useUserDetails();
  const { loading, gapLarge, scrollContainer, gapSmall } = globalStyles;
  const {
    data: trainingDetails,
    isLoading: isTrainingDetailsLoading,
    isError: isTrainingDetailsError,
    refetch: refetchTrainingDetails,
  } = useQuery({
    queryKey: queryKeysProphylacticTraining.detail(trainingId),
    queryFn: async () => await getProphylacticTrainingDetails(trainingId),
  });

  const buttonProps: ButtonBasic = {
    label: "T00834",
    onPress: () =>
      navigate("SetAProphylacticSchedule", {
        trainingId,
        patientId: id,
      }),
    loading: false,
    disabled: false,
  };

  if (isTrainingDetailsLoading) return <ActivityIndicator style={loading} />;

  if (isTrainingDetailsError)
    return <FetchError action={refetchTrainingDetails} />;

  const {
    name,
    exercises,
    description: trainingDescription,
    equipments,
    conditionsTotal,
  } = trainingDetails;

  return (
    <SafeAreaView edges={["left", "right", "bottom"]}>
      <ScrollView
        contentContainerStyle={[gapLarge, scrollContainer]}
        refreshControl={
          <RefreshControl
            refreshing={isTrainingDetailsLoading}
            onRefresh={refetchTrainingDetails}
          />
        }>
        <Text variant="titleLarge">{name}</Text>
        <Divider bold />
        <PrimaryButton {...buttonProps} />
        <Text variant="titleMedium">{t("T01037")}:</Text>
        <AssistantCard />
        <View style={gapLarge}>
          <View style={gapSmall}>
            <Text variant="titleMedium">{t("T00777")}:</Text>
            <Text variant="bodyMedium">{trainingDescription}</Text>
          </View>
          <Divider bold />
          <ConditionChipsSection title="T01482" conditions={conditionsTotal} />
          <Text variant="titleMedium">{t("T00916")}:</Text>
          <HumanModel
            humanGeneral={trainingDetails?.humanGeneralTotal}
            gender={gender}
            humanBones={trainingDetails?.humanBonesTotal}
            humanMuscles={trainingDetails?.humanMusclesTotal}
          />
        </View>
        <ExercisesInTrainingList
          exercises={exercises}
          onExercisePress={(exerciseId: number) =>
            navigate("ExerciseDetails", { id: exerciseId })
          }
        />
        <EquipmentsInTrainingList selectedEquipment={equipments} />
      </ScrollView>
    </SafeAreaView>
  );
};

export default GeneralWorkoutDetails;
