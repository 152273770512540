import { VerificationTile } from "@components/Tile";
import { FetchError } from "@components/errors";
import { useAuth } from "@contexts/AuthContext/auth";
import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { queryKeysLanguages } from "@screens/Common/queryKeysLanguages";
import { queryKeysServices } from "@screens/Common/queryKeysServices";
import {
  getLanguagesList,
  getServices,
} from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren } from "react";
import { RefreshControl, ScrollView } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { VerificationStatus } from "./verification.types";
import { ProfileCompletionInfo } from "../ProfileCompletionInfo";

const ServicesAndLanguages: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      ProfileCompletionStackParamList,
      "ServicesAndLanguages"
    >
  >
> = ({ navigation: { navigate } }) => {
  const { updateUser } = useAuth();

  const {
    data: languages,
    isLoading: isLanguagesLoading,
    isError: isLanguagesError,
    refetch: refetchLanguages,
  } = useQuery({
    queryKey: queryKeysLanguages.list(),
    queryFn: getLanguagesList,
    onSuccess: updateUser,
  });

  const {
    data: services,
    isLoading: isServicesLoading,
    isError: isServicesError,
    refetch: refetchServices,
  } = useQuery({
    queryKey: queryKeysServices.all,
    queryFn: async () => getServices(),
    onSuccess: updateUser,
  });

  const refetchAllData = async () =>
    await Promise.all([refetchServices(), refetchLanguages()]);

  if (isServicesLoading || isLanguagesLoading)
    return <ActivityIndicator style={globalStyles.loading} />;

  if (isServicesError || isLanguagesError)
    return <FetchError action={refetchAllData} />;

  return (
    <ScrollView
      style={globalStyles.container}
      contentContainerStyle={globalStyles.gapMedium}
      refreshControl={
        <RefreshControl
          refreshing={isServicesError || isLanguagesError}
          onRefresh={refetchAllData}
        />
      }>
      <ProfileCompletionInfo />
      <VerificationTile
        mode="outlined"
        title="T00240"
        content="T00241"
        status={services?.length ? VerificationStatus.VERIFIED : null}
        onPress={() => navigate("Services")}
        type="profile completion"
      />
      <VerificationTile
        mode="outlined"
        title="T00604"
        content="T01331"
        status={languages?.data?.length ? VerificationStatus.VERIFIED : null}
        onPress={() => navigate("Languages")}
        type="profile completion"
      />
    </ScrollView>
  );
};

export default ServicesAndLanguages;
