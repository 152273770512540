export const validationRegex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
  date: /^\d{4}-\d{2}-\d{2}$/, //date format YYYY-MM-DD
};

export const validateAge = (value: string) => {
  const today = new Date();
  const year = today.getFullYear() - 18;
  const month = today.getMonth();
  const day = today.getDate();
  const minDate = new Date(year, month, day);
  if (new Date(value) > minDate) return false;
  return true;
};

export const hourAndMinuteRegex = /^[0-9]{2}:[0-9]{2}$/;

export const isFileSizeLessThanMax = (
  resultFileSize: number,
  maxFileSize: number,
) => resultFileSize < maxFileSize;
