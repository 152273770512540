import { DETAILS } from "../../../../utils/constants";

const keys = {
  userDetails: "userDetails",
  verificationProgress: "verificationProgress",
  verificationProgressInSubscreens: "verificationProgressInSubscreens",
};

export const queryKeysPatientCompletion = {
  all: [keys.userDetails] as const,
  details: () => [...queryKeysPatientCompletion.all, DETAILS] as const,
  verificationProgressPatient: () =>
    [...queryKeysPatientCompletion.all, keys.verificationProgress] as const,
  verificationProgressPatientInSubscreens: () =>
    [
      ...queryKeysPatientCompletion.all,
      keys.verificationProgressInSubscreens,
    ] as const,
};
