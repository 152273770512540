import { TransKey } from "@globalTypes/i18next";
import { Condition } from "@screens/TrainingsAndExercises/exercise.types";
import SelectedConditionsList from "@screens/TrainingsAndExercises/Physiotherapist/SelectedConditionsList";
import { FC } from "react";
import { View, ViewStyle } from "react-native";
import { Text } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { useTranslation } from "react-i18next";

type Props = { title: TransKey; conditions: Condition[]; style?: ViewStyle };

const ConditionChipsSection: FC<Props> = ({ title, conditions, style }) => {
  const { t } = useTranslation();
  return (
    <View style={[globalStyles.gapMedium, style]}>
      <Text variant="titleMedium">{t(title)}:</Text>
      <SelectedConditionsList selectedConditions={conditions} />
    </View>
  );
};

export default ConditionChipsSection;
