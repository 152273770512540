import { FC, useCallback, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Input } from "@components/Input";
import { Control, useForm } from "react-hook-form";
import { BottomModalWithButton } from "@components/BottomSheet";
import { ButtonBottomSheet } from "@components/Button/Button.types";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reportTraining } from "@api/trainings";
import { AxiosError } from "axios";
import {
  queryKeysIndividualTrainings,
  queryKeysTraining,
} from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { object, string } from "yup";
import { useErrors } from "@hooks/useErrors";
import { PrimaryButton } from "@components/buttons";
import { useTranslation } from "react-i18next";
import { globalStyles } from "@styles/global";
import { spacing16, spacing32 } from "@styles/spacing";
import {
  ReportTrainingForm,
  ReportTrainingProps,
} from "@screens/TrainingsAndExercises/TrainingDetailsComponents/types";
import {
  MAX_REPORT_PROBLEM_WITH_TRAINING_INPUT_LENGTH,
  REPORT_INPUT_MIN_LENGTH,
} from "@utils/constants";

const ReportTraining: FC<ReportTrainingProps> = ({
  disabled,
  onSuccessCallback,
  id,
}) => {
  const [problemWithTrainingModalVisible, setProblemWithTrainingModalVisible] =
    useState(false);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const schema = object().shape({
    description: string()
      .min(
        REPORT_INPUT_MIN_LENGTH,
        t("T00032", {
          value: REPORT_INPUT_MIN_LENGTH,
        }),
      )
      .max(MAX_REPORT_PROBLEM_WITH_TRAINING_INPUT_LENGTH)
      .required(t("T00014")),
  });
  const { errors, setErrorsFromResponse } = useErrors();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  const { mutate, isLoading } = useMutation({
    mutationFn: reportTraining,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeysTraining.detail(id),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeysIndividualTrainings.list(),
        }),
      ]);
      setProblemWithTrainingModalVisible(false);
      onSuccessCallback();
    },
  });

  const onSubmit = useCallback(
    ({ description }: ReportTrainingForm) =>
      mutate({ description, workout: id, isLibrary: false }),
    [mutate, id],
  );

  const problemModalButtons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: "T01251",
        onPress: handleSubmit(onSubmit),
        loading: isLoading,
      },
    ],
    [handleSubmit, onSubmit, isLoading],
  );

  return (
    <View>
      <View style={[styles.bottomButtons, globalStyles.gapLarge]}>
        <PrimaryButton
          label="T00363"
          onPress={() => setProblemWithTrainingModalVisible(true)}
          mode="outlined"
          disabled={disabled}
        />
      </View>
      <BottomModalWithButton
        modalVisible={problemWithTrainingModalVisible}
        setModalVisible={setProblemWithTrainingModalVisible}
        title="T00363"
        subtitles={["T00364", "T00365", "T00366"]}
        buttons={problemModalButtons}>
        <Input
          name="description"
          label="T00368"
          errors={errors?.description}
          control={control as unknown as Control}
          multiline
          isInBottomSheet
        />
      </BottomModalWithButton>
    </View>
  );
};

export default ReportTraining;

const styles = StyleSheet.create({
  bottomButtons: {
    marginHorizontal: spacing16,
    marginBottom: spacing32,
  },
});
