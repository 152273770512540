import { FC, Fragment, PropsWithChildren } from "react";
import { Text } from "react-native-paper";
import { useTranslation } from "react-i18next";

import { TransKey } from "@globalTypes/i18next";

type ProfileSectionProps = {
  title?: TransKey;
  renderContent: JSX.Element | JSX.Element[];
};

const TitleSectionWithContent: FC<PropsWithChildren<ProfileSectionProps>> = ({
  title,
  renderContent,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {title && <Text variant="titleMedium">{t(title)}:</Text>}
      {Array.isArray(renderContent)
        ? renderContent.map((content, index) => (
            <Fragment key={`${index}-element`}>{content}</Fragment>
          ))
        : renderContent}
    </>
  );
};

export default TitleSectionWithContent;
