import { FC } from "react";
import { ActivityIndicator, List } from "react-native-paper";
import { palettes } from "@styles/colors";
import { useQuery } from "@tanstack/react-query";
import { queryKeysFaq } from "@screens/Common/queryKeysHelpCenter";
import { getCategorySubjectDetails } from "@services/ApiService/helpCenter";
import { FetchError } from "@components/errors";
import { globalStyles } from "@styles/global";

type Props = {
  categoryID: number;
  subjectID: number;
  onPress: (name: string, subtitle: string) => void;
};

export const FAQSubjectItem: FC<Props> = ({
  categoryID,
  subjectID,
  onPress,
}) => {
  const {
    data: subject,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryFn: () => getCategorySubjectDetails(categoryID, subjectID),
    queryKey: queryKeysFaq.category_subject_details(categoryID, subjectID),
  });

  if (isLoading) return <ActivityIndicator style={globalStyles.loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <List.Item
      onPress={() => onPress(subject.name, subject.subtitle)}
      title={subject.name}
      left={props => <List.Icon {...props} icon="folder-outline" />}
      right={props => <List.Icon {...props} icon="menu-right" />}
      style={{ backgroundColor: palettes.primary[99] }}
    />
  );
};
