import { TransKey } from "@globalTypes/i18next";
import { globalStyles } from "@styles/global";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";

export type TutorialTextSectionProps = { title: TransKey; content: TransKey };

const TutorialTextSection: FC<TutorialTextSectionProps> = ({
  title,
  content,
}) => {
  const { t } = useTranslation();
  const { container, gapMedium } = globalStyles;
  return (
    <View style={[container, gapMedium]}>
      <Text style={{ fontFamily: "Poppins-Bold" }}>{t(title)}</Text>
      <Text variant="bodyMedium">{t(content)}</Text>
    </View>
  );
};

export default TutorialTextSection;
