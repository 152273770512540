import { api } from "./api";
import { CurrencyAmountResponse } from "./api.types";
import { endpoints } from "./endpoints";

import {
  ReportContent,
  ReportContentResponse,
} from "@components/Report/report.types";
import { GetServicesResponse } from "@screens/Appointments/filters.types";

import {
  FileAttachment,
  InvitationCodeResponse,
  InvitationCodeType,
} from "@globalTypes/common.types";
import { DocumentPickerAsset } from "expo-document-picker";
import mime from "mime";
import { Platform } from "react-native";
import { isWeb } from "@utils/constants";

export type SuccessDocumentResult = DocumentPickerAsset;

export const getServices = async (query?: string) => {
  const { data } = await api.get<GetServicesResponse[]>(
    endpoints.ALL_SERVICES,
    { params: { serviceName: query } },
  );
  return data;
};

export const getRecommendedServices = async () => {
  const { data } = await api.get<{ name: string }[]>(
    endpoints.RECOMMENDED_SERVICES,
  );
  return data;
};

export const exportRehabilitationStatistics = async (patientId: number) =>
  await api.get(endpoints.EXPORT_REHABILITATION_STATISTICS, {
    params: { patientId },
  });

export const getAvailableCountries = async () => {
  const { data } = await api.get<{ value: string; displayName: string }[]>(
    endpoints.AVAILABLE_COUNTRIES,
  );
  return data;
};

export const getCurrencyAmount = async () => {
  const { data } = await api.get<CurrencyAmountResponse>(
    endpoints.CURRENCY_AMOUNT_OPTIONS,
  );
  return data;
};
export const rateApp = () => api.post<unknown>(endpoints.RATE_OUR_APP);

export const getAssistant = () =>
  api.get<{ fixmeUserId: number }>(endpoints.GET_FIXME_USER);

export const reportContent = async (params: ReportContent) =>
  await api.post<ReportContentResponse>(endpoints.REPORT_CONTENT, params);

export const acceptTerms = (id: number) =>
  api.patch(endpoints.PROFILES_ACCEPT_TERMS(id));

export const postFile = async ({
  file,
  endpoint,
}: {
  file: SuccessDocumentResult;
  endpoint: string;
}) => {
  const { uri, name } = file;
  const newImageUri = "file:///" + uri.split("file:/").join("");
  const formData = new FormData();

  if (Platform.OS === "web") {
    const response = await fetch(file.uri);
    const blob = await response.blob();
    formData.append("file", blob, name ?? uri.split("/").pop());
  } else {
    formData.append("file", {
      uri: newImageUri,
      name: name ?? newImageUri.split("/").pop(),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      type: isWeb ? mime.lookup(newImageUri) : mime.getType(newImageUri),
    } as never);
  }

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };

  const { data } = await api.post<FileAttachment>(endpoint, formData, options);

  return data;
};

export const postInvitationCode = async (params: InvitationCodeType) =>
  await api.post<InvitationCodeResponse>(endpoints.INVITATION_CODE, params);

export const postRedeemCode = async (code: string) =>
  await api.post(endpoints.REDEEM_CODE, { code });
