import { PrimaryButton } from "@components/buttons";
import { Appointment } from "@screens/Appointments/appointment.types";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  appointmentDetails: Appointment;
  setCancelAppointmentModalVisible: Dispatch<SetStateAction<boolean>>;
  setAlertVisible?: Dispatch<SetStateAction<boolean>>;
  isPhysiotherapist: boolean;
  isCanceling: boolean;
  customAppointment?: boolean;
  onConfirmedDelete?: () => void;
  onConfirmedCancel?: () => void;
};

export const CancelAppointmentButton: FC<Props> = ({
  appointmentDetails,
  setCancelAppointmentModalVisible,
  isPhysiotherapist,
  isCanceling,
  customAppointment,
  onConfirmedDelete,
  onConfirmedCancel,
}) => {
  const { t } = useTranslation();

  if (
    new Date(appointmentDetails?.dateFrom).getTime() < new Date().getTime() ||
    appointmentDetails?.cancelled
  )
    return;

  const onCancel = () => {
    if (appointmentDetails.paymentType === "Online" && !isPhysiotherapist) {
      setCancelAppointmentModalVisible(true);
    } else {
      onConfirmedCancel();
    }
  };
  const onDelete = () => {
    showAlertWithCustomButtons({
      title: t("T01401", {
        value: appointmentDetails.name,
      }),
      message: t("T01402"),
      confirmButton: {
        text: t("T01403"),
        onPress: onConfirmedDelete,
      },
      cancelButton: {
        text: t("T01404"),
      },
    });
  };

  return (
    <PrimaryButton
      mode="outlined"
      label={customAppointment ? "T01405" : "T01150"}
      onPress={customAppointment ? onDelete : onCancel}
      loading={isCanceling}
    />
  );
};
