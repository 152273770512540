import { FC } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "@components/buttons";
import { Input } from "@components/Input";
import { Control, useForm } from "react-hook-form";
import { globalStyles } from "@styles/global";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useErrors } from "@hooks/useErrors";
import { reportContent } from "@api/common";
import { ReportContent, ReportProps } from "@components/Report/report.types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MULTILINE_INPUT_MAX_LENGTH,
  REPORT_INPUT_MIN_LENGTH,
} from "@utils/constants";
import { object, string } from "yup";

export type ReportContentFormProps = ReportProps & {
  onReportSuccess: () => void;
};

type FormType = Pick<ReportContent, "description">;

const ReportContentForm: FC<ReportContentFormProps> = ({
  systemMessage,
  item,
  onReportSuccess,
}) => {
  const { t } = useTranslation();

  const reportSchema = object().shape({
    description: string()
      .required(t("T00014"))
      .min(
        REPORT_INPUT_MIN_LENGTH,
        t("T00032", {
          value: REPORT_INPUT_MIN_LENGTH,
        }),
      )
      .max(
        MULTILINE_INPUT_MAX_LENGTH,
        t("T00036", {
          value: MULTILINE_INPUT_MAX_LENGTH,
        }),
      ),
  });
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(reportSchema),
  });
  const { setErrorsFromResponse, errors } = useErrors();

  const { mutate, isLoading } = useMutation({
    mutationFn: reportContent,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: onReportSuccess,
  });

  const onSubmit = ({ description }: FormType) =>
    mutate({ description, systemMessage, item });

  return (
    <>
      <View style={globalStyles.gapMedium}>
        <Text variant="bodyLarge">{t("T00921")}</Text>
        <Text variant="bodyMedium">{t("T00922")}</Text>
        <Input
          name="description"
          label="T00368"
          errors={errors?.description}
          control={control as unknown as Control}
          isInBottomSheet
          multiline
          maxLength={MULTILINE_INPUT_MAX_LENGTH}
        />
        <PrimaryButton
          label="T00927"
          onPress={handleSubmit(onSubmit)}
          loading={isLoading}
        />
      </View>
    </>
  );
};

export default ReportContentForm;
