import { FC } from "react";
import { ActivityIndicator, Card, Text } from "react-native-paper";
import { DefaultTileProps } from "@components/Tile/types/Tile.types";
import { useQuery } from "@tanstack/react-query";
import { queryKeysSessionTraining } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { getSessionTraining } from "@api/trainings";
import { useTranslation } from "react-i18next";
import { FetchError } from "@components/errors";
import MultiColorText from "@components/Text/MultiColorText";
import { theme } from "@styles/theme";
import { getDate } from "@utils/date";

type TrainingSessionTileProps = DefaultTileProps & {
  sessionId: number;
  sessionOrder: number;
};

const TrainingSessionTile: FC<TrainingSessionTileProps> = ({
  sessionId,
  sessionOrder,
  mode = "outlined",
  elevation,
  onPress,
  ...props
}) => {
  const { t } = useTranslation();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysSessionTraining.detail(sessionId),
    queryFn: async () => await getSessionTraining(sessionId),
  });

  return (
    <Card
      {...props}
      mode={mode}
      elevation={elevation as never}
      style={{ borderColor: theme.colors.outlineVariant }}
      onPress={!isError && onPress}>
      <Card.Content>
        {isLoading ? (
          <ActivityIndicator />
        ) : isError ? (
          <FetchError action={refetch} />
        ) : (
          <>
            <Text variant="titleMedium">{`${t(
              "T00518",
            )} ${sessionOrder}`}</Text>
            <Text variant="bodyLarge">
              {t("T00519", {
                value: data?.percentageFinished,
              })}
            </Text>
            <MultiColorText
              variant="bodyMedium"
              leftText={`${t("T00520")}:`}
              rightText={getDate(data?.finishedAt)}
            />
          </>
        )}
      </Card.Content>
    </Card>
  );
};

export default TrainingSessionTile;
