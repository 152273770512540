/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import {
  Animated,
  Keyboard,
  Modal,
  PanResponder,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";

import { useKeyboard } from "@hooks/ui/useKeyboard";
import { isIOS, isWeb } from "@utils/constants";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";

import { bottomSheetBgColor, palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing16, spacing24, spacing32, spacing4 } from "@styles/spacing";

type Props = {
  children: JSX.Element;
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
  disableBackdrop?: boolean;
  scrollableContent?: boolean;
  disablePanHandlers?: boolean;
  renderHeader?: (cb: () => void) => JSX.Element;
};

const BottomModalContainer: FC<Props> = ({
  children,
  modalVisible,
  setModalVisible,
  disableBackdrop,
  scrollableContent,
  disablePanHandlers,
  renderHeader,
}) => {
  const [internalModalVisible, setinternalModalVisible] =
    useState<boolean>(modalVisible);
  const bottomSheetHeightRef = useRef(0);
  const animation = useRef(
    new Animated.Value(bottomSheetHeightRef.current),
  ).current;
  const { height, width } = useWindowDimensions();
  const { container, backdrop, animatedContainer, bottomSheetBar, scrollable } =
    styles;

  const keyboardHeight = useKeyboard();

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: (_, gestureState) => {
        if (gestureState.dy > 0) {
          animation.setValue(gestureState.dy);
        }
      },
      onPanResponderRelease: (_, gestureState) => {
        if (gestureState.dy > 0) {
          Animated.timing(animation, {
            toValue: height,
            duration: 250,
            useNativeDriver: true,
          }).start(() => {
            setinternalModalVisible(false);
            setModalVisible(false);
          });
        }
      },
    }),
  ).current;

  useEffect(() => {
    if (isIOS) {
      const willHide = Keyboard.addListener("keyboardWillHide", () => {
        Animated.timing(animation, {
          toValue: 0,
          duration: 250,
          useNativeDriver: true,
        }).start();
      });

      return () => {
        willHide.remove();
      };
    }
  }, [animation]);

  useEffect(() => {
    if (isIOS) {
      if (keyboardHeight > 0 && modalVisible) {
        Animated.timing(animation, {
          toValue: -keyboardHeight,
          duration: 250,
          useNativeDriver: true,
        }).start();
      } else if (keyboardHeight === 0 && modalVisible) {
        Animated.timing(animation, {
          toValue: 0,
          duration: 250,
          useNativeDriver: true,
        }).start();
      }
    }
  }, [keyboardHeight, animation, modalVisible]);

  useEffect(() => {
    if (modalVisible) {
      setinternalModalVisible(true);
      Animated.timing(animation, {
        toValue: 0,
        duration: 250,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(animation, {
        toValue:
          bottomSheetHeightRef.current > 0
            ? bottomSheetHeightRef.current
            : height,
        duration: 250,
        useNativeDriver: true,
      }).start(() => {
        setinternalModalVisible(false);
      });
    }
  }, [modalVisible]);

  const closeBottomSheet = () => {
    Animated.timing(animation, {
      toValue: height,
      duration: 250,
      useNativeDriver: true,
    }).start(() => {
      setinternalModalVisible(false);
      setModalVisible(false);
    });
  };

  const translateY = animation.interpolate({
    inputRange: [
      -keyboardHeight,
      bottomSheetHeightRef.current > 0 ? bottomSheetHeightRef.current : height,
    ],
    outputRange: [
      -keyboardHeight,
      bottomSheetHeightRef.current > 0 ? bottomSheetHeightRef.current : height,
    ],
    extrapolate: "clamp",
  });

  const panHandlersProps = {};
  if (!disablePanHandlers) {
    Object.assign(panHandlersProps, panResponder.panHandlers);
  }

  return (
    <Modal visible={internalModalVisible} transparent animationType="fade">
      <View style={[isWeb && { width, alignSelf: "center" }, container]}>
        <TouchableOpacity
          disabled={disableBackdrop}
          activeOpacity={0.5}
          onPress={closeBottomSheet}
          style={[backdrop, { height, width }]}
        />
        <Animated.View
          style={[
            globalStyles.gapMedium,
            animatedContainer,
            scrollableContent && scrollable,
            {
              transform: [
                {
                  translateY,
                },
              ],
            },
          ]}
          {...panHandlersProps}
          onLayout={({ nativeEvent }) => {
            if (bottomSheetHeightRef.current === 0) {
              bottomSheetHeightRef.current = nativeEvent.layout.height;
            }
          }}>
          {renderHeader ? (
            renderHeader(closeBottomSheet)
          ) : (
            <View style={bottomSheetBar} />
          )}
          {children}
        </Animated.View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
  },
  bottomSheetBar: {
    height: spacing4,
    width: spacing32,
    backgroundColor: palettes.neutralVariant["50"],
    alignSelf: "center",
    borderRadius: 100,
  },
  animatedContainer: {
    backgroundColor: bottomSheetBgColor,
    padding: spacing16,
    paddingBottom: spacing32,
    borderTopLeftRadius: spacing24,
    borderTopRightRadius: spacing24,
  },
  backdrop: {
    flex: 1,
    position: "absolute",
    ...globalStyles.backdrop,
  },
  scrollable: {
    paddingTop: spacing16,
    paddingBottom: spacing32,
    paddingHorizontal: 0,
  },
});

export default BottomModalContainer;
