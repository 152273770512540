import { BarChartNew, ChartHeader } from "@components/Charts";
import BartChartMonths from "@components/Charts/BarChartMonths";
import { completeMonthsWithMissingDays } from "@components/Charts/chartUtils";
import { StatsTileType } from "@components/Tile/EarningsTile";
import { FetchError } from "@components/errors";
import { queryKeysChart } from "@screens/Common/queryKeyCharts";
import AppointmentAmountTile from "@screens/Profiles/MyProfile/RehabStatistics/AppointmentsStatistics/AppointmentAmountTile";
import {
  getAppointmentStatsMonthChart,
  getAppointmentStatsYearChart,
} from "@services/ApiService/charts";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { getMonthAndYearAsNumber } from "@utils/date";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";
import { Divider, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";

const TrainingStatistics: FC = () => {
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const { MONTHLY, TOTAL } = StatsTileType;

  const { selectedMonthNumber, selectedYearNumber } = useMemo(
    () => getMonthAndYearAsNumber(currentDate),
    [currentDate],
  );

  const {
    data: monthAppointments,
    isLoading: monthAppointmentsLoading,
    refetch: refetchMonthAppointments,
    isError: monthAppointmentsError,
  } = useQuery({
    queryFn: async () =>
      await getAppointmentStatsMonthChart(
        selectedMonthNumber,
        selectedYearNumber,
      ),
    queryKey: queryKeysChart.appointmentsMonth(
      selectedMonthNumber,
      selectedYearNumber,
    ),
  });
  const {
    data: yearlyAppointments,
    isLoading: yearlyAppointmentsLoading,
    refetch: refetchYearlyAppointments,
    isError: yearlyAppointmentsError,
  } = useQuery({
    queryFn: async () => await getAppointmentStatsYearChart(),
    queryKey: queryKeysChart.appointmentsYear(),
  });

  const renderChartHeader = useCallback(
    () => <ChartHeader data={yearlyAppointments} />,
    [yearlyAppointments],
  );

  const { gapLarge, scrollContainer } = globalStyles;

  const isAnyError = useMemo(
    () => monthAppointmentsError || yearlyAppointmentsError,
    [monthAppointmentsError, yearlyAppointmentsError],
  );

  const refetchEverything = async () =>
    await Promise.all([
      refetchMonthAppointments(),
      refetchYearlyAppointments(),
    ]);

  if (isAnyError) return <FetchError action={refetchEverything} />;

  const monthsAppointmentResult = completeMonthsWithMissingDays(
    monthAppointments,
    currentDate,
    true,
  );

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["bottom", "left", "right"]}>
      <ScrollView contentContainerStyle={[gapLarge, scrollContainer]}>
        <AppointmentAmountTile key={`appointment-${MONTHLY}`} type={MONTHLY} />
        <AppointmentAmountTile key={`appointment-${TOTAL}`} type={TOTAL} />
        <Divider bold />
        <Text variant="titleMedium">{t("T00693")}:</Text>
        <BartChartMonths
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          chartTitle="T00722"
          chartDescription="T00723"
          isLoading={monthAppointmentsLoading}
          refetchData={refetchMonthAppointments}
          emptyData={!monthAppointments?.length}
          renderChart={() => (
            <BarChartNew
              data={monthsAppointmentResult}
              currentDate={currentDate}
            />
          )}
        />
        <BartChartMonths
          chartTitle="T00688"
          chartDescription="T00689"
          isLoading={yearlyAppointmentsLoading}
          refetchData={refetchYearlyAppointments}
          renderChartHeader={renderChartHeader}
          emptyData={yearlyAppointments?.every(
            e => e && e[0] === 0 && e[1] === 0,
          )}
          renderChart={() => <BarChartNew data={yearlyAppointments} grouped />}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

export default TrainingStatistics;
