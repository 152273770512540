import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import Lottie, { AnimationObject } from "lottie-react-native";
import { useTranslation } from "react-i18next";
import { spacing16 } from "@styles/spacing";
import { isWeb } from "@utils/constants";
import { TransKey } from "@globalTypes/i18next";

type Props = {
  animation: AnimationObject;
  text: TransKey;
};

export const SliderAnimation: FC<Props> = ({ animation, text }) => {
  const { desc, animationStyle, animationContainer, descWeb } = styles;
  const { t } = useTranslation();

  return (
    <View style={animationContainer}>
      <Lottie source={animation} autoPlay loop style={animationStyle} />
      <Text variant="bodyLarge" style={[isWeb ? descWeb : desc]}>
        {t(text)}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  desc: { flex: 1, textAlign: "center" },
  descWeb: { flex: 0, marginBottom: spacing16, textAlign: "center" },
  animationStyle: { position: "relative", flex: 3, width: "100%" },
  animationContainer: { flex: 1, alignItems: "center" },
});
