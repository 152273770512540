import { FC } from "react";
import { ActivityIndicator } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { getProphylacticTrainingDetails } from "@services/ApiService/trainings";
import { FetchError } from "@components/errors";
import BaseTrainingTile from "@components/Tile/training/BaseTrainingTile";
import { queryKeysProphylacticTraining } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";

type Props = {
  id: number;
  onPress?: (id: number) => void;
};

export const GeneralTrainingTile: FC<Props> = ({ id, onPress }) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryFn: async () => await getProphylacticTrainingDetails(id),
    queryKey: queryKeysProphylacticTraining.detail(id),
  });

  if (isLoading) return <ActivityIndicator style={globalStyles.loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <BaseTrainingTile
      data={data}
      isFetched
      isError={false}
      refetch={() => {}}
      onPress={() => onPress && onPress(data.id)}
    />
  );
};
