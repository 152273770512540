import InfoTile from "@components/Tile/InfoTile";
import ServiceTileWithQuery from "@components/Tile/service/ServiceTileWithQuery";
import { PrimaryButton, SearchbarButton } from "@components/buttons";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { FC, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { getFormatDateForAppointmentDetails } from "@utils/date";
import { SearchModal } from "@components/Modals";
import { PatientsListModal } from "@components/Modals/ModalComponents";
import { UserCard } from "@components/cards";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { CreateCustomAppointmentBottomSheet } from "@components/BottomSheetContents";

export const CreateCustomAppointment: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "CreateCustomAppointment">
  >
> = ({ route: { params }, navigation: { goBack, popToTop } }) => {
  const { dateFrom, serviceId, serviceType, language } = params;
  const { scrollContainer, gapLarge, gapMedium } = globalStyles;

  const [selectedPatient, setSelectedPatient] = useState<number>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [bottomsheetVisible, setBottomsheetVisible] = useState(false);

  const { t } = useTranslation();
  const { id, language: userLanguage } = useUserDetails();

  return (
    <>
      <ScrollView contentContainerStyle={[scrollContainer, gapLarge]}>
        <View style={gapMedium}>
          <Text variant="titleMedium">{`${t("T01353")}:`}</Text>
          <Text variant="bodyMedium">
            {getFormatDateForAppointmentDetails(dateFrom, userLanguage)}
          </Text>
          <ServiceTileWithQuery
            serviceId={serviceId}
            physioId={id}
            serviceType={serviceType}
          />
          <PrimaryButton mode="outlined" label="T00606" onPress={goBack} />
        </View>
        <View style={gapMedium}>
          <View style={styles.patientHeader}>
            <Text variant="titleMedium">{t("T01406")}</Text>
            {selectedPatient && (
              <PrimaryButton
                label="T00146"
                mode="text"
                style={{ flex: 0 }}
                onPress={() => setSelectedPatient(null)}
                icon="close"
              />
            )}
          </View>
          {selectedPatient ? (
            <UserCard
              initialData={{ id: selectedPatient }}
              onPress={() => {}}
              mode="outlined"
            />
          ) : (
            <InfoTile status="warning" content={<Text>{t("T01407")}</Text>} />
          )}
          <SearchbarButton
            label="T01408"
            onPress={() => setModalVisible(true)}
          />
        </View>
        <PrimaryButton
          label="T01397"
          onPress={() => setBottomsheetVisible(true)}
        />
      </ScrollView>
      <SearchModal<string>
        isModalVisible={modalVisible}
        setIsModalVisible={setModalVisible}
        selectedItems={[]}
        setSelectedItems={null}
        ListComponent={PatientsListModal}
        returnLabel={() => ""}
        onItemPress={(id: number) => {
          setSelectedPatient(id);
          setModalVisible(false);
        }}
        enableSearching
      />
      <BottomModalContainer
        modalVisible={bottomsheetVisible}
        setModalVisible={setBottomsheetVisible}>
        <CreateCustomAppointmentBottomSheet
          selectedPatient={selectedPatient}
          dateFrom={dateFrom}
          service={serviceId}
          appointmentLanguage={language}
          hideModal={() => setBottomsheetVisible(false)}
          popToTop={popToTop}
        />
      </BottomModalContainer>
    </>
  );
};

const styles = StyleSheet.create({
  patientHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
