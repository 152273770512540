import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { FC, PropsWithChildren } from "react";
import { ActivityIndicator } from "react-native-paper";

const LoadingScreen: FC<
  PropsWithChildren<NativeStackScreenProps<RootStackParamList, "Loading">>
> = () => {
  return <ActivityIndicator style={globalStyles.loading} />;
};

export default LoadingScreen;
