import Svg, { Path, SvgProps } from "react-native-svg";

export const SvgAssignment = (props: SvgProps) => (
  <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <Path
      d="M11.6667 26.6663H23.3333V29.9997H11.6667V26.6663ZM11.6667 19.9997H28.3333V23.333H11.6667V19.9997ZM11.6667 13.333H28.3333V16.6663H11.6667V13.333ZM31.6667 6.66634H24.7C24 4.73301 22.1667 3.33301 20 3.33301C17.8333 3.33301 16 4.73301 15.3 6.66634H8.33333C8.1 6.66634 7.88333 6.68301 7.66667 6.73301C7.01667 6.86634 6.43333 7.19967 5.98333 7.64967C5.68333 7.94967 5.43333 8.31634 5.26667 8.71634C5.1 9.09967 5 9.53301 5 9.99967V33.333C5 33.783 5.1 34.233 5.26667 34.633C5.43333 35.033 5.68333 35.383 5.98333 35.6997C6.43333 36.1497 7.01667 36.483 7.66667 36.6163C7.88333 36.6497 8.1 36.6663 8.33333 36.6663H31.6667C33.5 36.6663 35 35.1663 35 33.333V9.99967C35 8.16634 33.5 6.66634 31.6667 6.66634ZM20 6.24967C20.6833 6.24967 21.25 6.81634 21.25 7.49967C21.25 8.18301 20.6833 8.74967 20 8.74967C19.3167 8.74967 18.75 8.18301 18.75 7.49967C18.75 6.81634 19.3167 6.24967 20 6.24967ZM31.6667 33.333H8.33333V9.99967H31.6667V33.333Z"
      fill="black"
    />
  </Svg>
);
