import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import ExerciseBaseTile from "@components/Tile/exercise/ExerciseBaseTile";
import { setExerciseTileSubtitles } from "@components/Tile/exercise/exerciseTileHelpers";
import { PrimaryButton } from "@components/buttons";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { CtxExerciseType } from "@screens/TrainingsAndExercises/exercise.types";
import { bottomSheetBgColor } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing32 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";

type ExercisesListBottomSheetProps = {
  exercises: CtxExerciseType[];
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onSelectNewExercise: (id: number) => void;
  displayExtraIcon: boolean;
};

const ExercisesListBottomSheet: FC<ExercisesListBottomSheetProps> = ({
  exercises,
  visible,
  setVisible,
  onSelectNewExercise,
  displayExtraIcon,
}) => {
  const { t } = useTranslation();
  const [skipExerciseBottomSheetVisible, setSkipExerciseBottomSheetVisible] =
    useState(false);
  const [selectedExerciseId, setSelectedExerciseId] = useState<number | null>(
    null,
  );
  const { height } = useWindowDimensions();

  const onExerciseTilePress = (id: number) => {
    setSelectedExerciseId(id);
    setSkipExerciseBottomSheetVisible(true);
  };

  const onConfirmSkipExercise = () => {
    onSelectNewExercise(selectedExerciseId);
    setSkipExerciseBottomSheetVisible(false);
    setVisible(false);
  };

  const { bottomSheetContentContainer, listContainer, exerciseTile } = styles;

  return (
    <BottomModalContainer modalVisible={visible} setModalVisible={setVisible}>
      <View style={[bottomSheetContentContainer, { maxHeight: height * 0.55 }]}>
        <Text variant="bodyLarge">{t("T01237")}</Text>
        <ScrollView
          contentContainerStyle={listContainer}
          showsVerticalScrollIndicator={false}>
          {exercises.map(({ name, id, exerciseImageUrl, series }) => (
            <ExerciseBaseTile
              key={id}
              title={name}
              subtitles={setExerciseTileSubtitles(series)}
              onPress={() => onExerciseTilePress(id)}
              image={exerciseImageUrl}
              style={exerciseTile}
              displayExtraIcon={displayExtraIcon}
            />
          ))}
        </ScrollView>
        <PrimaryButton label="T00540" onPress={() => setVisible(false)} />
        <BottomModalContainer
          modalVisible={skipExerciseBottomSheetVisible}
          setModalVisible={setSkipExerciseBottomSheetVisible}>
          <View style={bottomSheetContentContainer}>
            <Text variant="bodyLarge">{t("T01238")}</Text>
            <Text variant="bodyMedium">{t("T01239")}</Text>
            <PrimaryButton
              mode="outlined"
              label="T00145"
              onPress={() => setSkipExerciseBottomSheetVisible(false)}
            />
            <PrimaryButton label="T01236" onPress={onConfirmSkipExercise} />
          </View>
        </BottomModalContainer>
      </View>
    </BottomModalContainer>
  );
};

export default ExercisesListBottomSheet;

const styles = StyleSheet.create({
  bottomSheetContentContainer: {
    marginTop: spacing32,
    ...globalStyles.gapLarge,
  },
  listContainer: {
    ...globalStyles.gapMedium,
  },
  exerciseTile: {
    backgroundColor: bottomSheetBgColor,
    borderColor: theme.colors.outlineVariant,
  },
});
