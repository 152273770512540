import { GenderType } from "@contexts/AuthContext/user.types";
import { SvgProps } from "react-native-svg";

export type Orientation = "front" | "back";

export enum ModelType {
  GENERAL = "general",
  MUSCLE = "muscle",
  BONE = "bone",
}

export type HumanModelObject = {
  [key in ModelType]: string[];
};

export type GenderModelProps = {
  onPressHandler: (id?: string) => void;
  checkIfItemIsSelected?: (id: string) => boolean;
} & SvgProps;

export type ModelProps = {
  defaultSelected?: string[];
  onChange: (items: string[]) => void;
  readOnly?: boolean;
  gender?: GenderType;
};

export type BackAndFrontModelProps = ModelProps & { orientation: Orientation };
