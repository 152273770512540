import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { HelperText, TextInput } from "react-native-paper";
import { spacing4, spacing48, spacing8 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { CustomInputFormat } from "./Input.types";
import { useCallback } from "react";
import { bottomSheetBgColor, palettes } from "@styles/colors";
import { isANDROID } from "@utils/constants";

export const Input = ({
  control,
  name,
  rules,
  label,
  errors = [],
  containerStyle,
  style,
  multiline,
  calendarInput,
  borderColor,
  onPress,
  maxLength,
  disableOutline,
  isRequired = false,
  isInBottomSheet,
  extraOnChange,
  extraHelperText,
  ...props
}: CustomInputFormat) => {
  const { t } = useTranslation();

  const {
    inputContainer,
    calendarInputContainer,
    inputCalendar,
    absoluteOnPress,
    container,
    inputStyle,
    inputBottomSheetStyle,
    infoHelperText,
  } = styles;

  const renderInput = useCallback(
    (
      error: unknown,
      fieldProps: {
        value: unknown;
      },
      onChange: (text: string) => void,
    ) => {
      const value: string = fieldProps.value as string;
      const fieldErrors = [error, ...(errors || [])].filter(i => !!i);
      const { error: errorColor, onSurfaceVariant, primary } = theme.colors;
      const isError = fieldErrors.length > 0;
      const outlineColor = isError ? errorColor : onSurfaceVariant;
      const activeColor = isError ? errorColor : primary;
      const labelToDisplay = `${t(label)}${isRequired ? "*" : ""}`;

      return (
        <View
          style={[
            inputContainer,
            {
              borderColor: borderColor || palettes.secondary["0"],
            },
            calendarInput && calendarInputContainer,
          ]}>
          <TextInput
            label={labelToDisplay}
            mode={calendarInput ? "flat" : "outlined"}
            onChangeText={text => {
              onChange(text);
              extraOnChange && extraOnChange(text);
            }}
            autoCapitalize="none"
            selectionColor={calendarInput ? "transparent" : activeColor}
            activeUnderlineColor={calendarInput ? "transparent" : activeColor}
            outlineColor={
              calendarInput || disableOutline ? "transparent" : outlineColor
            }
            activeOutlineColor={calendarInput ? "transparent" : activeColor}
            style={[
              inputStyle,
              calendarInput && inputCalendar,
              isInBottomSheet && inputBottomSheetStyle,
              style,
            ]}
            contentStyle={
              multiline &&
              isANDROID && {
                minHeight: spacing48,
                textAlignVertical: "center",
              }
            }
            multiline={multiline}
            maxLength={maxLength}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={value}
            underlineStyle={{ display: "none" }}
            right={calendarInput && <TextInput.Icon icon="calendar-today" />}
            {...props}
            {...fieldProps}
          />
          {calendarInput && (
            <TouchableOpacity onPress={onPress} style={absoluteOnPress} />
          )}
        </View>
      );
    },
    [
      errors,
      t,
      label,
      isRequired,
      inputContainer,
      borderColor,
      calendarInput,
      calendarInputContainer,
      disableOutline,
      inputStyle,
      inputCalendar,
      isInBottomSheet,
      inputBottomSheetStyle,
      style,
      multiline,
      maxLength,
      props,
      onPress,
      absoluteOnPress,
      extraOnChange,
    ],
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, ...fieldProps },
        fieldState: { error },
      }) => {
        const value = fieldProps.value as string;
        const fieldErrors = [error, ...(errors || [])].filter(i => !!i);

        return (
          <View style={[container, containerStyle]}>
            {renderInput(error, fieldProps, onChange)}
            {!fieldErrors?.length ? (
              <View style={infoHelperText}>
                {extraHelperText && (
                  <HelperText type="info">{t(extraHelperText)}</HelperText>
                )}
                {maxLength && (
                  <HelperText type="info">{`${
                    value?.length || 0
                  }/${maxLength}`}</HelperText>
                )}
              </View>
            ) : (
              fieldErrors?.map(({ message }, index) => (
                <HelperText type="error" key={index}>
                  {message}
                </HelperText>
              ))
            )}
          </View>
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: spacing8,
  },
  calendarInputStyles: {
    backgroundColor: "transparent",
    borderWidth: 0,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  calendarInputContainer: {
    borderWidth: 1,
    borderRadius: spacing4,
  },
  inputCalendar: {
    backgroundColor: "transparent",
    borderWidth: 0,
  },
  absoluteOnPress: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  inputStyle: {
    flex: 1,
    textAlign: "auto",
  },
  inputBottomSheetStyle: {
    backgroundColor: bottomSheetBgColor,
  },
  infoHelperText: {
    flexDirection: "row",
    flex: 0,
    justifyContent: "space-between",
  },
});

export default Input;
