import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={20} fill="none" {...props}>
    <Path fill="#4666FF" d="M13.598 7.849H10.4v11.116h3.197V7.85Z" />
    <Path fill="#F8495D" d="M7.696 10.581H4.499v8.384h3.197v-8.384Z" />
    <Path
      fill="#00C700"
      d="M19.5 5.185h-3.196v13.78H19.5V5.185ZM19.405.516a.497.497 0 0 0-.437-.362L17.307.003a.5.5 0 0 0-.491.262.495.495 0 0 0 .4.727l.341.031-4.223 3.074-1.068-1.579a.5.5 0 0 0-.527-.208.503.503 0 0 0-.183.082l-6.77 4.835a.5.5 0 0 0-.202.324.494.494 0 0 0 .415.571.506.506 0 0 0 .374-.087L11.722 3.5l1.07 1.583a.5.5 0 0 0 .53.207.504.504 0 0 0 .183-.083L18.3 1.72l-.052.472a.495.495 0 0 0 .444.55.504.504 0 0 0 .554-.441L19.42.702c0-.004-.002-.007-.003-.01a.483.483 0 0 0-.012-.177Z"
    />
    <Path
      fill="#000"
      d="M23.498 19.5H.502a.504.504 0 0 1-.355-.146.495.495 0 0 1 .355-.85h22.996c.133 0 .26.053.355.147a.496.496 0 0 1-.355.85Z"
    />
  </Svg>
);
export default SvgComponent;
