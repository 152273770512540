import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, useMemo, useState } from "react";
import { View } from "react-native";

import { PrimaryButton } from "@components/buttons";

import Rating from "@components/Rating/Rating";
import Snackbar from "@components/Snackbar/Snackbar";
import { FetchError } from "@components/errors";
import { useErrors } from "@hooks/useErrors";
import { queryKeysPhysiotherapist } from "@screens/Appointments/queryKeysAppointments";
import { checkReview, getReviewsList } from "@services/ApiService/reviews";
import { getMyPhysiotherapists } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { spacing48 } from "@styles/spacing";
import { ActivityIndicator } from "react-native-paper";
import { Host } from "react-native-portalize";
import { queryKeysPhysiotherapistRatings } from "./queryKeysPhysiotherapist";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { RootStackParamList } from "@navigators/navigation.types";
import { TabItemProps } from "./PhysiotherapistProfile";

const RatingsTab: FC<TabItemProps> = ({ id: rehabId }) => {
  const { setErrorsFromResponse } = useErrors();
  const { loading, marginTopLarge, gapLarge } = globalStyles;
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();

  const {
    data: reviewsList,
    isLoading: isReviewsLoading,
    isError: isReviewsError,
    refetch: reviewsRefetch,
  } = useQuery({
    queryKey: queryKeysPhysiotherapistRatings.list(rehabId),
    queryFn: async () => await getReviewsList(rehabId),
  });

  const {
    data,
    isLoading: isRatingsLoading,
    isError: isRatingsError,
    refetch: ratingsRefetch,
  } = useQuery({
    queryKey: queryKeysPhysiotherapistRatings.check(rehabId),
    queryFn: async () => await checkReview(rehabId),
  });

  const {
    data: myPhysiotherapists,
    isLoading: isGetMyPhysiotherapistLoading,
    isError: isGetMyPhysiotherapistError,
    refetch: getMyPhysiotherapistRefetch,
  } = useQuery({
    queryKey: queryKeysPhysiotherapist.myListEndedAppointment(),
    queryFn: async () => await getMyPhysiotherapists({ patientCanRate: true }),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const isAnyLoading = useMemo(
    () => isReviewsLoading || isRatingsLoading || isGetMyPhysiotherapistLoading,
    [isReviewsLoading, isRatingsLoading, isGetMyPhysiotherapistLoading],
  );

  const isAnyError = useMemo(
    () => isReviewsError || isRatingsError || isGetMyPhysiotherapistError,
    [isReviewsError, isRatingsError, isGetMyPhysiotherapistError],
  );

  const refetchAllQueries = async () =>
    await Promise.all([
      reviewsRefetch(),
      ratingsRefetch(),
      getMyPhysiotherapistRefetch(),
    ]);

  if (isAnyLoading) return <ActivityIndicator style={loading} />;

  const isMyPhysioToRate = myPhysiotherapists?.some(
    ({ id: physioId }) => physioId === rehabId,
  );
  const hideSnackbar = () => setSnackbarVisible(false);

  return (
    <Host>
      <View style={[marginTopLarge, gapLarge]}>
        {isAnyError ? (
          <FetchError action={refetchAllQueries} coverScreen={false} />
        ) : (
          <>
            <PrimaryButton
              label="T00380"
              onPress={() => navigate("PhysiotherapistEvaluation", { rehabId })}
              mode="outlined"
              disabled={
                !!data.length ||
                isReviewsLoading ||
                isReviewsError ||
                !isMyPhysioToRate
              }
            />
            {/* temp solution with padding below, there is problem with nesting tabs inside scrollview */}
            <View style={[gapLarge, { paddingBottom: spacing48 * 2 }]}>
              {reviewsList?.map(({ id }) => (
                <Rating
                  key={id}
                  reviewId={id}
                  navigateToEdit={() =>
                    navigate("PhysiotherapistEvaluation", {
                      rehabId,
                      ratingId: id,
                    })
                  }
                  displaySnackbar={() => setSnackbarVisible(true)}
                />
              ))}
            </View>
          </>
        )}
        <Snackbar
          visible={snackbarVisible}
          onDismiss={hideSnackbar}
          onIconPress={hideSnackbar}
          text="T00560"
          style={{ marginHorizontal: 0, marginBottom: 0 }}
        />
      </View>
    </Host>
  );
};

export default RatingsTab;
