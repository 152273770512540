import { GenderType } from "@contexts/AuthContext/user.types";
import { FC } from "react";
import MaleMuscleFront from "./MaleMuscleFront";
import FemaleMuscleFront from "./FemaleMuscleFront";
import { ModelProps } from "../humanModel.types";
import { useHumanModel } from "@hooks/humanModel/useHumanModel";

const MuscleFront: FC<ModelProps> = ({
  defaultSelected = [],
  onChange,
  readOnly = false,
  gender,
}) => {
  const { modelProps } = useHumanModel({
    onChange,
    readOnly,
    defaultSelected,
  });

  return gender === GenderType.MALE ? (
    <MaleMuscleFront {...modelProps} />
  ) : (
    <FemaleMuscleFront {...modelProps} />
  );
};
export default MuscleFront;
