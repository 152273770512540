import { globalStyles } from "@styles/global";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";

export const AppointmentDetailsAdditionalInfo: FC<{
  additionalInfo?: string;
  appointmentName?: string;
}> = ({ additionalInfo, appointmentName }) => {
  const { t } = useTranslation();

  return (
    <View style={globalStyles.gapSmall}>
      <Text variant="titleMedium">
        {t(appointmentName ? "T01359" : "T01360")}
      </Text>
      <Text variant="bodyMedium">{additionalInfo}</Text>
    </View>
  );
};
