import { storageManager } from "@utils/storageManager";
import {
  LocaleHeaders,
  UserAuthenticationData,
  UserTokensFromApi,
} from "../../contexts/AuthContext/user.types";
import { api } from "./api";
import { endpoints } from "./endpoints";

export const register = (data: UserAuthenticationData) =>
  api.post<UserTokensFromApi>(endpoints.REGISTER, data);
export const login = (data: UserAuthenticationData) =>
  api.post<UserTokensFromApi>(endpoints.LOGIN, data);
export const validateAccessToken = async (token: UserTokensFromApi["access"]) =>
  await api.post(endpoints.VALIDATE_TOKEN, { token });
export const refreshToken = async (token: UserTokensFromApi["refresh"]) => {
  const { data } = await api.post<UserTokensFromApi>(endpoints.REFRESH, {
    refresh: token,
  });

  const { access, refresh } = data;

  await storageManager.setItem("accessToken", access);
  await storageManager.setItem("refreshToken", refresh);

  return { access, refresh } as UserTokensFromApi;
};

export const updateHeaders = async (
  token: UserTokensFromApi["refresh"],
  headers: LocaleHeaders,
) => {
  const { data } = await api.post<UserTokensFromApi>(
    endpoints.REFRESH,
    {
      refresh: token,
    },
    {
      headers,
    },
  );
  const { access, refresh } = data;

  await storageManager.setItem("accessToken", access);
  await storageManager.setItem("refreshToken", refresh);

  return { access, refresh } as UserTokensFromApi;
};

export const signInWithApple = (
  identityToken: string,
  firstName?: null | string,
  lastName?: null | string,
) =>
  api.post<{ message: string; tokens: UserTokensFromApi }>(
    endpoints.APPLE_SIGNIN,
    {
      identityToken,
      firstName,
      lastName,
    },
  );

export const signInWithGoogle = (googleIdToken: string) =>
  api.post<{ message: string; tokens: UserTokensFromApi }>(
    endpoints.GOOGLE_SIGNIN,
    {
      googleIdToken,
    },
  );

export const singInWithFacebook = (accessToken: string) =>
  api.post<{ message: string; tokens: UserTokensFromApi }>(
    endpoints.FACEBOOK_SIGNIN,
    {
      accessToken,
    },
  );
