import {
  AppointmentsNotificationSetting,
  AppointmentsNotificationsSettingsOptions,
  MessagesNotificationSetting,
  NotificationSetting,
  TrainingsNotificationSetting,
  TrainingsNotificationSettingsFormType,
} from "@globalTypes/messagesNotificationSetting";
import { api } from "./api";
import { endpoints } from "./endpoints";
import { convertTimeToOrFromUTC } from "@screens/Profiles/MyProfile/Settings/utils";
import { checkNotifications } from "react-native-permissions";

export const getNotificationMainSettings = async () => {
  const { data } = await api.get<NotificationSetting[]>(
    endpoints.NOTIFICATION_MAIN_LIST,
  );
  return data;
};

export const getAppointmentsNotificationsSettings = async () => {
  const { data } = await api.get<AppointmentsNotificationSetting[]>(
    endpoints.NOTIFICATIONS_APPOINTMENTS,
  );
  return data;
};

export const getAppointmentsNotificationsSettingsOptions = async () => {
  const { data } = await api.options<AppointmentsNotificationsSettingsOptions>(
    endpoints.NOTIFICATIONS_APPOINTMENTS,
  );
  return data;
};

export const updateNotificationMainSettings = async ({
  id,
  notificationsActive,
}: NotificationSetting) =>
  await api.patch(endpoints.NOTIFICATION_MAIN_PATCH(id), {
    notificationsActive,
  });

export const updateAppointmentsNotificationsSettings = async ({
  id,
  ...data
}: Partial<AppointmentsNotificationSetting>) =>
  await api.patch(endpoints.NOTIFICATIONS_APPOINTMENTS_PATCH(id), data);

export const getMessagesNotificationsSettings = async () => {
  const { data } = await api.get<MessagesNotificationSetting[]>(
    endpoints.NOTIFICATIONS_MESSAGES,
  );
  return data;
};

export const updateMessagesNotificationsSettings = async ({
  id,
  ...data
}: Partial<MessagesNotificationSetting>) =>
  await api.patch(endpoints.NOTIFICATIONS_MESSAGES_PATCH(id), data);

export const getTrainingsNotificationsSettings = async () => {
  const { data } = await api.get<TrainingsNotificationSetting[]>(
    endpoints.NOTIFICATIONS_WORKOUTS,
  );
  const settingsData: TrainingsNotificationSetting = { ...data[0] };
  const { id, reminder, notificationsActive, ...rest } = settingsData;
  const convertedTime = convertTimeToOrFromUTC<
    Omit<TrainingsNotificationSettingsFormType, "reminder">
  >(rest, "local");
  return { id, reminder, notificationsActive, ...convertedTime };
};

export const updateTrainingsNotificationsSettings = async ({
  id,
  reminder,
  notificationsActive,
  ...data
}: Partial<TrainingsNotificationSetting>) => {
  const convertedTime = convertTimeToOrFromUTC<
    Omit<TrainingsNotificationSettingsFormType, "reminder">
  >(data, "utc");

  const dataForApi = {
    id,
    reminder,
    notificationsActive,
    ...convertedTime,
  };

  await api.patch(endpoints.NOTIFICATIONS_WORKOUTS_PATCH(id), dataForApi);
};

export const checkDevicePushNotificationsEnabled = async () => {
  const { status } = await checkNotifications();
  return status === "granted";
};
