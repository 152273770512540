import { View } from "react-native";
import { spacing8 } from "@styles/spacing";
import { FC, useState } from "react";
import { chartColors } from "@styles/colors";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { getDate } from "@utils/date";
import {
  SingleChartLegend,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryStack,
} from ".";

export type StackedBarItemType = {
  label: string;
  value: number;
  maxValue?: number;
};

type Props = {
  data: StackedBarItemType[];
  currentDate: Date;
};

export const StackedBarChart: FC<Props> = ({ currentDate, data }) => {
  const [displayLegend, setDisplayLegend] = useState("");
  const { width } = useWindowDimensions();

  const getItemLabel = ({ label, value, maxValue }: StackedBarItemType) => {
    const dateToDisplay = new Date(currentDate);
    dateToDisplay.setDate(+label);
    return `${getDate(dateToDisplay)} ${value}/${maxValue}`;
  };

  const onPressData = (item: StackedBarItemType) => ({
    target: "data",
    mutation: () => {
      return setDisplayLegend(prevState => {
        const newState = getItemLabel(item);

        return prevState === newState ? "" : newState;
      });
    },
  });

  const barEvents = (item: StackedBarItemType) => [
    {
      target: "data",
      eventHandlers: {
        onPressIn: () => {
          return [onPressData(item)];
        },
        onClick: () => {
          return [onPressData(item)];
        },
      },
    },
  ];

  const tempChartYValues = data
    .map(({ maxValue }) => maxValue)
    .filter(e => e !== null)
    .sort((a, b) => a - b);
  const lastYValue = tempChartYValues[tempChartYValues.length - 1];
  const tickValues = [0, Math.round(lastYValue / 2), lastYValue];

  return (
    <View>
      <SingleChartLegend displayLegend={displayLegend} />
      <VictoryChart
        width={width}
        height={width / 2}
        domainPadding={{ x: 10 }}
        padding={{ bottom: 30, right: 70, left: 40, top: 20 }}>
        {data.map((item, index) => {
          const { label, value, maxValue } = item;
          const itemLabel = getItemLabel(item);
          const shouldBeSelected = itemLabel === displayLegend;

          const maxValueFill = shouldBeSelected
            ? chartColors.maxSelected
            : chartColors.max;
          const valueFill = shouldBeSelected
            ? chartColors.secondarySelected
            : chartColors.secondary;

          return (
            <VictoryStack key={`stack-${label}-${index}`}>
              <VictoryBar
                data={[{ x: label, y: value }]}
                barWidth={spacing8 / 1.5}
                style={{
                  data: {
                    fill: valueFill,
                  },
                }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                events={barEvents(item)}
              />
              <VictoryBar
                data={[{ x: label, y: maxValue - value }]}
                barWidth={spacing8 / 1.5}
                style={{
                  data: {
                    fill: maxValueFill,
                  },
                }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                events={barEvents(item)}
              />
            </VictoryStack>
          );
        })}
        <VictoryAxis
          tickValues={data
            .map(({ label }) => (+label % 2 === 0 ? null : label))
            .filter(e => e !== null)}
        />
        <VictoryAxis dependentAxis tickValues={tickValues} />
      </VictoryChart>
    </View>
  );
};
