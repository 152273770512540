import { DETAILS, LIST, OPTIONS } from "@utils/constants";

const keys = {
  helpCenter: "helpCenter",
  faq: "faq",
  subject: "subject",
  category: "category",
};

export const queryKeysHelpCenter = {
  all: [keys.helpCenter] as const,
  options: () => [queryKeysHelpCenter.all, OPTIONS] as const,
};

export const queryKeysFaq = {
  all: [keys.faq] as const,
  categories_list: () => [queryKeysFaq.all, keys.category, LIST] as const,
  categorySubjects: (id: number) =>
    [queryKeysFaq.all, keys.subject, LIST, id] as const,
  categoryDetails: (id: number) =>
    [queryKeysFaq.all, keys.category, DETAILS, id] as const,
  category_subject_details: (catID: number, subID: number) =>
    [queryKeysFaq.all, keys.subject, DETAILS, catID, subID] as const,
};
