import { Dispatch, FC, SetStateAction } from "react";
import { StyleSheet, View } from "react-native";
import { List, Text } from "react-native-paper";
import { PrimaryButton } from "@components/buttons";
import { Purchase, Subscription, SubscriptionAndroid } from "react-native-iap";
import { globalStyles } from "@styles/global";
import { useTranslation } from "react-i18next";
import { palettes } from "@styles/colors";
import { spacing16 } from "@styles/spacing";

type Props = {
  subscriptions: Subscription[];
  purchaseHistory: Purchase[];
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  handleBuySubscription: (sku: string, offerToken?: string) => Promise<void>;
  goBack: () => void;
  handleGetPurchaseHistory: () => void;
};

export const AndroidSubscription: FC<Props> = ({
  subscriptions,
  purchaseHistory,
  setLoading,
  loading,
  handleBuySubscription,
  goBack,
  handleGetPurchaseHistory,
}) => {
  const { t } = useTranslation();

  const onPressButton = (pid: string, token: string) => {
    setLoading(prevState => !prevState);
    void handleBuySubscription(pid, token);
    void handleGetPurchaseHistory();
  };

  return (
    <View style={{ marginTop: 10 }}>
      {(subscriptions as SubscriptionAndroid[])?.map((subscription, index) => {
        const owned = purchaseHistory.findIndex(
          s => s?.productId === subscription.productId,
        );

        const {
          basePlanId,
          offerToken,
          pricingPhases: { pricingPhaseList },
        } = subscription.subscriptionOfferDetails[0];

        return (
          <View
            key={`android-subscription-button-${basePlanId}-${index}`}
            style={styles.container}>
            <List.Icon
              color={palettes.primary["0"]}
              icon="circle-medium"
              style={{ marginRight: spacing16 }}
            />
            <View style={[globalStyles.gapMedium, { flex: 1 }]}>
              <Text variant="titleSmall">{`${subscription.name} - ${pricingPhaseList[0].formattedPrice}`}</Text>
              {!loading && owned === -1 && (
                <PrimaryButton
                  onPress={() =>
                    onPressButton(subscription.productId, offerToken)
                  }
                  label="T00735"
                />
              )}
              {owned !== -1 && (
                <View style={globalStyles.gapLarge}>
                  <Text variant="bodyMedium">{t("T00736")}</Text>
                  <PrimaryButton
                    label="T00737"
                    onPress={goBack}
                    mode="outlined"
                  />
                </View>
              )}
            </View>
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flexDirection: "row", alignItems: "center" },
});
