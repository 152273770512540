export enum AnalyticsEventName {
  VISIT_HOME_SCREEN_AS_PATIENT = "visit_home_screen_as_patient",
  VISIT_HOME_SCREEN_AS_THERAPIST = "visit_home_screen_as_therapist",
  CURRENT_USER_SCREEN = "current_user_screen",
  QR_CODE_SHOWN = "qr_code_shown",
  SHARE_INVITATION_CLICKED = "share_invitation_clicked",
}

export type AnalyticsEvent = {
  eventName: AnalyticsEventName;
  eventParams?: {
    [key: string]: unknown;
  };
};
