import {
  findMaxKey,
  setInitialOrientation,
} from "@components/HumanModel/helpers";
import {
  ModelType,
  Orientation,
} from "@components/HumanModel/humanModel.types";
import { useMemo } from "react";

type HumanModelObject = {
  [key in ModelType]: string[];
};

export const useInitialParametersForHumanModel = (
  obj: HumanModelObject,
): {
  modelType: ModelType;
  orientation: Orientation;
  anyLengthGreaterThanZero?: boolean;
} => {
  const lengths: Record<ModelType, number> = useMemo(
    () => ({
      [ModelType.GENERAL]: obj?.general?.length || 0,
      [ModelType.MUSCLE]: obj?.muscle?.length || 0,
      [ModelType.BONE]: obj?.bone?.length || 0,
    }),
    [obj?.bone?.length, obj?.general?.length, obj?.muscle?.length],
  );

  const maxKey = findMaxKey(lengths);

  const anyLengthGreaterThanZero = useMemo(
    () => Object.values(lengths).some(length => length > 0),
    [lengths],
  );

  let orientation: Orientation;
  let longestPartArrayLength: number;
  if (!anyLengthGreaterThanZero)
    return {
      modelType: ModelType.GENERAL,
      orientation: "front",
      anyLengthGreaterThanZero,
    };

  const calculatedOrientation = setInitialOrientation({
    array: obj?.[maxKey]?.length ? [...obj[maxKey]] : [],
    key: maxKey,
    orientation,
    longestPartArrayLength,
  });

  return {
    modelType: maxKey,
    orientation: calculatedOrientation,
    anyLengthGreaterThanZero,
  };
};
