import {
  RootStackParamList,
  RootTabsParamList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";
import SetASchedule from "./SetASchedule";

type SetAScheduleScreen = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<
      RootStackParamList,
      "GeneralWorkoutCategories" | "SetAProphylacticSchedule"
    >,
    NativeStackScreenProps<RootTabsParamList, "TrainingsStack">
  >
>;

const SetAProphylacticSchedule: FC<SetAScheduleScreen> = ({
  route,
  navigation: { navigate },
}) => {
  const { patientId, trainingId } = route.params;

  return (
    <SetASchedule
      isProphylactic
      patientId={patientId}
      trainingId={trainingId}
      navigateAfterAssignProphylactic={() =>
        navigate("TrainingsStack", {
          screen: "AllPatientTrainings",
          params: { initialRoute: "GeneralTrainings" },
        })
      }
      onCloseAfterAssignProphylactic={() =>
        navigate("GeneralWorkoutCategories")
      }
    />
  );
};

export default SetAProphylacticSchedule;
