import React from "react";
import { View, ViewStyle, ImageStyle, TouchableOpacity } from "react-native";
import { SvgMic, SvgMicMuted } from "@assets/svg";

export interface IconProps {
  name: "talking" | "muted";
  style?: ImageStyle | ImageStyle[];
  containerStyle?: ViewStyle;
  onPress?: () => void;
}

export function Icon(props: IconProps) {
  const { containerStyle, onPress, name } = props;

  let icon: JSX.Element;

  switch (name) {
    case "talking":
      icon = <SvgMic />;
      break;
    case "muted":
      icon = <SvgMicMuted iconColor="red" />;
      break;
  }

  if (onPress) {
    return (
      <TouchableOpacity onPress={onPress} style={containerStyle}>
        {icon}
      </TouchableOpacity>
    );
  } else {
    return <View style={containerStyle}>{icon}</View>;
  }
}
