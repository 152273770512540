import { ParamListBase } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

export const handleBackToTop = ({
  popToTop,
  pop,
}: NativeStackNavigationProp<ParamListBase, string, undefined>) => {
  popToTop();
  pop();
};
