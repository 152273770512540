import InfoTile from "@components/Tile/InfoTile";
import { PrimaryButton } from "@components/buttons";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "react-native-paper";

type Props = {
  cancelled: boolean;
  switchOn?: boolean;
  paymentSuccessful: boolean;
  wasPaymentInitialized: boolean;
  openPaymentSheet: (() => Promise<void>) | (() => void);
};

export const AppointmentDetailsInfoTiles: FC<Props> = ({
  cancelled,
  switchOn,
  paymentSuccessful,
  wasPaymentInitialized,
  openPaymentSheet,
}) => {
  const { isPhysiotherapist } = useUserDetails();
  const { t } = useTranslation();

  return (
    <>
      {cancelled && (
        <InfoTile
          status="error"
          content={<Text variant="bodyMedium">{t("T01174")}.</Text>}
        />
      )}
      {switchOn && (
        <InfoTile
          status="error"
          content={<Text variant="bodyMedium">{t("T01151")}.</Text>}
        />
      )}
      {!paymentSuccessful && !cancelled && wasPaymentInitialized && (
        <>
          <InfoTile
            content={
              <Text variant="bodyMedium">
                {t(!isPhysiotherapist ? "T00805" : "T00806")}
              </Text>
            }
            status="warning"
          />
          {!isPhysiotherapist && (
            <PrimaryButton label="T00808" onPress={openPaymentSheet} />
          )}
        </>
      )}
    </>
  );
};
