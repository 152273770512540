import Svg, { SvgProps, Rect, Path } from "react-native-svg";

export const SvgOnlinePaymentsIcon1 = (props: SvgProps) => (
  <Svg width={56} height={56} fill="none" {...props}>
    <Rect width={56} height={56} fill="#DEE1FF" rx={28} />
    <Path fill="#4666FF" d="M27.82 22a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
    <Path
      fill="#4666FF"
      d="M31.71 24.11c-.39-.39-1.06-1.11-2.36-1.11h-2.54c-2.75-.01-4.99-2.25-4.99-5h-2c0 3.16 2.11 5.84 5 6.71V38h2v-6h2v6h2V26.05L34.77 30l1.41-1.41-4.47-4.48Z"
    />
  </Svg>
);
