import { spacing20, spacing40 } from "@styles/spacing";
import { isWeb } from "@utils/constants";
import { ComponentType, FC } from "react";
import { ReactFacebookLoginProps } from "react-facebook-login";
import { useTranslation } from "react-i18next";
import { FB_BUTTON_COLOR, WEB_BUTTON_WIDTH } from "../helpers";
import { fontConfig } from "@styles/fontConfig";

let ReactFacebookLogin: ComponentType<ReactFacebookLoginProps>;
if (isWeb) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  ReactFacebookLogin = require("react-facebook-login")
    .default as ComponentType<ReactFacebookLoginProps>;
}

const FacebookLoginWeb: FC<ReactFacebookLoginProps> = ({
  callback,
  buttonStyle,
  ...rest
}) => {
  const { t } = useTranslation();
  const { fontFamily, fontSize, letterSpacing, fontWeight } =
    fontConfig.labelLarge;
  return (
    <ReactFacebookLogin
      buttonStyle={{
        padding: "0 32px",
        borderRadius: spacing20,
        height: spacing40,
        textTransform: "none",
        backgroundColor: FB_BUTTON_COLOR,
        width: "100%",
        fontFamily,
        fontSize,
        letterSpacing,
        fontWeight,
        ...buttonStyle,
      }}
      containerStyle={{ width: WEB_BUTTON_WIDTH }}
      autoLoad={false}
      fields="name,email"
      callback={callback}
      textButton={t("T01289")}
      icon="fa-facebook-square"
      {...rest}
    />
  );
};

export default FacebookLoginWeb;
