import { api } from "./api";
import { endpoints } from "./endpoints";
import {
  CreateReviewResponse,
  PatchReviewType,
  ReportResponse,
  ReviewBody,
  ReviewReport,
  ReviewReportResponse,
  ReviewResponse,
  ReviewResponseBody,
  ReviewType,
} from "@screens/Profiles/Physiotherapist/evaluation.types";

export const createReview = (data: ReviewBody) =>
  api.post<CreateReviewResponse>(endpoints.REVIEWS, data);

export const editReview = (id: number, data: ReviewBody) =>
  api.patch<PatchReviewType>(endpoints.REVIEW_DETAILS(id), data);

export const getReviewsList = async (
  physiotherapistId: number,
  amount?: number,
) => {
  const { data } = await api.get<{ id: number }[]>(endpoints.REVIEWS, {
    params: { physiotherapistId, amount },
  });
  return data;
};

export const getReviewDetails = async (id: number) => {
  const { data } = await api.get<ReviewType>(endpoints.REVIEW_DETAILS(id));
  return data;
};

export const checkReview = async (id: number) => {
  const { data } = await api.get<{ id: number }[]>(endpoints.REVIEW_CHECK(id));
  return data;
};

export const reportReview = async (data: ReviewReport) =>
  api.post<ReviewReportResponse>(endpoints.REPORT_REVIEW, data);

export const replyReview = (data: ReviewResponseBody) =>
  api.post<ReviewResponse>(endpoints.POST_REVIEW_RESPONSE, data);

export const getReviewResponse = async (id: number) => {
  const { data } = await api.get<ReviewResponse>(endpoints.REVIEW_RESPONSE(id));
  return data;
};

export const removeReview = (id: number) =>
  api.delete(endpoints.REVIEW_DETAILS(id));

export const reportResponse = async (data: ReportResponse) =>
  api.post(endpoints.REPORT_REVIEW_RESPONSE, data);

export const deleteResponse = (id: number) =>
  api.delete(endpoints.REVIEW_RESPONSE(id));

export const editResponse = async (
  id: number,
  { content }: ReviewResponseBody,
) => await api.patch(endpoints.REVIEW_RESPONSE(id), { content });
