import { useHeaderHeight } from "@react-navigation/elements";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { format } from "date-fns";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, ScrollView, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";

import { Calendar, DateArrayType } from "@components/Calendar/Calendar";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { useAuth } from "@contexts/AuthContext/auth";
import { useErrors } from "@hooks/useErrors";
import { usePhysiotherapistAvailability } from "@hooks/availability/useFutureAvailability";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { queryKeysPhysiotherapist } from "@screens/Appointments/queryKeysAppointments";
import AvailabilityList from "@screens/Calendar/AvailabilityList";
import { CalendarDates } from "@screens/Calendar/availability.types";
import { copyAvailabilityFromSelectedDay } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { getDate, getDatesWithAvailability, getDays } from "@utils/date";
import { showSnackbar } from "@utils/snackbarHelper";
import { hideMessage } from "react-native-flash-message";
import { TransKey } from "@globalTypes/i18next";

type Props = Pick<CalendarDates, "initialDate"> & {
  setParentModalVisible: (val: boolean) => void;
  userId: number;
  scrollableContent?: boolean;
};

export const AvailabilityBottomSheetContent: FC<Props> = ({
  initialDate,
  setParentModalVisible,
  userId,
  scrollableContent,
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const {
    user: { id },
  } = useAuth();

  const [selectedDate, setSelectedDate] = useState<DateArrayType>({
    date: initialDate.date || today,
  });
  const { gapLarge } = globalStyles;

  const { height } = useWindowDimensions();
  const headerHeight = useHeaderHeight();
  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const queryClient = useQueryClient();

  const { startOfMonthDate: dateFrom, endOfMonthDate: dateTo } = getDays(
    selectedDate.date,
  );

  const { data, isLoading, isError, refetch } = usePhysiotherapistAvailability(
    queryKeysPhysiotherapist.availabilityPerMonth({
      physiotherapistId: id,
      dateFrom,
      dateTo,
    }),
    { dateFrom, dateTo, physiotherapistId: id },
  );

  const datesWithEvents = getDatesWithAvailability(data);

  const filteredEvents = datesWithEvents?.filter(e => {
    const eventDate = new Date(e.date);
    const tempSelectedDate = new Date(selectedDate.date);
    eventDate.setHours(0, 0, 0, 0);
    tempSelectedDate.setHours(0, 0, 0, 0);

    return eventDate.getTime() === tempSelectedDate.getTime();
  });

  const { mutate } = useMutation({
    mutationFn: async () =>
      await copyAvailabilityFromSelectedDay(
        format(selectedDate.date, "yyyy-MM-dd"),
        format(initialDate.date, "yyyy-MM-dd"),
      ),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeysPhysiotherapist.availability(userId),
      });
      setParentModalVisible(false);
      showSnackbar({
        message: t("T01308"),
        rightIcon: "close",
        onPressRightIcon: () => hideMessage(),
      });
    },
  });

  const copyButtonLabel: TransKey = `${t("T00500")} ${getDate(
    initialDate.date,
  )}` as TransKey;

  const alertTitle = `${t("T00501")}?`;
  const alertDesc = t("T00502");

  const onTryToSave = useCallback(() => {
    const isNotEmpty = datesWithEvents?.some(e => {
      const eventDate = new Date(e.date);
      const tempSelectedDate = new Date(initialDate.date);
      eventDate.setHours(0, 0, 0, 0);
      tempSelectedDate.setHours(0, 0, 0, 0);

      return eventDate.getTime() === tempSelectedDate.getTime();
    });

    isNotEmpty
      ? Alert.alert(alertTitle, alertDesc, [
          {
            text: t("T00145"),
            style: "cancel",
          },
          {
            text: t("T00503"),
            onPress: () => mutate(),
          },
        ])
      : mutate();
  }, [alertDesc, alertTitle, datesWithEvents, initialDate.date, mutate, t]);

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <ScrollView
      contentContainerStyle={[
        gapLarge,
        scrollableContent && { padding: spacing16 },
      ]}
      style={{
        height: height - headerHeight,
      }}>
      <Text variant="bodyMedium">{t("T00481")}</Text>
      <View style={gapLarge}>
        <View>
          <Calendar
            limitRange
            initialDates={[initialDate]}
            datesWithEvents={datesWithEvents}
            onSelectDate={setSelectedDate}
            yearsOption="future"
          />
        </View>
        <Text variant="bodyMedium">
          {`${t("T00492")} ${getDate(selectedDate.date)}:`}
        </Text>
        {filteredEvents?.length ? (
          <AvailabilityList
            date={filteredEvents[0].date}
            displayTitle={false}
          />
        ) : (
          <Text>{t("T00493")}</Text>
        )}
        <PrimaryButton
          mode="text"
          label="T00145"
          onPress={() => setParentModalVisible(false)}
        />
        <PrimaryButton
          label={copyButtonLabel}
          disabled={!filteredEvents?.length}
          onPress={onTryToSave}
        />
      </View>
    </ScrollView>
  );
};
