import {
  SvgLastPageArrowKeyboard,
  SvgLeftArrowKeyboard,
  SvgRightArrowKeyboard,
} from "@assets/svg";
import BarChart from "@components/Charts/BarChart";
import {
  checkSelectedMonthAndYear,
  createDataForChart,
} from "@components/Charts/chartUtils";
import TrainingTileWithAuthorName from "@components/Tile/training/TrainingTileWithAuthorName";
import { FetchError } from "@components/errors";
import { RehabilitationStatisticsStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import TrainingSessionsList from "@screens/Profiles/MyProfile/Statistics/TrainingSessionsList";
import { queryKeysExercises } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { getExerciseStats } from "@services/ApiService/exercises";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing12, spacing16, spacing24, spacing32 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { addMonths, subMonths } from "date-fns";
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import ExerciseDropdownPicker from "@components/DropdownPicker/ExerciseDropdownPicker";
import { OnPickerValueChange } from "@components/DropdownPicker/DropdownPicker";
import { getMonthAndYearAsNumber, getMonthWithYear } from "@utils/date";
import { UserCard } from "@components/cards";
import { useUserDetails } from "@hooks/user/useUserDetails";

type TrainingStatisticsProps = PropsWithChildren<
  NativeStackScreenProps<
    RehabilitationStatisticsStackParamsList,
    "TrainingStatistics"
  >
>;

export const chartConfig = {
  backgroundGradientFrom: "#fff",
  backgroundGradientTo: "#fff",
  color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
  strokeWidth: 2,
  barPercentage: 0.18,
  decimalPlaces: 0,
};

const TrainingStatistics: FC<TrainingStatisticsProps> = ({
  navigation: { navigate },
  route: {
    params: { trainingId },
  },
  route,
}) => {
  const { t } = useTranslation();
  const [selectedExercise, setSelectedExericse] = useState<number>();
  const [labelData, setLabelData] = useState({
    visible: false,
    value: 0,
    activeBarIndex: null,
  });

  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [transformedData, setTransformedData] = useState<{
    labelsToDisplay: string[];
    data: number[];
  }>({
    labelsToDisplay: [],
    data: [],
  });
  const patientId = route.params?.patientId;
  const { isPhysiotherapist, language } = useUserDetails();

  const { selectedMonthNumber, selectedYearNumber } = useMemo(
    () => getMonthAndYearAsNumber(currentDate),
    [currentDate],
  );

  const { isLoading, isError, refetch, data } = useQuery({
    enabled: !!selectedExercise,
    queryKey: queryKeysExercises.exerciseStats(
      selectedExercise,
      selectedMonthNumber,
      selectedYearNumber,
    ),
    queryFn: async () =>
      await getExerciseStats(
        selectedExercise,
        selectedMonthNumber,
        selectedYearNumber,
      ),
  });

  useEffect(() => {
    if (data) {
      setTransformedData(
        !data.labels.length
          ? {
              labelsToDisplay: [],
              data: [],
            }
          : createDataForChart(data),
      );
    }
  }, [data]);

  const colors = useMemo(
    () =>
      transformedData?.data?.map(
        (_, i) => () =>
          i !== labelData.activeBarIndex
            ? palettes.primary[80]
            : palettes.primary[30],
      ),
    [labelData.activeBarIndex, transformedData],
  );

  const chartData = useMemo(
    () => ({
      labels: transformedData?.labelsToDisplay,
      datasets: [
        {
          data: transformedData?.data,
          colors,
        },
      ],
    }),
    [colors, transformedData.data, transformedData.labelsToDisplay],
  );

  const getSelectedExercise: OnPickerValueChange = (exerciseId: string) => {
    setSelectedExericse(+exerciseId);
    setLabelData({ visible: false, value: 0, activeBarIndex: null });
  };
  const onDataPointClick = ({
    index,
    value,
  }: {
    index: number;
    value: number;
  }) =>
    labelData.activeBarIndex === index
      ? setLabelData(previousState => ({
          ...previousState,
          value,
          visible: !previousState.visible,
          activeBarIndex: null,
        }))
      : setLabelData({
          value,
          visible: true,
          activeBarIndex: index,
        });

  const leftArrowDisabled = checkSelectedMonthAndYear(currentDate, false);
  const rightArrowDisabled = checkSelectedMonthAndYear(currentDate);

  const onSessionTilePress = (id: number) =>
    navigate("TrainingSummary", { sessionId: id, patientId });

  if (isError) return <FetchError action={refetch} />;

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["bottom", "left", "right"]}>
      <ScrollView
        style={globalStyles.container}
        contentContainerStyle={[
          globalStyles.gapLarge,
          { paddingBottom: spacing32 },
        ]}>
        {isPhysiotherapist && (
          <UserCard initialData={{ id: patientId }} mode="outlined" />
        )}
        <TrainingTileWithAuthorName trainingId={trainingId} />
        <Divider />
        <Text variant="titleMedium">{t("T00525")}</Text>
        <View style={styles.descriptionContainer}>
          <Text variant="titleMedium">{t("T00526")}</Text>
          <Text variant="bodyMedium">{t("T00527")}</Text>
          <ExerciseDropdownPicker
            trainingId={trainingId}
            onValueChange={getSelectedExercise}
          />
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}>
            <Text variant="titleMedium">
              {getMonthWithYear(currentDate, language)}
            </Text>

            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                onPress={async () => {
                  setCurrentDate(prevDate => subMonths(prevDate, 1));
                  await refetch();
                }}
                disabled={leftArrowDisabled}
                style={{ opacity: leftArrowDisabled ? 0.5 : 1 }}>
                <SvgLeftArrowKeyboard />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={async () => {
                  setCurrentDate(prevDate => addMonths(prevDate, 1));
                  await refetch();
                }}
                disabled={rightArrowDisabled}
                style={{ opacity: rightArrowDisabled ? 0.5 : 1 }}>
                <SvgRightArrowKeyboard />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => setCurrentDate(new Date())}
                disabled={rightArrowDisabled}
                style={{ opacity: rightArrowDisabled ? 0.5 : 1 }}>
                <SvgLastPageArrowKeyboard />
              </TouchableOpacity>
            </View>
          </View>
          <View>
            <View style={styles.infoTileContainer}>
              {labelData.activeBarIndex !== null && (
                <View style={styles.infoTile}>
                  <Text
                    variant="labelSmall"
                    style={{
                      color: palettes.primary[100],
                    }}>
                    {/* TODO replace with real data  reps/time, remove percentage*/}
                    Percentage done {labelData.value}
                  </Text>
                </View>
              )}
            </View>
            {isLoading ? (
              <ActivityIndicator style={globalStyles.loading} />
            ) : !transformedData.data.length ? (
              <Text style={{ alignSelf: "center" }} variant="bodyMedium">
                {t("T00541")}
              </Text>
            ) : (
              <BarChart
                style={{
                  paddingRight: spacing24 + spacing12,
                }}
                chartData={chartData}
                chartConfig={chartConfig}
                onDataPointClick={onDataPointClick}
              />
            )}
          </View>
        </View>
        <Text variant="titleMedium">{t("T00517")}</Text>
        <TrainingSessionsList
          trainingId={trainingId}
          onSessionTilePress={onSessionTilePress}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

export default TrainingStatistics;

const styles = StyleSheet.create({
  infoTileContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 20,
  },
  infoTile: {
    backgroundColor: palettes.primary[30],
    paddingHorizontal: 4,
    paddingVertical: 2,
    borderRadius: 2,
  },
  descriptionContainer: {
    borderWidth: 1,
    borderRadius: 12,
    borderColor: palettes.neutral[80],
    padding: spacing12,
    gap: spacing16,
  },
});
