import { ConditionsModal } from "@components/Modals/ModalComponents";
import { spacing16, spacing8 } from "@styles/spacing";
import { Dispatch, SetStateAction } from "react";
import ExercisesFiltersBottomSheetWrapper from "./ExercisesFiltersBottomSheetWrapper";
import { ExercisesFiltersParamsType } from "./SearchExercises";

type Props = {
  params: ExercisesFiltersParamsType;
  onClear: () => void;
  setParams: Dispatch<SetStateAction<ExercisesFiltersParamsType>>;
  setConditionsBottomSheetVisible: Dispatch<SetStateAction<boolean>>;
};

const ConditionFiltersBottomSheetContent = ({
  params,
  onClear,
  setParams,
  setConditionsBottomSheetVisible,
}: Props) => {
  return (
    <ExercisesFiltersBottomSheetWrapper
      header="T01342"
      type="conditions"
      params={params}
      onClear={onClear}
      content={
        <ConditionsModal
          setSelectedItemsForQuery={setParams}
          selectedItems={params?.conditions}
          setVisibleAfterSelect={setConditionsBottomSheetVisible}
          filtersMode
          style={{
            marginHorizontal: spacing16,
          }}
          extraItemStyle={{ paddingVertical: spacing8 }}
        />
      }
    />
  );
};

export default ConditionFiltersBottomSheetContent;
