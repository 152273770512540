import { View } from "react-native";
import { FC } from "react";
import { Text } from "react-native-paper";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { globalStyles } from "@styles/global";
import { spacing32, spacing16 } from "@styles/spacing";
import { useTranslation } from "react-i18next";
import { getContent } from "./welcomeToProduct.helpers";

const WelcomeTextSection: FC = () => {
  const { t } = useTranslation();
  const { isPatient } = useUserDetails();
  const { container, gapSmall } = globalStyles;

  const { textSection, additionalText } = getContent(isPatient);
  return (
    <View style={[container, { marginTop: spacing32 }]}>
      <Text variant="headlineMedium">{t("T01370")}</Text>
      <View style={[{ marginVertical: spacing16 }, gapSmall]}>
        {textSection?.map(text => (
          <Text key={text} variant="bodyMedium">
            {t(text)}
          </Text>
        ))}
      </View>
      <Text variant="bodySmall">{t(additionalText)}</Text>
    </View>
  );
};

export default WelcomeTextSection;
