import { AbsoluteBlurredFooter } from "@components/Footers";
import { t } from "i18next";
import { Dispatch, FC, SetStateAction, useCallback, useEffect } from "react";
import { ConversationInput } from "./Conversation/Input";
import { MessageType } from "./ConversationScreen";
import { sendConversationMessage } from "@services/ApiService/conversations";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { queryKeysConversations } from "./queryKeysMessageCenter";
import { useForm, UseFormReturn } from "react-hook-form";
import { useErrors } from "@hooks/useErrors";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import { useUserBlacklistCheck } from "@hooks/queryHooks/useUserBlacklistCheck";

type ConversationMessagesFooterProps = {
  isAssistantId: boolean;
  isPhysiotherapist: boolean;
  isBlocked: boolean;
  disabledUserId: number;
  convId: number;
  setFooterHeight: Dispatch<SetStateAction<number>>;
};

const ConversationMessagesFooter: FC<ConversationMessagesFooterProps> = ({
  isAssistantId,
  isPhysiotherapist,
  isBlocked,
  disabledUserId,
  convId,
  setFooterHeight,
}) => {
  const queryClient = useQueryClient();

  const { setErrorsFromResponse } = useErrors();

  const isUserBlacklisted = useUserBlacklistCheck({
    userId: disabledUserId,
  });

  const schema = object().shape({
    msg: string().required(t("T00014")).trim(),
  });

  const { mutate, isLoading: isSendingMessage } = useMutation({
    mutationFn: async ({ msg }: MessageType) =>
      await sendConversationMessage(convId, msg),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeysConversations.details(convId),
      });
      reset();
      setValue("msg", "");
    },
  });

  useEffect(() => {
    if (!isBlocked) setFooterHeight(0);
  }, [setFooterHeight, isBlocked]);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    reset,
  }: UseFormReturn<MessageType> = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    ({ msg }: MessageType) => mutate({ msg }),
    [mutate],
  );

  if (isAssistantId || isBlocked)
    return (
      <AbsoluteBlurredFooter
        onLayout={height => setFooterHeight(height)}
        hideButton
        bottomText={
          <Text variant="bodySmall" style={styles.blockedText}>
            {t(
              isAssistantId
                ? "T00490"
                : isPhysiotherapist
                ? "T01448"
                : "T00489",
            )}
          </Text>
        }
      />
    );

  return (
    <ConversationInput
      control={control}
      isSendingMessage={isSendingMessage}
      onPress={handleSubmit(onSubmit)}
      isValidForm={isValid}
      disabled={isUserBlacklisted}
    />
  );
};

export default ConversationMessagesFooter;

const styles = StyleSheet.create({
  blockedText: {
    flex: 1,
    textAlign: "center",
  },
});
