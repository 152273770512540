import {
  createMaterialTopTabNavigator,
  MaterialTopTabScreenProps,
} from "@react-navigation/material-top-tabs";
import { useTranslation } from "react-i18next";
import { theme } from "@styles/theme";
import IndividualTrainings from "./IndividualTrainings";
import GeneralTrainings from "./GeneralTrainings";
import {
  AllPatientTrainingsTabsParamList,
  TrainingsAndExercisesStackParamList,
} from "@navigators/navigation.types";
import { fontConfig } from "@styles/fontConfig";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { CompositeScreenProps } from "@react-navigation/native";

type AllPatientTrainingsProps = PropsWithChildren<
  CompositeScreenProps<
    MaterialTopTabScreenProps<
      TrainingsAndExercisesStackParamList,
      "AllPatientTrainings"
    >,
    MaterialTopTabScreenProps<AllPatientTrainingsTabsParamList>
  >
>;

const Tab = createMaterialTopTabNavigator<AllPatientTrainingsTabsParamList>();

const AllPatientTrainings: FC<AllPatientTrainingsProps> = ({
  route: { params },
  navigation,
}) => {
  const [screenMounted, setScreenMounted] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setScreenMounted(true);
    return () => setScreenMounted(false);
  }, []);
  useEffect(() => {
    if (screenMounted && params?.initialRoute === "GeneralTrainings") {
      setTimeout(() => {
        navigation.jumpTo("GeneralTrainings");
        navigation.setParams({ initialRoute: "IndividualTrainings" });
      }, 0);
    }
  }, [navigation, params?.initialRoute, screenMounted]);

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: {
          backgroundColor: theme.colors.surface,
        },
        tabBarLabelStyle: { textTransform: "none", ...fontConfig.titleSmall },
      }}>
      <Tab.Screen
        name="IndividualTrainings"
        component={IndividualTrainings}
        options={{ title: t("T00295") }}
      />
      <Tab.Screen
        name="GeneralTrainings"
        component={GeneralTrainings}
        options={{ title: t("T01021") }}
      />
    </Tab.Navigator>
  );
};

export default AllPatientTrainings;
