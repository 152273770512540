import { api } from "@api/api";
import { endpoints } from "@api/endpoints";
import {
  AppleSubscriptionStatus,
  GoogleSubscriptionStatus,
} from "@contexts/AuthContext/user.types";
import { ResponseOptions } from "./api.types";

type BaseSubscription = {
  subscriptionType: "BASIC";
};

type AppleSubscription = BaseSubscription & {
  originalTransactionId: string;
};

type GoogleSubscription = BaseSubscription & {
  purchaseToken: string;
};

export const subscribeApple = (params: AppleSubscription) =>
  api.post<AppleSubscription>(endpoints.SUB_APPLE, params);

export const subscribeGoogle = (params: GoogleSubscription) =>
  api.post<GoogleSubscription>(endpoints.SUB_GOOGLE, params);

export const getSubscriptionAppleStatus = async () => {
  const { data } = await api.get<AppleSubscriptionStatus[]>(
    endpoints.SUBSCRIPTION_APPLE_STATUS,
  );
  return data;
};

export const getSubscriptionGoogleStatus = async () => {
  const { data } = await api.get<GoogleSubscriptionStatus[]>(
    endpoints.SUBSCRIPTION_GOOGLE_STATUS,
  );
  return data;
};

export const deferGoogleSubscription = async () =>
  api.post<GoogleSubscription>(endpoints.DEFER_SUB_GOOGLE);

export const getSubscriptionAppleStatusOptions = async () =>
  await api.options<ResponseOptions>(endpoints.SUBSCRIPTION_APPLE_STATUS);

export const getSubscriptionGoogleStatusOptions = async () =>
  await api.options<ResponseOptions>(endpoints.SUBSCRIPTION_GOOGLE_STATUS);
