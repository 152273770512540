import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { PrimaryButton } from "@components/buttons";
import { Input } from "@components/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSetPhone } from "@hooks/phoneVerification/useSetPhone";
import { useErrors } from "@hooks/useErrors";
import { checkPhoneVerificationCode } from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { useAppTheme } from "@styles/theme";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Icon, Text } from "react-native-paper";
import { object, string } from "yup";
import { queryKeysVerficiations } from "../queryKeysPhysiotherapistVerification";
import { useInvalidateProfileDetails } from "@hooks/index";

type VerificationCodeBottomSheetProps = {
  codeSheetVisible: boolean;
  setCodeSheetVisible: Dispatch<SetStateAction<boolean>>;
  phoneNumber: string;
  onCodeCheckSuccessCb: () => void;
};

const insertSpacesEveryThreeChars = (phoneNumber: string): string =>
  phoneNumber.replace(/(.{3})/g, "$1 ");

const VerificationCodeBottomSheet: FC<VerificationCodeBottomSheetProps> = ({
  codeSheetVisible,
  setCodeSheetVisible,
  phoneNumber,
  onCodeCheckSuccessCb,
}) => {
  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const {
    colors: { primary },
  } = useAppTheme();
  const [showCodeSentMessage, setShowCodeSentMessage] = useState(false);
  const queryClient = useQueryClient();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();

  const schema = object().shape({
    code: string()
      .matches(/^\d{6}$/, t("T01522"))
      .required(t("T00014")),
  });

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: checkPhoneVerificationCode,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(queryKeysVerficiations.phone()),
        invalidateProfileDetails(),
      ]);
      setCodeSheetVisible(false);
      onCodeCheckSuccessCb();
    },
  });

  const {
    mutate: resendCode,
    isLoading: isResending,
    isSuccess,
  } = useSetPhone({
    onSuccessCallback: () => setShowCodeSentMessage(true),
  });

  const onSubmit = ({ code }: { code: string }) => mutate(code);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isSuccess)
      timeout = setTimeout(() => setShowCodeSentMessage(false), 60000);

    return () => clearTimeout(timeout);
  }, [isSuccess]);

  const { loading, gapLarge, gapMedium } = globalStyles;

  return (
    <BottomModalContainer
      disableBackdrop
      modalVisible={codeSheetVisible}
      setModalVisible={setCodeSheetVisible}>
      {!phoneNumber ? (
        <ActivityIndicator style={loading} />
      ) : (
        <View style={gapLarge}>
          <PrimaryButton
            label="T00540"
            onPress={() => {
              reset();
              setCodeSheetVisible(false);
            }}
            mode="text"
            icon={() => <Icon source="close" color={primary} size={20} />}
          />
          <View style={gapMedium}>
            <Text>{t("T01512")}</Text>
            <Text>{insertSpacesEveryThreeChars(phoneNumber)}</Text>
            <Input
              name="code"
              label="T01513"
              control={control}
              isInBottomSheet
              keyboardType="numeric"
              textContentType="oneTimeCode"
              autoFocus
            />
          </View>
          {showCodeSentMessage ? (
            <PrimaryButton label="T01515" onPress={null} mode="text" />
          ) : (
            <PrimaryButton
              label="T01514"
              onPress={() => resendCode(phoneNumber)}
              mode="text"
              loading={isResending}
              icon={() => <Icon source="refresh" color={primary} size={20} />}
            />
          )}
          <PrimaryButton
            label="T01177"
            onPress={handleSubmit(onSubmit)}
            loading={isLoading}
            disabled={isResending}
          />
        </View>
      )}
    </BottomModalContainer>
  );
};

export default VerificationCodeBottomSheet;
