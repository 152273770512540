import {
  ActivityIndicator,
  Avatar as RNPAvatar,
  AvatarTextProps,
} from "react-native-paper";
import { getAvatarColor } from "./helpers";
import { FC } from "react";
import { palettes } from "@styles/colors";
import { AVATAR_SIZE } from "@utils/constants";
import { Image } from "expo-image";
import { useQuery } from "@tanstack/react-query";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import { globalStyles } from "@styles/global";
import { FetchError } from "@components/errors";
import {
  getPatientBasicData,
  getPhysiotherapistBasicData,
} from "@services/ApiService/users";
import { ImageStyle, StyleSheet, View } from "react-native";
import { SvgLogoSmall } from "@assets/svg";

type AvatarProps = Partial<AvatarTextProps> & {
  firstName?: string;
  userId: number;
  image?: string;
  containerStyle?: ImageStyle;
  isPatient?: boolean;
};

const Avatar: FC<AvatarProps> = ({
  firstName,
  userId,
  image,
  containerStyle,
  isPatient = false,
  ...props
}) => {
  const isQueryEnabled = Boolean(!firstName && !image && userId);
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.basicData(userId),
    queryFn: async () =>
      await (isPatient
        ? getPatientBasicData(userId)
        : getPhysiotherapistBasicData(userId)),
    enabled: isQueryEnabled,
  });

  if (isQueryEnabled && isLoading)
    return <ActivityIndicator style={globalStyles.loading} />;
  if (isQueryEnabled && isError)
    return <FetchError action={refetch} coverScreen={false} />;

  return (
    <View>
      {data?.avatar || image ? (
        <Image
          source={{ uri: data?.avatar || image }}
          style={[
            {
              width: AVATAR_SIZE,
              height: AVATAR_SIZE,
              borderRadius: AVATAR_SIZE,
            },
            containerStyle,
          ]}
        />
      ) : (
        <RNPAvatar.Text
          {...props}
          size={AVATAR_SIZE}
          label={data?.firstName?.[0] || firstName?.[0]}
          labelStyle={{ color: palettes.primary[100] }}
          style={[
            {
              backgroundColor: getAvatarColor(userId),
            },
            containerStyle,
          ]}
        />
      )}
      <SvgLogoSmall style={styles.logo} />
    </View>
  );
};

export default Avatar;

const styles = StyleSheet.create({
  logo: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
});
