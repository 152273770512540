import AddressWithMapRedirect from "@components/Address/AddressWithWebviewRedirect";
import { useAuth } from "@contexts/AuthContext/auth";
import { ServiceType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { globalStyles } from "@styles/global";
import { getFormatDateForAppointmentDetails } from "@utils/date";
import { capitalize } from "lodash";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";

type AppointmentDetailsSectionsProps = {
  physiotherapistId: number;
  serviceType: ServiceType;
  dateFrom: string;
  languageLabel: string;
};

const AppointmentDetailsSections: FC<AppointmentDetailsSectionsProps> = ({
  physiotherapistId,
  serviceType,
  dateFrom,
  languageLabel,
}) => {
  const { t } = useTranslation();
  const {
    user: { language },
  } = useAuth();
  const { gapSmall } = globalStyles;

  return (
    <>
      <View style={gapSmall}>
        <Text variant="titleMedium">{t("T00122")}:</Text>
        <Text variant="bodyMedium">
          {getFormatDateForAppointmentDetails(dateFrom, language)}
        </Text>
      </View>
      {serviceType === "Stationary" && (
        <View style={gapSmall}>
          <Text variant="titleMedium">{t("T00139")}:</Text>
          <AddressWithMapRedirect physioId={physiotherapistId} />
        </View>
      )}
      <View style={gapSmall}>
        <Text variant="titleMedium">{t("T00633")}:</Text>
        <Text variant="bodyMedium">{capitalize(languageLabel)}</Text>
      </View>
      <View style={gapSmall}>
        <Text variant="titleMedium">{t("T00600")}:</Text>
        <Text variant="bodyMedium">
          {t(serviceType === "Stationary" ? "T00484" : "T00483")}
        </Text>
      </View>
    </>
  );
};

export default AppointmentDetailsSections;
