import { AppbarTitleWithBackAction } from "@components/Appbar";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TransactionHistoryStackParamList } from "./navigation.types";

import TransactionHistory from "@screens/Profiles/MyProfile/TransactionHistory";
import { TransactionDetails } from "@screens/Profiles/Transactions";
import AppbarTitleWithDefaultBackAction from "@components/Appbar/AppbarTitleWithDefaultBackAction";

const Stack = createNativeStackNavigator<TransactionHistoryStackParamList>();

const TransactionHistoryStackNavigator: FC = () => {
  const { t } = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="TransactionHistory"
        component={TransactionHistory}
        options={{
          header: ({ navigation: { goBack } }) => (
            <AppbarTitleWithBackAction onClose={goBack} title={t("T00789")} />
          ),
        }}
      />
      <Stack.Screen
        name="TransactionDetails"
        component={TransactionDetails}
        options={{
          header: ({ navigation }) => (
            <AppbarTitleWithDefaultBackAction
              title={t("T00111")}
              navigation={navigation}
            />
          ),
        }}
      />
    </Stack.Navigator>
  );
};

export default TransactionHistoryStackNavigator;
