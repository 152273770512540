import { OPTIONS } from "@utils/constants";

const keys = {
  data: "data",
  profile: "profile",
  basic: "basic",
  notMeBasic: "notMeBasic",
  languages: "languages",
  timezone: "timezone",
  currency: "currency",
};

export const queryKeysForPatientAndPhysiotherapist = {
  all: [keys.data] as const,
  basicData: (userId: number) =>
    [...queryKeysForPatientAndPhysiotherapist.all, keys.basic, userId] as const,
  notMeBasicData: (userId: number) =>
    [
      ...queryKeysForPatientAndPhysiotherapist.all,
      keys.notMeBasic,
      userId,
    ] as const,
  profileData: () =>
    [...queryKeysForPatientAndPhysiotherapist.all, keys.profile] as const,
  profileOptions: () =>
    [...queryKeysForPatientAndPhysiotherapist.all, OPTIONS] as const,
  appLanguages: () =>
    [
      ...queryKeysForPatientAndPhysiotherapist.profileOptions(),
      keys.languages,
    ] as const,
  timezone: () =>
    [
      ...queryKeysForPatientAndPhysiotherapist.profileOptions(),
      keys.timezone,
    ] as const,
  currency: () =>
    [
      ...queryKeysForPatientAndPhysiotherapist.profileOptions(),
      keys.currency,
    ] as const,
};
