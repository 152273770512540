import { Dispatch, FC, SetStateAction } from "react";
import { PainTile, PainTileProps } from "@components/Tile/PainTile";
import { Controller, ControllerProps, FieldError } from "react-hook-form";
import { HelperText } from "react-native-paper";

type PainTileWithControllerProps = {
  setScrollEnabled: Dispatch<SetStateAction<boolean>>;
  errors?: FieldError[];
} & Pick<ControllerProps, "name" | "control"> &
  PainTileProps;

const PainTileWithController: FC<PainTileWithControllerProps> = ({
  setScrollEnabled,
  control,
  name,
  errors,
}) => {
  return (
    <Controller
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const fieldErrors = [error, ...(errors || [])].filter(i => !!i);
        return (
          <>
            <PainTile
              onSlidingStart={() => setScrollEnabled(false)}
              onSlidingComplete={() => setScrollEnabled(true)}
              initialValue={+value || 0}
              onChange={onChange}
            />
            {fieldErrors?.map(({ message }, index) => (
              <HelperText type="error" key={index}>
                {message}
              </HelperText>
            ))}
          </>
        );
      }}
      name={name}
      control={control}
    />
  );
};

export default PainTileWithController;
