import { palettes } from "@styles/colors";
import { spacing16, spacing4 } from "@styles/spacing";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";

type Props = {
  isInWebFloatingWindow?: boolean;
};
const OnlyPersonInfoText: FC<Props> = ({ isInWebFloatingWindow }) => {
  const { t } = useTranslation();
  const { textContainer, additionalTextContainerStyles, noInterlocutorText } =
    styles;

  return (
    <View
      style={[
        textContainer,
        !isInWebFloatingWindow && additionalTextContainerStyles,
      ]}>
      <Text
        variant={isInWebFloatingWindow ? "labelSmall" : "titleSmall"}
        style={noInterlocutorText}>
        {t("T01051")}
      </Text>
    </View>
  );
};

export default OnlyPersonInfoText;

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  additionalTextContainerStyles: {
    padding: spacing4,
    backgroundColor: palettes.neutral[25],
    borderTopEndRadius: spacing16,
    borderBottomEndRadius: spacing16,
  },
  noInterlocutorText: {
    color: palettes.primary[100],
    textAlign: "center",
  },
});
