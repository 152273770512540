import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItem, RefreshControl } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { FetchError } from "../../../../components/errors";
import { MedicalRecordTile } from "../../../../components/Tile";
import { MedicalRecordsTabProps } from "../../../../navigators/navigation.types";
import { globalStyles } from "../../../../styles/global";
import { MedicalRecordsSimpleGet } from "./MedicalRecords.types";
import { getMedicalRecords } from "../../../../services/ApiService/medicalRecords";
import { queryKeysMedicalRecord } from "./queryKeysMedicalRecord";

const RecordsCreatedByOtherUsers: FC<
  PropsWithChildren<MedicalRecordsTabProps>
> = ({ navigation: { navigate } }) => {
  const { t } = useTranslation();
  const { sharedToMeList } = queryKeysMedicalRecord;
  const { scrollContainer, gapMedium, loading } = globalStyles;
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: sharedToMeList(),
    queryFn: async () =>
      await getMedicalRecords({
        sharedWithYouByAll: true,
      }),
  });

  const renderItem: ListRenderItem<MedicalRecordsSimpleGet> = useCallback(
    ({ item: { id } }) => (
      <MedicalRecordTile
        recordId={id}
        onPress={() => navigate("RecordPreview", { id })}
      />
    ),
    [navigate],
  );

  if (isLoading) return <ActivityIndicator style={loading} />;

  if (isError) return <FetchError action={refetch} />;

  return (
    <FlatList
      data={data}
      keyExtractor={({ id }, index) =>
        `medical-record-created-by-physiotherapist-${id}-${index}`
      }
      renderItem={renderItem}
      refreshControl={
        <RefreshControl refreshing={isLoading} onRefresh={refetch} />
      }
      contentContainerStyle={[gapMedium, scrollContainer]}
      ListEmptyComponent={<Text variant="labelLarge">{t("T00325")}</Text>}
    />
  );
};

export default RecordsCreatedByOtherUsers;
