import { ProductLogoSygnet, SvgNote } from "@assets/svg";
import { CheckboxListItem } from "@components/ListItems";
import { useAuth } from "@contexts/AuthContext/auth";
import { useAssistant } from "@hooks/user/useAssistant";
import { globalStyles } from "@styles/global";
import { spacing16, spacing8 } from "@styles/spacing";
import { Dispatch, SetStateAction, useCallback } from "react";
import { ScrollView } from "react-native";
import ExercisesFiltersBottomSheetWrapper from "./ExercisesFiltersBottomSheetWrapper";
import { ExercisesFiltersParamsType } from "./SearchExercises";
import { useTranslation } from "react-i18next";

type Props = {
  params: ExercisesFiltersParamsType;
  onClear: () => void;
  setParams: Dispatch<SetStateAction<ExercisesFiltersParamsType>>;
  setTypeBottomSheetVisible: Dispatch<SetStateAction<boolean>>;
};

const TypeFiltersBottomSheetContent = ({
  params,
  onClear,
  setParams,
  setTypeBottomSheetVisible,
}: Props) => {
  const {
    user: { id },
  } = useAuth();
  const { assistantId, isAssistantId } = useAssistant(params?.authorId);
  const { gapMedium, scrollContainer } = globalStyles;
  const { t } = useTranslation();

  const types = [
    {
      name: "Fixme",
      id: assistantId,
      icon: <ProductLogoSygnet />,
      selected: !!params?.authorId && isAssistantId,
    },
    {
      name: t("T00315"),
      id,
      icon: <SvgNote />,
      selected: !!params?.authorId && !isAssistantId,
    },
  ];

  const onPressItem = useCallback(
    (id: number, name: string) => {
      setParams(prevState => {
        const prevAuthorId = prevState?.authorId;
        if (!prevAuthorId) {
          return { ...prevState, authorId: id, authorName: name };
        } else {
          return {
            ...prevState,
            authorId: prevAuthorId === id ? null : id,
            authorName: prevAuthorId === id ? null : name,
          };
        }
      });
      setTypeBottomSheetVisible(false);
    },
    [setParams, setTypeBottomSheetVisible],
  );

  return (
    <ExercisesFiltersBottomSheetWrapper
      header="T01341"
      onClear={onClear}
      params={params}
      type="authorType"
      content={
        <ScrollView
          style={[
            gapMedium,
            scrollContainer,
            { paddingTop: spacing8, paddingBottom: 0 },
          ]}>
          {types.map(({ name, id, icon, selected }, i) => (
            <CheckboxListItem
              key={`${name}-${i}`}
              title={name}
              svg={icon}
              checkboxProps={{ status: selected ? "checked" : "unchecked" }}
              onPress={() => onPressItem(id, name)}
              style={{ paddingVertical: spacing16 }}
            />
          ))}
        </ScrollView>
      }
    />
  );
};

export default TypeFiltersBottomSheetContent;
