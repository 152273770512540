import { ShareMedicalRecord } from "@screens/Profiles/MyProfile/MedicalRecords/MedicalRecords.types";
import { isFuture } from "date-fns";

export const getNumberOfShares = (
  sharedMedicalRecords: ShareMedicalRecord[],
) => {
  if (!sharedMedicalRecords) return 0;
  const sharedRecordsWithFutureDueDate = sharedMedicalRecords.filter(record => {
    if (isFuture(new Date(record.dueDate))) return record;
  });
  return sharedRecordsWithFutureDueDate.length;
};
