import Svg, { SvgProps, Circle, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={60} height={60} fill="none" {...props}>
    <Circle cx={30} cy={30} r={25} fill="#fff" />
    <Path
      fill="#4666FF"
      d="M30 0C13.44 0 0 13.44 0 30c0 16.56 13.44 30 30 30 16.56 0 30-13.44 30-30C60 13.44 46.56 0 30 0Zm-8.13 42.87L11.1 32.1a2.988 2.988 0 0 1 0-4.23 2.988 2.988 0 0 1 4.23 0L24 36.51l20.64-20.64a2.988 2.988 0 0 1 4.23 0 2.988 2.988 0 0 1 0 4.23L26.1 42.87c-1.14 1.17-3.06 1.17-4.23 0Z"
    />
  </Svg>
);
export default SvgComponent;
