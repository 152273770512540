import { Text } from "react-native-paper";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Appointment } from "@screens/Appointments/appointment.types";
import HumanModel from "@components/HumanModel/HumanModel";
import { PainTile } from "@components/Tile/PainTile";

type InfoSectionProps = Partial<Appointment>;

const InfoSection: FC<InfoSectionProps> = ({
  injuryDescription,
  painLevel,
  previouslyTreated,
  humanBones,
  humanMuscles,
  humanGeneral,
  gender,
  disfunctionDescription,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Text variant="titleMedium">{`${t("T00280")}:`}</Text>
      <HumanModel
        humanGeneral={humanGeneral}
        gender={gender}
        humanMuscles={humanMuscles}
        humanBones={humanBones}
      />
      <Text variant="titleMedium">{`${t("T00285")}:`}</Text>
      <Text variant="bodyMedium">{injuryDescription}</Text>
      <PainTile disableSliding initialValue={painLevel} />
      <Text variant="titleMedium">{t("T00573")}</Text>
      <Text variant="bodyMedium">
        {t(previouslyTreated ? "T00575" : "T00574")}
      </Text>
      <Text variant="titleMedium">{t("T00932")}</Text>
      <Text variant="bodyMedium">{disfunctionDescription || t("T00574")}</Text>
    </>
  );
};

export default InfoSection;
