import { FC, useCallback } from "react";
import { ActivityIndicator, IconButton } from "react-native-paper";
import BaseStatisticInfoTile from "@components/Tile/BaseStatisticInfoTile";
import { useAuth } from "@contexts/AuthContext/auth";
import { useQuery } from "@tanstack/react-query";
import { queryKeysEarningsStats } from "@screens/Profiles/MyProfile/RehabStatistics/queryKeysRehabilitatorStatistics";
import { getEarningsStats } from "@api/charts";
import { FetchError } from "@components/errors";
import { TransKey } from "@globalTypes/i18next";

export enum StatsTileType {
  TOTAL = "TOTAL",
  MONTHLY = "MONTHLY",
  MONTHLY_SHORT = "MONTHLY_SHORT",
}

export type StatsTileProps = {
  type: StatsTileType;
};

const getTitle = (type: StatsTileType): TransKey => {
  switch (type) {
    case StatsTileType.TOTAL:
      return "T00746";
    case StatsTileType.MONTHLY:
      return "T00747";
    case StatsTileType.MONTHLY_SHORT:
      return "T00679";
  }
};

const EarningsTile: FC<StatsTileProps> = ({ type }) => {
  const {
    user: { currency },
  } = useAuth();
  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: queryKeysEarningsStats.all,
    queryFn: getEarningsStats,
    refetchIntervalInBackground: true,
    refetchInterval: 120000,
  });

  const getValue = useCallback(() => {
    const { totalEarnings, currentMonthEarnings } = data;
    const value =
      type === StatsTileType.TOTAL
        ? totalEarnings / 100
        : currentMonthEarnings / 100;
    return `${value || 0}`;
  }, [data, type]);

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  const title = getTitle(type);
  return (
    <BaseStatisticInfoTile
      key={`earnings-${type}`}
      leftIcon={() => <IconButton icon="cash-multiple" />}
      title={title}
      value={getValue()}
      label={currency as TransKey}
    />
  );
};

export default EarningsTile;
