import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { spacing48 } from "@styles/spacing";

const EmptyHoursInformation: FC = () => {
  const { t } = useTranslation();
  const { container, text } = styles;
  return (
    <View style={container}>
      <Text variant="bodySmall" style={text}>
        {t("T00576")}
      </Text>
    </View>
  );
};

export default EmptyHoursInformation;

const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: "center", alignItems: "center" },
  text: { paddingHorizontal: spacing48, textAlign: "center" },
});
