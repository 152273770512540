import { FC, PropsWithChildren } from "react";
import { View } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { HelpCenterStackParamList } from "@navigators/navigation.types";
import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { useTranslation } from "react-i18next";
import { SectionTitleWithInfo } from "@components/Section/TitleWithInfo";
import { useQuery } from "@tanstack/react-query";
import { queryKeysFaq } from "@screens/Common/queryKeysHelpCenter";
import { getFaqCategories } from "@services/ApiService/helpCenter";
import { FAQCategoryItem } from "@components/ListItems";
import { ActivityIndicator } from "react-native-paper";
import { FetchError } from "@components/errors";

type HelpCenterProps = PropsWithChildren<
  NativeStackScreenProps<HelpCenterStackParamList, "HelpCenter">
>;

export type HelpCenterTopicType = "Patient account" | "Rehabilitator account";

const HelpCenter: FC<HelpCenterProps> = ({ navigation: { navigate } }) => {
  const { t } = useTranslation();

  const { container, gapLarge, gapMedium, loading } = globalStyles;

  const {
    data: categories,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryFn: getFaqCategories,
    queryKey: queryKeysFaq.categories_list(),
  });

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <View style={[container, gapLarge]}>
      <SectionTitleWithInfo title={t("T00700")} desc={t("T00701")} />
      <View style={gapMedium}>
        {categories.map(({ id: catID }, i) => (
          <FAQCategoryItem
            key={`category-${catID}-${i}`}
            id={catID}
            onPress={(id, name) => navigate("HelpCenterTopics", { id, name })}
          />
        ))}
      </View>
      <PrimaryButton
        mode="outlined"
        label="T00390"
        onPress={() => navigate("ContactForm")}
      />
    </View>
  );
};

export default HelpCenter;
