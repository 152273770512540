import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { Dispatch, FC, SetStateAction } from "react";
import { Text } from "react-native-paper";
import { StyleSheet, View } from "react-native";
import { spacing12 } from "@styles/spacing";
import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { useTranslation } from "react-i18next";

type EndWorkoutBottomSheetProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onContinue: () => void;
  onEndWithoutSaving: () => void;
  onEndAndSave: () => void;
};

const EndWorkoutBottomSheet: FC<EndWorkoutBottomSheetProps> = ({
  visible,
  setVisible,
  onContinue,
  onEndWithoutSaving,
  onEndAndSave,
}) => {
  const { t } = useTranslation();
  const { container } = styles;
  const handleSaveWorkout = () => {
    onEndAndSave();
    setVisible(false);
  };
  const handleContinueWorkout = () => {
    setVisible(false);
    onContinue();
  };
  const handleEndWithoutSaving = () => {
    setVisible(false);
    onEndWithoutSaving();
  };
  return (
    <BottomModalContainer
      modalVisible={visible}
      setModalVisible={setVisible}
      scrollableContent>
      <View style={container}>
        <Text variant="bodyLarge">{t("T01258")}</Text>
        <PrimaryButton
          mode="outlined"
          label="T01255"
          onPress={handleEndWithoutSaving}
        />
        <PrimaryButton
          mode="outlined"
          label="T01256"
          onPress={handleSaveWorkout}
        />
        <PrimaryButton label="T01257" onPress={handleContinueWorkout} />
      </View>
    </BottomModalContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: spacing12,
    ...globalStyles.gapLarge,
  },
});

export default EndWorkoutBottomSheet;
