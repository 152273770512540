import { CSSProperties } from "react";
import { videoViewStyles } from "../components/video-view/video-view.styles";

export const getVideoStyle = (singleSelf: boolean, self: boolean) => {
  let style: CSSProperties = {
    ...videoViewStyles.smallCameraWindow,
    display: "none",
  };
  if (singleSelf) {
    style = { ...style, ...videoViewStyles.singleSelfView };
  }
  if (self) {
    style = { ...style, display: "flex" };
  }
  return style;
};

export const SELF_VIDEO_ID = "ZOOM_WEB_SDK_SELF_VIDEO";
