import { LIST, MY } from "@utils/constants";

const keys = {
  patients: "patients",
};

export const queryKeysPatient = {
  all: [keys.patients] as const,
  myList: () => [...queryKeysPatient.all, LIST, MY] as const,
  myListSearch: (searchQuery?: string) =>
    [...queryKeysPatient.myList(), searchQuery] as const,
};
