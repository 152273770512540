import DocumentListItem from "@components/ListItems/DocumentListItem";
import ReportWrapper from "@components/Report/ReportWrapper";
import { ReportProps } from "@components/Report/report.types";
import { FileAttachment } from "@globalTypes/common.types";
import { globalStyles } from "@styles/global";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Linking, View } from "react-native";
import { Text } from "react-native-paper";

type MedicalRecordAttachmentListSectionType = ReportProps & {
  attachments: FileAttachment[];
  isMyRecord: boolean;
};

const MedicalRecordAttachmentsList: FC<
  MedicalRecordAttachmentListSectionType
> = ({ attachments, isMyRecord, ...props }) => {
  const { t } = useTranslation();

  return (
    <View style={globalStyles.gapSmall}>
      <Text variant="titleMedium">{`${t("T00596")}:`}</Text>
      {attachments?.length ? (
        attachments.map(({ name, file }, i) => {
          return isMyRecord ? (
            <DocumentListItem
              key={`${name}-${i}`}
              title={name}
              onPress={async () => {
                await Linking.openURL(file);
              }}
            />
          ) : (
            <ReportWrapper
              {...props}
              key={`${name}-${i}`}
              onPress={async () => {
                await Linking.openURL(file);
              }}>
              <DocumentListItem title={name} />
            </ReportWrapper>
          );
        })
      ) : (
        <Text variant="bodyMedium">{t("T00874")}</Text>
      )}
    </View>
  );
};

export default MedicalRecordAttachmentsList;
