import ReportWrapper from "@components/Report/ReportWrapper";
import { FC } from "react";
import { MessageTileWithReportType } from "./types/MessageTile.types";
import MessageTile from "./MessageTile";
import { SystemMessage } from "@components/Report/report.types";

export const MessageTileWithReport: FC<MessageTileWithReportType> = ({
  withReport = false,
  messageId,
  ...props
}) => {
  return (
    <ReportWrapper
      systemMessage={withReport && SystemMessage.REPORT_CHAT_MESSAGE}
      item={messageId}>
      <MessageTile {...props} />
    </ReportWrapper>
  );
};

export default MessageTileWithReport;
