import { FC, useMemo } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { globalStyles } from "@styles/global";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { Control, useForm } from "react-hook-form";
import {
  MULTILINE_INPUT_MAX_LENGTH,
  REPORT_INPUT_MIN_LENGTH,
} from "@utils/constants";
import { PrimaryButton } from "@components/buttons";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useMutation } from "@tanstack/react-query";
import { reportPatient, reportPhysiotherapist } from "@api/users";
import { useErrors } from "@hooks/useErrors";
import { AxiosError } from "axios";
import { Input } from "@components/Input";

type Props = {
  recipient: number;
  onSuccess: () => void;
};

type Report = {
  description: string;
};

const ReportBottomSheetContent: FC<Props> = ({ recipient, onSuccess }) => {
  const { t } = useTranslation();
  const { isPhysiotherapist } = useUserDetails();
  const { setErrorsFromResponse, errors } = useErrors();
  const schema = useMemo(
    () =>
      object().shape({
        description: string()
          .min(REPORT_INPUT_MIN_LENGTH)
          .max(MULTILINE_INPUT_MAX_LENGTH)
          .required(t("T00014")),
      }),
    [t],
  );
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  const { mutate, isLoading } = useMutation({
    mutationFn: isPhysiotherapist ? reportPatient : reportPhysiotherapist,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: onSuccess,
  });
  const onSubmit = ({ description }: Report) =>
    mutate({
      recipient,
      description,
    });

  return (
    <View style={[globalStyles.gapLarge]}>
      <Text variant="bodyLarge">
        {isPhysiotherapist ? t("T00559") : t("T00558")}
      </Text>
      <Text variant="bodyMedium">
        {isPhysiotherapist ? t("T00563") : t("T00561")}
      </Text>
      <Text variant="bodyMedium">
        {isPhysiotherapist ? t("T00564") : t("T00562")}
      </Text>
      <View style={globalStyles.gapMedium}>
        <Input
          name="description"
          label="T00368"
          control={control as unknown as Control}
          multiline
          maxLength={MULTILINE_INPUT_MAX_LENGTH}
          errors={errors?.description}
          isInBottomSheet
        />
        <PrimaryButton
          label="T00901"
          onPress={handleSubmit(onSubmit)}
          loading={isLoading}
        />
      </View>
    </View>
  );
};

export default ReportBottomSheetContent;
