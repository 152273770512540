import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { globalStyles } from "@styles/global";
import ExerciseWithSeriesTile from "@components/Tile/exercise/ExerciseWithSeriesTile";

type ExercisesListProps = {
  exercises: number[];
  onExercisePress?: (exerciseId: number) => void;
};

const ExercisesInTrainingList: FC<ExercisesListProps> = ({
  exercises,
  onExercisePress,
}) => {
  const { t } = useTranslation();
  return (
    <View style={globalStyles.gapMedium}>
      <Text variant="titleMedium">{t("T00169")}:</Text>
      {exercises.map((id, index) => (
        <ExerciseWithSeriesTile
          key={`${id}-${index}`}
          exerciseId={id}
          onPress={() => onExercisePress(id)}
        />
      ))}
    </View>
  );
};

export default ExercisesInTrainingList;
