import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { useAuth } from "@contexts/AuthContext/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrors } from "@hooks/useErrors";
import { postRedeemCode } from "@services/ApiService/common";
import { useMutation } from "@tanstack/react-query";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { AxiosError } from "axios";
import { FC } from "react";
import { useForm, Control, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { object, string } from "yup";

type FormType = { code: string };

type Props = { hideModal: () => void };

export const EnterPromotionCodeBottomSheet: FC<Props> = ({
  hideModal: onPress,
}) => {
  const { setErrorsFromResponse, errors } = useErrors();
  const { t } = useTranslation();

  const schema = object().shape({
    code: string().required(t("T00014")).trim(),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: "",
    },
  });

  const { updateUser } = useAuth();

  const { mutate } = useMutation({
    mutationFn: postRedeemCode,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: () => {
      updateUser();
      showAlertWithCustomButtons({
        title: t("T01351"),
        message: t("T01398"),
        confirmButton: { text: t("T00163"), onPress },
      });
    },
  });

  const onSubmit = ({ code }: FormType) => mutate(code);

  return (
    <View>
      <Input
        name="code"
        label="T01399"
        control={control as unknown as Control<FieldValues>}
        isInBottomSheet
        errors={errors?.code}
        extraHelperText="T01445"
      />
      <PrimaryButton label="T01177" onPress={handleSubmit(onSubmit)} />
    </View>
  );
};
