import { FC, PropsWithChildren, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { spacing12 } from "@styles/spacing";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { BottomTutorialComponent } from "@components/BottomSheet";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import {
  SvgProphylacticWorkout1,
  SvgProphylacticWorkout2,
} from "@assets/svg/tutorials";
import ActiveAndInactiveTrainingsList from "@components/Training/ActiveAndInactiveTrainingsList";
import { AllPatientTrainingsTabsProps } from "@navigators/navigation.types";

const GeneralTrainings: FC<
  PropsWithChildren<AllPatientTrainingsTabsProps>
> = () => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "left"]}>
      <ActiveAndInactiveTrainingsList
        ListHeaderComponent={
          <TutorialTextButton
            label="T00666"
            onPress={() => setModalVisible(true)}
            subContainerStyle={{ padding: spacing12 }}
          />
        }
        isAssistant={true}
      />
      <BottomModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}>
        <BottomTutorialComponent
          title="T00669"
          content={[
            {
              text: "T00667",
              svgComponent: () => <SvgProphylacticWorkout1 />,
            },
            {
              text: "T00668",
              svgComponent: () => <SvgProphylacticWorkout2 />,
            },
          ]}
          onPress={() => setModalVisible(false)}
        />
      </BottomModalContainer>
    </SafeAreaView>
  );
};

export default GeneralTrainings;
