import { VerificationTile } from "@components/Tile";
import InfoTile from "@components/Tile/InfoTile";
import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { FC, PropsWithChildren, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import {
  convertBooleanToVerificationStatus,
  getPhysioParentVerificationStatuses,
} from "@components/Status/helpers";
import { getRehabVerificationProgress } from "@services/ApiService/verifications";
import { useQuery } from "@tanstack/react-query";
import { queryKeysVerficiations } from "../queryKeysPhysiotherapistVerification";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { FetchError } from "@components/errors";
import { ProfileCompletionInfo } from "../../ProfileCompletionInfo";
import AppbarTitleWithDefaultBackAction from "@components/Appbar/AppbarTitleWithDefaultBackAction";

type PaymentsScreenType = PropsWithChildren<
  NativeStackScreenProps<ProfileCompletionStackParamList, "Payments">
>;

const Payments: FC<PaymentsScreenType> = ({
  navigation: { navigate, setOptions },
}) => {
  const { t } = useTranslation();
  const { isPhysiotherapist } = useUserDetails();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysVerficiations.verificationProgress(),
    queryFn: getRehabVerificationProgress,
    enabled: isPhysiotherapist,
  });

  const { onlinePaymentVerification, stationaryPaymentVerification } = useMemo(
    () =>
      isPhysiotherapist && data && getPhysioParentVerificationStatuses(data),
    [isPhysiotherapist, data],
  );

  useEffect(() => {
    setOptions({
      header: ({ navigation }) => (
        <AppbarTitleWithDefaultBackAction
          title={t("T00710")}
          filledIcon={
            stationaryPaymentVerification && onlinePaymentVerification
          }
          navigation={navigation}
        />
      ),
    });
  }, [
    t,
    navigate,
    stationaryPaymentVerification,
    onlinePaymentVerification,
    setOptions,
  ]);

  const { scrollContainer, gapLarge, loading } = globalStyles;

  return (
    <ScrollView style={scrollContainer} contentContainerStyle={gapLarge}>
      <ProfileCompletionInfo />
      <InfoTile
        content={<Text variant="bodyMedium">{t("T01088")}</Text>}
        status="info"
      />
      {isLoading ? (
        <ActivityIndicator style={loading} />
      ) : isError ? (
        <FetchError action={refetch} />
      ) : (
        <>
          <VerificationTile
            mode="outlined"
            title="T01090"
            content="T01091"
            onPress={() => navigate("StationaryPayments")}
            status={convertBooleanToVerificationStatus(
              stationaryPaymentVerification,
            )}
            type="profile completion"
          />
          <VerificationTile
            mode="outlined"
            title="T01089"
            content="T01092"
            onPress={() => navigate("OnlinePayments")}
            status={convertBooleanToVerificationStatus(
              onlinePaymentVerification,
            )}
            type="profile completion"
          />
        </>
      )}
    </ScrollView>
  );
};

export default Payments;
