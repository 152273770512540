import Svg, { ClipPath, Defs, G, Path, Rect, SvgProps } from "react-native-svg";

type Props = { props?: SvgProps; customColor?: string };

const SvgComponent = ({ props, customColor }: Props) => (
  <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <G clipPath="url(#clip0_53306_29806)">
      <Path
        d="M10.0002 1.66699C6.50016 1.66699 3.3335 4.35033 3.3335 8.50033C3.3335 11.1503 5.37516 14.267 9.45016 17.8587C9.76683 18.1337 10.2418 18.1337 10.5585 17.8587C14.6252 14.267 16.6668 11.1503 16.6668 8.50033C16.6668 4.35033 13.5002 1.66699 10.0002 1.66699ZM10.0002 10.0003C9.0835 10.0003 8.3335 9.25033 8.3335 8.33366C8.3335 7.41699 9.0835 6.66699 10.0002 6.66699C10.9168 6.66699 11.6668 7.41699 11.6668 8.33366C11.6668 9.25033 10.9168 10.0003 10.0002 10.0003Z"
        fill={customColor || "black"}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_53306_29806">
        <Rect width="20" height="20" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgComponent;
