import Svg, { SvgProps, G, Rect, Path, Defs, ClipPath } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={48} height={48} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Rect width={24} height={24} x={12} y={12} fill="#fff" rx={12} />
      <Path
        fill="#FFC22D"
        d="M24 14c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10Zm1 15h-2v-2h2v2Zm0-4h-2v-6h2v6Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Rect width={40} height={40} x={4} y={4} fill="#fff" rx={8} />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgComponent;
