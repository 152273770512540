import { ModelType, Orientation } from "./humanModel.types";

type ParametersForSettingInitialOrientation = {
  array: string[];
  key: ModelType;
  orientation: Orientation;
  longestPartArrayLength: number;
};

const thresholds: Record<ModelType, number> = {
  [ModelType.GENERAL]: 27,
  [ModelType.MUSCLE]: 32,
  [ModelType.BONE]: 34,
};

export const setInitialOrientation = ({
  array,
  key,
  orientation = "front",
  longestPartArrayLength = 0,
}: ParametersForSettingInitialOrientation) => {
  const parsedArray = array
    .map(value => parseInt(value.replace(/\D/g, ""), 10))
    .sort((a, b) => a - b);
  const threshold = thresholds[key];

  const arrayFront: number[] = [];
  const arrayBack: number[] = [];

  parsedArray.map(el =>
    el <= threshold ? arrayFront.push(el) : arrayBack.push(el),
  );

  if (arrayFront.length > longestPartArrayLength) {
    longestPartArrayLength = arrayFront.length;
    if (orientation === "back") orientation = "front";
  }
  if (arrayBack.length > longestPartArrayLength) {
    longestPartArrayLength = arrayBack.length;
    if (orientation === "front") orientation = "back";
  }
  return orientation;
};

export const findMaxKey = (
  obj: Record<ModelType, number>,
): ModelType | null => {
  if (
    obj[ModelType.GENERAL] >= obj[ModelType.MUSCLE] &&
    obj[ModelType.GENERAL] >= obj[ModelType.BONE]
  ) {
    return ModelType.GENERAL;
  } else if (obj[ModelType.MUSCLE] >= obj[ModelType.BONE]) {
    return ModelType.MUSCLE;
  } else {
    return ModelType.BONE;
  }
};
