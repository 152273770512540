import AsyncStorage from "@react-native-async-storage/async-storage";
import { LAST_PUSH_PERMISSION_REQUEST_DATE } from "@utils/constants";
import { addDays, isPast } from "date-fns";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useCheckNotifications } from "./useCheckNotifications";
import { useNotificationMainSettings } from "./useNotificationMainSettings";

type UseCheckNotificationsInMessageCenterOptions = {
  setIsPermissionModalVisible: Dispatch<SetStateAction<boolean>>;
};

export const useCheckNotificationsInMessageCenter = ({
  setIsPermissionModalVisible,
}: UseCheckNotificationsInMessageCenterOptions) => {
  const [shouldAskAboutPermission, setShouldAskAboutPermission] =
    useState(false);

  const checkLastPushPermissionRequestDate = useCallback(async () => {
    const dateToCheck = await AsyncStorage.getItem(
      LAST_PUSH_PERMISSION_REQUEST_DATE,
    );
    if (!dateToCheck)
      await AsyncStorage.setItem(
        LAST_PUSH_PERMISSION_REQUEST_DATE,
        new Date().toString(),
      );
    const formattedDateToCheck = new Date(dateToCheck);

    return setShouldAskAboutPermission(isPast(formattedDateToCheck));
  }, []);

  const { data, isSuccess } = useNotificationMainSettings();
  const { handleCheckNotifications, isNotificationGranted } =
    useCheckNotifications({
      mainNotification: data,
      onPermissionsGranted: () => setIsPermissionModalVisible(false),
    });

  useEffect(() => {
    if (shouldAskAboutPermission && !isNotificationGranted) {
      setIsPermissionModalVisible(true);
      const sevenDaysFromNow = addDays(new Date(), 7).toString();
      void AsyncStorage.setItem(
        LAST_PUSH_PERMISSION_REQUEST_DATE,
        sevenDaysFromNow,
      );
      setShouldAskAboutPermission(false);
    }
  }, [
    handleCheckNotifications,
    isNotificationGranted,
    setIsPermissionModalVisible,
    setShouldAskAboutPermission,
    shouldAskAboutPermission,
  ]);

  useEffect(
    () => {
      if (isSuccess) void checkLastPushPermissionRequestDate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess],
  );

  return {
    shouldAskAboutPermission,
    setShouldAskAboutPermission,
    isNotificationGranted,
    handleCheckNotifications,
  };
};
