import { AppbarTitleWithBackAction } from "@components/Appbar";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { HelpCenterStackParamList } from "./navigation.types";

import {
  ContactForm,
  HelpCenter,
  HelpCenterSelectedTopic,
  HelpCenterTopics,
} from "@screens/Profiles/MyProfile/CommonProfile/HelpCenter";

const Stack = createNativeStackNavigator<HelpCenterStackParamList>();

const HelpCenterStackNavigator: FC = () => {
  const { t } = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="HelpCenter"
        component={HelpCenter}
        options={{
          header: ({ navigation: { goBack } }) => (
            <AppbarTitleWithBackAction onClose={goBack} title={t("T00088")} />
          ),
        }}
      />
      <Stack.Screen
        name="HelpCenterTopics"
        component={HelpCenterTopics}
        options={{
          header: ({ navigation: { goBack, popToTop, pop } }) => (
            <AppbarTitleWithBackAction
              onBack={goBack}
              onClose={() => {
                popToTop();
                pop();
              }}
              title={t("T00107")}
            />
          ),
        }}
      />
      <Stack.Screen
        name="HelpCenterSelectedTopic"
        component={HelpCenterSelectedTopic}
        options={{
          header: ({ navigation: { goBack, popToTop, pop } }) => (
            <AppbarTitleWithBackAction
              onBack={goBack}
              onClose={() => {
                popToTop();
                pop();
              }}
              title={t("T00108")}
            />
          ),
        }}
      />
      <Stack.Screen
        name="ContactForm"
        component={ContactForm}
        options={{
          header: ({ navigation: { goBack, pop, popToTop } }) => (
            <AppbarTitleWithBackAction
              onBack={goBack}
              onClose={() => {
                popToTop();
                pop();
              }}
              title={t("T00089")}
            />
          ),
        }}
      />
    </Stack.Navigator>
  );
};

export default HelpCenterStackNavigator;
