import { Day } from "@components/PhysioAvailability/PhysioAvailability.types";
import MultiColorText from "@components/Text/MultiColorText";
import { FetchError } from "@components/errors";
import { PublicPhysiotherapistFullProfile } from "@contexts/AuthContext/user.types";
import { useFutureAvailability } from "@hooks/availability/useFutureAvailability";
import { queryKeysPhysiotherapistProfileData } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { getPhysiotherapistFullData } from "@services/ApiService/users";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { theme } from "@styles/theme";
import { useQuery } from "@tanstack/react-query";
import { getCurrentDatePlus30min } from "@utils/date";
import { inactiveUserAlert } from "@utils/showAlert";
import { setLanguagesText } from "@utils/textFromatters";
import { format } from "date-fns";
import { capitalize } from "lodash";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GestureResponderEvent, StyleSheet, View } from "react-native";
import {
  ActivityIndicator,
  Divider,
  Card as RNPCard,
  Text,
} from "react-native-paper";
import { CardProps } from "../Card.types";
import { PhysiotherapistDataWithRating } from "./PhysiotherapistDataWithRating";

const ExtendedPhysiotherapistCard: FC<
  CardProps<PublicPhysiotherapistFullProfile>
> = ({
  initialData: { id },
  mode = "outlined",
  elevation = 0,
  isLoading,
  onPress,
  style,
  ...props
}) => {
  const { data, isFetched, isError, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapistProfileData.full(id),
    queryFn: async () => await getPhysiotherapistFullData(id),
  });

  const {
    data: allAvailability,
    isFetched: isAvailabilityFetched,
    isError: isAvailabilityError,
    refetch: refetchAvailability,
  } = useFutureAvailability({
    physiotherapistId: id,
    dateFrom: getCurrentDatePlus30min(),
  });

  const { t } = useTranslation();
  const { containerOutlined } = styles;

  const isAllFetched = useMemo(
    () => isFetched && isAvailabilityFetched,
    [isFetched, isAvailabilityFetched],
  );

  const isAnyError = useMemo(
    () => isError || isAvailabilityError,
    [isError, isAvailabilityError],
  );

  const refetchQueries = async () =>
    await Promise.all([refetch(), refetchAvailability()]);

  const handleOnPress = (e: GestureResponderEvent) => {
    if (!data.isActive) return inactiveUserAlert();
    return onPress(e);
  };
  const renderAvailability = useCallback(
    (availability: Day[] | undefined, sectionTitle: string) => {
      if (!availability || availability.length === 0) return null;

      const day = availability[0];
      const firstAvailableDay = day?.hours?.filter(
        ({ appointment }) => !appointment,
      )?.[0];

      return (
        day?.hours?.some(({ appointment }) => appointment === null) && (
          <MultiColorText
            leftText={sectionTitle}
            rightText={
              firstAvailableDay &&
              `${format(new Date(firstAvailableDay.dateFrom), "dd.MM - HH:mm")}`
            }
          />
        )
      );
    },
    [],
  );

  return (
    <RNPCard
      elevation={elevation as never}
      mode={mode}
      style={[mode === "outlined" && containerOutlined, style]}
      onPress={!isAnyError && handleOnPress}
      {...props}>
      {isAnyError || !isAllFetched || isLoading ? (
        <RNPCard.Content>
          {isAnyError ? (
            <FetchError action={refetchQueries} coverScreen={false} />
          ) : (
            <ActivityIndicator />
          )}
        </RNPCard.Content>
      ) : (
        <RNPCard.Content style={globalStyles.gapMedium}>
          <PhysiotherapistDataWithRating data={data} />
          <Divider bold />
          <View style={globalStyles.gapSmall}>
            {data?.aboutMe && (
              <Text variant="bodyMedium" numberOfLines={3}>
                {data.aboutMe}
              </Text>
            )}
            {data?.spokenLanguages?.length > 0 && (
              <MultiColorText
                leftText={`${t("T00604")}:`}
                rightText={`${setLanguagesText(
                  data.spokenLanguages.map(({ languageFull }) =>
                    capitalize(languageFull),
                  ),
                )} ${
                  data.spokenLanguages.length > 2
                    ? t("T00641", {
                        value: data.spokenLanguages.length - 2,
                      })
                    : ""
                }`}
              />
            )}
            <MultiColorText
              leftText={`${t("T00463")}: `}
              rightText={data?.address?.town}
            />
            {renderAvailability(allAvailability?.online, `${t("T01541")}: `)}
            {renderAvailability(
              allAvailability?.stationary,
              `${t("T01542")}: `,
            )}
          </View>
        </RNPCard.Content>
      )}
    </RNPCard>
  );
};

export default ExtendedPhysiotherapistCard;

const styles = StyleSheet.create({
  containerOutlined: {
    borderColor: theme.colors.outlineVariant,
    backgroundColor: palettes.primary[99],
  },
});
