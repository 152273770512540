import { ImagePickerAsset } from "expo-image-picker";
import { DocumentPickerAsset } from "expo-document-picker";
import {
  FileType,
  PickedAssetType,
  ValidateFileSizeArgs,
  ValidateResultsArgs,
} from "./types";
import {
  maxImageSizeInBytes,
  maxPdfSizeInBytes,
  maxVideoSizeInBytes,
  videoTypes,
} from "./constants";
import { isWeb } from "@utils/constants";

const isImagePickerAsset = (
  asset: ImagePickerAsset | DocumentPickerAsset,
): asset is ImagePickerAsset => {
  return (asset as ImagePickerAsset).fileName !== undefined;
};

const getFileFormatFromUri = (uri: string): string | null => {
  const stringBeforeFileFormat = isWeb ? "image/" || "video/" : ".";
  const lastIndex = uri.lastIndexOf(stringBeforeFileFormat);
  if (lastIndex === -1) return null;

  const fileFormat = uri.slice(lastIndex + 1);
  return fileFormat.toLowerCase();
};

export const parsePickedElements = (
  data: (ImagePickerAsset | DocumentPickerAsset)[],
): PickedAssetType[] => {
  if (data?.length) {
    return data.map(el => {
      if (isImagePickerAsset(el)) {
        const { fileName, uri } = el;
        return { name: fileName, uri };
      } else {
        const { name, uri } = el;
        return { name, uri };
      }
    });
  }
  return [];
};

const validateFileFormat = (
  type: string,
  supportedFileTypes: FileType[],
): FileType | null => {
  if (!type) return null;
  const lowercaseType = type.toLowerCase();
  for (const fileType of supportedFileTypes) {
    const expectedType = fileType.toLowerCase();
    if (lowercaseType.includes(expectedType)) return fileType;
  }
  return null;
};

export const bytesToMegabytes = (bytes: number) => bytes / (1024 * 1024);

const getMaxFileSize = (format: FileType): number => {
  if (videoTypes.includes(format)) return maxVideoSizeInBytes;
  if (format === "PDF") return maxPdfSizeInBytes;
  return maxImageSizeInBytes;
};

const validateFileSize = ({
  size,
  format,
  name,
  alert,
}: ValidateFileSizeArgs) => {
  if (!size) return true; //if file has no size property, skip frontend validation
  const maxFileSizeInBytes = getMaxFileSize(format);
  const isValid = size < maxFileSizeInBytes;
  !isValid && alert(maxFileSizeInBytes, name);
  return isValid;
};

export const validateGalleryResults = ({
  results,
  supportedFileTypes,
  invalidFileSizeAlert,
  invalidFileFormatAlert,
}: ValidateResultsArgs<ImagePickerAsset>) => {
  const invalidIndices: number[] = [];
  results.forEach(({ fileName, uri, fileSize }, index) => {
    const fileFormat = validateFileFormat(
      getFileFormatFromUri(uri),
      supportedFileTypes,
    );
    const fileDescription = fileName ?? uri;
    if (!fileFormat) invalidFileFormatAlert(fileDescription);
    const isValidSize = validateFileSize({
      size: fileSize,
      format: fileFormat,
      name: fileDescription,
      alert: invalidFileSizeAlert,
    });
    if (!fileFormat || !isValidSize) invalidIndices.push(index);
  });
  return invalidIndices;
};

export const validateFileResults = ({
  results,
  supportedFileTypes,
  invalidFileSizeAlert,
  invalidFileFormatAlert,
}: ValidateResultsArgs<DocumentPickerAsset>) => {
  const invalidIndices: number[] = [];
  results.forEach(({ mimeType, name, uri, size }, index) => {
    const fileFormat = validateFileFormat(mimeType, supportedFileTypes);
    const fileDescription = name ?? uri;
    if (!fileFormat) invalidFileFormatAlert(fileDescription);
    const isValidSize = validateFileSize({
      size,
      format: fileFormat,
      name: fileDescription,
      alert: invalidFileSizeAlert,
    });
    if (!fileFormat || !isValidSize) invalidIndices.push(index);
  });
  return invalidIndices;
};
