import DropdownPicker from "@components/DropdownPicker/DropdownPicker";
import InfoTile from "@components/Tile/InfoTile";
import { PrimaryButton } from "@components/buttons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useServiceMutation } from "@hooks/verification/useServiceMutation";
import { ServiceDataType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { freeServicesPrices } from "@utils/prices";
import { FC, useMemo, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { object, string } from "yup";
import { ServiceFormType } from "./AddServiceBottomSheetContent";
import ServiceTypeButtonAndSlotsPicker from "./ServiceTypeButtonAndSlotsPicker";

type AddFreeServiceBottomSheetContentProps = {
  serviceName: string;
  errors: { [x: string]: FieldError[] };
  service?: ServiceDataType;
  serviceItem?: number;
  hideBottomSheet?: () => void;
};

const AddFreeServiceBottomSheetContent: FC<
  AddFreeServiceBottomSheetContentProps
> = ({ serviceName, errors, service = null, serviceItem, hideBottomSheet }) => {
  const [slotsDropdownOpen, setSlotsDropdownOpen] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState<number>(
    service?.slots - 1 || 0,
  );
  const [serviceFormData, setServiceFormData] = useState<ServiceFormType>(null);
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      object().shape({
        serviceType: string().required(t("T00028")),
      }),
    [t],
  );

  const { handleSubmit, control, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate, isMutationLoading } = useServiceMutation({
    serviceType: serviceFormData?.serviceType,
    prices: freeServicesPrices,
    hideBottomSheet,
    service,
    selectedSlots,
  });

  const onPressButton = (data: ServiceFormType) => {
    mutate({
      ...data,
      serviceItem,
      slots: selectedSlots + 1,
      prices: freeServicesPrices,
    });
    setServiceFormData(data);
  };

  return (
    <View style={[globalStyles.gapLarge, { marginVertical: spacing8 }]}>
      <Text variant="bodyLarge">{serviceName}</Text>
      <ServiceTypeButtonAndSlotsPicker
        control={control}
        selectedSlots={selectedSlots}
        setSelectedSlots={setSelectedSlots}
        slotsDropdownOpen={slotsDropdownOpen}
        setSlotsDropdownOpen={setSlotsDropdownOpen}
        watch={watch}
        serviceType={service?.serviceType}
        errors={errors?.serviceType}
      />
      <DropdownPicker<number>
        items={[{ label: t("T01521"), value: 0 }]}
        defaultValue={0}
        dropdownLabelText="T00545"
        isInBottomSheet
        disabled
      />
      <InfoTile status="info" content={<Text>{t("T01520")}</Text>} />
      <PrimaryButton
        onPress={handleSubmit(onPressButton)}
        label="T00250"
        loading={isMutationLoading}
        disabled={isMutationLoading}
      />
    </View>
  );
};

export default AddFreeServiceBottomSheetContent;
