import { spacing16 } from "@styles/spacing";
import { FC } from "react";
import { View, Animated, StyleProp, ViewStyle } from "react-native";
import Lottie, { AnimationObject } from "lottie-react-native";
import { palettes } from "@styles/colors";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { isWeb } from "@utils/constants";

type BackgroundWithLottieAnimationProps = {
  animation: string | AnimationObject | { uri: string };
};

const AnimatedLottieView = Animated.createAnimatedComponent(Lottie);

const BackgroundWithLottieAnimation: FC<BackgroundWithLottieAnimationProps> = ({
  animation,
}) => {
  const { height } = useWindowDimensions();

  const containerStyle: StyleProp<ViewStyle> = {
    backgroundColor: palettes.primary[90],
    minHeight: 0.2 * height,
  };
  let animationStyle: StyleProp<ViewStyle> = {
    width: "100%",
    position: "absolute",
    top: spacing16,
  };

  if (isWeb) {
    animationStyle = { ...animationStyle, height: 0.22 * height };
  } else {
    animationStyle = { ...animationStyle, minHeight: 0.22 * height };
  }

  return (
    <View style={containerStyle}>
      <AnimatedLottieView
        source={animation}
        loop={false}
        autoPlay
        style={animationStyle}
      />
    </View>
  );
};

export default BackgroundWithLottieAnimation;
