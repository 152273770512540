import {
  DataForBottomSection,
  PlayerMode,
  TextAndStyleForPlayerSection,
} from "./player.types";
import { palettes } from "@styles/colors";
import { CtxExerciseType } from "@screens/TrainingsAndExercises/exercise.types";
import { StatType } from "@screens/TrainingsAndExercises/training.types";

export const getDataForBottomSection = (
  mode: PlayerMode,
): DataForBottomSection | void => {
  switch (mode) {
    case PlayerMode.EXERCISE:
    case PlayerMode.BREAK_BETWEEN_SERIES:
      return {
        exerciseText: "T01244",
        serieText: "T01246",
      };
    case PlayerMode.NOT_STARTED:
    case PlayerMode.BREAK_BETWEEN_EXERCISES:
      return {
        exerciseText: "T01245",
      };
    default:
      return { exerciseText: undefined, serieText: undefined };
  }
};

export const formatTime = (seconds: number) => {
  const minutes = Math.floor(Math.abs(seconds) / 60);
  const remainingSeconds = Math.abs(seconds) % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");
  const sign = seconds < 0 ? "-" : "";
  return `${sign}${formattedMinutes}:${formattedSeconds}`;
};

const notStartedAndBreakBetweenExercisesPlayerStyle = {
  backgroundColor: palettes.primary[10],
  color: palettes.primary[100],
};

export const getTextAndStylesForPlayerSection = (
  mode: PlayerMode,
): TextAndStyleForPlayerSection => {
  switch (mode) {
    case PlayerMode.NOT_STARTED:
      return {
        text: "T01253",
        style: notStartedAndBreakBetweenExercisesPlayerStyle,
      };
    case PlayerMode.BREAK_BETWEEN_EXERCISES:
      return {
        text: "T00410",
        style: notStartedAndBreakBetweenExercisesPlayerStyle,
      };
    case PlayerMode.BREAK_BETWEEN_SERIES:
      return {
        text: "T00410",
        style: {
          backgroundColor: palettes.primary[100],
          color: palettes.primary[0],
        },
      };
    case PlayerMode.EXERCISE:
      return {
        text: "T01254",
        style: {
          backgroundColor: palettes.primary[100],
          color: palettes.primary[40],
        },
      };
  }
};

export const createInitialStatsFromExercises = (
  exercises: CtxExerciseType[],
): StatType[] => {
  let statsArray: StatType[] = [];

  exercises.forEach(exercise => {
    const exerciseStats = exercise.series.map(series => ({
      seriesId: series.id,
      value: 0,
      finishDate: new Date(),
      breakValue: 0,
      exerciseId: exercise.id,
    }));

    const alternativeExerciseStats = exercise?.alternativeExercise?.series.map(
      series => ({
        seriesId: series?.id,
        value: 0,
        finishDate: new Date(),
        breakValue: 0,
        exerciseId: exercise.alternativeExercise?.id,
        parentExerciseId: exercise.id,
      }),
    );

    if (alternativeExerciseStats?.length) {
      statsArray = [
        ...statsArray,
        ...exerciseStats,
        ...alternativeExerciseStats,
      ];
    } else {
      statsArray = [...statsArray, ...exerciseStats];
    }
  });
  return statsArray;
};

export const filterStats = (stats: StatType[]): StatType[] => {
  const mainExercises: Record<number, StatType[]> = {};
  const alternativeExercises: Record<number, StatType[]> = {};

  stats.forEach(stat => {
    const { exerciseId } = stat;
    if (stat?.parentExerciseId) {
      if (!alternativeExercises[stat.parentExerciseId]) {
        alternativeExercises[stat.parentExerciseId] = [];
      }
      alternativeExercises[stat.parentExerciseId].push(stat);
    } else {
      if (!mainExercises[exerciseId]) {
        mainExercises[exerciseId] = [];
      }
      mainExercises[exerciseId].push(stat);
    }
  });

  const filteredStats: StatType[] = [];

  Object.entries(mainExercises).forEach(([key, value]) => {
    const alternativeSeries =
      alternativeExercises && alternativeExercises[+key];
    const hasMainData = value?.some(serie => serie.value > 0);
    const hasAlternativeData =
      alternativeSeries?.length &&
      alternativeSeries.some(serie => serie.value > 0);

    if (hasMainData && hasAlternativeData) {
      filteredStats.push(...value, ...alternativeSeries);
    } else if (hasAlternativeData && !hasMainData) {
      filteredStats.push(...alternativeSeries);
    } else {
      filteredStats.push(...value);
    }
  });

  return filteredStats;
};
