import { VerificationTile } from "@components/Tile";
import { FetchError } from "@components/errors";
import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { getRehabVerificationProgress } from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren } from "react";
import { RefreshControl, ScrollView } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { queryKeysVerficiations } from "./queryKeysPhysiotherapistVerification";
import { ProfileCompletionInfo } from "../ProfileCompletionInfo";

const ProfileInformation: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      ProfileCompletionStackParamList,
      "ProfileInformation"
    >
  >
> = ({ navigation: { navigate } }) => {
  const { loading, scrollContainer, gapMedium } = globalStyles;
  const { data, isLoading, isError, refetch } = useQuery({
    queryFn: getRehabVerificationProgress,
    queryKey: queryKeysVerficiations.verificationProgress(),
  });

  if (isLoading) return <ActivityIndicator style={loading} />;

  if (isError) return <FetchError action={refetch} />;

  return (
    <ScrollView
      contentContainerStyle={[scrollContainer, gapMedium]}
      refreshControl={
        <RefreshControl refreshing={isLoading} onRefresh={refetch} />
      }>
      <ProfileCompletionInfo />
      <VerificationTile
        mode="outlined"
        title="T00259"
        content="T00262"
        status={data.aboutMeVerification || null}
        onPress={() => navigate("AboutMe")}
        type="profile completion"
      />
      <VerificationTile
        mode="outlined"
        title="T00528"
        content="T00529"
        status={data.profilePhotoVerification || null}
        onPress={() => navigate("AddPhoto")}
        type="profile completion"
      />
      <VerificationTile
        mode="outlined"
        title="T00265"
        content="T00276"
        status={data.experienceVerification || null}
        onPress={() => navigate("Experience")}
        type="profile completion"
      />
      <VerificationTile
        mode="outlined"
        title="T00269"
        content="T00270"
        status={data.educationVerification || null}
        onPress={() => navigate("Education")}
        type="profile completion"
      />
      <VerificationTile
        mode="outlined"
        title="T00274"
        content="T00278"
        status={data.certificatesVerification || null}
        onPress={() => navigate("Certificates")}
        type="profile completion"
      />
    </ScrollView>
  );
};

export default ProfileInformation;
