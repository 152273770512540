import { FC } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import { View } from "react-native";
import { Text } from "react-native-paper";

import { Input } from "@components/Input";

import { TextButton } from "@components/Button";
import SpecializationDropDownPicker from "@components/DropdownPicker/SpecializationDropdownPicker";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { useTranslation } from "react-i18next";
import { LicenceFormType } from "../VerifyLicence";
import { GetLicence, VerificationStatus } from "../verification.types";
import { TransKey } from "@globalTypes/i18next";

type Props = {
  control: Control<LicenceFormType>;
  initialData: GetLicence[];
  errors: {
    [x: string]: FieldError[];
  };
  disableButton: boolean;
  actionEnabled?: boolean;
  onPress: () => void;
};

export const LicenceNumberAndSpecialization: FC<Props> = ({
  control,
  initialData = [],
  errors,
  onPress,
  actionEnabled,
  disableButton,
}) => {
  const { t } = useTranslation();

  const isVerified =
    initialData?.[0]?.verificationStatus === VerificationStatus.VERIFIED;

  return (
    <View style={globalStyles.gapMedium}>
      {!isVerified && (
        <Text variant="bodyMedium" style={{ marginBottom: spacing8 }}>
          {t("T00550")}
        </Text>
      )}
      <Controller
        name="specialization"
        key="specialization"
        control={control}
        render={({ field, fieldState: { error } }) => {
          const hasSpecialization = !!initialData?.[0]?.specialization?.id;
          const { id, name } =
            (hasSpecialization && initialData[0].specialization) || {};
          return (
            <SpecializationDropDownPicker
              isVerified={isVerified}
              onValueChange={value => field.onChange(value)}
              defaultValue={hasSpecialization && `${id}`}
              placeholder={!hasSpecialization ? "T00919" : (name as TransKey)}
              errorMessage={
                errors?.specialization?.[0]?.message || (error && t("T00014"))
              }
              disabled={disableButton || actionEnabled}
            />
          );
        }}
      />
      <TextButton
        title="T00553"
        iconName="information-outline"
        onPress={onPress}
      />
      <Text variant="bodyMedium">{t("T01233")}</Text>
      <Input
        name="licenceNumber"
        key="licenceNumber"
        label="T01235"
        control={control as unknown as Control}
        errors={errors?.licenceNumber}
        isRequired
        editable={
          (!initialData?.length ||
            initialData?.[0].verificationStatus ===
              VerificationStatus.REJECTED) &&
          !actionEnabled
        }
        keyboardType="number-pad"
      />
    </View>
  );
};
