import SvgPaid from "@assets/svg/SvgPaid";
import StatusComponent from "@components/Status/StatusComponent";
import { TransactionTileProps } from "@screens/Profiles/Transactions/transaction.types";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { theme } from "@styles/theme";
import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, Card, Text } from "react-native-paper";
import { FetchError } from "@components/errors";
import { useTransactionParticipant } from "@hooks/payments/useTransactionParticipant";
import { useUserDetails } from "@hooks/index";

const TransactionTile: FC<TransactionTileProps> = ({
  paymentStatus,
  mode,
  elevation = 0,
  subtitle,
  finalPrice,
  currency,
  transactionType,
  participant,
  servicePrice,
  ...props
}) => {
  const { isPatient } = useUserDetails();
  const { gapSmall } = globalStyles;
  const {
    borderColor,
    itemWithSubtitlesContainer,
    bottomContent,
    colorfulAmount,
  } = styles;
  const { isLoading, isError, refetch, participantFullName } =
    useTransactionParticipant(participant);

  const isNegative = Math.sign(finalPrice) === -1;

  const displayingPrice =
    Math.abs(
      isPatient && transactionType !== "Income" ? servicePrice : finalPrice,
    ) / 100;

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <Card
      elevation={elevation as never}
      mode={mode}
      style={borderColor}
      {...props}>
      <Card.Content>
        <View style={itemWithSubtitlesContainer}>
          <SvgPaid width={40} height={40} />
          <View style={[gapSmall, { flex: 1 }]}>
            <Text variant="titleMedium">{participantFullName}</Text>
            <Text variant="bodySmall">{subtitle}</Text>
            <View style={bottomContent}>
              <StatusComponent status={paymentStatus} />
              <Text
                style={
                  transactionType === "Income" && !isNegative
                    ? colorfulAmount
                    : null
                }
                variant="bodyMedium">
                {transactionType !== "Income" || isNegative ? "- " : "+ "}
                {`${displayingPrice}`} {currency.toUpperCase()}
              </Text>
            </View>
          </View>
        </View>
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  borderColor: {
    borderColor: theme.colors.outlineVariant,
    borderWidth: 1,
    backgroundColor: palettes.primary[99],
  },
  itemWithSubtitlesContainer: {
    flexDirection: "row",
    alignItems: "center",
    ...globalStyles.gapLarge,
  },
  bottomContent: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colorfulAmount: { color: palettes.complete[30] },
});

export default TransactionTile;
