import {
  getProphylacticTrainingsForCategory,
  getTrainingCategory,
} from "@api/trainings";
import { AppbarTitleWithBackAction } from "@components/Appbar";
import { FetchError } from "@components/errors";
import { ScrollSectionWithChips } from "@components/Section/ScrollSectionWithChips";
import BaseTrainingTile from "@components/Tile/training/BaseTrainingTile";
import CategoryName from "@components/Training/CategoryName";
import { useMediaStyle } from "@hooks/ui/useMediaStyle";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  queryKeysGeneralWorkoutCategories,
  queryKeysWorkoutCategories,
} from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { Image } from "expo-image";
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, ScrollView, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";

const GeneralWorkoutCategory: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "GeneralWorkoutCategory">
  >
> = ({
  route: {
    params: { id },
  },
  navigation: { setOptions, goBack, navigate },
}) => {
  const [selectedFilter, setSelectedFilter] = useState<string>(null);
  const { width } = useMediaStyle();
  const { t } = useTranslation();

  const { gapLarge, scrollContainer, loading } = globalStyles;

  const {
    data: trainingsInCategory,
    isLoading: isTrainingsLoading,
    isError: isTrainingsError,
    refetch: refetchTrainingsInCategory,
  } = useQuery({
    queryKey: queryKeysGeneralWorkoutCategories.detail(id, selectedFilter),
    queryFn: async () => {
      let params: { workoutCategoryId: number; sortBy?: string } = {
        workoutCategoryId: id,
      };
      if (selectedFilter !== null)
        params = {
          ...params,
          sortBy: selectedFilter,
        };

      return await getProphylacticTrainingsForCategory(params);
    },
  });

  const {
    data: trainingData,
    isLoading: isTrainingDataLoading,
    isError: isTrainingDataError,
    refetch: refetchTrainingData,
  } = useQuery({
    queryKey: queryKeysWorkoutCategories.detail(id),
    queryFn: async () => await getTrainingCategory(id),
  });

  useEffect(
    () =>
      setOptions({
        header: () => (
          <AppbarTitleWithBackAction title={t("T01529")} onClose={goBack} />
        ),
      }),
    [setOptions, goBack, trainingData, t],
  );

  const isAnyError = useMemo(
    () => isTrainingsError || isTrainingDataError,
    [isTrainingsError, isTrainingDataError],
  );

  const refetchEverything = async () =>
    await Promise.all([refetchTrainingsInCategory(), refetchTrainingData()]);

  if (isTrainingDataLoading) return <ActivityIndicator style={loading} />;
  if (isAnyError) return <FetchError action={refetchEverything} />;

  const chipsList = [
    { title: t("T00815"), value: "duration_desc" },
    { title: t("T00814"), value: "duration_asc" },
    { title: t("T00816"), value: "exercises_amount_asc" },
    { title: t("T00817"), value: "exercises_amount_desc" },
  ];

  return (
    <ScrollView
      contentContainerStyle={scrollContainer}
      refreshControl={
        <RefreshControl
          refreshing={isTrainingDataLoading}
          onRefresh={refetchEverything}
        />
      }>
      <View style={gapLarge}>
        <CategoryName name={trainingData?.name} />
        <Image
          source={{ uri: trainingData.image }}
          style={{ width, height: width / 2 }}
        />
        <ScrollSectionWithChips
          data={chipsList}
          setSelectedFilter={setSelectedFilter}
          selectedFilter={selectedFilter}
        />
        {isTrainingsLoading ? (
          <ActivityIndicator />
        ) : (
          trainingsInCategory.map((e, index) => (
            <BaseTrainingTile
              key={`training-${id}-in-category-${index}`}
              data={e}
              isFetched
              isError={false}
              refetch={() => {}}
              onPress={() =>
                navigate("GeneralWorkoutDetails", {
                  trainingId: e.id,
                })
              }
            />
          ))
        )}
      </View>
    </ScrollView>
  );
};

export default GeneralWorkoutCategory;
