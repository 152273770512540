import {
  RootStackParamList,
  TrainingSelectionAssistantStackParamsList,
} from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ScrollView, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { AbsoluteBlurredFooter } from "@components/Footers";
import { palettes } from "@styles/colors";
import { TrainingSelectionAssistantContext } from "./trainingSelectionAssistant.context";
import { useTranslation } from "react-i18next";
import { Tile } from "@components/Tile";
import { useQuery } from "@tanstack/react-query";
import { getTrainingsByIntelligentTrainingAssistant } from "@services/ApiService/trainings";
import { inteliigentAssistantQueryKeys } from "./inteliigentAssistantQueryKeys";
import { AxiosError } from "axios";
import { useErrors } from "@hooks/useErrors";
import { FetchError } from "@components/errors";
import { GeneralTrainingTile } from "@components/Tile/training/GeneralTrainingTile";
import {
  NavigationProp,
  useIsFocused,
  useNavigation,
} from "@react-navigation/native";
import { useGetShowWhatCanYouExpectStatus } from "@hooks/useGetShowWhatCanYouExpectStatus";

export const TrainingAssistantSummary: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      TrainingSelectionAssistantStackParamsList,
      "TrainingAssistantSummary"
    >
  >
> = ({ navigation: { replace, popToTop, goBack } }) => {
  const [footerHeight, setFooterHeight] = useState(0);

  const isFocused = useIsFocused();
  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();

  const showWhatCanYouExpect = useGetShowWhatCanYouExpectStatus(isFocused);

  const {
    data: { bodyParts, time, hasInjuriesOrMedicalConditions },
    clearData,
  } = useContext(TrainingSelectionAssistantContext);

  const { data, isLoading, isError, refetch } = useQuery({
    queryFn: async () =>
      await getTrainingsByIntelligentTrainingAssistant({
        bodyParts,
        time,
      }),
    queryKey: inteliigentAssistantQueryKeys.list(bodyParts, time),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    enabled: !hasInjuriesOrMedicalConditions,
  });

  useEffect(() => {
    return () => clearData();
  }, [clearData]);

  const { gapLarge, gapMedium, scrollContainer, gapSmall } = globalStyles;

  const renderNoResults = useCallback(
    () => <Text variant="bodyMedium">{t("T01017")}</Text>,
    [t],
  );

  const renderSuggestedTrainings = useCallback(
    () => (
      <View style={gapLarge}>
        <Text variant="bodyMedium">{t("T01022")}</Text>
        <View style={gapMedium}>
          {data.map(({ id }, i) => (
            <GeneralTrainingTile
              key={`prophylactic-training-${id}-${i}`}
              id={id}
              onPress={id =>
                navigate("GeneralWorkoutDetails", {
                  trainingId: id,
                })
              }
            />
          ))}
        </View>
      </View>
    ),
    [navigate, t, data, gapLarge, gapMedium],
  );

  if (isLoading && !hasInjuriesOrMedicalConditions)
    return <ActivityIndicator style={globalStyles.loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <>
      <ScrollView
        contentContainerStyle={[
          scrollContainer,
          gapLarge,
          { paddingBottom: footerHeight },
        ]}>
        <View style={gapSmall}>
          <Text variant="titleMedium">{t("T01016")}</Text>
          {hasInjuriesOrMedicalConditions || !data.length
            ? renderNoResults()
            : renderSuggestedTrainings()}
        </View>
        <View style={gapLarge}>
          <View style={gapSmall}>
            <Text variant="titleMedium">{t("T01018")}</Text>
            <Text variant="bodyMedium">{t("T01019")}</Text>
          </View>
          <Tile
            tileType="medic"
            mode="outlined"
            onPress={() =>
              replace(
                // todo: need to check @mm
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                showWhatCanYouExpect ? "WhatCanYouExpect" : "Physiotherapists",
              )
            }
          />
        </View>
      </ScrollView>
      <AbsoluteBlurredFooter
        intensity={1}
        onPress={() => {
          popToTop();
          goBack();
        }}
        title="T01020"
        onLayout={height => setFooterHeight(height)}
        buttonStyle={{ backgroundColor: palettes.primary[100] }}
      />
    </>
  );
};
