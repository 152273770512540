import { CheckboxListItem } from "@components/ListItems/CheckboxListItem";
import { FetchError } from "@components/errors";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { ExercisesFiltersParamsType } from "@screens/TrainingsAndExercises/Physiotherapist/SearchExercises/SearchExercises";
import { Condition } from "@screens/TrainingsAndExercises/exercise.types";
import { queryKeysConditions } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { getConditionsList } from "@services/ApiService/exercises";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FlatList, StyleProp, ViewStyle } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";

export type ConditionsModalProps = {
  searchQuery?: string;
  setSelectedItems?: Dispatch<SetStateAction<Condition[]>>;
  setSelectedItemsForQuery?: Dispatch<
    SetStateAction<ExercisesFiltersParamsType>
  >;
  selectedItems: Condition[];
  style?: StyleProp<ViewStyle>;
  extraItemStyle?: StyleProp<ViewStyle>;
  setVisibleAfterSelect?: Dispatch<SetStateAction<boolean>>;
  filtersMode?: boolean;
};

export const ConditionsModal: FC<ConditionsModalProps> = ({
  searchQuery,
  setSelectedItems,
  setSelectedItemsForQuery,
  selectedItems,
  style,
  extraItemStyle,
  setVisibleAfterSelect,
  filtersMode = false,
}) => {
  const [itemsList, setItemsList] = useState<Condition[]>([]);
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, gapMedium, scrollContainer, flatListSeparator } =
    globalStyles;

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysConditions.list(),
    queryFn: getConditionsList,
  });

  useEffect(() => {
    if (searchQuery?.length > 0) {
      setItemsList(() =>
        data.filter(e =>
          e.name?.toLocaleLowerCase().includes(searchQuery.toLowerCase()),
        ),
      );
    } else {
      setItemsList(data);
    }
  }, [data, searchQuery]);

  const onPressItem = useCallback(
    (item: Condition) => {
      if (filtersMode) {
        setSelectedItemsForQuery(prevState => {
          if (!prevState?.conditions.length)
            return { ...prevState, conditions: [item] };
          const itemExists = prevState?.conditions?.some(e => e.id === item.id);
          return {
            ...prevState,
            conditions: itemExists
              ? prevState.conditions.filter(e => e.id !== item.id)
              : [item],
          };
        });
        setVisibleAfterSelect(false);
      } else
        setSelectedItems(prevState => {
          if (!prevState) return [item];
          return prevState.some(e => e.id === item.id)
            ? prevState.filter(e => e.id !== item.id)
            : [...prevState, item];
        });
    },
    [
      filtersMode,
      setSelectedItems,
      setSelectedItemsForQuery,
      setVisibleAfterSelect,
    ],
  );
  const renderItem = useCallback(
    ({ item }: { item: Condition }) => {
      return (
        <CheckboxListItem
          title={item.name}
          checkboxProps={{
            status: selectedItems?.some(
              selectedItem => selectedItem.id === item.id,
            )
              ? "checked"
              : "unchecked",
          }}
          onPress={() => onPressItem(item)}
          style={extraItemStyle}
        />
      );
    },
    [extraItemStyle, onPressItem, selectedItems],
  );

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;
  return (
    <FlatList
      data={searchQuery ? itemsList : data}
      keyExtractor={({ id }, index) => `condition-${id}-${index}`}
      contentContainerStyle={[
        gapMedium,
        scrollContainer,
        { paddingTop: spacing8 },
        style,
      ]}
      renderItem={renderItem}
      ListEmptyComponent={<Text variant="bodyMedium">{t("T00636")}</Text>}
      ItemSeparatorComponent={
        !filtersMode
          ? () => (
              <Divider
                style={[flatListSeparator, { width, marginTop: spacing8 }]}
              />
            )
          : null
      }
    />
  );
};
