import { FC, useCallback } from "react";
import { ListRenderItem, FlatList, StyleSheet } from "react-native";
import {
  AvailableAppointmentsColumns,
  DayColumn,
} from "./PhysioAvailability.types";
import SingleColumn from "./SingleColumn";
import { spacing32 } from "@styles/spacing";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";

type ThreeDaySectionColumnsListProps = {
  isStationaryService: boolean;
  onHourPress: (dateFrom: string) => void;
  data: AvailableAppointmentsColumns;
};

const ThreeDaySectionColumnsList: FC<ThreeDaySectionColumnsListProps> = ({
  data,
  isStationaryService,
  onHourPress,
}) => {
  const { width } = useWindowDimensions();
  const renderItemContentWidth = width - spacing32;
  const renderColumn: ListRenderItem<DayColumn> = useCallback(
    ({ item, index }) => {
      const isFirst = index === 0;
      const isLast = index === data?.length - 1;
      const key = `column-${index}-${item?.date.toString()}`;

      return (
        <SingleColumn
          key={key}
          columnData={item?.hours}
          isStationaryService={isStationaryService}
          onHourPress={onHourPress}
          isFirst={isFirst}
          isLast={isLast}
        />
      );
    },
    [data?.length, isStationaryService, onHourPress],
  );

  return (
    <FlatList
      data={data}
      renderItem={renderColumn}
      scrollEnabled={false}
      horizontal
      style={[styles.listStyle, { width: renderItemContentWidth }]}
    />
  );
};

export default ThreeDaySectionColumnsList;

const styles = StyleSheet.create({
  listStyle: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
