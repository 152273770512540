import {
  Children,
  cloneElement,
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { Slider } from "@miblanchard/react-native-slider";
import { StyleSheet, View } from "react-native";
import { palettes } from "@styles/colors";
import { spacing2, spacing8 } from "@styles/spacing";

type ContainerPropsType = {
  children: ReactElement;
  currentValue: number;
  trackMarks?: number[];
  vertical?: boolean;
};

type SliderComponentPropsType = {
  setCurrentValue?: Dispatch<SetStateAction<number>>;
};

const SliderContainer = ({
  currentValue,
  trackMarks,
  children,
}: ContainerPropsType) => {
  const renderTrackMarkComponent = (index: number) => {
    return (
      <View
        style={[
          styles.dotStyle,
          {
            backgroundColor:
              index <= currentValue
                ? palettes.primary[100]
                : palettes.neutralVariant[30],
            opacity: index <= currentValue ? 0.5 : 1,
          },
        ]}
      />
    );
  };

  return Children.map(children, (child: ReactElement) => {
    if (child?.type === Slider) {
      return cloneElement(child, {
        renderTrackMarkComponent,
        trackMarks,
        value: currentValue,
      });
    }

    return child;
  });
};

export const SliderComponent = ({
  setCurrentValue: propsSetCurrentValue,
}: SliderComponentPropsType) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [currentTintColor, setCurrentTintColor] = useState(
    palettes.primary[40],
  );

  const getRange = (value: number) => {
    if (value >= 0 && value <= 3) return "low";
    if (value >= 4 && value <= 6) return "medium";
    if (value >= 7 && value <= 10) return "high";
    return "outOfRange";
  };

  useEffect(() => {
    switch (getRange(currentValue)) {
      case "low":
        setCurrentTintColor(palettes.primary[40]);
        break;
      case "medium":
        setCurrentTintColor(palettes.warning[91]);
        break;
      case "high":
        setCurrentTintColor(palettes.error[40]);
        break;
      default:
        break;
    }
  }, [currentValue]);

  return (
    <SliderContainer
      currentValue={currentValue}
      trackMarks={Array.from({ length: 11 }, (_, i) => i)}>
      <Slider
        maximumValue={10}
        minimumValue={0}
        step={1}
        trackStyle={{ height: 4 }}
        thumbTintColor={currentTintColor}
        onValueChange={value => {
          setCurrentValue(value[0]);
          propsSetCurrentValue && propsSetCurrentValue(value[0]);
        }}
        minimumTrackTintColor={currentTintColor}
        maximumTrackTintColor={palettes.neutralVariant[90]}
        thumbStyle={styles.thumbStyle}
      />
    </SliderContainer>
  );
};

const styles = StyleSheet.create({
  thumbStyle: {
    shadowColor: palettes.primary[0],
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  dotStyle: {
    width: spacing2,
    height: spacing2,
    borderRadius: spacing2,
    left: spacing8,
  },
});
