import TransactionTile from "@components/Tile/TransactionTile";
import { useUserDetails } from "@hooks/index";
import { TransactionHistoryStackParamList } from "@navigators//navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { FetchError } from "@components/errors";
import { getTransactionsHistory } from "@services/ApiService/transactionHistory";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { formatDateWithDayName } from "@utils/date";
import { FC, PropsWithChildren, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, SectionList } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { queryKeysTransactionsHistory } from "../Transactions/queryKeysTransactionsHistory";
import {
  Transaction,
  TransactionType,
} from "../Transactions/transaction.types";
import { useAuth } from "@contexts/AuthContext/auth";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { REFUND_PATIENT_INFO, REFUND_THERAPIST_INFO } from "@utils/constants";
import { BottomTutorialComponent } from "@components/BottomSheet";
import InfoTile from "@components/Tile/InfoTile";
import { ScrollSectionWithChips } from "@components/Section";

const TransactionHistory: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      TransactionHistoryStackParamList,
      "TransactionHistory"
    >
  >
> = ({ navigation: { navigate } }) => {
  const [refundsModalVisible, setRefundsModalVisible] = useState(false);
  const [transactionModalVisible, setTransactionModalVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string>("30");

  const { t } = useTranslation();
  const { scrollContainer, gapMedium } = globalStyles;
  const { isPhysiotherapist } = useUserDetails();
  const {
    user: { language },
  } = useAuth();

  const { data, isLoading, isError, refetch } = useQuery({
    queryFn: async () =>
      await getTransactionsHistory({ dateFrom: selectedFilter }),
    queryKey: queryKeysTransactionsHistory.list(selectedFilter),
  });

  const renderSectionHeader = ({
    section: { sectionTitle },
  }: {
    section: {
      sectionTitle: string;
    };
  }) => {
    const date = formatDateWithDayName(language, sectionTitle);
    return <Text variant="titleMedium">{date}</Text>;
  };

  const renderItem = useCallback(
    ({
      item: { name, ...rest },
      section: { sectionTitle },
    }: {
      item: Transaction;
      section: {
        sectionTitle: string;
      };
    }) => {
      const isNegative =
        Math.sign(rest.finalPrice) === (isPhysiotherapist ? -1 : 1);
      let serviceName = name.split(": ")[1];

      if (isNegative) {
        serviceName = `${t("T01210")} ${serviceName.substring(
          0,
          serviceName.length - 1,
        )}`;
      }

      const transactionType = isPhysiotherapist
        ? TransactionType.INCOME
        : isNegative
        ? TransactionType.INCOME
        : TransactionType.EXPENSE;

      return (
        <TransactionTile
          subtitle={serviceName}
          onPress={() =>
            navigate("TransactionDetails", {
              data: {
                serviceName,
                date: sectionTitle,
                transactionType,
                ...rest,
              },
            })
          }
          transactionType={transactionType}
          {...rest}
        />
      );
    },
    [isPhysiotherapist, navigate, t],
  );

  const sections = data
    ? Object.keys(data).map(date => ({
        sectionTitle: date,
        data: data[date],
      }))
    : [];

  const chipsList = [
    { title: t("T01284"), value: "30" },
    { title: t("T01285"), value: "180" },
    { title: t("T01286"), value: "365" },
    { title: t("T01287"), value: null },
  ];

  return (
    <>
      <SectionList
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={refetch} />
        }
        sections={sections}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        ListEmptyComponent={() => {
          if (isLoading) return <ActivityIndicator />;
          if (isError) return <FetchError action={refetch} />;

          return (
            <InfoTile
              status="info"
              content={<Text variant="bodyMedium">{t("T01283")}</Text>}
            />
          );
        }}
        ListHeaderComponent={
          <>
            <TutorialTextButton
              label="T01267"
              onPress={() => setRefundsModalVisible(true)}
            />
            <TutorialTextButton
              label="T01278"
              onPress={() => setTransactionModalVisible(true)}
            />
            <ScrollSectionWithChips
              data={chipsList}
              setSelectedFilter={setSelectedFilter}
              selectedFilter={selectedFilter}
              selectedChipMode="flat"
            />
          </>
        }
        contentContainerStyle={[scrollContainer, gapMedium]}
        keyExtractor={({ name, participant }, i) =>
          `${name}-${participant}-${i}`
        }
        stickySectionHeadersEnabled={false}
      />
      <BottomModalContainer
        modalVisible={refundsModalVisible}
        setModalVisible={setRefundsModalVisible}>
        <BottomTutorialComponent
          title="T01267"
          content={[
            {
              text: isPhysiotherapist
                ? REFUND_THERAPIST_INFO
                : REFUND_PATIENT_INFO,
            },
          ]}
          onPress={() => setRefundsModalVisible(false)}
        />
      </BottomModalContainer>
      <BottomModalContainer
        modalVisible={transactionModalVisible}
        setModalVisible={setTransactionModalVisible}>
        <BottomTutorialComponent
          title="T01267"
          content={[
            {
              text: isPhysiotherapist
                ? ["T01279", "T01281", "T01282"]
                : ["T01279", "T01280"],
            },
          ]}
          onPress={() => setTransactionModalVisible(false)}
        />
      </BottomModalContainer>
    </>
  );
};

export default TransactionHistory;
