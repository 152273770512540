import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Text } from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { HelpCenterStackParamList } from "@navigators/navigation.types";
import { useTranslation } from "react-i18next";
import { Input } from "../../../../../components/Input";
import { Control, Controller, useForm } from "react-hook-form";
import { PrimaryButton } from "../../../../../components/buttons";
import { globalStyles } from "@styles/global";
import { yupResolver } from "@hookform/resolvers/yup";
import { boolean, object, string } from "yup";
import { MULTILINE_INPUT_MAX_LENGTH } from "@utils/constants";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { spacing16, spacing32 } from "@styles/spacing";
import {
  HelpCenterIssue,
  HelpCenterSubmission,
} from "@screens/Profiles/MyProfile/CommonProfile/HelpCenter/helpCenter.types";
import { useErrors } from "@hooks/useErrors";
import { createSubmission } from "@api/helpCenter";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { View } from "react-native";
import CheckboxSimpleText from "@components/Checkboxes/CheckboxSimpleText";
import PrivacyAndTermsAgreement from "@components/Checkboxes/PrivacyAndTermsAgreement";
import FormCategoriesDropdownPicker from "@components/DropdownPicker/FormCategoriesDropdownPicker";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { AppbarTitleWithBackAction } from "@components/Appbar";

type ContactFormProps = PropsWithChildren<
  NativeStackScreenProps<HelpCenterStackParamList, "ContactForm">
>;

const ContactForm: FC<ContactFormProps> = ({
  navigation: { navigate, goBack, setOptions },
  route,
}) => {
  const defaultIssue = route.params?.defaultIssue;
  const isDefaultIssueAccountRemoval =
    defaultIssue === HelpCenterIssue.AccountRemoval;
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const { setErrorsFromResponse, errors } = useErrors();
  const { email } = useUserDetails();
  const checkboxRules = boolean()
    .oneOf([true], t("T00014"))
    .required(t("T00014"));
  const schema = useMemo(
    () =>
      object().shape({
        email: string().email(t("T00006")).required(t("T00005")),
        content: string().max(MULTILINE_INPUT_MAX_LENGTH).required(t("T00014")),
        privacyCheckbox: checkboxRules,
        mailingCheckbox: checkboxRules,
        submissionCategory: string().required(t("T00014")),
      }),
    [checkboxRules, t],
  );

  const { mutate, isLoading } = useMutation({
    mutationFn: (params: HelpCenterSubmission) => createSubmission(params),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: () => setModalVisible(true),
  });

  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email,
      content: "",
      submissionCategory: defaultIssue || "",
    },
  });
  const onSubmit = (data: HelpCenterSubmission) => mutate(data);

  const { container, gapMedium, gapLarge } = globalStyles;

  useEffect(() => {
    if (isDefaultIssueAccountRemoval) {
      setOptions({
        header: ({ navigation: { goBack } }) => (
          <AppbarTitleWithBackAction onClose={goBack} title={t("T00089")} />
        ),
      });
    }
  }, [defaultIssue, isDefaultIssueAccountRemoval, setOptions, t]);

  return (
    <KeyboardAwareScrollView
      style={container}
      contentContainerStyle={gapMedium}>
      <Text variant="titleMedium">{t("T00390")}</Text>
      <Text variant="bodyMedium">{t("T00392")}</Text>
      <Input
        name="email"
        label="T00004"
        control={control as unknown as Control}
        keyboardType="email-address"
        errors={errors?.email}
        isRequired
      />
      <Controller
        name="submissionCategory"
        control={control}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <FormCategoriesDropdownPicker
            onSelectItem={({ value }) => onChange(value)}
            errorMessage={
              errors?.submissionCategory?.[0]?.message || (error && t("T00014"))
            }
            defaultValue={defaultIssue}
          />
        )}
      />
      <Input
        name="content"
        label="T00391"
        control={control as unknown as Control}
        multiline
        maxLength={MULTILINE_INPUT_MAX_LENGTH}
        errors={errors?.content}
        isRequired
      />
      <View style={[gapMedium, { marginBottom: spacing16 }]}>
        <PrivacyAndTermsAgreement
          name="privacyCheckbox"
          control={control as unknown as Control}
        />
        <CheckboxSimpleText
          name="mailingCheckbox"
          text="T00738"
          control={control as unknown as Control}
        />
      </View>
      <PrimaryButton
        label="T00393"
        onPress={handleSubmit(onSubmit)}
        style={{ marginBottom: spacing32 }}
        loading={isLoading}
        disabled={isLoading}
      />
      <BottomModalContainer
        disableBackdrop
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}>
        <View style={gapLarge}>
          <Text variant="bodyLarge">{t("T00395")}</Text>
          <Text variant="bodyMedium">{`${t("T00396")} ${watch("email")}`}</Text>
          <PrimaryButton
            label="T00394"
            onPress={() =>
              isDefaultIssueAccountRemoval ? goBack() : navigate("HelpCenter")
            }
          />
        </View>
      </BottomModalContainer>
    </KeyboardAwareScrollView>
  );
};

export default ContactForm;
