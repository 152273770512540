import Svg, { ClipPath, Defs, G, Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#E2A379"
        d="M23.498 7.062c-.058-.26-1.862.381-1.862.381-2.49.365-3.622.818-3.622.818-1.5.153-4.208.799-4.74 1.01-.533.212-.218 1.302.354 1.225.572-.077 1.066-.212 1.624-.33.558-.119 2.472-.86 2.95-1.013l3.557-1.118c.132-.018.266-.018.398 0 .352.044 1.4-.714 1.34-.973Z"
      />
      <Path
        fill="#3D3C3C"
        d="M8.45 10.93c-.156-.08-.6-.583-1.34-.763-.444-.11-1.008-.153-1.516-.167-.722-.02-.756 2.848-.264 3.06.794.34 2.388.436 2.879.44.334 0 .791-1.49.791-1.49s-.396-1.002-.55-1.08Z"
      />
      <Path
        fill="#3D3C3C"
        d="M2.138 8.143S.644 7.67.505 8.445c-.145.807 3.048 3.326 3.74 3.847.692.52.988.692 2.608 1.208.239-.396.122-.534.092-1.853-.037-1.628-4.807-3.504-4.807-3.504Z"
      />
      <Path
        fill="#E2A379"
        d="M6.457.54c.227.204-.52 1.254-.543 1.483-.011.114.081.281-.04.51-.158.297-.493.332-.543.437-.261.555-.918 1.698-1.242 2.364-.075.154-.156.31-.243.468-.354.64-1.484-.455-1.332-.673.606-.875 2.104-2.512 2.36-2.803 0 0 .612-1.003.78-1.226.103-.134.573-.764.803-.56Z"
      />
      <Path
        fill="#3D3C3C"
        d="M2.718 8.08C2.997 7.786 3.674 6.67 4 6.1c-.24-.036-1.074-.455-1.225-.6-.14.195-2.399 2.5-2.27 3.198.095.505.868.29 1.498-.018.255-.182.494-.383.715-.6Z"
      />
      <Path
        fill="#F4B382"
        d="M7.935 23.99c-.26-.062-1.24 0-1.338-.007-.235-.01-.463-.138-.417-.363.035-.17.116-.324.106-.43-.052-.548-.208-2.186-.26-2.85A9.013 9.013 0 0 1 6 19.869c-.02-.655 1.303-.283 1.288-.046-.065.949-.306 3.19-.368 3.529 0 0 1.606.079 1.58.326-.018.151-.305.374-.565.313Z"
      />
      <Path
        fill="#3D3C3C"
        d="M6.018 17.25c-.095.797-.207 1.558-.187 2.25.216-.086 1.114-.112 1.313-.05.017-.249.32-2.176.32-2.176.073-.61.663-4.2.511-5.315a5.168 5.168 0 0 0-.133-.753c-.103-.407-2.26-.175-2.342.387.075 1.226.252 3.098.352 4.002.07.628.166 1.654.166 1.654Z"
      />
      <Path
        fill="#4666FF"
        d="M13.862 10.976c.293-.792.062-1.338-.158-2.411-.044-.124-.489-.505-.763-.547-.304-.046-.636-.014-.853.223-.204.173-.506.582-1.2 2.064-.193.414-1.633.866-2.357.684 0 0-.531.59-.531 1.39 0 .593.303 1.121.303 1.121 3.076-.048 4.214-.625 4.951-1.956.003.003.466-.182.608-.568Z"
      />
      <Path
        fill="#E2A379"
        d="M13.546 8.546c.096-.364.25-.71.454-1.024L13.273 7c-.125.548-.773.975-.773.975.487 1.298.95 1.013.999.967l.047-.396Z"
      />
      <Path
        fill="#F4B382"
        d="M14.83 6.144c.256-.393.063-.766-.076-.976-.434-.652-.868-.694-1.423-.66-.733.047-.895 2.048-.81 2.377.216.86 1.407 1.127 1.886 1.115.527-.018.384-.196.355-.41 0-.009.024-.042.024-.042a.609.609 0 0 1 .028-.277.741.741 0 0 1-.026-.102c0-.024 0-.038.024-.05a.559.559 0 0 0 .174-.097c.089-.112-.274-.695-.156-.878Z"
      />
      <Path
        fill="#D6876B"
        d="M14.5 7.429s.078.106.426.059c.043-.059.114-.196.045-.44-.048-.196-.222.26-.471.381Z"
      />
      <Path
        fill="#602F0F"
        d="M13.38 4.507c-.95.054-2.135 1.425-.741 2.88.093.096.3.206.581-.02.128-.106.239-.229.33-.364l.121-.202c.047-.08-.043-.52.053-.54.923-.192 1.29-.683 1.275-.92-.148-.56-.604-.893-1.619-.834Z"
      />
      <Path
        fill="#F4B382"
        d="M13.55 6.565c.126.451.26.46.45.42l-.137-.706-.11-.279c-.189.041-.333.114-.204.565Z"
      />
      <Path
        fill="#4C230C"
        d="M12.14 5.95c-.24-.17-.165-.618.175-1 .34-.382.811-.555 1.055-.385.243.17.13.511-.21.894-.34.383-.777.659-1.02.49Z"
      />
      <Path
        fill="#602F0F"
        d="M13.977 6.33a.621.621 0 0 1-.03-.33l-.447.114c0 .04.008.08.011.12l.127-.03h-.011c.1.088.183.184.246.284 0 0 .192.076.104-.158ZM12.133 5.952c-.237-.164-.148-.61.193-.993.34-.383.813-.562 1.05-.398.238.164.118.504-.226.888s-.78.668-1.017.503Z"
      />
      <Path
        fill="#4C230C"
        d="M12.008 4.998c-.034-.022.048-.15.182-.286.133-.136.27-.23.302-.209.033.021-.041.124-.175.26-.134.137-.275.256-.31.235Z"
      />
      <Path
        fill="#F4B382"
        d="M4.022 2.234c-.17.205.7.902.889 1.198l.398.145C6.917 5.46 7.834 6.15 7.834 6.15c.915 1.175 2.802 3.006 3.25 3.328.27.2 1.145-1.026.86-1.324-.48-.41-.691-.457-1.119-.825-.39-.399-2.02-1.567-2.396-1.875l-2.724-2.34a1.478 1.478 0 0 1-.209-.338c-.153-.32-.512-.65-.777-.746a.638.638 0 0 0-.374-.004.692.692 0 0 0-.323.208ZM4.359 3.18s-.185-.266-.303-.151c-.1.096-.125.143.437.471.001.002.05-.088-.134-.32Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgComponent;
