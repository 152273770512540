import { DETAILS, LIST } from "@utils/constants";

const keys = {
  topics: "topics",
  posts: "posts",
};

export const queryKeysForumTopics = {
  all: [keys.topics] as const,
  list: () => [...queryKeysForumTopics.all, LIST] as const,
  details: (id: number) => [...queryKeysForumTopics.all, DETAILS, id] as const,
};

export const queryKeysForumPosts = {
  all: [keys.posts] as const,
  list: () => [...queryKeysForumPosts.all, LIST] as const,
  details: (id: number) => [...queryKeysForumPosts.all, DETAILS, id] as const,
};
