import { useCallback, useEffect, useState } from "react";
import { Keyboard, KeyboardEvent } from "react-native";

export const useKeyboard = (willInsteadDid?: boolean) => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  const onKeyboardDidShow = useCallback((e: KeyboardEvent) => {
    setKeyboardHeight(e.endCoordinates.height);
  }, []);

  const onKeyboardDidHide = useCallback(() => setKeyboardHeight(0), []);

  useEffect(() => {
    const showSubscription = Keyboard.addListener(
      willInsteadDid ? "keyboardWillShow" : "keyboardDidShow",
      onKeyboardDidShow,
    );
    const hideSubscription = Keyboard.addListener(
      willInsteadDid ? "keyboardWillHide" : "keyboardDidHide",
      onKeyboardDidHide,
    );
    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, [onKeyboardDidHide, onKeyboardDidShow, willInsteadDid]);

  return keyboardHeight;
};
