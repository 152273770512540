import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { RefreshControl, ScrollView, View } from "react-native";
import { Divider } from "react-native-paper";

import { Tile } from "@components/Tile";
import { RootStackParamList } from "@navigators/navigation.types";
import { queryKeysTrainingDay } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { getAppointmentsList } from "@services/ApiService/appointments";
import { getTrainingClosestDay } from "@services/ApiService/trainings";
import { globalStyles } from "@styles/global";
import { formatDateForApi } from "@utils/date";
import { Appointment } from "../Appointments/appointment.types";
import {
  queryKeysAppointments,
  queryKeysPhysiotherapist,
} from "../Appointments/queryKeysAppointments";
import {
  PatientLatestRehabilitants,
  PatientUpcomingAppointment,
  PatientUpcomingTraining,
} from "./Patient";
import { useUpdateUser } from "@hooks/user/useUpdateUser";
import { useIsFocused } from "@react-navigation/native";
import { useGetShowWhatCanYouExpectStatus } from "@hooks/useGetShowWhatCanYouExpectStatus";
import { useWelcomeToProductModal, useAnalytics } from "@hooks/index";
import { AnalyticsEventName } from "@globalTypes/analytics.types";
import { getMyLatestPhysiotherapist } from "@services/ApiService/users";
import { useTranslation } from "react-i18next";

const todayDate = new Date();
const todayDateISO = formatDateForApi(todayDate, "iso");

export const HomeScreenPatient: FC<
  PropsWithChildren<NativeStackScreenProps<RootStackParamList, "Home">>
> = ({ navigation: { navigate } }) => {
  const [upcomingAppointment, setUpcomingAppointment] = useState<Appointment>();
  const [emptyUpcomingDays, setEmptyUpcomingDays] = useState<boolean>(false);
  const isFocused = useIsFocused();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { container, gapLarge } = globalStyles;

  const showWhatCanYouExpect = useGetShowWhatCanYouExpectStatus(isFocused);
  const { handleLogEvent } = useAnalytics();

  useEffect(() => {
    void handleLogEvent({
      eventName: AnalyticsEventName.VISIT_HOME_SCREEN_AS_PATIENT,
    });
  }, []);

  const {
    data: appointments,
    isLoading: getAppointmentsListLoading,
    isError: getAppointmentsListError,
    refetch: getAppointmentsRefetch,
    isSuccess: getAppointmentsSuccess,
  } = useQuery({
    queryKey: queryKeysAppointments.listWithDateFilter(todayDateISO),
    queryFn: async () => await getAppointmentsList(formatDateForApi(todayDate)),
  });

  const filterNearestAppointment = useMemo(
    () =>
      appointments?.find(
        ({ cancelled, dateTo }) => !cancelled && todayDateISO < dateTo,
      ),
    [appointments],
  );

  useWelcomeToProductModal({ openModal: () => navigate("WelcomeToProduct") });

  const {
    data: trainingDays,
    isLoading: isGetTrainingDaysLoading,
    isError: isGetTrainingDaysError,
    refetch: getTrainingDaysRefetch,
    isSuccess,
  } = useQuery({
    queryKey: queryKeysTrainingDay.closestDays(),
    queryFn: async () => await getTrainingClosestDay(),
    onError: ({ response }: AxiosError) => {
      if (response.status === 404) setEmptyUpcomingDays(true);
    },
    retry: false,
  });

  const {
    data: latestTherapists,
    isLoading: isLatestTherapistsLoading,
    isError: isLatestTherapistsError,
    refetch: refetchLatestTherapists,
  } = useQuery({
    queryKey: queryKeysPhysiotherapist.myRehabilitants(),
    queryFn: async () => await getMyLatestPhysiotherapist(),
  });

  const onTryRefresh = async () => {
    try {
      setLoading(true);
      await Promise.all([
        getAppointmentsRefetch(),
        getTrainingDaysRefetch(),
        refetchLatestTherapists(),
      ]);
      setLoading(false);
    } catch (error) {
      alert(t("T00832"));
      setLoading(false);
    }
  };

  const onPressAppointment = () =>
    navigate("AppointmentDetails", {
      id: upcomingAppointment.id,
    });

  const onPressTraining = (id: number) =>
    navigate("AssignedTrainingDetails", { id });

  const onPressRehab = (id: number) =>
    navigate("PhysiotherapistProfile", { id });

  useEffect(() => {
    if (isSuccess) setEmptyUpcomingDays(false);
  }, [isSuccess]);

  useEffect(() => {
    if (getAppointmentsSuccess)
      setUpcomingAppointment(filterNearestAppointment);
  }, [filterNearestAppointment, getAppointmentsSuccess]);

  useUpdateUser();

  return (
    <ScrollView
      refreshControl={
        <RefreshControl refreshing={loading} onRefresh={onTryRefresh} />
      }
      contentContainerStyle={[container, { flex: 0 }]}>
      <View style={gapLarge}>
        <Tile
          tileType="medic"
          mode="outlined"
          onPress={() =>
            navigate(
              showWhatCanYouExpect ? "WhatCanYouExpect" : "Physiotherapists",
            )
          }
        />
        <PatientUpcomingAppointment
          error={getAppointmentsListError}
          loading={getAppointmentsListLoading}
          refetch={getAppointmentsRefetch}
          upcomingAppointment={upcomingAppointment}
          onPress={onPressAppointment}
        />
        <PatientUpcomingTraining
          loading={isGetTrainingDaysLoading}
          error={isGetTrainingDaysError}
          emptyUpcomingDays={emptyUpcomingDays}
          refetch={getTrainingDaysRefetch}
          trainingDays={trainingDays}
          onPress={onPressTraining}
        />
        <Tile
          tileType="training"
          mode="outlined"
          onPress={() => navigate("GeneralWorkoutCategories")}
        />
        <Divider bold horizontalInset />
        <PatientLatestRehabilitants
          onPress={onPressRehab}
          data={latestTherapists}
          isLoading={isLatestTherapistsLoading}
          isError={isLatestTherapistsError}
          refetch={refetchLatestTherapists}
        />
      </View>
    </ScrollView>
  );
};

export default HomeScreenPatient;
