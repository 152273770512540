import { FC } from "react";
import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { View } from "react-native";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useErrors } from "@hooks/useErrors";
import { deleteResponse } from "@api/reviews";
import { queryKeysPhysiotherapistRatings } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { useAuth } from "@contexts/AuthContext/auth";

type Props = {
  id: number;
  reviewId: number;
  onEditPress: () => void;
  onDeletePress: () => void;
};

const RatingAnswerSheetContentPhysio: FC<Props> = ({
  id,
  reviewId,
  onDeletePress,
  onEditPress,
}) => {
  const { setErrorsFromResponse } = useErrors();
  const queryClient = useQueryClient();
  const {
    user: { id: userId },
  } = useAuth();
  const { mutate, isLoading } = useMutation({
    mutationFn: deleteResponse,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeysPhysiotherapistRatings.list(userId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeysPhysiotherapistRatings.details(reviewId),
        }),
      ]);
    },
  });
  return (
    <>
      <View style={globalStyles.gapLarge}>
        <PrimaryButton
          label="T00732"
          mode="outlined"
          onPress={() => {
            onDeletePress();
            mutate(id);
          }}
          loading={isLoading}
        />
        <PrimaryButton label="T00733" mode="outlined" onPress={onEditPress} />
      </View>
    </>
  );
};

export default RatingAnswerSheetContentPhysio;
