import { MIN_AGE } from "@utils/constants";

export type YearsOption = "past" | "future" | "both" | "pastMinusMinAge";

export const generateYears = (yearsOption: YearsOption) => {
  const actualDate = new Date();
  const currentYear = actualDate.getFullYear();
  const years: string[] = [];
  switch (yearsOption) {
    case "past": {
      for (let i = 0; i < 119; i++) years.push((+currentYear - i).toString());
      break;
    }
    case "future": {
      for (let i = 0; i < 50; i++) years.push((+currentYear + i).toString());
      break;
    }
    case "both": {
      for (let i = -10; i <= 10; i++) years.push((+currentYear + i).toString());
      break;
    }
    case "pastMinusMinAge": {
      for (let i = MIN_AGE; i < 119; i++)
        years.push((+currentYear - i).toString());
      break;
    }
  }
  return years;
};
