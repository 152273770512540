import { FC } from "react";
import { TouchableOpacity } from "react-native";
import { IconButton, Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { TransKey } from "@globalTypes/i18next";
import { EmptyIconButton } from "@components/buttons";

type Props = {
  iconToDisplay: string;
  onPress: () => void;
  item: TransKey;
  type: "year" | "month";
};

export const EditMonthYearSelector: FC<Props> = ({
  onPress,
  iconToDisplay,
  item,
  type,
}) => {
  const { t } = useTranslation();

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{ flexDirection: "row", alignItems: "center" }}>
      {iconToDisplay ? (
        <IconButton icon={iconToDisplay} />
      ) : (
        <EmptyIconButton />
      )}
      <Text>{type === "month" ? t(item) : item}</Text>
    </TouchableOpacity>
  );
};
