import { FC, BaseSyntheticEvent } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Checkbox, HelperText, Text, TextProps } from "react-native-paper";
import { TransKey } from "@globalTypes/i18next";

type CheckboxSimpleTextProps = Pick<ControllerProps, "control"> &
  Pick<TextProps<string>, "variant"> & {
    name: string;
    text: TransKey;
    checkboxContainerStyle?: StyleProp<ViewStyle>;
    additionalMarginForText?: number;
    handleSubmit?: (e?: BaseSyntheticEvent) => Promise<void>;
  };

const CheckboxSimpleText: FC<CheckboxSimpleTextProps> = ({
  control,
  name,
  text,
  variant = "labelSmall",
  checkboxContainerStyle,
  additionalMarginForText = 0,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <View style={checkboxContainerStyle}>
            <View style={styles.checkboxContainer}>
              <Checkbox.Android
                onPress={() => {
                  handleSubmit && void handleSubmit();
                  onChange(!value);
                }}
                status={value ? "checked" : "unchecked"}
              />
              <View
                style={[
                  styles.textContainer,
                  { marginLeft: additionalMarginForText },
                ]}>
                <Text variant={variant}>{t(text)}</Text>
              </View>
            </View>
            {error && <HelperText type="error">{t("T00014")}</HelperText>}
          </View>
        );
      }}
    />
  );
};

export default CheckboxSimpleText;

const styles = StyleSheet.create({
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  textContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
  },
});
