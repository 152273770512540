import ServicesList from "@components/Lists/ServicesList";
import { FC } from "react";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { FetchError } from "../../../components/errors";
import { globalStyles } from "@styles/global";
import { TabItemProps, usePhysiotherapist } from "./PhysiotherapistProfile";

const ServicesTab: FC<TabItemProps> = ({ id }) => {
  const { data, isError, isLoading, refetch } = usePhysiotherapist(id);
  const { loading, gapMedium } = globalStyles;

  if (isLoading) return <ActivityIndicator style={loading} />;

  const { services } = data;

  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <View style={gapMedium}>
      <ServicesList services={services} emptyText="T00245" />
    </View>
  );
};

export default ServicesTab;
