import { spacing16, spacing24, spacing8 } from "@styles/spacing";
import { FC, useRef } from "react";
import { StyleSheet, TextInput, View } from "react-native";
import { IconButton } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { palettes } from "@styles/colors";

type Props = {
  onClear?: () => void;
  onChangeText?: (text: string) => void;
  goBack: () => void;
  searchQuery?: string;
  enableSearching?: boolean;
  autoFocus?: boolean;
};

export const HeaderWithSearchbar: FC<Props> = ({
  onClear,
  onChangeText,
  goBack,
  searchQuery,
  enableSearching,
  autoFocus = false,
}) => {
  const inputRef = useRef<TextInput>(null);
  const { t } = useTranslation();
  const { input, inputContainer } = styles;

  const clearInput = () => {
    inputRef.current.clear();
    onClear();
  };

  return (
    <View style={inputContainer}>
      <IconButton icon="arrow-left" onPress={goBack} size={spacing24} />
      {enableSearching && (
        <TextInput
          ref={inputRef}
          placeholder={t("T00588")}
          onChangeText={onChangeText}
          style={input}
          autoFocus={autoFocus}
        />
      )}
      {searchQuery?.length > 0 && (
        <IconButton icon="close" onPress={clearInput} size={spacing24} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: palettes.neutralVariant["50"],
    paddingVertical: spacing8,
  },
  input: {
    flex: 1,
    fontSize: spacing16,
  },
});
