import {
  ACTIVE,
  ASSIGNED_TO_PATIENT,
  DETAILS,
  IN_ACTIVE,
  LIST,
  OPTIONS,
  STATS,
} from "@utils/constants";
import { CalendarFilter } from "@screens/Calendar/PatientCalendar";
import { GetLibraryExercisesParams } from "./exercise.types";

const keys = {
  exercises: "exercises",
  assignedTrainings: "assignedTrainings",
  specialTrainings: "specialTrainings",
  libraryTrainings: "libraryTrainings",
  prophylacticTrainings: "prophylacticTrainings",
  trainingDay: "trainingDay",
  patient: "patient",
  closestDays: "closestDays",
  training: "training",
  prophylacticTraining: "prophylacticTraining",
  generalWorkoutCategories: "generalWorkoutCategories",
  sessionTraining: "sessionTraining",
  equipment: "equipment",
  workoutCategories: "workoutCategories",
  prophylacticWorkoutsSets: "prophylacticWorkoutsSets",
  prophylacticWorkoutCategorySets: "prophylacticWorkoutCategorySets",
  conditions: "conditions",
};

export const queryKeysSpecialTrainings = {
  all: [keys.specialTrainings] as const,
  list: () => [...queryKeysSpecialTrainings.all, LIST] as const,
  commitmentsToAppointmentList: (appointmentId: number) =>
    [...queryKeysSpecialTrainings.list(), appointmentId] as const,
  commitmentsListForSpecificPatient: (patientId: number) =>
    [...queryKeysSpecialTrainings.list(), keys.patient, patientId] as const,
  detail: (appointmentId: number) =>
    [...queryKeysSpecialTrainings.all, DETAILS, appointmentId] as const,
};

export const queryKeysExercises = {
  all: [keys.exercises] as const,
  list: (filters?: GetLibraryExercisesParams) =>
    [...queryKeysExercises.all, LIST, filters] as const,
  detail: (id: number) => [...queryKeysExercises.all, DETAILS, id] as const,
  options: () => [...queryKeysExercises.all, OPTIONS] as const,
  seriesDetails: (params: { seriesId: number; exerciseId: number }) => [
    ...queryKeysExercises.detail(params.exerciseId),
    params.seriesId,
  ],
  exerciseStats: (id: number, month: number, year: number) =>
    [...queryKeysExercises.detail(id), DETAILS, STATS, month, year] as const,
};

export const queryKeysIndividualTrainings = {
  all: [keys.assignedTrainings] as const,
  list: () => [...queryKeysIndividualTrainings.all, LIST] as const,
  assignedToPatientList: (patientId: number) =>
    [
      ...queryKeysIndividualTrainings.list(),
      ASSIGNED_TO_PATIENT,
      patientId,
    ] as const,
  assignedToPatientActiveList: (patientId: number) => [
    ...queryKeysIndividualTrainings.assignedToPatientList(patientId),
    ACTIVE,
  ],
  assignedToPatientInActiveList: (patientId: number) => [
    ...queryKeysIndividualTrainings.assignedToPatientList(patientId),
    IN_ACTIVE,
  ],
  activeList: (isAssistant: boolean) => [
    ...queryKeysIndividualTrainings.list(),
    ACTIVE,
    isAssistant,
  ],
  inActiveList: (isAssistant: boolean) => [
    ...queryKeysIndividualTrainings.list(),
    IN_ACTIVE,
    isAssistant,
  ],
};

export const queryKeysProphylacticTrainings = {
  all: [keys.prophylacticTrainings] as const,
  list: () => [...queryKeysProphylacticTrainings.all, LIST] as const,
};
export const queryKeysLibraryTrainings = {
  all: [keys.libraryTrainings] as const,
  list: () => [...queryKeysLibraryTrainings.all, LIST] as const,
};

export const queryKeysTrainingDay = {
  all: [keys.trainingDay] as const,
  list: () => [...queryKeysTrainingDay.all, LIST] as const,
  monthList: (calendarFilter: CalendarFilter) =>
    [...queryKeysTrainingDay.list(), calendarFilter] as const,
  details: (dayId: number) =>
    [...queryKeysTrainingDay.all, DETAILS, dayId] as const,
  closestDays: () =>
    [...queryKeysTrainingDay.list(), keys.closestDays] as const,
  daysForWorkoutList: (workoutId: number) =>
    [...queryKeysTrainingDay.list(), workoutId] as const,
};

export const queryKeysTraining = {
  detail: (trainingId: number, params?: { fullData: boolean }) =>
    [keys.training, DETAILS, trainingId, params?.fullData || false] as const,
};

export const queryKeysProphylacticTraining = {
  detail: (trainingId: number) =>
    [keys.prophylacticTraining, DETAILS, trainingId] as const,
};

export const queryKeysGeneralWorkoutCategories = {
  all: [keys.generalWorkoutCategories] as const,
  list: () => [...queryKeysGeneralWorkoutCategories.all, LIST] as const,
  detail: (catId: number, filter?: string) =>
    [queryKeysGeneralWorkoutCategories.all, DETAILS, catId, filter] as const,
};

export const queryKeysSessionTraining = {
  all: [keys.sessionTraining] as const,
  list: (trainingId: number) =>
    [...queryKeysSessionTraining.all, LIST, trainingId] as const,
  detail: (sessionId: number) =>
    [queryKeysSessionTraining.all, DETAILS, sessionId] as const,
};

export const queryKeysEquipments = {
  all: [keys.equipment] as const,
  list: () => [...queryKeysEquipments.all, LIST] as const,
  detail: (equipmentId: number) =>
    [queryKeysEquipments.all, DETAILS, equipmentId] as const,
};

export const queryKeysConditions = {
  all: [keys.conditions] as const,
  list: () => [...queryKeysConditions.all, LIST] as const,
};

export const queryKeysWorkoutCategories = {
  all: [keys.workoutCategories] as const,
  list: () => [...queryKeysWorkoutCategories.all, LIST] as const,
  detail: (id: number) =>
    [...queryKeysWorkoutCategories.all, DETAILS, id] as const,
};

export const queryKeysProphylacticWorkoutsSetCategories = {
  all: [keys.prophylacticWorkoutsSets] as const,
  list: () =>
    [...queryKeysProphylacticWorkoutsSetCategories.all, LIST] as const,
  detail: (id: number) =>
    [...queryKeysProphylacticWorkoutsSetCategories.all, DETAILS, id] as const,
};

export const queryKeysProphylacticWorkoutCategorySets = {
  all: [keys.prophylacticWorkoutCategorySets] as const,
  list: (id: number, filter?: string) =>
    [
      ...queryKeysProphylacticWorkoutCategorySets.all,
      LIST,
      filter,
      id,
    ] as const,
  detail: (id: number) =>
    [...queryKeysProphylacticWorkoutCategorySets.all, DETAILS, id] as const,
};
