import { GenderType } from "@contexts/AuthContext/user.types";
import { FC } from "react";
import MaleBoneBack from "./MaleBoneBack";
import FemaleBoneBack from "./FemaleBoneBack";
import { ModelProps } from "../humanModel.types";
import { useHumanModel } from "@hooks/humanModel/useHumanModel";

const BoneBack: FC<ModelProps> = ({
  defaultSelected = [],
  onChange,
  readOnly = false,
  gender,
}) => {
  const { modelProps } = useHumanModel({
    onChange,
    readOnly,
    defaultSelected,
  });

  return gender === GenderType.MALE ? (
    <MaleBoneBack {...modelProps} />
  ) : (
    <FemaleBoneBack {...modelProps} />
  );
};
export default BoneBack;
