import { FetchError } from "@components/errors";
import { queryKeysPhysiotherapistProfileData } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { getPhysiotherapistFullData } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";

type TherapistAccountSectionProps = {
  physiotherapistId: number;
};

const TherapistAccountSection: FC<TherapistAccountSectionProps> = ({
  physiotherapistId,
}) => {
  const { t } = useTranslation();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapistProfileData.full(physiotherapistId),
    queryFn: async () => await getPhysiotherapistFullData(physiotherapistId),
  });

  if (isLoading) return <ActivityIndicator />;

  if (isError) return <FetchError coverScreen={false} action={refetch} />;

  return (
    <View style={globalStyles.gapMedium}>
      <Text variant="titleMedium">{t("T01225")}:</Text>
      <Text>{data.isCompany ? t("T01183") : t("T01184")}</Text>
    </View>
  );
};

export default TherapistAccountSection;
