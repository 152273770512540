import Svg, { Rect, Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={56} height={56} viewBox="0 0 56 56" fill="none" {...props}>
    <Rect width={56} height={56} rx={28} fill="#4666FF" />
    <Path
      d="M23.993 15.607c0-2.148 1.607-3.61 3.87-3.61 2.262 0 3.787 1.462 3.787 3.61 0 2.148-1.566 3.568-3.787 3.568-2.222 0-3.87-1.51-3.87-3.568z"
      fill="#fff"
    />
    <Path
      d="M34.988 35.293l-3.286-4.139 10.003-12.346h-7.04l-6.542 8.414-6.543-8.414h-7.326l10.04 12.483-10.203 12.712h7.035l6.751-8.642 3.228 4.185 3.883-4.253zM38.465 39.668h-7.266l3.344 4.336h7.366l-3.444-4.336z"
      fill="#fff"
    />
  </Svg>
);

export default SvgComponent;
