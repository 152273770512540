import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";

import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";

type Props = {
  onPress: () => void;
};

export const VerifyLicenceConfirmationBottomSheet: FC<Props> = ({
  onPress,
}) => {
  const { t } = useTranslation();

  return (
    <View style={globalStyles.gapMedium}>
      <Text variant="titleMedium">{t("T00773")}</Text>
      <Text variant="bodyMedium">{t("T00774")}</Text>
      <PrimaryButton label="T00394" onPress={onPress} />
    </View>
  );
};
