import { PrimaryButton } from "@components/buttons";
import { TransKey } from "@globalTypes/i18next";
import { spacing16 } from "@styles/spacing";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { ExercisesFiltersParamsType } from "./SearchExercises";
import { FC } from "react";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { globalStyles } from "@styles/global";

type Props = {
  header: TransKey;
  type: "conditions" | "authorType";
  params: ExercisesFiltersParamsType;
  onClear: () => void;
  content: JSX.Element;
};

const ExercisesFiltersBottomSheetWrapper: FC<Props> = ({
  type,
  header,
  params,
  onClear,
  content,
}) => {
  const conditionsBottomSheet = type === "conditions";
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  return (
    <View
      style={[
        {
          paddingTop: spacing16,
          height: conditionsBottomSheet ? height * 0.5 : null,
        },
        globalStyles.gapMedium,
      ]}>
      <View
        style={[
          styles.conditionsContainer,
          {
            paddingHorizontal: conditionsBottomSheet ? spacing16 : null,
          },
        ]}>
        <Text variant="bodyLarge" lineBreakStrategyIOS="push-out">
          {t(header)}
        </Text>
        {((conditionsBottomSheet && !!params?.conditions.length) ||
          (type === "authorType" && !!params?.authorId)) && (
          <PrimaryButton label="T01343" onPress={onClear} mode="text" />
        )}
      </View>
      {content}
    </View>
  );
};

export default ExercisesFiltersBottomSheetWrapper;

const styles = StyleSheet.create({
  conditionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
