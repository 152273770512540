import { FC } from "react";
import { MapWebViewScreen } from "@screens/Common/MapWebView";
import { useQuery } from "@tanstack/react-query";
import { getPhysiotherapistFullData } from "@api/users";
import { queryKeysPhysiotherapistProfileData } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { FetchError } from "@components/errors";
import { ActivityIndicator, Text } from "react-native-paper";
import {
  Linking,
  StyleProp,
  TextProps,
  TextStyle,
  View,
  ViewProps,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { PrimaryButton } from "@components/buttons";
import { spacing12 } from "@styles/spacing";
import { globalStyles } from "@styles/global";
import {
  formatAddressToDisplay,
  formatAddressToRedirection,
} from "@utils/index";
import { isWeb } from "@utils/constants";

type Props = ViewProps & {
  physioId: number;
  labelStyle?: StyleProp<TextStyle>;
  textProps?: TextProps;
};

type UseNavigation = MapWebViewScreen["navigation"];

const AddressWithMapRedirect: FC<Props> = ({
  physioId,
  labelStyle,
  textProps,
  ...props
}) => {
  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapistProfileData.full(physioId),
    queryFn: async () => await getPhysiotherapistFullData(physioId),
  });
  const { navigate } = useNavigation<UseNavigation>();
  const address = data?.address;

  const navToMap = async () => {
    const uri = `https://maps.google.com/?q=${formatAddressToRedirection(
      address,
    )}`;

    if (isWeb) {
      await Linking.openURL(uri);
    } else {
      navigate("MapWebView", { uri });
    }
  };

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <View style={globalStyles.gapSmall} {...props}>
      <Text variant="bodyMedium" {...textProps}>
        {formatAddressToDisplay(address)}
      </Text>
      {address?.additionalInformation && (
        <Text variant="bodyMedium" {...textProps}>
          {address.additionalInformation}
        </Text>
      )}
      <PrimaryButton
        style={{
          alignSelf: "flex-start",
          marginLeft: -spacing12,
        }}
        mode="text"
        label="T00548"
        labelStyle={[
          {
            marginVertical: 0,
          },
          labelStyle,
        ]}
        onPress={navToMap}
      />
    </View>
  );
};

export default AddressWithMapRedirect;
