import {
  Coords,
  GeocodeStatus,
  GetCoordinatesFromAddressResponse,
} from "@globalTypes/geocoding.types";
import { api } from "./api";
import { config } from "@utils/config";

const endpoint = (address: string) =>
  `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${config.EXPO_PUBLIC_GOOGLE_CLOUD_API_KEY}`;

export const getCoordinatesFromAddress = async (
  address: string,
  onError?: (reason: GeocodeStatus) => void,
) => {
  try {
    const { data } = await api.get<GetCoordinatesFromAddressResponse>(
      endpoint(address),
    );
    if (data.status !== "OK" && onError) return onError(data.status);
    const coords: Coords = data?.results[0]?.geometry.location;
    return coords;
  } catch (err) {
    console.log("geocoding error", err);
  }
};
