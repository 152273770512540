import { FC } from "react";
import { ActivityIndicator } from "react-native-paper";
import { useAppTheme } from "@styles/theme";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";

const AbsoluteLoader: FC = () => {
  const { width, height } = useWindowDimensions();
  const {
    colors: { backdrop },
  } = useAppTheme();
  return (
    <ActivityIndicator
      style={{
        width,
        height,
        position: "absolute",
        backgroundColor: backdrop,
        zIndex: 999,
      }}
    />
  );
};

export default AbsoluteLoader;
