import { PrimaryButton } from "@components/buttons";
import { CustomChip } from "@components/CustomChip";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { FC } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

type Props = {
  data: {
    title: string;
    onPress: () => void;
    selected?: boolean;
  }[];
  clearAllFilters: () => void;
};

export const ScrollFilteringChipsSection: FC<Props> = ({
  data,
  clearAllFilters,
}) => {
  const { gapMedium } = globalStyles;
  const {
    colors: { onSurface },
  } = useAppTheme();

  return (
    <View>
      <ScrollView
        horizontal
        contentContainerStyle={[gapMedium, styles.container]}
        showsHorizontalScrollIndicator={false}>
        {data.map(({ title, onPress, selected }, i) => (
          <CustomChip
            key={`chip-sorting-${title}-${i}`}
            selected={selected}
            onPress={onPress}
            title={title}
            color={onSurface}
          />
        ))}
        {data.some(({ selected }) => selected) && (
          <PrimaryButton label="T01420" onPress={clearAllFilters} mode="text" />
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: spacing16,
    justifyContent: "center",
    alignItems: "center",
  },
});
