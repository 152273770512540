import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      fill="#4ACB29"
      d="M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667Zm0 15A6.675 6.675 0 0 1 3.333 10 6.676 6.676 0 0 1 10 3.333 6.676 6.676 0 0 1 16.666 10 6.675 6.675 0 0 1 10 16.667Zm3.825-10.35-5.492 5.491-2.158-2.15L5 10.833l3.333 3.334L15 7.5l-1.175-1.183Z"
    />
  </Svg>
);
export default SvgComponent;
