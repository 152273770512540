import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { AddPhysiotherapistContent } from "@components/BottomSheetContents";
import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { globalStyles } from "@styles/global";
import { MAX_CODE_LENGTH } from "@utils/constants";
import { Control } from "react-hook-form";
import { View } from "react-native";
import { getContent } from "./welcomeToProduct.helpers";
import { FC } from "react";
import { usePromoCodesLogic } from "@hooks/promoCodes/usePromoCodesLogic";

type FormSectionProps = { onAlertConfirm: () => void };

const FormSection: FC<FormSectionProps> = ({ onAlertConfirm }) => {
  const { isPatient } = useUserDetails();
  const {
    handleSubmit,
    control,
    isLoading,
    currentInvitationCode,
    physioId,
    isBottomSheetVisible,
    errors,
    clearErrors,
    setIsBottomSheetVisible,
  } = usePromoCodesLogic(onAlertConfirm);
  const { buttonLabel } = getContent(isPatient);
  const { container } = globalStyles;
  return (
    <View style={container}>
      <Input
        key="invitationCode"
        name="invitationCode"
        label="T01367"
        placeholder="A00000"
        control={control as unknown as Control}
        errors={errors?.invitation_code}
        autoCapitalize="sentences"
        onChange={clearErrors}
        maxLength={MAX_CODE_LENGTH}
      />
      <PrimaryButton
        label={buttonLabel}
        mode="outlined"
        onPress={handleSubmit}
        loading={isLoading}
        disabled={isLoading}
      />
      <BottomModalContainer
        modalVisible={isBottomSheetVisible}
        setModalVisible={setIsBottomSheetVisible}>
        <AddPhysiotherapistContent
          physioId={physioId}
          invitationCode={currentInvitationCode}
          hideModal={() => setIsBottomSheetVisible(false)}
          onAlertConfirm={onAlertConfirm}
        />
      </BottomModalContainer>
    </View>
  );
};

export default FormSection;
