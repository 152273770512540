import { PaperCardProps } from "@components/Tile/types/RecordTile.types";
import { CurrencyType } from "@globalTypes/common.types";

export enum TransactionStatus {
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
  CANCELLED = "CANCELLED",
  AVAILABLE = "AVAILABLE",
}

export enum TransactionType {
  INCOME = "Income",
  EXPENSE = "Expense",
}

export type TransactionBaseProps = {
  paymentStatus: TransactionStatus;
  finalPrice: number;
  paymentProviderFee: number;
  servicePrice: number;
  currency: CurrencyType;
  participant: number;
  createdAt: string;
  availableOn: string;
};

export type TransactionTileProps = {
  subtitle: string;
  transactionType: TransactionType;
} & TransactionBaseProps &
  PaperCardProps;

export type Transaction = {
  name: string;
  date: string;
} & TransactionBaseProps;

export type TransactionHistoryResponse = {
  [date: string]: Transaction[];
};

export type TransactionDetailsType = Pick<Transaction, "date"> &
  TransactionBaseProps & {
    serviceName: string;
    transactionType: TransactionType;
  };
