import { FC } from "react";
import { Text } from "react-native-paper";
import InfoTile from "@components/Tile/InfoTile";
import { useTranslation } from "react-i18next";
import { useAppTheme } from "@styles/theme";
import { View } from "react-native";
import { globalStyles } from "@styles/global";
import { InfoTileType } from "@components/Tile/types/InfoTile.types";

type Props = Omit<InfoTileType, "content" | "status">;

const SuggestedParametersTile: FC<Props> = props => {
  const { t } = useTranslation();
  const {
    colors: { primary },
  } = useAppTheme();
  return (
    <InfoTile
      content={
        <View style={globalStyles.gapMedium}>
          <Text variant="titleMedium">{t("T00755")}</Text>
          <Text variant="bodyMedium">{t("T00756")}</Text>
          <Text variant="labelLarge" style={{ color: primary }}>
            {t("T00757")}
          </Text>
        </View>
      }
      status="info"
      hasBackground={false}
      {...props}
    />
  );
};

export default SuggestedParametersTile;
