import { SvgChevronLeft, SvgChevronRight } from "@assets/svg";
import {
  AvailableAppointments,
  AvailableAppointmentsColumns,
  HoursSectionProps,
} from "@components/PhysioAvailability/PhysioAvailability.types";
import { PhysioAvailabilityHeader } from "@components/PhysioAvailability/index";
import { FetchError } from "@components/errors";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { globalStyles } from "@styles/global";
import { spacing32, spacing4, spacing8 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  FlatList,
  ListRenderItem,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleSheet,
  View,
} from "react-native";
import { IconButton } from "react-native-paper";
import EmptySectionInfo from "./EmptySectionInfo";
import ThreeDaySectionColumnsList from "./ThreeDaySectionColumnsList";
import {
  fillSectionWithApiData,
  generateEmptySections,
  getCalendarRange,
  keyExtractor,
  scrollToIndex,
} from "./helpers";
import { AppointmentSlots } from "@screens/Calendar/availability.types";
import { useThreeDayCalendarSection } from "@hooks/availability/useThreeDayCalendarSection";

const CALENDAR_RANGE = getCalendarRange();
const LAST_SECTION_INDEX = CALENDAR_RANGE / 3 - 1;

const PhysioAvailabilityHoursSection: FC<HoursSectionProps> = ({
  physiotherapistId,
  serviceId,
  serviceType,
  onHourPress,
}) => {
  const [sections, setSections] = useState<AvailableAppointmentsColumns[]>(
    generateEmptySections(CALENDAR_RANGE),
  );
  const {
    colors: { surfaceDisabled },
  } = useAppTheme();
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [activeSectionFirstDay, setActiveSectionFirstDay] = useState(
    new Date(),
  );
  const isStationaryService = serviceType === AppointmentSlots.STATIONARY;
  const { leftIcon, rightIcon, iconStyle, itemContainer } = styles;
  const { width } = useWindowDimensions();
  const renderItemContentWidth = width - spacing32;
  const flatListRef = useRef<FlatList>(null);

  const fillSection = useCallback(
    (index: number, appointments: AvailableAppointments) => {
      if (
        sections[index]?.some(section => section.hours.length) ||
        index > LAST_SECTION_INDEX
      )
        return;

      const filledSection = fillSectionWithApiData(
        sections[index],
        appointments,
      );
      setSections(prev => {
        const temp = [...prev];
        temp[index] = filledSection;
        return temp;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const commonHookProps = useMemo(
    () => ({
      physiotherapistId,
      serviceId,
      activeSectionFirstDay,
      range: CALENDAR_RANGE,
      currentSectionIndex,
      onSuccess: fillSection,
    }),
    [
      activeSectionFirstDay,
      fillSection,
      physiotherapistId,
      serviceId,
      currentSectionIndex,
    ],
  );
  const {
    isLoading: isActiveSectionLoading,
    isError: isActiveSectionError,
    refetch: activeSectionRefetch,
  } = useThreeDayCalendarSection({
    fetchFutureSectionNumber: 0,
    ...commonHookProps,
  });

  const {
    isLoading: isNextSectionLoading,
    isError: isNextSectionError,
    refetch: nextSectionRefetch,
  } = useThreeDayCalendarSection({
    fetchFutureSectionNumber: 1,
    ...commonHookProps,
  });

  useThreeDayCalendarSection({
    fetchFutureSectionNumber: 2,
    ...commonHookProps,
  });

  useThreeDayCalendarSection({
    fetchFutureSectionNumber: 3,
    ...commonHookProps,
  });

  useThreeDayCalendarSection({
    fetchFutureSectionNumber: 4,
    ...commonHookProps,
  });

  const isLoading = useMemo(
    () => isActiveSectionLoading || isNextSectionLoading,
    [isActiveSectionLoading, isNextSectionLoading],
  );
  const isError = useMemo(
    () => isActiveSectionError || isNextSectionError,
    [isActiveSectionError, isNextSectionError],
  );

  const refetch = async () =>
    await Promise.all([activeSectionRefetch(), nextSectionRefetch()]);

  const calculateVisibleIndex = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const contentOffset = event.nativeEvent.contentOffset.x;
      const visibleIndex = Math.floor(
        contentOffset / (renderItemContentWidth - spacing8),
      );
      setCurrentSectionIndex(prev => {
        if (
          visibleIndex === LAST_SECTION_INDEX &&
          prev === LAST_SECTION_INDEX
        ) {
          setCurrentSectionIndex(prev);
          return prev;
        }
        return visibleIndex > 0 ? visibleIndex : 0;
      });
    },
    [renderItemContentWidth],
  );

  const scrollToNextAvailable = useCallback(
    (date: string) => {
      const index = sections?.findIndex(section =>
        section.map(s => s.date).includes(date),
      );
      let currentIndex = currentSectionIndex;
      while (currentIndex < index) {
        fillSection(currentIndex, []);
        currentIndex++;
      }
      scrollToIndex(index, flatListRef, LAST_SECTION_INDEX);
    },
    [sections],
  );

  const renderItem: ListRenderItem<AvailableAppointmentsColumns> = useCallback(
    ({ item, index }) => {
      const isOnLastSection =
        currentSectionIndex >= LAST_SECTION_INDEX &&
        index === LAST_SECTION_INDEX;
      const isOnFirstSection = currentSectionIndex === 0;
      const rightDisabled = isOnLastSection;
      const isEmptySection = item?.every(
        ({ hours, wasFilled }) => !hours.length && wasFilled,
      );

      return (
        <View style={itemContainer}>
          <IconButton
            style={[iconStyle, leftIcon]}
            icon={() => (
              <SvgChevronLeft color={isOnFirstSection && surfaceDisabled} />
            )}
            onPress={() => {
              if (isOnFirstSection) return;
              scrollToIndex(currentSectionIndex - 1, flatListRef);
            }}
            disabled={isOnFirstSection}
          />
          <IconButton
            style={[iconStyle, rightIcon]}
            icon={() => (
              <SvgChevronRight color={rightDisabled && surfaceDisabled} />
            )}
            onPress={() => {
              console.log("ONpress");
              if (isOnLastSection) return;
              scrollToIndex(
                currentSectionIndex + 1,
                flatListRef,
                LAST_SECTION_INDEX,
              );
            }}
            disabled={rightDisabled}
          />
          <PhysioAvailabilityHeader item={item} />
          {isEmptySection ? (
            <EmptySectionInfo
              activeIndex={currentSectionIndex}
              sectionIndex={index}
              date={item[2].date}
              serviceId={serviceId}
              physiotherapistId={physiotherapistId}
              isStationary={isStationaryService}
              onPress={scrollToNextAvailable}
            />
          ) : (
            <ThreeDaySectionColumnsList
              isStationaryService={isStationaryService}
              onHourPress={onHourPress}
              data={item}
            />
          )}
        </View>
      );
    },
    [
      currentSectionIndex,
      iconStyle,
      isStationaryService,
      itemContainer,
      leftIcon,
      onHourPress,
      physiotherapistId,
      rightIcon,
      scrollToNextAvailable,
      serviceId,
      surfaceDisabled,
    ],
  );

  const getItemLayout = useCallback(
    (_: unknown, index: number) => ({
      length: renderItemContentWidth,
      offset: renderItemContentWidth * index,
      index,
    }),
    [renderItemContentWidth],
  );

  useEffect(() => {
    setCurrentSectionIndex(0);
    scrollToIndex(0, flatListRef);
  }, [serviceId]);

  useEffect(() => {
    setActiveSectionFirstDay(
      new Date(sections[currentSectionIndex]?.[0]?.date),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSectionIndex]);

  if (isError) return <FetchError action={refetch} coverScreen={false} />;
  return (
    <View style={{ flex: 1 }}>
      <FlatList
        ref={flatListRef}
        data={sections}
        renderItem={renderItem}
        horizontal
        showsHorizontalScrollIndicator={false}
        keyExtractor={keyExtractor}
        onScroll={calculateVisibleIndex}
        pagingEnabled
        initialNumToRender={2}
        removeClippedSubviews
        windowSize={3}
        maxToRenderPerBatch={2}
        initialScrollIndex={0}
        getItemLayout={getItemLayout}
        refreshing={isLoading}
      />
    </View>
  );
};

export default PhysioAvailabilityHoursSection;

const styles = StyleSheet.create({
  iconStyle: {
    position: "absolute",
    top: -spacing4,
    zIndex: 999,
  },
  leftIcon: {
    left: -spacing4,
  },
  rightIcon: {
    right: -spacing4,
  },
  scrollViewContent: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  itemContainer: {
    ...globalStyles.gapMedium,
    flex: 1,
  },
});
