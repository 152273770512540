import { BottomTutorialComponent } from "@components/BottomSheet";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { FC } from "react";
import {
  SvgOnlinePaymentsIcon1,
  SvgOnlinePaymentsIcon2,
  SvgOnlinePaymentsIcon3,
  SvgOnlinePaymentsIcon4,
} from "@assets/svg/tutorials";

export type TutorialBottomSheetProps = {
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
};

const OnlinePaymentsTutorial: FC<TutorialBottomSheetProps> = ({
  modalVisible,
  setModalVisible,
}) => {
  return (
    <BottomModalContainer
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}>
      <BottomTutorialComponent
        title="T01109"
        content={[
          {
            text: "T01110",
          },
          {
            text: "T01111",

            svgComponent: () => <SvgOnlinePaymentsIcon1 />,
            displayHorizontally: true,
          },
          {
            text: "T01112",
            svgComponent: () => <SvgOnlinePaymentsIcon2 />,
            displayHorizontally: true,
          },
          {
            text: "T01113",
            svgComponent: () => <SvgOnlinePaymentsIcon3 />,
            displayHorizontally: true,
          },
          {
            text: "T01114",
            svgComponent: () => <SvgOnlinePaymentsIcon4 />,
            displayHorizontally: true,
          },
        ]}
        onPress={() => setModalVisible(false)}
      />
    </BottomModalContainer>
  );
};

export default OnlinePaymentsTutorial;
