import { palettes } from "@styles/colors";
import { fontConfig } from "@styles/fontConfig";
import { spacing12, spacing20, spacing26, spacing28 } from "@styles/spacing";
import { isWeb } from "@utils/constants";
import RNCircularProgress from "react-native-circular-progress-indicator";

type ProgressCircleType = {
  percent: number;
  initialValue?: number;
};

export const ProgressCircle = ({
  percent,
  initialValue,
}: ProgressCircleType) => {
  const getCircleColor = (percent: number) => {
    switch (true) {
      case percent === 0:
      default:
        return palettes.secondary[90];
      case percent > 0 && percent < 40:
        return palettes.warning[91];
      case percent >= 40 && percent < 70:
        return palettes.primary[40];
      case percent >= 70 && percent < 100:
        return palettes.complete[10];
      case percent === 100:
        return palettes.complete[30];
    }
  };
  return (
    <RNCircularProgress
      initialValue={initialValue}
      value={percent}
      delay={400}
      radius={spacing28}
      progressValueColor={palettes.primary[0]}
      duration={1000}
      valueSuffix="%"
      valueSuffixStyle={(fontConfig.titleSmall, isWeb && { width: spacing12 })}
      progressValueStyle={
        (fontConfig.titleSmall,
        isWeb && {
          textAlign: "right",
          width:
            percent === 100 ? spacing26 : percent > 0 ? spacing20 : spacing12,
        })
      }
      activeStrokeColor={getCircleColor(percent)}
      inActiveStrokeColor={palettes.secondary[90]}
      maxValue={100}
    />
  );
};
