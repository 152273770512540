import DropdownPicker, {
  PickerItem,
} from "@components/DropdownPicker/DropdownPicker";
import { SegmentedButtonsWithController } from "@components/SegmentedButtonsWithController";
import { ServiceType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { palettes } from "@styles/colors";
import { spacing8 } from "@styles/spacing";
import { SERVICE_SLOT_TIME, SLOTS_COUNT } from "@utils/constants";
import { FC } from "react";
import { Control, FieldError, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HelperText } from "react-native-paper";

type ServiceTypeButtonAndSlotsPickerParams = {
  control: Control;
  watch: UseFormWatch<{
    serviceType?: string;
  }>;
  selectedSlots: number;
  setSelectedSlots: (value: number) => void;
  setCurrencyDropdownOpen?: (open: boolean) => void;
  slotsDropdownOpen: boolean;
  setSlotsDropdownOpen: (open: boolean) => void;
  errors?: FieldError[];
  serviceType?: ServiceType;
};

const ServiceTypeButtonAndSlotsPicker: FC<
  ServiceTypeButtonAndSlotsPickerParams
> = ({
  control,
  watch,
  selectedSlots,
  setSelectedSlots,
  slotsDropdownOpen,
  setSlotsDropdownOpen,
  setCurrencyDropdownOpen,
  errors,
  serviceType,
}) => {
  const { t } = useTranslation();

  const createSlots = (n: number): PickerItem<number>[] => {
    const arr: PickerItem<number>[] = [];
    for (let i = 0; i < n; i++) {
      const obj: PickerItem<number> = {
        value: i,
        label: `${SERVICE_SLOT_TIME + i * SERVICE_SLOT_TIME}`,
      };
      arr.push(obj);
    }
    return arr;
  };

  const slots = createSlots(SLOTS_COUNT);

  return (
    <>
      <SegmentedButtonsWithController
        control={control}
        name="serviceType"
        key="serviceType"
        buttons={[
          {
            value: "Stationary",
            label: `${t("T00546")}`,
            showSelectedCheck: true,
          },
          {
            value: "Online",
            label: `${t("T00483")}`,
            style: watch("serviceType") === "Online" && {
              backgroundColor: palettes.tertiary[90],
            },
            showSelectedCheck: true,
          },
        ]}
        errors={errors}
        density="small"
        defaultValue={serviceType}
      />
      <DropdownPicker<number>
        items={slots}
        defaultValue={selectedSlots}
        onSelectItem={({ value }) => setSelectedSlots(value)}
        containerStyles={{ marginTop: spacing8 }}
        dropdownLabelText="T00785"
        onOpen={() => {
          setCurrencyDropdownOpen && setCurrencyDropdownOpen(false);
          setSlotsDropdownOpen(true);
        }}
        onClose={() => setSlotsDropdownOpen(false)}
        isDropdownOpen={slotsDropdownOpen}
        multiDropdownContent
        isInBottomSheet
        dropDownDirection="TOP"
      />
      <HelperText type="info" style={{ paddingTop: 0 }}>
        {t("T01304")}
      </HelperText>
    </>
  );
};

export default ServiceTypeButtonAndSlotsPicker;
