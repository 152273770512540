import {
  copyLibraryExercise,
  deleteLibraryExercise,
  getExercise,
} from "@api/exercises";
import { AppbarTitleWithBackAction } from "@components/Appbar";
import { BottomModalWithButton } from "@components/BottomSheet";
import {
  ButtonBasic,
  ButtonBottomSheet,
} from "@components/Button/Button.types";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import HumanModel from "@components/HumanModel/HumanModel";
import { ImagePreview } from "@components/Image";
import { FullScreenModal } from "@components/Modals/FullScreenModal";
import { SystemMessage } from "@components/Report/report.types";
import { TransKey } from "@globalTypes/i18next";
import { useErrors } from "@hooks/useErrors";
import { useAssistant } from "@hooks/user/useAssistant";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ImageProps, ScrollView, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { Host } from "react-native-portalize";
import { SafeAreaView } from "react-native-safe-area-context";
import { Actions, ExerciseDetailsScreenType } from "../exercise.types";
import SelectedEquipmentList from "../Physiotherapist/SelectedEquipmentList";
import { queryKeysExercises } from "../queryKeysTrainingsAndExercises";
import ExerciseInstruction from "./ExerciseInstruction";
import ExercisePhoto from "./ExercisePhoto";
import ExerciseTitle from "./ExerciseTitle";
import ExerciseVideoSection from "./ExerciseVideosSection";
import ConditionChipsSection from "@components/Training/ConditionChipsSection";

const setScreenTitle = (type: ExerciseDetailsScreenType) => {
  switch (type) {
    case "libraryExercise":
      return "T00078";
    case "fixmeExercise":
      return "T00079";
    default:
      return "T00077";
  }
};

const ExerciseDetails: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "ExerciseDetails">
  >
> = ({
  navigation: { navigate, setOptions, goBack },
  route: {
    params: { id },
  },
}) => {
  const [exerciseType, setExerciseType] = useState<ExerciseDetailsScreenType>();
  const [title, setTitle] = useState<TransKey>();
  const [subtitles, setSubtitles] = useState<TransKey[]>();
  const [buttons, setButtons] = useState<ButtonBottomSheet[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [imagePreviewModalVisible, setImagePreviewModalVisible] =
    useState(false);

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const { isPhysiotherapist } = useUserDetails();

  const { loading, scrollContainer, gapMedium } = globalStyles;

  const refreshAndGoBack = async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKeysExercises.list(),
    });
    goBack();
  };

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysExercises.detail(id),
    queryFn: async () => await getExercise(id),
  });
  const { isLoading: isDeleting, mutate: deleteExercise } = useMutation({
    mutationFn: deleteLibraryExercise,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: refreshAndGoBack,
  });

  const { isLoading: isCopyExerciseLoading, mutate: copyExercise } =
    useMutation({
      mutationFn: copyLibraryExercise,
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
      onSuccess: refreshAndGoBack,
    });

  const { isAssistantId } = useAssistant(data?.author);

  useEffect(() => {
    if (data) {
      const { workout } = data;
      if (isAssistantId) return setExerciseType("fixmeExercise");
      if (workout === null) return setExerciseType("libraryExercise");
      setExerciseType("exercise");
    }
  }, [data, isAssistantId]);

  useEffect(() => {
    setButtons(prevButtons =>
      prevButtons.map(button => ({
        ...button,
        loading: isDeleting || isCopyExerciseLoading,
      })),
    );
  }, [isCopyExerciseLoading, isDeleting]);

  const openModal = useCallback(
    (action: Actions) => {
      switch (action) {
        case "delete":
          setTitle("T00302");
          setSubtitles(["T00306"]);
          setButtons([
            {
              label: "T00146",
              onPress: () => deleteExercise(id),
              loading: isDeleting,
            },
          ]);
          break;

        case "copy":
          setTitle("T00310");
          setSubtitles(["T00311", "T00312"]);
          setButtons([
            {
              label: "T00313",
              onPress: () => copyExercise(id),
              loading: isCopyExerciseLoading,
            },
          ]);
          break;
      }
      setModalVisible(true);
    },
    [isDeleting, isCopyExerciseLoading, deleteExercise, id, copyExercise],
  );

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack } }) => (
        <AppbarTitleWithBackAction
          title={t(setScreenTitle(exerciseType))}
          onClose={goBack}
        />
      ),
    });
  }, [setOptions, t, exerciseType]);

  const exerciseImageSource = useMemo(
    () =>
      data?.exerciseImageUrl
        ? { uri: data?.exerciseImageUrl }
        : (require("@assets/images/exercise_default_image.png") as ImageProps["source"]),
    [data?.exerciseImageUrl],
  );

  const renderImagePreview = useCallback(
    () => (
      <ImagePreview
        source={exerciseImageSource}
        onPress={() => setImagePreviewModalVisible(false)}
      />
    ),
    [exerciseImageSource],
  );

  if (isLoading) return <ActivityIndicator style={loading} />;

  if (isError) return <FetchError action={refetch} />;

  const { name, conditions, instruction, exerciseVideoUrl } = data;

  const getButtonProps = (type: ExerciseDetailsScreenType) =>
    ({
      label: type === "fixmeExercise" ? "T00313" : "T00314",
      onPress:
        type === "fixmeExercise"
          ? () => openModal("copy")
          : () => navigate("CreateAndEditExercise", { id }),
    } as ButtonBasic);

  return (
    <Host>
      <SafeAreaView edges={["bottom", "left", "right"]}>
        <ScrollView contentContainerStyle={scrollContainer}>
          <View style={gapMedium}>
            <ExerciseTitle
              isAssistantExercise={isAssistantId}
              title={name}
              item={id}
              systemMessage={SystemMessage.REPORT_EXERCISE_TITLE}
            />
            <ConditionChipsSection
              title="T01335"
              conditions={conditions}
              style={{ marginBottom: spacing8 }}
            />
            {exerciseType === ("libraryExercise" || "exercise") && (
              <View style={gapMedium}>
                {exerciseType === "libraryExercise" && (
                  <>
                    <PrimaryButton
                      label="T00301"
                      onPress={() => openModal("delete")}
                      mode="outlined"
                    />
                    <PrimaryButton
                      label="T00309"
                      onPress={() => openModal("copy")}
                      mode="outlined"
                    />
                  </>
                )}
                {exerciseType !== "exercise" && (
                  <PrimaryButton {...getButtonProps(exerciseType)} />
                )}
              </View>
            )}
            {exerciseType === "fixmeExercise" && isPhysiotherapist && (
              <PrimaryButton label="T00313" onPress={() => openModal("copy")} />
            )}
            <Text variant="titleMedium">{t("T00298")}:</Text>
            <HumanModel
              humanGeneral={data?.humanGeneral}
              gender={data?.gender}
              humanBones={data?.humanBones}
              humanMuscles={data?.humanMuscles}
            />
            <Text variant="titleMedium">{t("T00554")}:</Text>
            <SelectedEquipmentList selectedEquipment={data?.equipments} />
            <ExerciseInstruction
              disableReportWrapper={isAssistantId}
              instruction={instruction}
              item={id}
              systemMessage={SystemMessage.REPORT_EXERCISE_INSTRUCTION}
            />
            <ExerciseVideoSection
              title={t("T00742")}
              uri={exerciseVideoUrl}
              systemMessage={SystemMessage.REPORT_EXERCISE_VIDEO}
              isAssistantExercise={isAssistantId}
              item={id}
              subtitlesUrl={data?.exerciseVideoSubtitlesUrl}
            />
            {data?.instructionExerciseVideoUrl && (
              <ExerciseVideoSection
                title={t("T00762")}
                uri={data.instructionExerciseVideoUrl}
                systemMessage={SystemMessage.REPORT_EXERCISE_INSTRUCTION_VIDEO}
                isAssistantExercise={isAssistantId}
                item={id}
                subtitlesUrl={data?.instructionExerciseVideoSubtitlesUrl}
                isThumbnail
              />
            )}
            <ExercisePhoto
              onPress={() => setImagePreviewModalVisible(true)}
              source={exerciseImageSource}
              isAssistantExercise={isAssistantId}
              systemMessage={SystemMessage.REPORT_EXERCISE_PHOTO}
              item={id}
            />
          </View>
          <BottomModalWithButton
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            title={title}
            subtitles={subtitles}
            buttons={buttons}
          />
          <FullScreenModal
            isModalVisible={imagePreviewModalVisible}
            setIsModalVisible={setImagePreviewModalVisible}
            renderContent={renderImagePreview}
          />
        </ScrollView>
      </SafeAreaView>
    </Host>
  );
};

export default ExerciseDetails;
