import { BottomTutorialComponent } from "@components/BottomSheet";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { FC } from "react";
import {
  SvgWorkoutCalendar1,
  SvgWorkoutCalendar2,
} from "@assets/svg/tutorials";

type TutorialTrainingDetailsProps = {
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
};

const TutorialTrainingDetails: FC<TutorialTrainingDetailsProps> = ({
  modalVisible,
  setModalVisible,
}) => {
  return (
    <BottomModalContainer
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}>
      <BottomTutorialComponent
        title="T00067"
        content={[
          {
            text: "T00644",
            svgComponent: () => <SvgWorkoutCalendar1 />,
          },
          {
            text: ["T00645", "T00646", "T00647"],
            svgComponent: () => <SvgWorkoutCalendar2 />,
          },
        ]}
        onPress={() => setModalVisible(false)}
      />
    </BottomModalContainer>
  );
};

export default TutorialTrainingDetails;
