import { FC, PropsWithChildren, useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import Carousel from "react-native-reanimated-carousel";

import { PrimaryButton } from "@components/buttons";
import { Logo } from "@components/Logo";
import { SliderDots } from "@components/Slider/SliderDots";
import { SliderAnimation } from "@components/Slider/SliderAnimation";

import { RootStackParamList } from "@navigators/navigation.types";
import { globalStyles } from "@styles/global";
import { spacing24 } from "@styles/spacing";
import { DataType, physioSliderData } from "@frontendData/physio_slider";
import { useMediaStyle } from "@hooks/ui/useMediaStyle";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { useUserDetails } from "@hooks/index";

const AreYouPhysiotherapist: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "AreYouPhysiotherapist">
  >
> = ({ navigation: { goBack } }) => {
  const { isPhysiotherapist } = useUserDetails();
  const { width } = useMediaStyle();
  const { height } = useWindowDimensions();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const renderItem = useCallback(
    ({ item: { animation, text } }: { item: DataType; index: number }) => (
      <SliderAnimation animation={animation} text={text} />
    ),
    [],
  );

  const sliderData = isPhysiotherapist
    ? physioSliderData.slice(1)
    : physioSliderData;

  return (
    <View
      style={[
        globalStyles.container,
        { alignItems: "center", justifyContent: "center" },
      ]}>
      <Logo />
      <Carousel
        loop
        data={sliderData}
        autoPlay
        pagingEnabled
        width={width}
        autoPlayInterval={5000}
        onProgressChange={(_, absoluteProgress) =>
          setCurrentIndex(absoluteProgress)
        }
        style={{ height: height / 1.5 }}
        renderItem={renderItem}
      />
      <View style={styles.footerContainer}>
        <SliderDots data={sliderData} currentIndex={currentIndex} />
        <PrimaryButton
          label="T00165"
          onPress={goBack}
          style={{ width: "100%" }}
        />
      </View>
    </View>
  );
};

export default AreYouPhysiotherapist;

const styles = StyleSheet.create({
  footerContainer: {
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-start",
    marginBottom: spacing24,
  },
});
