import { FC, PropsWithChildren, useState } from "react";
import { ScrollView } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RehabilitationStatisticsStackParamsList } from "@navigators/navigation.types";
import { useErrors } from "@hooks/useErrors";
import { getAssignedTrainings } from "@api/trainings";
import { queryKeysIndividualTrainings } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { globalStyles } from "@styles/global";
import { FetchError } from "@components/errors";
import { SafeAreaView } from "react-native-safe-area-context";
import { useTranslation } from "react-i18next";
import { spacing16 } from "@styles/spacing";
import TrainingTileWithAuthorName from "@components/Tile/training/TrainingTileWithAuthorName";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { BottomTutorialComponent } from "@components/BottomSheet";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { UserCard } from "@components/cards";
import ExportStatistics from "@screens/Profiles/MyProfile/Statistics/ExportStatistics";
import { Host } from "react-native-portalize";
import { useAuth } from "@contexts/AuthContext/auth";
import { SvgWorkoutStatistics } from "@assets/svg/tutorials";
import InfoTile from "@components/Tile/InfoTile";
import { TransKey } from "@globalTypes/i18next";

type RehabilitationStatisticsProps = PropsWithChildren<
  NativeStackScreenProps<
    RehabilitationStatisticsStackParamsList,
    "RehabilitationStatistics"
  >
>;

const subtitles: TransKey[] = ["T00664", "T00665"];

const RehabilitationStatistics: FC<RehabilitationStatisticsProps> = ({
  navigation: { navigate },
  route,
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const { isPhysiotherapist } = useUserDetails();
  const {
    user: { id },
  } = useAuth();
  const patientId = route.params?.patientId;
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysIndividualTrainings.list(),
    queryFn: async () =>
      await getAssignedTrainings({ isLibrary: false, patientId }),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  if (isLoading) return <ActivityIndicator style={globalStyles.loading} />;

  if (isError) return <FetchError action={refetch} />;

  return (
    <Host>
      <SafeAreaView style={{ flex: 1 }} edges={["left", "right", "bottom"]}>
        <ScrollView
          contentContainerStyle={[
            globalStyles.gapMedium,
            globalStyles.scrollContainer,
          ]}>
          {isPhysiotherapist && (
            <UserCard initialData={{ id: patientId }} mode="outlined" />
          )}
          <ExportStatistics patientId={patientId || id} />
          <Text style={{ marginVertical: spacing16 }} variant="titleLarge">
            {t("T00505")}
          </Text>
          <TutorialTextButton
            label="T00663"
            onPress={() => setModalVisible(true)}
            subContainerStyle={{ paddingHorizontal: spacing16 }}
          />
          {data.length ? (
            data.map(({ id }) => (
              <TrainingTileWithAuthorName
                key={id}
                trainingId={id}
                onPress={() => {
                  navigate("TrainingStatistics", {
                    trainingId: id,
                    patientId,
                  });
                }}
              />
            ))
          ) : (
            <InfoTile status="info" content={<Text>{t("T01277")}</Text>} />
          )}
        </ScrollView>
        <BottomModalContainer
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}>
          <BottomTutorialComponent
            title="T00504"
            subtitles={subtitles}
            content={[{ svgComponent: () => <SvgWorkoutStatistics /> }]}
            onPress={() => setModalVisible(false)}
          />
        </BottomModalContainer>
      </SafeAreaView>
    </Host>
  );
};

export default RehabilitationStatistics;
