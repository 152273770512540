import { AnalyticsEventName } from "@globalTypes/analytics.types";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { useCallback } from "react";
import { useAnalytics } from "./useAnalytics";

export const useLogInvitationEvents = () => {
  const { handleLogEvent } = useAnalytics();
  const { id } = useUserDetails();

  const handleShowQrCodeEvent = useCallback(async () => {
    await handleLogEvent({
      eventName: AnalyticsEventName.QR_CODE_SHOWN,
      eventParams: { qr_code_owner_id: id },
    });
  }, [handleLogEvent, id]);

  const handleShareCodeEvent = useCallback(async () => {
    await handleLogEvent({
      eventName: AnalyticsEventName.SHARE_INVITATION_CLICKED,
      eventParams: { invitation_sender_id: id },
    });
  }, [handleLogEvent, id]);

  return { handleShareCodeEvent, handleShowQrCodeEvent };
};
