import { ButtonBasic } from "@components/Button/Button.types";
import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { getDate } from "@utils/date";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";

type Props = { buttonDisabled: boolean; selectedDate: Date } & Pick<
  ButtonBasic,
  "onPress" | "loading"
>;

export const DeleteAllHoursBottomSheetContent: FC<Props> = ({
  buttonDisabled,
  selectedDate,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <View style={[globalStyles.gapLarge, { marginTop: spacing16 }]}>
      <Text variant="titleMedium">
        {`${t("T00487")} ${getDate(selectedDate.toLocaleDateString())}?`}
      </Text>
      <PrimaryButton label="T00146" disabled={buttonDisabled} {...props} />
    </View>
  );
};
