import { spacing16, spacing4, spacing8 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { useMemo } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { SegmentedButtons, Text } from "react-native-paper";
import { Props } from "./SegmentedButtonsWithController.types";
import { SegmentedButtonType } from "@utils/types";

const SegmentedButtonsWithController = ({
  control,
  name,
  label,
  buttons,
  buttonsData,
  errors = [],
  defaultValue,
  ...otherProps
}: Props) => {
  const { t } = useTranslation();

  const {
    container,
    segmentedButtons,
    singleButtonStyle,
    error: styleError,
  } = styles;

  const buttonsFromData = useMemo(
    () =>
      buttonsData?.map(({ value, displayName }) => ({
        value,
        label: displayName,
        accessibilityLabel: displayName,
        showSelectedCheck: true,
      })),
    [buttonsData],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const fieldErrors = [error, ...(errors || [])].filter(i => !!i);
        const resultButtons = buttons?.length ? buttons : buttonsFromData;
        return (
          <View style={container}>
            {label && <Text variant="titleMedium">{t(label)}</Text>}
            {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/*@ts-ignore*/}
            <SegmentedButtons
              value={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value
              }
              onValueChange={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                (newValue: unknown) => {
                  onChange(newValue);
                }
              }
              style={segmentedButtons}
              buttons={resultButtons.map(
                (e: (typeof buttons)[0] | (typeof buttonsFromData)[0]) =>
                  ({
                    ...e,
                    style: resultButtons.length === 1 ? singleButtonStyle : {},
                  } as SegmentedButtonType),
              )}
              {...otherProps}
            />
            {fieldErrors?.map(({ message }, index) => (
              <Text variant="bodySmall" key={index} style={styleError}>
                {message}
              </Text>
            ))}
          </View>
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  error: {
    color: theme.colors.error,
    marginTop: spacing4,
    marginStart: spacing16,
  },
  container: {
    marginVertical: spacing4,
  },
  segmentedButtons: {
    alignItems: "center",
    marginTop: spacing8,
  },
  singleButtonStyle: {
    borderRightWidth: 1,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  },
});

export default SegmentedButtonsWithController;
