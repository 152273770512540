import { FC } from "react";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";

import { FetchError } from "@components/errors";

import { globalStyles } from "@styles/global";
import { PhysiotherapistCardWithRating } from "@components/cards";

type Props = {
  onPress: (id: number) => void;
  data: { id: number }[];
  isLoading: boolean;
  isError: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<
    QueryObserverResult<
      {
        id: number;
      }[],
      unknown
    >
  >;
};

export const PatientLatestRehabilitants: FC<Props> = ({
  onPress,
  data,
  isError,
  isLoading,
  refetch,
}) => {
  const { t } = useTranslation();

  return (
    <View style={globalStyles.gapLarge}>
      <Text variant="titleMedium">{t("T00473")}</Text>
      {isLoading ? (
        <ActivityIndicator />
      ) : isError ? (
        <FetchError action={refetch} coverScreen={false} />
      ) : data?.length > 0 ? (
        data.map(({ id }, i) => {
          return (
            <PhysiotherapistCardWithRating
              key={`my-latest-physiotherapist-${id}-${i}`}
              initialData={{ id }}
              onPress={() => onPress(id)}
            />
          );
        })
      ) : (
        <Text variant="bodyMedium">{t("T00474")}</Text>
      )}
    </View>
  );
};
