import { DETAILS, LIST } from "../../../../utils/constants";

export const queryKeysAchievements = {
  all: ["achievements"] as const,
  list: () => [...queryKeysAchievements.all, LIST] as const,
  details: (id: number) => [...queryKeysAchievements.all, DETAILS, id] as const,
};

export const queryKeysUserAchievements = {
  all: ["userAchievements"] as const,
  list: () => [...queryKeysUserAchievements.all, LIST] as const,
};
