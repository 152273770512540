import { FetchError } from "@components/errors";
import MediaWithButton from "@components/Image/MediaWithButton";
import { PreviewType } from "@globalTypes/common.types";
import {
  TabItemProps,
  usePhysiotherapist,
} from "@screens/Profiles/Physiotherapist/PhysiotherapistProfile";
import { globalStyles } from "@styles/global";
import { isPdfInFileName } from "@utils/files";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";

const CertificatesTab: FC<TabItemProps> = ({ id, navigateToPreview }) => {
  const { data, isLoading, isError, refetch } = usePhysiotherapist(id);
  const { t } = useTranslation();

  if (isLoading) return <ActivityIndicator style={globalStyles.loading} />;

  return (
    <>
      {isError ? (
        <FetchError action={refetch} coverScreen={false} />
      ) : (
        <View style={globalStyles.gapLarge}>
          {!data?.certificates?.length ? (
            <Text variant="bodyMedium">{t("T00638")}</Text>
          ) : (
            data.certificates.map(
              ({ attachment, name, attachmentThumbnail }, index) => (
                <MediaWithButton
                  imgUri={attachmentThumbnail || attachment}
                  key={`image-${name}-${index}`}
                  onPressItem={() =>
                    navigateToPreview(
                      isPdfInFileName(name) ? PreviewType.PDF : PreviewType.IMG,
                      attachment,
                    )
                  }
                  isImgThumbnail
                />
              ),
            )
          )}
        </View>
      )}
    </>
  );
};

export default CertificatesTab;
