import { AppointmentSessionContext } from "@contexts/AppointmentSessionContext/appointmentSession.context";
import { VideoViewInterface } from "../../utils/types";
import { useCallback, useContext, useLayoutEffect, useState } from "react";
import { Text, TouchableWithoutFeedback, View, ViewStyle } from "react-native";
import { CSSProperties } from "react-native-render-html";
import { getVideoStyle, SELF_VIDEO_ID } from "../../utils/helpers.web";
import { Icon } from "../icon";
import {
  videoViewStyles,
  videoViewStyles as styles,
} from "./video-view.styles";
import { ActivityIndicator } from "react-native-paper";
import { globalStyles } from "@styles/global";
import Avatar from "@components/Avatar";
import { AVATAR_SIZE } from "@utils/constants";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import OnlyPersonInfoText from "../OnlyPersonInfoText";
import { ZoomWebUser } from "../../utils/types.web";

export function VideoView(props: VideoViewInterface) {
  const {
    user: webUser,
    fullScreen,
    showName = false,
    users: webUsers = [],
    isMuted,
    videoRef,
    selfVideoCanvasRef,
  } = props;
  const user = webUser as ZoomWebUser;
  const users = webUsers as ZoomWebUser[];
  const audioStatusIcon = isMuted && "muted";
  const {
    zmClient,
    mediaContext: { mediaStream },
    isTabsVisible,
    data: { displayInFloatingView },
  } = useContext(AppointmentSessionContext);
  const [loading, setLoading] = useState(true);
  const { width, height } = useWindowDimensions();

  const isCurrentUserStartedVideo = zmClient.getCurrentUserInfo()?.bVideoOn;

  const videoStyle = getVideoStyle(
    users.length === 1,
    isCurrentUserStartedVideo,
  ) as CSSProperties;

  const renderAvatar = useCallback(
    (video: "SMALL" | "LARGE") => {
      const avatarLargeContainerStyle: ViewStyle = {
        position: "absolute",
        top: height / (isTabsVisible ? 4 : 2) - AVATAR_SIZE,
        left: width / 2 - AVATAR_SIZE,
      };
      if (users.length === 1 && !users[0].bVideoOn && video === "LARGE")
        return (
          <View style={avatarLargeContainerStyle}>
            <Avatar
              userId={users[0].apiUserId}
              isPatient={!users[0].isUserPhysio}
              containerStyle={videoViewStyles.avatarLarge}
            />
          </View>
        );
      if (users.length === 2) {
        if (!users[0].bVideoOn && video === "SMALL")
          return (
            <View style={[videoViewStyles.smallCameraWindow]}>
              <Avatar
                userId={users[0].apiUserId}
                isPatient={!users[0].isUserPhysio}
              />
            </View>
          );
        if (!users[1].bVideoOn && video === "LARGE")
          return (
            <View style={avatarLargeContainerStyle}>
              <Avatar
                userId={users[1].apiUserId}
                isPatient={!users[1].isUserPhysio}
                containerStyle={videoViewStyles.avatarLarge}
              />
            </View>
          );
      }

      return null;
    },
    [height, isTabsVisible, users, width],
  );

  useLayoutEffect(() => {
    setLoading(false);
  }, []);

  if (loading) return <ActivityIndicator style={globalStyles.loading} />;

  if (displayInFloatingView) {
    if (!users.length) return null;
    return (
      <View style={videoViewStyles.floatingAvatarContainerWeb}>
        {users.length === 1 ? (
          <>
            <Avatar
              userId={users[0].apiUserId}
              isPatient={!users[0].isUserPhysio}
              style={videoViewStyles.floatingAvatarWeb}
            />
            <View style={{ maxWidth: "50%" }}>
              <OnlyPersonInfoText isInWebFloatingWindow />
            </View>
          </>
        ) : (
          <>
            <Avatar
              userId={users[0].apiUserId}
              isPatient={!users[0].isUserPhysio}
              key="zoomWebUser1"
              style={videoViewStyles.floatingAvatarWeb}
            />
            <Avatar
              userId={users[1].apiUserId}
              isPatient={!users[1].isUserPhysio}
              style={videoViewStyles.floatingAvatarWeb}
              key="zoomWebUser2"
            />
          </>
        )}
      </View>
    );
  }

  return (
    <TouchableWithoutFeedback onPress={() => {}}>
      <View style={{ flex: 1 }}>
        {mediaStream?.isRenderSelfViewWithVideoElement() ? (
          <>
            <video
              autoPlay
              muted
              playsInline
              id={SELF_VIDEO_ID}
              style={videoStyle}
            />
            {renderAvatar("SMALL")}
          </>
        ) : (
          <>
            <canvas
              id={SELF_VIDEO_ID}
              style={videoStyle}
              ref={selfVideoCanvasRef}
            />
            {renderAvatar("SMALL")}
          </>
        )}

        <div style={styles.zoomView}>
          <canvas
            id={`video-canvas-${user.displayName}`}
            ref={videoRef}
            style={styles.zoomView}
          />
          {renderAvatar("LARGE")}
        </div>

        {!fullScreen && audioStatusIcon && (
          <View style={styles.userInfo}>
            {showName && (
              <Text style={styles.userName}>
                {user.displayName ?? "Unknown"}
              </Text>
            )}
            {audioStatusIcon && (
              <Icon style={styles.audioStatusIcon} name={audioStatusIcon} />
            )}
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
}
