import { SvgTrainingOption } from "@assets/svg";
import { globalStyles } from "@styles/global";
import { theme } from "@styles/theme";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Card, Text } from "react-native-paper";
import { TrainingTileWithoutTrainingIdProps } from "./trainingTile.types";

const TrainingTileWithoutTrainingId: FC<TrainingTileWithoutTrainingIdProps> = ({
  data,
  mode = "outlined",
  elevation,
  ...props
}) => {
  const { name, exercises } = data;
  const { t } = useTranslation();
  const { gapLarge, gapSmall, flex } = globalStyles;
  const { containerOutlined, cardContentStyle } = styles;
  return (
    <Card
      {...props}
      mode={mode}
      elevation={elevation as never}
      style={mode === "outlined" && containerOutlined}>
      <Card.Content>
        <View style={[cardContentStyle, gapLarge]}>
          <View>
            <SvgTrainingOption width={40} height={40} />
          </View>
          <View style={[gapSmall, flex]}>
            <Text variant="titleMedium" numberOfLines={1}>
              {name}
            </Text>
            <Text variant="bodyMedium" numberOfLines={4}>
              {t("T00183", {
                number: exercises.length,
              })}
            </Text>
          </View>
        </View>
      </Card.Content>
    </Card>
  );
};

export default TrainingTileWithoutTrainingId;

const styles = StyleSheet.create({
  cardContentStyle: {
    flexDirection: "row",
    alignItems: "center",
  },
  containerOutlined: {
    borderColor: theme.colors.outlineVariant,
  },
});
