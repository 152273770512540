export function removeNullFields(
  obj: Record<string, string | number | object | null | undefined>,
): void {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    }
  }
}

export function removeEmptyArraysFromObject(
  obj: Record<string, string | number | object | null | undefined>,
): void {
  for (const key in obj) {
    if (Array.isArray(obj[key]) && !(obj[key] as Array<unknown>).length) {
      delete obj[key];
    }
  }
}
