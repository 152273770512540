import {
  ChipProps,
  UpcomingDatesSection,
  chipsStyles,
} from "@components/UpcomingDatesSection";
import { CustomAppointmentCard } from "@components/cards";
import UpcomingAppointmentCard from "@components/cards/UpcomingAppointmentCard";
import { RootStackParamList } from "@navigators/navigation.types";
import { NavigationProp, useNavigation } from "@react-navigation/native";

import { Appointment } from "@screens/Appointments/appointment.types";
import { globalStyles } from "@styles/global";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Chip, Text } from "react-native-paper";

type Props = {
  data: Appointment[];
  onPressAppointment: (id: number) => void;
};

export const NextAppointmentsSection: FC<Props> = ({
  data: appointments,
  onPressAppointment,
}) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { chipContainer, chipText } = chipsStyles;
  const commonChipsProps: ChipProps = {
    style: { ...chipContainer },
    textStyle: { ...chipText },
  };

  const data = appointments.filter(
    ({ dateTo }) => new Date(dateTo).getTime() > new Date().getTime(),
  );

  const onPressCustomAppointment = (appointmentId: number) =>
    navigate("CustomAppointmentDetails", {
      appointmentId,
    });

  const filteredData = useMemo(() => data?.filter(el => !el.cancelled), [data]);
  const firstCardProps = {
    upcomingAppointmentData: filteredData[0],
    onPress: () =>
      filteredData[0]?.name
        ? onPressCustomAppointment(filteredData[0].id)
        : onPressAppointment(filteredData[0].id),
  };
  const secondCardProps = {
    upcomingAppointmentData: filteredData[1],
    onPress: () =>
      filteredData[1]?.name
        ? onPressCustomAppointment(filteredData[1].id)
        : onPressAppointment(filteredData[1].id),
  };

  return (
    <View style={globalStyles.gapMedium}>
      <Text variant="titleMedium">{`${t("T01008")}:`}</Text>
      {!filteredData.length ? (
        <Chip {...commonChipsProps}>{t("T01007")}</Chip>
      ) : (
        <UpcomingDatesSection
          data={filteredData}
          firstCard={
            filteredData[0]?.name ? (
              <CustomAppointmentCard {...firstCardProps} />
            ) : (
              <UpcomingAppointmentCard {...firstCardProps} />
            )
          }
          secondCard={
            filteredData.length > 1 &&
            (filteredData[1]?.name ? (
              <CustomAppointmentCard {...secondCardProps} />
            ) : (
              <UpcomingAppointmentCard {...secondCardProps} />
            ))
          }
        />
      )}
    </View>
  );
};
