import { SvgHeaderLogo } from "@assets/svg";
import { FC } from "react";
import { Appbar } from "react-native-paper";
import { spacing48, spacing8 } from "../../styles/spacing";
import { TitleWithLogo } from "./Appbar.types";

const AppbarTitleWithLogo: FC<TitleWithLogo> = ({ title }) => {
  return (
    <Appbar.Header elevated mode="center-aligned">
      <Appbar.Action
        icon={SvgHeaderLogo}
        size={spacing48}
        style={{ marginStart: -spacing8 }}
      />
      <Appbar.Content title={title} style={{ marginStart: -spacing8 }} />
    </Appbar.Header>
  );
};

export default AppbarTitleWithLogo;
