/* eslint-disable react-hooks/exhaustive-deps */
import TutorialTextButton from "@components/Button/TutorialTextButton";
import TwoPartsText from "@components/Text/TwoPartsText";
import InfoTile from "@components/Tile/InfoTile";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { useAuth } from "@contexts/AuthContext/auth";
import { useErrors } from "@hooks/useErrors";
import {
  ProfileCompletionStackParamList,
  ProfileStackParamsList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  getOnlinePaymentMethods,
  updateOnlinePaymentMethods,
} from "@services/ApiService/verifications";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing32, spacing8 } from "@styles/spacing";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { showAlert } from "@utils/showAlert";
import { AxiosError } from "axios";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Alert,
  AppState,
  Linking,
  RefreshControl,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { ActivityIndicator, Switch, Text } from "react-native-paper";

import { showProfileVerificationSnackbar } from "@components/snackbars";
import { openAuthSessionAsync, openBrowserAsync } from "expo-web-browser";
import {
  ComissionTutorial,
  CurrencyInfoTutorial,
  OnlinePaymentsTutorial,
  RefundTutorial,
} from "..";
import { queryKeysVerficiations } from "../queryKeysPhysiotherapistVerification";
import {
  OnlinePaymentMethods,
  OnlinePaymentMethodsResponse,
} from "../verification.types";
import { useInvalidateProfileDetails } from "@hooks/index";

type OnlinePaymentsScreenType = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<ProfileCompletionStackParamList, "OnlinePayments">,
    NativeStackScreenProps<ProfileStackParamsList, "Profile">
  >
>;

const OnlinePayments: FC<OnlinePaymentsScreenType> = () => {
  const [refreshing, setRefreshing] = useState(false);

  const [onlinePaymentsTutorialVisible, setOnlinePaymentsTutorialVisible] =
    useState(false);
  const [commissionTutorialVisible, setCommissionTutorialVisible] =
    useState(false);
  const [currencyInfoTutorialVisible, setCurrencyInfoTutorialVisible] =
    useState(false);
  const [refundModalVisible, setRefundModalVisible] = useState(false);

  const {
    data: onlinePaymentMethodsData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: queryKeysVerficiations.onlinePaymentMethods(),
    queryFn: async () => await getOnlinePaymentMethods(),
  });

  const [stationaryPaymentsSwitchOn, setStationaryPaymentsSwitchOn] = useState(
    onlinePaymentMethodsData?.[0].stationaryAppointments,
  );
  const [onlinePaymentsSwitchOn, setOnlinePaymentsSwitchOn] = useState(
    onlinePaymentMethodsData?.[0].onlineAppointments,
  );

  const { setErrorsFromResponse } = useErrors();

  const queryClient = useQueryClient();

  const onMutationSuccess = async () => {
    await Promise.all([
      queryClient.invalidateQueries(
        queryKeysVerficiations.onlinePaymentMethods(),
      ),
      queryClient.invalidateQueries(
        queryKeysVerficiations.verificationProgress(),
      ),
      queryClient.invalidateQueries(
        queryKeysVerficiations.verificationProgressRehabInSubscreens(),
      ),
    ]);
    showProfileVerificationSnackbar(t("T01311"));
  };

  const { mutate } = useMutation({
    mutationFn: async (data: OnlinePaymentMethods) => {
      return await updateOnlinePaymentMethods(
        onlinePaymentMethodsData?.[0].id,
        data,
      );
    },
    onSuccess: onMutationSuccess,
    onError: ({ response }: AxiosError<OnlinePaymentMethodsResponse>) => {
      setErrorsFromResponse(response);
    },
  });

  const updateOnlinePaymentsForStationaryAppointments = useCallback(() => {
    if (!stripeActive) return showStripeAlert();
    setStationaryPaymentsSwitchOn(prev => {
      mutate({
        stationaryAppointments: !prev,
      });
      return !prev;
    });
  }, [mutate, onlinePaymentMethodsData]);

  const updateOnlinePaymentsForOnlineAppointments = useCallback(() => {
    if (!stripeActive) return showStripeAlert();
    setOnlinePaymentsSwitchOn(prev => {
      mutate({
        onlineAppointments: !prev,
      });
      return !prev;
    });
  }, [[mutate, onlinePaymentMethodsData]]);

  const appState = useRef(AppState.currentState);
  const { t } = useTranslation();

  const showStripeAlert = useCallback(
    () =>
      Alert.alert(t("T01104"), t("T01105"), [
        { text: t("T00163"), style: "cancel" },
      ]),
    [t],
  );

  const {
    user: {
      stripeAccountRegisterUrl,
      stripeActive,
      stripeDashboardUrl,
      currency,
      stripeAccountId,
    },
    updateUser,
  } = useAuth();

  const { invalidateProfileDetails } = useInvalidateProfileDetails();
  const { scrollContainer, gapLarge, gapMedium } = globalStyles;
  const { switchContainer, textContainer } = styles;
  useEffect(() => {
    if (stripeActive) return;

    const subscription = AppState.addEventListener("change", nextAppState => {
      if (appState.current === "background" && nextAppState === "active")
        updateUser();
      void invalidateProfileDetails();

      appState.current = nextAppState;
    });

    const updateInterval = setInterval(() => updateUser(), 15000);

    return () => {
      subscription.remove();
      clearInterval(updateInterval);
    };
  }, [stripeActive]);

  const togglePayments = async () => {
    if (!stripeAccountRegisterUrl)
      return showAlert(`${t("T00838")} ${t("T00839")}`);
    const webBrowserReturn = stripeActive
      ? await openBrowserAsync(stripeDashboardUrl)
      : await openAuthSessionAsync(stripeAccountRegisterUrl);

    const { type } = webBrowserReturn;

    if (type === "success" && webBrowserReturn?.url) {
      updateUser();
      return Linking.openURL(webBrowserReturn.url);
    }
  };

  const refreshUserData = () => {
    setRefreshing(true);
    updateUser();
    void invalidateProfileDetails();
    setRefreshing(false);
  };

  return (
    <>
      <ScrollView
        style={[scrollContainer]}
        contentContainerStyle={[gapLarge, { paddingBottom: spacing32 }]}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={refreshUserData} />
        }>
        {isLoading ? (
          <ActivityIndicator style={globalStyles.loading} />
        ) : isError ? (
          <FetchError action={refetch} />
        ) : (
          <>
            <InfoTile
              status={
                onlinePaymentMethodsData?.[0].stationaryAppointments
                  ? "success"
                  : "info"
              }
              content={
                <Text variant="bodyMedium">
                  {onlinePaymentMethodsData?.[0].stationaryAppointments ? (
                    <Trans
                      i18nKey="T01100"
                      components={{
                        1: <Text style={{ fontWeight: "bold" }}>can</Text>,
                      }}
                    />
                  ) : (
                    <Trans
                      i18nKey="T01099"
                      components={{
                        1: <Text style={{ fontWeight: "bold" }}>cannot</Text>,
                      }}
                    />
                  )}
                </Text>
              }
            />
            <InfoTile
              status={
                onlinePaymentMethodsData?.[0].onlineAppointments
                  ? "success"
                  : "info"
              }
              content={
                <Text variant="bodyMedium">
                  {onlinePaymentMethodsData?.[0].onlineAppointments ? (
                    <Trans
                      i18nKey="T01102"
                      components={{
                        1: <Text style={{ fontWeight: "bold" }}>can</Text>,
                      }}
                    />
                  ) : (
                    <Trans
                      i18nKey="T01101"
                      components={{
                        1: <Text style={{ fontWeight: "bold" }}>cannot</Text>,
                      }}
                    />
                  )}
                </Text>
              }
            />
            <Text variant="titleMedium">{t("T01103")}</Text>

            <View style={switchContainer}>
              <Text variant="bodyLarge" style={textContainer}>
                {t("T01106")}
              </Text>
              <Switch
                value={stationaryPaymentsSwitchOn}
                onValueChange={updateOnlinePaymentsForStationaryAppointments}
              />
            </View>
            <View style={switchContainer}>
              <Text variant="bodyLarge" style={textContainer}>
                {t("T01107")}
              </Text>
              <Switch
                value={onlinePaymentsSwitchOn}
                onValueChange={updateOnlinePaymentsForOnlineAppointments}
              />
            </View>

            <View style={gapMedium}>
              <Text variant="titleMedium">{t("T00713")}:</Text>
              {stripeActive ? (
                <>
                  <TwoPartsText leftText="T00717" rightText={currency} />
                  <TwoPartsText leftText="T00787" rightText={stripeAccountId} />
                </>
              ) : (
                <Text
                  variant="bodyMedium"
                  style={{ color: palettes.error[40] }}>
                  {t("T00714")}
                </Text>
              )}
            </View>
            <PrimaryButton
              label={`${stripeActive ? "T00716" : "T00715"}`}
              onPress={togglePayments}
            />
          </>
        )}
        <>
          <TutorialTextButton
            label="T01108"
            onPress={() => setOnlinePaymentsTutorialVisible(true)}
            ignoreTextStyle
          />
          <OnlinePaymentsTutorial
            modalVisible={onlinePaymentsTutorialVisible}
            setModalVisible={setOnlinePaymentsTutorialVisible}
          />
          <TutorialTextButton
            label="T01115"
            onPress={() => setCommissionTutorialVisible(true)}
            ignoreTextStyle
          />
          <ComissionTutorial
            modalVisible={commissionTutorialVisible}
            setModalVisible={setCommissionTutorialVisible}
          />
          <TutorialTextButton
            label="T01116"
            onPress={() => setCurrencyInfoTutorialVisible(true)}
            ignoreTextStyle
          />
          <CurrencyInfoTutorial
            modalVisible={currencyInfoTutorialVisible}
            setModalVisible={setCurrencyInfoTutorialVisible}
          />
          <TutorialTextButton
            label="T01267"
            onPress={() => setRefundModalVisible(true)}
          />
          <RefundTutorial
            modalVisible={refundModalVisible}
            setModalVisible={setRefundModalVisible}
          />
        </>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  switchContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: spacing8,
    padding: spacing8,
    alignItems: "center",
  },
  textContainer: {
    maxWidth: "80%",
  },
});

export default OnlinePayments;
