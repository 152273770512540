import { FC } from "react";
import AppbarTitleWithBackAction from "./AppbarTitleWithBackAction";
import { handleBackToTop } from "@utils/navigation.helpers";
import { ParamListBase } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { TitleWithAction } from "./Appbar.types";

type AppbarTitleWithBackActionProps = TitleWithAction & {
  navigation: NativeStackNavigationProp<ParamListBase, string, undefined>;
};

const AppbarTitleWithDefaultBackAction: FC<AppbarTitleWithBackActionProps> = ({
  navigation,
  ...props
}) => {
  return (
    <AppbarTitleWithBackAction
      onClose={() => handleBackToTop(navigation)}
      onBack={navigation.goBack}
      {...props}
    />
  );
};

export default AppbarTitleWithDefaultBackAction;
