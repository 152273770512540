import { useErrors } from "@hooks/useErrors";
import { useAssistant } from "@hooks/user/useAssistant";
import { queryKeysMessagesBlacklist } from "@screens/MessageCenter/queryKeysMessageCenter";
import { getMessagesBlacklist } from "@services/ApiService/users";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";

export const useUserBlacklistCheck = ({ userId }: { userId: number }) => {
  const [isUserOnBlacklist, setIsUserOnBlacklist] = useState<boolean>(null);
  const { assistantId } = useAssistant(userId);
  const queryEnabled = !!userId && assistantId !== userId;
  const { error, isSuccess } = useQuery<object, AxiosError>({
    queryKey: queryKeysMessagesBlacklist.details(userId),
    queryFn: async () => await getMessagesBlacklist(userId),
    enabled: queryEnabled,
    retry: 0,
  });
  const { setErrorsFromResponse } = useErrors();

  useEffect(() => {
    if (error?.response) {
      if (error.response.status === 404) {
        setIsUserOnBlacklist(false);
      } else {
        setErrorsFromResponse(error.response);
      }
    }
    if (isSuccess) setIsUserOnBlacklist(true);
  }, [error?.response, isSuccess, setErrorsFromResponse]);

  return isUserOnBlacklist;
};
