import Svg, { SvgProps, Circle, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={48} height={48} fill="none" {...props}>
    <Circle cx={24.5} cy={23.5} r={11.5} fill="#fff" />
    <Path
      fill="#78767A"
      d="M24 4C12.96 4 4 12.96 4 24s8.96 20 20 20 20-8.96 20-20S35.04 4 24 4Zm8 22h-6v6c0 1.1-.9 2-2 2s-2-.9-2-2v-6h-6c-1.1 0-2-.9-2-2s.9-2 2-2h6v-6c0-1.1.9-2 2-2s2 .9 2 2v6h6c1.1 0 2 .9 2 2s-.9 2-2 2Z"
    />
  </Svg>
);
export default SvgComponent;
