import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { AvailabilityGroup } from "@components/PhysioAvailability/PhysioAvailability.types";
import { PrimaryButton } from "@components/buttons";
import { useAuth } from "@contexts/AuthContext/auth";
import { useErrors } from "@hooks/useErrors";
import { queryKeysPhysiotherapist } from "@screens/Appointments/queryKeysAppointments";
import AvailabilityTile from "@screens/Calendar/AvailabilityTile";
import { deletePhysiotherapistAvailabilityGroup } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showAlert } from "@utils/showAlert";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch, FC, SetStateAction } from "react";
import { View } from "react-native";
import { showSnackbar } from "@utils/snackbarHelper";
import { useTranslation } from "react-i18next";

type DeleteAvailabilityRecordBottomSheetProps = {
  groupDetails: AvailabilityGroup;
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
};

export const DeleteAvailabilityRecordBottomSheet: FC<
  DeleteAvailabilityRecordBottomSheetProps
> = ({ groupDetails, modalVisible, setModalVisible }) => {
  const slotsToDelete = groupDetails?.hours.map(({ id }) => id);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const {
    user: { id },
  } = useAuth();
  const { mutate, isLoading } = useMutation({
    mutationFn: () => deletePhysiotherapistAvailabilityGroup(slotsToDelete),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async ({ data: { detail: alertMessage } }: AxiosResponse) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeysPhysiotherapist.availability(id),
      }),
        setModalVisible(false);
      alertMessage
        ? showAlert(alertMessage as string)
        : showSnackbar({
            message: t("T01519"),
          });
    },
  });
  return (
    <BottomModalContainer
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}>
      <View style={globalStyles.gapLarge}>
        <AvailabilityTile data={groupDetails} mode="contained" />
        <PrimaryButton
          mode="outlined"
          label="T00146"
          onPress={() => mutate()}
          loading={isLoading}
          disabled={isLoading}
        />
      </View>
    </BottomModalContainer>
  );
};
