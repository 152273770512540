import { StyleSheet, View } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { PrimaryButton } from "@components/buttons";
import { Logo } from "@components/Logo";

import { RootStackParamList } from "@navigators/navigation.types";
import { globalStyles } from "@styles/global";
import { OnBoardingSlider } from "@components/Slider";
import { Text } from "react-native-paper";
import { config } from "@utils/config";

function InitialScreen({
  navigation,
}: NativeStackScreenProps<RootStackParamList, "Initial">) {
  const { container, gapLarge } = globalStyles;
  const { logoContainer } = styles;

  return (
    <View style={[container, gapLarge]}>
      <View style={logoContainer}>
        <Logo />
      </View>
      <OnBoardingSlider />
      <View style={[gapLarge, { flex: 1 }]}>
        <PrimaryButton
          label="T00003"
          mode="contained"
          onPress={() => navigation.navigate("SignUp")}
        />
        <PrimaryButton
          label="T00044"
          mode="outlined"
          onPress={() => navigation.navigate("SignIn")}
        />
      </View>
      {__DEV__ && (
        <Text>{`Mode: ${config.EXPO_PUBLIC_ENV} | ${config.EXPO_PUBLIC_API_BASE_URL}`}</Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  logoContainer: { flex: 1, justifyContent: "flex-end" },
});

export default InitialScreen;
