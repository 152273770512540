import { useAuth } from "@contexts/AuthContext/auth";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DateTimePicker, {
  DateTimePickerEvent,
} from "@react-native-community/datetimepicker";
import { isIOS } from "@utils/constants";
import { TimePickerModal, registerTranslation } from "react-native-paper-dates";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";
import { getTime } from "@utils/date";
import { palettes } from "@styles/colors";
import { useLocale } from "@hooks/useLocale";
import { getTranslationObject } from "./pickerHelpers";

type MinuteInterval = 1 | 2 | 3 | 4 | 5 | 6 | 10 | 12 | 15 | 20 | 30;

type Props = {
  visible: boolean;
  visibleWebModal: boolean;
  onDismiss?: () => void;
  onConfirm: ({ hours, minutes }: { hours: number; minutes: number }) => void;
  hours: number;
  minutes: number;
  value: Date;
  minimumDate?: Date;
  onChange: (_: DateTimePickerEvent, selectedDate: Date) => void;
  onPress: () => void;
  timeIntervalIOS?: MinuteInterval;
};

export const TimePicker: FC<Props> = ({
  visible,
  visibleWebModal,
  onDismiss,
  onConfirm,
  hours,
  minutes,
  value,
  onChange,
  minimumDate,
  onPress,
  timeIntervalIOS = 15,
}) => {
  const { t } = useTranslation();
  const {
    user: { timezone },
  } = useAuth();
  const { locale } = useLocale();

  const translationObject = useMemo(
    () => getTranslationObject(locale?.code),
    [locale?.code],
  );

  useEffect(() => {
    registerTranslation(locale?.code, translationObject);
  }, [locale?.code, translationObject]);

  const { androidButtonContainer, androidButtonText } = styles;
  return (
    <>
      {!isIOS && (
        <TouchableOpacity onPress={onPress} style={androidButtonContainer}>
          <Text variant="titleMedium" style={androidButtonText}>
            {getTime(value, locale)}
          </Text>
        </TouchableOpacity>
      )}
      {!isIOS ? (
        <TimePickerModal
          visible={visibleWebModal}
          onDismiss={onDismiss}
          onConfirm={onConfirm}
          hours={hours}
          minutes={minutes}
          defaultInputType="keyboard"
          cancelLabel={t("T00145")}
          confirmLabel={t("T00163")}
          label={t("T00988")}
          locale={locale?.code}
        />
      ) : visible ? (
        <DateTimePicker
          value={value}
          mode="time"
          onChange={onChange}
          minimumDate={minimumDate}
          minuteInterval={timeIntervalIOS}
          timeZoneName={timezone}
        />
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  androidButtonContainer: {
    backgroundColor: "#7676801F",
    padding: 6,
    borderRadius: 6,
    width: "25%",
  },
  androidButtonText: { textAlign: "center", color: palettes.primary[40] },
});
