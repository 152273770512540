import { FetchError } from "@components/errors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { PushNotificationsSettingsStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { FC, PropsWithChildren } from "react";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import SwitchSection from "./SwitchSection";
import { useMessagesNotificationsSettings } from "@hooks/notifications/useMessagesNotificationsSettings";

type MessagesPushSettingsProps = PropsWithChildren<
  NativeStackScreenProps<PushNotificationsSettingsStackParamsList, "Messages">
>;

const Messages: FC<MessagesPushSettingsProps> = () => {
  const { isPhysiotherapist } = useUserDetails();
  const {
    isError,
    isLoading,
    interlocutorMessagesSwitchOn,
    assistantMessagesSwitchOn,
    refetch,
    onAssistantMessagesSwitchChange,
    onInterlocutorMessagesSwitchChange,
  } = useMessagesNotificationsSettings();
  const { container, gapLarge, loading } = globalStyles;

  if (isLoading) return <ActivityIndicator style={loading} />;

  if (isError) return <FetchError action={refetch} />;

  return (
    <View style={[container, gapLarge]}>
      <SwitchSection
        switchOn={interlocutorMessagesSwitchOn}
        onChange={onInterlocutorMessagesSwitchChange}
        title={isPhysiotherapist ? "T01470" : "T01469"}
        description={isPhysiotherapist ? "T01472" : "T01471"}
      />
      <SwitchSection
        switchOn={assistantMessagesSwitchOn}
        onChange={onAssistantMessagesSwitchChange}
        title="T01473"
        description="T01474"
      />
    </View>
  );
};

export default Messages;
