import {
  AppointmentsNotificationsSettingsOptions,
  RadioButtonDataType,
} from "@globalTypes/messagesNotificationSetting";
import { padZero } from "@utils/date";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { format } from "date-fns";
import { TFunction } from "i18next";

type TimeConversionType = "utc" | "local";

export const turnOnMainNotificationAlert = (
  t: TFunction,
  onConfirm: () => void,
) =>
  showAlertWithCustomButtons({
    title: t("T01127"),
    message: t("T01128"),
    cancelButton: {
      text: t("T00145"),
    },
    confirmButton: {
      text: t("T00575"),
      onPress: onConfirm,
    },
  });

export const systemPushPermissionDeniedAlert = (
  t: TFunction,
  onConfirm: () => void,
) => {
  showAlertWithCustomButtons({
    title: t("T01127"),
    message: t("T01488"),
    cancelButton: {
      text: t("T00145"),
    },
    confirmButton: {
      text: t("T00002"),
      onPress: onConfirm,
    },
  });
};

export const transformAppointmentsNotificationsSettingsToArray = (
  obj: AppointmentsNotificationsSettingsOptions,
): RadioButtonDataType[] =>
  (Object.keys(obj) as unknown as Array<keyof typeof obj>).map(key => ({
    value: key.toString(),
    label: obj[key],
  }));

export const getUserNotificationTime = (
  notificationTime: number,
  data: RadioButtonDataType[],
): RadioButtonDataType | undefined =>
  data.find(({ value }) => value === notificationTime.toString());

export const extractHoursAndMinutes = (timeString: string) => {
  const [hours, minutes] = timeString.split(":");
  return { hours, minutes };
};

export const createDateFromHoursAndMinutes = (timeString: string | Date) => {
  let date: Date;
  if (typeof timeString === "string") {
    const [hours, minutes, seconds] = timeString.split(":");
    date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes), parseInt(seconds), 0);
  } else {
    date = new Date(timeString);
  }
  return date;
};

export const convertDateToNotificationHour = (date: Date): string => {
  const hours: number = date.getHours();
  const minutes: number = date.getMinutes();
  const seconds: number = date.getSeconds();

  const formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
    seconds,
  )}`;

  return formattedTime;
};

export const convertNotificationTime = (
  time: string,
  convertTo: TimeConversionType,
) => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  const date = new Date();
  if (convertTo === "utc") {
    date.setHours(hours, minutes, seconds, 0);
    const utcHours = date.getUTCHours();
    const utcMinutes = date.getUTCMinutes();
    const utcSeconds = date.getUTCSeconds();
    return `${padZero(utcHours)}:${padZero(utcMinutes)}:${padZero(utcSeconds)}`;
  } else {
    date.setUTCHours(hours, minutes, seconds, 0);
    const localHours = date.getHours();
    const localMinutes = date.getMinutes();
    const localSeconds = date.getSeconds();
    return `${padZero(localHours)}:${padZero(localMinutes)}:${padZero(
      localSeconds,
    )}`;
  }
};

export const convertTimeToOrFromUTC = <T>(
  data: T | { [key: string]: string },
  convertTo: TimeConversionType,
) =>
  Object.keys(data).reduce((acc, key) => {
    acc[key] = convertNotificationTime(data[key] as string, convertTo);
    return acc;
  }, {} as T);

export const checkIfUserHasDifferentNotificationHoursForDays = (
  notificationTimes: string[],
): boolean => {
  const firstElement = notificationTimes[0];
  return notificationTimes.some(el => el !== firstElement);
};

export const getTodaysWeekDayName = () => {
  const today = new Date();
  return format(today, "EEEE");
};
