import { updateHeaders } from "@api/auth";
import AppLanguageDropdownPicker from "@components/DropdownPicker/AppLanguageDropdownPicker";
import CurrencyDropdownPicker from "@components/DropdownPicker/CurrencyDropdownPicker";
import { PickerItem } from "@components/DropdownPicker/DropdownPicker";
import TimezoneDropdownPicker from "@components/DropdownPicker/TimezoneDropdownPicker";
import InfoTile from "@components/Tile/InfoTile";
import { PrimaryButton } from "@components/buttons";
import { useAuth } from "@contexts/AuthContext/auth";
import { LocaleHeaders } from "@contexts/AuthContext/user.types";
import { useErrors } from "@hooks/useErrors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  changePhysiotherapistSettings,
  getPhysiotherapistSettings,
} from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { theme } from "@styles/theme";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { config } from "@utils/config";
import { isWeb } from "@utils/constants";
import { getLang } from "@utils/lang";
import { storageManager } from "@utils/storageManager";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Linking, ScrollView, View } from "react-native";
import {
  ActivityIndicator,
  IconButton,
  Switch,
  Text,
} from "react-native-paper";
import { queryKeysSettings } from "../queryKeysSettings";
import { settingsStyles } from "./settings.styles";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { useHeaderHeight } from "@react-navigation/elements";

type ApplicationSettingsProps = PropsWithChildren<
  NativeStackScreenProps<RootStackParamList, "ApplicationSettings">
>;

type PickerType = "Language" | "Timezone" | "Currency";

export type OnSelectItem = (item: PickerItem, pickerType?: PickerType) => void;

const ApplicationSettings: FC<ApplicationSettingsProps> = ({
  navigation: { navigate },
}) => {
  const { t, i18n } = useTranslation();
  const { scrollContainer, gapLarge, gapMedium } = globalStyles;
  const { isPhysiotherapist } = useUserDetails();
  const {
    updateUser,
    user: { stripeDashboardUrl },
  } = useAuth();
  const queryClient = useQueryClient();
  const [isMessagesSwitchOn, setIsMessagesSwitchOn] = useState(false);
  const [dropdownLanguageOpen, setDropdownLanguageOpen] = useState(false);
  const [dropdownTimezoneOpen, setDropdownTimezoneOpen] = useState(false);
  const [dropdownCurrencyOpen, setDropdownCurrencyOpen] = useState(false);
  const { setErrorsFromResponse } = useErrors();
  const { height } = useWindowDimensions();
  const headerHeight = useHeaderHeight();

  const {
    data,
    isLoading: isSettingsLoading,
    isError: isSettingsError,
    refetch: refetchSettings,
  } = useQuery({
    queryFn: getPhysiotherapistSettings,
    queryKey: queryKeysSettings.physiotherapistSettings(),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: data => setIsMessagesSwitchOn(data[0].messagesEnabled),
    enabled: isPhysiotherapist,
  });

  const { mutate } = useMutation({
    mutationFn: () => {
      setIsMessagesSwitchOn(prevState => !prevState);
      return changePhysiotherapistSettings(data[0].id, {
        messagesEnabled: !isMessagesSwitchOn,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeysSettings.physiotherapistSettings(),
      });
    },
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const { mutate: updateHeader, isLoading: isUpdating } = useMutation({
    mutationFn: async (headers: LocaleHeaders) => {
      const token = await storageManager.getItem("refreshToken");
      await updateHeaders(token, headers);
    },
    onSuccess: updateUser,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const physioInfos = [t("T00507"), t("T00508"), t("T00509")];

  const onSelectItem: OnSelectItem = (item, pickerType) =>
    updateHeader({
      [pickerType]: item.value,
    });

  const selectItem = async (item: PickerItem) => {
    onSelectItem(item, "Language");
    await i18n.changeLanguage(getLang(item.value));
    queryClient.clear();
  };

  const changePassword = async () => {
    const url = `${config.EXPO_PUBLIC_API_BASE_URL}/accounts/password/reset/`;

    if (isWeb) {
      await Linking.openURL(url);
    } else {
      navigate("ForgotPasswordWebView", {
        uri: url,
      });
    }
  };

  const closeAllDropdowns = useCallback(() => {
    setDropdownTimezoneOpen(false);
    setDropdownCurrencyOpen(false);
    setDropdownLanguageOpen(false);
  }, []);

  if (isUpdating) return <ActivityIndicator style={globalStyles.loading} />;

  const currencyChangeEnabled =
    isPhysiotherapist && !!stripeDashboardUrl?.length;

  return (
    <ScrollView
      contentContainerStyle={[
        scrollContainer,
        gapLarge,
        { minHeight: height - headerHeight },
      ]}>
      <View style={gapLarge}>
        <Text variant="titleMedium">{t("T00697")}</Text>
        <AppLanguageDropdownPicker
          onSelectItem={selectItem}
          onOpen={() => {
            closeAllDropdowns();
            setDropdownLanguageOpen(true);
          }}
          onClose={() => setDropdownLanguageOpen(false)}
          isDropdownOpen={dropdownLanguageOpen}
          multiDropdownContent
        />

        <TimezoneDropdownPicker
          onSelectItem={item => onSelectItem(item, "Timezone")}
          onOpen={() => {
            closeAllDropdowns();
            setDropdownTimezoneOpen(true);
          }}
          onClose={() => setDropdownTimezoneOpen(false)}
          isDropdownOpen={dropdownTimezoneOpen}
          multiDropdownContent
        />
        <Text variant="titleMedium">{t("T00699")}</Text>
        {currencyChangeEnabled && (
          <InfoTile status="info" content={<Text>{t("T00880")}</Text>} />
        )}
        <CurrencyDropdownPicker
          disabled={currencyChangeEnabled}
          onSelectItem={item => onSelectItem(item, "Currency")}
          onOpen={() => {
            closeAllDropdowns();
            setDropdownCurrencyOpen(true);
          }}
          onClose={() => setDropdownCurrencyOpen(false)}
          isDropdownOpen={dropdownCurrencyOpen}
          multiDropdownContent
        />
        <View style={gapMedium}>
          <Text variant="titleMedium">{t("T00648")}</Text>
          <PrimaryButton
            mode="outlined"
            label="T00649"
            onPress={changePassword}
          />
        </View>
      </View>
      {isPhysiotherapist ? (
        <View style={gapMedium}>
          <View style={settingsStyles.switchContainer}>
            <Text variant="titleSmall" style={{ flex: 1 }}>
              {t("T00506")}
            </Text>
            {isSettingsLoading ? (
              <ActivityIndicator />
            ) : isSettingsError ? (
              <IconButton
                icon="reload"
                size={32}
                onPress={() => refetchSettings()}
                iconColor={theme.colors.error}
              />
            ) : (
              <Switch
                value={isMessagesSwitchOn}
                onValueChange={() => mutate()}
              />
            )}
          </View>
          {physioInfos.map((e, i) => (
            <Text key={`info-item-${i}`} variant="bodySmall">
              {e}
            </Text>
          ))}
        </View>
      ) : null}
    </ScrollView>
  );
};

export default ApplicationSettings;
