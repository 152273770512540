import { FC } from "react";
import { View, ViewStyle } from "react-native";
import { Text } from "react-native-paper";

import { globalStyles } from "@styles/global";
import { ProductLogo } from "@assets/svg/ProductLogo";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";

type Props = {
  text?: string;
  style?: ViewStyle;
};

const Logo: FC<Props> = ({ text, style }) => {
  const { width } = useWindowDimensions();
  return (
    <View style={[{ alignItems: "center" }, globalStyles.gapMedium, style]}>
      <ProductLogo width={width / 2} height={width / 4} />
      {text && <Text variant="titleMedium">{text}</Text>}
    </View>
  );
};

export default Logo;
