import { View } from "react-native";
import { Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { Control, FieldValues, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { useMutation } from "@tanstack/react-query";
import {
  deleteAdditionalExperience,
  updateAdditionalExperience,
} from "@services/ApiService/verifications";
import { FC } from "react";
import { useErrors } from "@hooks/useErrors";
import { AxiosError } from "axios";

type Props = {
  data: { id: number; description: string }[];
  onSuccess: () => void;
};

type FormProps = {
  description: string;
};

export const EditExperienceBottomSheet: FC<Props> = ({ onSuccess, data }) => {
  const { t } = useTranslation();

  const schema = object().shape({
    description: string().required(t("T00014")).trim(),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: data?.[0]?.description || "",
    },
  });

  const { setErrorsFromResponse: setUpdateErrorsFromResponse } = useErrors();
  const { setErrorsFromResponse: setDeleteErrorsFromResponse } = useErrors();

  const { mutate: updateExperience, isLoading: isUpdateExperienceLoading } =
    useMutation({
      mutationFn: (form: FormProps) =>
        updateAdditionalExperience(form, data[0].id),
      onSuccess,
      onError: ({ response }: AxiosError) =>
        setUpdateErrorsFromResponse(response),
    });

  const { mutate: deleteExperience, isLoading: isDeleteExperienceLoading } =
    useMutation({
      mutationFn: () => deleteAdditionalExperience(data[0].id),
      onSuccess,
      onError: ({ response }: AxiosError) =>
        setDeleteErrorsFromResponse(response),
    });

  const onSubmit = (data: FormProps) => updateExperience(data);

  return (
    <View style={globalStyles.gapLarge}>
      <Text variant="bodyLarge">{t("T01195")}</Text>
      <Input
        name="description"
        label="T00265"
        control={control as unknown as Control<FieldValues>}
        isInBottomSheet
      />
      <PrimaryButton
        label="T01196"
        mode="outlined"
        loading={isDeleteExperienceLoading}
        disabled={isDeleteExperienceLoading}
        onPress={() => deleteExperience()}
      />
      <PrimaryButton
        label="T00841"
        loading={isUpdateExperienceLoading}
        disabled={isUpdateExperienceLoading}
        onPress={handleSubmit(onSubmit)}
      />
    </View>
  );
};
