/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isWeb } from "@utils/constants";
import { showAlert } from "@utils/showAlert";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Linking, Platform } from "react-native";
import { useInvitationCode } from "..";
import Clipboard from "@react-native-clipboard/clipboard";
import { showSnackbar } from "@utils/snackbarHelper";

export type ShareType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("react-native-share").default;

let Share: ShareType;

if (!isWeb) {
  Share = (require("react-native-share") as typeof import("react-native-share"))
    .default;
}

export const useInvitation = () => {
  const { t } = useTranslation();
  const { invitationCode, invitationUrl } = useInvitationCode();

  const shareMessage = useMemo(
    () =>
      t("T01423", {
        invitationCode,
        invitationUrl,
        interpolation: { escapeValue: false },
      }),
    [invitationCode, invitationUrl, t],
  );

  const share = useCallback(async () => {
    try {
      if (!isWeb) {
        await Share.open({
          message: shareMessage,
        });
      } else {
        await Linking.openURL(`mailto:?body=${shareMessage}`);
      }
    } catch (e: unknown) {
      //@ts-ignore
      showAlert(e.message as string);
    }
  }, [shareMessage]);

  const copyToClipboard = useCallback(() => {
    try {
      Clipboard.setString(invitationCode);
      showSnackbar({ message: t("T01424") });
    } catch (e: unknown) {
      //@ts-ignore
      showAlert(e.message as string);
    }
  }, [invitationCode, t]);

  return { share, copyToClipboard, invitationCode };
};
