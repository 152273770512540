import { useCallback, useEffect, useState } from "react";
import NetInfo, { NetInfoState } from "@react-native-community/netinfo";
import { showSnackbar } from "@utils/snackbarHelper";
import { onlineManager, useQueryClient } from "@tanstack/react-query";
import { hideMessage } from "react-native-flash-message";
import { useTranslation } from "react-i18next";

const useNetworkStatus = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [
    shouldShowNetworkReconnectedSnackbar,
    setShouldShowNetworkReconnectedSnackbar,
  ] = useState(false);

  const showNoNetworkSnackbar = useCallback(() => {
    showSnackbar({
      message: t("T01483"),
      rightIcon: "close",
      autoHide: false,
      onPressRightIcon: hideMessage,
    });
    !shouldShowNetworkReconnectedSnackbar &&
      setShouldShowNetworkReconnectedSnackbar(true);
  }, [shouldShowNetworkReconnectedSnackbar, t]);

  const showNetworkReconnectedSnackbar = useCallback(() => {
    showSnackbar({
      message: t("T01484"),
      rightIcon: "close",
      onPressRightIcon: hideMessage,
      autoHide: false,
    });
  }, [t]);

  const handleConnectivityChange = useCallback(
    (state: NetInfoState) => {
      if (!state.isConnected) {
        showNoNetworkSnackbar();
        onlineManager.setOnline(false);
      } else if (state.isConnected) {
        shouldShowNetworkReconnectedSnackbar &&
          showNetworkReconnectedSnackbar();
        onlineManager.setOnline(true);
      }
    },
    [
      shouldShowNetworkReconnectedSnackbar,
      showNetworkReconnectedSnackbar,
      showNoNetworkSnackbar,
    ],
  );

  useEffect(
    () =>
      queryClient.setDefaultOptions({
        mutations: {
          onMutate: async () => {
            const netInfo = await NetInfo.fetch();
            if (!netInfo.isConnected) showNoNetworkSnackbar();
          },
        },
      }),
    [queryClient, showNoNetworkSnackbar, t],
  );

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(handleConnectivityChange);
    return () => {
      unsubscribe();
    };
  }, [handleConnectivityChange]);
};

export default useNetworkStatus;
