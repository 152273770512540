import { BottomTutorialComponent } from "@components/BottomSheet";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { FC } from "react";
import { TutorialBottomSheetProps } from "./Payments/OnlinePaymentsTutorial";
import { TransKey } from "@globalTypes/i18next";

const texts: TransKey[] = ["T01123", "T01124"];

const CurrencyInfoTutorial: FC<TutorialBottomSheetProps> = ({
  modalVisible,
  setModalVisible,
}) => {
  return (
    <BottomModalContainer
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}>
      <BottomTutorialComponent
        title="T01122"
        subtitles={texts}
        onPress={() => setModalVisible(false)}
      />
    </BottomModalContainer>
  );
};

export default CurrencyInfoTutorial;
