export type HelpCenterSubmission = {
  email: string;
  content: string;
  submissionCategory: string;
};

enum SubmissionStatus {
  PENDING = "P",
  REJECTED = "R",
}

export type HelpCenterSubmissionResponse = HelpCenterSubmission & {
  id: number;
  submissionStatus: SubmissionStatus;
};

export enum HelpCenterIssue {
  Payments = "Payments",
  Appointments = "Appointments",
  AdditionalContent = "Additional content",
  RuleViolations = "Rule violations",
  SoftwareBugs = "Software bugs",
  AccountRemoval = "Account removal",
  PersonalInformationRemoval = "Personal information removal",
  Other = "Other",
}
