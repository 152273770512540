import { Card, Text } from "react-native-paper";
import { spacing16 } from "@styles/spacing";
import { globalStyles } from "@styles/global";
import { FC } from "react";

type Props = {
  title: string;
  subtitle: string;
  uri: string;
  onPress: () => void;
};

export const TrainingCategoryTile: FC<Props> = ({
  title,
  subtitle,
  uri,
  onPress,
}) => {
  return (
    <Card
      mode="outlined"
      style={{ borderColor: "transparent" }}
      onPress={onPress}>
      <Card.Cover source={{ uri }} />
      <Card.Content style={[globalStyles.gapMedium, { padding: spacing16 }]}>
        <Text variant="bodyLarge">{title}</Text>
        <Text variant="bodyMedium">{subtitle}</Text>
      </Card.Content>
    </Card>
  );
};
