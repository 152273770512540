import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      fill="#BA1A1A"
      d="m15.833 5.342-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10 4.167 14.66l1.175 1.175L10 11.175l4.658 4.659 1.175-1.175L11.175 10l4.658-4.658Z"
    />
  </Svg>
);
export default SvgComponent;
