/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  clearTransactionIOS,
  endConnection,
  initConnection,
  requestSubscription,
  setup,
  SubscriptionAndroid,
  SubscriptionIOS,
  useIAP,
} from "react-native-iap";
import { isANDROID, isIOS } from "@utils/constants";
import { Platform } from "react-native";
import { showAlert } from "@utils/showAlert";
import { useMutation } from "@tanstack/react-query";
import {
  deferGoogleSubscription,
  subscribeApple,
  subscribeGoogle,
} from "@api/subscriptions";
import { useErrors } from "@hooks/useErrors";
import { AxiosError } from "axios";
import { useAuth } from "@contexts/AuthContext/auth";
import { useTranslation } from "react-i18next";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import { config } from "@utils/config";
import { TransKey } from "@globalTypes/i18next";
import useInvalidateProfileDetailsAndSubscriptionStatus from "@hooks/queryHooks/useInvalidateProfileDetailsAndSubscriptionStatus";

const skus = Platform.select({
  ios: ["fix_sub_01_month"],
  android: [config.EXPO_PUBLIC_GOOGLE_MONTHLY_SUB_ID],
});

export const useSubscription = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const {
    connected,
    subscriptions, //returns subscriptions for this app.
    getSubscriptions, //Gets available subscriptions for this app.ą
    purchaseHistory, //return the purchase history of the user on the device (sandbox user in dev)
    getPurchaseHistory, //gets users purchase history
    initConnectionError,
  } = useIAP();
  const { setErrorsFromResponse } = useErrors();
  const { updateUser } = useAuth();

  const { invalidateData } = useInvalidateProfileDetailsAndSubscriptionStatus();

  const handleMutationError = (response: AxiosError["response"]) =>
    setErrorsFromResponse(response);

  const onSuccess = () => {
    updateUser();
    showProfileVerificationSnackbar(t("T01311"));
  };

  const { mutate: postSubscriptionApple, isLoading: isPostAppleLoading } =
    useMutation({
      mutationFn: subscribeApple,
      onError: ({ response }: AxiosError) => handleMutationError(response),
      onSuccess,
      onSettled: () => setLoading(false),
    });

  const { mutate: deferSub } = useMutation({
    mutationFn: deferGoogleSubscription,
    onError: ({ response }: AxiosError) => handleMutationError(response),
    onSuccess,
    onSettled: () => setLoading(false),
  });

  const { mutate: postSubscriptionGoogle, isLoading: isPostGoogleLoading } =
    useMutation({
      mutationFn: subscribeGoogle,
      onError: ({ response }: AxiosError) => handleMutationError(response),
      onSuccess,
      onSettled: () => setLoading(false),
    });

  const handleGetPurchaseHistory = useCallback(async () => {
    setLoading(true);
    try {
      await getPurchaseHistory();
    } catch (error: unknown) {
      throw new Error("handleGetPurchaseHistoryError", { cause: error });
    }
    setLoading(false);
  }, [getPurchaseHistory]);

  useEffect(() => {
    setup({ storekitMode: "STOREKIT2_MODE" });
  }, []);

  useEffect(() => {
    initConnection().catch(error => console.error(error));
    return () => {
      void endConnection();
    };
  }, []);

  const handleGetSubscriptions = useCallback(async () => {
    setLoading(true);
    try {
      await getSubscriptions({ skus });
    } catch (error: unknown) {
      throw new Error("handleGetSubscriptionsError", { cause: error });
    }
    setLoading(false);
  }, [getSubscriptions]);

  useEffect(() => {
    if (connected) {
      void handleGetSubscriptions();
      void handleGetPurchaseHistory();
    }
  }, [connected, handleGetSubscriptions, handleGetPurchaseHistory]);

  const handleBuySubscription = useCallback(
    async (sku: string, offerToken?: string) => {
      setLoading(true);
      try {
        isIOS && (await clearTransactionIOS());
        const sub = await requestSubscription({
          sku,
          ...(offerToken && { subscriptionOffers: [{ sku, offerToken }] }),
        });

        if (!sub) {
          setLoading(false);
          console.warn("Subscriptions are not available");
          return;
        }

        let subscription = sub;
        if (Array.isArray(sub)) subscription = sub[0];

        if (isIOS && "originalTransactionIdentifierIOS" in subscription)
          postSubscriptionApple({
            subscriptionType: "BASIC",
            originalTransactionId:
              subscription.originalTransactionIdentifierIOS ||
              subscription.transactionId,
          });

        if (isANDROID && "purchaseToken" in subscription)
          postSubscriptionGoogle({
            subscriptionType: "BASIC",
            purchaseToken: subscription.purchaseToken,
          });
      } catch (error: unknown) {
        console.log(error);
        setLoading(false);
      }
    },
    [postSubscriptionApple, postSubscriptionGoogle],
  );

  useEffect(() => {
    if (initConnectionError) showAlert(initConnectionError.message);
  }, [initConnectionError]);

  const subscribeIOS = useCallback(
    (subscription: SubscriptionIOS) => {
      const { productId } = subscription;
      void handleBuySubscription(productId);
    },
    [handleBuySubscription],
  );

  const subscribeAndroid = useCallback(
    (subscription: SubscriptionAndroid) => {
      void handleBuySubscription(
        subscription.productId,
        subscription.subscriptionOfferDetails[0].offerToken,
      );
    },
    [handleBuySubscription],
  );

  const onSubscribe = () => {
    if (isIOS) return subscribeIOS(subscriptions[0] as SubscriptionIOS);
    if (isANDROID)
      return subscribeAndroid(subscriptions[0] as SubscriptionAndroid);
    void invalidateData();
  };

  const displaySubscriptionPrice = useCallback(() => {
    if (isIOS) {
      const subscriptionData = subscriptions[0] as SubscriptionIOS;

      const priceIOS =
        subscriptionData &&
        `${subscriptionData?.price || ""} ${subscriptionData?.currency || ""}`;
      if (!priceIOS) return "";
      return `${priceIOS}/${t("T01042")}`;
    }
    if (isANDROID) {
      const subscriptionData = subscriptions[0] as SubscriptionAndroid;

      const priceAndroid =
        subscriptionData &&
        subscriptionData.subscriptionOfferDetails[0]?.pricingPhases.pricingPhaseList.slice(
          -1,
        )[0].formattedPrice;
      if (!priceAndroid) return "";
      return `${priceAndroid}/${t("T01042")}` as TransKey;
    }
  }, [subscriptions, t]);

  const anyLoading = useMemo(
    () => loading || isPostAppleLoading || isPostGoogleLoading,
    [loading, isPostGoogleLoading, isPostAppleLoading],
  );

  return {
    connected,
    subscriptions,
    purchaseHistory,
    loading: anyLoading,
    handleBuySubscription,
    onSubscribe,
    displaySubscriptionPrice,
    deferSub,
  };
};
