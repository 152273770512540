import { FC } from "react";
import Card from "@components/cards/Card";
import { useTranslation } from "react-i18next";
import { CardProps } from "react-native-paper";

type Props = Omit<CardProps, "children">;

const AssistantCard: FC<Props> = ({ onPress, ...props }) => {
  const { t } = useTranslation();
  return (
    <Card
      mode="outlined"
      initialData={{
        firstName: t("T00860"),
        lastName: "",
      }}
      isFetched={true}
      isAssistantCard
      onPress={onPress && onPress}
      flat={!onPress}
      {...props}
    />
  );
};

export default AssistantCard;
