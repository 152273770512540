import { Button, ButtonProps } from "react-native-paper";
import { palettes } from "@styles/colors";
import { spacing16 } from "@styles/spacing";

type HourButtonProps = ButtonProps & {
  isStationary: boolean;
};

export const HourButton = ({
  isStationary,
  disabled,
  ...props
}: HourButtonProps) => (
  <Button
    mode="contained-tonal"
    buttonColor={!isStationary && palettes.tertiary[90]}
    labelStyle={[
      disabled && {
        textDecorationLine: "line-through",
      },
      {
        width: spacing16 * 3,
      },
    ]}
    disabled={disabled}
    {...props}
  />
);

export default HourButton;
