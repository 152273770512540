import { FAQSubjectItem } from "@components/ListItems/FAQSubjectItem";
import { SectionTitleWithInfo } from "@components/Section";
import { FetchError } from "@components/errors";
import { HelpCenterStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { queryKeysFaq } from "@screens/Common/queryKeysHelpCenter";
import { getCategorySubjects } from "@services/ApiService/helpCenter";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";

export const HelpCenterTopics: FC<
  PropsWithChildren<
    NativeStackScreenProps<HelpCenterStackParamList, "HelpCenterTopics">
  >
> = ({
  navigation: { navigate },
  route: {
    params: { id, name },
  },
}) => {
  const { t } = useTranslation();

  const { scrollContainer, gapLarge, gapMedium, loading } = globalStyles;

  const {
    data: subjects,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryFn: () => getCategorySubjects(id),
    queryKey: queryKeysFaq.categorySubjects(id),
  });

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <ScrollView contentContainerStyle={[scrollContainer, gapLarge]}>
      <SectionTitleWithInfo title={name} desc={t("T00728")} />
      <View style={gapMedium}>
        {subjects.map(({ name, id: subID }, i) => (
          <FAQSubjectItem
            key={`topic-${name}-${id}-${i}`}
            categoryID={id}
            subjectID={subID}
            onPress={(name, subtitle) =>
              navigate("HelpCenterSelectedTopic", { name, subtitle })
            }
          />
        ))}
      </View>
    </ScrollView>
  );
};
