import Svg, { SvgProps, Rect, Path } from "react-native-svg";

export const SvgOnlinePaymentsIcon2 = (props: SvgProps) => (
  <Svg width={56} height={56} fill="none" {...props}>
    <Rect width={56} height={56} fill="#DEE1FF" rx={28} />
    <Path
      fill="#4666FF"
      d="M36 18H20c-1.1 0-1.99.9-1.99 2L18 38l4-4h14c1.1 0 2-.9 2-2V20c0-1.1-.9-2-2-2Zm-3.62 10.7L30 26.8V29c0 .55-.45 1-1 1h-6c-.55 0-1-.45-1-1v-6c0-.55.45-1 1-1h6c.55 0 1 .45 1 1v2.2l2.38-1.9a.998.998 0 0 1 1.62.78v3.84c0 .84-.97 1.3-1.62.78Z"
    />
  </Svg>
);
