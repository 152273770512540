import { TrainingSelectionAssistantStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren, useContext, useState } from "react";
import { View } from "react-native";
import { Chip, Text } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { PrimaryButton } from "@components/buttons";
import { useTranslation } from "react-i18next";
import { TrainingSelectionAssistantContext } from "./trainingSelectionAssistant.context";

export const DailyTimeForTraining: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      TrainingSelectionAssistantStackParamsList,
      "DailyTimeForTraining"
    >
  >
> = ({ navigation: { navigate } }) => {
  const [currentValue, setCurrentValue] = useState<1 | 2 | 3>();

  const { t } = useTranslation();
  const { updateData } = useContext(TrainingSelectionAssistantContext);

  const { container, gapLarge, gapSmall } = globalStyles;

  return (
    <View style={[container, { justifyContent: "space-between" }]}>
      <View style={gapLarge}>
        <View style={gapSmall}>
          <Text variant="titleMedium">{t("T01012")}</Text>
          <Text>{t("T01010")}</Text>
        </View>
        <View style={gapLarge}>
          <Chip
            mode="outlined"
            style={{ alignSelf: "flex-start" }}
            selected={currentValue === 1}
            onPress={() =>
              setCurrentValue(prevState => (prevState === 1 ? undefined : 1))
            }>
            {t("T01013")}
          </Chip>
          <Chip
            mode="outlined"
            style={{ alignSelf: "flex-start" }}
            selected={currentValue === 2}
            onPress={() =>
              setCurrentValue(prevState => (prevState === 2 ? undefined : 2))
            }>
            {t("T01014")}
          </Chip>
          <Chip
            mode="outlined"
            style={{ alignSelf: "flex-start" }}
            selected={currentValue === 3}
            onPress={() =>
              setCurrentValue(prevState => (prevState === 3 ? undefined : 3))
            }>
            {t("T01015")}
          </Chip>
        </View>
      </View>
      <PrimaryButton
        label="T00472"
        disabled={!currentValue}
        onPress={() => {
          updateData({ time: currentValue });
          navigate("TrainingAssistantSummary");
        }}
      />
    </View>
  );
};
