import { GetPhysiotherapistsResponse } from "@contexts/AuthContext/user.types";
import { useCallback, useEffect, useState } from "react";

type UseFirstTherapistOverDistanceOptions = {
  therapistsList: GetPhysiotherapistsResponse[];
  distanceRange: number;
};

const useFirstTherapistOverDistance = ({
  therapistsList,
  distanceRange,
}: UseFirstTherapistOverDistanceOptions) => {
  const [firstTherapistOverDistanceId, setFirstTherapistOverDistanceId] =
    useState<number>(null);

  const getFirstTherapistOverDistance = useCallback(() => {
    const indexOverDistance = therapistsList?.findIndex(
      ({ distance }) => distance !== null && +distance > distanceRange,
    );
    if (indexOverDistance === -1 || indexOverDistance === undefined)
      return setFirstTherapistOverDistanceId(null);

    return therapistsList[indexOverDistance].id;
  }, [distanceRange, therapistsList]);

  useEffect(() => {
    const firstTherapistOverDistance = getFirstTherapistOverDistance();
    if (firstTherapistOverDistance)
      setFirstTherapistOverDistanceId(firstTherapistOverDistance);
  }, [getFirstTherapistOverDistance, therapistsList]);

  return { firstTherapistOverDistanceId };
};

export default useFirstTherapistOverDistance;
