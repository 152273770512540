import EquipmentTile from "@components/Tile/exercise/EquipmentTile";
import { RootStackParamList } from "@navigators/navigation.types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "react-native-paper";

export type SelectedEquipmentListProps = {
  selectedEquipment: number[];
};

const SelectedEquipmentList: FC<
  PropsWithChildren<SelectedEquipmentListProps>
> = ({ selectedEquipment }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  return (
    <>
      {selectedEquipment?.length ? (
        selectedEquipment.map(eq => (
          <EquipmentTile
            key={eq}
            mode="outlined"
            equipmentId={eq}
            onPress={() => navigate("EquipmentDetails", { id: eq })}
          />
        ))
      ) : (
        <Text variant="bodyMedium">{t("T00578")}</Text>
      )}
    </>
  );
};

export default SelectedEquipmentList;
