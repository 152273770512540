import {
  format,
  startOfWeek,
  addDays,
  getISOWeeksInYear,
  getWeek,
  subMonths,
  addMonths,
  parse,
} from "date-fns";

export const getWeekCalendarTitle = (singleWeek: Date[]) => {
  const firstDayOfWeek = singleWeek[0];
  const lastDayOfWeek = singleWeek[singleWeek.length - 1];
  return `${format(firstDayOfWeek, "dd.MM")} - ${format(
    lastDayOfWeek,
    "dd.MM",
  )}`;
};

export const getWeekDaysArray = (startDateOfWeek: Date) => {
  const daysArray: Date[] = [];
  let currentDate = startOfWeek(startDateOfWeek, { weekStartsOn: 1 });

  for (let i = 0; i < 7; i++) {
    daysArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }

  return daysArray;
};

export const getFirstDayOfWeekByWeekIndex = (
  weekNumber: number,
  year: number,
) => {
  let date = parse(weekNumber.toString(), "I", new Date(year, 0, 1));

  if (isNaN(+date)) {
    if (weekNumber > 52) {
      const nextYear = new Date(year, 0, 1).getFullYear() + 1;
      date = parse("1", "I", new Date(nextYear, 0, 1));
    } else {
      const prevYear = new Date(year, 0, 1).getFullYear() - 1;
      const lastWeekOfPrevYear = getISOWeeksInYear(new Date(prevYear, 0, 1));
      date = parse(
        lastWeekOfPrevYear.toString(),
        "I",
        new Date(prevYear, 0, 1),
      );
    }
  }
  return date;
};

export const getPastWeekBoundary = (date: Date) => getWeek(subMonths(date, 1));

export const getFutureWeekBoundary = (date: Date) =>
  getWeek(addMonths(date, 2));
