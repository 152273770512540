import { useCallback, useMemo, useState } from "react";
import {
  ListRenderItem,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  FlatList,
} from "react-native";
import { Divider, IconButton } from "react-native-paper";
import { useTranslation } from "react-i18next";

import { EditMonthYearSelector } from "./Edit";

import { spacing16, spacing8 } from "@styles/spacing";

import { months } from "@utils/constants";
import { DateArrayType } from "./Calendar";
import { generateYears, YearsOption } from "@components/Calendar/helpers";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { EmptyIconButton } from "@components/buttons";
import { TransKey } from "@globalTypes/i18next";

type Props = {
  changeCalendarMode: () => void;
  currentDate: DateArrayType;
  setCurrentDate: (date: DateArrayType) => void;
  yearsOption: YearsOption;
  onChangeMonth?: (date: Date) => void;
  onSelectDate?: (data: DateArrayType, dontClose?: boolean) => void;
};

export const CalendarEdit = ({
  currentDate,
  setCurrentDate,
  changeCalendarMode,
  yearsOption,
  onChangeMonth,
  onSelectDate,
}: Props) => {
  const { height: screenHeight } = useWindowDimensions();
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();
  const { tabItem, tabBarContainer } = styles;

  const selectedMonth = currentDate.date.getMonth();
  const selectedYear = currentDate.date.getFullYear();

  const years = useMemo(() => generateYears(yearsOption), [yearsOption]);

  const selectDateAndChangeMonth = useCallback(
    (date: Date) => {
      setCurrentDate({ date });
      onSelectDate?.({ date }, true);
      onChangeMonth?.(date);
    },
    [onChangeMonth, onSelectDate, setCurrentDate],
  );
  const onMonthPress = useCallback(
    (index: number) => {
      const tempDate = new Date(currentDate.date);
      tempDate.setMonth(index);
      selectDateAndChangeMonth(tempDate);
      setIndex(1);
    },
    [currentDate.date, selectDateAndChangeMonth],
  );

  const onYearPress = useCallback(
    (item: string) => {
      const tempDate = new Date(currentDate.date);
      tempDate.setFullYear(+item);
      selectDateAndChangeMonth(tempDate);
      changeCalendarMode();
    },
    [changeCalendarMode, currentDate.date, selectDateAndChangeMonth],
  );

  const renderMonth: ListRenderItem<string> = useCallback(
    ({ item, index }) => {
      const icon = `${index === selectedMonth ? "check" : ""}`;
      return (
        <EditMonthYearSelector
          key={`month-${item}-${index}`}
          item={item as TransKey}
          onPress={() => onMonthPress(index)}
          iconToDisplay={icon}
          type="month"
        />
      );
    },
    [onMonthPress, selectedMonth],
  );

  const renderYear: ListRenderItem<TransKey> = useCallback(
    ({ item, index }) => {
      const icon = `${+item === selectedYear ? "check" : ""}`;
      return (
        <EditMonthYearSelector
          key={`year-${item}-${index}`}
          item={item}
          onPress={() => onYearPress(item)}
          iconToDisplay={icon}
          type="year"
        />
      );
    },
    [selectedYear, onYearPress],
  );

  const renderTabItem = useCallback(
    (title: TransKey, indexToChange: number) => {
      return (
        <TouchableOpacity
          onPress={() => setIndex(indexToChange)}
          style={tabItem}>
          <Text>{`${indexToChange === 0 ? t(title) : title}`}</Text>
          {index === indexToChange ? (
            <IconButton icon="menu-down" />
          ) : (
            <EmptyIconButton />
          )}
        </TouchableOpacity>
      );
    },
    [index, t, tabItem],
  );

  return (
    <View
      style={{
        padding: spacing8,
        maxHeight: screenHeight / 2,
      }}>
      <View style={tabBarContainer}>
        {renderTabItem(months[selectedMonth], 0)}
        {renderTabItem(`${selectedYear}` as TransKey, 1)}
      </View>
      <Divider bold />
      <FlatList
        nestedScrollEnabled
        renderItem={index === 0 ? renderMonth : renderYear}
        data={index === 0 ? months : years}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  tabBarContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tabItem: {
    flex: 1,
    padding: spacing16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});
