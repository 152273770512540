import { FlatList } from "react-native";
import { FC, PropsWithChildren } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { HeaderWithSearchbar } from "@components/HeaderWithSearchbar";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useQuery } from "@tanstack/react-query";
import { queryKeysPatient } from "@screens/Common/queryKeysMyPatients";
import { AxiosError } from "axios";
import { useErrors } from "@hooks/useErrors";
import { getMyPatients } from "@services/ApiService/users";
import { ActivityIndicator, Divider, Text } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { FetchError } from "@components/errors";
import { UserListItem, UserListItemMode } from "@components/ListItems";
import InfoTile from "@components/Tile/InfoTile";
import { useTranslation } from "react-i18next";
import { useAssistant } from "@hooks/user/useAssistant";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { spacing8 } from "@styles/spacing";

export const PatientToAssignMedicalRecord: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "PatientToAssignMedicalRecord">
  >
> = ({ navigation: { goBack, navigate } }) => {
  const { setErrorsFromResponse } = useErrors();
  const { t } = useTranslation();
  const { assistantId } = useAssistant();
  const { width } = useWindowDimensions();

  const { loading, scrollContainer, flatListSeparator } = globalStyles;

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysPatient.myList(),
    queryFn: () => getMyPatients(),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["top", "left", "right"]}>
      <HeaderWithSearchbar goBack={goBack} />
      <FlatList
        contentContainerStyle={[scrollContainer, { paddingTop: spacing8 }]}
        data={data.filter(({ id }) => id !== assistantId)}
        ListEmptyComponent={
          <InfoTile
            status="info"
            content={<Text variant="bodyMedium">{t("T00862")}</Text>}
          />
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ItemSeparatorComponent={
          <Divider
            bold
            style={[flatListSeparator, { width, marginVertical: spacing8 }]}
          />
        }
        renderItem={({ item: { id }, index }) => (
          <UserListItem
            key={`patient-to-share-medical-record-${id}-${index}`}
            id={id}
            mode={UserListItemMode.PATIENTS}
            onPress={id => navigate("CreateAndEditRecord", { patientId: id })}
          />
        )}
      />
    </SafeAreaView>
  );
};
