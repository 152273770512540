import { PrimaryButton } from "@components/buttons";
import { TransKey } from "@globalTypes/i18next";
import { useMediaStyle } from "@hooks/ui/useMediaStyle";
import { globalStyles } from "@styles/global";
import { ResizeMode, Video } from "expo-av";
import { Image, ImageProps } from "expo-image";
import { FC } from "react";
import {
  ImageProps as RNImageProps,
  TouchableOpacity,
  View,
} from "react-native";

type Props = Omit<ImageProps, "source"> & {
  onPressButton?: () => void;
  imgUri?: string;
  videoUri?: string;
  onPressItem?: () => void;
  buttonLabel?: TransKey;
  isImgThumbnail?: boolean;
};

const MediaWithButton: FC<Props> = ({
  onPressButton,
  imgUri,
  videoUri,
  onPressItem,
  buttonLabel,
  isImgThumbnail,
  ...props
}) => {
  const { thumbnailHeight, width, height } = useMediaStyle();
  const mediaStyle = { width, height };

  return (
    <View style={globalStyles.gapMedium}>
      <TouchableOpacity onPress={onPressItem}>
        {imgUri && (
          <Image
            source={{ uri: imgUri }}
            style={
              isImgThumbnail ? { width, height: thumbnailHeight } : mediaStyle
            }
            contentFit="cover"
            {...props}
          />
        )}
        {videoUri && (
          <Video
            style={mediaStyle}
            videoStyle={mediaStyle}
            source={{
              uri: videoUri,
            }}
            useNativeControls
            resizeMode={ResizeMode.CONTAIN}
            posterSource={
              require("@assets/images/tutorial/example.png") as RNImageProps["source"]
            }
            isLooping
          />
        )}
      </TouchableOpacity>
      {onPressButton && (
        <PrimaryButton
          mode="text"
          onPress={onPressButton}
          label={buttonLabel}
          style={{ alignSelf: "flex-end" }}
        />
      )}
    </View>
  );
};

export default MediaWithButton;
