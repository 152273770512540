import { useAuth } from "@contexts/AuthContext/auth";
import { queryKeysVerficiations } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/queryKeysPhysiotherapistVerification";
import { VerificationStatus } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { queryKeysPhysiotherapistProfileData } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { getPhysiotherapistFullData } from "@services/ApiService/users";
import { getLicence } from "@services/ApiService/verifications";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useSubscriptionData } from "../subscriptions/useSubscriptionData";
import { useTherapistPaymentMethods } from "../payments/useTherapistPaymentMethods";

export const useDataForVerificationActionTileProfile = () => {
  const {
    user: { id, stripeActive },
  } = useAuth();
  const { isSubActive } = useSubscriptionData();
  const { stationaryMethods } = useTherapistPaymentMethods(id);
  const [actionTileTypeProfileVisible, setActionTileTypeProfileVisible] =
    useState(false);

  const {
    data: licenceData,
    isLoading: isLicenceLoading,
    isError: isLicenceError,
    refetch: refetchLicence,
  } = useQuery({
    queryKey: queryKeysVerficiations.licence(),
    queryFn: getLicence,
  });

  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
    refetch: refetchUserData,
  } = useQuery({
    queryKey: queryKeysPhysiotherapistProfileData.full(id),
    queryFn: async () => await getPhysiotherapistFullData(id),
  });

  const isAnyLoading = useMemo(
    () => isLicenceLoading || isUserDataLoading,
    [isLicenceLoading, isUserDataLoading],
  );
  const isAnyError = useMemo(
    () => isLicenceError || isUserDataError,
    [isLicenceError, isUserDataError],
  );
  useEffect(() => {
    const isDataReturnTrue =
      !licenceData ||
      licenceData?.[0]?.verificationStatus === VerificationStatus.REJECTED ||
      !userData?.address ||
      !userData?.services?.length ||
      (!stripeActive && !stationaryMethods[0].isActive) ||
      !isSubActive;
    setActionTileTypeProfileVisible(isDataReturnTrue);
  }, [
    isSubActive,
    licenceData,
    stationaryMethods,
    stripeActive,
    userData?.address,
    userData?.services?.length,
  ]);

  return {
    actionTileTypeProfileVisible,
    isAnyLoading,
    isAnyError,
    licenceData,
    isLicenceLoading,
    isLicenceError,
    refetchLicence,
    refetchUserData,
  };
};
