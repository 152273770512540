import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";

import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { AppbarTitleWithBackAction } from "../components/Appbar";
import {
  PushNotificationsSettingsStackParamsList,
  RehabilitationStatisticsStackParamsList,
} from "./navigation.types";

import RehabilitationStatistics from "@screens/Profiles/MyProfile/Statistics/RehabilitationStatistics";
import TrainingSummary from "@screens/TrainingsAndExercises/TrainingSummary";
import TrainingStatistics from "@screens/Profiles/MyProfile/Statistics/TrainingStatistics";

const Stack =
  createNativeStackNavigator<RehabilitationStatisticsStackParamsList>();

const RehabilitationStatisticsStackNavigator: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      PushNotificationsSettingsStackParamsList,
      "PushNotificationsSettings"
    >
  >
> = ({ navigation: { pop } }) => {
  const { t } = useTranslation();
  return (
    <Stack.Navigator
      screenOptions={{
        headerBackVisible: false,
      }}>
      <Stack.Screen
        name="RehabilitationStatistics"
        component={RehabilitationStatistics}
        options={{
          header: () => (
            <AppbarTitleWithBackAction
              title={t("T00504")}
              onClose={() => pop()}
            />
          ),
        }}
      />
      <Stack.Screen
        name="TrainingSummary"
        component={TrainingSummary}
        options={{
          header: () => <AppbarTitleWithBackAction title={t("T00195")} />,
        }}
        initialParams={{ pop }}
      />
      <Stack.Screen
        name="TrainingStatistics"
        component={TrainingStatistics}
        options={{
          header: ({ navigation: { goBack } }) => (
            <AppbarTitleWithBackAction
              title={t("T00523")}
              onClose={() => pop()}
              onBack={goBack}
            />
          ),
        }}
      />
    </Stack.Navigator>
  );
};

export default RehabilitationStatisticsStackNavigator;
