import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import PushNotificationsSettings from "@screens/Profiles/MyProfile/Settings/PushNotificationsSettings";
import Appointments from "@screens/Profiles/MyProfile/Settings/PushNotificationsSettings/Appointments";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { AppbarTitleWithBackAction } from "../components/Appbar";
import { PushNotificationsSettingsStackParamsList } from "./navigation.types";
import Messages from "@screens/Profiles/MyProfile/Settings/PushNotificationsSettings/Messages";
import TrainingsPushSettings from "@screens/Profiles/MyProfile/Settings/PushNotificationsSettings/TrainingsPushSettings";

const Stack =
  createNativeStackNavigator<PushNotificationsSettingsStackParamsList>();

const PushNotificationsSettingsStackNavigator: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      PushNotificationsSettingsStackParamsList,
      "PushNotificationsSettings"
    >
  >
> = ({ navigation: { pop } }) => {
  const { t } = useTranslation();
  return (
    <Stack.Navigator
      screenOptions={{
        headerBackVisible: false,
      }}>
      <Stack.Screen
        name="PushNotificationsSettings"
        component={PushNotificationsSettings}
        options={{
          header: ({ navigation: { goBack } }) => (
            <AppbarTitleWithBackAction onClose={goBack} title={t("T01454")} />
          ),
        }}
      />
      <Stack.Screen
        name="Appointments"
        component={Appointments}
        options={{
          header: ({ navigation: { goBack } }) => (
            <AppbarTitleWithBackAction
              title={t("T00680")}
              onBack={goBack}
              onClose={() => pop()}
            />
          ),
        }}
      />
      <Stack.Screen
        name="Messages"
        component={Messages}
        options={{
          header: ({ navigation: { goBack } }) => (
            <AppbarTitleWithBackAction
              title={t("T00362")}
              onBack={goBack}
              onClose={() => pop()}
            />
          ),
        }}
      />
      <Stack.Screen
        name="TrainingsPushSettings"
        component={TrainingsPushSettings}
        options={{
          header: ({ navigation: { goBack } }) => (
            <AppbarTitleWithBackAction
              title={t("T00167")}
              onBack={goBack}
              onClose={() => pop()}
            />
          ),
        }}
      />
    </Stack.Navigator>
  );
};

export default PushNotificationsSettingsStackNavigator;
