import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";
import { PhysiotherapistAppointmentCalendar } from "./PhysiotherapistAppointmentCalendar";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { useTranslation } from "react-i18next";
import { AppointmentSlots } from "@screens/Calendar/availability.types";
import { OnHourButtonPress } from "./appointment.types";

export const ServiceAndDate: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "ServiceAndDate">
  >
> = ({ route, navigation: { navigate } }) => {
  const { physiotherapistId, serviceType: initialServiceType } = route.params;
  const { t } = useTranslation();

  const onHourPress = ({
    dateFrom,
    serviceType,
    selectedItem,
    serviceId,
  }: OnHourButtonPress) => {
    const resultServiceType =
      serviceType === AppointmentSlots.ONLINE ? "Online" : "Stationary";

    navigate("CreateCustomAppointment", {
      serviceId,
      serviceType: resultServiceType,
      dateFrom,
      language: selectedItem.value,
    });
  };

  return (
    <PhysiotherapistAppointmentCalendar
      physiotherapistId={physiotherapistId}
      serviceType={initialServiceType}
      onHourPress={onHourPress}
      hidePhysioTile
      onSegmentedButtonChange={value =>
        value === AppointmentSlots.ONLINE &&
        showAlertWithCustomButtons({
          title: t("T01362"),
          message: t("T01363"),
          confirmButton: { text: t("T00394") },
        })
      }
    />
  );
};
