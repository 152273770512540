import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { spacing12, spacing4 } from "@styles/spacing";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Card, Text } from "react-native-paper";
import { CommitmentTileType } from "./types/CommitmentTileType";

const CommitmentTile: FC<CommitmentTileType> = ({
  title,
  content,
  elevation,
  action,
  onButtonPress,
  ...props
}) => {
  const { t } = useTranslation();
  const { gapLarge, gapSmall } = globalStyles;
  const { button, container } = styles;

  return (
    <Card {...props} mode="outlined" elevation={elevation as never}>
      <Card.Content style={[container, gapLarge]}>
        <View style={{ flex: 1 }}>
          <View style={gapSmall}>
            <Text variant="titleMedium">{t(title)}</Text>
            <Text variant="bodySmall">{t(content)}</Text>
          </View>
          <PrimaryButton
            mode="text"
            onPress={onButtonPress}
            label="T00824"
            style={button}
          />
        </View>
        {action}
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: { flexDirection: "row", alignItems: "center" },
  button: {
    alignSelf: "flex-start",
    marginStart: -spacing12,
    marginTop: -spacing4,
  },
});

export default CommitmentTile;
