import { FC } from "react";
import { SessionSeriesStat } from "@screens/TrainingsAndExercises/training.types";
import ExerciseWithSeriesTile from "@components/Tile/exercise/ExerciseWithSeriesTile";
import SeriesTile from "@components/Tile/SeriesTile";
import { View } from "react-native";
import { Divider } from "react-native-paper";
import { spacing16 } from "@styles/spacing";
import { globalStyles } from "@styles/global";
import { palettes } from "@styles/colors";

type ExercisesStatisticsProps = {
  seriesStats: SessionSeriesStat[];
};

const ExercisesStatistics: FC<ExercisesStatisticsProps> = ({ seriesStats }) => {
  return (
    <View style={globalStyles.gapMedium}>
      {seriesStats.map(({ exercise, id, series, value, breakValue }, index) => {
        const isFirst = index === 0;
        const isLastSeries = index === seriesStats.length - 1;
        const showExercise =
          isFirst || seriesStats[index - 1].exercise !== exercise;
        return (
          <View key={id} style={globalStyles.gapMedium}>
            {showExercise && !isFirst && (
              <Divider
                horizontalInset
                style={{ marginVertical: spacing16 }}
                bold
              />
            )}
            {showExercise && (
              <ExerciseWithSeriesTile
                exerciseId={exercise}
                mode="outlined"
                style={{ backgroundColor: palettes.primary[100] }}
                displayAlternative={false}
              />
            )}
            <SeriesTile
              id={series}
              exercise={exercise}
              valueDone={value}
              breakValue={breakValue}
              isLast={isLastSeries}
            />
          </View>
        );
      })}
    </View>
  );
};

export default ExercisesStatistics;
