import SelectedEquipmentList from "@screens/TrainingsAndExercises/Physiotherapist/SelectedEquipmentList";
import { globalStyles } from "@styles/global";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";

type EquipmentsListProps = {
  selectedEquipment: number[];
};

const EquipmentsInTrainingList: FC<EquipmentsListProps> = ({
  selectedEquipment,
}) => {
  const { t } = useTranslation();
  return (
    <View style={globalStyles.gapMedium}>
      <Text variant="titleMedium">{t("T00775")}:</Text>
      <SelectedEquipmentList selectedEquipment={selectedEquipment} />
    </View>
  );
};

export default EquipmentsInTrainingList;
