import WebViewModal from "@components/Modals/WebViewModal";
import { spacing16 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { isWeb, WEBVIEW_URIS } from "@utils/constants";
import { FC, useState } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Linking, StyleSheet, View } from "react-native";
import { Checkbox, HelperText, Text } from "react-native-paper";

type PrivacyAndTermsAgreementProps = Pick<ControllerProps, "control"> & {
  name: string;
  text?: string;
  actionEnabled?: boolean;
};

const PrivacyAndTermsAgreement: FC<PrivacyAndTermsAgreementProps> = ({
  control,
  name,
  text = "T01188",
  actionEnabled,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalProps, setModalProps] = useState({ uri: "", title: "" });
  const { t } = useTranslation();
  const {
    colors: { primary },
  } = useAppTheme();

  const openModal = async (type: "privacy" | "terms") => {
    const isPrivacy = type === "privacy";
    const uri = isPrivacy ? WEBVIEW_URIS.privacy : WEBVIEW_URIS.terms;

    setModalProps(() => {
      return {
        uri,
        title: t(isPrivacy ? "T00094" : "T00093"),
      };
    });

    if (isWeb) {
      await Linking.openURL(uri);
    } else {
      setIsModalVisible(true);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <View>
            <View style={{ flexDirection: "row" }}>
              <Checkbox.Android
                onPress={() => onChange(!value)}
                status={value || actionEnabled ? "checked" : "unchecked"}
                disabled={actionEnabled}
              />
              <View style={styles.textContainer}>
                <Text variant="labelSmall">
                  <Trans
                    i18nKey={text}
                    components={{
                      1: (
                        <Text
                          variant="labelSmall"
                          style={{ color: primary }}
                          onPress={() => openModal("terms")}>
                          the terms of service
                        </Text>
                      ),
                      2: (
                        <Text
                          variant="labelSmall"
                          style={{ color: primary }}
                          onPress={() => openModal("privacy")}>
                          privacy policy
                        </Text>
                      ),
                    }}
                  />
                </Text>
              </View>
            </View>
            {error && <HelperText type="error">{t("T00014")}</HelperText>}
            <WebViewModal
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              title={modalProps.title}
              uri={modalProps.uri}
              type="web"
              modalAnimation="none"
            />
          </View>
        );
      }}
    />
  );
};

export default PrivacyAndTermsAgreement;

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    marginRight: spacing16,
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
  },
});
