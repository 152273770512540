import {
  VideoClient,
  Stream,
  Participant as SDKParticipant,
  ChatClient as SDKChatClient,
  SubsessionClient as SDKSubsessionClient,
  RecordingClient as SDKRecordingClient,
  LiveTranscriptionClient as SDKLiveTranscriptionClient,
  CommandChannel,
  LiveStreamClient as SDKLiveStreamClient,
  VideoActiveState,
} from "@zoom/videosdk";

export type ZoomClient = typeof VideoClient;
export type MediaStream = typeof Stream;
export type Participant = SDKParticipant;
export type ChatClient = typeof SDKChatClient;
export type CommandChannelClient = typeof CommandChannel;
export type SubsessionClient = typeof SDKSubsessionClient;
export type RecordingClient = typeof SDKRecordingClient;
export type LiveTranscriptionClient = typeof SDKLiveTranscriptionClient;
export type LiveStreamClient = typeof SDKLiveStreamClient;

export type ZoomWebUser = Participant & {
  apiUserId?: number;
  isUserPhysio?: boolean;
};

export type OnVideoActiveChangePayload = {
  state: VideoActiveState;
  userId: number;
};

export enum VideoQuality {
  /**
   * 90P
   */
  Video_90P = 0,
  /**
   * 180P
   */
  Video_180P = 1,
  /**
   * 360P
   */
  Video_360P = 2,
  /**
   * 720P
   */
  Video_720P = 3,
  /**
   * 1080P
   */
  Video_1080P = 4,
}

export type MediaState = {
  audio: {
    encode?: unknown;
    decode?: unknown;
  };
  video: {
    encode?: unknown;
    decode?: unknown;
  };
  share: {
    encode?: unknown;
    decode?: unknown;
  };
};

export type Payload = {
  action?: string;
  type?: string;
  result?: string;
  reason?: string;
  subsessionName?: string;
  state?: string;
  userId?: number;
};

export type ActiveSpeakerType = {
  userId?: number;
  displayName: string;
};

export type Action =
  | { type: "audio-encode"; payload: unknown }
  | { type: "audio-decode"; payload: unknown }
  | { type: "video-encode"; payload: unknown }
  | { type: "video-decode"; payload: unknown }
  | { type: "share-encode"; payload: unknown }
  | { type: "share-decode"; payload: unknown }
  | { type: "reset-media" };
