import { FC, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "@components/buttons";
import MultiColorText from "@components/Text/MultiColorText";
import { spacing16, spacing32, spacing36, spacing8 } from "@styles/spacing";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { useFutureAvailability } from "@hooks/availability/useFutureAvailability";
import { FetchError } from "@components/errors";
import { EmptySectionInfoProps } from "./PhysioAvailability.types";

const EmptySectionInfo: FC<EmptySectionInfoProps> = ({
  onPress,
  activeIndex,
  sectionIndex,
  date,
  physiotherapistId,
  serviceId,
  isStationary,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { container } = styles;
  const [nextDate, setNextDate] = useState<string | null>(null);
  const [hasFutureHours, setHasFutureHours] = useState(true);

  const {
    data,
    isLoading: isFutureAvailabilityLoading,
    isError,
    refetch,
    isSuccess,
  } = useFutureAvailability(
    {
      serviceId,
      dateFrom: date,
      physiotherapistId,
    },
    activeIndex === sectionIndex,
  );

  useEffect(() => {
    if (isSuccess) {
      if (isStationary) {
        setNextDate(data?.stationary[0]?.date);
        setHasFutureHours(!!data.stationary.length);
      } else {
        setNextDate(data?.online[0]?.date);
        setHasFutureHours(!!data.online.length);
      }
    }
  }, [isSuccess, isStationary, data]);

  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <View style={[{ width: width - spacing32 }, container]}>
      {!hasFutureHours ? (
        <Text variant="bodySmall" style={{ margin: spacing16 }}>
          {t("T01418")}
        </Text>
      ) : (
        <>
          <Text variant="bodySmall">{t("T00581")}</Text>
          <MultiColorText
            leftText={`${t("T00582")}:`}
            rightText={nextDate}
            variant="titleMedium"
            rightTextProps={{ variant: "titleMedium" }}
            rightComponent={
              isFutureAvailabilityLoading && (
                <ActivityIndicator style={styles.loader} />
              )
            }
          />
          <PrimaryButton
            label="T00584"
            onPress={() => onPress(nextDate)}
            mode="outlined"
            style={{ alignSelf: "stretch" }}
            disabled={isFutureAvailabilityLoading}
          />
        </>
      )}
    </View>
  );
};

export default EmptySectionInfo;

const styles = StyleSheet.create({
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 1,
    marginTop: spacing16,
    gap: spacing8,
  },
  loader: { marginHorizontal: spacing36 },
});
