import { PrimaryButton } from "@components/buttons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSetPhone } from "@hooks/phoneVerification/useSetPhone";
import {
  ProfileCompletionStackParamList,
  ProfileStackParamsList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { spacing16, spacing4 } from "@styles/spacing";
import { theme, useAppTheme } from "@styles/theme";
import { isANDROID, isWeb } from "@utils/constants";
import { FC, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, HelperText, Text } from "react-native-paper";
import PhoneInput from "react-native-phone-number-input";
import { object, string } from "yup";
import VerificationCodeBottomSheet from "./Phone/VerificationCodeBottomSheet";
import useGetPhone from "@hooks/phoneVerification/useGetPhone";
import { FetchError } from "@components/errors";
import InfoTile from "@components/Tile/InfoTile";
import { showProfileVerificationSnackbar } from "@components/snackbars";

type VerifyPhoneScreenProps = CompositeScreenProps<
  NativeStackScreenProps<ProfileCompletionStackParamList, "VerifyPhone">,
  NativeStackScreenProps<ProfileStackParamsList, "Profile">
>;

export const VerifyPhone: FC<VerifyPhoneScreenProps> = ({
  navigation: { goBack },
}) => {
  const { t } = useTranslation();
  const phoneRef = useRef<PhoneInput>(null);
  const {
    colors: { primary, outline, error: errorColor, scrim },
  } = useAppTheme();
  const [isFocused, setIsFocused] = useState(false);
  const [codeSheetVisible, setCodeSheetVisible] = useState(false);

  const { mutate, isLoading } = useSetPhone({
    onSuccessCallback: () => setCodeSheetVisible(true),
  });

  const {
    isLoading: isGetPhoneLoading,
    isError,
    refetch,
    previousPhoneNumberWithoutCountryCode,
    isVerified,
  } = useGetPhone();

  const schema = object().shape({
    phone: string()
      .required(t("T00014"))
      .trim()
      .matches(/^\d+$/, t("T01518"))
      .test("isValidPhone", t("T01518"), function (value) {
        const isValid = phoneRef.current.isValidNumber(value);
        return isValid;
      }),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: previousPhoneNumberWithoutCountryCode || "",
    },
  });

  const { container, gapLarge, loading } = globalStyles;
  const {
    containerStyle,
    countryPickerButtonStyle,
    textContainerStyle,
    inputContainer,
  } = styles;

  const onSubmit = () =>
    mutate(
      phoneRef.current?.getNumberAfterPossiblyEliminatingZero().formattedNumber,
    );

  if (isGetPhoneLoading) return <ActivityIndicator style={loading} />;

  if (isError) return <FetchError coverScreen={true} action={refetch} />;

  return (
    <View style={[container, gapLarge]}>
      {isVerified && (
        <InfoTile status="success" content={<Text>{t("T01517")}</Text>} />
      )}
      <Text variant="bodyMedium">{t("T01510")}</Text>
      <View style={inputContainer}>
        <Controller
          name="phone"
          control={control}
          render={({
            field: { onChange, onBlur, ...fieldProps },
            fieldState: { error },
          }) => {
            return (
              <>
                <PhoneInput
                  autoFocus
                  disabled={isVerified}
                  defaultValue={previousPhoneNumberWithoutCountryCode}
                  ref={phoneRef}
                  onChangeText={onChange}
                  defaultCode="PL"
                  codeTextStyle={
                    isANDROID && {
                      fontWeight: "400",
                    }
                  }
                  layout="second"
                  containerStyle={[
                    containerStyle,
                    { opacity: isVerified ? 0.5 : 1 },
                  ]}
                  countryPickerButtonStyle={countryPickerButtonStyle}
                  textContainerStyle={[
                    textContainerStyle,
                    {
                      borderColor: error
                        ? errorColor
                        : isFocused
                        ? primary
                        : outline,
                      borderWidth: error || isFocused ? 2 : 1,
                    },
                  ]}
                  textInputStyle={[
                    { fontSize: spacing16 },
                    isANDROID && {
                      color: scrim,
                    },
                    isWeb &&
                      ({
                        outline: "transparent",
                        width: "100%",
                      } as unknown), //needed to reset inner text outline for web input
                  ]}
                  placeholder={t("T01508")}
                  countryPickerProps={{
                    disableNativeModal: true,
                    countryCodes: ["PL"],
                    visible: false,
                  }}
                  disableArrowIcon={true}
                  textInputProps={{
                    maxLength: 9,
                    textContentType: "none",
                    selectionColor: error ? errorColor : primary,
                    onBlur: () => {
                      onBlur();
                      setIsFocused(false);
                    },
                    onFocus: () => {
                      setIsFocused(true);
                    },
                    ...fieldProps,
                  }}
                />
                {error && (
                  <HelperText type="error" style={{ textAlign: "center" }}>
                    {error.message}
                  </HelperText>
                )}
              </>
            );
          }}
        />
      </View>
      <PrimaryButton
        onPress={handleSubmit(onSubmit)}
        label="T01516"
        loading={isLoading}
        disabled={isVerified}
      />
      {!isVerified && (
        <VerificationCodeBottomSheet
          codeSheetVisible={codeSheetVisible}
          setCodeSheetVisible={setCodeSheetVisible}
          phoneNumber={
            phoneRef.current?.getNumberAfterPossiblyEliminatingZero()
              .formattedNumber
          }
          onCodeCheckSuccessCb={() => {
            goBack();
            showProfileVerificationSnackbar(t("T01311"));
          }}
        />
      )}
    </View>
  );
};

export default VerifyPhone;

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: "center",
  },
  containerStyle: {
    width: "100%",
    height: 46,
  },
  countryPickerButtonStyle: {
    borderRadius: spacing4,
    borderWidth: 1,
    borderColor: theme.colors.outline,
    marginRight: spacing16,
    maxWidth: 80,
  },
  textContainerStyle: {
    paddingVertical: 0,
    borderRadius: spacing4,
  },
});
