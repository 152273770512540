import { TransKey } from "@globalTypes/i18next";

export const daysOfWeek: { transKey: TransKey; weekDayName: string }[] = [
  { transKey: "T00170", weekDayName: "Monday" },
  { transKey: "T00171", weekDayName: "Tuesday" },
  { transKey: "T00172", weekDayName: "Wednesday" },
  { transKey: "T00173", weekDayName: "Thursday" },
  { transKey: "T00174", weekDayName: "Friday" },
  { transKey: "T00175", weekDayName: "Saturday" },
  { transKey: "T00176", weekDayName: "Sunday" },
];
