import { FC, useMemo, useState } from "react";
import { Control, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, TouchableOpacity, View } from "react-native";

import { Input } from "@components/Input";

import { spacing4, spacing40, spacing8 } from "@styles/spacing";
import { MessageType } from "../ConversationScreen";
import { isIOS } from "@utils/constants";
import SvgSend from "@assets/svg/SvgSend";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { palettes } from "@styles/colors";

type Props = {
  isSendingMessage: boolean;
  onPress: () => void;
  isValidForm: boolean;
  control: Control<MessageType>;
  disabled?: boolean;
  onPressContainer?: () => void;
  autoFocus?: boolean;
};

const sendButtonWidth = spacing40;

export const ConversationInput: FC<Props> = ({
  isSendingMessage,
  onPress,
  isValidForm,
  control,
  disabled,
  onPressContainer,
  autoFocus,
}) => {
  const [inputHeight, setInputHeight] = useState(0);
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { textInput, sendButtonContainer, container, onPressContainerStyle } =
    styles;
  const message = useWatch<MessageType>({ control, name: "msg" })?.trim();
  const isDisabled = useMemo(
    () => !message || !isValidForm || disabled || isSendingMessage,
    [message, isValidForm, disabled, isSendingMessage],
  );

  return (
    <View style={container}>
      <Input
        mode="flat"
        name="msg"
        label={undefined}
        placeholder={`${t("T00374")}...`}
        control={control as unknown as Control}
        style={[
          textInput,
          {
            maxHeight: inputHeight * (isIOS ? 3.7 : 1.75),
          },
        ]}
        underlineColor="transparent"
        activeUnderlineColor={isIOS ? "transparent" : palettes.primary[20]}
        dense
        multiline
        containerStyle={{
          width: width - sendButtonWidth,
        }}
        onContentSizeChange={event => {
          if (inputHeight === 0) {
            setInputHeight(event.nativeEvent.contentSize.height);
          }
        }}
        onKeyPress={event => {
          if (event.nativeEvent.key === "Enter" && !isDisabled) {
            event.preventDefault();
            return onPress();
          }
        }}
        autoFocus={autoFocus}
      />
      <Pressable
        style={sendButtonContainer}
        onPress={onPress}
        disabled={isDisabled}>
        <SvgSend style={isDisabled && { opacity: 0.5 }} />
      </Pressable>
      {onPressContainer && (
        <TouchableOpacity
          onPress={onPressContainer}
          style={onPressContainerStyle}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    paddingStart: spacing8,
    paddingTop: spacing8,
  },
  textInput: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderRadius: 20,
  },
  sendButtonContainer: {
    marginStart: spacing4,
    width: sendButtonWidth,
    marginTop: -spacing8,
  },
  onPressContainerStyle: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
});
