import { Platform } from "react-native";
import { FC } from "react";
import { isWeb } from "@utils/constants";
import { useAuth } from "@contexts/AuthContext/auth";
import { useLocale } from "@hooks/useLocale";
import { useTranslation } from "react-i18next";
import { WEB_BUTTON_WIDTH } from "../helpers";

type GoogleLoginButtonType = Platform["OS"] extends "web"
  ? typeof import("@react-oauth/google").GoogleLogin
  : unknown;

let GoogleLoginButton: GoogleLoginButtonType;

if (isWeb) {
  GoogleLoginButton = (
    require("@react-oauth/google") as typeof import("@react-oauth/google")
  ).GoogleLogin;
}

const GoogleLoginWeb: FC = () => {
  const { handleGoogleLoginSuccess } = useAuth();
  const { t } = useTranslation();
  const { locale } = useLocale();
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <GoogleLoginButton
      onSuccess={async ({ credential }) =>
        await handleGoogleLoginSuccess(credential as string)
      }
      onError={(error: unknown) => {
        alert(t("T00832"));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        console.log({ error });
      }}
      width={WEB_BUTTON_WIDTH}
      shape="pill"
      text="continue_with"
      locale={locale?.code}
    />
  );
};

export default GoogleLoginWeb;
