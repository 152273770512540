import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, Card, Text } from "react-native-paper";
import { FetchError } from "@components/errors";
import { SvgError, SvgTrainingOption } from "@assets/svg";
import { getIconColorByStatus } from "@components/Tile/utils/InfoTile.utils";
import { globalStyles } from "@styles/global";
import { theme } from "@styles/theme";
import { useTranslation } from "react-i18next";
import { BaseTrainingTileProps } from "./trainingTile.types";
import StatusComponent from "@components/Status/StatusComponent";

const BaseTrainingTile: FC<BaseTrainingTileProps> = ({
  data,
  mode = "outlined",
  elevation,
  subtitle,
  isError,
  refetch,
  isFetched,
  showExercises = true,
  status,
  ...props
}) => {
  const { t } = useTranslation();
  const iconFillColor = getIconColorByStatus("warning");
  const { gapLarge, gapSmall, flex } = globalStyles;
  const { containerOutlined, cardContentStyle } = styles;
  return (
    <Card
      {...props}
      mode={mode}
      elevation={elevation as never}
      style={mode === "outlined" && containerOutlined}>
      <Card.Content>
        {!isFetched ? (
          <ActivityIndicator />
        ) : isError ? (
          <FetchError action={refetch} coverScreen={false} />
        ) : (
          <View style={[cardContentStyle, gapLarge]}>
            <View>
              <SvgTrainingOption width={40} height={40} />
            </View>
            <View style={[gapSmall, flex]}>
              <Text variant="titleMedium" numberOfLines={2}>
                {data?.name}
              </Text>
              {subtitle ? <Text variant="bodyMedium">{subtitle}</Text> : null}
              {showExercises && (
                <Text variant="bodyMedium" numberOfLines={4}>
                  {t("T00183", {
                    number: data?.exercises?.length,
                  })}
                </Text>
              )}
              {data?.reportDescription && (
                <View
                  style={[
                    { flexDirection: "row", alignItems: "center" },
                    gapSmall,
                  ]}>
                  <SvgError fill={iconFillColor} />
                  <Text variant="bodyMedium">{t("T00372")}</Text>
                </View>
              )}
              {status ? <StatusComponent status={status} /> : null}
            </View>
          </View>
        )}
      </Card.Content>
    </Card>
  );
};

export default BaseTrainingTile;

const styles = StyleSheet.create({
  cardContentStyle: {
    flexDirection: "row",
    alignItems: "center",
  },
  containerOutlined: {
    borderColor: theme.colors.outlineVariant,
  },
});
