import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} fill="none" {...props}>
    <Path
      fill="#4666FF"
      d="M14.25 14.25H3.75V3.75H9v-1.5H3.75a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V9h-1.5v5.25Zm-3.75-12v1.5h2.693L5.82 11.123l1.058 1.057 7.372-7.372V7.5h1.5V2.25H10.5Z"
    />
  </Svg>
);
export default SvgComponent;
