import { AvailabilityGroup } from "@components/PhysioAvailability/PhysioAvailability.types";
import AvailabilityTile from "@screens/Calendar/AvailabilityTile";
import { spacing16 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Animated, Easing, View } from "react-native";
import { Button, Card } from "react-native-paper";

type Props = {
  data: AvailabilityGroup[];
  date: Date;
  navToAvailability: (initialDate?: Date) => void;
};

export const PhysiotherapistAvailabilityCard: FC<Props> = ({
  data,
  date,
  navToAvailability,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [tileHeight, setTileHeight] = useState(0);

  const animatedHeight = useRef(new Animated.Value(0)).current;

  const toggleSection = () => {
    setIsExpanded(prev => !prev);
    Animated.timing(animatedHeight, {
      toValue: isExpanded ? 0 : tileHeight * data.slice(2)?.length + spacing16,
      duration: 500,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();
  };

  return (
    <Card mode="outlined" style={{ borderColor: theme.colors.outlineVariant }}>
      <View style={{ margin: spacing16 }}>
        {data.slice(0, 2)?.map((element, index) => {
          return (
            <AvailabilityTile
              key={`availability-item-${element.serviceType}-${index}`}
              mode="contained"
              onLayout={e => setTileHeight(e.nativeEvent.layout.height)}
              data={element}
            />
          );
        })}
        <Animated.View style={{ height: animatedHeight, overflow: "hidden" }}>
          {data.slice(2)?.map((element, index) => {
            return (
              <AvailabilityTile
                key={`availability-item-${element.serviceType}-${index}`}
                mode="contained"
                data={element}
              />
            );
          })}
        </Animated.View>

        <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
          {date.getTime() >= today.getTime() && (
            <Button mode="text" onPress={() => navToAvailability(date)}>
              {t("T00690")}
            </Button>
          )}
          {data.length > 2 && data.slice(2) && (
            <Button mode="text" onPress={toggleSection}>
              {t(isExpanded ? "T00917" : "T00918", {
                value: data.slice(2).length,
              })}
            </Button>
          )}
        </View>
      </View>
    </Card>
  );
};
