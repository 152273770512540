import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { globalStyles } from "@styles/global";
import { spacing16, spacing4 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { config } from "@utils/config";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Linking, Pressable, View } from "react-native";
import { Text } from "react-native-paper";

const WebBanner: FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const isSmallScreen = width < 500;
  return (
    <View
      style={[
        {
          backgroundColor: theme.colors.warning,
          padding: isSmallScreen ? spacing4 : spacing16,
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "center",
        },
        !isSmallScreen && globalStyles.gapSmall,
      ]}>
      <Text variant="labelLarge" style={{ textAlign: "center" }}>
        {t("T01499")}
      </Text>
      <Pressable
        onPress={() => Linking.openURL(`${config.EXPO_PUBLIC_API_BASE_URL}/`)}
        style={{
          alignSelf: "center",
        }}>
        <Text
          variant="labelLarge"
          style={{
            color: theme.colors.primary,
            textAlign: "center",
          }}>
          {t("T01500")}
        </Text>
      </Pressable>
    </View>
  );
};

export default WebBanner;
