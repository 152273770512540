import { getISOWeeksInYear, getMonth, getWeek, getYear } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Button, IconButton } from "react-native-paper";

import { PickerWeek } from "./Picker/Week";

import { calculateMonth } from "./helpers";

import { spacing8 } from "@styles/spacing";
import { days, months } from "@utils/constants";
import { DateArrayType } from "./Calendar";
import {
  getFirstDayOfWeekByWeekIndex,
  getFutureWeekBoundary,
  getPastWeekBoundary,
  getWeekCalendarTitle,
  getWeekDaysArray,
} from "./helpers/weekCalendarHelpers";

type Props = {
  changeCalendarMode: () => void;
  currentDate: DateArrayType;
  setCurrentDate: (data: DateArrayType) => void;
  initialDates: DateArrayType[];
  onSelectDate: (data: DateArrayType, dontClose?: boolean) => void;
  multiselect?: boolean;
  onDismiss?: () => void;
  onSubmit?: (dates: DateArrayType[]) => void;
  selectedDays: DateArrayType[];
  setSelectedDays: (dates: DateArrayType[]) => void;
  datesWithEvents?: DateArrayType[];
  type?: "week" | "month";
  limitRange?: boolean;
  blockPast?: boolean;
  onChangeMonth?: (date: Date) => void;
  onWeekChange?: (week: Date) => void;
  disableOnPress?: boolean;
  disableModeChange?: boolean;
};

export const CalendarPicker = ({
  changeCalendarMode,
  currentDate: propsCurrentDate,
  initialDates,
  multiselect,
  onDismiss,
  onSubmit,
  selectedDays,
  setSelectedDays,
  type = "month",
  limitRange,
  blockPast,
  onChangeMonth,
  onSelectDate,
  onWeekChange,
  disableModeChange,
  ...props
}: Props) => {
  const today = useMemo(() => new Date(), []);
  today.setHours(0, 0, 0, 0);
  const [currentDate, setCurrentDate] = useState(
    propsCurrentDate
      ? new Date(
          initialDates?.length > 0
            ? initialDates[0].date
            : propsCurrentDate.date,
        )
      : today,
  );
  const [selectedDate, setSelectedDate] = useState<DateArrayType>(
    !multiselect && initialDates?.length
      ? { date: new Date(initialDates[0].date) }
      : { date: today },
  );
  const [weekNumber, setWeekNumber] = useState<number>(getWeek(currentDate));
  const [currYear, setCurrYear] = useState<number>(new Date().getFullYear());
  const { selectDate, container, arrows, dayLetter, footer, weekArrows } =
    styles;
  const { t } = useTranslation();
  const isWeek = type === "week";

  useEffect(
    () => setCurrentDate(new Date(propsCurrentDate.date)),
    [propsCurrentDate],
  );

  const currentMonth = getMonth(currentDate);
  const currentMonthName = months[currentMonth];
  const currentYear = getYear(currentDate);
  const firstDayOfTheMonthTemp = new Date(currentDate);
  firstDayOfTheMonthTemp.setDate(1);

  const firstDay = firstDayOfTheMonthTemp.getDay();
  const monthMap = useMemo(
    () => calculateMonth(firstDay, currentMonth, currentYear, type),
    [currentMonth, currentYear, firstDay, type],
  );

  const monthSelectText = `${t(currentMonthName)} ${currentYear}`;

  const changeMonth = useCallback(
    (direction: number) => {
      const tempDate = new Date(currentDate);
      tempDate.setDate(1);
      tempDate.setMonth(currentMonth + direction);
      setCurrentDate(tempDate);
      onChangeMonth?.(tempDate);
      onSelectDate?.({ date: tempDate }, true);
    },
    [currentDate, currentMonth, onChangeMonth, onSelectDate],
  );

  const incrementWeek = useCallback(() => {
    const weeksNumberInCurrentYear = getISOWeeksInYear(
      new Date(currYear, 0, 1),
    );
    if (weeksNumberInCurrentYear === weekNumber) {
      setCurrYear(prev => prev + 1);
      setWeekNumber(1);
    } else {
      setWeekNumber(prev => prev + 1);
    }
  }, [currYear, weekNumber]);

  const decrementWeek = useCallback(() => {
    if (weekNumber === 1) {
      setCurrYear(prev => prev - 1);
      const lastWeekOfPrevYear = getISOWeeksInYear(new Date(currYear, 0, 1));

      setWeekNumber(lastWeekOfPrevYear);
    } else {
      setWeekNumber(prev => prev - 1);
    }
  }, [currYear, weekNumber]);

  const shouldBlockLeftArrow = useMemo(
    () => weekNumber === getPastWeekBoundary(today),
    [today, weekNumber],
  );
  const shouldBlockRightArrow = useMemo(
    () => weekNumber === getFutureWeekBoundary(today),
    [today, weekNumber],
  );

  const onPressArrow = useCallback(
    (value: number) => {
      if (!isWeek) {
        changeMonth(value);
      } else {
        value === 1 ? incrementWeek() : decrementWeek();
      }
    },
    [changeMonth, decrementWeek, incrementWeek, isWeek],
  );

  const commonPickerWeekProps = {
    multiselect,
    currentYear,
    currentMonth,
    selectedDays,
    setSelectedDays,
    setOwnCurrentDate: setCurrentDate,
    selectedDate,
    setSelectedDate,
    type,
    blockPast,
    onSelectDate,
    ...props,
  };

  const singleWeek = useMemo(
    () => getWeekDaysArray(getFirstDayOfWeekByWeekIndex(weekNumber, currYear)),
    [currYear, weekNumber],
  );

  const weekcalendarTitle = useMemo(
    () => getWeekCalendarTitle(singleWeek),
    [singleWeek],
  );

  useEffect(() => {
    onWeekChange && onWeekChange(singleWeek[0]);
  }, [onWeekChange, singleWeek]);

  return (
    <>
      <View style={container}>
        {!isWeek && (
          <TouchableOpacity
            onPress={disableModeChange ? null : changeCalendarMode}
            style={selectDate}>
            <Text>{monthSelectText}</Text>
            {!disableModeChange && <IconButton icon="menu-down" />}
          </TouchableOpacity>
        )}
        <View style={[arrows, isWeek && weekArrows]}>
          <IconButton
            icon="chevron-left"
            onPress={() => onPressArrow(-1)}
            style={{ marginHorizontal: 0 }}
            disabled={
              isWeek
                ? shouldBlockLeftArrow
                : limitRange &&
                  currentMonth < today.getMonth() &&
                  currentYear <= today.getFullYear()
            }
          />
          {isWeek && <Text>{weekcalendarTitle}</Text>}
          <IconButton
            icon="chevron-right"
            onPress={() => onPressArrow(1)}
            style={{
              marginHorizontal: 0,
            }}
            disabled={
              isWeek
                ? shouldBlockRightArrow
                : limitRange &&
                  currentMonth - 1 > today.getMonth() &&
                  currentYear >= today.getFullYear()
            }
          />
        </View>
      </View>
      <View>
        <View
          style={{
            width: "100%",
          }}>
          <View style={container}>
            {days.map((e, i) => (
              <Text key={`dayName-${e}-${i}`} style={dayLetter}>
                {t(e)}
              </Text>
            ))}
          </View>
          {!isWeek ? (
            monthMap.map((e: Date[], i: number) => (
              <PickerWeek
                key={`month-${currentMonthName}-week-${i}`}
                data={e}
                shouldDisableDays={
                  limitRange &&
                  (currentMonth + 1 < today.getMonth() ||
                    currentMonth - 2 > today.getMonth())
                }
                {...commonPickerWeekProps}
              />
            ))
          ) : (
            <PickerWeek data={singleWeek} {...commonPickerWeekProps} />
          )}
        </View>
      </View>
      {multiselect && (
        <View style={footer}>
          <Button
            mode="text"
            onPress={() => {
              setSelectedDays([]);
              onDismiss && onDismiss();
            }}>
            {t("T00145")}
          </Button>
          <Button
            mode="text"
            onPress={() => {
              onSubmit && onSubmit(selectedDays);
            }}>
            {t("T00163")}
          </Button>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectDate: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: spacing8,
  },
  arrows: {
    flexDirection: "row",
    alignItems: "center",
  },
  weekArrows: {
    justifyContent: "space-between",
    width: "100%",
  },
  dayLetter: {
    flex: 1,
    padding: spacing8,
    textAlign: "center",
  },
  footer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});
