import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";
import WebView from "react-native-webview";
import { renderLoader } from "@components/Modals/WebViewModal";
import { config } from "@utils/config";

export type SpecializationRequirementsScreenProps = PropsWithChildren<
  NativeStackScreenProps<
    ProfileCompletionStackParamList,
    "SpecializationRequirements"
  >
>;

const SpecializationRequirements: FC<
  SpecializationRequirementsScreenProps
> = () => (
  <WebView
    source={{
      uri: `${config.EXPO_PUBLIC_API_BASE_URL}/specialization-requirements/`,
    }}
    startInLoadingState={true}
    renderLoading={renderLoader}
  />
);

export default SpecializationRequirements;
