import { AppbarTitleWithBackAction } from "@components/Appbar";
import {
  FullScreenModal,
  FullScreenModalProps,
} from "@components/Modals/FullScreenModal";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { FC, useCallback } from "react";
import { Image } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import WebView from "react-native-webview";
import { useHeaderHeight } from "@react-navigation/elements";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { spacing16 } from "@styles/spacing";
import { isANDROID } from "@utils/constants";
import { ModalAnimationType } from "@globalTypes/common.types";

type WebViewModalProps = Omit<FullScreenModalProps, "renderContent"> & {
  uri: string;
  title: string;
  type: "image" | "web";
  modalAnimation: ModalAnimationType;
};

export const renderLoader = () => (
  <ActivityIndicator style={globalStyles.loading} />
);

const WebViewModal: FC<WebViewModalProps> = ({
  uri,
  type,
  isModalVisible,
  setIsModalVisible,
  title,
  modalAnimation,
}) => {
  const { width, height } = useWindowDimensions();
  const headerHeight = useHeaderHeight();

  const renderContent = useCallback(
    () => (
      <SafeAreaView
        style={{
          width,
          height,
          backgroundColor: palettes.primary["100"],
        }}>
        <AppbarTitleWithBackAction
          title={title}
          onClose={() => setIsModalVisible(false)}
          containerStyle={[isANDROID && { marginTop: -spacing16 }]}
        />
        {type === "image" ? (
          <Image
            source={{ uri }}
            style={{
              width,
              height: height - headerHeight,
            }}
            resizeMode="contain"
          />
        ) : (
          <WebView
            source={{
              uri,
            }}
            startInLoadingState={true}
            renderLoading={renderLoader}
          />
        )}
      </SafeAreaView>
    ),
    [headerHeight, height, setIsModalVisible, title, type, uri, width],
  );
  return (
    <FullScreenModal
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      renderContent={renderContent}
      withoutBackDrop
      modalAnimation={modalAnimation}
    />
  );
};

export default WebViewModal;
