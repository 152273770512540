import { FC } from "react";
import { BackAndFrontModelProps } from "../humanModel.types";
import MuscleFront from "./MuscleFront";
import MuscleBack from "./MuscleBack";

const MuscleModel: FC<BackAndFrontModelProps> = ({ orientation, ...props }) =>
  orientation === "front" ? (
    <MuscleFront
      key={`muscle-model-front-${props.defaultSelected?.length}`}
      {...props}
    />
  ) : (
    <MuscleBack
      key={`muscle-model-back-${props.defaultSelected?.length}`}
      {...props}
    />
  );

export default MuscleModel;
