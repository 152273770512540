import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { spacing16, spacing32, spacing4, spacing8 } from "@styles/spacing";
import { chartColors, palettes } from "@styles/colors";
import { FC } from "react";
import { isWeb } from "@utils/constants";
import {
  VictoryAxisType,
  VictoryBarType,
  VictoryChartType,
  VictoryGroupType,
  VictoryStackType,
} from "./chart.types";
import { BasicGroupedBarItemType } from "./BarChartNew";
import { GroupedStackedBarItemType } from "./GroupedStackedBarChart";

export const GroupedChartLegend: FC<{ displayedMonth: string[] }> = ({
  displayedMonth,
}) => {
  return (
    <View style={styles.legendContainer}>
      {displayedMonth.length
        ? displayedMonth.map((e, i) => (
            <View
              key={`legend-item-${e}-${i}`}
              style={[
                styles.legendItem,
                {
                  backgroundColor:
                    i === 0 ? palettes.neutral[26] : palettes.complete[25],
                },
              ]}>
              <Text
                style={{
                  color: i === 0 ? palettes.primary[100] : palettes.primary[0],
                }}>
                {e}
              </Text>
            </View>
          ))
        : null}
    </View>
  );
};

export const SingleChartLegend: FC<{ displayLegend: string }> = ({
  displayLegend,
}) => (
  <View style={styles.legendContainer}>
    {displayLegend.length ? (
      <View style={styles.legendItem}>
        <Text
          variant="titleSmall"
          style={{
            color: palettes.primary[0],
          }}>
          {displayLegend}
        </Text>
      </View>
    ) : null}
  </View>
);

export const ChartHeader: FC<{
  data: GroupedStackedBarItemType[] | BasicGroupedBarItemType[];
}> = ({ data }) => {
  const legendItems = data?.[0].groupItems.map(
    (e: { label: string }) => e.label,
  );

  return (
    <View style={styles.headerContainer}>
      {legendItems?.map((e, i) => (
        <View
          style={[
            styles.headerContainer,
            {
              marginRight: i < legendItems.length ? spacing8 : 0,
            },
          ]}
          key={`legend-item-${e}-${i}`}>
          <View
            style={[
              styles.colorSquare,
              {
                backgroundColor:
                  i === 0 ? chartColors.primary : chartColors.secondary,
              },
            ]}
          />
          <Text variant="titleMedium">{e}</Text>
        </View>
      ))}
    </View>
  );
};

export let VictoryBar: VictoryBarType;
export let VictoryChart: VictoryChartType;
export let VictoryStack: VictoryStackType;
export let VictoryAxis: VictoryAxisType;
export let VictoryGroup: VictoryGroupType;

export const loadVictoryComponents = () => {
  if (!isWeb) {
    VictoryBar = (require("victory-native") as typeof import("victory-native"))
      .VictoryBar;
    VictoryChart = (
      require("victory-native") as typeof import("victory-native")
    ).VictoryChart;
    VictoryStack = (
      require("victory-native") as typeof import("victory-native")
    ).VictoryStack;
    VictoryAxis = (require("victory-native") as typeof import("victory-native"))
      .VictoryAxis;
    VictoryGroup = (
      require("victory-native") as typeof import("victory-native")
    ).VictoryGroup;
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    VictoryBar = (require("victory") as typeof import("victory")).VictoryBar;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    VictoryChart = (require("victory") as typeof import("victory"))
      .VictoryChart;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    VictoryStack = (require("victory") as typeof import("victory"))
      .VictoryStack;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    VictoryAxis = (require("victory") as typeof import("victory")).VictoryAxis;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    VictoryGroup = (require("victory") as typeof import("victory"))
      .VictoryGroup;
  }
};

const styles = StyleSheet.create({
  legendContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: spacing4,
    minHeight: spacing32,
  },
  legendItem: {
    padding: spacing4,
    borderRadius: spacing4,
    backgroundColor: palettes.complete[25],
  },
  headerContainer: { flexDirection: "row", alignItems: "center" },
  colorSquare: {
    height: spacing16,
    width: spacing16,
    borderRadius: 2,
    marginRight: spacing8,
  },
});
