import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";

type DataForContextType = {
  hasInjuriesOrMedicalConditions?: boolean;
  bodyParts?: string[];
  time?: 1 | 2 | 3;
};

type TrainingSelectionAssistantContextType = {
  data: DataForContextType;
  updateData: (data: DataForContextType) => void;
  clearData: () => void;
};

const TrainingSelectionAssistantContext =
  createContext<TrainingSelectionAssistantContextType>(
    {} as TrainingSelectionAssistantContextType,
  );

const TrainingSelectionAssistantContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [data, setData] = useState<DataForContextType>({
    hasInjuriesOrMedicalConditions: null,
    bodyParts: [],
  });

  const updateData = useCallback((data: DataForContextType) => {
    setData(previousData => ({ ...previousData, ...data }));
  }, []);

  const clearData = () =>
    setData({ hasInjuriesOrMedicalConditions: null, bodyParts: [] });

  const contextValue = {
    data,
    updateData,
    setData,
    clearData,
  };

  return (
    <TrainingSelectionAssistantContext.Provider value={contextValue}>
      {children}
    </TrainingSelectionAssistantContext.Provider>
  );
};
export {
  TrainingSelectionAssistantContext,
  TrainingSelectionAssistantContextProvider,
};
