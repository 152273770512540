import {
  TrainingsNotificationSetting,
  TrainingsNotificationSettingsFormType,
} from "@globalTypes/messagesNotificationSetting";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrors } from "@hooks/useErrors";
import { queryKeysNotificationSettings } from "@screens/Profiles/MyProfile/queryKeysSettings";
import {
  checkIfUserHasDifferentNotificationHoursForDays,
  getTodaysWeekDayName,
} from "@screens/Profiles/MyProfile/Settings/utils";
import {
  getTrainingsNotificationsSettings,
  updateTrainingsNotificationsSettings,
} from "@services/ApiService/appSettings";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { boolean, object, string } from "yup";

const schema = object().shape({
  reminder: boolean(),
  notificationTimeMonday: string(),
  notificationTimeTuesday: string(),
  notificationTimeWednesday: string(),
  notificationTimeThursday: string(),
  notificationTimeFriday: string(),
  notificationTimeSaturday: string(),
  notificationTimeSunday: string(),
});

export const useTrainingsNotificationsSettings = () => {
  const [trainingsNotificationsSwitchOn, setTrainingsNotificationsSwitchOn] =
    useState(false);
  const [individualDayReminderSwitchOn, setIndividualDayReminderSwitchOn] =
    useState(false);
  const { setErrorsFromResponse } = useErrors();

  const { data, isSuccess, isError, isLoading, refetch } = useQuery({
    queryKey: queryKeysNotificationSettings.workouts(),
    queryFn: getTrainingsNotificationsSettings,
  });

  const queryClient = useQueryClient();

  const onSuccessHandler = useCallback(
    async () =>
      await queryClient.invalidateQueries(
        queryKeysNotificationSettings.workouts(),
      ),
    [queryClient],
  );

  const onErrorHandler = useCallback(
    ({ response }: AxiosError) => setErrorsFromResponse(response),
    [setErrorsFromResponse],
  );

  const { mutate: updateTrainingsNotifications } = useMutation({
    mutationFn: async ({
      id,
      ...rest
    }: Partial<TrainingsNotificationSetting>) => {
      await updateTrainingsNotificationsSettings({
        id,
        ...rest,
      });
    },
    onError: onErrorHandler,
    onSuccess: onSuccessHandler,
  });

  useEffect(() => {
    if (isSuccess) setTrainingsNotificationsSwitchOn(data?.notificationsActive);
  }, [data?.notificationsActive, isSuccess]);

  const onTrainingsNotificationsSwitchChange = useCallback(
    (val: boolean) => {
      setTrainingsNotificationsSwitchOn(prev => !prev);
      updateTrainingsNotifications({
        id: data?.id,
        notificationsActive: val,
      });
    },
    [data?.id, updateTrainingsNotifications],
  );

  const onSubmitSingleDay = useCallback(
    ({
      notificationTimeMonday,
      reminder,
    }: TrainingsNotificationSettingsFormType) => {
      const { id } = data;
      updateTrainingsNotifications({
        id,
        reminder,
        notificationTimeMonday,
        notificationTimeTuesday: notificationTimeMonday,
        notificationTimeWednesday: notificationTimeMonday,
        notificationTimeThursday: notificationTimeMonday,
        notificationTimeFriday: notificationTimeMonday,
        notificationTimeSaturday: notificationTimeMonday,
        notificationTimeSunday: notificationTimeMonday,
      });
    },
    [data, updateTrainingsNotifications],
  );

  const onSubmitMultipleDays = useCallback(
    (formData: TrainingsNotificationSettingsFormType) => {
      const { id } = data;
      updateTrainingsNotifications({
        id,
        ...formData,
      });
    },
    [data, updateTrainingsNotifications],
  );

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver<TrainingsNotificationSettingsFormType>(schema),
  });

  useEffect(() => {
    setValue("notificationTimeMonday", data?.notificationTimeMonday);
    setValue("notificationTimeTuesday", data?.notificationTimeTuesday);
    setValue("notificationTimeWednesday", data?.notificationTimeWednesday);
    setValue("notificationTimeThursday", data?.notificationTimeThursday);
    setValue("notificationTimeFriday", data?.notificationTimeFriday);
    setValue("notificationTimeSaturday", data?.notificationTimeSaturday);
    setValue("notificationTimeSunday", data?.notificationTimeSunday);
    setValue("reminder", data?.reminder);
  }, [
    data?.notificationTimeFriday,
    data?.notificationTimeMonday,
    data?.notificationTimeSaturday,
    data?.notificationTimeSunday,
    data?.notificationTimeThursday,
    data?.notificationTimeTuesday,
    data?.notificationTimeWednesday,
    data?.reminder,
    setValue,
  ]);

  const notificationTimes = useMemo(
    () => [
      data?.notificationTimeMonday,
      data?.notificationTimeTuesday,
      data?.notificationTimeWednesday,
      data?.notificationTimeThursday,
      data?.notificationTimeFriday,
      data?.notificationTimeSaturday,
      data?.notificationTimeSunday,
    ],
    [
      data?.notificationTimeFriday,
      data?.notificationTimeMonday,
      data?.notificationTimeSaturday,
      data?.notificationTimeSunday,
      data?.notificationTimeThursday,
      data?.notificationTimeTuesday,
      data?.notificationTimeWednesday,
    ],
  );

  const onIndividualDayReminderSwitchChange = useCallback(() => {
    setIndividualDayReminderSwitchOn(prev => {
      void handleSubmit(!prev ? onSubmitMultipleDays : onSubmitSingleDay)();
      return !prev;
    });
  }, [handleSubmit, onSubmitMultipleDays, onSubmitSingleDay]);

  useEffect(() => {
    notificationTimes?.length &&
      isSuccess &&
      setIndividualDayReminderSwitchOn(
        checkIfUserHasDifferentNotificationHoursForDays(notificationTimes),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const todaysReminderTime = useMemo(() => {
    const key = `notificationTime${getTodaysWeekDayName()}` as keyof Omit<
      TrainingsNotificationSettingsFormType,
      "reminder"
    >;
    return data?.[key]?.slice(0, 5);
  }, [data]);

  return {
    trainingsNotificationsSwitchOn,
    onTrainingsNotificationsSwitchChange,
    onIndividualDayReminderSwitchChange,
    individualDayReminderSwitchOn,
    isError,
    isLoading,
    refetch,
    control,
    notificationTimes,
    handleSubmitSingleDay: handleSubmit(onSubmitSingleDay),
    handleSubmitMultipleDays: handleSubmit(onSubmitMultipleDays),
    todaysReminderTime,
  };
};
