import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";

import { SvgStickyNote } from "@assets/svg";
import ReportWrapper from "@components/Report/ReportWrapper";
import { ReportProps } from "@components/Report/report.types";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";

type RecordTitleWithIconProps = ReportProps & {
  text: string;
  isMyRecord?: boolean;
};

export const RecordTitleWithIcon: FC<{
  text: string;
}> = ({ text }) => {
  const { titleContainer } = styles;
  const { flex } = globalStyles;
  return (
    <View style={titleContainer}>
      <SvgStickyNote />
      <Text variant="titleLarge" style={flex}>
        {text}
      </Text>
    </View>
  );
};

export const RecordTitleWithReportWrapper: FC<RecordTitleWithIconProps> = ({
  text,
  isMyRecord,
  ...props
}) => {
  return !isMyRecord ? (
    <ReportWrapper {...props}>
      <RecordTitleWithIcon text={text} />
    </ReportWrapper>
  ) : (
    <RecordTitleWithIcon text={text} />
  );
};

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    columnGap: spacing16,
  },
});
