import { pickFile } from "@utils/pickFile";
import { DocumentPickerResult } from "expo-document-picker";
import {
  ImagePickerResult,
  launchImageLibraryAsync,
  MediaTypeOptions,
} from "expo-image-picker";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { allFileTypes } from "./constants";
import {
  MediaType,
  PickedAssetType,
  PickFilesFromDevice,
  useImageOrVideoOptions,
} from "./types";
import {
  bytesToMegabytes,
  parsePickedElements,
  validateFileResults,
  validateGalleryResults,
} from "./utils";

export const useResources = ({
  mediaTypes = MediaTypeOptions.All,
  multiselect = false,
  initialData = null,
  supportedFileTypes = allFileTypes,
  maxNumberOfFiles = 5,
}: useImageOrVideoOptions) => {
  const [pickedFiles, setPickedFiles] =
    useState<PickedAssetType[]>(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const invalidFileFormatAlert = useCallback(
    (name: string) => alert(t("T01129", { value: name })),
    [t],
  );

  const invalidFileSizeAlert = useCallback(
    (maxSize: number, name: string) =>
      alert(
        `${t("T01130", { value: name })} ${t("T01131", {
          value: bytesToMegabytes(maxSize),
        })}`,
      ),
    [t],
  );

  const maxNumberOfFilesAlert = useCallback(
    () =>
      alert(
        t("T01132", {
          value: maxNumberOfFiles,
        }),
      ),
    [maxNumberOfFiles, t],
  );

  const pickFilesFromDevice: PickFilesFromDevice = useCallback(
    async galleryType => {
      setIsLoading(true);
      let result: ImagePickerResult | DocumentPickerResult;
      let invalidIndices: number[] = [];
      const validationCommonArgs = {
        supportedFileTypes,
        invalidFileSizeAlert,
        invalidFileFormatAlert,
      };
      if (galleryType === MediaType.gallery) {
        result = await launchImageLibraryAsync({
          mediaTypes,
          allowsEditing: false,
          aspect: [4, 4],
          quality: 0.7,
          allowsMultipleSelection: multiselect,
        });
        if (!result.canceled) {
          const validationResults = validateGalleryResults({
            results: result.assets,
            ...validationCommonArgs,
          });
          invalidIndices = [...validationResults];
        }
      } else {
        result = await pickFile(multiselect);
        if (!result.canceled) {
          const validationResults = validateFileResults({
            results: result.assets,
            ...validationCommonArgs,
          });
          invalidIndices = [...validationResults];
        }
      }

      result?.canceled && setIsLoading(false);

      setTimeout(() => {
        if (!result.canceled) {
          setIsLoading(false);
          const validAssets = result.assets.filter((asset, index) => {
            if (!invalidIndices.includes(index)) return asset;
          });
          const parsedData = parsePickedElements(validAssets);
          if (parsedData?.length) {
            if (!pickedFiles) {
              if (
                multiselect &&
                maxNumberOfFiles &&
                parsedData.length > maxNumberOfFiles
              ) {
                const files = parsedData.slice(0, maxNumberOfFiles);
                setPickedFiles(files);
                return maxNumberOfFilesAlert();
              }
              return setPickedFiles(parsedData);
            }
            setPickedFiles(prevFiles =>
              parsedData.some(({ name }) =>
                prevFiles.some(f => f.name === name),
              )
                ? prevFiles
                : [...prevFiles, ...parsedData],
            );
          }
        }
      });
    },
    [
      invalidFileFormatAlert,
      invalidFileSizeAlert,
      maxNumberOfFiles,
      maxNumberOfFilesAlert,
      mediaTypes,
      multiselect,
      pickedFiles,
      supportedFileTypes,
    ],
  );

  const removeFile = useCallback(
    (fileUri: string) =>
      setPickedFiles(prevFiles =>
        prevFiles.filter(f => {
          const { uri, name } = f;
          if (uri) return uri !== fileUri;
          else if (typeof name === "string") return name !== fileUri;
          else return f;
        }),
      ),
    [],
  );

  const clearFiles = useCallback(() => setPickedFiles(null), []);

  return {
    pickedFiles,
    pickFilesFromDevice,
    isLoading,
    removeFile,
    clearFiles,
    maxNumberOfFilesAlert,
  };
};
