import { LIST } from "@utils/constants";

const keys = {
  intelligentAssistantTrainings: "intelligentAssistantTrainings",
};

export const inteliigentAssistantQueryKeys = {
  all: [keys.intelligentAssistantTrainings] as const,
  list: (bodyParts: string[], time: 1 | 2 | 3) =>
    [...inteliigentAssistantQueryKeys.all, LIST, ...bodyParts, time] as const,
};
