import { queryKeysNotificationSettings } from "@screens/Profiles/MyProfile/queryKeysSettings";
import { getNotificationMainSettings } from "@services/ApiService/appSettings";
import { useQuery } from "@tanstack/react-query";
import { showAlert } from "@utils/showAlert";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const useNotificationMainSettings = () => {
  const { t } = useTranslation();
  const query = useQuery(
    queryKeysNotificationSettings.all,
    getNotificationMainSettings,
    {
      select: data => data.length && data[0],
    },
  );
  useEffect(() => {
    if (query?.isError) showAlert(t("T00160"));
  }, [query?.isError, t]);
  return query;
};
