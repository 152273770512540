import ServiceTile from "@components/Tile/service/ServiceTile";
import { TranslationKey } from "@globalTypes/i18next";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, Text } from "react-native-paper";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { View } from "react-native";
import { Button } from "@components/Button";
import { globalStyles } from "@styles/global";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteService } from "@api/verifications";
import { useErrors } from "@hooks/useErrors";
import { AxiosError } from "axios";
import { ServiceDataType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import {
  queryKeysServices,
  queryKeysVerificationServices,
} from "@screens/Common/queryKeysServices";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { PrimaryButton } from "@components/buttons";
import { spacing16 } from "@styles/spacing";
import { AddServicePricesBottomSheetContent } from "@components/BottomSheetContents";
import AddServicesBottomSheetContent, {
  AddingServiceData,
} from "@components/BottomSheetContents/AddServiceBottomSheetContent";
import { queryKeysVerficiations } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/queryKeysPhysiotherapistVerification";
import { getCurrencyAmount } from "@services/ApiService/common";
import { useAuth } from "@contexts/AuthContext/auth";
import { FetchError } from "@components/errors";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import { useInvalidateProfileDetails } from "@hooks/index";
import AddFreeServiceBottomSheetContent from "@components/BottomSheetContents/AddFreeServiceBottomSheetContent";

type ServicesListProps = {
  services: ServiceDataType[];
  emptyText: TranslationKey;
};

const ServicesList: FC<ServicesListProps> = ({ services, emptyText }) => {
  const { t } = useTranslation();
  const { isPhysiotherapist } = useUserDetails();
  const { setErrorsFromResponse } = useErrors();
  const queryClient = useQueryClient();
  const [currentlySelectedService, setCurrentlySelectedService] =
    useState<ServiceDataType>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditOrRemoveModalVisible, setIsEditOrRemoveModalVisible] =
    useState(false);
  const [addServicesModalVisible, setAddServicesModalVisible] = useState(false);
  const [addPricesModalVisible, setAddPricesModalVisible] = useState(false);
  const [savedData, setSavedData] = useState<AddingServiceData>(
    {} as AddingServiceData,
  );

  const {
    user: { currency },
  } = useAuth();
  const { errors } = useErrors();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();

  const {
    data: currencyAmount,
    isLoading: currencyAmountLoading,
    isError: currencyAmountError,
    refetch: refetchCurrencyAmount,
  } = useQuery({
    queryFn: getCurrencyAmount,
    queryKey: queryKeysVerficiations.currencyOptions(),
    enabled: isPhysiotherapist,
  });

  const onDeleteServiceSuccess = async () => {
    await Promise.all([
      invalidateProfileDetails(),
      queryClient.invalidateQueries({
        queryKey: queryKeysServices.all,
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysVerificationServices.all,
      }),
      queryClient.invalidateQueries({
        queryKey:
          queryKeysVerficiations.verificationProgressRehabInSubscreens(),
      }),
    ]);
    setIsDeleteModalVisible(false);
    showProfileVerificationSnackbar(t("T01311"));
  };

  const { isLoading, mutate } = useMutation({
    mutationFn: deleteService,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: onDeleteServiceSuccess,
  });

  const handleServiceEditPress = (service: ServiceDataType) => {
    setCurrentlySelectedService(service);
    setIsEditOrRemoveModalVisible(true);
  };

  const handleRemoveServicePress = () => {
    setIsEditOrRemoveModalVisible(false);
    setTimeout(() => setIsDeleteModalVisible(true), 500);
  };

  const openModal = useCallback(() => {
    setIsEditOrRemoveModalVisible(false);
    setTimeout(() => setAddServicesModalVisible(true), 500);
  }, []);

  const onClose = (data: AddingServiceData) => {
    setSavedData(data);
    setAddServicesModalVisible(false);
    setTimeout(() => setAddPricesModalVisible(true), 500);
  };

  if (currencyAmountLoading && isPhysiotherapist)
    return <ActivityIndicator style={globalStyles.loading} />;

  if (currencyAmountError) return <FetchError action={refetchCurrencyAmount} />;
  return (
    <>
      {services?.length ? (
        services.map((item, index) => (
          <ServiceTile
            service={item}
            key={`${item.serviceItem.name}-${index}`}
            onPress={
              isPhysiotherapist ? () => handleServiceEditPress(item) : null
            }
            onlyForRehabUse
          />
        ))
      ) : (
        <Text variant="bodyMedium">{t(emptyText)}</Text>
      )}
      {isPhysiotherapist && currentlySelectedService && (
        <>
          <BottomModalContainer
            modalVisible={isDeleteModalVisible}
            setModalVisible={setIsDeleteModalVisible}>
            <View style={globalStyles.gapLarge}>
              <Text variant="bodyLarge">{t("T00853")}</Text>
              <Text variant="bodyMedium">{t("T00854")}</Text>
              <Text variant="bodyMedium">{t("T00856")}</Text>
              <Button
                label="T00852"
                onPress={() => mutate(currentlySelectedService.id)}
                mode="contained"
                loading={isLoading}
              />
            </View>
          </BottomModalContainer>
          <BottomModalContainer
            modalVisible={isEditOrRemoveModalVisible}
            setModalVisible={setIsEditOrRemoveModalVisible}>
            <View style={globalStyles.gapLarge}>
              <View style={{ marginTop: spacing16 }}>
                <ServiceTile
                  service={currentlySelectedService}
                  onlyForRehabUse
                />
              </View>
              <PrimaryButton
                label="T01250"
                onPress={openModal}
                mode="outlined"
              />
              <PrimaryButton
                label="T00852"
                onPress={handleRemoveServicePress}
                mode="outlined"
              />
            </View>
          </BottomModalContainer>
          <BottomModalContainer
            modalVisible={addServicesModalVisible}
            setModalVisible={setAddServicesModalVisible}>
            {currentlySelectedService.serviceItem.freeService ? (
              <AddFreeServiceBottomSheetContent
                serviceName={currentlySelectedService.serviceItem.name}
                serviceItem={currentlySelectedService.serviceItem.id}
                errors={errors}
                service={currentlySelectedService}
                hideBottomSheet={() => setAddServicesModalVisible(false)}
              />
            ) : (
              <AddServicesBottomSheetContent
                onClose={onClose}
                serviceName={currentlySelectedService.serviceItem.name}
                errors={errors}
                currencyAmount={
                  currencyAmount.choices.filter(e => e.currency === currency)[0]
                }
                service={currentlySelectedService}
              />
            )}
          </BottomModalContainer>
          <BottomModalContainer
            modalVisible={addPricesModalVisible}
            setModalVisible={setAddPricesModalVisible}>
            <AddServicePricesBottomSheetContent
              serviceName={currentlySelectedService.serviceItem.name}
              data={savedData}
              disableModal={() => setAddPricesModalVisible(false)}
              currencyAmount={currencyAmount.choices.filter(
                e => e.currency !== currency,
              )}
              service={currentlySelectedService}
            />
          </BottomModalContainer>
        </>
      )}
    </>
  );
};

export default ServicesList;
