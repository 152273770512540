import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";

import { TrainingTile } from "@components/Tile";
import { FetchError } from "@components/errors";

import {
  ChipProps,
  UpcomingDatesSection,
  chipsStyles,
} from "@components/UpcomingDatesSection";
import { globalStyles } from "@styles/global";
import { Chip } from "react-native-paper";
import { TrainingDay } from "../../TrainingsAndExercises/training.types";

type Props = {
  loading: boolean;
  error: boolean;
  emptyUpcomingDays: boolean;
  refetch: () => void;
  trainingDays: TrainingDay[];
  onPress: (id: number) => void;
};

export const PatientUpcomingTraining: FC<Props> = ({
  loading,
  error,
  emptyUpcomingDays,
  refetch,
  trainingDays,
  onPress,
}) => {
  const { t } = useTranslation();
  const { gapMedium, gapSmall, marginBottomSmall } = globalStyles;
  const { chipContainer, chipText } = chipsStyles;
  const commonChipsProps: ChipProps = {
    style: { ...chipContainer },
    textStyle: { ...chipText },
  };

  return (
    <View style={[marginBottomSmall, gapMedium]}>
      <Text variant="titleMedium" style={gapSmall}>
        {t("T00456")}:
      </Text>
      {loading ? (
        <ActivityIndicator />
      ) : error && !emptyUpcomingDays ? (
        <FetchError action={refetch} coverScreen={false} />
      ) : !emptyUpcomingDays && trainingDays?.length ? (
        <View style={gapMedium}>
          <UpcomingDatesSection
            data={trainingDays}
            firstCard={
              <TrainingTile
                trainingId={trainingDays[0].workout}
                onPress={() => onPress(trainingDays[0].workout)}
              />
            }
            secondCard={
              trainingDays?.length > 1 && (
                <TrainingTile
                  trainingId={trainingDays[1].workout}
                  onPress={() => onPress(trainingDays[1].workout)}
                />
              )
            }
          />
        </View>
      ) : (
        <Chip {...commonChipsProps}>{t("T00457")}</Chip>
      )}
    </View>
  );
};
