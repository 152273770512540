import { reportResponse, reportReview } from "@api/reviews";
import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrors } from "@hooks/useErrors";
import { globalStyles } from "@styles/global";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC } from "react";
import { Control, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import {
  MULTILINE_INPUT_MAX_LENGTH,
  REPORT_INPUT_MIN_LENGTH,
} from "@utils/constants";
import { object, string } from "yup";

type Props = {
  reportedId: number;
  isReview?: boolean;
  onSuccessCallback: () => void;
};

type FormType = { description: string };

const RatingReportForm: FC<Props> = ({
  reportedId,
  isReview,
  onSuccessCallback,
}) => {
  const { t } = useTranslation();
  const { gapMedium } = globalStyles;

  const reportSchema = object().shape({
    description: string()
      .required(t("T00014"))
      .min(
        REPORT_INPUT_MIN_LENGTH,
        t("T00032", {
          value: REPORT_INPUT_MIN_LENGTH,
        }),
      )
      .max(
        MULTILINE_INPUT_MAX_LENGTH,
        t("T00036", {
          value: MULTILINE_INPUT_MAX_LENGTH,
        }),
      ),
  });
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(reportSchema),
  });
  const { setErrorsFromResponse, errors } = useErrors();
  const {
    setErrorsFromResponse: setReportRatingErrorsFromResponse,
    errors: reportRatingErrors,
  } = useErrors();

  const { mutate: reportRating, isLoading: isReportRatingLoading } =
    useMutation({
      mutationFn: reportReview,
      onError: ({ response }: AxiosError) =>
        setReportRatingErrorsFromResponse(response),
      onSuccess: onSuccessCallback,
    });

  const {
    mutate: reportRatingResponse,
    isLoading: isReportRatingResponseLoading,
  } = useMutation({
    mutationFn: reportResponse,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: onSuccessCallback,
  });

  const onSubmit = ({ description }: FormType) => {
    if (isReview) {
      reportRating({ description, review: reportedId });
    } else {
      reportRatingResponse({ description, response: reportedId });
    }
  };

  return (
    <View style={gapMedium}>
      <Text variant="bodyLarge">{t("T00739")}</Text>
      <Text variant="bodyMedium">{t("T00740")}</Text>
      <Input
        name="description"
        label="T00368"
        control={control as unknown as Control}
        errors={errors?.description || reportRatingErrors?.description}
        multiline
        isInBottomSheet
      />
      <PrimaryButton
        label="T00901"
        onPress={handleSubmit(onSubmit)}
        loading={isReportRatingResponseLoading || isReportRatingLoading}
      />
    </View>
  );
};

export default RatingReportForm;
