import { CheckboxListItem } from "@components/ListItems";
import InfoTile from "@components/Tile/InfoTile";
import { FetchError } from "@components/errors";
import { useErrors } from "@hooks/useErrors";
import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  getStationaryPaymentMethods,
  updateStationaryPaymentMethods,
} from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ScrollView } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { queryKeysVerficiations } from "../queryKeysPhysiotherapistVerification";
import {
  StationaryPaymentMethods,
  StationaryPaymentMethod,
  StationaryPaymentMethodsResponse,
} from "../verification.types";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import { TransKey } from "@globalTypes/i18next";

type PaymentsScreenType = PropsWithChildren<
  NativeStackScreenProps<ProfileCompletionStackParamList, "StationaryPayments">
>;

const StationaryPayments: FC<PaymentsScreenType> = () => {
  const { t } = useTranslation();

  const {
    data: stationaryPaymentMethodsData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: queryKeysVerficiations.stationaryPaymentMethods(),
    queryFn: async () => await getStationaryPaymentMethods(),
  });

  const { setErrorsFromResponse } = useErrors();

  const { scrollContainer, gapLarge } = globalStyles;

  const queryClient = useQueryClient();

  const stationaryPaymentMethods: Array<keyof StationaryPaymentMethods> =
    useMemo(() => {
      return (
        stationaryPaymentMethodsData &&
        (Object.keys(stationaryPaymentMethodsData[0]).splice(3) as Array<
          keyof StationaryPaymentMethods
        >)
      );
    }, [stationaryPaymentMethodsData]);

  const onMutationSuccess = async () => {
    await Promise.all([
      queryClient.invalidateQueries(
        queryKeysVerficiations.stationaryPaymentMethods(),
      ),
      queryClient.invalidateQueries(
        queryKeysVerficiations.verificationProgress(),
      ),
      queryClient.invalidateQueries(
        queryKeysVerficiations.verificationProgressRehabInSubscreens(),
      ),
    ]);
    showProfileVerificationSnackbar(t("T01311"));
  };

  const { mutate } = useMutation({
    mutationFn: async (data: StationaryPaymentMethods) => {
      return await updateStationaryPaymentMethods(
        stationaryPaymentMethodsData?.[0].id,
        data,
      );
    },
    onSuccess: onMutationSuccess,
    onError: ({ response }: AxiosError<StationaryPaymentMethodsResponse>) => {
      setErrorsFromResponse(response);
    },
  });

  const handleCheckboxChange = useCallback(
    (paymentMethod: keyof StationaryPaymentMethods) => {
      const updatedData = {
        ...stationaryPaymentMethodsData[0],
        [paymentMethod]: !stationaryPaymentMethodsData?.[0][paymentMethod],
      };
      mutate(updatedData);
    },
    [mutate, stationaryPaymentMethodsData],
  );

  return (
    <ScrollView style={scrollContainer} contentContainerStyle={gapLarge}>
      {isLoading ? (
        <ActivityIndicator style={globalStyles.loading} />
      ) : isError ? (
        <FetchError action={refetch} />
      ) : (
        <>
          <InfoTile
            status={
              stationaryPaymentMethodsData[0].isActive ? "success" : "info"
            }
            content={
              <Text variant="bodyMedium">
                {stationaryPaymentMethodsData[0].isActive ? (
                  <Trans
                    i18nKey="T01096"
                    components={{
                      1: <Text style={{ fontWeight: "bold" }}>can</Text>,
                    }}
                  />
                ) : (
                  <Trans
                    i18nKey="T01093"
                    components={{
                      1: <Text style={{ fontWeight: "bold" }}>cannot</Text>,
                    }}
                  />
                )}
              </Text>
            }
          />

          <Text variant="titleMedium">{t("T01094")}</Text>
          <Text variant="bodyMedium">{t("T01095")}</Text>
          {stationaryPaymentMethods?.map(e => (
            <CheckboxListItem
              title={t(
                {
                  [StationaryPaymentMethod.cash]: "T01134",
                  [StationaryPaymentMethod.card]: "T01135",
                  [StationaryPaymentMethod.blik]: "T01136",
                  [StationaryPaymentMethod.phoneTransfer]: "T01137",
                }[e] as TransKey,
              )}
              key={e}
              onPress={() => handleCheckboxChange(e)}
              checkboxProps={{
                status: stationaryPaymentMethodsData?.[0][e]
                  ? "checked"
                  : "unchecked",
              }}
            />
          ))}
        </>
      )}
    </ScrollView>
  );
};

export default StationaryPayments;
