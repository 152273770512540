import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { StyleSheet } from "react-native";
import { Control, Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { number, object, string } from "yup";

import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { PhysiotherapistCard } from "@components/cards";
import TitleSectionWithContent from "../Common/TitleSectionWithContent";

import { useErrors } from "@hooks/useErrors";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  createReview,
  editReview,
  getReviewDetails,
} from "@services/ApiService/reviews";
import { globalStyles } from "@styles/global";
import {
  queryKeysPhysiotherapistProfileData,
  queryKeysPhysiotherapistRatings,
} from "./queryKeysPhysiotherapist";

import { FetchError } from "@components/errors";
import { ActivityIndicator, HelperText } from "react-native-paper";
import { ReviewBody, ReviewType } from "./evaluation.types";
import { RatingStars } from "@components/Rating/RatingStars";
import { spacing8 } from "@styles/spacing";
import { MULTILINE_INPUT_MAX_LENGTH } from "@utils/constants";
import { TransKey } from "@globalTypes/i18next";

type InputsFormType = {
  comment: string;
  evaluation: number;
};

type EvaluationDataType = { title: TransKey; renderContent: JSX.Element }[];

const PhysiotherapistEvaluation: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "PhysiotherapistEvaluation">
  >
> = ({ route, navigation: { goBack } }) => {
  const { rehabId, ratingId } = route.params;
  const { t } = useTranslation();
  const { setErrorsFromResponse, errors } = useErrors();
  const editionMode = !!ratingId;
  const { loading, gapLarge, container } = globalStyles;

  const queryClient = useQueryClient();

  const schema = object().shape({
    comment: string().trim(),
    evaluation: number().min(1).max(5).required(t("T00014")),
  });

  const { control, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const setReviewDetailsData = useCallback(
    ({ review, score }: ReviewType) => {
      setValue("comment", review);
      setValue("evaluation", score);
    },
    [setValue],
  );

  const refreshAndGoBack = async (id: number) => {
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: queryKeysPhysiotherapistRatings.list(rehabId),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysPhysiotherapistRatings.check(rehabId),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysPhysiotherapistProfileData.full(rehabId),
      }),
    ]);
    editionMode &&
      (await queryClient.invalidateQueries({
        queryKey: queryKeysPhysiotherapistRatings.details(id),
      }));
    goBack();
    reset();
  };

  const {
    isLoading: isReviewDetailsLoading,
    isError: isReviewDetailsError,
    refetch: refetchReviewDetails,
  } = useQuery({
    queryKey: queryKeysPhysiotherapistRatings.details(ratingId),
    queryFn: async () => await getReviewDetails(ratingId),
    enabled: editionMode,
    onSuccess: setReviewDetailsData,
  });

  const { mutate, isLoading: isMutationLoading } = useMutation({
    mutationFn: async (data: ReviewBody) =>
      await (editionMode ? editReview(ratingId, data) : createReview(data)),
    onSuccess: ({ data: { id } }) => refreshAndGoBack(id),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const onSubmit = ({ comment, evaluation }: InputsFormType) =>
    mutate({ score: evaluation, review: comment, physiotherapist: rehabId });

  const renderEvaluation = useMemo(
    () => (
      <Controller
        name="evaluation"
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <RatingStars
                editionMode
                onChangeRating={rating => field.onChange(rating)}
                rating={field.value}
              />
              {error && (
                <HelperText type="error" style={styles.errorText}>
                  {t("T01332")}
                </HelperText>
              )}
              {errors?.score?.map(({ message }, index) => (
                <HelperText
                  type="error"
                  style={styles.errorText}
                  key={`score-error-${message}-${index}`}>
                  {message}
                </HelperText>
              ))}
            </>
          );
        }}
      />
    ),
    [control, t, errors?.score],
  );

  const data: EvaluationDataType = [
    {
      title: "T01333",
      renderContent: renderEvaluation,
    },
    {
      title: "T00383",
      renderContent: (
        <Input
          name="comment"
          label={undefined}
          placeholder={t("T00386")}
          control={control as unknown as Control}
          multiline
          errors={errors?.review}
          maxLength={MULTILINE_INPUT_MAX_LENGTH}
        />
      ),
    },
  ];

  if (editionMode && isReviewDetailsLoading)
    return <ActivityIndicator style={loading} />;

  if (editionMode && isReviewDetailsError)
    return <FetchError action={refetchReviewDetails} />;

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={[container, gapLarge, { flex: 0 }]}>
      <PhysiotherapistCard initialData={{ id: rehabId }} />
      {data.map(({ title, renderContent }, i) => {
        return (
          <TitleSectionWithContent
            key={`physio-evaluation-section-${title}-${i}`}
            title={title}
            renderContent={renderContent}
          />
        );
      })}
      <PrimaryButton
        label="T01334"
        onPress={handleSubmit(onSubmit)}
        loading={isMutationLoading}
      />
    </KeyboardAwareScrollView>
  );
};

export default PhysiotherapistEvaluation;

const styles = StyleSheet.create({
  errorText: {
    paddingHorizontal: 0,
    paddingLeft: spacing8,
  },
});
