import { FC, useCallback } from "react";
import { ActivityIndicator, IconButton } from "react-native-paper";
import BaseStatisticInfoTile from "./BaseStatisticInfoTile";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getOccupancyStats } from "@api/charts";
import { queryKeysOccupancyStats } from "@screens/Profiles/MyProfile/RehabStatistics/queryKeysRehabilitatorStatistics";
import { FetchError } from "@components/errors";
import { StatsTileType } from "@components/Tile/EarningsTile";
import { TransKey } from "@globalTypes/i18next";

type Props = {
  type: StatsTileType;
};

const getTitle = (type: StatsTileType): TransKey => {
  switch (type) {
    case StatsTileType.TOTAL:
      return "T00685";
    case StatsTileType.MONTHLY:
      return "T00684";
    case StatsTileType.MONTHLY_SHORT:
      return "T00681";
  }
};

export const OccupancyTile: FC<Props> = ({ type }) => {
  const { t } = useTranslation();
  const { data, isLoading, isError, refetch } = useQuery({
    queryFn: getOccupancyStats,
    queryKey: queryKeysOccupancyStats.all,
    refetchIntervalInBackground: true,
    refetchInterval: 120000,
  });

  const getValue = useCallback(() => {
    const { totalHours, currentMonthHours } = data;
    const usedData =
      type === StatsTileType.TOTAL ? totalHours : currentMonthHours;
    const valueFrom = usedData[0];
    const valueTo = usedData.reduce((a, b) => a + b, 0);
    return `${valueFrom} / ${valueTo}`;
  }, [data, type]);

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;
  return (
    <BaseStatisticInfoTile
      key={`occupancy-${type}`}
      leftIcon={() => <IconButton icon="timelapse" style={{ marginLeft: 0 }} />}
      title={getTitle(type)}
      value={getValue()}
      label={t("T00712").toLowerCase() as TransKey}
    />
  );
};
