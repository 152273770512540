import Svg, { Path, G, Defs, Rect, ClipPath, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <G clipPath="url(#clip0_54306_7493)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8003 12C15.0103 12 16.8003 10.21 16.8003 8C16.8003 5.79 15.0103 4 12.8003 4C10.5903 4 8.80029 5.79 8.80029 8C8.80029 10.21 10.5903 12 12.8003 12ZM12.8003 14C10.1303 14 4.80029 15.34 4.80029 18V20H20.8003V18C20.8003 15.34 15.4703 14 12.8003 14Z"
        fill="#46464F"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_54306_7493">
        <Rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.800293)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgComponent;
