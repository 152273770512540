import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { TrainingCategoryTile } from "../TrainingCategoryTile";
import { queryKeysProphylacticWorkoutsSetCategories } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { getWorkoutSetCategoryDetails } from "@services/ApiService/trainings";
import { ActivityIndicator } from "react-native-paper";
import { FetchError } from "@components/errors";
import { globalStyles } from "@styles/global";
import { WorkoutSetCategory } from "./prophylacticSets.type";

type Props = {
  id: number;
  onPress: (data: WorkoutSetCategory) => void;
};

export const WorkoutSetTile: FC<Props> = ({ id, onPress }) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysProphylacticWorkoutsSetCategories.detail(id),
    queryFn: async () => await getWorkoutSetCategoryDetails(id),
  });

  if (isLoading) return <ActivityIndicator style={globalStyles.loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <TrainingCategoryTile
      title={data?.name}
      subtitle={data?.description}
      uri={data?.image}
      onPress={() => onPress(data)}
    />
  );
};
