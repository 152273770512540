import Svg, { ClipPath, Defs, G, Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#15262E"
        fillRule="evenodd"
        d="M1.49 3.154 12.964 2.09a.913.913 0 0 1 .387.047c.127.042.242.1.332.188.103.086.181.188.245.291a.915.915 0 0 1 .117.372l1.744 18.847a.914.914 0 0 1-.046.387.861.861 0 0 1-.188.331 1.18 1.18 0 0 1-.291.246.914.914 0 0 1-.372.116L3.417 23.98a.914.914 0 0 1-.387-.047.862.862 0 0 1-.332-.188 1.179 1.179 0 0 1-.246-.291.913.913 0 0 1-.116-.372L.592 4.234a.913.913 0 0 1 .046-.387.863.863 0 0 1 .188-.331 1.18 1.18 0 0 1 .291-.246.913.913 0 0 1 .372-.116Z"
        clipRule="evenodd"
      />
      <Path
        fill="#051324"
        fillRule="evenodd"
        d="m1.967 4.776 10.839-1.003 1.372 14.823-10.84 1.003L1.968 4.776Z"
        clipRule="evenodd"
      />
      <Path
        fill="#81D4FA"
        fillRule="evenodd"
        d="m2.124 4.994 10.528-.974 1.35 14.592-10.527.975L2.124 4.994Z"
        clipRule="evenodd"
      />
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M9.26 20.39c-.056-.022-.11-.017-.164-.012a.508.508 0 0 0-.16.042.488.488 0 0 0-.127.108c-.037.044-.059.1-.082.144a.345.345 0 0 0-.012.165c.005.054.024.12.042.16.032.052.064.104.108.14.045.037.088.06.145.083.056.022.11.017.165.012a.585.585 0 0 0 .16-.042c.052-.033.103-.065.127-.108.036-.044.059-.1.08-.158.023-.056.018-.11.013-.165a.507.507 0 0 0-.042-.16.489.489 0 0 0-.252-.209Z"
        clipRule="evenodd"
      />
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M8.829 19.856c.176-.016.343.01.51.049.169.052.313.135.446.245.134.11.241.238.324.394.081.143.125.316.141.492.016.177-.01.343-.048.51a1.286 1.286 0 0 1-.246.447c-.11.133-.237.24-.394.323a1.23 1.23 0 0 1-.492.142 1.572 1.572 0 0 1-.51-.049 1.285 1.285 0 0 1-.447-.245 1.287 1.287 0 0 1-.323-.394 1.23 1.23 0 0 1-.141-.493c-.017-.176.009-.342.048-.51.053-.169.135-.313.246-.446.11-.133.237-.24.393-.324a1.23 1.23 0 0 1 .493-.14Z"
        clipRule="evenodd"
      />
      <Path
        fill="#020D1A"
        d="m8.66 3.269-2.71.25a.174.174 0 0 0-.16.186.174.174 0 0 0 .192.153l2.71-.25a.174.174 0 0 0 .16-.186.174.174 0 0 0-.192-.153Z"
      />
      <Path
        fill="#051324"
        fillRule="evenodd"
        d="M8.852 20.1c.135-.013.273.002.414.044.14.041.256.113.36.199.103.086.196.2.261.317.066.117.106.25.12.399.012.135-.003.273-.044.414a1.159 1.159 0 0 1-.2.36 1.16 1.16 0 0 1-.317.262.994.994 0 0 1-.399.119 1.092 1.092 0 0 1-.414-.044 1.158 1.158 0 0 1-.36-.2 1.157 1.157 0 0 1-.261-.316.994.994 0 0 1-.12-.4 1.092 1.092 0 0 1 .044-.413c.042-.14.113-.257.2-.36.085-.104.2-.197.317-.262a.994.994 0 0 1 .399-.12Z"
        clipRule="evenodd"
      />
      <Path
        fill="#DB5669"
        d="M21.366 10.106c-.305 2.941-5.137 8.394-7.16 10.541a.9.9 0 0 1-1.423-.148c-1.533-2.502-5.15-8.849-4.845-11.785a6.75 6.75 0 0 1 13.428 1.392Z"
      />
      <Path
        fill="#F26674"
        d="M21.366 10.106c-.293 2.82-4.76 7.944-6.68 10.02-.368-.123-.475-.266-1.955-2.97-1.647-2.958-3.584-7.01-3.357-9.198A6.705 6.705 0 0 1 12.26 3.1a6.75 6.75 0 0 1 9.105 7.006Z"
      />
      <Path
        fill="#DAD7E5"
        d="M19.575 9.92a4.95 4.95 0 0 1-5.434 4.414c-4.673-.485-6.095-6.667-2.089-9.134a4.95 4.95 0 0 1 7.523 4.72Z"
      />
      <Path
        fill="#EDEBF2"
        d="M19.575 9.92a4.896 4.896 0 0 1-1.337 2.898 4.896 4.896 0 0 1-3.11.714A4.95 4.95 0 0 1 12.053 5.2a4.95 4.95 0 0 1 7.523 4.72Z"
      />
      <Path
        fill="#9DCC6B"
        d="M19.31 21.204a1.576 1.576 0 0 1-.556 1c-2.763 2.429-11.382.689-11.082-2.207.143-1.379 2.232-1.936 3.61-2.087a68.789 68.789 0 0 0 1.5 2.59.899.899 0 0 0 1.424.147 75.274 75.274 0 0 0 2-2.226c1.938.635 3.215 1.708 3.104 2.783Z"
      />
      <Path
        fill="#B5E08C"
        d="M19.31 21.203a1.576 1.576 0 0 1-.556 1.002c-3.746 1.217-9.894-.574-9.646-2.964a1.66 1.66 0 0 1 .556-1.001 8.214 8.214 0 0 1 1.609-.33 68.847 68.847 0 0 0 1.5 2.589.9.9 0 0 0 1.424.147 75.132 75.132 0 0 0 2-2.226c1.947.636 3.224 1.71 3.113 2.783Z"
      />
      <Path
        fill="#9DCC6B"
        d="M11.887 20.407a.9.9 0 0 0 1.136.348.899.899 0 0 1-.24-.255 75.142 75.142 0 0 1-1.501-2.59c-.27.03-.528.072-.777.119.53.96 1.018 1.784 1.382 2.378Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgComponent;
