import { AntDesign } from "@expo/vector-icons";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import { queryKeysMedicalRecord } from "@screens/Profiles/MyProfile/MedicalRecords/queryKeysMedicalRecord";
import {
  getPatientBasicData,
  getPhysiotherapistBasicData,
} from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { spacing24 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { ActivityIndicator, Card, IconButton, Text } from "react-native-paper";
import { useAuth } from "@contexts/AuthContext/auth";
import { getMedicalRecord } from "@services/ApiService/medicalRecords";
import { theme } from "@styles/theme";
import { RecordTileProps } from "./types/RecordTile.types";
import { SvgStickyNote } from "@assets/svg";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { getNumberOfShares } from "@components/MedicalRecord/helpers";

const MedicalRecordTile: FC<RecordTileProps> = ({
  mode = "outlined",
  elevation,
  recordId,
  showNumberOfShares = false,
  sharedToInfo = false,
  onXIconPress,
  onPress,
  ...props
}) => {
  const {
    user: { id },
  } = useAuth();
  const { isPatient, isPhysiotherapist } = useUserDetails();
  const { t } = useTranslation();
  const {
    data: recordData,
    isLoading: isRecordDataLoading,
    isError: isRecordDataError,
    refetch: refetchRecordData,
  } = useQuery({
    queryKey: queryKeysMedicalRecord.recordDetails(recordId),
    queryFn: async () => await getMedicalRecord(recordId),
  });

  const { loading, gapSmall } = globalStyles;
  const { borderColor, itemWithSubtitlesContainer, tileContent, reloadIcon } =
    styles;

  const recordOwnerId = recordData?.owner;
  const isOwnerAccount = id === recordOwnerId;

  const showAssigneeData =
    useMemo(
      () => isOwnerAccount && isPhysiotherapist,
      [isOwnerAccount, isPhysiotherapist],
    ) ?? false;

  const enableBasicDataFetch =
    useMemo(
      () => !!recordOwnerId && !showAssigneeData,
      [recordOwnerId, showAssigneeData],
    ) ?? false;

  const enableAssigneeDataFetch =
    useMemo(
      () => recordData?.asignee && showAssigneeData,
      [recordData?.asignee, showAssigneeData],
    ) ?? false;

  const displaySharedToInfo = useMemo(
    () => sharedToInfo && isPhysiotherapist && isOwnerAccount,
    [isOwnerAccount, isPhysiotherapist, sharedToInfo],
  );

  const numberOfShares = getNumberOfShares(recordData?.sharedMedicalRecords);

  const showNumberOfSharesForPatient = useMemo(
    () =>
      showNumberOfShares && numberOfShares > 0 && isPatient && isOwnerAccount,
    [isOwnerAccount, isPatient, numberOfShares, showNumberOfShares],
  );

  const {
    data: userBasicData,
    isFetched: isUserBasicDataFetched,
    isError: isUserBasicDataError,
    refetch: refetchUserBasicData,
  } = useQuery({
    enabled: enableBasicDataFetch,
    queryKey:
      queryKeysForPatientAndPhysiotherapist.notMeBasicData(recordOwnerId),
    queryFn: async () =>
      !isOwnerAccount
        ? isPatient
          ? await getPhysiotherapistBasicData(recordOwnerId)
          : await getPatientBasicData(recordOwnerId)
        : null,
  });

  const {
    data: assigneeData,
    isFetched: isAssigneeDataFetched,
    isError: isAssigneeDataError,
    refetch: refetchAssigneeData,
  } = useQuery({
    enabled: enableAssigneeDataFetch,
    queryKey: queryKeysForPatientAndPhysiotherapist.notMeBasicData(
      recordData?.asignee?.[0],
    ),
    queryFn: async () => getPatientBasicData(recordData?.asignee?.[0]),
  });

  const isAnyLoading = useMemo(() => {
    if (enableAssigneeDataFetch)
      return isRecordDataLoading || !isAssigneeDataFetched;
    return isRecordDataLoading || !isUserBasicDataFetched;
  }, [
    enableAssigneeDataFetch,
    isAssigneeDataFetched,
    isRecordDataLoading,
    isUserBasicDataFetched,
  ]);
  const isAnyError = useMemo(() => {
    if (enableAssigneeDataFetch)
      return isRecordDataError || isAssigneeDataError;
    return isRecordDataError || isUserBasicDataError;
  }, [
    enableAssigneeDataFetch,
    isAssigneeDataError,
    isRecordDataError,
    isUserBasicDataError,
  ]);
  const refetchAllData = async () => {
    if (enableAssigneeDataFetch)
      return await Promise.all([refetchRecordData(), refetchAssigneeData()]);
    return await Promise.all([refetchRecordData(), refetchUserBasicData()]);
  };

  return (
    <Card
      {...props}
      elevation={elevation as never}
      mode={mode}
      style={borderColor}
      onPress={!isAnyError && onPress}>
      <Card.Content>
        <View style={itemWithSubtitlesContainer}>
          {isAnyLoading ? (
            <ActivityIndicator style={loading} />
          ) : (
            <>
              <SvgStickyNote />
              {isAnyError ? (
                <IconButton
                  icon="reload"
                  size={36}
                  onPress={refetchAllData}
                  iconColor={theme.colors.error}
                  style={reloadIcon}
                />
              ) : (
                <View style={tileContent}>
                  <View style={gapSmall}>
                    <Text variant="titleMedium">{recordData?.title}</Text>
                    {showNumberOfSharesForPatient ? (
                      <Text variant="bodyMedium">
                        {t("T00321", {
                          value: numberOfShares,
                        })}
                      </Text>
                    ) : null}
                    {!isOwnerAccount ? (
                      <Text variant="bodyMedium">
                        {userBasicData?.firstName} {userBasicData?.lastName}
                      </Text>
                    ) : null}
                    {showAssigneeData ? (
                      <Text variant="bodyMedium">
                        {assigneeData?.firstName} {assigneeData?.lastName}
                      </Text>
                    ) : null}
                    {displaySharedToInfo ? (
                      <Text variant="bodyMedium">
                        {t(numberOfShares ? "T01230" : "T01231")}
                      </Text>
                    ) : null}
                  </View>
                  {onXIconPress ? (
                    <TouchableOpacity
                      onPress={() => onXIconPress(recordId)}
                      hitSlop={15}>
                      <AntDesign name="close" size={spacing24} />
                    </TouchableOpacity>
                  ) : null}
                </View>
              )}
            </>
          )}
        </View>
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  borderColor: {
    borderColor: theme.colors.outlineVariant,
  },
  itemWithSubtitlesContainer: {
    flexDirection: "row",
    alignItems: "center",
    ...globalStyles.gapLarge,
  },
  tileContent: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  reloadIcon: { flex: 1, alignItems: "flex-end" },
});

export default MedicalRecordTile;
