import { MutableRefObject, useCallback } from "react";
import { useAnalytics } from "./useAnalytics";
import { NavigationContainerRefWithCurrent } from "@react-navigation/native";
import { AnalyticsEventName } from "@globalTypes/analytics.types";

type UseLogUserScreenEventOptions = {
  navigationRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>;
  routeNameRef: MutableRefObject<string>;
};

export const useLogUserScreenEvent = ({
  navigationRef,
  routeNameRef,
}: UseLogUserScreenEventOptions) => {
  const { handleLogEvent } = useAnalytics();

  const logUserScreenEvent = useCallback(async () => {
    const previousRouteName = routeNameRef.current;
    const currentRouteName = navigationRef.current.getCurrentRoute().name;

    if (previousRouteName !== currentRouteName) {
      await handleLogEvent({
        eventName: AnalyticsEventName.CURRENT_USER_SCREEN,
        eventParams: {
          screen_name: currentRouteName,
        },
      });
    }
    routeNameRef.current = currentRouteName;
  }, [handleLogEvent, navigationRef, routeNameRef]);

  return { logUserScreenEvent };
};
