import { BottomModalWithButton } from "@components/BottomSheet";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { ReportContentBottomSheet } from "@components/BottomSheetContents/ReportContentBottomSheet";
import { ButtonBottomSheet } from "@components/Button/Button.types";
import CardWithPhotoAndDate from "@components/cards/CardWithPhotoAndDate";
import { FetchError } from "@components/errors";
import { useErrors } from "@hooks/useErrors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import {
  queryKeysForumPosts,
  queryKeysForumTopics,
} from "@screens/Profiles/MyProfile/Forum/queryKeysForum";
import { blockForumContent, getPost } from "@services/ApiService/forum";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Linking, View } from "react-native";
import {
  ActivityIndicator,
  Divider,
  Icon,
  List,
  Text,
} from "react-native-paper";

type Props = {
  title?: string;
  postId: number;
  toggleSnackBar: () => void;
  onBlockSuccess: () => void;
};

const PostContent: FC<Props> = ({
  title,
  postId,
  toggleSnackBar,
  onBlockSuccess,
}) => {
  const [modalMenuVisible, setModalMenuVisible] = useState(false);
  const [modalReportVisible, setModalReportVisible] = useState(false);

  const { t } = useTranslation();
  const { id } = useUserDetails();
  const { loading, gapLarge } = globalStyles;
  const {
    colors: { primary },
  } = useAppTheme();
  const queryClient = useQueryClient();
  const { setErrorsFromResponse } = useErrors();

  const {
    data: postDetails,
    isLoading: isPostDetailsLoading,
    isError: isPostDetailsError,
    refetch: refetchPostDetails,
  } = useQuery({
    queryFn: async () => await getPost(postId),
    queryKey: queryKeysForumPosts.details(postId),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: blockForumContent,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeysForumTopics.all,
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeysForumPosts.all,
        }),
      ]);
      onBlockSuccess();
    },
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const handleBlockUser = useCallback(
    () => mutate(postDetails?.user),
    [mutate, postDetails?.user],
  );

  const buttons: ButtonBottomSheet[] = useMemo(
    () => [
      {
        label: "T01447",
        onPress: handleBlockUser,
        loading: isLoading,
        mode: "outlined",
        icon: () => <Icon size={24} source="eye-off-outline" color={primary} />,
      },
      {
        label: title ? "T00924" : "T00925",
        onPress: () => {
          setModalMenuVisible(false);
          setTimeout(() => {
            setModalReportVisible(true);
          }, 500);
        },
        loading: false,
      },
    ],
    [handleBlockUser, isLoading, primary, title],
  );

  if (isPostDetailsLoading) return <ActivityIndicator style={loading} />;
  if (isPostDetailsError)
    return <FetchError action={refetchPostDetails} coverScreen={false} />;

  const {
    attachments,
    createdAt,
    text,
    user: userId,
    userAvatar,
    userName,
  } = postDetails;

  return (
    <>
      <View style={gapLarge}>
        <View style={[gapLarge, !title && { padding: spacing16 }]}>
          <CardWithPhotoAndDate
            userName={userName}
            userId={userId}
            createdAt={createdAt}
            avatar={userAvatar}
            onMenuPress={userId === id ? null : () => setModalMenuVisible(true)}
          />
          {title && <Text variant="titleMedium">{title}</Text>}
          <Text variant="bodyMedium">{text}</Text>
          {title && !!attachments?.length && (
            <Text variant="titleMedium">{t("T00877")}:</Text>
          )}
          {!!attachments?.length &&
            attachments.map(({ file, id, name }, index) => (
              <List.Item
                title={name}
                key={`${id}-${name}-${index}`}
                style={{ backgroundColor: palettes.primary[99] }}
                titleNumberOfLines={2}
                right={props => <List.Icon {...props} icon="eye" />}
                onPress={async () => {
                  await Linking.openURL(file);
                }}
              />
            ))}
        </View>
        <Divider horizontalInset bold />
      </View>
      <BottomModalWithButton
        modalVisible={modalMenuVisible}
        setModalVisible={setModalMenuVisible}
        buttons={buttons}
      />
      <BottomModalContainer
        modalVisible={modalReportVisible}
        setModalVisible={setModalReportVisible}>
        <ReportContentBottomSheet
          onSuccessCallback={() => {
            setModalReportVisible(false);
            setTimeout(toggleSnackBar, 500);
          }}
          idToReport={postId}
          title={title}
        />
      </BottomModalContainer>
    </>
  );
};

export default PostContent;
