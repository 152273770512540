import {
  AssignProphylacticParams,
  AssignProphylacticResponse,
  AssignTrainingsToPatientData,
  CreateAndEditTrainingType,
  CreateAndEditTrainingTypeResponse,
  FinishTrainingParams,
  FinishTrainingResponse,
  GetTrainingdays,
  PredictedParametersResponse,
  ProphylacticTrainingCategoryType,
  ReportTrainingType,
  SessionTraining,
  SpecialTrainingResponse,
  SpecialTrainingsResponse,
  TrainingDay,
  TrainingDayDate,
  TrainingFullDataType,
  TrainingPatchResponse,
  TrainingType,
  WorkoutCategoryResponse,
} from "@screens/TrainingsAndExercises/training.types";
import { api } from "./api";
import { endpoints } from "./endpoints";
import {
  WorkoutCategorySetsParams,
  WorkoutSetCategory,
  WorkoutSetDetails,
} from "@components/Tile/training/prophylacticSets.type";
import { CtxExerciseType } from "@screens/TrainingsAndExercises/exercise.types";

export const getLibraryTrainingsList = async () => {
  const { data } = await api.get<
    {
      id: number;
    }[]
  >(endpoints.TRAININGS, {
    params: {
      isLibrary: true,
    },
  });
  return data;
};

export const getLibraryTraining = async <T = TrainingType>(
  id: number,
  fullData?: boolean,
) => {
  const { data } = await api.get<T>(endpoints.TRAINING(id), {
    params: { fullData },
  });
  return data;
};

export const getFullTrainingData = async (id: number, fullData?: boolean) => {
  const { data } = await api.get<TrainingFullDataType>(endpoints.TRAINING(id), {
    params: { fullData },
  });
  return data;
};

const transformExerciseConditionsForPostApi = (exercises: CtxExerciseType[]) =>
  exercises.map(e => ({ ...e, conditions: e.conditions.map(c => c.id) }));

export const createLibraryTraining = (data: CreateAndEditTrainingType) => {
  const exercises = data.exercises.map(e =>
    e.originalExerciseId
      ? e
      : {
          ...e,
          originalExerciseId: e.id,
        },
  );
  const params = {
    ...data,
    exercises: transformExerciseConditionsForPostApi(exercises),
  };

  return api.post<CreateAndEditTrainingTypeResponse>(
    endpoints.TRAININGS,
    params,
  );
};

type GetTrainingsParams = {
  patientId?: number;
  isFixmeUser?: boolean;
  isLibrary: boolean;
  isActive?: boolean;
};
export const getAssignedTrainings = async (params: GetTrainingsParams) => {
  const { data } = await api.get<
    {
      id: number;
    }[]
  >(endpoints.TRAININGS, {
    params,
  });

  return data;
};

export const getAssignedTrainingData = async (id: number) => {
  const { data } = await api.get<TrainingType>(endpoints.TRAINING(id));
  return data;
};

export const getProphylacticTrainingsForCategory = async (params: {
  workoutCategoryId: number;
  sortBy?: string;
}) => {
  const { data } = await api.get<ProphylacticTrainingCategoryType[]>(
    endpoints.PROPHYLACTIC_TRAININGS,
    { params },
  );
  return data;
};

export const getWorkoutSetCategories = async () => {
  const { data } = await api.get<{ id: number }[]>(
    endpoints.WORKOUT_SET_CATEGORIES,
  );
  return data;
};

export const getWorkoutSetCategoryDetails = async (id: number) => {
  const { data } = await api.get<WorkoutSetCategory>(
    endpoints.WORKOUT_SET_CATEGORY(id),
  );
  return data;
};

export const getWorkoutCategorySets = async (
  workoutSetCategoryId: number,
  workoutAmount?: "asc" | "desc",
  ordering?: string,
) => {
  const params: WorkoutCategorySetsParams = { workoutSetCategoryId };
  if (workoutAmount) {
    params.workoutAmount = workoutAmount;
  }
  if (ordering) {
    params.ordering = ordering;
  }

  const { data } = await api.get<{ id: number }[]>(
    endpoints.WORKOUT_CATEGORY_SETS,
    { params },
  );
  return data;
};

export const getWorkoutCategorySet = async (id: number) => {
  const { data } = await api.get<WorkoutSetDetails>(
    endpoints.WORKOUT_CATEGORY_SET(id),
  );
  return data;
};

export const assignWorkoutSet = (id: number) =>
  api.post<object>(endpoints.ASSIGN_WORKOUT_SET(id));

export const assignTrainingToPatient = (data: AssignTrainingsToPatientData) =>
  api.post<AssignTrainingsToPatientData>(endpoints.TRAININGS, {
    ...data,
    exercises: transformExerciseConditionsForPostApi(data.exercises),
  });

export const finishTraining = (id: number, params: FinishTrainingParams) =>
  api.patch<FinishTrainingResponse>(endpoints.TRAINING_FINISH(id), params);

export const deleteLibraryTraining = (id: number) =>
  api.delete(endpoints.TRAINING(id));

export const changeLibraryTrainingDays = (
  id: number,
  days: TrainingDayDate[],
) => api.patch<TrainingPatchResponse>(endpoints.TRAINING(id), { days });

export const copyLibraryTraining = (id: number) =>
  api.post<TrainingType>(endpoints.TRAINING_COPY(id));

export const reportTraining = (data: ReportTrainingType) =>
  api.post<
    ReportTrainingType & {
      id: number;
    }
  >(endpoints.REPORT_TRAINING, data);

export const getTrainingDays = async (params?: GetTrainingdays) => {
  const { data } = await api.get<TrainingDay[]>(endpoints.TRAINING_DAYS, {
    params,
  });
  return data;
};

export const getTrainingClosestDay = async () => {
  const { data } = await api.get<TrainingDay[]>(endpoints.TRAINING_CLOSEST_DAY);
  return data;
};

export const getTrainingDay = async (id: number) => {
  const { data } = await api.get<TrainingDay>(endpoints.TRAINING_DAY(id));
  return data;
};

export const getSpecialTrainingCommitments = async (appointmentId: number) => {
  const { data } = await api.get<SpecialTrainingsResponse[]>(
    endpoints.SPECIAL_TRAININGS,
    { params: { appointmentId } },
  );
  return data;
};

export const getSpecialTrainingCommitmentsForSpecificPatient = async (
  patientId: number,
) => {
  const { data } = await api.get<SpecialTrainingsResponse[]>(
    endpoints.SPECIAL_TRAININGS,
    { params: { patientId } },
  );
  return data;
};

export const createCommitment = (appointment: number) =>
  api.post<SpecialTrainingsResponse[]>(endpoints.SPECIAL_TRAININGS, {
    appointment,
  });

export const deleteCommitment = (appointment: number) =>
  api.delete(endpoints.SPECIAL_TRAINING(appointment));

export const getSpecialTrainings = async () => {
  const { data } = await api.get<SpecialTrainingsResponse[]>(
    endpoints.SPECIAL_TRAININGS,
  );
  return data;
};

export const updateSpecialTraining = (id: number) =>
  api.patch<SpecialTrainingResponse>(endpoints.SPECIAL_TRAINING(id));

export const assignProphylacticTraining = (
  id: number,
  params: AssignProphylacticParams,
) =>
  api.post<AssignProphylacticResponse>(
    endpoints.PROPHYLACTIC_TRAINING_ASSIGN(id),
    params,
  );

export const getProphylacticTrainingDetails = async (id: number) => {
  const { data } = await api.get<TrainingType>(
    endpoints.PROPHYLACTIC_TRAINING(id),
  );
  return data;
};

export const getSessionTrainings = async (trainingId: number) => {
  const { data } = await api.get<
    {
      id: number;
    }[]
  >(endpoints.SESSION_TRAININGS, { params: { workoutId: trainingId } });
  return data;
};

export const getSessionTraining = async (id: number) => {
  const { data } = await api.get<SessionTraining>(
    endpoints.SESSION_TRAINING(id),
  );
  return data;
};

export const getPredictedParameters = async (params: {
  patientId: number;
  exerciseId: number;
}) => {
  const { data } = await api.get<PredictedParametersResponse>(
    endpoints.PREDICTED_PARAMETERS,
    {
      params,
    },
  );
  return data;
};

export const getWorkoutCategories = async () => {
  const { data } = await api.get<
    {
      id: number;
    }[]
  >(endpoints.WORKOUT_CATEGORIES);
  return data;
};

export const getTrainingCategory = async (id: number) => {
  const { data } = await api.get<WorkoutCategoryResponse>(
    endpoints.WORKOUT_CATEGORY(id),
  );
  return data;
};

export const getTrainingsByIntelligentTrainingAssistant = async (params: {
  bodyParts?: string[];
  time: 1 | 2 | 3;
}) => {
  const bodyParts = params.bodyParts?.join(",");
  const reqParams = { ...params };
  bodyParts && Object.assign(reqParams, { bodyParts });
  const { data } = await api.get<
    {
      id: number;
    }[]
  >(endpoints.INTELLIGENT_TRAINING_ASSISTANT, { params: reqParams });
  return data;
};
