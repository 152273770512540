import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      fill="#4666FF"
      d="M10.833 5.834H9.167v3.333H5.833v1.667h3.334v3.333h1.666v-3.333h3.334V9.167h-3.334V5.834ZM10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.334 8.333 8.334S18.333 14.6 18.333 10 14.6 1.667 10 1.667Zm0 15A6.676 6.676 0 0 1 3.333 10 6.676 6.676 0 0 1 10 3.334 6.675 6.675 0 0 1 16.667 10 6.675 6.675 0 0 1 10 16.667Z"
    />
  </Svg>
);
export default SvgComponent;
