import EquipmentTile from "@components/Tile/exercise/EquipmentTile";
import { RootStackParamList } from "@navigators/navigation.types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { FC, PropsWithChildren } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { SelectedEquipmentListProps } from "./SelectedEquipmentList";

type SelectedEquipmentListPropsWithControllerProps = {
  onXIconPress?: (eq: number) => void;
} & SelectedEquipmentListProps &
  Pick<ControllerProps, "control">;

const SelectedEquipmentListWithController: FC<
  PropsWithChildren<SelectedEquipmentListPropsWithControllerProps>
> = ({ control, selectedEquipment, onXIconPress }) => {
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  return (
    <>
      {selectedEquipment?.map(eq => (
        <Controller
          key={eq}
          name={`equipments.${eq}`}
          control={control}
          defaultValue={eq}
          render={() => (
            <EquipmentTile
              mode="outlined"
              equipmentId={eq}
              onPress={() => navigate("EquipmentDetails", { id: eq })}
              onXIconPress={onXIconPress}
            />
          )}
        />
      ))}
    </>
  );
};

export default SelectedEquipmentListWithController;
