import {
  CreateForumPostParams,
  CreateForumPostResponse,
  CreateForumTopicParams,
  CreateForumTopicResponse,
  GetForumPostResponse,
  GetForumTopicResponse,
} from "@globalTypes/forum.types";
import { api } from "./api";
import { endpoints } from "./endpoints";

export const getTopics = async () => {
  const { data } = await api.get<{ id: number }[]>(endpoints.FORUM_TOPICS);
  return data;
};

export const getTopic = async (id: number) => {
  const { data } = await api.get<GetForumTopicResponse>(
    endpoints.FORUM_TOPIC(id),
  );
  return data;
};

export const createPost = async (data: CreateForumPostParams) => {
  const formData = new FormData();

  formData.append("topic", `${data.topic}`);
  formData.append("text", data.text);
  data.attachments &&
    data.attachments?.forEach(att => formData.append("attachments", `${att}`));

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };

  return await api.post<CreateForumPostResponse>(
    endpoints.FORUM_POSTS,
    formData,
    options,
  );
};

export const createTopic = ({ posts, ...data }: CreateForumTopicParams) =>
  api.post<CreateForumTopicResponse>(endpoints.FORUM_TOPICS, {
    ...data,
    posts,
  });

export const getPost = async (id: number) => {
  const { data } = await api.get<GetForumPostResponse>(
    endpoints.FORUM_POST(id),
  );
  return data;
};

export const blockForumContent = (physio: number) =>
  api.post(endpoints.FORUM_BLACKLIST, { physio });
