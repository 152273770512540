import { StyleSheet } from "react-native";
import { spacing8 } from "@styles/spacing";

export const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    position: "absolute",
    right: spacing8,
    top: 100,
  },
});
