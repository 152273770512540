import { OnlyIdProfile } from "@contexts/AuthContext/user.types";
import { useErrors } from "@hooks/useErrors";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useCallback } from "react";
import {
  FlatList,
  ListRenderItem,
  RefreshControl,
  StyleSheet,
  View,
} from "react-native";
import { ActivityIndicator, FAB, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { FetchError } from "../../components/errors";

import { getMyPhysiotherapists } from "@api/users";
import { Tile } from "@components/Tile";
import { UserChatCard } from "@components/cards/UserChatCard";
import { useUnreadMessages } from "@hooks/queryHooks/useUnreadMessages";
import { useGetShowWhatCanYouExpectStatus } from "@hooks/useGetShowWhatCanYouExpectStatus";
import { MessageCenterScreenType } from "@navigators/MessageCenterStackNavigator.tsx";
import { RootStackParamList } from "@navigators/navigation.types";
import {
  NavigationProp,
  useIsFocused,
  useNavigation,
} from "@react-navigation/native";
import { queryKeysPhysiotherapist } from "@screens/Appointments/queryKeysAppointments";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useTranslation } from "react-i18next";
import MessageCenterPermissionModal from "./MessageCenterPermissionModal";
import { queryKeysUnreadMessages } from "./queryKeysMessageCenter";

const Physiotherapists: FC<PropsWithChildren<MessageCenterScreenType>> = () => {
  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const isFocused = useIsFocused();
  const queryClient = useQueryClient();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { isLoading: isUnreadMessagesLoading } = useUnreadMessages();
  const { gapMedium, loading, gapLarge, fab } = globalStyles;

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapist.myList(),
    queryFn: async () => await getMyPhysiotherapists(),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    enabled: isFocused,
  });

  const showWhatCanYouExpect = useGetShowWhatCanYouExpectStatus(isFocused);

  const renderItem: ListRenderItem<OnlyIdProfile> = useCallback(
    ({ item: { id } }) => (
      <UserChatCard
        interlocutorId={id}
        onPress={() => navigate("ConversationScreen", { interlocutorId: id })}
      />
    ),
    [navigate],
  );

  const renderHeader = useCallback(
    () => (
      <View style={gapMedium}>
        <Text variant="bodyMedium">{t("T00913")}</Text>
        <Tile
          tileType="medic"
          mode="outlined"
          onPress={() =>
            navigate(
              showWhatCanYouExpect ? "WhatCanYouExpect" : "Physiotherapists",
            )
          }
        />
      </View>
    ),
    [gapMedium, t, navigate, showWhatCanYouExpect],
  );

  const refetchAll = async () =>
    await Promise.all([
      refetch(),
      await queryClient.invalidateQueries(queryKeysUnreadMessages.all),
    ]);

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <SafeAreaView edges={["right", "left"]} style={{ flex: 1 }}>
      <FlatList
        data={data}
        ListHeaderComponent={data?.length <= 1 ? renderHeader : null}
        renderItem={renderItem}
        keyExtractor={({ id }: OnlyIdProfile) => `physio-${id}`}
        contentContainerStyle={[styles.contentContainerStyle, gapLarge]}
        refreshControl={
          <RefreshControl
            refreshing={isLoading || isUnreadMessagesLoading}
            onRefresh={refetchAll}
          />
        }
      />
      <FAB
        icon="account-plus-outline"
        onPress={() =>
          navigate("EnterInvitationOrPromotionCode", {
            screenType: "invitationCode",
          })
        }
        style={fab}
        color={palettes.primary[100]}
      />
      <MessageCenterPermissionModal />
    </SafeAreaView>
  );
};
const styles = StyleSheet.create({
  contentContainerStyle: {
    padding: spacing16,
  },
});

export default Physiotherapists;
