import {
  TranslationsType,
  pl,
  en,
  it,
  ru,
  de,
  fr,
  es,
} from "react-native-paper-dates";
import { no } from "./noTranslation";

export const getTranslationObject = (languageCode: string) => {
  let translationObject: TranslationsType;
  switch (languageCode) {
    case "pl":
      translationObject = pl;
      break;
    case "en":
      translationObject = en;
      break;
    case "es":
      translationObject = es;
      break;
    case "it":
      translationObject = it;
      break;
    case "fr":
      translationObject = fr;
      break;
    case "ru":
      translationObject = ru;
      break;
    case "de":
      translationObject = de;
      break;
    case "nb":
    case "nn":
    case "no":
      translationObject = no;
      break;
    default:
      translationObject = en;
  }
  return translationObject;
};
