import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Animated,
  Easing,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { useQuery } from "@tanstack/react-query";
import { ActivityIndicator, Text } from "react-native-paper";
import Lottie, { AnimationObject } from "lottie-react-native";
import { useTranslation } from "react-i18next";

import { FullScreenModal } from "@components/Modals/FullScreenModal";

import { getAchievement } from "@services/ApiService/users";
import { queryKeysAchievements } from "./queryKeysAchievements";
import { spacing16 } from "@styles/spacing";
import { AchievementType } from "./Achievements";
import { globalStyles } from "@styles/global";
import { palettes } from "@styles/colors";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";

const AnimatedLottieView = Animated.createAnimatedComponent(Lottie);

type AchievementDetailProps = {
  data: AchievementType;
  setError: Dispatch<SetStateAction<boolean>>;
  setIsModalVisible: (val: boolean) => void;
};

const AchievementComponent: FC<AchievementDetailProps> = ({
  data: { id, isCompleted },
  setError,
}) => {
  const animationsProgress = useRef(new Animated.Value(0));
  const animationsProgress2 = useRef(new Animated.Value(0));
  const modalAnimation = useRef(new Animated.Value(0)).current;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { height } = useWindowDimensions();
  const { t } = useTranslation();
  const { item, modalContentContainer, container, activityItem } = styles;

  useEffect(() => {
    if (isModalVisible) {
      Animated.timing(modalAnimation, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }).start(() => {
        Animated.timing(animationsProgress2.current, {
          toValue: 1,
          duration: 1000,
          easing: Easing.linear,
          useNativeDriver: false,
        }).start();
      });
    } else {
      modalAnimation.setValue(0);
      animationsProgress2.current.setValue(0);
    }
  }, [isModalVisible, modalAnimation]);

  useEffect(() => {
    Animated.timing(animationsProgress.current, {
      toValue: 1,
      duration: 1500,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();
  }, []);

  const { data: achievementDetail, isLoading: getAchievementLoading } =
    useQuery({
      queryKey: queryKeysAchievements.details(id),
      queryFn: async () => getAchievement(id),
      onError: () => setError(true),
    });

  let animation: AnimationObject | null = null;

  switch (achievementDetail?.label) {
    case "RATE_OUR_APP": {
      animation = isCompleted
        ? (require("@assets/animations/achievements/Unlocked_RATE_OUR_APP.json") as AnimationObject)
        : (require("@assets/animations/achievements/Locked_RATE_OUR_APP.json") as AnimationObject);
      break;
    }
    case "SIGN_UP_FOR_VISIT":
      animation = isCompleted
        ? (require("@assets/animations/achievements/Unlocked_SIGN_UP_FOR_VISIT.json") as AnimationObject)
        : (require("@assets/animations/achievements/Locked_SIGN_UP_FOR_VISIT.json") as AnimationObject);
      break;
    case "FINISH_PROPHYLACTIC_TRAINING":
      animation = isCompleted
        ? (require("@assets/animations/achievements/Unlocked_FINISH_PROPHYLACTIC_TRAINING.json") as AnimationObject)
        : (require("@assets/animations/achievements/Locked_FINISH_PROPHYLACTIC_TRAINING.json") as AnimationObject);
      break;
    case "CREATE_MEDICAL_RECORD":
      animation = isCompleted
        ? (require("@assets/animations/achievements/Unlocked_CREATE_MEDICAL_RECORD.json") as AnimationObject)
        : (require("@assets/animations/achievements/Locked_CREATE_MEDICAL_RECORD.json") as AnimationObject);
      break;
    case "FINISH_INDIVIDUAL_TRAINING":
      animation = isCompleted
        ? (require("@assets/animations/achievements/Unlocked_FINISH_INDIVIDUAL_TRAINING.json") as AnimationObject)
        : (require("@assets/animations/achievements/Locked_FINISH_INDIVIDUAL_TRAINING.json") as AnimationObject);
      break;
    default:
      animation = isCompleted
        ? (require("@assets/animations/achievements/Unlocked_FULFILL_YOUR_PROFILE.json") as AnimationObject)
        : (require("@assets/animations/achievements/Locked_FULFILL_YOUR_PROFILE.json") as AnimationObject);
      break;
  }

  const renderAnimation = useCallback(
    (
      additionalStyles?: object,
      animationsProgressRef?: MutableRefObject<Animated.Value>,
    ) => (
      <AnimatedLottieView
        source={animation}
        progress={
          animationsProgressRef
            ? animationsProgressRef.current
            : animationsProgress.current
        }
        style={[item, additionalStyles]}
      />
    ),
    [animation, item],
  );

  const renderModalContent = useCallback(() => {
    const modalContentStyle = {
      transform: [
        {
          translateY: modalAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: [height, 0],
          }),
        },
      ],
    };

    return (
      <Animated.View
        style={[
          globalStyles.gapMedium,
          modalContentContainer,
          modalContentStyle,
        ]}>
        {isCompleted && <Text variant="headlineMedium">{t("T00411")}</Text>}
        {renderAnimation({ height: 164, width: 164 }, animationsProgress2)}
        <Text variant="titleMedium">{achievementDetail?.title}</Text>
        <Text variant="bodySmall">{achievementDetail?.message}</Text>
      </Animated.View>
    );
  }, [
    isCompleted,
    achievementDetail?.message,
    achievementDetail?.title,
    animationsProgress2,
    renderAnimation,
    height,
    modalAnimation,
    modalContentContainer,
    t,
  ]);

  if (getAchievementLoading) return <ActivityIndicator style={activityItem} />;

  return (
    <View style={container}>
      <TouchableOpacity
        onPress={() => setIsModalVisible(true)}
        style={container}>
        {renderAnimation()}
        <Text style={{ textAlign: "center" }}>{achievementDetail?.title}</Text>
      </TouchableOpacity>
      <FullScreenModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        renderContent={renderModalContent}
      />
    </View>
  );
};

export default AchievementComponent;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    marginBottom: spacing16,
    flex: 1,
  },
  item: { height: 96, width: 96 },
  activityItem: {
    minHeight: 96,
    minWidth: 96,
  },
  modalContentContainer: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: palettes.primary["99"],
    minHeight: 328,
    minWidth: 328,
    borderRadius: 28,
  },
});
