import {
  GetFutureAvailabilityParams,
  PhysiotherapistAvailabilityParams,
} from "@components/PhysioAvailability/PhysioAvailability.types";
import { queryKeysPhysiotherapist } from "@screens/Appointments/queryKeysAppointments";
import {
  getFutureAvailability,
  getPhysiotherapistAvailability,
} from "@services/ApiService/users";
import { QueryKey, useQuery } from "@tanstack/react-query";
import { roundUpToNearest15Minutes } from "@utils/dateRoundUp";

export const useFutureAvailability = (
  params: GetFutureAvailabilityParams,
  enabled = true,
  queryKey?: QueryKey,
) => {
  const updatedParams = {
    ...params,
    dateFrom: roundUpToNearest15Minutes(params.dateFrom),
  };

  return useQuery({
    queryKey:
      queryKey || queryKeysPhysiotherapist.futureAvailability(updatedParams),
    queryFn: async () => await getFutureAvailability(updatedParams),
    enabled,
  });
};

export const usePhysiotherapistAvailability = (
  queryKey: QueryKey,
  params: PhysiotherapistAvailabilityParams,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey,
    queryFn: async () => await getPhysiotherapistAvailability(params),
    enabled,
  });
};
