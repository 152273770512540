import { TransKey } from "@globalTypes/i18next";
import { spacing2 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, TouchableOpacityProps, View } from "react-native";
import { Searchbar, SearchbarProps } from "react-native-paper";

type ButtonType = TouchableOpacityProps & {
  label: TransKey;
  isError?: boolean;
};
export const SearchbarButton = (
  { label, isError, ...props }: ButtonType,
  { ...searchbarProps }: SearchbarProps,
) => {
  const { t } = useTranslation();
  const {
    colors: { error },
  } = useAppTheme();
  return (
    <View>
      <Searchbar
        {...searchbarProps}
        value={null}
        editable={false}
        placeholder={t(label)}
        style={[
          searchbarProps.style,
          isError && { borderColor: error, borderWidth: spacing2 },
        ]}
      />
      <TouchableOpacity
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          zIndex: 1,
        }}
        {...props}
      />
    </View>
  );
};

export default SearchbarButton;
