import { LIST, OPTIONS } from "@utils/constants";

const keys = {
  languages: "languages",
};

export const queryKeysLanguages = {
  all: [keys.languages] as const,
  list: () => [...queryKeysLanguages.all, LIST] as const,
  options: () => [...queryKeysLanguages.all, OPTIONS] as const,
};
