import { queryKeysSubscription } from "@components/Subscriptions/queryKeysSubscription";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

export const useInvalidateSubscriptionStatus = () => {
  const queryClient = useQueryClient();

  const invalidateSubscriptionStatus = useCallback(async () => {
    await Promise.all([
      queryClient.invalidateQueries(queryKeysSubscription.appleStatus()),
      queryClient.invalidateQueries(queryKeysSubscription.googleStatus()),
    ]);
  }, [queryClient]);
  return { invalidateSubscriptionStatus };
};
