import { FetchError } from "@components/errors";
import { useCheckNotifications } from "@hooks/notifications/useCheckNotifications";
import { useNotificationMainSettings } from "@hooks/notifications/useNotificationMainSettings";
import { isWeb } from "@utils/constants";
import { showAlert } from "@utils/showAlert";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { AppState, View } from "react-native";
import OneSignal from "react-native-onesignal";
import { PushNotificationsSettingsStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import PushNotificationsListItems from "./PushNotificationsListItems";
import SwitchSection from "./SwitchSection";
import { useUpdateNotificationMain } from "@hooks/notifications/useUpdateNotificationMain";
import PermissionModal from "@components/Modals/PermissionModal";
import { PermissionType } from "@globalTypes/common.types";

type ApplicationSettingsProps = PropsWithChildren<
  NativeStackScreenProps<
    PushNotificationsSettingsStackParamsList,
    "PushNotificationsSettings"
  >
>;

const PushNotificationsSettings: FC<ApplicationSettingsProps> = ({
  navigation,
}) => {
  const { t } = useTranslation();
  const [pushNotificationSwitch, setPushNotificationSwitch] =
    useState<boolean>();
  const [isDevicePermissionsOn, setIsDevicePermissionsOn] = useState(false);
  const [
    showNotificationPermissionModalVisible,
    setShowNotificationPermissionModalVisible,
  ] = useState(false);
  const appState = useRef(AppState.currentState);

  const {
    data: settings,
    refetch,
    isRefetchError,
    isError,
  } = useNotificationMainSettings();

  const { update } = useUpdateNotificationMain({
    settingId: settings?.id,
    onMutate: (val: boolean) => setPushNotificationSwitch(val),
    onSuccess: () => refetch(),
  });

  const { handleCheckNotifications, isNotificationGranted } =
    useCheckNotifications({
      mainNotification: settings,
      onPermissionsGranted: () =>
        setShowNotificationPermissionModalVisible(false),
    });

  const getDeviceOneSignalPermissions = useCallback(async () => {
    if (!isWeb) {
      try {
        const deviceState = await OneSignal.getDeviceState();
        setIsDevicePermissionsOn(deviceState.hasNotificationPermission);
      } catch (error) {
        console.debug(error);
        showAlert(t("T00042"));
      }
    }
  }, [t]);

  const onPermissionsToggleSwitch = useCallback(() => {
    if (isWeb) return showAlert(t("T01379"));
    if (!isDevicePermissionsOn) setShowNotificationPermissionModalVisible(true);
    if (settings?.notificationsActive) return update(false);
    update(true);
  }, [isDevicePermissionsOn, settings?.notificationsActive, t, update]);

  useEffect(() => {
    const subscription = AppState.addEventListener("change", nextAppState => {
      if (appState.current === "background" && nextAppState === "active")
        setTimeout(getDeviceOneSignalPermissions, 250);

      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, [getDeviceOneSignalPermissions]);

  useEffect(() => {
    void getDeviceOneSignalPermissions();
  }, [getDeviceOneSignalPermissions]);

  useEffect(() => {
    if (isNotificationGranted) void getDeviceOneSignalPermissions();
  }, [getDeviceOneSignalPermissions, isNotificationGranted]);

  useEffect(() => {
    if (isDevicePermissionsOn) {
      setPushNotificationSwitch(settings?.notificationsActive);
    } else setPushNotificationSwitch(false);
  }, [isDevicePermissionsOn, settings]);

  const { container, gapLarge } = globalStyles;

  const isAnyError = useMemo(
    () => isRefetchError || isError,
    [isError, isRefetchError],
  );

  if (isAnyError) <FetchError coverScreen={false} action={refetch} />;

  return (
    <View style={[container, gapLarge]}>
      <SwitchSection
        switchOn={pushNotificationSwitch}
        onChange={onPermissionsToggleSwitch}
        title="T00515"
        description="T00516"
      />
      {pushNotificationSwitch && (
        <PushNotificationsListItems navigation={navigation} />
      )}
      <PermissionModal
        isModalVisible={showNotificationPermissionModalVisible}
        setIsModalVisible={setShowNotificationPermissionModalVisible}
        onPressConfirm={handleCheckNotifications}
        type={PermissionType.notifications}
      />
    </View>
  );
};

export default PushNotificationsSettings;
