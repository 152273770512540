import { TrainingSelectionAssistantStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren, useState } from "react";
import { View } from "react-native";
import { Chip, Text } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "@components/buttons";

enum SelectionAssistantAnswers {
  GENERAL_PREVENTION = "General prevention",
  SPECIFIC_PARTS = "Specific parts",
}

export const ExerciseForSpecificGroup: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      TrainingSelectionAssistantStackParamsList,
      "ExerciseForSpecificGroup"
    >
  >
> = ({ navigation: { navigate } }) => {
  const [currentValue, setCurrentValue] =
    useState<SelectionAssistantAnswers>(null);

  const { t } = useTranslation();
  const { container, gapLarge, gapSmall } = globalStyles;

  return (
    <View style={[container, { justifyContent: "space-between" }]}>
      <View style={gapLarge}>
        <View style={gapSmall}>
          <Text variant="titleMedium">{t("T01001")}</Text>
          <Text variant="bodyMedium">{t("T01010")}</Text>
        </View>
        <View style={gapLarge}>
          <Chip
            mode="outlined"
            style={{ alignSelf: "flex-start" }}
            selected={currentValue === SelectionAssistantAnswers.SPECIFIC_PARTS}
            onPress={() =>
              setCurrentValue(prevState =>
                prevState === SelectionAssistantAnswers.SPECIFIC_PARTS
                  ? null
                  : SelectionAssistantAnswers.SPECIFIC_PARTS,
              )
            }>
            {t("T01002")}
          </Chip>
          <Chip
            mode="outlined"
            style={{ alignSelf: "flex-start" }}
            selected={
              currentValue === SelectionAssistantAnswers.GENERAL_PREVENTION
            }
            onPress={() =>
              setCurrentValue(prevState =>
                prevState === SelectionAssistantAnswers.GENERAL_PREVENTION
                  ? null
                  : SelectionAssistantAnswers.GENERAL_PREVENTION,
              )
            }>
            {t("T01003")}
          </Chip>
        </View>
      </View>
      <PrimaryButton
        label="T00472"
        disabled={currentValue === null}
        onPress={() =>
          navigate(
            currentValue === SelectionAssistantAnswers.SPECIFIC_PARTS
              ? "TrainingSelectionModel"
              : "DailyTimeForTraining",
          )
        }
      />
    </View>
  );
};
