import { Platform } from "react-native";
import { StatusBar as ExpoStatusBar } from "expo-status-bar";

const StatusBar = () => {
  return (
    <ExpoStatusBar
      style={Platform.OS === "ios" ? "dark" : "light"}
      backgroundColor="#000"
    />
  );
};

export default StatusBar;
