import { useAuth } from "@contexts/AuthContext/auth";
import { getDate, getFormattedDayWithMonth } from "@utils/date";
import { format, sub, add } from "date-fns";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useSubscriptionData = () => {
  const {
    user: { subscriptionExpires, subscriptionActive },
  } = useAuth();
  const { t } = useTranslation();

  const getFormattedExpireDate = useCallback(() => {
    if (!subscriptionExpires) return t("T00961");

    return getDate(format(new Date(subscriptionExpires), "yyyy-MM-dd"));
  }, [subscriptionExpires, t]);

  const endOfFreeTrialChargeDate = useMemo(() => {
    if (subscriptionExpires === null) {
      const today = new Date();
      const dateIn3Months = add(today, { months: 3 });
      const finalDate = sub(dateIn3Months, { days: 1 });
      return getFormattedDayWithMonth(finalDate);
    }
    return "";
  }, [subscriptionExpires]);

  return {
    isSubActive: subscriptionActive,
    getFormattedExpireDate,
    subscriptionExpires,
    endOfFreeTrialChargeDate,
  };
};
