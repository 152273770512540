import DropdownPicker from "@components/DropdownPicker/DropdownPicker";
import { PrimaryButton } from "@components/buttons";
import { useAuth } from "@contexts/AuthContext/auth";
import { TransKey } from "@globalTypes/i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PricesType,
  ServiceType,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { ServiceDataType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import { CurrencyChoices } from "@services/ApiService/api.types";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { FC, useMemo, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { object, string } from "yup";
import ServiceTypeButtonAndSlotsPicker from "./ServiceTypeButtonAndSlotsPicker";

export type AddingServiceData = {
  slots: number;
  serviceType: ServiceType;
  price: number;
  selectedCurrency: string;
};

export type ServiceFormType = {
  serviceType: ServiceType;
};

type AddServiceBottomSheetContentProps = {
  serviceName: string;
  onClose?: (data: AddingServiceData) => void;
  currencyAmount: CurrencyChoices;
  errors: { [x: string]: FieldError[] };
  service?: ServiceDataType;
};

const AddServiceBottomSheetContent: FC<AddServiceBottomSheetContentProps> = ({
  serviceName,
  onClose,
  currencyAmount,
  errors,
  service = null,
}) => {
  const {
    user: { currency },
  } = useAuth();

  const [selectedCurrencyAmount, setSelectedCurrencyAmount] = useState<number>(
    getPriceInBaseCurrency(service?.prices) || currencyAmount.value[0],
  );
  const [slotsDropdownOpen, setSlotsDropdownOpen] = useState(false);
  const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState<number>(
    service?.slots - 1 || 0,
  );
  const { t } = useTranslation();
  const schema = useMemo(
    () =>
      object().shape({
        serviceType: string().required(t("T00028")),
      }),
    [t],
  );

  const { handleSubmit, control, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: ServiceFormType) =>
    onClose({
      ...data,
      slots: selectedSlots + 1,
      price: selectedCurrencyAmount,
      selectedCurrency: currencyAmount.currency,
    });

  function getPriceInBaseCurrency(prices: PricesType[]) {
    if (!prices?.length) return null;
    return prices.find(e => e.currency === currency).amount;
  }

  return (
    <View style={globalStyles.gapMedium}>
      <Text variant="bodyLarge">{serviceName}</Text>
      {service?.serviceType && <Text variant="bodyMedium">{t("T01179")}</Text>}
      <ServiceTypeButtonAndSlotsPicker
        control={control}
        selectedSlots={selectedSlots}
        setSelectedSlots={setSelectedSlots}
        slotsDropdownOpen={slotsDropdownOpen}
        setSlotsDropdownOpen={setSlotsDropdownOpen}
        setCurrencyDropdownOpen={setCurrencyDropdownOpen}
        watch={watch}
        serviceType={service?.serviceType}
        errors={errors?.serviceType}
      />
      <DropdownPicker<number>
        items={currencyAmount.value.map(e => ({ value: e, label: `${e}` }))}
        defaultValue={selectedCurrencyAmount}
        onSelectItem={({ value }) => setSelectedCurrencyAmount(value)}
        containerStyles={{ marginBottom: spacing8 }}
        dropdownLabelText={
          `${t("T00784")} ${currencyAmount.currency}` as TransKey
        }
        onOpen={() => {
          setSlotsDropdownOpen(false);
          setCurrencyDropdownOpen(true);
        }}
        onClose={() => setCurrencyDropdownOpen(false)}
        isDropdownOpen={currencyDropdownOpen}
        multiDropdownContent
        isInBottomSheet
      />
      <PrimaryButton
        onPress={handleSubmit(onSubmit)}
        label={service ? "T01052" : "T00250"}
        style={{ marginBottom: spacing8 }}
      />
    </View>
  );
};

export default AddServiceBottomSheetContent;
