import { config } from "./config";
import { ProductName } from "@globalTypes/common.types";

type Flags = {
  PRODUCT_NAME: ProductName;
  FEATURE_APPLE_LOGIN: boolean;
  SMS_VERIFICATION_ENABLED: boolean;
  FREE_SERVICE_ENABLED: boolean;
  FEATURE_FACEBOOK_LOGIN: boolean;
};

export const flags: Flags = {
  PRODUCT_NAME:
    (config.EXPO_PUBLIC_PRODUCT_NAME as ProductName) || ProductName.FIXME,
  FEATURE_APPLE_LOGIN: config.EXPO_PUBLIC_FEATURE_APPLE_LOGIN === "true",
  SMS_VERIFICATION_ENABLED:
    config.EXPO_PUBLIC_SMS_VERIFICATION_ENABLED === "true",
  FREE_SERVICE_ENABLED: config.EXPO_PUBLIC_FREE_SERVICE_ENABLED === "true",
  FEATURE_FACEBOOK_LOGIN: config.EXPO_PUBLIC_FEATURE_FACEBOOK_LOGIN === "true",
};
