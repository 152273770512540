import CheckboxSimpleText from "@components/Checkboxes/CheckboxSimpleText";
import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { useUserDetails } from "@hooks/index";
import { useErrors } from "@hooks/useErrors";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import {
  getPatientBasicData,
  getPhysiotherapistBasicData,
} from "@services/ApiService/users";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC } from "react";
import { Control, FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, Text } from "react-native-paper";

type Props = {
  asignee: number;
  onInputPress: () => void;
  onHandleSubmitPress: () => void;
  loading: boolean;
  isValid: boolean;
  errors: FieldError[];
  control: Control;
};

export const ShareRecordFormContent: FC<Props> = ({
  asignee,
  onInputPress,
  onHandleSubmitPress,
  loading,
  isValid,
  errors,
  control,
}) => {
  const { t } = useTranslation();
  const { isPatient } = useUserDetails();
  const { setErrorsFromResponse } = useErrors();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.basicData(asignee),
    queryFn: async () =>
      await (!isPatient
        ? getPatientBasicData(asignee)
        : getPhysiotherapistBasicData(asignee)),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <>
      <Text variant="bodyLarge">{t("T00336")}:</Text>
      <Text variant="bodyMedium">
        {data.firstName} {data.lastName}
      </Text>
      <Text variant="bodyLarge">{t("T00337")}:</Text>
      <Input
        name="dueDate"
        label="T00122"
        errors={errors}
        control={control}
        calendarInput
        editable={false}
        onPress={onInputPress}
      />
      {isPatient && (
        <CheckboxSimpleText
          name="processingCheckbox"
          control={control}
          text="T01400"
        />
      )}
      <PrimaryButton
        label="T01203"
        onPress={onHandleSubmitPress}
        disabled={!isValid || loading}
        loading={loading}
      />
    </>
  );
};
