import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { config } from "@utils/config";

export const useMetadata = () => {
  const { t } = useTranslation();
  const { title, imgPath, description, keywords } = {
    title: t("T01358"),
    imgPath: `${config.EXPO_PUBLIC_API_BASE_URL}/static/img/icon/fixme_logo_1200x630.png`,
    description: t("T01425"),
    keywords: t("T01426"),
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta name={title} content={description} />
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={title} />
      <meta property="og:url" content={config.EXPO_PUBLIC_API_BASE_URL} />
      <meta property="og:image" content={imgPath} />
      <meta property="twitter:image" content={imgPath} />
      <meta name="description" content={description} />
      <meta property="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="twitter:description" content={description} />
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};
