import { Alert, AlertButton } from "react-native";
import { isWeb } from "./constants";

export const customAlert = (
  title: string,
  subtitle: string,
  buttons?: AlertButton[],
) => {
  if (isWeb) {
    const result = window.confirm(`${title}\n${subtitle}`);
    if (result) {
      buttons?.[0]?.onPress();
    } else {
      buttons?.[1]?.onPress();
    }
  } else {
    Alert.alert(title, subtitle, buttons);
  }
};
