import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill="#E8EAF6"
      fillRule="evenodd"
      d="M.72 2.289a.729.729 0 0 0-.72.735v20.233c0 .407.324.743.72.743h22.56c.396 0 .72-.336.72-.743V3.024a.729.729 0 0 0-.72-.735H.72Z"
      clipRule="evenodd"
    />
    <Path
      fill="#E64A19"
      fillRule="evenodd"
      d="M.72 2.289a.729.729 0 0 0-.72.735V9h24V3.024a.729.729 0 0 0-.72-.735H.719Z"
      clipRule="evenodd"
    />
    <Path
      fill="#424242"
      fillRule="evenodd"
      d="M13.86 14.71a.526.526 0 0 1 .514.877l-2.194 2.196a.527.527 0 0 1-.746 0l-1.352-1.352c-.49-.49.255-1.236.744-.746l.98.98 2.054-1.954Z"
      clipRule="evenodd"
    />
    <Path
      fill="#757575"
      fillRule="evenodd"
      d="M4.57 13.716H1.145v-3.432H4.57v3.432ZM4.57 18.286H1.145v-3.433H4.57v3.433ZM4.57 22.856H1.636c-.274 0-.491-.19-.491-.43V19.43H4.57v3.426ZM5.714 19.43H9.14v3.426H5.714V19.43ZM5.714 14.853H9.14v3.433H5.714v-3.433ZM5.714 10.284H9.14v3.432H5.714v-3.432ZM10.283 19.43h3.433v3.426h-3.433V19.43ZM10.283 10.284h3.433v3.432h-3.433v-3.432ZM14.854 19.43h3.432v3.426h-3.433V19.43ZM14.854 14.853h3.432v3.433h-3.433v-3.433ZM14.854 10.284h3.432v3.432h-3.433v-3.432ZM19.43 19.43h3.425v2.996c0 .24-.217.43-.491.43H19.43V19.43ZM19.43 14.853h3.425v3.433H19.43v-3.433ZM19.43 10.284h3.425v3.432H19.43v-3.432Z"
      clipRule="evenodd"
    />
    <Path
      fill="#A5A5A5"
      fillRule="evenodd"
      d="M17.16 1.15h-.223a.67.67 0 0 0-.671.672V4.05c0 .37.301.67.671.67h.222V1.15Z"
      clipRule="evenodd"
    />
    <Path
      fill="#BDBDBD"
      fillRule="evenodd"
      d="M17.346 4.72c.37 0 .672-.296.672-.671V1.822a.673.673 0 0 0-.672-.672H17.124v3.57h.222Z"
      clipRule="evenodd"
    />
    <Path
      fill="#A5A5A5"
      fillRule="evenodd"
      d="M6.894 1.15h-.222A.67.67 0 0 0 6 1.822V4.05c0 .37.301.67.672.67h.222V1.15Z"
      clipRule="evenodd"
    />
    <Path
      fill="#BDBDBD"
      fillRule="evenodd"
      d="M7.08 4.72c.37 0 .672-.296.672-.671V1.822a.673.673 0 0 0-.671-.672H6.857v3.57h.222Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default SvgComponent;
