import { useUserDetails } from "@hooks/user/useUserDetails";
import { isANDROID } from "@utils/constants";
import {
  getMediaLibraryPermissionsAsync,
  requestMediaLibraryPermissionsAsync,
} from "expo-image-picker";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Platform } from "react-native";
import { openSettings } from "react-native-permissions";

type UseMediaPermissionsOptions = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
};

const isAndroidApiLevel33AndHigher = isANDROID && +Platform.Version >= 33; //should skip asking for permissions and image picker will open without them, this is required by google for android from api 33.

export const useMediaPermissions = ({
  isModalVisible,
  setIsModalVisible,
}: UseMediaPermissionsOptions) => {
  const { t } = useTranslation();
  const { isPatient } = useUserDetails();
  const [onGranted, setOnGranted] = useState<() => Promise<void>>(null);
  const [isFromGallery, setIsFromGallery] = useState(false);

  const checkPermissions = async (
    onGrantedCb: () => Promise<void>,
    isGallery = false,
  ) => {
    if (isGallery && !isAndroidApiLevel33AndHigher) {
      setOnGranted(() => onGrantedCb);
      setIsFromGallery(isGallery);
      const permissions = await getMediaLibraryPermissionsAsync();
      if (permissions.granted) {
        await onGrantedCb();
        isModalVisible && setIsModalVisible(false);
      } else if (!isModalVisible) openPermissionsModal();
      return permissions;
    } else {
      await onGrantedCb();
      return { canAskAgain: true, granted: true };
    }
  };

  const requestPermission = async () => {
    const { granted } = await requestMediaLibraryPermissionsAsync();
    if (granted) await checkPermissions(onGranted, isFromGallery);
  };

  const openPermissionsModal = () => setIsModalVisible(true);

  const onPressConfirm = async () => {
    const { canAskAgain, granted } = await checkPermissions(
      onGranted,
      isFromGallery,
    );
    if (!granted) {
      if (canAskAgain) await requestPermission();
      else mediaAccessDeniedAlert();
    }
    return setIsModalVisible(false);
  };

  const mediaAccessDeniedAlert = useCallback(() => {
    Alert.alert(t("T01199"), t(isPatient ? "T01492" : "T01491"), [
      {
        text: t("T00145"),
        onPress: () => {},
        style: "cancel",
      },
      {
        text: t("T00002"),
        onPress: () => {
          setIsModalVisible(false);
          openSettings().catch(err => {
            console.error(err);
            alert(t("T00832"));
          });
        },
      },
    ]);
  }, [isPatient, setIsModalVisible, t]);

  return { checkPermissions, onPressConfirm };
};
