import { Platform } from "react-native";
import { config } from "./config";
import { TransKey } from "@globalTypes/i18next";

export const MAX_ABOUT_ME_DESCRIPTION_INPUT_LENGTH = 1000;
export const MAX_THREAD_DESCRIPTION_INPUT_LENGTH = 2000;
export const MAX_EXPERIENCE_VALUE = 50;
export const MAX_CODE_LENGTH = 6;

export const DISTANCE_RANGE = 30;

export const THERAPISTS_LIST_PAGE_SIZE = 10;
export const EXERCISES_LIST_PAGE_SIZE = 10;

export const LIST = "list";
export const DETAILS = "details";
export const OPTIONS = "options";
export const CHECK = "check";
export const RECENT_LIST = "recent_list";
export const MY = "my";
export const ASSIGNED_TO_PATIENT = "assigned to patient";
export const ACTIVE = "active";
export const IN_ACTIVE = "inactive";
export const STATS = "stats";
export const ENDED_APPOINTMENT = "ended_appointment";
export const SELECTED_DAY = "selected_day";
export const STATUS = "status";

export const months: TransKey[] = [
  "T00426",
  "T00427",
  "T00428",
  "T00429",
  "T00430",
  "T00431",
  "T00432",
  "T00433",
  "T00434",
  "T00435",
  "T00436",
  "T00437",
];

export const currency = {
  PLN: "PLN",
  EUR: "EUR",
  GBP: "GBP",
  USD: "USD",
};

export const days: TransKey[] = [
  "T00438",
  "T00439",
  "T00440",
  "T00441",
  "T00442",
  "T00443",
  "T00444",
];
export const MULTILINE_INPUT_MAX_LENGTH = 500;
export const MULTILINE_INPUT_MAX_LENGTH_SHORT = 250;
export const TITLE_INPUT_MAX_LENGTH = 200;
export const INPUT_MAX_LENGTH = 100;
export const REPORT_INPUT_MIN_LENGTH = 20;
export const MIN_PAIN_LEVEL = 0;
export const MAX_PAIN_LEVEL = 10;
export const MAX_REPORT_PROBLEM_WITH_TRAINING_INPUT_LENGTH = 500;
export const DEFAULT_ICON_SIZE = 24;

export const AVATAR_SIZE = 56;

export const isIOS = Platform.OS === "ios";
export const isWeb = Platform.OS === "web";
export const isANDROID = Platform.OS === "android";

export const webScreenWidth = 700;

// TODO: change for real app_ids when we will have them
export const IOS_APP_ID = config.EXPO_PUBLIC_IOS_APP_ID || "01e";
export const ANDROID_APP_ID = config.EXPO_PUBLIC_ANDROID_APP_ID || "01e";

export const WEBVIEW_URIS = {
  privacy: `${config.EXPO_PUBLIC_API_BASE_URL}/privacy-policy/`,
  terms: `${config.EXPO_PUBLIC_API_BASE_URL}/terms-of-service/`,
  aboutTheApplicationProject: `${config.EXPO_PUBLIC_API_BASE_URL}/about/`,
};

export const SERVICE_SLOT_TIME = 15;
export const SLOTS_COUNT = 16;

const webAppUris = {
  development: "https://dev-open.fixmeapp.pl/",
  staging: "https://stg-open.fixmeapp.pl/",
  production: "https://open.fixmeapp.pl/",
};

export const webAppUri = webAppUris[config.EXPO_PUBLIC_ENV] as string;

export const linkingConfiguration = {
  prefixes: [
    config.EXPO_PUBLIC_DEEP_LINK_URL,
    webAppUris.development,
    webAppUris.staging,
    webAppUris.production,
  ],
  config: {
    screens: {
      Home: {
        path: "home-stack",
        screens: {
          CalendarStack: {
            path: "calendar-stack",
            screens: {
              Calendar: "calendar",
            },
          },
          TrainingsStack: {
            path: "trainings-stack",
            screens: {
              IndividualTrainings: "individualTrainings",
              ProphylacticTrainings: "prophylacticTrainings",
            },
          },
          ProfileStack: {
            path: "profile-stack",
            screens: {
              ProfileCompletionStack: {
                path: "profile-completion-stack",
                screens: {
                  AddressAndVerification:
                    "addressAndVerification/:isFromNotification",
                  SuccessStripeModal: "onlinePayments/successStripeModal",
                },
              },
            },
          },
          ScheduleAppointmentStack: {
            path: "schedule-appointment-stack",
            screens: {
              AppointmentConfirmation: "appointmentConfirmation/:id",
            },
          },
        },
      },
      ApplicationSettings: "settings",
      ForgotPasswordWebView: "forgotPasswordWebView",
      AssignedTrainingDetails: "assignedTrainingDetails/:id",
      AppointmentDetails: "appointmentDetails/:idFromNotification/:tabName?",
      ConversationScreen: "conversationScreen/:interlocutorId",
      RecordPreview: "recordPreview/:id",
    },
  },
};

export const EXERCISE_LIMITS = {
  maxSeries: 20,
  minSeries: 1,
  maxRepetitions: 100,
  maxExerciseTime: 60 * 60 - 1,
  maxBreakTime: 60 * 60 - 1,
};

export const REFUND_PATIENT_INFO: TransKey[] = [
  "T01268",
  "T01269",
  "T01270",
  "T01271",
];
export const REFUND_THERAPIST_INFO: TransKey[] = [
  "T01272",
  "T01273",
  "T01270",
  "T01274",
];

export const MIN_AGE = 18;
export const MASSAGE_TECHNICIAN_SPECIALIZATION_ID = 8;

export const subSettingsUrl = isWeb
  ? ""
  : isIOS
  ? "https://apps.apple.com/account/subscriptions"
  : "https://play.google.com/store/account/subscriptions?package=com.fixmephysioapp.pl";

export const LAST_PUSH_PERMISSION_REQUEST_DATE =
  "last_push_permission_request_date";

export const createAndEditExerciseTimeoutMs = 900000;

export const fallbackLanguage = "en";
