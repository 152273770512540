import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import PermissionModal from "@components/Modals/PermissionModal";
import { PermissionType } from "@globalTypes/common.types";
import { useAppointmentData } from "@hooks/index";
import { useCheckNotifications } from "@hooks/notifications/useCheckNotifications";
import { useNotificationMainSettings } from "@hooks/notifications/useNotificationMainSettings";
import {
  RootStackParamList,
  ScheduleAppointmentStackParamsList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import AppointmentPaymentError from "@screens/Appointments/ScheduleAppointment/AppointmentConfirmation/AppointmentPaymentError";
import AppointmentSuccessConfirmation from "@screens/Appointments/ScheduleAppointment/AppointmentConfirmation/AppointmentSuccessConfirmation";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";

type AppointmentConfirmationProps = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<
      ScheduleAppointmentStackParamsList,
      "AppointmentConfirmation"
    >,
    NativeStackScreenProps<
      Pick<RootStackParamList, "Home" | "AppointmentDetails">
    >
  >
>;

const AppointmentConfirmation: FC<AppointmentConfirmationProps> = ({
  navigation: { navigate },
  route: {
    params: { id },
  },
  route,
}) => {
  const paymentError = route.params?.paymentError;
  const [
    showNotificationPermissionModalVisible,
    setShowNotificationPermissionModalVisible,
  ] = useState(false);
  const {
    data: appointment,
    isLoading,
    isError,
    refetch,
  } = useAppointmentData(id);
  const { data } = useNotificationMainSettings();
  const { handleCheckNotifications, isNotificationGranted, isCheckFinished } =
    useCheckNotifications({
      mainNotification: data,
      onPermissionsGranted: () =>
        setShowNotificationPermissionModalVisible(false),
    });

  useEffect(() => {
    if (!isNotificationGranted && isCheckFinished)
      setShowNotificationPermissionModalVisible(true);
  }, [isCheckFinished, isNotificationGranted]);

  const { gapMedium, container, loading } = globalStyles;
  const { contentContainer } = styles;

  const openAppointmentDetails = useCallback(() => {
    navigate("Home");
    navigate("AppointmentDetails", { id });
  }, [id, navigate]);

  if (isLoading) return <ActivityIndicator style={loading} />;

  if (isError) return <FetchError action={refetch} />;

  return (
    <SafeAreaView edges={["bottom", "left", "right"]} style={container}>
      <View style={contentContainer}>
        {paymentError ? (
          <AppointmentPaymentError />
        ) : (
          <AppointmentSuccessConfirmation
            wasPaidFor={appointment?.payment?.paymentStatus === "succeeded"}
            appointmentType={appointment?.serviceType}
          />
        )}
      </View>
      <View style={gapMedium}>
        <PrimaryButton
          label="T00976"
          onPress={openAppointmentDetails}
          mode="outlined"
        />
        <PrimaryButton label="T00977" onPress={() => navigate("Home")} />
      </View>
      <PermissionModal
        isModalVisible={showNotificationPermissionModalVisible}
        setIsModalVisible={setShowNotificationPermissionModalVisible}
        onPressConfirm={handleCheckNotifications}
        type={PermissionType.notifications}
      />
    </SafeAreaView>
  );
};

export default AppointmentConfirmation;

const styles = StyleSheet.create({
  contentContainer: { flex: 1, justifyContent: "center" },
  iconAndTextContainer: {
    alignItems: "center",
    marginBottom: spacing16,
  },
});
