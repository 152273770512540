import { OPTIONS } from "@utils/constants";

const keys = {
  settings: "settings",
  physiotherapist: "physiotherapist",
  notificationSettings: "notificationSettings",
  appointments: "appointments",
  workouts: "workouts",
  messages: "messages",
  device: "device",
};

export const queryKeysSettings = {
  all: [keys.settings] as const,
  physiotherapistSettings: () => [
    ...queryKeysSettings.all,
    keys.physiotherapist,
  ],
};

export const queryKeysNotificationSettings = {
  all: [keys.notificationSettings] as const,
  appointments: () =>
    [...queryKeysNotificationSettings.all, keys.appointments] as const,
  appointmentsOptions: () =>
    [...queryKeysNotificationSettings.all, OPTIONS, keys.appointments] as const,
  workouts: () =>
    [...queryKeysNotificationSettings.all, keys.workouts] as const,
  messagesNotificationSettings: () =>
    [...queryKeysSettings.all, keys.messages] as const,
  devicePushNotifications: () =>
    [...queryKeysNotificationSettings.all, keys.device] as const,
};
