import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";
import { ScrollView, View } from "react-native";

import { PrimaryButton } from "@components/buttons";
import { ForumStackParamsList } from "@navigators/navigation.types";
import { globalStyles } from "@styles/global";

import TopicListItem from "@components/ListItems/TopicListItem";
import { FetchError } from "@components/errors";
import { getTopics } from "@services/ApiService/forum";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, Text } from "react-native-paper";
import { queryKeysForumTopics } from "./queryKeysForum";

const Forum: FC<
  PropsWithChildren<NativeStackScreenProps<ForumStackParamsList, "Forum">>
> = ({ navigation: { navigate } }) => {
  const { scrollContainer, loading, gapLarge, gapMedium } = globalStyles;
  const { t } = useTranslation();

  const {
    data: topicIdsList,
    isLoading: isTopicIdsListLoading,
    isError: isTopicIdsListError,
    refetch: refetchTopicIdsList,
  } = useQuery({
    queryFn: getTopics,
    queryKey: queryKeysForumTopics.list(),
  });

  if (isTopicIdsListLoading) return <ActivityIndicator style={loading} />;
  if (isTopicIdsListError) return <FetchError action={refetchTopicIdsList} />;

  return (
    <ScrollView contentContainerStyle={[scrollContainer, gapLarge]}>
      <PrimaryButton label="T00876" onPress={() => navigate("CreateThread")} />
      <View style={gapMedium}>
        {topicIdsList.length ? (
          topicIdsList.map(({ id }) => (
            <TopicListItem
              id={id}
              key={`topic-id-${id}`}
              onPress={() => navigate("TopicPreview", { id })}
            />
          ))
        ) : (
          <Text>{t("T00875")}</Text>
        )}
      </View>
    </ScrollView>
  );
};

export default Forum;
