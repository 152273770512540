import { setStatus } from "@components/Status/setStatus";
import { getTime } from "@utils/date";
import { FC } from "react";
import { Card, Text } from "react-native-paper";
import { CardProps } from "./UpcomingAppointmentCard/UpcomingAppointmentCard.types";
import StatusComponent from "@components/Status/StatusComponent";
import { globalStyles } from "@styles/global";
import { styles } from "./UpcomingAppointmentCard";
import { useLocale } from "@hooks/useLocale";

export const CustomAppointmentCard: FC<CardProps> = ({
  upcomingAppointmentData,
  mode = "outlined",
  elevation,
  ...props
}) => {
  const { dateFrom, serviceType } = upcomingAppointmentData;
  const { containerOutlined, onlineBackground } = styles;
  const isOnline = serviceType === "Online";
  const { locale } = useLocale();

  return (
    <Card
      {...props}
      elevation={elevation as never}
      mode={mode}
      style={[
        mode === "outlined" && containerOutlined,
        isOnline && onlineBackground,
      ]}>
      <Card.Content style={globalStyles.gapSmall}>
        <Text variant="titleMedium" numberOfLines={3}>
          {upcomingAppointmentData?.name}
        </Text>
        <Text variant="bodyMedium">{getTime(dateFrom, locale)}</Text>
        <StatusComponent status={setStatus(upcomingAppointmentData)} />
      </Card.Content>
    </Card>
  );
};
