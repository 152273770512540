import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { PhysiotherapistCard, UserCard } from "@components/cards";
import { FetchError } from "@components/errors";

import { ActivityIndicator, Text } from "react-native-paper";
import { getSharedMedicalRecord } from "@services/ApiService/medicalRecords";
import { queryKeysMedicalRecord } from "./queryKeysMedicalRecord";
import { bottomSheetBgColor, palettes } from "@styles/colors";
import { View } from "react-native";
import { PhysiotherapistCardProps } from "@components/cards/PhysiotherapistCard/PhysiotherapistCard.types";
import { ReactElement } from "react";
import { getDate } from "@utils/date";

type RecordSharedForProps = {
  id: number;
  isPatient: boolean;
  onPress?: () => void;
  isInBottomSheet?: boolean;
};

type DataForTile = {
  initialData: PhysiotherapistCardProps["initialData"];
  subtitle?: string | ReactElement;
  onPress?: () => void;
};

const RecordSharedFor = ({
  id,
  isPatient,
  onPress,
  isInBottomSheet,
}: RecordSharedForProps) => {
  const { t } = useTranslation();

  const {
    data: sharedMedicalRecord,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: queryKeysMedicalRecord.sharedToOtherUserDetails(id),
    queryFn: async () => await getSharedMedicalRecord(id),
  });

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  const dataForTile: DataForTile = {
    initialData: { id: sharedMedicalRecord?.data?.asignee },
    onPress: onPress,
  };

  if (isPatient) {
    const dueDate = sharedMedicalRecord?.data?.dueDate;
    if (dueDate && new Date(dueDate) >= new Date()) {
      dataForTile.subtitle = (
        <View style={{ flexDirection: "row" }}>
          <Text variant="bodyMedium">{t("T00354")}: </Text>
          <Text variant="bodyMedium" style={{ color: palettes.primary[40] }}>
            {getDate(dueDate)}
          </Text>
        </View>
      );
    }
  }

  return isPatient ? (
    <PhysiotherapistCard
      {...dataForTile}
      style={isInBottomSheet && { backgroundColor: bottomSheetBgColor }}
    />
  ) : (
    <UserCard
      {...dataForTile}
      style={isInBottomSheet && { backgroundColor: bottomSheetBgColor }}
    />
  );
};

export default RecordSharedFor;
