import { SpecializationItemType } from "@components/DropdownPicker/SpecializationDropdownPicker";
import {
  GetProfileImageResponse,
  UpdateProfileImageResponse,
} from "@screens/Profiles/MyProfile/CommonProfile/verification.types";
import { PostVerificationServiceType } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import {
  AddressType,
  CertificatesDataType,
  Education,
  ExperienceApiType,
  GetLicence,
  LanguageDataType,
  OnlinePaymentMethods,
  OnlinePaymentMethodsResponse,
  PatientProgress,
  GetPhoneVerification,
  PostLicenceWithAttachment,
  PostLicenceWithAttachmentResponse,
  ProfileInformationDataType,
  RehabVerificationProgressResponse,
  ServiceDataType,
  ServiceItem,
  StationaryPaymentMethods,
  StationaryPaymentMethodsResponse,
  UpdateCertificate,
  UpdateExperienceResponse,
  UserAddress,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import FormData from "form-data";
import { api } from "./api";
import { ResponseOptions } from "./api.types";
import { endpoints } from "./endpoints";

export const getRehabVerificationProgress = async () => {
  const { data } = await api.get<RehabVerificationProgressResponse>(
    endpoints.REHAB_VERIFICATION_PROGRESS,
  );
  return data;
};

export const getPatientVerificationProgress = async () => {
  const { data } = await api.get<PatientProgress>(
    endpoints.PATIENT_VERIFICATION_PROGRESS,
  );
  return data;
};

export const getAddress = async () => {
  const { data } = await api.get<UserAddress[]>(endpoints.VERIFY_ADDRESS);
  return data;
};

export const saveAddress = (data: AddressType) => {
  return api.post<UserAddress>(endpoints.VERIFY_ADDRESS, data);
};

export const updateAddress = (id: number, params: AddressType) =>
  api.patch<UserAddress>(endpoints.UPDATE_ADDRESS(id), params);

export const getCountryOptions = async () =>
  await api.options<ResponseOptions>(endpoints.VERIFY_ADDRESS);

export const getLicence = async () => {
  const { data } = await api.get<GetLicence[]>(endpoints.VERIFY_LICENCE);
  return data;
};
export const updateLicence = ({
  licenceNumber,
  specialization,
  attachments,
}: PostLicenceWithAttachment) => {
  const formData = new FormData();

  formData.append("licence_number", licenceNumber);
  formData.append("specialization", specialization);
  attachments.forEach(att => formData.append("attachments", `${att}`));

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };

  return api.post<PostLicenceWithAttachmentResponse>(
    endpoints.VERIFY_LICENCE,
    formData,
    options,
  );
};
export const getEducation = async () => {
  const { data } = await api.get<Education[]>(endpoints.VERIFY_EDUCATION);
  return data;
};
export const setEducation = (description: string) =>
  api.post<Education>(endpoints.VERIFY_EDUCATION, {
    description,
  });
export const deleteEducation = (id: number) =>
  api.delete(endpoints.EDUCATION_DETAILS(id));
export const modifyEducation = (id: number, description: string) =>
  api.patch(endpoints.EDUCATION_DETAILS(id), { description });

export const getAboutMeInformation = async () => {
  const { data } = await api.get<ProfileInformationDataType[]>(
    endpoints.ABOUT_ME,
  );
  return data;
};
export const setAboutMeInformation = async ({
  description,
}: ProfileInformationDataType) =>
  api.post<ProfileInformationDataType>(endpoints.ABOUT_ME, {
    description,
  });
export const getExperience = async () => {
  const { data } = await api.get<ExperienceApiType[]>(endpoints.EXPERIENCE);
  return data;
};
export const setExperience = async ({ years }: ExperienceApiType) =>
  api.post<ExperienceApiType>(endpoints.EXPERIENCE, {
    years,
  });
export const updateExperience = async (
  { years }: ExperienceApiType,
  id: number,
) =>
  api.patch<ExperienceApiType>(endpoints.EXPERIENCE_DETAILS(id), {
    years,
  });

export const addAdditionalExperience = (data: {
  description: string;
  experience: number;
}) =>
  api.post<{ id: number; description: string }>(
    endpoints.ADDITIONAL_EXPERIENCE,
    data,
  );

export const updateAdditionalExperience = (
  data: {
    description: string;
  },
  id: number,
) =>
  api.patch<UpdateExperienceResponse>(
    endpoints.ADDITIONAL_EXPERIENCE_DETAILS(id),
    data,
  );

export const deleteAdditionalExperience = (id: number) =>
  api.delete(endpoints.ADDITIONAL_EXPERIENCE_DETAILS(id));

export const getCertificates = async () => {
  const { data } = await api.get<CertificatesDataType[]>(
    endpoints.CERTIFICATES,
  );
  return data;
};

export const setCertificate = ({ attachments }: UpdateCertificate) => {
  const formData = new FormData();
  formData.append("attachments", `${attachments[0]}`);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };
  return api.post<number[]>(endpoints.CERTIFICATES, formData, options);
};

export const deleteCertificate = (id: number) =>
  api.delete(endpoints.CERTIFICATE(id));

export const getServices = async (
  params?: { specializationId: number },
  page?: number,
  pageSize?: number,
) => {
  const resultParams = { ...params };

  if (page) {
    Object.assign(resultParams, { page });
  }
  if (pageSize) {
    Object.assign(resultParams, { pageSize });
  }

  const { data } = await api.get<ServiceDataType[]>(endpoints.SERVICES, {
    params: resultParams,
  });
  return data;
};

export const getServicesForSpecialization = async (
  params?: { specializationId: number },
  page?: number,
  pageSize?: number,
) => {
  const resultParams = { ...params };

  if (page) {
    Object.assign(resultParams, { page });
  }
  if (pageSize) {
    Object.assign(resultParams, { pageSize });
  }

  const { data } = await api.get<ServiceItem[]>(
    endpoints.SERVICES_FOR_SPECIALIZATION,
    { params: resultParams },
  );
  return data;
};

export const setService = (data: PostVerificationServiceType) =>
  api.post<ServiceDataType>(endpoints.SERVICES, data);

export const deleteService = (id: number) =>
  api.patch(endpoints.DELETE_SERVICE(id), { archived: true });

export const getProfileImages = async () => {
  const { data } = await api.get<GetProfileImageResponse[]>(endpoints.IMAGES);
  return data;
};

export const setProfileImage = async ({
  attachments,
  id,
}: {
  attachments: number[];
  id?: number;
}) => {
  const formData = new FormData();
  formData.append("attachments", attachments[0]);
  id && formData.append("id", id);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };
  return id
    ? api.patch<UpdateProfileImageResponse>(
        endpoints.IMAGE(id),
        formData,
        options,
      )
    : api.post<UpdateProfileImageResponse>(endpoints.IMAGES, formData, options);
};

export const deleteProfileImage = (id: number) =>
  api.delete(endpoints.IMAGE(id));

export const getSpecializations = async () => {
  const { data } = await api.get<SpecializationItemType[]>(
    endpoints.SPECIALIZATIONS,
  );

  return data;
};

export const getLanguagesOptions = async () =>
  await api.options<ResponseOptions>(endpoints.LANGUAGES);

export const getLanguagesList = async () => {
  const { data } = await api.get<Pick<LanguageDataType, "id">[]>(
    endpoints.LANGUAGES,
  );
  return { data };
};

export const setLanguage = async (language: string) =>
  await api.post<LanguageDataType>(endpoints.LANGUAGES, { language });

export const deleteLanguage = async (id: number) =>
  await api.delete(endpoints.LANGUAGE(id));

export const getOnlinePaymentMethods = async (params?: {
  physiotherapistId?: number;
}) => {
  const { data } = await api.get<OnlinePaymentMethodsResponse[]>(
    endpoints.ONLINE_PAYMENT_METHODS,
    { params },
  );

  return data;
};

export const getStationaryPaymentMethods = async (params?: {
  physiotherapistId?: number;
}) => {
  const { data } = await api.get<StationaryPaymentMethodsResponse[]>(
    endpoints.STATIONARY_PAYMENT_METHODS,
    { params },
  );
  return data;
};

export const updateOnlinePaymentMethods = async (
  id: number,
  data: OnlinePaymentMethods,
) =>
  await api.patch<OnlinePaymentMethodsResponse>(
    endpoints.ONLINE_PAYMENT_METHOD_UPDATE(id),
    data,
  );

export const updateStationaryPaymentMethods = async (
  id: number,
  data: StationaryPaymentMethods,
) =>
  await api.patch<StationaryPaymentMethodsResponse>(
    endpoints.STATIONARY_PAYMENT_METHOD_UPDATE(id),
    data,
  );

export const getPhone = async () => {
  const { data } = await api.get<GetPhoneVerification[]>(
    endpoints.VERIFY_PHONE,
  );
  return data;
};

export const setPhone = (phoneNumber: string) =>
  api.post<ExperienceApiType>(endpoints.VERIFY_PHONE, {
    phoneNumber,
  });

export const checkPhoneVerificationCode = (code: string) =>
  api.post<ExperienceApiType>(endpoints.VERIFY_PHONE_CODE_CHECK, {
    code,
  });
