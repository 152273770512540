import { yupResolver } from "@hookform/resolvers/yup";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Text } from "react-native-paper";
import { object, string } from "yup";
import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { View } from "react-native";
import { FC } from "react";
import { useMutation } from "@tanstack/react-query";
import { addAdditionalExperience } from "@services/ApiService/verifications";
import { useErrors } from "@hooks/useErrors";
import { AxiosError } from "axios";

type Props = {
  onSave: () => void;
  experience: number;
};

export const AddExperienceBottomSheet: FC<Props> = ({ onSave, experience }) => {
  const { t } = useTranslation();

  const schema = object().shape({
    description: string().required(t("T00014")).trim(),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: "",
    },
  });

  const { setErrorsFromResponse } = useErrors();

  const { mutate, isLoading } = useMutation({
    mutationFn: addAdditionalExperience,
    onSuccess: () => onSave(),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const onSubmit = (data: { description: string }) =>
    mutate({ ...data, experience });

  return (
    <View style={globalStyles.gapLarge}>
      <View style={globalStyles.gapMedium}>
        <Text variant="bodyLarge">{t("T01191")}</Text>
        <Text variant="bodyMedium">{t("T01194")}</Text>
      </View>
      <Input
        name="description"
        label="T00265"
        control={control as unknown as Control<FieldValues>}
        isInBottomSheet
        maxLength={1000}
      />
      <PrimaryButton
        label="T01191"
        disabled={isLoading}
        loading={isLoading}
        onPress={handleSubmit(onSubmit)}
      />
    </View>
  );
};
