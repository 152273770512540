import { FC, PropsWithChildren, useState } from "react";
import { FlatList, View } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ActivityIndicator, Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import Rating from "@components/Rating/Rating";
import { FetchError } from "@components/errors";

import { RootStackParamList } from "@navigators/navigation.types";
import { globalStyles } from "@styles/global";
import { useAuth } from "@contexts/AuthContext/auth";
import { getReviewsList } from "@services/ApiService/reviews";
import { queryKeysPhysiotherapistRatings } from "../Physiotherapist/queryKeysPhysiotherapist";
import { Host } from "react-native-portalize";
import Snackbar from "@components/Snackbar/Snackbar";

const MyRatings: FC<
  PropsWithChildren<NativeStackScreenProps<RootStackParamList, "MyRatings">>
> = () => {
  const { t } = useTranslation();
  const {
    user: { id },
  } = useAuth();
  const { container, gapLarge, loading } = globalStyles;
  const [snackbarVisible, setSnackbarVisible] = useState<boolean>(false);

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapistRatings.list(id),
    queryFn: async () => await getReviewsList(id),
  });

  const hideSnackbar = () => setSnackbarVisible(false);

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <Host>
      <View style={container}>
        <FlatList
          data={data}
          keyExtractor={(item, index) => `rating-${item.id}-${index}`}
          renderItem={({ item: { id } }) => (
            <Rating
              reviewId={id}
              displaySnackbar={() => setSnackbarVisible(true)}
            />
          )}
          contentContainerStyle={[gapLarge, { flex: 0 }]}
          ListHeaderComponent={<Text variant="bodyMedium">{t("T00417")}</Text>}
        />
        <Snackbar
          visible={snackbarVisible}
          onDismiss={hideSnackbar}
          onIconPress={hideSnackbar}
          text="T00560"
        />
      </View>
    </Host>
  );
};

export default MyRatings;
