import { OPTIONS } from "@utils/constants";

const keys = {
  verifications: "verifications",
  aboutMe: "aboutMe",
  address: "address",
  licence: "licence",
  certificates: "certificates",
  experience: "experience",
  education: "education",
  specialization: "specialization",
  country: "country",
  language: "language",
  amountOptions: "amountOptions",
  verificationProgress: "verificationProgress",
  verificationProgressRehabInSubscreens:
    "verificationProgressRehabInSubscreens",
  profilePhoto: "profilePhoto",
  stationaryPaymentMethods: "stationaryPaymentMethods",
  onlinePaymentMethods: "onlinePaymentMethods",
  phone: "phone",
};

export const queryKeysVerficiations = {
  all: [keys.verifications] as const,
  verificationProgress: () =>
    [...queryKeysVerficiations.all, keys.verificationProgress] as const,
  verificationProgressRehabInSubscreens: () =>
    [
      ...queryKeysVerficiations.all,
      keys.verificationProgressRehabInSubscreens,
    ] as const,
  aboutMe: () => [...queryKeysVerficiations.all, keys.aboutMe] as const,
  address: () => [...queryKeysVerficiations.all, keys.address] as const,
  options: () => [...queryKeysVerficiations.all, OPTIONS] as const,
  licence: () => [...queryKeysVerficiations.all, keys.licence] as const,
  licenceSpecialization: () =>
    [...queryKeysVerficiations.licence(), keys.specialization] as const,
  certificates: () =>
    [...queryKeysVerficiations.all, keys.certificates] as const,
  experience: () => [...queryKeysVerficiations.all, keys.experience] as const,
  education: () => [...queryKeysVerficiations.all, keys.education] as const,
  countryOptions: () => [queryKeysVerficiations.options, keys.country] as const,
  specializationOptions: () =>
    [queryKeysVerficiations.options, keys.specialization] as const,
  currencyOptions: () =>
    [...queryKeysVerficiations.all, keys.amountOptions, OPTIONS] as const,
  profilePhoto: () =>
    [...queryKeysVerficiations.all, keys.profilePhoto] as const,
  stationaryPaymentMethods: (physiotherapistId?: number) =>
    [
      ...queryKeysVerficiations.all,
      keys.stationaryPaymentMethods,
      physiotherapistId,
    ] as const,
  onlinePaymentMethods: (physiotherapistId?: number) =>
    [
      ...queryKeysVerficiations.all,
      keys.onlinePaymentMethods,
      physiotherapistId,
    ] as const,
  phone: () => [...queryKeysVerficiations.all, keys.phone] as const,
};
