import CheckboxSimpleText from "@components/Checkboxes/CheckboxSimpleText";
import { RadioButtonsGroup } from "@components/FormElements";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { yupResolver } from "@hookform/resolvers/yup";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import { getPhysiotherapistBasicData } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { spacing32 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { formatDateForApi } from "@utils/date";
import { addDays } from "date-fns";
import { FC, useMemo } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { boolean, object, string } from "yup";
import { TransKey } from "@globalTypes/i18next";

type Props = {
  physioId: number;
  dateFrom: string;
  saveAndContinue: ({ shareUntilDate }: { shareUntilDate: string }) => void;
};

export const ShareMedicalRecordsContent: FC<Props> = ({
  physioId,
  dateFrom,
  saveAndContinue,
}) => {
  const plus30DaysDate = formatDateForApi(addDays(new Date(), 30));
  const { gapMedium, gapLarge } = globalStyles;
  const { t } = useTranslation();

  const shareUntilDateData: { value: string; label: TransKey }[] = [
    {
      value: formatDateForApi(dateFrom),
      label: "T00338",
    },
    {
      value: plus30DaysDate,
      label: "T00339",
    },
  ];

  const schema = useMemo(
    () =>
      object().shape({
        shareUntilDate: string(),
        processingCheckbox: boolean()
          .oneOf([true], t("T00014"))
          .required(t("T00014")),
      }),
    [t],
  );

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.basicData(physioId),
    queryFn: async () => await getPhysiotherapistBasicData(physioId),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      shareUntilDate: formatDateForApi(dateFrom),
    },
  });

  return (
    <View style={gapLarge}>
      <View style={gapMedium}>
        <Text variant="titleMedium">{t("T00336")}:</Text>
        {isError ? (
          <FetchError action={refetch} coverScreen={false} />
        ) : isLoading ? (
          <ActivityIndicator />
        ) : (
          <Text variant="bodyMedium">
            {data?.firstName} {data?.lastName}
          </Text>
        )}
      </View>
      <Controller
        control={control}
        name="shareUntilDate"
        render={({ field: { onChange, value } }) => (
          <RadioButtonsGroup
            errorMessage={errors?.shareUntilDate?.message}
            onChange={onChange}
            value={value}
            data={shareUntilDateData}
            title="T00340"
          />
        )}
      />
      <CheckboxSimpleText
        name="processingCheckbox"
        control={control as never as Control}
        text="T01400"
      />
      <PrimaryButton
        label="T00341"
        onPress={handleSubmit(saveAndContinue)}
        style={{ marginTop: "auto", marginBottom: spacing32 }}
      />
    </View>
  );
};
