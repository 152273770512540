import { FC, useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { HelperText, IconButton, SegmentedButtons } from "react-native-paper";

import { GenderType } from "@contexts/AuthContext/user.types";
import { globalStyles } from "@styles/global";

import {
  ModelType,
  Orientation,
} from "@components/HumanModel/humanModel.types";
import { spacing28, spacing40 } from "@styles/spacing";
import { useTranslation } from "react-i18next";

import BoneModel from "@components/HumanModel/Bone";
import GeneralModel from "@components/HumanModel/General";
import MuscleModel from "@components/HumanModel/Muscle";
import { styles } from "@components/HumanModel/humanModel.styles";
import { SegmentedButtonsWithController } from "@components/SegmentedButtonsWithController";
import { useInitialParametersForHumanModel } from "@hooks/index";
import { capitalize } from "lodash";
import {
  Control,
  Controller,
  ControllerProps,
  FieldError,
} from "react-hook-form";

type HumanModelWithControllerProps = {
  errors: {
    [x: string]: FieldError[];
  };
  control: ControllerProps["control"];
  gender: GenderType;
  humanGeneral: string[];
  humanMuscles: string[];
  humanBones: string[];
  onGenderChange?: (value: GenderType) => void;
  clearErrors?: () => void;
  disableModelChange?: boolean;
};

const HumanModelWithController: FC<HumanModelWithControllerProps> = ({
  errors,
  control,
  gender,
  humanMuscles,
  humanGeneral,
  humanBones,
  onGenderChange,
  clearErrors,
  disableModelChange,
}) => {
  const {
    modelType: initialModelType,
    orientation: initialOrientation,
    anyLengthGreaterThanZero,
  } = useInitialParametersForHumanModel({
    general: humanGeneral,
    muscle: humanMuscles,
    bone: humanBones,
  });
  const [orientation, setOrientation] =
    useState<Orientation>(initialOrientation);
  const [modelType, setModelType] = useState<ModelType>(initialModelType);
  const { t } = useTranslation();

  useEffect(() => {
    if (anyLengthGreaterThanZero && clearErrors) {
      clearErrors();
    }
  }, [anyLengthGreaterThanZero, clearErrors]);

  const modelTypeButtons = [
    {
      value: ModelType.GENERAL,
      label: `${t("T00388")} ${humanGeneral?.length || ""}`,
    },
    {
      value: ModelType.MUSCLE,
      label: `${t("T00844")} ${humanMuscles?.length || ""}`,
      style: { flexGrow: humanMuscles?.length > 1 ? 2 : 1 },
    },
    {
      value: ModelType.BONE,
      label: `${t("T00843")} ${humanBones?.length || ""}`,
    },
  ];

  const genderButtons = [
    {
      value: GenderType.MALE,
      label: capitalize(t("T00054")),
    },
    {
      value: GenderType.FEMALE,
      label: capitalize(t("T00055")),
    },
  ];

  const renderModel = useCallback(
    (modelType: ModelType) => {
      switch (modelType) {
        case ModelType.MUSCLE:
          return (
            <Controller
              name="humanMuscles"
              key="humanMuscles"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MuscleModel
                  orientation={orientation}
                  defaultSelected={value as string[]}
                  onChange={onChange}
                  gender={gender}
                />
              )}
            />
          );
        case ModelType.BONE:
          return (
            <Controller
              name="humanBones"
              key="humanBones"
              control={control}
              render={({ field: { value, onChange } }) => (
                <BoneModel
                  orientation={orientation}
                  defaultSelected={value as string[]}
                  onChange={onChange}
                  gender={gender}
                />
              )}
            />
          );
        default:
          return (
            <Controller
              name="humanGeneral"
              key="humanGeneral"
              control={control}
              render={({ field: { value, onChange } }) => (
                <GeneralModel
                  orientation={orientation}
                  defaultSelected={value as string[]}
                  onChange={onChange}
                  gender={gender}
                />
              )}
            />
          );
      }
    },
    [control, gender, orientation],
  );
  const { gapLarge } = globalStyles;
  const { iconButton, container } = styles;

  return (
    <View style={[gapLarge, { position: "relative" }]}>
      {onGenderChange && (
        <SegmentedButtonsWithController
          control={control as unknown as Control}
          name="gender"
          key="gender"
          onValueChange={onGenderChange}
          buttons={genderButtons}
        />
      )}
      {!disableModelChange && (
        <SegmentedButtons
          value={modelType}
          onValueChange={(v: ModelType) => setModelType(v)}
          buttons={modelTypeButtons}
        />
      )}
      <View style={container}>
        <HelperText type="error" style={{ height: 40 }}>
          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/*@ts-ignore*/}
          {errors?.[""] && !anyLengthGreaterThanZero && errors[""]?.message}
        </HelperText>
        {renderModel(modelType)}
        <IconButton
          icon="cached"
          size={spacing40}
          style={[iconButton, { top: spacing28 }]}
          onPress={() =>
            setOrientation(orientation === "front" ? "back" : "front")
          }
        />
      </View>
    </View>
  );
};

export default HumanModelWithController;
