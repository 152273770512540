import { globalStyles } from "@styles/global";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { HelperText, RadioButton, Text } from "react-native-paper";
import { TransKey } from "@globalTypes/i18next";

export type RadioButtonsGroupProps = {
  onChange: (value: string) => void;
  value: string;
  data: {
    value: string;
    label: TransKey | string;
  }[];
  errorMessage: string;
  title?: TransKey;
  containerStyles?: StyleProp<ViewStyle>;
  errorMessagePlacement?: "TOP" | "BOTTOM";
  radioContainerStyle?: StyleProp<ViewStyle>;
  radioItemStyle?: StyleProp<ViewStyle>;
  withColon?: boolean;
  ignoreRadioButtonTranslations?: boolean;
};

export const RadioButtonsGroup: FC<RadioButtonsGroupProps> = ({
  onChange,
  value,
  errorMessage,
  data,
  title,
  containerStyles,
  errorMessagePlacement = "TOP",
  radioContainerStyle,
  withColon = true,
  radioItemStyle,
  ignoreRadioButtonTranslations = false,
}) => {
  const { t } = useTranslation();

  return (
    <RadioButton.Group onValueChange={onChange} value={`${value}`}>
      <View style={[globalStyles.gapLarge, containerStyles]}>
        {title && (
          <Text variant="titleMedium">
            {t(title)}
            {withColon && ":"}
          </Text>
        )}
        {errorMessage && errorMessagePlacement === "TOP" && (
          <HelperText type="error">{errorMessage}</HelperText>
        )}
        <View style={radioContainerStyle}>
          {data.map(({ value, label }) => (
            <TouchableOpacity
              onPress={() => onChange(value)}
              style={[styles.radioButtonContaier, radioItemStyle]}
              key={value}>
              <RadioButton.Android value={value.toString()} />
              <Text variant="bodyLarge">
                {ignoreRadioButtonTranslations ? label : t(label as TransKey)}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </View>
      {errorMessage && errorMessagePlacement === "BOTTOM" && (
        <HelperText type="error">{errorMessage}</HelperText>
      )}
    </RadioButton.Group>
  );
};

const styles = StyleSheet.create({
  radioButtonContaier: {
    flexDirection: "row",
    alignItems: "center",
  },
});
