import { FC } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { globalStyles } from "@styles/global";

type Props = {
  title: string;
  subtitle?: string;
  extras?: () => JSX.Element;
};

export const DetailsItem: FC<Props> = ({ title, subtitle, extras }) => (
  <View style={globalStyles.gapSmall}>
    <Text variant="titleMedium">{title}</Text>
    {subtitle && <Text variant="bodyMedium">{subtitle}</Text>}
    {extras && extras()}
  </View>
);
