import { SectionWithSearchbar } from "@components/Section/WithSearchbar";
import { Choice } from "@services/ApiService/api.types";
import { globalStyles } from "@styles/global";
import { capitalize } from "lodash";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Chip, Text } from "react-native-paper";
import { TransKey } from "@globalTypes/i18next";

type Props = {
  onPress: () => void;
  items: (string | Choice)[];
  onDelete: (item: string | number) => void;
  title?: TransKey;
  header?: TransKey;
  emptyResult?: TransKey;
  searchLabel: TransKey;
  isLoading?: boolean;
  rowDirection?: boolean;
};

export const FiltersSearchSection: FC<Props> = ({
  items,
  onDelete,
  isLoading,
  rowDirection,
  header,
  emptyResult: emptyResult,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionWithSearchbar {...props} />
      <View style={globalStyles.gapMedium}>
        {header && <Text variant="titleMedium">{t(header)}:</Text>}
        <View
          style={[
            globalStyles.gapMedium,
            rowDirection ? { flexDirection: "row", flexWrap: "wrap" } : null,
          ]}>
          {isLoading ? (
            <ActivityIndicator style={globalStyles.loading} />
          ) : items.length === 0 && emptyResult ? (
            <Text variant="bodyMedium">{t(emptyResult)}</Text>
          ) : (
            items.map((e, i) => {
              const item =
                typeof e === "string"
                  ? capitalize(e)
                  : typeof e === "object"
                  ? capitalize(e.displayName)
                  : null;

              return item ? (
                <Chip
                  key={`chip-${e}-${i}`}
                  onClose={() =>
                    onDelete(typeof e === "string" ? e : `${e.value}`)
                  }>
                  {item}
                </Chip>
              ) : null;
            })
          )}
        </View>
      </View>
    </>
  );
};
