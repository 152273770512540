import BackgroundWithLottieAnimation from "@components/BackgroundWithLottieAnimation";
import { TextButton } from "@components/Button";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { AnimationObject } from "lottie-react-native";
import { FC, PropsWithChildren, useState } from "react";
import { ScrollView, View, StyleSheet } from "react-native";
import InviteToTheAppBottomSheet from "./InviteToTheAppBottomSheet";
import TextSection from "./TextSection";
import InviteToTheAppBlurredFooter from "./InviteToTheAppBlurredFooter";
import QrCodeBottomSheet from "./QrCodeBottomSheet";

const animation =
  require("@assets/animations/promo_codes_and_invitations/AnimRefInviteLogo.json") as AnimationObject;

const InviteToTheApp: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "InviteToTheApp">
  >
> = () => {
  const [
    inviteToTheAppBottomSheetModalVisible,
    setInviteToTheAppBottomSheetModalVisible,
  ] = useState(false);
  const [qrCodeModalVisible, setQrCodeModalVisible] = useState(false);
  const [footerHeight, setFooterHeight] = useState(0);

  const { gapLarge } = globalStyles;
  return (
    <>
      <ScrollView
        contentContainerStyle={[gapLarge, { paddingBottom: footerHeight }]}>
        <BackgroundWithLottieAnimation animation={animation} />
        <View style={{ marginTop: spacing16 }}>
          <TextSection title="T01384" subtitle="T01385" content="T01386" />
          <TextSection title="T01381" subtitle="T01382" content="T01383" />
        </View>
        <TextButton
          title="T01380"
          onPress={() => setInviteToTheAppBottomSheetModalVisible(true)}
          {...styles}
        />
        <InviteToTheAppBottomSheet
          modalVisible={inviteToTheAppBottomSheetModalVisible}
          setModalVisible={setInviteToTheAppBottomSheetModalVisible}
        />
        <QrCodeBottomSheet
          modalVisible={qrCodeModalVisible}
          setModalVisible={setQrCodeModalVisible}
        />
      </ScrollView>
      <InviteToTheAppBlurredFooter
        onRightTextButtonPress={() => setQrCodeModalVisible(true)}
        onLayout={height => setFooterHeight(height)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  containerStyle: {
    width: "50%",
    flexDirection: "row",
    alignSelf: "center",
  },
  textStyle: { textAlign: "center" },
});

export default InviteToTheApp;
