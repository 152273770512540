import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99996 1.66666C5.39163 1.66666 1.66663 5.39166 1.66663 9.99999C1.66663 14.6083 5.39163 18.3333 9.99996 18.3333C14.6083 18.3333 18.3333 14.6083 18.3333 9.99999C18.3333 5.39166 14.6083 1.66666 9.99996 1.66666ZM9.99996 16.6667C6.32496 16.6667 3.33329 13.675 3.33329 9.99999C3.33329 6.32499 6.32496 3.33332 9.99996 3.33332C13.675 3.33332 16.6666 6.32499 16.6666 9.99999C16.6666 13.675 13.675 16.6667 9.99996 16.6667ZM9.99996 8.82499L12.9916 5.83332L14.1666 7.00832L11.175 9.99999L14.1666 12.9917L12.9916 14.1667L9.99996 11.175L7.00829 14.1667L5.83329 12.9917L8.82496 9.99999L5.83329 7.00832L7.00829 5.83332L9.99996 8.82499Z"
      fill="#BA1A1A"
    />
  </Svg>
);

export default SvgComponent;
