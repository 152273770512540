import { FC } from "react";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { useQuery } from "@tanstack/react-query";

import { FetchError } from "@components/errors";

import { queryKeysPhysiotherapistRatings } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { getReviewResponse } from "@services/ApiService/reviews";
import { spacing16 } from "@styles/spacing";
import RatingAnswerContent from "@components/Rating/RatingAnswerContent";

type Props = {
  answer: number;
  reviewId: number;
  isMyAnswer: boolean;
  onDotsPress: () => void;
};

const RatingAnswer: FC<Props> = ({
  reviewId,
  answer,
  isMyAnswer,
  onDotsPress,
}) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapistRatings.answerDetails(answer, reviewId),
    queryFn: async () => await getReviewResponse(answer),
  });
  return (
    <View style={{ marginLeft: spacing16 }}>
      {isLoading ? (
        <ActivityIndicator />
      ) : isError ? (
        <FetchError action={refetch} coverScreen={false} />
      ) : (
        <RatingAnswerContent
          text={data.content}
          isMyAnswer={isMyAnswer}
          createdAt={data.createdAt}
          onDotsPress={onDotsPress}
        />
      )}
    </View>
  );
};

export default RatingAnswer;
