import { View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import { TimePicker } from "@components/Picker";
import { spacing16 } from "@styles/spacing";
import { useTranslation } from "react-i18next";
import { TransKey } from "@globalTypes/i18next";
import { FC, useState } from "react";
import { DateTimePickerEvent } from "@react-native-community/datetimepicker";
import { roundTimeToMatchReminderTime } from "@utils/date";

type DayReminderProps = {
  title: TransKey;
  initialDate: Date;
  onChange: (date: Date) => void;
  onDismiss?: () => void;
};

const DayReminder: FC<DayReminderProps> = ({
  title,
  initialDate,
  onChange,
  onDismiss,
}) => {
  const [visible, setVisible] = useState(false);

  const handlePress = () => setVisible(true);

  const handleConfirm = ({
    hours,
    minutes,
  }: {
    hours: number;
    minutes: number;
  }) => {
    const newDate = new Date(initialDate);
    const { roundedHours, roundedMinutes } = roundTimeToMatchReminderTime(
      hours,
      minutes,
    );
    newDate.setHours(roundedHours);
    newDate.setMinutes(roundedMinutes);
    setVisible(false);
    onChange(newDate);
    onDismiss && onDismiss();
  };
  const handleChange = ({ type }: DateTimePickerEvent, date: Date) => {
    type === "set" && onChange(date);
    type === "dismissed" && onDismiss && onDismiss();
  };

  const { t } = useTranslation();
  return (
    <View style={styles.reminderContainer}>
      <Text variant="bodyLarge">{t(title)}</Text>
      <TimePicker
        visible
        visibleWebModal={visible}
        onConfirm={handleConfirm}
        hours={initialDate.getHours()}
        minutes={initialDate.getMinutes()}
        value={new Date(initialDate) || new Date()}
        onChange={handleChange}
        onPress={handlePress}
        timeIntervalIOS={10}
        onDismiss={() => setVisible(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  reminderContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingHorizontal: spacing16,
  },
});

export default DayReminder;
