import { FC, PropsWithChildren } from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "@navigators/navigation.types";
import WebView from "react-native-webview";
import { renderLoader } from "@components/Modals/WebViewModal";

export type MapWebViewScreen = PropsWithChildren<
  NativeStackScreenProps<RootStackParamList, "MapWebView">
>;

const MapWebView: FC<MapWebViewScreen> = ({
  route: {
    params: { uri },
  },
}) => {
  return (
    <WebView
      source={{ uri }}
      startInLoadingState={true}
      renderLoading={renderLoader}
    />
  );
};

export default MapWebView;
