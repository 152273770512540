import { globalStyles } from "@styles/global";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { PrimaryButton } from "@components/buttons";

type Props = {
  onAbort: () => void;
  onContinue: () => void;
};

export const AbortFillSurveyBottomSheet: FC<Props> = ({
  onAbort,
  onContinue,
}) => {
  const { t } = useTranslation();
  const { gapLarge, gapMedium } = globalStyles;

  return (
    <View style={gapLarge}>
      <View style={gapMedium}>
        <Text variant="bodyLarge">{t("T01262")}</Text>
        <Text variant="bodyMedium">{t("T01263")}</Text>
      </View>
      <PrimaryButton mode="outlined" label="T01264" onPress={onAbort} />
      <PrimaryButton label="T00472" onPress={onContinue} />
    </View>
  );
};
