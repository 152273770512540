import {
  createMaterialTopTabNavigator,
  MaterialTopTabScreenProps,
} from "@react-navigation/material-top-tabs";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/AuthContext/auth";
import { AccountType } from "../../../../contexts/AuthContext/user.types";
import { RootStackParamList } from "../../../../navigators/navigation.types";
import { theme } from "../../../../styles/theme";
import RecordsCreatedByCurrentUser from "./RecordsCreatedByCurrentUser";
import RecordsCreatedByOtherUsers from "./RecordsCreatedByOtherUser";
import { fontConfig } from "@styles/fontConfig";

export type MedicalRecordsTabsParamList = {
  RecordsCreatedByCurrentUser: undefined;
  RecordsCreatedByOtherUsers: undefined;
};

const Tab = createMaterialTopTabNavigator<MedicalRecordsTabsParamList>();

const MedicalRecords: FC<
  PropsWithChildren<
    MaterialTopTabScreenProps<RootStackParamList, "MedicalRecords">
  >
> = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const isPatient = user.accountType === AccountType.PATIENT;
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: {
          backgroundColor: theme.colors.surface,
        },
        tabBarLabelStyle: { textTransform: "none", ...fontConfig.titleSmall },
      }}>
      <Tab.Screen
        name="RecordsCreatedByCurrentUser"
        component={RecordsCreatedByCurrentUser}
        options={{ title: t("T00315") }}
      />
      <Tab.Screen
        name="RecordsCreatedByOtherUsers"
        component={RecordsCreatedByOtherUsers}
        options={{
          title: isPatient ? t("T00316") : t("T00317"),
        }}
      />
    </Tab.Navigator>
  );
};

export default MedicalRecords;
