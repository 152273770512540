import { TransKey } from "@globalTypes/i18next";
import { AnimationObject } from "lottie-react-native";

type DataType = {
  animation: AnimationObject;
  text: TransKey;
};

export const onBoardingData: DataType[] = [
  {
    animation:
      require("@assets/animations/sliders/Slider_01_Online.json") as AnimationObject,
    text: "T00445",
  },
  {
    animation:
      require("@assets/animations/sliders/Slider_02_Statistics.json") as AnimationObject,
    text: "T00446",
  },
  {
    animation:
      require("@assets/animations/sliders/Slider_04_Documents.json") as AnimationObject,
    text: "T00447",
  },
  {
    animation:
      require("@assets/animations/sliders/Slider_05_Trainings.json") as AnimationObject,
    text: "T00448",
  },
  {
    animation:
      require("@assets/animations/sliders/Slider_03_Specialists.json") as AnimationObject,
    text: "T00449",
  },
];
