import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#000"
        d="M6.667 10c0-1.833 1.5-3.333 3.333-3.333 1.834 0 3.334 1.5 3.334 3.333 0 1.834-1.5 3.334-3.334 3.334A3.343 3.343 0 0 1 6.667 10Zm-5 5h10l11.667-8.333L25.517 9.2l-6.95 4.967h4.767l13-7.5 2 2.333-14.167 11-.833 16.667H20L19.167 20l-5.833-1.666H1.667V15Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h40v40H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgComponent;
