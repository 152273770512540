import ErrorComponent from "@components/Tile/components/ErrorComponent";
import { globalStyles } from "@styles/global";
import { FC } from "react";
import { Modal, StyleSheet, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { TranslationKey } from "../../types/i18next";
import { PrimaryButton } from "../buttons";
import GeneralError from "./GeneralError";

type FetchErrorProps = {
  action: () => void;
  text?: TranslationKey;
  label?: TranslationKey;
  coverScreen?: boolean;
  fallbackButtonColor?: string;
};

const FetchError: FC<FetchErrorProps> = ({
  text = "T00116",
  label = "T00115",
  action,
  coverScreen = true,
  fallbackButtonColor,
}) => {
  if (coverScreen)
    return (
      <Modal>
        <SafeAreaView style={[styles.container, globalStyles.container]}>
          <ErrorComponent buttonBackgroundColor={fallbackButtonColor} />
          <PrimaryButton
            mode="text"
            label={label}
            onPress={action}
            labelStyle={fallbackButtonColor && { color: fallbackButtonColor }}
          />
        </SafeAreaView>
      </Modal>
    );
  if (!coverScreen)
    return (
      <View>
        <GeneralError error={text} />
        <PrimaryButton mode="text" label={label} onPress={action} />
      </View>
    );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
});

export default FetchError;
