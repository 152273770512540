import { getCurrentDatePlus30min } from "@utils/date";
import { useFutureAvailability } from "../availability/useFutureAvailability";
import { useUserDetails } from "../user/useUserDetails";
import { useQuery } from "@tanstack/react-query";
import { queryKeysVerficiations } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/queryKeysPhysiotherapistVerification";
import { getOnlinePaymentMethods } from "@services/ApiService/verifications";
import { useCheckFutureSlots } from "@hooks/availability/useCheckFutureSlots";

export const useActionTileType = () => {
  const { id: physiotherapistId } = useUserDetails();

  const { data } = useFutureAvailability({
    dateFrom: getCurrentDatePlus30min(),
    physiotherapistId,
  });

  const { data: onlinePaymentMethodsData } = useQuery({
    queryKey: queryKeysVerficiations.onlinePaymentMethods(),
    queryFn: async () => await getOnlinePaymentMethods(),
  });

  const { hasFutureSlots } = useCheckFutureSlots();

  const actionTileTypeOnlinePaymentsVisible =
    !!data?.online.length && !onlinePaymentMethodsData?.[0].onlineAppointments;

  return {
    actionTileTypeAvailabilityVisible: !hasFutureSlots,
    actionTileTypeStationaryServiceVisible: !data?.stationary,
    actionTileTypeOnlineServiceVisible: !data?.online,
    actionTileTypeOnlinePaymentsVisible,
  };
};
