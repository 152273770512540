import { LIST } from "@utils/constants";

const keys = {
  occupancyStats: "occupancyStats",
  occupancyStatsChart: "occupancyStatsChart",
  earningsStats: "earningsStats",
  earningsStatsChart: "earningsStatsChart",
  yearlyOccupancyStats: "yearlyOccupancyStats",
  yearly: "yearly",
};

export const queryKeysOccupancyStats = {
  all: [keys.occupancyStats] as const,
};

export const queryKeysOccupancyStatsChart = {
  all: [keys.occupancyStatsChart] as const,
  list: (month: number, year: number) =>
    [...queryKeysOccupancyStatsChart.all, LIST, month, year] as const,
};

export const queryKeysYearlyOccupancyStats = {
  all: [keys.yearlyOccupancyStats] as const,
  list: () => [...queryKeysYearlyOccupancyStats.all, LIST] as const,
};

export const queryKeysEarningsStats = {
  all: [keys.earningsStats] as const,
};

export const queryKeysEarningsStatsChart = {
  all: [keys.earningsStatsChart] as const,
  month: (month: number, year: number) =>
    [...queryKeysEarningsStatsChart.all, LIST, month, year] as const,
  year: () => [...queryKeysEarningsStatsChart.all, LIST, keys.yearly] as const,
};
