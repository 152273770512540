import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { AbsoluteBlurredFooter } from "@components/Footers";
import { SystemMessage } from "@components/Report/report.types";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import ExerciseInstruction from "@screens/TrainingsAndExercises/ExerciseDetails/ExerciseInstruction";
import VideoComponent from "@screens/TrainingsAndExercises/ExerciseDetails/VideoComponent";

import { CtxExerciseType } from "@screens/TrainingsAndExercises/exercise.types";
import { globalStyles } from "@styles/global";
import { spacing16, spacing32, spacing36, spacing8 } from "@styles/spacing";
import { ResizeMode } from "expo-av";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";

type ExerciseInstructionBottomSheetProps = {
  exercise: CtxExerciseType;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
};

const ExerciseInstructionBottomSheet: FC<
  ExerciseInstructionBottomSheetProps
> = ({ visible, setVisible, exercise }) => {
  const { height } = useWindowDimensions();
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const { container, margin } = styles;

  return (
    <BottomModalContainer
      modalVisible={visible}
      setModalVisible={setVisible}
      scrollableContent
      disablePanHandlers>
      <>
        <ScrollView
          style={[container, { maxHeight: height * 0.8 }]}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: footerHeight }}>
          <Text variant="titleMedium" style={margin}>
            {exercise.name}
          </Text>
          {!!exercise?.instructionExerciseVideoUrl && (
            <VideoComponent
              uri={exercise?.instructionExerciseVideoUrl}
              resizeMode={ResizeMode.COVER}
              subtitlesUrl={exercise?.instructionExerciseVideoSubtitlesUrl}
              shouldAutoStartPlaying={false}
              style={{ height: height - spacing36, width: "100%" }}
            />
          )}
          <View style={margin}>
            <ExerciseInstruction
              systemMessage={"" as SystemMessage}
              instruction={exercise.instruction}
              disableReportWrapper={true}
            />
          </View>
        </ScrollView>
        <AbsoluteBlurredFooter
          onPress={() => setVisible(false)}
          title="T00540"
          onLayout={height => setFooterHeight(height)}
        />
      </>
    </BottomModalContainer>
  );
};

export default ExerciseInstructionBottomSheet;

const styles = StyleSheet.create({
  container: {
    ...globalStyles.gapLarge,
    marginTop: spacing32,
  },
  margin: {
    marginHorizontal: spacing16,
    marginVertical: spacing8,
  },
});
