import { FC } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import EarningsTile, { StatsTileType } from "@components/Tile/EarningsTile";
import { OccupancyTile } from "@components/Tile";
import { globalStyles } from "@styles/global";
import AppointmentAmountTile from "@screens/Profiles/MyProfile/RehabStatistics/AppointmentsStatistics/AppointmentAmountTile";

const MonthlyStatisticsSection: FC = () => {
  const { t } = useTranslation();
  return (
    <View style={globalStyles.gapMedium}>
      <Text variant="titleMedium">{t("T00758")}:</Text>
      <EarningsTile type={StatsTileType.MONTHLY_SHORT} />
      <AppointmentAmountTile type={StatsTileType.MONTHLY_SHORT} />
      <OccupancyTile type={StatsTileType.MONTHLY_SHORT} />
    </View>
  );
};

export default MonthlyStatisticsSection;
