import { useCallback } from "react";
import { useInvalidateProfileDetails } from "./useInvalidateProfileDetails";
import { useInvalidateSubscriptionStatus } from "@hooks/subscriptions/useInvalidateSubscriptionStatus";

const useInvalidateProfileDetailsAndSubscriptionStatus = () => {
  const { invalidateProfileDetails } = useInvalidateProfileDetails();
  const { invalidateSubscriptionStatus } = useInvalidateSubscriptionStatus();

  const invalidateData = useCallback(async () => {
    await invalidateProfileDetails();
    await invalidateSubscriptionStatus();
  }, [invalidateProfileDetails, invalidateSubscriptionStatus]);
  return { invalidateData };
};

export default useInvalidateProfileDetailsAndSubscriptionStatus;
