import { postFile } from "@api/common";
import { endpoints } from "@api/endpoints";
import { AppbarTitleWithBackAction } from "@components/Appbar";
import AddAttachmentBottomSheet from "@components/Files/AddAttachmentBottomSheet";
import { Input } from "@components/Input";
import DocumentListItem from "@components/ListItems/DocumentListItem";
import { PrimaryButton } from "@components/buttons";
import {
  CreateForumTopicResponse,
  CreateTopicAndPostForm,
} from "@globalTypes/forum.types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useResources } from "@hooks/media/useResources/useResources";
import { useErrors } from "@hooks/useErrors";
import { ForumStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { queryKeysForumTopics } from "@screens/Profiles/MyProfile/Forum/queryKeysForum";
import { createPost, createTopic } from "@services/ApiService/forum";
import { globalStyles } from "@styles/global";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  MAX_THREAD_DESCRIPTION_INPUT_LENGTH,
  TITLE_INPUT_MAX_LENGTH,
} from "@utils/constants";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Control, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { array, number, object, string } from "yup";

const forumMaxFiles = 5;

const CreateThread: FC<
  PropsWithChildren<
    NativeStackScreenProps<ForumStackParamsList, "CreateThread">
  >
> = ({ navigation: { setOptions, goBack }, route: { params } }) => {
  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors({
    showModalOnGeneralError: true,
  });
  const queryClient = useQueryClient();

  const topicId = params?.topicId;
  const { gapLarge, scrollContainer } = globalStyles;
  const replyMode = !!params?.topicId;

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack } }) => (
        <AppbarTitleWithBackAction
          title={t(topicId ? "T00879" : "T00876")}
          onClose={goBack}
        />
      ),
    });
  }, [setOptions, t, topicId]);

  const [addAttachmentVisible, setAddAttachmentVisible] = useState(false);
  const {
    pickFilesFromDevice,
    pickedFiles,
    isLoading: isPickedFileLoading,
    removeFile,
    maxNumberOfFilesAlert,
  } = useResources({
    multiselect: true,
    maxNumberOfFiles: forumMaxFiles,
  });

  const onFinalSuccess = async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKeysForumTopics.list(),
    });
    replyMode &&
      (await queryClient.invalidateQueries({
        queryKey: queryKeysForumTopics.details(topicId),
      }));
    goBack();
  };

  const { mutate: createThreadTopic, isLoading: isCreateThreadTopicLoading } =
    useMutation({
      mutationFn: createTopic,
      onError: ({ response }: AxiosError<CreateForumTopicResponse>) => {
        setErrorsFromResponse(response);
        response.data.posts.length && alert(response.data.posts[0].detail[0]);
      },
      onSuccess: onFinalSuccess,
    });

  const { mutate: responseToPost, isLoading: isResponseToPostLoading } =
    useMutation({
      mutationFn: createPost,
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
      onSuccess: onFinalSuccess,
    });

  const { mutate: postForumFile, isLoading: isPostFileLoading } = useMutation({
    mutationFn: postFile,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: ({ name, id, file }) => {
      if (fields.length >= forumMaxFiles) return;
      append({ name, id, file });
    },
  });

  const schema = useMemo(
    () =>
      object().shape({
        title: replyMode ? string() : string().required(t("T00014")),
        text: string()
          .max(MAX_THREAD_DESCRIPTION_INPUT_LENGTH)
          .required(t("T00014")),
        files: array()
          .of(
            object().shape({
              id: number().optional(),
              file: string(),
              name: string(),
            }),
          )
          .max(forumMaxFiles),
      }),
    [t, replyMode],
  );

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    name: "files",
    control,
  });

  useEffect(() => {
    if (pickedFiles?.length) {
      setAddAttachmentVisible(false);
      pickedFiles.forEach(({ name, uri }, index) => {
        const howManyCanBeAdded = forumMaxFiles - fields.length;
        if (index >= howManyCanBeAdded) {
          removeFile(uri);
          return maxNumberOfFilesAlert();
        }
        postForumFile({
          file: { uri, name },
          endpoint: endpoints.FORUM_POST_FILES,
        });
        removeFile(uri);
      });
    }
  }, [
    append,
    fields.length,
    maxNumberOfFilesAlert,
    pickedFiles,
    postForumFile,
    removeFile,
  ]);

  const onSubmit = ({ files, text, title }: CreateTopicAndPostForm) => {
    const attachments = files?.map(({ id }) => id);
    return replyMode
      ? responseToPost({ text, topic: params?.topicId, attachments })
      : createThreadTopic({
          title,
          posts: [{ text, attachments }],
        });
  };

  const isFormLoading = useMemo(
    () => isResponseToPostLoading || isCreateThreadTopicLoading,
    [isResponseToPostLoading, isCreateThreadTopicLoading],
  );

  return (
    <>
      <KeyboardAwareScrollView
        contentContainerStyle={[scrollContainer, gapLarge]}>
        {!replyMode && (
          <Input
            control={control as unknown as Control}
            name="title"
            label="T00881"
            maxLength={TITLE_INPUT_MAX_LENGTH}
            isRequired
          />
        )}
        <Input
          control={control as unknown as Control}
          name="text"
          multiline
          label="T00883"
          maxLength={MAX_THREAD_DESCRIPTION_INPUT_LENGTH}
          isRequired
        />
        {fields.map(({ name }, index) => {
          return (
            <DocumentListItem
              key={name}
              title={name}
              onRemove={() => remove(index)}
            />
          );
        })}
        <PrimaryButton
          label="T00870"
          onPress={() => setAddAttachmentVisible(true)}
          mode="outlined"
          disabled={isPickedFileLoading || fields?.length >= forumMaxFiles}
          loading={isPostFileLoading}
        />
        <PrimaryButton
          label={replyMode ? "T00879" : "T00876"}
          onPress={handleSubmit(onSubmit)}
          loading={isFormLoading}
          disabled={isPostFileLoading}
        />
      </KeyboardAwareScrollView>
      <AddAttachmentBottomSheet
        visible={addAttachmentVisible}
        setVisible={setAddAttachmentVisible}
        pickFilesFromDevice={pickFilesFromDevice}
      />
    </>
  );
};

export default CreateThread;
