import {
  CreateTrainingStackParamsList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren } from "react";
import SetASchedule from "./SetASchedule";

type SetAScheduleScreen = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<
      CreateTrainingStackParamsList,
      "SetAIndividualTrainingSchedule"
    >,
    NativeStackScreenProps<RootStackParamList, "PatientProfileStack">
  >
>;

const SetAIndividualTrainingSchedule: FC<SetAScheduleScreen> = ({
  route,
  navigation: { navigate },
}) => {
  const { patientId } = route.params;

  return (
    <SetASchedule
      patientId={patientId}
      trainingId={route.params?.trainingId}
      navigateAfterAssignTrainingToPatient={() =>
        navigate("PatientProfileStack", {
          screen: "RehabilitationTrainings",
          params: { patientId },
        })
      }
      isNewTraining={route.params?.isNewTraining}
      editedTraining={route.params?.editedTraining}
    />
  );
};

export default SetAIndividualTrainingSchedule;
