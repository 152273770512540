import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={48} height={48} fill="none" {...props}>
    <Path
      fill="#000"
      d="M38 10v18H28v10H10V10h28Zm0-4H10c-2.2 0-4 1.8-4 4v28c0 2.2 1.8 4 4 4h20l12-12V10c0-2.2-1.8-4-4-4ZM24 28H14v-4h10v4Zm10-8H14v-4h20v4Z"
    />
  </Svg>
);
export default SvgComponent;
