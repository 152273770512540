import { createProperFileType } from "@utils/createProperFileType";
import { ImagePickerResult } from "expo-image-picker";
import { generateRandomString } from "@utils/strings";
import { isWeb } from "@utils/constants";
import FormData from "form-data";
import { CreateAndEditExerciseType } from "@screens/TrainingsAndExercises/exercise.types";
import { PostMedicalRecords } from "@screens/Profiles/MyProfile/MedicalRecords/MedicalRecords.types";
import { SuccessDocumentResult } from "@services/ApiService/common";
import { PreviewType } from "@globalTypes/common.types";

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const createFormDataWithImage = (
  image: ImagePickerResult,
  fieldName: string,
) => {
  const formData = new FormData();
  if (image.assets.length !== 1) {
    throw Error;
  }

  if (isWeb) {
    const uri = image.assets[0].uri;
    const fileName = `${generateRandomString(16)}.${
      uri.split(";")[0].split("/")[1]
    }`;
    const file = dataURLtoFile(uri, fileName);

    formData.append(fieldName, file);
  } else {
    const { uri, type, fileName } = image.assets[0];
    const supportedType = createProperFileType(type, uri);
    formData.append(fieldName, {
      uri,
      type: supportedType,
      name: fileName || uri,
    } as never);
  }

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };
  return { formData, options };
};

export const generateFile = (
  file: ImagePickerResult | Partial<SuccessDocumentResult>,
) => {
  let uri = "";

  if ("assets" in file && file.assets && file.assets.length > 0) {
    uri = file.assets[0].uri;
  } else if ("uri" in file) {
    uri = file.uri;
  } else {
    throw new Error("Niepoprawny typ pliku");
  }

  const fileName = `${generateRandomString(16)}.${
    uri.split(";")[0].split("/")[1]
  }`;

  return dataURLtoFile(uri, fileName);
};

export const createFormDataForExercise = (
  {
    name,
    humanGeneral,
    humanBones,
    humanMuscles,
    instruction,
    equipments,
    exerciseVideo,
    exerciseImage,
    instructionExerciseVideo,
    gender,
    conditions,
  }: CreateAndEditExerciseType,
  editionMode?: boolean,
) => {
  const formData = new FormData();

  formData.append("name", name);
  formData.append("gender", gender);
  formData.append("instruction", instruction);

  equipments?.forEach(equipment => formData.append("equipments", equipment));

  if ((editionMode && exerciseVideo) || !editionMode) {
    if (isWeb) {
      formData.append("exercise_video", generateFile(exerciseVideo));
    } else {
      const { uri, fileName } = exerciseVideo.assets[0];

      formData.append("exercise_video", {
        uri,
        type: createProperFileType("video", uri),
        name: fileName || uri,
      });
    }
  }
  if (humanGeneral?.length) {
    humanGeneral.forEach(el => formData.append("human_general", el));
  }
  if (humanMuscles?.length) {
    humanMuscles.forEach(el => formData.append("human_muscles", el));
  }
  if (humanBones?.length) {
    humanBones.forEach(el => formData.append("human_bones", el));
  }
  if (conditions?.length) {
    conditions.forEach(el => formData.append("conditions", el));
  }
  if (exerciseImage) {
    if (isWeb) {
      formData.append("exercise_image", generateFile(exerciseImage));
    } else {
      const { uri: imageUri, fileName: imageFileName } =
        exerciseImage.assets[0];

      formData.append("exercise_image", {
        uri: imageUri,
        type: createProperFileType("image", imageUri),
        name: imageFileName || imageUri,
      });
    }
  }

  if (instructionExerciseVideo) {
    if (isWeb) {
      formData.append(
        "instruction_exercise_video",
        generateFile(instructionExerciseVideo),
      );
    } else {
      const { uri: instructionUri, fileName: instructionFileName } =
        instructionExerciseVideo.assets[0];

      formData.append("instruction_exercise_video", {
        uri: instructionUri,
        type: createProperFileType("video", instructionUri),
        name: instructionFileName || instructionUri,
      });
    }
  }

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };
  return { formData, options };
};

export const createFormDataForMedicalRecord = ({
  title,
  description,
  painLevel,
  previouslyTreated,
  gender,
  humanGeneral,
  humanMuscles,
  humanBones,
  attachments,
}: PostMedicalRecords<number>) => {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("description", description);
  formData.append("pain_level", painLevel);
  formData.append("had_been_cured", previouslyTreated ? "Yes" : "No");
  formData.append("gender", gender);
  if (humanGeneral?.length)
    humanGeneral.forEach(el => formData.append("human_general", el));
  if (humanMuscles?.length)
    humanMuscles.forEach(el => formData.append("human_muscles", el));
  if (humanBones?.length)
    humanBones.forEach(el => formData.append("human_bones", el));
  if (attachments?.length) {
    attachments.forEach(el => formData.append("attachments", `${el}`));
  }

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };

  return { formData, options };
};

export const isPdfInFileName = (name: string) =>
  name.slice(-3) === PreviewType.PDF;

export const getFileSizeFromBase64 = (base64Uri: string) => {
  // Remove the data URI scheme and get the base64 string
  const base64String = base64Uri.split(",")[1];

  const fileSizeInBytes =
    base64String.length * (3 / 4) -
    (base64String.endsWith("==") ? 2 : base64String.endsWith("=") ? 1 : 0);

  return fileSizeInBytes;
};
