import { FC, ReactNode } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { PrimaryButton } from "@components/buttons";
import { spacing16 } from "@styles/spacing";
import { isANDROID, isIOS } from "@utils/constants";
import { globalStyles } from "@styles/global";
import { BlurView } from "@components/BlurView";
import { palettes } from "@styles/colors";
import { ButtonBasic } from "@components/Button/Button.types";
import { TransKey } from "@globalTypes/i18next";

export type AbsoluteBlurredFooterProps = {
  title?: TransKey;
  onPress?: () => void;
  onLayout: (height: number) => void;
  buttonLoading?: boolean;
  buttonDisabled?: boolean;
  bottomText?: ReactNode;
  topText?: ReactNode;
  hideButton?: boolean;
  intensity?: number;
  buttonStyle?: StyleProp<ViewStyle>;
  buttons?: ButtonBasic[];
};

export const AbsoluteBlurredFooter: FC<AbsoluteBlurredFooterProps> = ({
  title,
  onPress,
  onLayout,
  buttonLoading,
  buttonDisabled,
  bottomText,
  topText,
  intensity,
  buttonStyle,
  hideButton = false,
  buttons = [],
}) => {
  const { blurredBackground, buttonContainer } = styles;
  return (
    <BlurView
      onLayout={({ nativeEvent }) => onLayout(nativeEvent.layout.height)}
      style={[
        blurredBackground,
        buttonStyle,
        isANDROID && { backgroundColor: palettes.primary[100] },
      ]}
      intensity={intensity ? intensity : isIOS ? 50 : 100}
      tint="light">
      {topText}
      <View
        style={[
          buttonContainer,
          buttons.length ? globalStyles.gapMedium : globalStyles.gapSmall,
        ]}>
        {buttons.length
          ? buttons.map(btn => <PrimaryButton key={`${btn.label}`} {...btn} />)
          : !hideButton && (
              <PrimaryButton
                label={title}
                onPress={onPress}
                loading={buttonLoading}
                disabled={buttonDisabled}
              />
            )}
        {bottomText}
      </View>
    </BlurView>
  );
};

const styles = StyleSheet.create({
  blurredBackground: {
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
  },
  buttonContainer: {
    padding: spacing16,
    marginBottom: spacing16,
  },
});
