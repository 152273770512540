import { GenderType } from "@contexts/AuthContext/user.types";
import { FileAttachment } from "@globalTypes/common.types";
import { CreateAndEditExerciseType } from "@screens/TrainingsAndExercises/exercise.types";
import { MAX_PAIN_LEVEL, MIN_PAIN_LEVEL } from "@utils/constants";
import { useTranslation } from "react-i18next";
import { ObjectSchema, array, boolean, number, object, string } from "yup";

export const useHumanModelSchemas = () => {
  const { t } = useTranslation();

  const fieldIsRequiredMessage = t("T00014");
  const humanRules = array().of(string().required(fieldIsRequiredMessage));
  const stringRules = string().required(fieldIsRequiredMessage).trim();
  const genderValidationSchema = string()
    .required(fieldIsRequiredMessage)
    .trim()
    .oneOf(Object.values(GenderType));

  const humanModelTest = (values: {
    gender?: GenderType;
    humanGeneral?: string[];
    humanMuscles?: string[];
    humanBones?: string[];
  }) => {
    const { humanGeneral, humanMuscles, humanBones } = values;
    return (
      humanGeneral?.length >= 1 ||
      humanMuscles?.length >= 1 ||
      humanBones?.length >= 1
    );
  };

  const testObjectArgs = {
    type: "at-least-one-array",
    message: t("T00845"),
    fn: humanModelTest,
  };

  const humanModelShape = {
    gender: genderValidationSchema,
    humanGeneral: humanRules,
    humanMuscles: humanRules,
    humanBones: humanRules,
  };

  const humanModelSchema = object()
    .shape(humanModelShape)
    .test(testObjectArgs.type, testObjectArgs.message, testObjectArgs.fn);

  const fileAttachmentSchema: ObjectSchema<Omit<FileAttachment, "thumbnail">> =
    object().shape({
      id: number().optional(),
      file: string(),
      name: string(),
    });

  const medicalRecordSchema = object()
    .shape({
      title: stringRules,
      description: stringRules,
      painLevel: number()
        .required(fieldIsRequiredMessage)
        .typeError(t("T00035"))
        .min(
          MIN_PAIN_LEVEL,
          t("T00033", {
            value: MIN_PAIN_LEVEL,
          }),
        )
        .max(
          MAX_PAIN_LEVEL,
          t("T00034", {
            value: MAX_PAIN_LEVEL,
          }),
        ),
      previouslyTreated: stringRules,
      processingCheckbox: boolean()
        .oneOf([true], t("T00014"))
        .required(t("T00014")),
      attachments: array().of(fileAttachmentSchema),
      ...humanModelShape,
    })
    .test(testObjectArgs.type, testObjectArgs.message, testObjectArgs.fn);

  const exerciseSchema: ObjectSchema<
    Omit<
      CreateAndEditExerciseType,
      "exerciseVideo" | "instructionExerciseVideo" | "exerciseImage"
    >
  > = object().shape({
    name: stringRules,
    instruction: string(),
    equipments: array().of(number()).nullable(),
    exerciseVideoUrl: string(),
    exerciseImageUrl: string(),
    instructionExerciseVideoUrl: string(),
    conditions: array().of(number()),
    gender: genderValidationSchema,
    humanGeneral: array().of(string()),
    humanMuscles: array().of(string()),
    humanBones: array().of(string()),
  });

  return {
    exerciseSchema,
    medicalRecordSchema,
    humanModelSchema,
  };
};
