import { globalStyles } from "@styles/global";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { MD3TypescaleKey, Text, TextProps } from "react-native-paper";
import { TransKey } from "@globalTypes/i18next";

type TwoPartsTextProps = {
  leftText: TransKey;
  rightText: string;
} & Omit<TextProps<MD3TypescaleKey>, "children">;

const TwoPartsText: FC<TwoPartsTextProps> = ({
  leftText,
  rightText,
  style = globalStyles.gapSmall,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <View
      style={[
        {
          flexDirection: "row",
        },
        style,
      ]}>
      <Text variant="titleSmall" {...props}>
        {t(leftText)}:
      </Text>
      <Text variant="bodyMedium" {...props}>
        {rightText}
      </Text>
    </View>
  );
};

export default TwoPartsText;
