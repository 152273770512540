import { useWindowDimensions as useRNWindowDimensions } from "react-native";
import { isWeb, webScreenWidth } from "@utils/constants";

export const useWindowDimensions = () => {
  const { width, height } = useRNWindowDimensions();

  const webWidth = width < webScreenWidth ? width : webScreenWidth;

  return { width: isWeb ? webWidth : width, height };
};
