import {
  PostServiceType,
  PricesType,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification/services.types";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing12 } from "@styles/spacing";
import { FC, useCallback, useMemo } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from "react-native";
import { Chip, IconButton, Text } from "react-native-paper";
import { useAuth } from "@contexts/AuthContext/auth";
import { Trans, useTranslation } from "react-i18next";
import { getPrice } from "@utils/prices";

type ServiceTileProps = {
  service: PostServiceType;
  onlyForRehabUse?: boolean;
  payment?: PricesType;
} & TouchableOpacityProps;

const ServiceTile: FC<ServiceTileProps> = ({
  service: { serviceItem, serviceType, slots, prices },
  onlyForRehabUse,
  onPress,
  payment,
  ...props
}) => {
  const {
    user: { currency: userCurrency },
  } = useAuth();
  const { t } = useTranslation();
  const { gapMedium } = globalStyles;
  const { container, mainContainer } = styles;

  const basePrice = useMemo(() => {
    if (payment) {
      const { amount, currency } = payment;
      return { baseAmount: amount, baseCurrency: currency };
    }
    return getPrice(prices, userCurrency);
  }, [payment, prices, userCurrency]);

  const freeServicePrice = useMemo(
    () => (
      <Text variant="bodyMedium">
        <Trans
          i18nKey="T01523"
          components={{
            1: (
              <Text
                style={{
                  fontWeight: "bold",
                }}>
                Free
              </Text>
            ),
          }}
        />
      </Text>
    ),
    [],
  );
  const timeAndBasePrice = useMemo(() => {
    const { baseAmount, baseCurrency } = basePrice;
    return `${slots * 15} ${t("T00820")} ${
      !serviceItem.freeService ? `| ${baseAmount} ${baseCurrency}` : ""
    }`;
  }, [basePrice, serviceItem.freeService, slots, t]);

  const time = useMemo(() => `${slots * 15} ${t("T00820")}`, [slots, t]);

  const renderAllPrices = useCallback(() => {
    const { baseAmount, baseCurrency } = basePrice;
    const otherPrices = prices?.filter(
      price => price.currency !== baseCurrency,
    );
    if (serviceItem.freeService) return freeServicePrice;
    return (
      <View style={[{ flexDirection: "row" }, gapMedium]}>
        <Text variant="titleSmall">
          {baseAmount} {baseCurrency}
        </Text>
        {otherPrices.map(({ amount, currency }, index) => (
          <Text variant="bodyMedium" key={`${amount}-${currency}-${index}`}>
            {`|  ${amount} ${currency?.toUpperCase()}`}
          </Text>
        ))}
      </View>
    );
  }, [basePrice, freeServicePrice, gapMedium, prices, serviceItem.freeService]);

  return (
    <TouchableOpacity
      style={[container, mainContainer]}
      onPress={onPress}
      disabled={!onPress}
      {...props}>
      <View style={[gapMedium, { flex: 1 }]}>
        <Text variant="bodyLarge" numberOfLines={2}>
          {serviceItem.name}
        </Text>
        <View style={[container, gapMedium]}>
          <Chip
            compact
            style={{
              backgroundColor:
                serviceType === "Online"
                  ? palettes.tertiary[90]
                  : palettes.secondary[90],
            }}>
            <Text variant="bodyMedium">
              {t(serviceType === "Stationary" ? "T00484" : "T00483")}
            </Text>
          </Chip>
          <View>
            <Text variant="bodyMedium">
              {onlyForRehabUse ? time : timeAndBasePrice}
            </Text>
          </View>
        </View>
        {onlyForRehabUse
          ? renderAllPrices()
          : serviceItem.freeService
          ? freeServicePrice
          : null}
      </View>
      {onPress && <IconButton icon="playlist-edit" />}
    </TouchableOpacity>
  );
};

export default ServiceTile;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  mainContainer: {
    backgroundColor: palettes.secondary[99],
    justifyContent: "space-between",
    padding: spacing12,
  },
});
