import { useAuth } from "@contexts/AuthContext/auth";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import { queryKeysPhysiotherapistProfileData } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { queryKeysPatientCompletion } from "@screens/Profiles/MyProfile/PatientCompletion/queryKeysPatientCompletion";
import { queryKeysVerficiations } from "@screens/Profiles/MyProfile/PhysiotherapistVerification/queryKeysPhysiotherapistVerification";
import { useUserDetails } from "..";

export const useInvalidateProfileDetails = () => {
  const queryClient = useQueryClient();
  const { isPhysiotherapist } = useUserDetails();
  const {
    user: { id },
  } = useAuth();

  const invalidateProfileDetails = useCallback(async () => {
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: queryKeysVerficiations.profilePhoto(),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysForPatientAndPhysiotherapist.basicData(id),
      }),
      isPhysiotherapist &&
        queryClient.invalidateQueries({
          queryKey: queryKeysPhysiotherapistProfileData.full(id),
        }),
      queryClient.invalidateQueries({
        queryKey: isPhysiotherapist
          ? queryKeysVerficiations.verificationProgress()
          : queryKeysPatientCompletion.verificationProgressPatient(),
      }),
      queryClient.invalidateQueries({
        queryKey: isPhysiotherapist
          ? queryKeysVerficiations.verificationProgressRehabInSubscreens()
          : queryKeysPatientCompletion.verificationProgressPatientInSubscreens(),
      }),
    ]);
  }, [id, isPhysiotherapist, queryClient]);
  return { invalidateProfileDetails };
};
