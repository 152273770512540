import { useUserDetails } from "@hooks/user/useUserDetails";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  AppbarTitleWithLogo,
  AppBarTitleWithLogoForPhysiotherapist,
} from "@components/Appbar";
import HomeScreenPatient from "@screens/Home/HomeScreenPatient";
import HomeScreenPhysiotherapist from "@screens/Home/HomeScreenPhysiotherapist";
import { HomeStackParamList } from "./navigation.types";
import useOneSignal from "@hooks/notifications/useOneSignal";

const Stack = createNativeStackNavigator<HomeStackParamList>();

const HomeStackNavigator: FC = () => {
  const { t } = useTranslation();
  const { isPatient } = useUserDetails();

  useOneSignal();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="HomeScreen"
        component={isPatient ? HomeScreenPatient : HomeScreenPhysiotherapist}
        options={{
          header: () =>
            isPatient ? (
              <AppbarTitleWithLogo title={t("T00063")} />
            ) : (
              <AppBarTitleWithLogoForPhysiotherapist title={t("T00063")} />
            ),
        }}
      />
    </Stack.Navigator>
  );
};

export default HomeStackNavigator;
