import Svg, { Path, G, Defs, ClipPath, Rect, SvgProps } from "react-native-svg";

type Props = { props?: SvgProps; customColor?: string };

const SvgComponent = ({ props, customColor }: Props) => (
  <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <G clipPath="url(#clip0_53300_28841)">
      <Path
        d="M16.6665 1.66699H3.33317C2.4165 1.66699 1.67484 2.41699 1.67484 3.33366L1.6665 18.3337L4.99984 15.0003H16.6665C17.5832 15.0003 18.3332 14.2503 18.3332 13.3337V3.33366C18.3332 2.41699 17.5832 1.66699 16.6665 1.66699ZM13.6498 10.5837L11.6665 9.00033V10.8337C11.6665 11.292 11.2915 11.667 10.8332 11.667H5.83317C5.37484 11.667 4.99984 11.292 4.99984 10.8337V5.83366C4.99984 5.37533 5.37484 5.00033 5.83317 5.00033H10.8332C11.2915 5.00033 11.6665 5.37533 11.6665 5.83366V7.66699L13.6498 6.08366C14.1915 5.65033 14.9998 6.03366 14.9998 6.73366V9.93366C14.9998 10.6337 14.1915 11.017 13.6498 10.5837Z"
        fill={customColor || "black"}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_53300_28841">
        <Rect width="20" height="20" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgComponent;
