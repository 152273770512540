import { TransKey } from "@globalTypes/i18next";
import { FC } from "react";
import { View } from "react-native";
import { Switch, Text } from "react-native-paper";
import { settingsStyles } from "../settings.styles";
import { useTranslation } from "react-i18next";

type SwitchSectionProps = {
  switchOn: boolean;
  onChange: (val: boolean) => void;
  title: TransKey;
  description?: TransKey;
};

const SwitchSection: FC<SwitchSectionProps> = ({
  switchOn,
  onChange,
  title,
  description,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <View style={settingsStyles.switchContainer}>
        <Text variant="titleSmall" style={{ flex: 1 }}>
          {t(title)}
        </Text>
        <Switch value={switchOn} onValueChange={onChange} />
      </View>
      {description && <Text variant="bodySmall">{t(description)}</Text>}
    </>
  );
};

export default SwitchSection;
