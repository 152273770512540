import { FC, useEffect, useState } from "react";
import DropdownPicker, {
  OnPickerValueChange,
  PickerItem,
} from "@components/DropdownPicker/DropdownPicker";
import { getFullTrainingData } from "@api/trainings";
import { useQuery } from "@tanstack/react-query";
import { queryKeysTraining } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { ActivityIndicator } from "react-native-paper";
import { FetchError } from "@components/errors";

type ExerciseDropdownPickerProps = {
  trainingId: number;
  onValueChange: OnPickerValueChange;
};

const ExerciseDropdownPicker: FC<ExerciseDropdownPickerProps> = ({
  trainingId,
  onValueChange,
}) => {
  const [items, setItems] = useState<PickerItem[]>([]);
  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: queryKeysTraining.detail(trainingId, { fullData: true }),
    queryFn: async () => await getFullTrainingData(trainingId, true),
    onSuccess: data => onValueChange(data.exercises[0].id.toString()),
  });

  useEffect(() => {
    if (!isError && !isLoading) {
      const items: PickerItem[] = data?.exercises?.map(({ name, id }) => ({
        label: name,
        value: id.toString(),
      }));
      setItems(items);
    }
  }, [data, isError, isLoading]);

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    items?.length > 0 && (
      <DropdownPicker
        dropdownLabelText="T00536"
        items={items}
        dropDownDirection="TOP"
        onValueChange={onValueChange}
      />
    )
  );
};

export default ExerciseDropdownPicker;
