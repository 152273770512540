import { SvgSearch } from "@assets/svg";
import { PrimaryButton } from "@components/buttons";
import { globalStyles } from "@styles/global";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";

type Props = {
  clearAllFilters: () => void;
  anyFilterActive: boolean;
};

export const NoResultsInfo: FC<Props> = ({
  clearAllFilters,
  anyFilterActive,
}) => {
  const { gapLarge, gapMedium } = globalStyles;
  const { t } = useTranslation();

  return (
    <View style={[gapLarge, styles.container]}>
      <SvgSearch />
      <View style={gapMedium}>
        <Text variant="titleMedium">{t("T01344")}</Text>
        <Text variant="bodyMedium">{t("T01345")}</Text>
      </View>
      {anyFilterActive && (
        <PrimaryButton label="T00565" onPress={clearAllFilters} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
});

export default NoResultsInfo;
