import { GetAvailabilityResponse } from "@components/PhysioAvailability/PhysioAvailability.types";
import { DateArrayType } from "@components/Calendar";

export enum AppointmentSlots {
  ONLINE = "Online Consultation",
  STATIONARY = "Stationary Consultation",
  ONLINE_OR_STATIONARY = "Online/Stationary",
}

export type AppointmentsAvailabilityResponse = {
  id: number;
  physiotherapist: number;
  appointment: number | null;
  dateFrom: string;
  dateTo: string;
  serviceType: AppointmentSlots;
};

export type PostAvailabilityHoursRangeType = {
  serviceType: AppointmentSlots;
  hourFrom: string;
  hourTo: string;
  dates: Date[];
};

export type PostAvailabilityHoursRangeErrorType = {
  serviceType: string;
  hourFrom: string;
  hourTo: string;
  dates: string;
};

export type AvailabilityHoursRangeFormType = {
  serviceType: AppointmentSlots;
};

export type CalendarDates = {
  initialDate: DateArrayType;
  data: GetAvailabilityResponse;
  datesWithEvents: DateArrayType[];
};
export type HoursConfirmType = {
  hours: number;
  minutes: number;
};
