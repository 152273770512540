import { MessagesNotificationSetting } from "@globalTypes/messagesNotificationSetting";
import { useErrors } from "@hooks/useErrors";
import { queryKeysNotificationSettings } from "@screens/Profiles/MyProfile/queryKeysSettings";
import {
  getMessagesNotificationsSettings,
  updateMessagesNotificationsSettings,
} from "@services/ApiService/appSettings";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";

export const useMessagesNotificationsSettings = () => {
  const [interlocutorMessagesSwitchOn, setInterlocutorMessagesSwitchOn] =
    useState(false);
  const [assistantMessagesSwitchOn, setAssistantMessagesSwitchOn] =
    useState(false);
  const { setErrorsFromResponse } = useErrors();
  const {
    data: messagesNotificationSettings,
    isSuccess,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: queryKeysNotificationSettings.messagesNotificationSettings(),
    queryFn: getMessagesNotificationsSettings,
    select: data => data?.length && data[0],
  });

  const queryClient = useQueryClient();

  const onSuccessHandler = useCallback(
    async () =>
      await queryClient.invalidateQueries(
        queryKeysNotificationSettings.messagesNotificationSettings(),
      ),
    [queryClient],
  );

  const onErrorHandler = useCallback(
    ({ response }: AxiosError) => setErrorsFromResponse(response),
    [setErrorsFromResponse],
  );

  const { mutate: updateMessagesNotifications } = useMutation({
    mutationFn: async ({
      id,
      notificationsActive,
    }: Omit<MessagesNotificationSetting, "fixmeAssistantNotifications">) => {
      setInterlocutorMessagesSwitchOn(prev => !prev);
      await updateMessagesNotificationsSettings({ id, notificationsActive });
    },
    onError: onErrorHandler,
    onSuccess: onSuccessHandler,
  });

  const { mutate: updateAssistantMessagesNotifications } = useMutation({
    mutationFn: async ({
      id,
      fixmeAssistantNotifications,
    }: Omit<MessagesNotificationSetting, "notificationsActive">) => {
      setAssistantMessagesSwitchOn(prev => !prev);
      await updateMessagesNotificationsSettings({
        id,
        fixmeAssistantNotifications,
      });
    },
    onError: onErrorHandler,
    onSuccess: onSuccessHandler,
  });

  const onInterlocutorMessagesSwitchChange = useCallback(
    (val: boolean) => {
      updateMessagesNotifications({
        id: messagesNotificationSettings?.id,
        notificationsActive: val,
      });
    },
    [messagesNotificationSettings?.id, updateMessagesNotifications],
  );

  const onAssistantMessagesSwitchChange = useCallback(
    (val: boolean) => {
      updateAssistantMessagesNotifications({
        id: messagesNotificationSettings?.id,
        fixmeAssistantNotifications: val,
      });
    },
    [messagesNotificationSettings?.id, updateAssistantMessagesNotifications],
  );

  useEffect(() => {
    if (isSuccess) {
      setInterlocutorMessagesSwitchOn(
        messagesNotificationSettings?.notificationsActive,
      );
      setAssistantMessagesSwitchOn(
        messagesNotificationSettings?.fixmeAssistantNotifications,
      );
    }
  }, [
    isSuccess,
    messagesNotificationSettings?.fixmeAssistantNotifications,
    messagesNotificationSettings?.notificationsActive,
  ]);

  return {
    isLoading,
    isError,
    interlocutorMessagesSwitchOn,
    assistantMessagesSwitchOn,
    refetch,
    onInterlocutorMessagesSwitchChange,
    onAssistantMessagesSwitchChange,
  };
};
