import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={162} height={224} fill="none" {...props}>
    <Path
      fill="#4666FF"
      d="M139.515 58.562V40.229c0-11.257-6.082-20.68-16.688-23.174v-2.493c0-3.043-2.522-5.5-5.6-5.5a5.493 5.493 0 0 0-5.525 5.5v2.493c-10.643 2.494-16.688 11.88-16.688 23.174v18.333l-4.82 4.73c-2.337 2.31-.705 6.27 2.596 6.27h48.838c3.301 0 4.969-3.96 2.633-6.27l-4.746-4.73Zm-22.288 22c4.08 0 7.417-3.3 7.417-7.333h-14.833c0 4.033 3.3 7.333 7.416 7.333ZM97.87 17.239c1.557-1.394 1.594-3.777.111-5.244-1.409-1.393-3.708-1.43-5.154-.073-6.342 5.72-10.718 13.493-12.127 22.22-.333 2.237 1.41 4.253 3.709 4.253 1.78 0 3.337-1.283 3.634-3.043 1.112-7.113 4.672-13.457 9.827-18.113Zm43.87-5.317c-1.484-1.357-3.783-1.32-5.192.073-1.483 1.467-1.409 3.814.111 5.207 5.118 4.657 8.715 11 9.827 18.113.26 1.76 1.817 3.044 3.634 3.044 2.262 0 4.042-2.017 3.672-4.254-1.41-8.69-5.748-16.426-12.052-22.183Z"
    />
    <Path
      fill="#3F3D56"
      d="M84.079 102.761a1.353 1.353 0 0 1 .716.737 1.338 1.338 0 0 1-.014 1.027l-1.823 4.234.207.105-1.243 2.874-.199-.111-6.53 15.168a2.33 2.33 0 0 1-2.774 1.318l-10.153-2.884a1.774 1.774 0 0 1-1.126-.973 1.76 1.76 0 0 1 .02-1.487l10.234-21.222a2.18 2.18 0 0 1 2.456-1.177l9.99 2.314c.082.018.162.044.239.077Z"
    />
    <Path
      fill="#4666FF"
      d="m75.629 102.003 5.252 1.217a.442.442 0 0 0 .488-.222.498.498 0 0 1 .554-.252l1.106.256a1.056 1.056 0 0 1 .706.554 1.058 1.058 0 0 1 .028.896l-9.45 21.997a1.21 1.21 0 0 1-1.46.68l-10.082-3.042a.936.936 0 0 1-.574-1.293l9.989-21.086a1.508 1.508 0 0 1 1.7-.823l1.167.27a.754.754 0 0 0 .576.848Z"
    />
    <Path
      fill="#FFB8B8"
      d="M50.626 217.391h6.24l2.968-24.07h-9.21l.002 24.07Z"
    />
    <Path
      fill="#2F2E41"
      d="m68.901 223.186-19.612.002-.001-7.578 12.035-.001a7.575 7.575 0 0 1 7.001 4.677c.38.92.577 1.905.577 2.9Z"
    />
    <Path
      fill="#FFB8B8"
      d="M16.988 217.391h6.241l2.967-24.07h-9.21l.002 24.07Z"
    />
    <Path
      fill="#2F2E41"
      d="m35.264 223.186-19.612.002v-7.578l12.034-.001a7.578 7.578 0 0 1 7.578 7.577Z"
    />
    <Path
      fill="#FFB8B8"
      d="M35.265 46.281c7.404 0 13.407-6.002 13.407-13.406 0-7.405-6.003-13.407-13.407-13.407S21.858 25.47 21.858 32.875c0 7.404 6.003 13.406 13.407 13.406ZM81.107 120.306a5.117 5.117 0 0 0-6.601-4.247l-7.493-8.981-6.568 3.222 10.759 12.535a5.145 5.145 0 0 0 9.903-2.529ZM16.623 129.39a5.122 5.122 0 0 0-1.327-6.095 5.109 5.109 0 0 0-1.965-1.03l-1.504-11.6-7.272-.805 2.354 16.351a5.148 5.148 0 0 0 9.714 3.179Z"
    />
    <Path
      fill="#CCC"
      d="M6.446 122.296a2.455 2.455 0 0 1-2.43-2.096L.438 96.096a17.326 17.326 0 0 1 1.054-8.981l10.99-27.424a6.87 6.87 0 1 1 12.646 5.358l-5.18 11.588a79.078 79.078 0 0 0-6.345 41.468 2.455 2.455 0 0 1-1.719 2.634l-4.715 1.449a2.484 2.484 0 0 1-.722.108Z"
    />
    <Path
      fill="#2F2E41"
      d="M32 46.28a14.303 14.303 0 1 1 2.68-28.29c2.949-1.79 6.376-2.994 9.812-2.688 3.436.306 6.833 2.346 8.118 5.547 1.285 3.201-.096 7.42-3.29 8.721-2.048.834-4.36.43-6.546.094-2.186-.337-4.57-.556-6.472.571-1.902 1.128-2.757 4.152-1.054 5.562a5.862 5.862 0 0 1 1.684 6.452c-.856 2.214-3.193 3.993-4.932 4.03ZM15.173 212.543a2.45 2.45 0 0 1-1.76-.742 2.45 2.45 0 0 1-.697-1.779L15.1 119.51l.26-.005 39.425-.86 6.807 88.012a2.465 2.465 0 0 1-1.627 2.49l-.075.015-7.737.473a2.462 2.462 0 0 1-1.99-.187 2.45 2.45 0 0 1-1.193-1.605l-12.613-56.439a.821.821 0 0 0-.825-.64.819.819 0 0 0-.783.69l-9.319 58.619a2.47 2.47 0 0 1-2.3 2.067l-7.834.4a2.568 2.568 0 0 1-.124.003Z"
    />
    <Path
      fill="#CCC"
      d="M26.775 127.002c-4.906 0-9.19-.741-11.556-2.856-1.52-1.359-2.178-3.207-1.955-5.493.841-8.593-.616-27.428-1.986-41.716a25.378 25.378 0 0 1 5.852-18.71 24.171 24.171 0 0 1 16.85-8.608c4.513-.322 15.082-1.071 18.367 7.343 4.936 12.64 2.522 18.437.188 24.042-1.984 4.763-4.034 9.688-1.52 18.935a138.202 138.202 0 0 0 5.036 15.065c1.361 3.49 2.044 5.242 1.413 6.386-.497.903-1.738 1.296-3.855 1.706-.691.134-1.605.328-2.693.559-5.53 1.176-15.745 3.347-24.141 3.347Z"
    />
    <Path
      fill="#CCC"
      d="m67.055 119.08-.057-.001a2.453 2.453 0 0 1-1.843-.897l-13.897-16.935a26.435 26.435 0 0 1-5.662-12.58l-4.279-26.741a6.87 6.87 0 0 1 8.54-7.726 6.87 6.87 0 0 1 5.063 5.822l1.701 14.184a73.29 73.29 0 0 0 14.708 35.991l.972 1.261a2.455 2.455 0 0 1-.12 3.143l-3.301 3.666a2.451 2.451 0 0 1-1.825.813Z"
    />
  </Svg>
);
export default SvgComponent;
