import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import React, { FC, PropsWithChildren } from "react";
import PatientProfile from "../screens/Profiles/PatientProfile";
import RehabilitationTrainings from "../screens/Profiles/PatientProfileOptions/RehabilitationTrainings";
import { PatientProfileStackParamsList } from "./navigation.types";
import { AppbarTitleWithBackAction } from "../components/Appbar";
import { useTranslation } from "react-i18next";

const Stack = createNativeStackNavigator<PatientProfileStackParamsList>();

const PatientProfileStackNavigator: FC<
  PropsWithChildren<
    NativeStackScreenProps<PatientProfileStackParamsList, "PatientProfile">
  >
> = () => {
  const { t } = useTranslation();
  return (
    <Stack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
      }}>
      <Stack.Screen
        name="PatientProfile"
        component={PatientProfile}
        options={{
          header: ({ navigation: { goBack } }) => (
            <AppbarTitleWithBackAction title={t("T00190")} onClose={goBack} />
          ),
        }}
      />
      <Stack.Screen
        name="RehabilitationTrainings"
        component={RehabilitationTrainings}
        options={{
          header: ({ navigation: { goBack } }) => (
            <AppbarTitleWithBackAction title={t("T00189")} onBack={goBack} />
          ),
        }}
      />
    </Stack.Navigator>
  );
};

export default PatientProfileStackNavigator;
