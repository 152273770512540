import AppbarTitleWithDefaultBackAction from "@components/Appbar/AppbarTitleWithDefaultBackAction";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import AddAttachmentBottomSheet from "@components/Files/AddAttachmentBottomSheet";
import MediaWithButton from "@components/Image/MediaWithButton";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { showProfileVerificationSnackbar } from "@components/snackbars";
import { FileAttachment, PreviewType } from "@globalTypes/common.types";
import { useInvalidateProfileDetails } from "@hooks/index";
import { imageAndPdfTypes } from "@hooks/media/useResources/constants";
import { useResources } from "@hooks/media/useResources/useResources";
import { useErrors } from "@hooks/useErrors";
import {
  ProfileCompletionStackParamList,
  ProfileStackParamsList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { postFile } from "@services/ApiService/common";
import { endpoints } from "@services/ApiService/endpoints";
import {
  deleteCertificate,
  getCertificates,
  setCertificate,
} from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isPdfInFileName } from "@utils/files";

import { AxiosError } from "axios";
import { MediaTypeOptions } from "expo-image-picker";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFieldArray, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { queryKeysVerficiations } from "./queryKeysPhysiotherapistVerification";
import { CreateCertificateForm } from "./verification.types";

type CertificatesScreen = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<ProfileCompletionStackParamList, "Certificates">,
    CompositeScreenProps<
      NativeStackScreenProps<ProfileStackParamsList>,
      NativeStackScreenProps<RootStackParamList>
    >
  >
>;

const MAX_CERTIFICATES_AMOUNT = 5;

const Certificates: FC<CertificatesScreen> = ({
  navigation: { setOptions, navigate },
}) => {
  const [currentCertificateId, setCurrentCertificateId] =
    useState<number>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [addAttachmentBottomSheetVisible, setAddAttachmentBottomSheetVisible] =
    useState(false);

  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();
  const {
    pickFilesFromDevice,
    pickedFiles,
    isLoading: isPickedFileLoading,
    removeFile,
    maxNumberOfFilesAlert,
  } = useResources({
    multiselect: true,
    maxNumberOfFiles: MAX_CERTIFICATES_AMOUNT,
    supportedFileTypes: imageAndPdfTypes,
    mediaTypes: MediaTypeOptions.Images,
  });

  const queryClient = useQueryClient();
  const { loading, scrollContainer, gapLarge, gapMedium } = globalStyles;

  const {
    isLoading: isGetCertificatesLoading,
    isError: isGetCertificatesError,
    refetch: getCertificatesRefetch,
    data: certificatesData,
    isSuccess,
  } = useQuery({
    queryKey: queryKeysVerficiations.certificates(),
    queryFn: getCertificates,
  });

  const { mutate: postCertificateFile, isLoading: isPostFileLoading } =
    useMutation({
      mutationFn: postFile,
      onError: ({ response }: AxiosError<FileAttachment>) => {
        alert(response.data.file[0]);
      },
      onSuccess: ({ id }) => {
        if (fields.length >= MAX_CERTIFICATES_AMOUNT) return;
        postCertificate({ attachments: [id] });
      },
    });

  const invalidateCertificates = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKeysVerficiations.verificationProgress(),
    });
    await queryClient.invalidateQueries({
      queryKey: queryKeysVerficiations.certificates(),
    });
    await queryClient.invalidateQueries({
      queryKey: queryKeysVerficiations.verificationProgressRehabInSubscreens(),
    });
  }, [queryClient]);

  const deleteCertificateSuccessHandler = async (
    _: unknown,
    certificateId: number,
  ) => {
    await invalidateProfileDetails();
    await invalidateCertificates();
    const idToRemove = fields.findIndex(e => e.certificateId === certificateId);
    remove(idToRemove);
    setModalVisible(false);
    showProfileVerificationSnackbar(t("T01311"));
  };

  const { mutate: deleteAction, isLoading: isDeleting } = useMutation({
    mutationFn: deleteCertificate,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: deleteCertificateSuccessHandler,
  });

  const { mutate: postCertificate, isLoading: isPostCertificateLoading } =
    useMutation({
      mutationFn: setCertificate,
      onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
      onSuccess: async () => {
        await getCertificatesRefetch();
        await invalidateCertificates();
        showProfileVerificationSnackbar(t("T01311"));
      },
    });

  const { control }: UseFormReturn<CreateCertificateForm> = useForm();

  const { fields, append, remove } = useFieldArray({
    name: "files",
    control,
  });

  useEffect(() => {
    if (isSuccess && certificatesData?.length) {
      remove();
      certificatesData.forEach(({ attachments, id: certificateId }) => {
        if (attachments?.length) {
          const { id, name, file, thumbnail } = attachments[0];
          append({ id, name, file, thumbnail, certificateId });
        }
      });
    }
  }, [append, certificatesData, isSuccess, remove]);

  useEffect(() => {
    if (pickedFiles?.length) {
      setAddAttachmentBottomSheetVisible(false);
      pickedFiles.forEach(({ name, uri }, index) => {
        const howManyCanBeAdded = MAX_CERTIFICATES_AMOUNT - fields.length;
        if (index >= howManyCanBeAdded) {
          removeFile(uri);
          return maxNumberOfFilesAlert();
        }
        postCertificateFile({
          file: { uri, name },
          endpoint: endpoints.CERTIFICATE_FILES,
        });
        removeFile(uri);
      });
    }
  }, [
    append,
    fields.length,
    maxNumberOfFilesAlert,
    pickedFiles,
    postCertificateFile,
    removeFile,
  ]);

  useEffect(() => {
    setOptions({
      header: ({ navigation }) => (
        <AppbarTitleWithDefaultBackAction
          navigation={navigation}
          title={t("T00274")}
          filledIcon={!!fields?.length}
        />
      ),
    });
  }, [setOptions, t, navigate, fields?.length]);

  const onCertificatePressHandler = (id: number) => {
    setCurrentCertificateId(id);
    setModalVisible(true);
  };

  const selectFileLoading = useMemo(
    () => isPostFileLoading || isDeleting || isPostCertificateLoading,
    [isDeleting, isPostCertificateLoading, isPostFileLoading],
  );

  const selectFileDisabled = useMemo(
    () =>
      isPickedFileLoading ||
      fields?.length >= MAX_CERTIFICATES_AMOUNT ||
      isDeleting,
    [fields?.length, isDeleting, isPickedFileLoading],
  );

  if (isGetCertificatesLoading) return <ActivityIndicator style={loading} />;
  if (isGetCertificatesError)
    return <FetchError action={getCertificatesRefetch} />;
  return (
    <ScrollView contentContainerStyle={[scrollContainer, gapLarge]}>
      <Text variant="bodyMedium">{t("T00630")}</Text>
      <Text variant="bodyMedium">{t("T00631")}</Text>
      <PrimaryButton
        label="T00534"
        onPress={() => setAddAttachmentBottomSheetVisible(true)}
        mode="outlined"
        loading={selectFileLoading}
        disabled={selectFileDisabled}
      />
      <Text variant="bodyMedium">{`${t("T00535")}${imageAndPdfTypes.map(
        t => ` .${t.toLowerCase()}`,
      )}.`}</Text>
      <View style={gapMedium}>
        <Text variant="titleMedium">{t("T00637")}:</Text>
        {fields?.length ? (
          <View key="certifcates-list">
            {fields?.map(({ id, file, certificateId, thumbnail, name }, i) => (
              <MediaWithButton
                key={`certificate-${id}-${i}`}
                imgUri={thumbnail || file}
                buttonLabel="T00146"
                onPressButton={() => onCertificatePressHandler(certificateId)}
                onPressItem={() => {
                  navigate("PreviewScreen", {
                    uri: file,
                    previewType: isPdfInFileName(name)
                      ? PreviewType.PDF
                      : PreviewType.IMG,
                  });
                }}
                isImgThumbnail
              />
            ))}
          </View>
        ) : (
          <Text variant="bodyMedium">{t("T00638")}</Text>
        )}
      </View>
      <BottomModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}>
        <View style={gapMedium}>
          <Text variant="bodyLarge">{t("T00675")}</Text>
          <Text variant="bodyMedium">{t("T00306")}</Text>
          <PrimaryButton
            label="T00146"
            onPress={() => deleteAction(currentCertificateId)}
            loading={isDeleting}
            disabled={isDeleting}
          />
        </View>
      </BottomModalContainer>
      <AddAttachmentBottomSheet
        visible={addAttachmentBottomSheetVisible}
        setVisible={setAddAttachmentBottomSheetVisible}
        pickFilesFromDevice={pickFilesFromDevice}
      />
    </ScrollView>
  );
};

export default Certificates;
