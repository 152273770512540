import { spacing32 } from "@styles/spacing";
import { StyleSheet } from "react-native";

export const settingsStyles = StyleSheet.create({
  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: spacing32,
  },
});
