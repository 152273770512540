import { FC } from "react";
import { StyleSheet, View } from "react-native";
import SeriesInfo from "@components/BottomSheetContents/components/SeriesInfo";
import { spacing16 } from "@styles/spacing";
import { globalStyles } from "@styles/global";
import { PredictedValues } from "@screens/TrainingsAndExercises/training.types";

type Props = {
  data: PredictedValues[];
};

const SuggestedParametersData: FC<Props> = ({ data }) => {
  const { column, container } = styles;
  const firstColumn = data?.slice(0, Math.ceil(data.length / 2));
  const secondColumn = data?.slice(firstColumn.length);

  return data ? (
    <View style={container}>
      <View style={column}>
        {firstColumn.map((e, i) => (
          <SeriesInfo key={`series-first-${i}`} order={i + 1} {...e} />
        ))}
      </View>
      <View style={column}>
        {secondColumn.map((e, i) => (
          <SeriesInfo
            key={`series-second-${i}`}
            order={firstColumn.length + i + 1}
            {...e}
          />
        ))}
      </View>
    </View>
  ) : null;
};

export default SuggestedParametersData;

const styles = StyleSheet.create({
  column: {
    ...globalStyles.gapMedium,
    paddingStart: spacing16,
  },
  container: {
    ...globalStyles.gapLarge,
    flexDirection: "row",
  },
});
