import { DETAILS } from "@utils/constants";

const keys = {
  predictedParameters: "predictedParameters",
};

export const parametersQueryKeys = {
  all: [keys.predictedParameters] as const,
  detail: (exerciseId: number, patientId: number) =>
    [parametersQueryKeys.all, DETAILS, exerciseId, patientId] as const,
};
