import { FC, useCallback, useState } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import Carousel from "react-native-reanimated-carousel";
import { AnimationObject } from "lottie-react-native";

import { SliderDots } from "@components/Slider/SliderDots";
import { SliderAnimation } from "@components/Slider/SliderAnimation";

import { onBoardingData } from "@frontendData/onboarding_slider";
import { useMediaStyle } from "@hooks/ui/useMediaStyle";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { TransKey } from "@globalTypes/i18next";

type Props = { carouselStyle?: ViewStyle };

export const OnBoardingSlider: FC<Props> = ({ carouselStyle }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { width } = useMediaStyle();
  const { height } = useWindowDimensions();

  const renderItem = useCallback(
    ({
      item: { animation, text },
      index,
    }: {
      item: { animation: AnimationObject; text: TransKey };
      index: number;
    }) => (
      <SliderAnimation
        key={`slider-animation-${index}`}
        animation={animation}
        text={text}
      />
    ),
    [],
  );

  return (
    <View style={styles.carouselContainer}>
      <Carousel
        loop
        data={onBoardingData}
        autoPlay
        pagingEnabled
        width={width}
        autoPlayInterval={5000}
        onProgressChange={(_, absoluteProgress) =>
          setCurrentIndex(absoluteProgress)
        }
        style={[{ height: height / 1.75 }, carouselStyle]}
        renderItem={renderItem}
      />
      <SliderDots data={onBoardingData} currentIndex={currentIndex} />
    </View>
  );
};

const styles = StyleSheet.create({
  carouselContainer: {
    flex: 4,
    alignItems: "center",
  },
});
