import { BottomTutorialComponent } from "@components/BottomSheet";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { FC } from "react";
import { TutorialBottomSheetProps } from "./Payments/OnlinePaymentsTutorial";

import { REFUND_PATIENT_INFO, REFUND_THERAPIST_INFO } from "@utils/constants";
import { useUserDetails } from "@hooks/index";

const RefundTutorial: FC<TutorialBottomSheetProps> = ({
  modalVisible,
  setModalVisible,
}) => {
  const { isPhysiotherapist } = useUserDetails();
  return (
    <BottomModalContainer
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}>
      <BottomTutorialComponent
        title="T01122"
        subtitles={
          isPhysiotherapist ? REFUND_THERAPIST_INFO : REFUND_PATIENT_INFO
        }
        onPress={() => setModalVisible(false)}
      />
    </BottomModalContainer>
  );
};

export default RefundTutorial;
