import ShareRecordForm from "@components/Forms/ShareRecordForm";
import { FetchError } from "@components/errors";
import { OnlyIdProfile } from "@contexts/AuthContext/user.types";
import { useErrors } from "@hooks/useErrors";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { queryKeysPhysiotherapist } from "@screens/Appointments/queryKeysAppointments";
import { getMyPhysiotherapists } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { UserListItem, UserListItemMode } from "@components/ListItems";
import { useAssistant } from "@hooks/user/useAssistant";

const PhysioListForRecordSharing: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "PhysioListForRecordSharing">
  >
> = ({
  navigation: { navigate },
  route: {
    params: { medicalRecordId },
  },
}) => {
  const [asignee, setAsignee] = useState<number>(null);
  const [shareFormModalVisible, setShareFormModalVisible] = useState(false);
  const [calendarModalVisible, setCalendarModalVisible] = useState(false);

  const { assistantId } = useAssistant();
  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapist.myList(),
    queryFn: async () =>
      await getMyPhysiotherapists({
        notSharingMedicalRecordId: medicalRecordId,
      }),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const { loading, gapLarge } = globalStyles;

  const handleCardPress = ({ id }: OnlyIdProfile) => {
    setAsignee(id);
    setShareFormModalVisible(true);
  };

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "bottom", "left"]}>
      <FlatList
        data={data.filter(({ id }) => id !== assistantId)}
        renderItem={({ item }) => (
          <UserListItem
            mode={UserListItemMode.THERAPISTS}
            id={item.id}
            onPress={() => handleCardPress(item)}
          />
        )}
        keyExtractor={({ id }: OnlyIdProfile, index: number) =>
          `rehabilitator-list-item-${id}-${index}`
        }
        contentContainerStyle={[{ padding: spacing16 }, gapLarge]}
        ListEmptyComponent={() => <Text>{t("T00327")}</Text>}
        ItemSeparatorComponent={() => (
          <Divider bold style={{ marginTop: spacing16 }} />
        )}
      />
      <ShareRecordForm
        asignee={asignee ? asignee : data[0].id}
        medicalRecord={medicalRecordId}
        onRecordShareSuccess={() =>
          navigate("ShareRecord", { id: medicalRecordId })
        }
        setShareFormModalVisible={setShareFormModalVisible}
        shareFormModalVisible={shareFormModalVisible}
        setCalendarModalVisible={setCalendarModalVisible}
        calendarModalVisible={calendarModalVisible}
      />
    </SafeAreaView>
  );
};

export default PhysioListForRecordSharing;
