import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";

import Rating from "@components/Rating/Rating";
import { FetchError } from "@components/errors";

import { useAuth } from "@contexts/AuthContext/auth";
import { queryKeysPhysiotherapistRatings } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import { getReviewsList } from "@services/ApiService/reviews";
import { spacing16 } from "@styles/spacing";
import { globalStyles } from "@styles/global";

type Props = {
  displaySnackbar: () => void;
};

export const PhysiotherapistRatings: FC<Props> = ({ displaySnackbar }) => {
  const { t } = useTranslation();
  const {
    user: { id },
  } = useAuth();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapistRatings.list(id),
    queryFn: async () => await getReviewsList(id, 3),
  });

  if (isLoading) return <ActivityIndicator />;
  if (isError) return <FetchError action={refetch} coverScreen={false} />;

  return (
    <View style={globalStyles.gapLarge}>
      <Text variant="titleMedium">{t("T00455")}</Text>
      {isLoading ? (
        <ActivityIndicator />
      ) : isError ? (
        <FetchError action={refetch} coverScreen={false} />
      ) : data?.length > 0 ? (
        data?.map(({ id }, i) => (
          <Rating
            key={`rating-${id}-${i}`}
            reviewId={id}
            disableReply
            isLast={i > data.length || data.length === 1}
            displaySnackbar={displaySnackbar}
            disableNumberRating
          />
        ))
      ) : (
        <Text style={{ marginTop: spacing16 }} variant="bodyMedium">
          {t("T00682")}
        </Text>
      )}
    </View>
  );
};
