import { MicAndCamBlockedType } from "@components/Appointment/types";
import { isWeb } from "@utils/constants";
import { useTranslation } from "react-i18next";
import { Platform, Alert } from "react-native";
import {
  PERMISSIONS,
  checkMultiple,
  RESULTS,
  requestMultiple,
  openSettings,
} from "react-native-permissions";

const micAndCamPermissions = Platform.select({
  ios: [PERMISSIONS.IOS.CAMERA, PERMISSIONS.IOS.MICROPHONE],
  android: [PERMISSIONS.ANDROID.CAMERA, PERMISSIONS.ANDROID.RECORD_AUDIO],
});

export const useCheckAndRequestMicAndCamPermissions = () => {
  const { t } = useTranslation();

  const checkMicAndCamPermissionGranted = async () => {
    if (isWeb) return false;
    try {
      const result = await checkMultiple(micAndCamPermissions);
      const isGranted = Object.values(result).every(
        status => status === RESULTS.GRANTED,
      );
      return isGranted;
    } catch (err) {
      console.error(err);
      alert(t("T00832"));
      return false;
    }
  };

  const requestMicAndCamPermissions = async (
    onRequestEnd?: () => void,
    handleRequestResult?: (args: MicAndCamBlockedType) => void,
    withCustomAlert = true,
  ) => {
    if (isWeb) return;
    try {
      const result = await requestMultiple(micAndCamPermissions);
      const isCameraBlocked =
        result[micAndCamPermissions[0]] === RESULTS.BLOCKED;
      const isMicBlocked = result[micAndCamPermissions[1]] === RESULTS.BLOCKED;

      handleRequestResult &&
        handleRequestResult({ isCameraBlocked, isMicBlocked });

      if (!isCameraBlocked && !isMicBlocked) {
        onRequestEnd && onRequestEnd();
        return;
      }

      const isBothBlocked = isCameraBlocked && isMicBlocked;

      if (withCustomAlert) {
        displayMicCamOrBothBlockedAlert(
          isCameraBlocked,
          isBothBlocked,
          onRequestEnd,
        );
      }
    } catch (err) {
      console.error(err);
      alert(t("T00832"));
    }
  };

  const displayMicCamOrBothBlockedAlert = (
    cameraBlocked = false,
    bothBlocked = false,
    cancelAlertCallback?: () => void,
  ) => {
    if (isWeb) return;
    let title = t("T01086");
    let subtitle = t("T01087");

    if (true === bothBlocked) {
      title = t("T01082");
      subtitle = t("T01083");
    } else if (true === cameraBlocked) {
      title = t("T01084");
      subtitle = t("T01085");
    }

    Alert.alert(title, subtitle, [
      {
        text: t("T00145"),
        onPress: !!cancelAlertCallback && cancelAlertCallback,
        style: "cancel",
      },
      {
        text: t("T00002"),
        onPress: () =>
          openSettings().catch(err => {
            console.error(err);
            alert(t("T00832"));
          }),
      },
    ]);
  };

  const updateMicAndCamIconsState = async (
    onMicAndCamBlocked: (args: MicAndCamBlockedType) => void,
  ) => {
    if (isWeb) return;
    try {
      const result = await checkMultiple(micAndCamPermissions);
      const isCameraBlocked =
        result[micAndCamPermissions[0]] === RESULTS.BLOCKED;
      const isMicBlocked = result[micAndCamPermissions[1]] === RESULTS.BLOCKED;
      onMicAndCamBlocked({ isCameraBlocked, isMicBlocked });
    } catch (err) {
      console.error(err);
      alert(t("T00832"));
    }
  };
  return {
    updateMicAndCamIconsState,
    checkMicAndCamPermissionGranted,
    displayMicCamOrBothBlockedAlert,
    requestMicAndCamPermissions,
  };
};
