/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Platform, ScrollView, StyleSheet } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  endConnection,
  initConnection,
  Purchase,
  PurchaseError,
  requestSubscription,
  useIAP,
  validateReceiptIos,
  withIAPContext,
} from "react-native-iap";
import { globalStyles } from "@styles/global";
import { isIOS } from "@utils/constants";
import {
  AndroidSubscription,
  AppleSubscription,
} from "@components/Subscriptions";
import { config } from "@utils/config";

const errorLog = ({ message, error }) => {
  console.error("An error happened", message, error);
};

const skus = Platform.select({
  ios: ["fix_sub_01_month"],
  android: ["fix_sub_01_month", "fix_sub_01_month_2"],
});

const BuySubscriptionScreen: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "BuySubscriptionScreen">
  >
> = ({ navigation }) => {
  const [loading, setLoading] = useState<boolean>(true);

  const {
    connected,
    subscriptions, //returns subscriptions for this app.
    getSubscriptions, //Gets available subsctiptions for this app.
    currentPurchase, //current purchase for the tranasction
    finishTransaction,
    purchaseHistory, //return the purchase history of the user on the device (sandbox user in dev)
    getPurchaseHistory, //gets users purchase history
  } = useIAP();

  const { loading: styleLoading, scrollContainer } = globalStyles;

  console.debug({
    connected,
    subscriptions,
    getSubscriptions,
    currentPurchase,
    finishTransaction,
    purchaseHistory,
    getPurchaseHistory,
  });

  const handleGetPurchaseHistory = useCallback(async () => {
    try {
      await getPurchaseHistory();
    } catch (error: unknown) {
      errorLog({ message: "handleGetPurchaseHistory", error });
    }
    setLoading(false);
  }, [getPurchaseHistory]);

  useEffect(() => {
    setLoading(true);
    initConnection().catch(error => console.error(error));
    return () => {
      void endConnection();
    };
  }, []);

  const handleGetSubscriptions = useCallback(async () => {
    try {
      await getSubscriptions({ skus });
    } catch (error: unknown) {
      errorLog({ message: "handleGetSubscriptions", error });
    }
  }, [getSubscriptions]);

  useEffect(() => {
    if (connected) {
      void handleGetSubscriptions();
      void handleGetPurchaseHistory();
    }
  }, [connected, handleGetSubscriptions, handleGetPurchaseHistory]);

  useEffect(() => {
    // ... listen History and subscriptions exist
    if (purchaseHistory.find(({ productId }) => skus.includes(productId))) {
      navigation.navigate("Home");
    }
  }, [connected, navigation, purchaseHistory, subscriptions]);

  const handleBuySubscription = async (sku: string, offerToken?: string) => {
    try {
      await requestSubscription({
        sku,
        ...(offerToken && { subscriptionOffers: [{ sku, offerToken }] }),
      });
    } catch (error: unknown) {
      errorLog({
        message:
          error instanceof PurchaseError
            ? `[${error.code}]: ${error.message}`
            : "handleBuySubscription",
        error,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const checkCurrentPurchase = async (purchase: Purchase) => {
      if (purchase) {
        try {
          const receipt = purchase.transactionReceipt;
          if (receipt) {
            if (isIOS) {
              const appleReceiptResponse = await validateReceiptIos({
                receiptBody: {
                  "receipt-data": receipt,
                  password: config.EXPO_PUBLIC_APPLE_SHARED_SECRET,
                },
                isTest: config.EXPO_PUBLIC_APPLE_SUB_TEST_MODE === "true",
              });

              if (appleReceiptResponse) {
                const { status } = appleReceiptResponse;
                if (status) {
                  navigation.navigate("Home");
                }
              }

              return;
            }
          }
        } catch (error) {
          console.error("error", error);
        }
      }
      setLoading(false);
    };
    void checkCurrentPurchase(currentPurchase);
  }, [currentPurchase, finishTransaction, navigation]);

  const goBack = () =>
    navigation.reset({
      index: 0,
      routes: [{ name: "Home" }],
    });

  if (loading) return <ActivityIndicator style={styleLoading} />;

  const commonSubscriptionProps = {
    subscriptions,
    purchaseHistory,
    loading,
    setLoading,
    handleBuySubscription,
    goBack,
    handleGetPurchaseHistory,
  };

  return (
    <SafeAreaView edges={["bottom", "left", "right"]}>
      <ScrollView contentContainerStyle={[scrollContainer]}>
        <Text variant="titleLarge" style={styles.listItem}>
          Choose your subscription plan.
        </Text>
        {isIOS ? (
          <AppleSubscription {...commonSubscriptionProps} />
        ) : (
          <AndroidSubscription {...commonSubscriptionProps} />
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

export default withIAPContext(BuySubscriptionScreen);

const styles = StyleSheet.create({
  listItem: {
    paddingLeft: 8,
    paddingBottom: 3,
    textAlign: "center",
  },
});
