type CustomCircleBaseProps = {
  cx: number;
  cy: number;
  transform?: string;
  id: string;
};

export const maleGeneralFrontData: CustomCircleBaseProps[] = [
  {
    cx: 82.902,
    cy: 98.002,
    id: "G1",
  },
  {
    cx: 139.902,
    cy: 98.002,
    id: "G2",
  },
  {
    cx: 49.795,
    cy: 123.002,
    id: "G3",
  },
  {
    cx: 89.902,
    cy: 131.002,
    id: "G4",
  },
  {
    cx: 130.902,
    cy: 131.002,
    id: "G5",
  },
  {
    cx: 10,
    cy: 10,
    transform: "matrix(-1 0 0 1 180.795 113.002)",
    id: "G6",
  },
  {
    cx: 43.795,
    cy: 180.002,
    id: "G7",
  },
  {
    cx: 91.902,
    cy: 188.002,
    id: "G8",
  },
  {
    cx: 131.795,
    cy: 188.002,
    id: "G9",
  },
  {
    cx: 10,
    cy: 10,
    transform: "matrix(-1 0 0 1 188.795 170.002)",
    id: "G10",
  },
  {
    cx: 35.795,
    cy: 222.002,
    id: "G11",
  },
  {
    cx: 111.795,
    cy: 229.002,
    id: "G12",
  },
  {
    cx: 10,
    cy: 10,
    transform: "matrix(-1 0 0 1 197.795 212.002)",
    id: "G13",
  },
  {
    cx: 22.795,
    cy: 264.002,
    id: "G14",
  },
  {
    cx: 85.795,
    cy: 257.002,
    id: "G15",
  },
  {
    cx: 10,
    cy: 10,
    transform: "matrix(-1 0 0 1 147.795 247.002)",
    id: "G16",
  },
  {
    cx: 10,
    cy: 10,
    transform: "matrix(-1 0 0 1 210.795 256.002)",
    id: "G17",
  },
  {
    cx: 76.795,
    cy: 325.002,
    id: "G18",
  },
  {
    cx: 10,
    cy: 10,
    transform: "matrix(-1 0 0 1 155.795 315.002)",
    id: "G19",
  },
  {
    cx: 73.795,
    cy: 385.002,
    id: "G20",
  },
  {
    cx: 10,
    cy: 10,
    transform: "matrix(-1 0 0 1 157.795 375.002)",
    id: "G21",
  },
  {
    cx: 73.795,
    cy: 429.002,
    id: "G22",
  },
  {
    cx: 10,
    cy: 10,
    transform: "matrix(-1 0 0 1 159.795 419.002)",
    id: "G23",
  },
  {
    cx: 67.902,
    cy: 490.002,
    id: "G24",
  },
  {
    cx: 10,
    cy: 10,
    transform: "matrix(-1 0 0 1 163.902 480.002)",
    id: "G25",
  },
  {
    cx: 67.902,
    cy: 530.002,
    id: "G26",
  },
  {
    cx: 10,
    cy: 10,
    transform: "matrix(-1 0 0 1 163.902 520.002)",
    id: "G27",
  },
];

export const maleGeneralBackData: CustomCircleBaseProps[] = [
  {
    cx: 90.188,
    cy: 50.488,
    id: "G28",
  },
  {
    cx: 130.295,
    cy: 50.488,
    id: "G29",
  },
  {
    cx: 110.295,
    cy: 76.002,
    id: "G30",
  },
  {
    cx: 81.295,
    cy: 99.002,
    id: "G31",
  },
  {
    cx: 139.295,
    cy: 99.002,
    id: "G32",
  },
  {
    cx: 49.188,
    cy: 123.488,
    id: "G33",
  },
  {
    cx: 110.295,
    cy: 123.002,
    id: "G34",
  },
  {
    cx: 10,
    cy: 10,
    id: "G35",
    transform: "matrix(-1 0 0 1 180.188 113.488)",
  },
  {
    cx: 50.188,
    cy: 160.488,
    id: "G36",
  },
  {
    cx: 79.295,
    cy: 168.002,
    id: "G37",
  },
  {
    cx: 141.295,
    cy: 168.002,
    id: "G38",
  },
  {
    cx: 10,
    cy: 10,
    id: "G39",
    transform: "matrix(-1 0 0 1 182.188 150.488)",
  },
  {
    cx: 37.188,
    cy: 197.488,
    id: "G40",
  },
  {
    cx: 110.188,
    cy: 205.488,
    id: "G41",
  },
  {
    cx: 10,
    cy: 10,
    id: "G42",
    transform: "matrix(-1 0 0 1 195.188 187.488)",
  },
  {
    cx: 31.188,
    cy: 235.488,
    id: "G43",
  },
  {
    cx: 110.188,
    cy: 236.488,
    id: "G44",
  },
  {
    cx: 10,
    cy: 10,
    id: "G45",
    transform: "matrix(-1 0 0 1 201.188 225.488)",
  },
  {
    cx: 22.188,
    cy: 266.488,
    id: "G46",
  },
  {
    cx: 85.188,
    cy: 261.488,
    id: "G47",
  },
  {
    cx: 10,
    cy: 10,
    id: "G48",
    transform: "matrix(-1 0 0 1 147.188 251.488)",
  },
  {
    cx: 10,
    cy: 10,
    id: "G49",
    transform: "matrix(-1 0 0 1 208.188 256.488)",
  },
  {
    cx: 77.188,
    cy: 325.488,
    id: "G50",
  },
  {
    cx: 10,
    cy: 10,
    id: "G51",
    transform: "matrix(-1 0 0 1 155.188 315.488)",
  },
  {
    cx: 73.188,
    cy: 385.488,
    id: "G52",
  },
  {
    cx: 10,
    cy: 10,
    id: "G53",
    transform: "matrix(-1 0 0 1 159.188 375.488)",
  },
  {
    cx: 73.188,
    cy: 429.488,
    id: "G54",
  },
  {
    cx: 10,
    cy: 10,
    id: "G55",
    transform: "matrix(-1 0 0 1 159.188 419.488)",
  },
  {
    cx: 67.295,
    cy: 490.488,
    id: "G56",
  },
  {
    cx: 10,
    cy: 10,
    id: "G57",
    transform: "matrix(-1 0 0 1 163.295 480.488)",
  },
  {
    cx: 67.295,
    cy: 530.488,
    id: "G58",
  },
  {
    cx: 10,
    cy: 10,
    id: "G59",
    transform: "matrix(-1 0 0 1 163.295 520.488)",
  },
];

export const femaleGeneralFrontData: CustomCircleBaseProps[] = [
  {
    cx: 69.33,
    cy: 94.613,
    id: "G1",
  },
  {
    cx: 126.33,
    cy: 94.613,
    id: "G2",
  },
  {
    cx: 43.33,
    cy: 115.785,
    id: "G3",
  },
  {
    cx: 76.33,
    cy: 127.613,
    id: "G4",
  },
  {
    cx: 116.33,
    cy: 127.613,
    id: "G5",
  },
  {
    cx: 10,
    cy: 10,
    id: "G6",
    transform: "matrix(-1 0 0 1 163.33 105.785)",
  },
  {
    cx: 37.33,
    cy: 178.785,
    id: "G7",
  },
  {
    cx: 80.33,
    cy: 184.613,
    id: "G8",
  },
  {
    cx: 115.33,
    cy: 184.785,
    id: "G9",
  },
  {
    cx: 10,
    cy: 10,
    id: "G10",
    transform: "matrix(-1 0 0 1 169.33 168.785)",
  },
  {
    cx: 28.33,
    cy: 220.785,
    id: "G11",
  },
  {
    cx: 97.33,
    cy: 225.785,
    id: "G12",
  },
  {
    cx: 10,
    cy: 10,
    id: "G13",
    transform: "matrix(-1 0 0 1 178.33 210.785)",
  },
  {
    cx: 22.33,
    cy: 262.785,
    id: "G14",
  },
  {
    cx: 72.33,
    cy: 256.785,
    id: "G15",
  },
  {
    cx: 10,
    cy: 10,
    id: "G16",
    transform: "matrix(-1 0 0 1 134.33 246.785)",
  },
  {
    cx: 10,
    cy: 10,
    id: "G17",
    transform: "matrix(-1 0 0 1 184.33 252.785)",
  },
  {
    cx: 74.33,
    cy: 321.785,
    id: "G18",
  },
  {
    cx: 10,
    cy: 10,
    id: "G19",
    transform: "matrix(-1 0 0 1 132.33 311.785)",
  },
  {
    cx: 77.33,
    cy: 388.785,
    id: "G20",
  },
  {
    cx: 10,
    cy: 10,
    id: "G21",
    transform: "matrix(-1 0 0 1 128.33 378.785)",
  },
  {
    cx: 80.33,
    cy: 425.785,
    id: "G22",
  },
  {
    cx: 10,
    cy: 10,
    id: "G23",
    transform: "matrix(-1 0 0 1 125.33 415.785)",
  },
  {
    cx: 81.437,
    cy: 486.785,
    id: "G24",
  },
  {
    cx: 10,
    cy: 10,
    id: "G25",
    transform: "matrix(-1 0 0 1 124.437 476.785)",
  },
  {
    cx: 81.437,
    cy: 526.785,
    id: "G26",
  },
  {
    cx: 10,
    cy: 10,
    id: "G27",
    transform: "matrix(-1 0 0 1 124.437 516.785)",
  },
];

export const femaleGeneralBackData: CustomCircleBaseProps[] = [
  {
    cx: 78.052,
    cy: 47.205,
    id: "G28",
  },
  {
    cx: 118.052,
    cy: 47.205,
    id: "G29",
  },
  {
    cx: 98.052,
    cy: 75.002,
    id: "G30",
  },
  {
    cx: 73.052,
    cy: 95.002,
    id: "G31",
  },
  {
    cx: 123.052,
    cy: 95.002,
    id: "G32",
  },
  {
    cx: 43.052,
    cy: 110.205,
    id: "G33",
  },
  {
    cx: 98.052,
    cy: 123.002,
    id: "G34",
  },
  {
    cx: 10,
    cy: 10,
    id: "G35",
    transform: "matrix(-1 0 0 1 162.052 100.205)",
  },
  {
    cx: 38.052,
    cy: 157.205,
    id: "G36",
  },
  {
    cx: 78.052,
    cy: 165.002,
    id: "G37",
  },
  {
    cx: 117.052,
    cy: 165.205,
    id: "G38",
  },
  {
    cx: 10,
    cy: 10,
    id: "G39",
    transform: "matrix(-1 0 0 1 170.052 147.205)",
  },
  {
    cx: 34.052,
    cy: 194.205,
    id: "G40",
  },
  {
    cx: 98.052,
    cy: 203.205,
    id: "G41",
  },
  {
    cx: 10,
    cy: 10,
    id: "G42",
    transform: "matrix(-1 0 0 1 171.052 184.205)",
  },
  {
    cx: 30.052,
    cy: 232.205,
    id: "G43",
  },
  {
    cx: 98.052,
    cy: 236.205,
    id: "G44",
  },
  {
    cx: 10,
    cy: 10,
    id: "G45",
    transform: "matrix(-1 0 0 1 176.052 222.205)",
  },
  {
    cx: 20.052,
    cy: 263.205,
    id: "G46",
  },
  {
    cx: 73.052,
    cy: 259.205,
    id: "G47",
  },
  {
    cx: 10,
    cy: 10,
    id: "G48",
    transform: "matrix(-1 0 0 1 135.052 249.205)",
  },
  {
    cx: 10,
    cy: 10,
    id: "G49",
    transform: "matrix(-1 0 0 1 186.052 253.205)",
  },
  {
    cx: 75.052,
    cy: 322.205,
    id: "G50",
  },
  {
    cx: 10,
    cy: 10,
    id: "G51",
    transform: "matrix(-1 0 0 1 133.052 312.205)",
  },
  {
    cx: 76.052,
    cy: 392.205,
    id: "G52",
  },
  {
    cx: 10,
    cy: 10,
    id: "G53",
    transform: "matrix(-1 0 0 1 129.052 382.205)",
  },
  {
    cx: 79.052,
    cy: 426.205,
    id: "G54",
  },
  {
    cx: 10,
    cy: 10,
    id: "G55",
    transform: "matrix(-1 0 0 1 127.052 416.205)",
  },
  {
    cx: 82.16,
    cy: 487.205,
    id: "G56",
  },
  {
    cx: 10,
    cy: 10,
    id: "G57",
    transform: "matrix(-1 0 0 1 124.16 477.205)",
  },
  {
    cx: 82.16,
    cy: 527.205,
    id: "G58",
  },
  {
    cx: 10,
    cy: 10,
    id: "G59",
    transform: "matrix(-1 0 0 1 124.16 517.205)",
  },
];
