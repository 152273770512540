import { TranslationKey } from "@globalTypes/i18next";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { CtxExerciseType } from "@screens/TrainingsAndExercises/exercise.types";
import { ColorValue } from "react-native";

export type UsePlayerOptions = {
  exercises: CtxExerciseType[];
  trainingId?: number;
  navigation?: NativeStackNavigationProp<
    RootStackParamList,
    "TrainingPlayer",
    undefined
  >;
};

export enum PlayerMode {
  NOT_STARTED = "not started",
  EXERCISE = "T00166",
  BREAK_BETWEEN_EXERCISES = "break between exercises",
  BREAK_BETWEEN_SERIES = "break between series",
}

export type DataForBottomSection = {
  exerciseText: TranslationKey;
  serieText?: TranslationKey;
};

type StyleType = {
  color: ColorValue | undefined;
  backgroundColor: ColorValue | undefined;
};

export type TextAndStyleForPlayerSection = {
  text: TranslationKey;
  style: StyleType;
};
