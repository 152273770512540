import { useCallback, useState } from "react";
import { LayoutChangeEvent } from "react-native";

export const useElementHeight = () => {
  const [elementHeight, setElementHeight] = useState<number>(0);

  const onElementLayout = useCallback((event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setElementHeight(height);
  }, []);
  return { onElementLayout, elementHeight };
};
