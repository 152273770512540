import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { format } from "date-fns";
import { FC, PropsWithChildren, useState } from "react";
import { ScrollView, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { Calendar, DateArrayType } from "@components/Calendar/Calendar";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";
import { AvailabilityBottomSheetContent } from "@screens/Calendar/Availability";
import AvailabilityList from "@screens/Calendar/AvailabilityList";

import { useAuth } from "@contexts/AuthContext/auth";
import { useErrors } from "@hooks/useErrors";
import { usePhysiotherapistAvailability } from "@hooks/availability/useFutureAvailability";
import { CalendarStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { deleteAllAvailabilityHoursFromSelectedDay } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { spacing32 } from "@styles/spacing";
import { getDatesWithAvailability, getDays } from "@utils/date";
import { queryKeysPhysiotherapist } from "../Appointments/queryKeysAppointments";
import AddHoursBottomSheetContent from "./Availability/AddHoursBottomSheetContent";
import { DeleteAllHoursBottomSheetContent } from "./Availability/DeleteAllHoursBottomSheetContent";
import { showSnackbar } from "@utils/snackbarHelper";
import { useTranslation } from "react-i18next";

export const AvailabilityHours: FC<
  PropsWithChildren<
    NativeStackScreenProps<CalendarStackParamList, "AvailabilityHours">
  >
> = ({
  route: {
    params: { initialDate },
  },
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<DateArrayType>({
    date: initialDate,
  });
  const [firstDayOfCurrentWeek, setFirstDayOfCurrentWeek] =
    useState<Date>(initialDate);
  const { date } = selectedDate;
  const {
    user: { id },
  } = useAuth();
  const { setErrorsFromResponse } = useErrors();

  const { container, gapLarge } = globalStyles;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { startOfWeekDate: dateFrom, endOfWeekDate: dateTo } = getDays(
    firstDayOfCurrentWeek,
  );

  const { data, isError, refetch } = usePhysiotherapistAvailability(
    queryKeysPhysiotherapist.availabilityPerWeek({
      physiotherapistId: id,
      dateFrom,
      dateTo,
    }),
    { dateFrom, dateTo, physiotherapistId: id },
    !!(dateFrom && dateTo),
  );
  const { isLoading: isDeleteAllHoursLoading, mutate } = useMutation({
    mutationFn: async () =>
      await deleteAllAvailabilityHoursFromSelectedDay(
        format(date, "yyyy-MM-dd"),
      ),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async ({ data: { detail: alertMessage } }: AxiosResponse) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeysPhysiotherapist.availabilityPerWeek({
            physiotherapistId: id,
            dateFrom,
            dateTo,
          }),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeysPhysiotherapist.availabilityGroupsForSelectedDay({
            physioId: id,
            date,
          }),
        }),
      ]);
      setDeleteModalVisible(false);
      alertMessage
        ? alert(alertMessage)
        : showSnackbar({
            message: t("T01519"),
          });
    },
  });

  if (isError) return <FetchError action={refetch} />;

  const datesWithEvents = getDatesWithAvailability(data);

  const shouldDisableDeleteAllHoursButton = !data?.availableAppointments?.find(
    e => e.date === format(date, "yyyy-MM-dd"),
  );

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "left"]}>
      <ScrollView
        style={container}
        contentContainerStyle={[gapLarge, { paddingBottom: spacing32 }]}>
        <View>
          <Calendar
            type="week"
            initialDates={[selectedDate]}
            datesWithEvents={datesWithEvents}
            onSelectDate={setSelectedDate}
            blockPast
            yearsOption="future"
            onWeekChange={setFirstDayOfCurrentWeek}
          />
        </View>
        <AvailabilityList date={date} />

        <PrimaryButton
          label="T00486"
          onPress={() => setDeleteModalVisible(true)}
          mode="text"
          disabled={!!shouldDisableDeleteAllHoursButton}
        />
        <PrimaryButton
          label="T00480"
          onPress={() => setModalVisible(true)}
          mode="outlined"
        />
        <PrimaryButton
          label="T00902"
          onPress={() => setAddModalVisible(true)}
        />
        <BottomModalContainer
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          scrollableContent>
          <AvailabilityBottomSheetContent
            initialDate={selectedDate}
            setParentModalVisible={setModalVisible}
            userId={id}
            scrollableContent
          />
        </BottomModalContainer>
        <BottomModalContainer
          modalVisible={deleteModalVisible}
          setModalVisible={setDeleteModalVisible}>
          <DeleteAllHoursBottomSheetContent
            onPress={() => mutate()}
            buttonDisabled={shouldDisableDeleteAllHoursButton}
            selectedDate={date}
            loading={isDeleteAllHoursLoading}
          />
        </BottomModalContainer>
        <BottomModalContainer
          modalVisible={addModalVisible}
          setModalVisible={setAddModalVisible}>
          <AddHoursBottomSheetContent
            isVisible={addModalVisible}
            setVisible={setAddModalVisible}
            initialDate={selectedDate}
          />
        </BottomModalContainer>
      </ScrollView>
    </SafeAreaView>
  );
};
