import { UserCard } from "@components/cards";
import ExtendedPhysiotherapistCard from "@components/cards/PhysiotherapistCard/ExtendedPhysiotherapistCard";
import { TransKey } from "@globalTypes/i18next";
import { useUserDetails } from "@hooks/user/useUserDetails";
import {
  PatientProfileStackParamsList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { globalStyles } from "@styles/global";
import { FC } from "react";
import { View } from "react-native";
import { Divider } from "react-native-paper";
import AppointmentTitleSection from "./AppointmentTitleSection";

type Props = {
  patient?: number | null;
  physiotherapist: number;
  appointmentName?: string;
};

export const AppointmentDetailsHeader: FC<Props> = ({
  physiotherapist,
  patient,
  appointmentName,
}) => {
  const { gapLarge } = globalStyles;

  const { isPhysiotherapist } = useUserDetails();
  const { navigate } =
    useNavigation<
      NavigationProp<
        PatientProfileStackParamsList &
          RootStackParamList &
          "AppointmentConfirmation"
      >
    >();

  if (appointmentName?.length)
    return (
      <>
        <AppointmentTitleSection
          title={appointmentName as TransKey}
          variant="titleLarge"
        />
        <Divider bold />
      </>
    );

  return (
    <>
      {!isPhysiotherapist && (
        <View style={gapLarge}>
          <AppointmentTitleSection title="T00060" />
          <Divider bold />
          <ExtendedPhysiotherapistCard
            initialData={{ id: physiotherapist }}
            onPress={() =>
              navigate("PhysiotherapistProfile", {
                id: physiotherapist,
              })
            }
          />
        </View>
      )}
      {isPhysiotherapist && (
        <View style={gapLarge}>
          <AppointmentTitleSection title="T00275" />
          <Divider bold />
          <UserCard
            initialData={{ id: patient }}
            onPress={() =>
              navigate("PatientProfileStack", {
                screen: "PatientProfile",
                params: { id: patient },
              })
            }
            mode="outlined"
          />
        </View>
      )}
    </>
  );
};
