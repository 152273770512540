import ReportWrapper from "@components/Report/ReportWrapper";
import { ReportProps } from "@components/Report/report.types";
import { DetailsItem } from "@screens/Appointments/AppointmentDetails/DetailsItem";
import { FC } from "react";

type DescriptionSectionType = ReportProps & {
  detailsItems: {
    title?: string;
    subtitle?: string;
    withReport?: boolean;
    extras?: () => JSX.Element;
  }[];
};

const DescriptionSection: FC<DescriptionSectionType> = ({
  detailsItems,
  ...props
}) => {
  return (
    <>
      {detailsItems.map(({ title, extras, subtitle, withReport }, index) => {
        return !withReport ? (
          <DetailsItem
            key={`details-item-${title}-${subtitle}-${index}`}
            title={title}
            subtitle={subtitle}
            extras={extras}
          />
        ) : (
          <ReportWrapper
            key={`details-item-${title}-${subtitle}-${index}`}
            {...props}>
            <DetailsItem title={title} subtitle={subtitle} extras={extras} />
          </ReportWrapper>
        );
      })}
    </>
  );
};

export default DescriptionSection;
