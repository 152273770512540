import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Switch, Text } from "react-native-paper";

import { FetchError } from "@components/errors";
import { useErrors } from "@hooks/useErrors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { queryKeysSettings } from "@screens/Profiles/MyProfile/queryKeysSettings";
import {
  addUserToBlacklist,
  getPhysiotherapistSettings,
  removeUserFromBlacklist,
} from "@services/ApiService/users";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { queryKeysMessagesBlacklist } from "../queryKeysMessageCenter";
import { useUserBlacklistCheck } from "@hooks/queryHooks/useUserBlacklistCheck";

type Props = {
  disabledUserId: number;
};

export const DisableMessages: FC<Props> = ({ disabledUserId }) => {
  const { blockMessagesContainer, switchContainer } = styles;
  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const queryClient = useQueryClient();
  const isUserBlacklisted = useUserBlacklistCheck({
    userId: disabledUserId,
  });
  const [value, setValue] = useState<boolean>(!isUserBlacklisted);
  const { isPhysiotherapist } = useUserDetails();

  const { mutate: handleBlock, isLoading: isBlocking } = useMutation({
    mutationFn: async () => {
      setValue(prev => !prev);
      await (!isUserBlacklisted
        ? addUserToBlacklist({ blocked: disabledUserId })
        : removeUserFromBlacklist({ blocked: disabledUserId }));
    },
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeysMessagesBlacklist.details(disabledUserId),
      });
    },
  });

  const {
    data: settingsData,
    isLoading,
    isError,
    refetch: refetchSettings,
  } = useQuery({
    queryFn: getPhysiotherapistSettings,
    queryKey: queryKeysSettings.physiotherapistSettings(),
    enabled: isPhysiotherapist,
  });

  const switchDisabled = useMemo(
    () =>
      (isPhysiotherapist && isLoading) ||
      (isPhysiotherapist && !settingsData?.[0].messagesEnabled) ||
      isBlocking ||
      isUserBlacklisted === null,
    [isBlocking, isLoading, isPhysiotherapist, isUserBlacklisted, settingsData],
  );

  if (isError)
    return <FetchError action={refetchSettings} coverScreen={false} />;

  return (
    <View style={blockMessagesContainer}>
      <Text variant="titleSmall" style={{ flex: 3 }}>
        {t(isPhysiotherapist ? "T00491" : "T01449")}
      </Text>
      <View style={switchContainer}>
        <Switch
          value={value}
          disabled={switchDisabled}
          onValueChange={() => handleBlock()}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  blockMessagesContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  switchContainer: {
    flex: 2,
    alignItems: "flex-end",
  },
});
