import { useAuth } from "@contexts/AuthContext/auth";
import { AccountType } from "@contexts/AuthContext/user.types";
import ZoomVideo, {
  ChatMessage,
  ConnectionState,
  ReconnectReason,
} from "@zoom/videosdk";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  Action,
  ChatClient,
  MediaState,
  MediaStream,
  Participant,
  Payload,
  VideoQuality,
  ZoomWebUser,
} from "../../utils/types.web";
import { useZoomUi } from "./use-zoom-ui";
import { CallScreenProps } from "zoom/utils/types";
import { AppointmentSessionContext } from "@contexts/AppointmentSessionContext/appointmentSession.context";
import { ActivityIndicator } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { globalStyles } from "@styles/global";
import { MediaContext } from "@contexts/AppointmentSessionContext/types";
import { palettes } from "@styles/colors";
import { useParticipantsChange } from "../../hooks/useParticitpantsChange";
import { SELF_VIDEO_ID } from "../../utils/helpers.web";
import { useCanvasDimension } from "../../hooks/useCanvasDimensions";
import { usePrevious } from "../../hooks/usePrevious";
import { isEqual } from "lodash";
import { ConversationMessage } from "@screens/Appointments/appointment.types";
import { useAppointmentData } from "@hooks/index";

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: unknown | undefined;
    mediaStream: unknown | undefined;
    crossOriginIsolated: boolean;
    ltClient: unknown | undefined;
    logClient: unknown | undefined;
  }
}

const mediaShape: MediaState = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
};

const isIPad = () => {
  return /MacIntel/i.test(navigator.platform) && navigator?.maxTouchPoints > 2;
};

const isIOSMobile = () => {
  const { userAgent } = navigator;
  const isIOS = /iPad|iPhone|iPod/i.test(userAgent);
  return isIOS || isIPad();
};

const mediaReducer = (state: MediaState, action: Action) => {
  switch (action.type) {
    case "audio-encode": {
      return {
        ...state,
        audio: {
          ...state.audio,
          encode: !!action.payload,
        },
      };
    }
    case "audio-decode": {
      return {
        ...state,
        audio: {
          ...state.audio,
          decode: !!action.payload,
        },
      };
    }
    case "video-encode": {
      return {
        ...state,
        video: {
          ...state.video,
          encode: !!action.payload,
        },
      };
    }
    case "video-decode": {
      return {
        ...state,
        video: {
          ...state.video,
          decode: !!action.payload,
        },
      };
    }
    case "share-encode": {
      return {
        ...state,
        share: {
          ...state.share,
          encode: !!action.payload,
        },
      };
    }
    case "share-decode": {
      return {
        ...state,
        share: {
          ...state.share,
          decode: !!action.payload,
        },
      };
    }
    case "reset-media": {
      return { ...mediaShape };
    }
    default:
      return state;
  }
};

export function useZoomCall(props: CallScreenProps) {
  const { sessionName: topic, userName, token } = props;
  const {
    user: { firstName, accountType, id },
  } = useAuth();
  const [loading, setIsLoading] = useState(true);
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("closed");
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [users, setUsersInSession] = useState<ZoomWebUser[]>([]);
  const [isMuted, setIsMuted] = useState(true);
  const [fullScreenUser, setFullScreenUser] = useState<ZoomWebUser>();
  const { data: appointment } = useAppointmentData(+topic);
  const { zmClient, setMediaContext } = useContext(AppointmentSessionContext);
  const { t } = useTranslation();

  const [activeVideo, setActiveVideo] = useState<number>(
    mediaStream?.getActiveVideoId() ?? 0,
  );
  const videoRef = useRef<HTMLCanvasElement | null>(null);
  const selfVideoCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const selfCanvasDimension = useCanvasDimension(
    mediaStream,
    selfVideoCanvasRef,
  );
  const previousCanvasDimension = usePrevious(canvasDimension);
  const isCurrentUserStartedVideo = zmClient.getCurrentUserInfo()?.bVideoOn;
  const previousActiveUser = useRef<Participant>();
  const onActiveVideoChange = useCallback(({ userId }: { userId: number }) => {
    setActiveVideo(userId);
  }, []);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [chatMessages, setChatMessages] = useState<ConversationMessage[]>([]);
  const [chat, setChat] = useState<ChatClient | null>(null);
  const [newMessagesCount, setNewMessagesCount] = useState(0);

  const activeUser = useMemo(
    () =>
      users.find(
        user =>
          user.userId === activeVideo &&
          !(user.userId === zmClient.getSessionInfo().userId && user.bVideoOn),
      ),
    [users, activeVideo, zmClient],
  );

  useEffect(() => {
    if (mediaStream && videoRef.current) {
      if (activeUser?.bVideoOn !== previousActiveUser.current?.bVideoOn) {
        if (
          activeUser?.bVideoOn &&
          !(activeUser.userId === zmClient.getSessionInfo().userId)
        ) {
          void mediaStream.renderVideo(
            videoRef.current,
            activeUser.userId,
            canvasDimension.width,
            canvasDimension.height,
            0,
            0,
            VideoQuality.Video_360P,
          );
        } else {
          if (previousActiveUser.current?.bVideoOn) {
            void mediaStream.stopRenderVideo(
              videoRef.current,
              previousActiveUser.current?.userId,
            );
          }
        }
      }
      if (activeUser?.bVideoOn && previousActiveUser.current?.bVideoOn) {
        if (activeUser.userId !== previousActiveUser.current.userId) {
          void mediaStream.stopRenderVideo(
            videoRef.current,
            previousActiveUser.current?.userId,
          );
          void mediaStream.renderVideo(
            videoRef.current,
            activeUser.userId,
            canvasDimension.width,
            canvasDimension.height,
            0,
            0,
            VideoQuality.Video_360P,
          );
        } else {
          if (!isEqual(canvasDimension, previousCanvasDimension)) {
            void mediaStream.adjustRenderedVideoPosition(
              videoRef.current,
              activeUser.userId,
              canvasDimension.width,
              canvasDimension.height,
              0,
              0,
            );
          }
        }
      }
      previousActiveUser.current = activeUser;
    }
  }, [
    mediaStream,
    activeUser,
    canvasDimension,
    previousCanvasDimension,
    zmClient,
    isCurrentUserStartedVideo,
  ]);
  useEffect(() => {
    if (
      selfVideoCanvasRef.current &&
      isCurrentUserStartedVideo &&
      mediaStream
    ) {
      void mediaStream.adjustRenderedVideoPosition(
        selfVideoCanvasRef.current,
        zmClient.getSessionInfo().userId,
        selfCanvasDimension.width,
        selfCanvasDimension.height,
        0,
        0,
      );
    }
  }, [selfCanvasDimension, mediaStream, zmClient, isCurrentUserStartedVideo]);

  const onPressVideo = useCallback(async () => {
    if (!mediaStream) return;
    if (isVideoOn) {
      await mediaStream.stopVideo();
      setIsVideoOn(false);
    } else {
      const startVideoOptions = {
        hd: true,
        fullHd: true,
        ptz: mediaStream.isBrowserSupportPTZ(),
        originalRatio: true,
      };
      await mediaStream.startVideo(startVideoOptions);
      setIsVideoOn(true);
      if (!mediaStream.isSupportMultipleVideos()) {
        const canvasElement = document.querySelector(
          `#${SELF_VIDEO_ID}`,
        ) as unknown as HTMLVideoElement;
        await mediaStream.renderVideo(
          canvasElement,
          zmClient.getSessionInfo().userId,
          canvasElement.width,
          canvasElement.height,
          0,
          0,
          3,
        );
      }
      const temporaryException = isIOSMobile() && window.crossOriginIsolated; // add ios mobile exception for test backward compatible.
      if (
        mediaStream.isRenderSelfViewWithVideoElement() &&
        !temporaryException
      ) {
        const videoElement = document.querySelector(
          `#${SELF_VIDEO_ID}`,
        ) as unknown as HTMLVideoElement;
        if (videoElement) {
          await mediaStream.startVideo({ videoElement });
        }
      } else {
        const startVideoOptions = {
          hd: true,
          fullHd: true,
          ptz: mediaStream.isBrowserSupportPTZ(),
        };
        await mediaStream.startVideo(startVideoOptions);
        if (!mediaStream.isSupportMultipleVideos()) {
          const canvasElement = document.querySelector(
            `#${SELF_VIDEO_ID}`,
          ) as unknown as HTMLVideoElement;
          await mediaStream.renderVideo(
            canvasElement,
            zmClient.getSessionInfo().userId,
            canvasElement.width,
            canvasElement.height,
            0,
            0,
            3,
          );
        }
      }

      setIsVideoOn(true);
    }
  }, [isVideoOn, mediaStream, zmClient]);

  useEffect(() => {
    setMediaContext({ ...mediaState, mediaStream } as MediaContext);
  }, [mediaState, mediaStream, setMediaContext]);

  const getConversationUsers = useCallback(
    (mySelf: Participant, remoteUsers: Participant[]) => {
      const amIPhysio = accountType === AccountType.THERAPIST;
      const resultUsers: ZoomWebUser[] = [
        {
          ...(mySelf as ZoomWebUser),
          apiUserId: id,
          isUserPhysio: amIPhysio,
        },
      ];

      if (remoteUsers[0]) {
        resultUsers.push({
          ...(remoteUsers[0] as ZoomWebUser),
          apiUserId: amIPhysio
            ? appointment?.patient
            : appointment?.physiotherapist,
          isUserPhysio: !amIPhysio,
        });
      }

      return resultUsers;
    },
    [accountType, appointment?.patient, appointment?.physiotherapist, id],
  );

  const sendChatMessage = async (msg: string) => {
    if (chat) {
      if (users.length === 1) await chat.sendToAll(msg);
      if (users.length === 2) {
        const otherUserId = users.find(u => u.apiUserId !== id).userId;
        await chat.send(msg, otherUserId);
      }
    }
  };

  const onChatMessage = useCallback(
    ({ sender, receiver, timestamp, message }: ChatMessage) => {
      const senderId = users.find(u => u.userId === sender.userId)?.apiUserId;
      const receiverId = users.find(
        u => u.userId === receiver.userId,
      )?.apiUserId;
      const msg: ConversationMessage = {
        id: timestamp,
        sender: senderId,
        recipient: receiverId,
        createdAt: new Date(timestamp).toISOString(),
        message,
        isSystemMessage: false,
        readAt: null,
      };
      setChatMessages(prev => [...prev, msg]);
      setNewMessagesCount(v => v + 1);
    },
    [users],
  );

  const clearHasNewMessage = () => setNewMessagesCount(0);

  const onPressAudio = useCallback(async () => {
    isMuted ? await mediaStream?.startAudio() : await mediaStream?.stopAudio();
    setIsMuted(prev => !prev);
  }, [isMuted, mediaStream]);

  useEffect(() => {
    const init = async () => {
      await zmClient.init("en-US", `${window.location.origin}/lib`, {
        webEndpoint: "zoom.us",
        stayAwake: true,
        patchJsMedia: true,
        leaveOnPageUnload: true,
      });

      try {
        await zmClient
          .join(`Appointment ${topic}`, token, userName || firstName)
          .catch(e => {
            console.log(e);
          });
        const stream = zmClient.getMediaStream();
        const chat = zmClient.getChatClient();
        setNewMessagesCount(0);
        setChat(chat);
        setMediaStream(stream);
      } catch (e) {
        console.log("zoom init error", e);
      } finally {
        setIsLoading(false);
      }
    };
    void init();
    return () => {
      ZoomVideo.destroyClient();
      setNewMessagesCount(0);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConnectionChange = useCallback(
    (payload: Payload) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus("connecting");
        const { reason, subsessionName } = payload;
        if (reason === ReconnectReason.Failover) {
          console.log("Session Disconnected,Try to reconnect");
        } else if (
          reason === ReconnectReason.JoinSubsession ||
          reason === ReconnectReason.MoveToSubsession
        ) {
          console.log(`Joining ${subsessionName}...`);
        } else if (reason === ReconnectReason.BackToMainSession) {
          console.log("Returning to Main Session...");
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus("connected");
        if (isFailover) {
          setIsLoading(false);
        }
        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();
      } else if (payload.state === ConnectionState.Closed) {
        setStatus("closed");
        dispatch({ type: "reset-media" });
        if (payload.reason === "ended by host") {
          console.log("meeting ended by host");
        }
      }
    },
    [isFailover, zmClient],
  );

  const onMediaSDKChange = useCallback((payload: Payload) => {
    const { action, type, result } = payload;
    dispatch({
      type: `${type}-${action}`,
      payload: result === "success",
    } as Action);
  }, []);

  const onPressLeave = useCallback(
    async (force = false, onCallback?: () => void) => {
      const leave = async () => {
        if (status === "connected") {
          await zmClient.leave();
          console.warn("You have left the session.");
        }
        onCallback && onCallback();
      };
      if (force) {
        await leave();
        return;
      }
      if (confirm(t("T00896"))) await leave();
    },
    [status, t, zmClient],
  );

  useParticipantsChange(zmClient, participants => {
    const mySelf = zmClient.getCurrentUserInfo();
    const remoteUsers = participants.filter(
      participant => participant.userId !== mySelf.userId,
    );
    const users = getConversationUsers(mySelf, remoteUsers);
    setFullScreenUser(remoteUsers.length === 0 ? users[0] : users[1]);
    setUsersInSession(users);
  });

  useEffect(() => {
    zmClient.on("connection-change", onConnectionChange);
    zmClient.on("media-sdk-change", onMediaSDKChange);
    zmClient.on("video-active-change", onActiveVideoChange);
    zmClient.on("chat-on-message", onChatMessage);

    return () => {
      zmClient.off("connection-change", onConnectionChange);
      zmClient.off("media-sdk-change", onMediaSDKChange);
      zmClient.off("video-active-change", onActiveVideoChange);
      zmClient.off("chat-on-message", onChatMessage);
    };
  }, [
    zmClient,
    onConnectionChange,
    onMediaSDKChange,
    onActiveVideoChange,
    onChatMessage,
  ]);

  const { context, renderInterlocutorCameraView, renderMyCameraView } =
    useZoomUi({
      users,
      isInSession: status === "connected",
      isMuted,
      isVideoOn,
      interlocutorMuted: false,
      fullScreenUser,
      onPressAudio,
      onPressVideo,
      onPressLeave,
      selfVideoCanvasRef,
      videoRef,
    });

  return {
    context: loading ? (
      <ActivityIndicator
        style={[
          globalStyles.loading,
          { backgroundColor: palettes.neutralVariant[30] },
        ]}
      />
    ) : (
      context
    ),
    sendChatMessage,
    chatMessages,
    onPressLeave,
    isMuted,
    onPressAudio,
    isVideoOn,
    onPressVideo,
    joinSession: () => {},
    newMessagesCount,
    clearHasNewMessage,
    renderInterlocutorCameraView,
    renderMyCameraView,
    participants: users,
  };
}
