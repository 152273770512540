import { Pressable, StyleSheet, View } from "react-native";
import VideoSectionTopBar from "./VideoSectionTopBar";
import VideoBottomSection from "./VideoBottomSection";
import {
  AVPlaybackStatus,
  AVPlaybackStatusSuccess,
  ResizeMode,
  Video,
} from "expo-av";
import {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { StatusBar } from "expo-status-bar";
import { isIOS } from "@utils/constants";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { palettes } from "@styles/colors";
import { SvgChangeExerciseIcon } from "@assets/svg";
import { PrimaryButton } from "@components/buttons";
import { spacing12 } from "@styles/spacing";
import { CtxExerciseType } from "@screens/TrainingsAndExercises/exercise.types";
import { PlayerMode } from "../player.types";
import { ActivityIndicator } from "react-native-paper";

type VideoSectionProps = {
  videoUrl: string;
  topAndBottomSectionsVisible: boolean;
  playerSectionHeight: number;
  exercises: CtxExerciseType[];
  playerMode: PlayerMode;
  currentExercise: CtxExerciseType;
  currentExerciseIndex: number;
  currentSeriesIndex: number;
  shouldBeMuted?: boolean;
  onXPress: () => void;
  onExercisesListPress: () => void;
  onInstructionPress: () => void;
  onVideoPress: () => void;
  handleChangeCurrentExercise: () => void;
};

const VideoSection: FC<PropsWithChildren<VideoSectionProps>> = ({
  videoUrl,
  topAndBottomSectionsVisible,
  playerSectionHeight,
  exercises,
  playerMode,
  currentExercise,
  currentExerciseIndex,
  currentSeriesIndex,
  shouldBeMuted,
  onXPress,
  onExercisesListPress,
  onInstructionPress,
  onVideoPress,
  handleChangeCurrentExercise,
}) => {
  const video = useRef<Video>(null);
  const { top } = useSafeAreaInsets();
  const { width, height } = useWindowDimensions();
  const {
    container,
    statusBarStyleForIOS,
    changeButtonContainer,
    videoStyle,
    loader,
  } = styles;
  const [status, setStatus] = useState<AVPlaybackStatus>(null);
  const opacity = useMemo(
    () => (playerMode === PlayerMode.BREAK_BETWEEN_SERIES ? 0.4 : 1),
    [playerMode],
  );

  const shouldDisplayChangeButton = useMemo(
    () =>
      currentExercise.alternativeExercise &&
      (playerMode === PlayerMode.BREAK_BETWEEN_EXERCISES ||
        playerMode === PlayerMode.NOT_STARTED),
    [currentExercise.alternativeExercise, playerMode],
  );

  const shouldDisplayLoader = useMemo(
    () =>
      !(status as AVPlaybackStatusSuccess)?.isPlaying ||
      !(status as AVPlaybackStatusSuccess)?.isLoaded,
    [status],
  );

  useEffect(() => {
    void video?.current?.setIsMutedAsync(shouldBeMuted);
  }, [shouldBeMuted]);

  return (
    <View style={container}>
      {isIOS ? (
        <View
          style={
            topAndBottomSectionsVisible && {
              height: top,
              ...statusBarStyleForIOS,
            }
          }
        />
      ) : (
        <StatusBar
          translucent
          backgroundColor={
            topAndBottomSectionsVisible ? palettes.primary[0] : null
          }
        />
      )}
      {topAndBottomSectionsVisible && (
        <VideoSectionTopBar
          onXPress={onXPress}
          onExercisesListPress={onExercisesListPress}
          onInstructionPress={onInstructionPress}
        />
      )}
      {shouldDisplayChangeButton && (
        <View style={changeButtonContainer}>
          <PrimaryButton
            label="T01259"
            onPress={handleChangeCurrentExercise}
            icon={() => <SvgChangeExerciseIcon />}
          />
        </View>
      )}
      <Pressable onPress={onVideoPress} style={{ flex: 1 }}>
        <Video
          ref={video}
          style={[
            videoStyle,
            { width, height: height - playerSectionHeight, opacity },
          ]}
          source={{
            uri: videoUrl,
          }}
          useNativeControls={false}
          resizeMode={ResizeMode.COVER}
          isLooping
          shouldPlay
          onPlaybackStatusUpdate={status => setStatus(() => status)}
        />
      </Pressable>
      <VideoBottomSection
        numberOfExercises={exercises?.length}
        extraDataVisible={topAndBottomSectionsVisible}
        playerMode={playerMode}
        onSectionPress={onVideoPress}
        currentExerciseIndex={currentExerciseIndex}
        currentExercise={currentExercise}
        currentSeriesIndex={currentSeriesIndex}
      />
      {shouldDisplayLoader && <ActivityIndicator style={loader} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 4,
    justifyContent: "center",
  },
  videoStyle: {
    position: "absolute",
  },
  statusBarStyleForIOS: {
    top: 0,
    backgroundColor: palettes.primary[100],
    width: "100%",
    position: "absolute",
    zIndex: 2,
  },
  changeButtonContainer: {
    position: "absolute",
    zIndex: 2,
    marginLeft: spacing12,
    top: "15%",
  },
  loader: {
    position: "absolute",
    alignSelf: "center",
  },
});

export default VideoSection;
