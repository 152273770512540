import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "react-native-paper";
import { spacing8 } from "@styles/spacing";
import { theme } from "@styles/theme";
import { TransKey } from "../../types/i18next";

interface GeneralErrorProps {
  error: TransKey | string;
}

const GeneralError: FC<GeneralErrorProps> = ({ error }) => {
  const { t } = useTranslation();
  const errorMessage = t(error as TransKey).toString();
  return (
    <Text
      style={{
        color: theme.colors.error,
        marginBottom: error && spacing8,
      }}>
      {errorMessage}
    </Text>
  );
};

export default GeneralError;
