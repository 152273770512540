import BartChartMonths from "@components/Charts/BarChartMonths";
import { OccupancyTile } from "@components/Tile";
import { StatsTileType } from "@components/Tile/EarningsTile";
import { FetchError } from "@components/errors";
import {
  getOccupancyStatsCharts,
  getYearOccupancyStats,
} from "@services/ApiService/charts";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";
import { Divider, Text } from "react-native-paper";
import {
  queryKeysOccupancyStatsChart,
  queryKeysYearlyOccupancyStats,
} from "./queryKeysRehabilitatorStatistics";
import {
  ChartHeader,
  GroupedStackedBarChart,
  StackedBarChart,
} from "@components/Charts";
import { completeMonthsWithMissingDays } from "@components/Charts/chartUtils";

const AppointmentsOccupancy: FC = () => {
  const [monthOccupancyDate, setMonthOccupancyDate] = useState(new Date());
  const { t } = useTranslation();

  const { scrollContainer, gapLarge } = globalStyles;

  const {
    data: occupancyStatsChart,
    isLoading: occupancyStatsChartLoading,
    isError: occupancyStatsChartError,
    refetch: refetchOccupancyStatsChart,
  } = useQuery({
    queryFn: async () =>
      await getOccupancyStatsCharts({
        month: monthOccupancyDate.getMonth() + 1,
        year: monthOccupancyDate.getFullYear(),
      }),
    queryKey: queryKeysOccupancyStatsChart.list(
      monthOccupancyDate.getMonth() + 1,
      monthOccupancyDate.getFullYear(),
    ),
  });

  const {
    data: yearlyOccupancyStats,
    isLoading: yearlyOccupancyStatsLoading,
    isError: yearlyOccupancyStatsError,
    refetch: refetchYearlyOccupancyStats,
  } = useQuery({
    queryFn: getYearOccupancyStats,
    queryKey: queryKeysYearlyOccupancyStats.list(),
  });

  const isAnyError = useMemo(
    () => yearlyOccupancyStatsError || occupancyStatsChartError,
    [yearlyOccupancyStatsError, occupancyStatsChartError],
  );
  const refetchEverything = async () =>
    await Promise.all([refetchYearlyOccupancyStats()]);

  const renderChartHeader = useCallback(
    () => <ChartHeader data={yearlyOccupancyStats} />,
    [yearlyOccupancyStats],
  );

  if (isAnyError) return <FetchError action={refetchEverything} />;

  return (
    <ScrollView contentContainerStyle={[scrollContainer, gapLarge]}>
      <OccupancyTile type={StatsTileType.MONTHLY} />
      <OccupancyTile type={StatsTileType.TOTAL} />
      <Divider bold />
      <Text variant="titleMedium">{`${t("T00693")}:`}</Text>
      <BartChartMonths
        chartTitle="T00691"
        chartDescription="T00692"
        currentDate={monthOccupancyDate}
        setCurrentDate={setMonthOccupancyDate}
        isLoading={occupancyStatsChartLoading}
        refetchData={refetchOccupancyStatsChart}
        emptyData={!occupancyStatsChart?.length}
        allowOneMore
        renderChart={() => (
          <StackedBarChart
            data={completeMonthsWithMissingDays(
              occupancyStatsChart,
              monthOccupancyDate,
            )}
            currentDate={monthOccupancyDate}
          />
        )}
      />
      <BartChartMonths
        chartTitle="T00694"
        chartDescription="T00695"
        isLoading={yearlyOccupancyStatsLoading}
        refetchData={refetchYearlyOccupancyStats}
        emptyData={yearlyOccupancyStats?.every(
          e => e && e[0] === 0 && e[1] === 0,
        )}
        allowOneMore
        renderChartHeader={renderChartHeader}
        renderChart={() => (
          <GroupedStackedBarChart data={yearlyOccupancyStats} />
        )}
      />
    </ScrollView>
  );
};

export default AppointmentsOccupancy;
