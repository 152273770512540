import PhysioProfileAvailability from "@components/PhysioAvailability/PhysioProfileAvailability";
import { AppointmentSlots } from "@screens/Calendar/availability.types";
import { globalStyles } from "@styles/global";
import { Dispatch, FC, SetStateAction } from "react";
import { View } from "react-native";

type Props = {
  id: number;
  navigate: (serviceType: AppointmentSlots) => void;
  setSelectedServiceType: Dispatch<SetStateAction<AppointmentSlots>>;
  selectedServiceType: AppointmentSlots;
};

export const TabHeader: FC<Props> = ({
  id,
  navigate,
  selectedServiceType,
  setSelectedServiceType,
}) => {
  const { gapLarge, scrollContainer } = globalStyles;

  return (
    <View pointerEvents="box-none" style={[scrollContainer, gapLarge]}>
      <PhysioProfileAvailability
        onHourPress={navigate}
        id={id}
        setSelectedServiceType={setSelectedServiceType}
        selectedServiceType={selectedServiceType}
      />
    </View>
  );
};
