import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Card, IconButton } from "react-native-paper";
import { SvgError } from "@assets/svg";
import { globalStyles } from "@styles/global";
import { InfoTileType } from "./types/InfoTile.types";
import { spacing20, spacing8 } from "@styles/spacing";
import { isWeb } from "@utils/constants";
import {
  getIconColorByStatus,
  getTileStyleByStatus,
} from "./utils/InfoTile.utils";

const InfoTile: FC<Partial<InfoTileType>> = ({
  content,
  status,
  elevation,
  hasBackground = true,
  style,
  ...props
}) => {
  const tileStyle = getTileStyleByStatus(status);
  const iconFillColor = getIconColorByStatus(status);

  return (
    <Card
      {...props}
      mode="outlined"
      elevation={elevation as never}
      style={[
        tileStyle,
        !hasBackground && { backgroundColor: "transparent" },
        style,
      ]}>
      <Card.Content style={styles.container}>
        <View style={{ flex: 1 }}>{content}</View>
        <View style={{ flex: 0, marginRight: isWeb ? spacing8 : 0 }}>
          {status === "success" ? (
            <IconButton
              icon="check-circle-outline"
              style={styles.icon}
              size={spacing20}
              iconColor={iconFillColor}
            />
          ) : (
            <SvgError fill={iconFillColor} />
          )}
        </View>
      </Card.Content>
    </Card>
  );
};

export default InfoTile;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    ...globalStyles.gapMedium,
    justifyContent: "space-between",
  },
  icon: {
    margin: 0,
    padding: 0,
    width: spacing20,
    height: spacing20,
  },
});
