import { FC, PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "@navigators/navigation.types";
import { Logo } from "@components/Logo";
import { useTranslation } from "react-i18next";
import { Control, useForm } from "react-hook-form";
import PrivacyAndTermsAgreement from "@components/Checkboxes/PrivacyAndTermsAgreement";
import { PrimaryButton } from "@components/buttons";
import { useAuth } from "@contexts/AuthContext/auth";
import { globalStyles } from "@styles/global";
import { boolean, object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { acceptTerms } from "@api/common";
import { useErrors } from "@hooks/useErrors";
import { AxiosError } from "axios";
import { spacing8 } from "@styles/spacing";
import { SafeAreaView } from "react-native-safe-area-context";

type AcceptTermsProps = PropsWithChildren<
  NativeStackScreenProps<RootStackParamList, "AcceptTerms">
>;

const AcceptTerms: FC<AcceptTermsProps> = () => {
  const { t } = useTranslation();
  const checkboxRules = boolean()
    .oneOf([true], t("T00014"))
    .required(t("T00014"));
  const schema = object().shape({
    privacyCheckbox: checkboxRules,
  });
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    signOut,
    user: { id },
    updateUser,
  } = useAuth();
  const { container, gapLarge } = globalStyles;
  const { setErrorsFromResponse } = useErrors();

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => await acceptTerms(id),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: updateUser,
  });

  const onSubmit = ({ privacyCheckbox }) => privacyCheckbox && mutate();

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={[container, gapLarge]}>
        <Logo style={styles.logo} />
        <Text variant="titleMedium">{t("T01009")}</Text>
        <View>
          <PrivacyAndTermsAgreement
            name="privacyCheckbox"
            control={control as unknown as Control}
          />
        </View>
        <PrimaryButton
          label="T01177"
          disabled={!isValid}
          onPress={handleSubmit(onSubmit)}
          loading={isLoading}
        />
        <PrimaryButton label="T00045" onPress={signOut} mode="text" />
      </View>
    </SafeAreaView>
  );
};

export default AcceptTerms;

const styles = StyleSheet.create({
  logo: { alignItems: "flex-start", marginBottom: -spacing8 },
});
