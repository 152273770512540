import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={250} height={188} fill="none" {...props}>
    <Path
      fill="#E6E6E6"
      d="M237.915 187.632H12.085A11.476 11.476 0 0 1 .62 176.167V11.485A11.478 11.478 0 0 1 12.085.02h225.83a11.478 11.478 0 0 1 11.465 11.465v164.682a11.472 11.472 0 0 1-3.362 8.103 11.472 11.472 0 0 1-8.103 3.362Z"
    />
    <Path
      fill="#fff"
      d="M222.023 187.285h-1.549l-23.931-48.209-.695-1.39-17.698-35.66-29.99-60.418-.688-1.39L127.689.368h1.557l19.775 39.85.695 1.39 29.226 58.882.688 1.39 17.691 35.632 24.702 49.773Z"
    />
    <Path
      fill="#fff"
      d="M249.033 40.22H.967v1.39h248.066v-1.39ZM249.033 100.493h-70.585v1.39h70.585v-1.39Z"
    />
    <Path fill="#fff" d="M68.38.368h-1.39V187.98h1.39V.368Z" />
    <Path fill="#fff" d="M196.972 137.684H67.685v1.389h129.287v-1.389Z" />
    <Path
      fill="#4666FF"
      d="M148.625 73.675c0 11.845-17.419 45.245-23.961 57.372a2.767 2.767 0 0 1-2.443 1.46 2.778 2.778 0 0 1-2.444-1.46c-6.542-12.127-23.961-45.527-23.961-57.372a26.403 26.403 0 0 1 45.075-18.67 26.404 26.404 0 0 1 7.734 18.67Z"
    />
    <Path
      fill="#E6E6E6"
      d="M122.221 85.488c6.523 0 11.812-5.289 11.812-11.813 0-6.523-5.289-11.812-11.812-11.812-6.524 0-11.813 5.288-11.813 11.812 0 6.524 5.289 11.813 11.813 11.813Z"
    />
    <Path
      fill="#4666FF"
      d="M122.221 143.856a4.864 4.864 0 1 0 0-9.729 4.864 4.864 0 0 0 0 9.729Z"
    />
  </Svg>
);
export default SvgComponent;
