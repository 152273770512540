import { fontConfig } from "@styles/fontConfig";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { List, Text } from "react-native-paper";

type SubSheetContentProps = {
  freeTrialAvailable: boolean;
  subPrice: string;
  endOfFreeTrialChargeDate: string;
  subButton: ReactNode;
};

const SubSheetContent: FC<SubSheetContentProps> = ({
  freeTrialAvailable,
  subPrice,
  endOfFreeTrialChargeDate,
  subButton,
}) => {
  const { t } = useTranslation();
  const {
    colors: { onPrimary },
  } = useAppTheme();
  const { gapLarge, gapMedium, gapSmall } = globalStyles;

  return (
    <View style={[gapLarge, { marginTop: spacing16 }]}>
      {freeTrialAvailable && (
        <Text style={{ ...fontConfig.bodyLarge, fontFamily: "Poppins-Bold" }}>
          {t("T01453")}
        </Text>
      )}
      <List.Item
        title={<Text variant="labelLarge">{t("T01459")}</Text>}
        description={
          <View style={[gapSmall, { flexDirection: "row" }]}>
            <Text
              style={{
                ...fontConfig.labelMedium,
                fontFamily: "Poppins-Bold",
              }}>
              {subPrice}
            </Text>
            {freeTrialAvailable && (
              <Text variant="labelMedium">{t("T01455")}</Text>
            )}
          </View>
        }
        contentStyle={gapLarge}
        style={{ backgroundColor: onPrimary }}
      />
      <View style={gapMedium}>
        <Text variant="labelSmall">{t("T01456")}</Text>
        {freeTrialAvailable && (
          <Text variant="labelSmall">{`${t(
            "T01457",
          )} ${endOfFreeTrialChargeDate}`}</Text>
        )}
        <Text variant="labelSmall">
          {t(freeTrialAvailable ? "T01460" : "T01458")}
        </Text>
      </View>
      {subButton}
    </View>
  );
};

export default SubSheetContent;
