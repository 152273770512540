import { yupResolver } from "@hookform/resolvers/yup";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native-safe-area-context";
import { mixed, object, string } from "yup";

import {
  FiltersCountrySection,
  FiltersFooter,
  FiltersSearchSection,
  LanguagesModal,
  ServicesModal,
  TimeInputs,
} from "./FiltersComponents";

import { SearchModal } from "@components/Modals";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";

import { RadioButtonsGroup } from "@components/FormElements";
import {
  defaultFilters,
  getDateWithHour00,
  getDateWithHour2345,
} from "@screens/Appointments/FiltersComponents/utils";
import { AppointmentSlots } from "@screens/Calendar/availability.types";
import { Choice } from "@services/ApiService/api.types";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { visitTypes } from "./filters.types";
import VisitDatePicker, {
  VisitDatePickerOptions,
} from "./FiltersComponents/VisitDatePicker";

export type FiltersFormTypes = {
  visitDateType: VisitDatePickerOptions;
  availabilityType: AppointmentSlots;
};

const initialHourFrom = getDateWithHour00();

const initialHourTo = getDateWithHour2345();

export const AppointmentsFilters: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "AppointmentsFilters">
  >
> = ({
  navigation: { navigate },
  route: {
    params: { initialFilters, setFilters },
  },
}) => {
  const { t } = useTranslation();

  const [footerHeight, setFooterHeight] = useState<number>(0);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [servicesModalVisible, setServicesModalVisible] =
    useState<boolean>(false);
  const [languagesModalVisible, setLanguagesModalVisible] =
    useState<boolean>(false);
  const [selectedLanguages, setSelectedLanguages] = useState<Choice[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(
    initialFilters?.country || null,
  );
  const [hourFrom, setHourFrom] = useState<Date>(
    initialFilters?.hourFrom || initialHourFrom,
  );
  const [hourTo, setHourTo] = useState<Date>(
    initialFilters?.hourTo || initialHourTo,
  );
  const [selectedDate, setSelectedDate] = useState<Date>(
    (initialFilters?.visitDatePicker === VisitDatePickerOptions.SELECTED_DAY &&
      initialFilters?.dateFrom &&
      new Date(initialFilters.dateFrom)) ||
      new Date(),
  );

  const { scrollContainer, gapLarge } = globalStyles;

  const schema = object().shape({
    visitDateType: mixed<VisitDatePickerOptions>()
      .oneOf(Object.values(VisitDatePickerOptions))
      .required(t("T00014")),
    availabilityType: string(),
  });

  const {
    control,
    formState: { errors: errorsFromForm },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      visitDateType:
        initialFilters?.visitDatePicker || defaultFilters.visitDatePicker,
      availabilityType:
        initialFilters?.availabilityType || defaultFilters.availabilityType,
    },
  });

  const onClearFilters = () => {
    reset({
      visitDateType: VisitDatePickerOptions.ASAP,
      availabilityType: defaultFilters.availabilityType,
    });
    setSelectedLanguages([]);
    setSelectedServices([]);
    setSelectedCountry(null);
    setSelectedDate(new Date());
    setHourFrom(initialHourFrom);
    setHourTo(initialHourTo);
    setFilters(prev => {
      const lat = prev?.lat;
      const lng = prev?.lng;
      if (lat && lng) return { ...defaultFilters, lat, lng };
      return null;
    });
  };

  useEffect(() => {
    setSelectedLanguages(initialFilters?.spokenLanguage || []);
    setSelectedServices(initialFilters?.serviceName || []);
  }, [initialFilters?.spokenLanguage, initialFilters?.serviceName]);

  const goBack = useCallback(
    ({ filtersActive }: { filtersActive: boolean }) =>
      navigate("Physiotherapists", { resetAllFilters: !filtersActive }),
    [navigate],
  );

  return (
    <SafeAreaView edges={["bottom", "left", "right"]}>
      <KeyboardAwareScrollView
        contentContainerStyle={[
          scrollContainer,
          gapLarge,
          {
            paddingBottom: footerHeight,
            paddingTop: spacing16,
          },
        ]}>
        <VisitDatePicker
          value={watch("visitDateType")}
          setValue={val => setValue("visitDateType", val)}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <TimeInputs
          hourFrom={hourFrom}
          hourTo={hourTo}
          setHourTo={setHourTo}
          setHourFrom={setHourFrom}
        />
        <Controller
          control={control}
          name="availabilityType"
          render={({ field: { onChange, value } }) => (
            <RadioButtonsGroup
              errorMessage={errorsFromForm?.availabilityType?.message}
              onChange={onChange}
              value={value}
              data={visitTypes}
              title="T00551"
            />
          )}
        />
        <FiltersSearchSection
          items={selectedServices}
          onDelete={item =>
            setSelectedServices(prevState => prevState.filter(e => e !== item))
          }
          onPress={() => setServicesModalVisible(prevState => !prevState)}
          title="T00240"
          searchLabel="T00587"
        />
        <FiltersSearchSection
          items={selectedLanguages?.map(({ displayName }) => displayName)}
          onDelete={item =>
            setSelectedLanguages(prevState =>
              prevState.filter(({ displayName }) => displayName !== item),
            )
          }
          onPress={() => setLanguagesModalVisible(prevState => !prevState)}
          title="T00604"
          searchLabel="T00634"
          rowDirection
        />
        <FiltersCountrySection
          setSelectedCountry={setSelectedCountry}
          selectedCountry={selectedCountry}
        />
      </KeyboardAwareScrollView>
      <FiltersFooter
        setFilters={setFilters}
        goBack={goBack}
        setFooterHeight={setFooterHeight}
        handleSubmit={handleSubmit}
        selectedServices={selectedServices}
        selectedLanguages={selectedLanguages}
        selectedCountry={selectedCountry}
        onClearFilters={onClearFilters}
        hourFrom={hourFrom}
        hourTo={hourTo}
        selectedDate={selectedDate}
      />
      <SearchModal<string>
        isModalVisible={servicesModalVisible}
        setIsModalVisible={setServicesModalVisible}
        selectedItems={selectedServices}
        setSelectedItems={setSelectedServices}
        ListComponent={ServicesModal}
        returnLabel={value =>
          t("T00598", {
            value,
          })
        }
        enableSearching
      />
      <SearchModal<Choice>
        isModalVisible={languagesModalVisible}
        setIsModalVisible={setLanguagesModalVisible}
        selectedItems={selectedLanguages}
        setSelectedItems={setSelectedLanguages}
        ListComponent={LanguagesModal}
        returnLabel={value =>
          t("T00635", {
            value,
          })
        }
        enableSearching
      />
    </SafeAreaView>
  );
};
