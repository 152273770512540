import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrors } from "@hooks/useErrors";
import { globalStyles } from "@styles/global";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC } from "react";
import { Control, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { reportContent } from "@services/ApiService/common";
import {
  MULTILINE_INPUT_MAX_LENGTH,
  REPORT_INPUT_MIN_LENGTH,
} from "@utils/constants";
import { object, string } from "yup";
import { SystemMessage } from "@components/Report/report.types";

type Props = {
  onSuccessCallback: () => void;
  idToReport: number;
  title?: string;
};

type FormType = {
  description: string;
};

export const ReportContentBottomSheet: FC<Props> = ({
  onSuccessCallback,
  title,
  idToReport,
}) => {
  const { t } = useTranslation();
  const { gapMedium } = globalStyles;

  const reportSchema = object().shape({
    description: string()
      .required(t("T00014"))
      .min(
        REPORT_INPUT_MIN_LENGTH,
        t("T00032", {
          value: REPORT_INPUT_MIN_LENGTH,
        }),
      )
      .max(
        MULTILINE_INPUT_MAX_LENGTH,
        t("T00036", {
          value: MULTILINE_INPUT_MAX_LENGTH,
        }),
      ),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(reportSchema),
  });
  const { setErrorsFromResponse, errors } = useErrors();

  const { mutate: report, isLoading: reportLoading } = useMutation({
    mutationFn: reportContent,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    onSuccess: onSuccessCallback,
  });

  const onSubmit = ({ description }: FormType) => {
    report({
      systemMessage: title
        ? SystemMessage.REPORT_FORUM_TOPIC
        : SystemMessage.REPORT_FORUM_POST,
      item: idToReport,
      description,
    });
  };

  return (
    <View style={gapMedium}>
      <Text variant="bodyLarge">{t("T00739")}</Text>
      <Text variant="bodyMedium">{t("T00920")}</Text>
      <Input
        name="description"
        label="T00368"
        control={control as unknown as Control}
        errors={errors?.description}
        multiline
        isInBottomSheet
      />
      <PrimaryButton
        label="T00901"
        onPress={handleSubmit(onSubmit)}
        loading={reportLoading}
      />
    </View>
  );
};
