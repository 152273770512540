import { Text } from "react-native-paper";
import React, { FC } from "react";
import InfoTile from "../InfoTile";
import { useTranslation } from "react-i18next";
import { GetPhysiotherapistsResponse } from "@contexts/AuthContext/user.types";
import { GetPhysiotherapistParams } from "@screens/Appointments/filters.types";
import { StyleProp, ViewStyle } from "react-native";

type Props = {
  therapistsList: GetPhysiotherapistsResponse[];
  selectedFilters: GetPhysiotherapistParams;
  firstTherapistOverDistanceId: number | null;
  extraStyle?: StyleProp<ViewStyle>;
  distanceRange: number;
};

const CloseResultsInfoTile: FC<Props> = ({
  therapistsList,
  selectedFilters,
  firstTherapistOverDistanceId,
  extraStyle,
  distanceRange,
}) => {
  const { t } = useTranslation();
  if (!therapistsList?.length || !selectedFilters?.lat || !selectedFilters?.lng)
    return null;
  const { distance: closestDistance } = therapistsList[0];
  const condition =
    +closestDistance < distanceRange && !!firstTherapistOverDistanceId;
  return (
    <InfoTile
      content={<Text>{t(condition ? "T01485" : "T01310")}</Text>}
      status="info"
      style={extraStyle && extraStyle}
    />
  );
};

export default CloseResultsInfoTile;
