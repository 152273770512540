import { FetchError } from "@components/errors";
import { PublicPhysiotherapistFullProfile } from "@contexts/AuthContext/user.types";
import { getPhysiotherapistFullData } from "@services/ApiService/users";
import { palettes } from "@styles/colors";
import { theme } from "@styles/theme";
import { useQuery } from "@tanstack/react-query";
import { inactiveUserAlert } from "@utils/showAlert";
import { FC } from "react";
import { GestureResponderEvent, StyleSheet } from "react-native";
import { ActivityIndicator, Card as RNPCard } from "react-native-paper";
import { CardProps } from "../Card.types";
import PhysiotherapistDataWithRating from "./PhysiotherapistDataWithRating";
import { queryKeysPhysiotherapistProfileData } from "@screens/Profiles/Physiotherapist/queryKeysPhysiotherapist";
import AssistantCard from "@components/cards/AssistantCard";
import { useAssistant } from "@hooks/user/useAssistant";

const PhysiotherapistCardWithRating: FC<
  CardProps<PublicPhysiotherapistFullProfile>
> = ({
  initialData: { id },
  onPress,
  mode,
  elevation = 0,
  flat = true,
  ...props
}) => {
  const { borderColor, styleFlat, elevatedBackground } = styles;
  const { isAssistantId } = useAssistant(id);
  const { data, isError, isFetched, refetch } = useQuery({
    queryKey: queryKeysPhysiotherapistProfileData.full(id),
    queryFn: async () => await getPhysiotherapistFullData(id),
    enabled: !isAssistantId,
  });

  const handleOnPress = (e: GestureResponderEvent) => {
    if (!data.isActive && onPress) return inactiveUserAlert();
    return onPress(e);
  };

  if (isAssistantId) return <AssistantCard onPress={onPress} />;

  return (
    <RNPCard
      elevation={elevation as never}
      mode={mode}
      style={[
        mode === "outlined" && borderColor,
        mode === "elevated" && elevatedBackground,
        flat && styleFlat,
      ]}
      onPress={!isError && onPress && handleOnPress}
      {...props}>
      <RNPCard.Content>
        {isError ? (
          <FetchError action={refetch} coverScreen={false} />
        ) : !isFetched ? (
          <ActivityIndicator />
        ) : (
          data && <PhysiotherapistDataWithRating data={data} />
        )}
      </RNPCard.Content>
    </RNPCard>
  );
};

export default PhysiotherapistCardWithRating;

const styles = StyleSheet.create({
  borderColor: {
    borderColor: theme.colors.outlineVariant,
  },
  elevatedBackground: {
    backgroundColor: theme.colors.elevatedCardBackground,
  },
  styleFlat: {
    backgroundColor: palettes.primary[100],
    borderWidth: 1,
    borderColor: theme.colors.outlineVariant,
  },
});
