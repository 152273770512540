import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  AppbarTitleWithBackAction,
  AppbarTitleWithLogo,
  AppBarTitleWithLogoForPhysiotherapist,
} from "@components/Appbar";
import { CalendarStackParamList } from "./navigation.types";

import { AvailabilityHours } from "@screens/Calendar";
import PatientCalendar from "@screens/Calendar/PatientCalendar";
import PhysiotherapistCalendar from "@screens/Calendar/PhysiotherapistCalendar";
import { useUserDetails } from "@hooks/user/useUserDetails";

const Stack = createNativeStackNavigator<CalendarStackParamList>();

const CalendarStackNavigator: FC = () => {
  const { t } = useTranslation();
  const { isPatient } = useUserDetails();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Calendar"
        component={isPatient ? PatientCalendar : PhysiotherapistCalendar}
        options={{
          header: () =>
            isPatient ? (
              <AppbarTitleWithLogo title={t("T00067")} />
            ) : (
              <AppBarTitleWithLogoForPhysiotherapist title={t("T00067")} />
            ),
        }}
      />
      <Stack.Screen
        name="AvailabilityHours"
        component={AvailabilityHours}
        options={{
          header: ({ navigation: { goBack } }) => (
            <AppbarTitleWithBackAction title={t("T00101")} onBack={goBack} />
          ),
        }}
      />
    </Stack.Navigator>
  );
};

export default CalendarStackNavigator;
