export const spacing2 = 2;
export const spacing4 = 4;
export const spacing6 = 6;
export const spacing8 = 8;
export const spacing12 = 12;
export const spacing16 = 16;
export const spacing18 = 18;
export const spacing20 = 20;
export const spacing24 = 24;
export const spacing26 = 26;
export const spacing28 = 28;
export const spacing32 = 32;
export const spacing36 = 36;
export const spacing40 = 40;
export const spacing48 = 48;
export const spacing56 = 56;
export const spacing64 = 64;
