import {
  BaseSeries,
  SeriesType,
} from "@screens/TrainingsAndExercises/exercise.types";

export const setExerciseTileSubtitles = (series?: BaseSeries[]) => {
  const subtitles: string[] = [];
  if (series?.length) {
    const s = series
      .map(({ value, seriesType }) => {
        if (!value) return "";
        return seriesType === SeriesType.TIME ? `${value}s ` : `${value}x `;
      })
      .join("");
    subtitles.push(s);
  }
  return subtitles;
};
