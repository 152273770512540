import { BlurView } from "@components/BlurView";
import { PrimaryButton } from "@components/buttons";
import { palettes } from "@styles/colors";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { isANDROID } from "@utils/constants";
import { getFormattedHourAndMinutes } from "@utils/date";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { FiltersFormTypes } from "../Filters";
import { FiltersFooterProps, GetPhysiotherapistParams } from "../filters.types";
import {
  checkIsDefault,
  defaultFilters,
  getDateFromAndDateToFromSelectedVisitDateType,
} from "./utils";

export const FiltersFooter: FC<FiltersFooterProps> = ({
  handleSubmit,
  setFilters,
  goBack,
  setFooterHeight,
  selectedServices,
  selectedLanguages,
  selectedCountry,
  onClearFilters,
  hourFrom,
  hourTo,
  selectedDate,
}) => {
  const { bottom } = useSafeAreaInsets();
  const { t } = useTranslation();

  const submit = (values: FiltersFormTypes) => {
    if (
      getFormattedHourAndMinutes(hourFrom) > getFormattedHourAndMinutes(hourTo)
    ) {
      return alert(t("T00958"));
    }
    const dates = getDateFromAndDateToFromSelectedVisitDateType(
      values.visitDateType,
      selectedDate,
    );

    const filters: GetPhysiotherapistParams = {
      availabilityType: values.availabilityType,
      country: selectedCountry,
      serviceName: selectedServices,
      spokenLanguage: selectedLanguages,
      available: true,
      visitDatePicker: values.visitDateType,
      ...dates,
      hourFrom,
      hourTo,
    };

    const isDefaultFilter = checkIsDefault(filters);
    setFilters(prev => {
      const lat = prev?.lat;
      const lng = prev?.lng;
      if (lat && lng) {
        const filtersToSet = isDefaultFilter ? defaultFilters : filters;
        return { ...filtersToSet, lat, lng };
      }
      return isDefaultFilter ? null : filters;
    });
    goBack({ filtersActive: !isDefaultFilter });
  };

  return (
    <BlurView
      tint="light"
      onLayout={({ nativeEvent }) => setFooterHeight(nativeEvent.layout.height)}
      style={[
        styles.blurContainer,
        { bottom: bottom > 0 ? bottom : 0 },
        isANDROID && { backgroundColor: palettes.primary[100] },
      ]}>
      <View style={[globalStyles.gapLarge]}>
        <PrimaryButton mode="text" label="T00565" onPress={onClearFilters} />
        <PrimaryButton label="T00566" onPress={handleSubmit(submit)} />
      </View>
    </BlurView>
  );
};

const styles = StyleSheet.create({
  blurContainer: {
    position: "absolute",
    width: "100%",
    padding: spacing16,
  },
});
