import { spacing16 } from "@styles/spacing";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  timeContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: spacing16,
  },
});
