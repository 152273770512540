import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren, useMemo } from "react";
import { RefreshControl, ScrollView } from "react-native";
import { VerificationTile } from "@components/Tile";
import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import { globalStyles } from "@styles/global";
import { useUserDetails } from "@hooks/user/useUserDetails";
import {
  getPatientVerificationProgress,
  getRehabVerificationProgress,
} from "@services/ApiService/verifications";
import { useQuery } from "@tanstack/react-query";
import { queryKeysPatientCompletion } from "../PatientCompletion/queryKeysPatientCompletion";
import { ActivityIndicator } from "react-native-paper";
import { FetchError } from "@components/errors";
import { queryKeysVerficiations } from "./queryKeysPhysiotherapistVerification";
import {
  convertBooleanToVerificationStatus,
  getPhysioParentVerificationStatuses,
} from "@components/Status/helpers";
import { ProfileCompletionInfo } from "../ProfileCompletionInfo";

const ProfileCompletion: FC<
  PropsWithChildren<
    NativeStackScreenProps<ProfileCompletionStackParamList, "ProfileCompletion">
  >
> = ({ navigation: { navigate } }) => {
  const { isPhysiotherapist } = useUserDetails();
  const {
    data: patientData,
    isLoading: isPatientDataLoading,
    isError: isPatientDataError,
    refetch: refetchPatientData,
  } = useQuery({
    queryKey: queryKeysPatientCompletion.verificationProgressPatient(),
    queryFn: getPatientVerificationProgress,
    enabled: !isPhysiotherapist,
  });

  const {
    data: physioData,
    isLoading: isPhysioDataLoading,
    isError: isPhysioDataError,
    refetch: refetchPhysioData,
  } = useQuery({
    queryKey: queryKeysVerficiations.verificationProgress(),
    queryFn: getRehabVerificationProgress,
    enabled: isPhysiotherapist,
  });

  const {
    licenceAndAddressVerificationStatus,
    profileInfoVerificationStatus,
    stationaryPaymentVerification,
    onlinePaymentVerification,
    languageAndServicesVerificationStatus,
    subscriptionVerification,
  } = useMemo(
    () =>
      isPhysiotherapist &&
      physioData &&
      getPhysioParentVerificationStatuses(physioData),
    [isPhysiotherapist, physioData],
  );

  const { gapMedium, scrollContainer, loading } = globalStyles;

  return (
    <ScrollView
      contentContainerStyle={[gapMedium, scrollContainer]}
      refreshControl={
        <RefreshControl
          refreshing={
            isPhysiotherapist ? isPhysioDataLoading : isPatientDataLoading
          }
          onRefresh={isPhysiotherapist ? refetchPhysioData : refetchPatientData}
        />
      }>
      <ProfileCompletionInfo />
      {isPhysiotherapist ? (
        isPhysioDataLoading ? (
          <ActivityIndicator style={loading} />
        ) : isPhysioDataError ? (
          <FetchError action={refetchPhysioData} />
        ) : (
          <>
            <VerificationTile
              mode="outlined"
              title="T00234"
              content="T00235"
              onPress={() => navigate("AddressAndVerification")}
              status={licenceAndAddressVerificationStatus}
              type="profile completion"
            />
            <VerificationTile
              mode="outlined"
              title="T00236"
              content="T00237"
              onPress={() => navigate("ProfileInformation")}
              status={profileInfoVerificationStatus}
              type="profile completion"
            />
            <VerificationTile
              mode="outlined"
              title="T00710"
              content="T00711"
              onPress={() => navigate("Payments")}
              status={convertBooleanToVerificationStatus(
                stationaryPaymentVerification && onlinePaymentVerification,
              )}
              type="profile completion"
            />
            <VerificationTile
              mode="outlined"
              title="T00238"
              content="T00239"
              onPress={() => navigate("ServicesAndLanguages")}
              status={languageAndServicesVerificationStatus}
              type="profile completion"
            />
            <VerificationTile
              mode="outlined"
              title="T00936"
              content="T00937"
              onPress={() => navigate("Subscription")}
              status={convertBooleanToVerificationStatus(
                subscriptionVerification,
              )}
              type="profile completion"
            />
          </>
        )
      ) : isPatientDataLoading ? (
        <ActivityIndicator style={loading} />
      ) : isPatientDataError ? (
        <FetchError action={refetchPatientData} />
      ) : (
        <>
          <VerificationTile
            mode="outlined"
            title="T01023"
            content="T01024"
            onPress={() => navigate("PatientDetails")}
            status={patientData?.profileFulfilled}
            type="profile completion"
          />
          <VerificationTile
            mode="outlined"
            title="T00356"
            content="T00357"
            onPress={() => navigate("WeightAndHeight")}
            status={patientData?.patientDetails}
            type="profile completion"
          />
          <VerificationTile
            mode="outlined"
            title="T00604"
            content="T00626"
            onPress={() => navigate("Languages")}
            status={patientData?.languageVerification}
            type="profile completion"
          />
          <VerificationTile
            mode="outlined"
            title="T00528"
            content="T00529"
            onPress={() => navigate("AddPhoto")}
            status={patientData?.profilePhotoVerification}
            type="profile completion"
          />
        </>
      )}
    </ScrollView>
  );
};

export default ProfileCompletion;
