import { getFullTrainingData } from "@api/trainings";
import AbsoluteLoader from "@components/Loader/AbsoluteLoader";
import EndWorkoutBottomSheet from "@components/Player/EndWorkoutBottomSheet";
import ExerciseInstructionBottomSheet from "@components/Player/ExerciseInstructionBottomSheet";
import ExercisesListBottomSheet from "@components/Player/ExercisesListBottomSheet";
import PlayerSection from "@components/Player/PlayerSection";
import SeriesResultInputBottomSheet from "@components/Player/SeriesResultInputBottomSheet";
import VideoSection from "@components/Player/VideoSection";
import { PlayerMode } from "@components/Player/player.types";
import { FetchError } from "@components/errors";
import { usePlayer } from "@hooks/index";
import { useErrors } from "@hooks/useErrors";
import { RootStackParamList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useKeepAwake } from "expo-keep-awake";
import { FC, PropsWithChildren, useCallback, useState } from "react";
import { ActivityIndicator } from "react-native-paper";
import { queryKeysTraining } from "./queryKeysTrainingsAndExercises";
import { useAssistant } from "@hooks/user/useAssistant";

const TrainingPlayer: FC<
  PropsWithChildren<
    NativeStackScreenProps<RootStackParamList, "TrainingPlayer">
  >
> = ({ navigation, route }) => {
  useKeepAwake();
  const { id } = route.params;
  const { goBack } = navigation;

  const { setErrorsFromResponse } = useErrors();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysTraining.detail(id, { fullData: true }),
    queryFn: async () => await getFullTrainingData(id, true),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const { isAssistantId } = useAssistant(data?.author);

  const {
    isPlaying,
    currentExercise,
    currentSeries,
    currentExerciseIndex,
    currentSeriesIndex,
    initialValue,
    playerMode,
    setPlayerMode,
    handleSkipCurrentExercise,
    handleChangeCurrentExercise,
    saveSeriesData,
    setIsPlaying,
    saveWorkoutSession,
    togglePlay,
    updateSeriesBreakValue,
    getInitialTimeValue,
    isLastSerie,
    isLastExercise,
    isFinishTrainingLoading,
    audioMuted,
  } = usePlayer({
    exercises: data?.exercises,
    trainingId: id,
    navigation,
  });

  const [playerSectionHeight, setPlayerSectionHeight] = useState(0);

  const [endTrainingBottomSheetVisible, setEndTrainingBottomSheetVisible] =
    useState(false);

  const [exercisesListBottomSheetVisible, setExercisesListBottomSheetVisible] =
    useState(false);
  const [instructionBottomSheetVisible, setInstructionBottomSheetVisible] =
    useState(false);
  const [endSeriesBottomSheetVisible, setEndSeriesBottomSheetVisible] =
    useState(false);

  const saveSeriesBreakValue = useCallback(
    (totalValue: number) => {
      updateSeriesBreakValue(totalValue, () =>
        setEndSeriesBottomSheetVisible(true),
      );
    },
    [updateSeriesBreakValue],
  );
  if (isLoading) return <ActivityIndicator style={globalStyles.loading} />;

  if (isError) return <FetchError action={refetch} />;

  return (
    <>
      {isFinishTrainingLoading && <AbsoluteLoader />}
      <VideoSection
        exercises={data.exercises}
        topAndBottomSectionsVisible={!isPlaying}
        videoUrl={currentExercise.exerciseVideoUrl}
        playerSectionHeight={playerSectionHeight}
        onXPress={() => setEndTrainingBottomSheetVisible(prev => !prev)}
        onExercisesListPress={() =>
          setExercisesListBottomSheetVisible(prev => !prev)
        }
        onInstructionPress={() =>
          setInstructionBottomSheetVisible(prev => !prev)
        }
        onVideoPress={togglePlay}
        playerMode={playerMode}
        currentExercise={currentExercise}
        currentExerciseIndex={currentExerciseIndex}
        currentSeriesIndex={currentSeriesIndex}
        shouldBeMuted={instructionBottomSheetVisible || audioMuted}
        handleChangeCurrentExercise={handleChangeCurrentExercise}
      />
      <PlayerSection
        onLayout={({ nativeEvent }) =>
          setPlayerSectionHeight(nativeEvent.layout.height)
        }
        onLeftButtonPress={togglePlay}
        onRightButtonPress={saveSeriesBreakValue}
        playerMode={playerMode}
        currentSeries={currentSeries}
        isPlaying={isPlaying}
        onAutoStart={() => setPlayerMode(PlayerMode.EXERCISE)}
        getInitialTimeValue={getInitialTimeValue}
      />
      <EndWorkoutBottomSheet
        visible={endTrainingBottomSheetVisible}
        setVisible={setEndTrainingBottomSheetVisible}
        onContinue={() => setIsPlaying(true)}
        onEndWithoutSaving={goBack}
        onEndAndSave={saveWorkoutSession}
      />
      <ExercisesListBottomSheet
        visible={exercisesListBottomSheetVisible}
        setVisible={setExercisesListBottomSheetVisible}
        exercises={data.exercises}
        onSelectNewExercise={handleSkipCurrentExercise}
        displayExtraIcon={isAssistantId}
      />
      <ExerciseInstructionBottomSheet
        visible={instructionBottomSheetVisible}
        setVisible={setInstructionBottomSheetVisible}
        exercise={currentExercise}
      />
      <SeriesResultInputBottomSheet
        visible={endSeriesBottomSheetVisible}
        setVisible={setEndSeriesBottomSheetVisible}
        type={currentSeries.seriesType}
        onNextPressCallback={saveSeriesData}
        initialValue={initialValue}
        buttonProps={isLastSerie && isLastExercise && { label: "T00510" }}
      />
    </>
  );
};

export default TrainingPlayer;
