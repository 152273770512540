import { ControllerProps, Controller } from "react-hook-form";
import {
  createDateFromHoursAndMinutes,
  convertDateToNotificationHour,
} from "../utils";
import DayReminder from "./DayReminder";
import { BaseSyntheticEvent, FC } from "react";
import { TransKey } from "@globalTypes/i18next";

type DayReminderWithControllerProps = Pick<ControllerProps, "control"> & {
  fieldName: string;
  notificationTime: string;
  handleSubmit: (e?: BaseSyntheticEvent) => Promise<void>;
  name: TransKey;
};

const DayReminderWithController: FC<DayReminderWithControllerProps> = ({
  control,
  fieldName,
  notificationTime,
  handleSubmit,
  name,
}) => {
  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={notificationTime}
      render={({ field: { onChange } }) => (
        <DayReminder
          title={name}
          initialDate={createDateFromHoursAndMinutes(notificationTime)}
          onChange={data => {
            onChange(convertDateToNotificationHour(data));
          }}
          onDismiss={handleSubmit}
        />
      )}
    />
  );
};

export default DayReminderWithController;
