import { FC } from "react";
import { BarChart as BChart } from "react-native-chart-kit-with-pressable-bar-graph";
import { BarChartProps } from "./chart.types";

const BarChart: FC<BarChartProps> = ({
  chartData,
  chartConfig,
  width = 320,
  height = 220,
  style,
  onDataPointClick,
}) => (
  <BChart
    style={style}
    data={chartData}
    width={width}
    height={height}
    yAxisLabel=""
    yAxisSuffix=""
    chartConfig={chartConfig}
    fromZero
    showBarTops={false}
    withInnerLines={false}
    withCustomBarColorFromData
    flatColor
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onDataPointClick={onDataPointClick}
  />
);

export default BarChart;
