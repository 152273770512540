import { GenderType } from "@contexts/AuthContext/user.types";
import { FC } from "react";
import MaleMuscleBack from "./MaleMuscleBack";
import FemaleMuscleBack from "./FemaleMuscleBack";
import { ModelProps } from "../humanModel.types";
import { useHumanModel } from "@hooks/humanModel/useHumanModel";

const MuscleBack: FC<ModelProps> = ({
  defaultSelected = [],
  onChange,
  readOnly = false,
  gender,
}) => {
  const { modelProps } = useHumanModel({
    onChange,
    readOnly,
    defaultSelected,
  });

  return gender === GenderType.MALE ? (
    <MaleMuscleBack {...modelProps} />
  ) : (
    <FemaleMuscleBack {...modelProps} />
  );
};
export default MuscleBack;
