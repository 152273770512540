/* eslint-disable react-hooks/rules-of-hooks */
import { BottomTutorialComponent } from "@components/BottomSheet";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import {
  AbortFillSurveyBottomSheet,
  ShareMedicalRecordsContent,
} from "@components/BottomSheetContents";
import TutorialTextButton from "@components/Button/TutorialTextButton";
import { AbsoluteBlurredFooter } from "@components/Footers/AbsoluteBlurredFooter";
import { SearchModal } from "@components/Modals";
import { MedicalRecordsModal } from "@components/Modals/ModalComponents";
import { MedicalRecordTile } from "@components/Tile";
import { PrimaryButton } from "@components/buttons";
import { ScheduleAppointmentStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { formatDateForApi, getDate } from "@utils/date";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { Text } from "react-native-paper";
import { AppointmentContext } from "../appointment.context";
import SurveyHeader from "./SurveyHeader";
import { AppbarTitleWithBackAction } from "@components/Appbar";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fillAppointmentSurvey } from "@services/ApiService/appointments";
import { FillAppointmentSurvey } from "../appointment.types";
import { queryKeysMedicalRecord } from "@screens/Profiles/MyProfile/MedicalRecords/queryKeysMedicalRecord";
import { shareMedicalRecords } from "@services/ApiService/medicalRecords";
import { AxiosError } from "axios";
import { useErrors } from "@hooks/useErrors";
import { queryKeysAppointments } from "../queryKeysAppointments";
import { TransKey } from "@globalTypes/i18next";

const subtitles: TransKey[] = ["T00909", "T00910", "T00911"];

const MedicalRecordsToAppointment: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      ScheduleAppointmentStackParamsList,
      "MedicalRecordsToAppointment"
    >
  >
> = ({ navigation: { navigate, setOptions, pop }, route: { params } }) => {
  const [selectedRecords, setSelectedRecords] = useState<number[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tutorialModalVisible, setTutorialModalVisible] = useState(false);
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);
  const [abortModalVisible, setAbortModalVisible] = useState(false);
  const [footerHeight, setFooterHeight] = useState(0);
  const [shareUntil, setShareUntil] = useState<string>(null);

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setErrorsFromResponse } = useErrors();

  useEffect(() => {
    params?.fillInSurveyProcess &&
      setOptions({
        header: ({ navigation: { goBack } }) => (
          <AppbarTitleWithBackAction
            title={t("T00080")}
            onBack={goBack}
            onClose={() => setAbortModalVisible(true)}
          />
        ),
      });
  }, [params, setOptions, t]);

  const {
    data: { physiotherapist: physioId, dateFrom, ...rest },
    updateData,
  } = useContext(AppointmentContext);

  const { scrollContainer, gapLarge, gapSmall } = globalStyles;

  const removeRecordFromList = useCallback((recordId: number) => {
    setSelectedRecords(prevSelected =>
      prevSelected.filter(selectedRecord => selectedRecord !== recordId),
    );
  }, []);

  const closeStack = () => {
    pop();
    pop();
    pop();
  };

  const { mutate: shareRecords, isLoading: isShareRecordsLoading } =
    useMutation({
      mutationFn: () =>
        shareMedicalRecords({
          asignee: params.physiotherapistId,
          medicalRecords: selectedRecords,
          dueDate: shareUntil,
        }),
      onSuccess: async () => {
        const {
          createdByMeList,
          sharedBetweenMeAndOtherUserList,
          recordDetails,
          createdForList,
        } = queryKeysMedicalRecord;
        await Promise.all([
          selectedRecords.map(id =>
            queryClient.invalidateQueries({
              queryKey: recordDetails(id),
            }),
          ),
          queryClient.invalidateQueries({ queryKey: createdByMeList() }),
          queryClient.invalidateQueries({
            queryKey: createdForList(params.physiotherapistId),
          }),
          queryClient.invalidateQueries({
            queryKey: sharedBetweenMeAndOtherUserList(params.physiotherapistId),
          }),
          params.fillInSurveyProcess &&
            queryClient.invalidateQueries({
              queryKey: queryKeysAppointments.detail(params.appointmentId),
            }),
        ]);
        closeStack();
      },
      onError: ({ response }: AxiosError) => {
        setErrorsFromResponse(response);
        alert(
          `${t("T00676")}. ${
            (response.data as { due_date: string[] }).due_date[0]
          }`,
        );
      },
    });

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: FillAppointmentSurvey) =>
      await fillAppointmentSurvey(params.appointmentId, data),
    onSuccess: async () => {
      if (selectedRecords.length) {
        shareRecords();
      } else {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: queryKeysAppointments.detail(params.appointmentId),
          }),
        ]);
        closeStack();
      }
    },
  });

  const saveAndContinue = ({ shareUntilDate }: { shareUntilDate: string }) => {
    if (params?.fillInSurveyProcess) {
      const {
        injuryDescription,
        painLevel,
        previouslyTreated,
        gender,
        humanBones,
        humanGeneral,
        humanMuscles,
      } = rest;
      const result = {
        injuryDescription,
        painLevel,
        previouslyTreated,
        gender,
      };

      if (rest?.disfunctionDescription) {
        Object.assign(result, {
          disfunctionDescription: rest.disfunctionDescription,
        });
      }
      if (humanBones.length) {
        Object.assign(result, {
          humanBones,
        });
      }
      if (humanGeneral.length) {
        Object.assign(result, {
          humanGeneral,
        });
      }
      if (humanMuscles.length) {
        Object.assign(result, {
          humanMuscles,
        });
      }

      selectedRecords.length &&
        setShareUntil(formatDateForApi(shareUntilDate, "iso"));
      mutate(result);
    } else {
      selectedRecords.length &&
        updateData({
          recordIds: selectedRecords,
          shareUntilDate: formatDateForApi(shareUntilDate, "iso"),
        });
      setIsBottomSheetVisible(false);
      navigate("ScheduledAppointmentSummary");
    }
  };

  const isButtonLoading = useMemo(
    () => isLoading || isShareRecordsLoading,
    [isLoading, isShareRecordsLoading],
  );

  return (
    <>
      <ScrollView
        contentContainerStyle={[
          scrollContainer,
          gapLarge,
          { paddingBottom: footerHeight },
        ]}>
        <View style={gapSmall}>
          <SurveyHeader
            disableSkip={params?.fillInSurveyProcess}
            step="3"
            onSkip={() => {
              navigate("ScheduledAppointmentSummary", { surveyOmitted: true });
            }}
          />
          <Text variant="titleMedium">{t("T00290")}</Text>
          <Text variant="bodyMedium">{t("T00907")}</Text>
          <TutorialTextButton
            label="T00908"
            onPress={() => setTutorialModalVisible(true)}
          />
          <PrimaryButton
            label="T00291"
            mode="outlined"
            onPress={() => setIsModalVisible(true)}
          />
        </View>
        {selectedRecords.map((id, i) => (
          <MedicalRecordTile
            recordId={id}
            key={`record-${id}-${i}`}
            onXIconPress={removeRecordFromList}
          />
        ))}
      </ScrollView>
      <AbsoluteBlurredFooter
        title={params?.fillInSurveyProcess ? "T01265" : "T00289"}
        buttonLoading={isButtonLoading}
        onPress={() => {
          if (selectedRecords?.length > 0) {
            setIsBottomSheetVisible(true);
          } else {
            if (params?.fillInSurveyProcess) {
              saveAndContinue({ shareUntilDate: null });
            } else {
              navigate("ScheduledAppointmentSummary");
            }
          }
        }}
        onLayout={height => setFooterHeight(height)}
      />
      <SearchModal<number>
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedItems={selectedRecords}
        setSelectedItems={setSelectedRecords}
        ListComponent={MedicalRecordsModal}
        returnLabel={value =>
          t("T00293", {
            value,
          })
        }
      />
      <BottomModalContainer
        modalVisible={isBottomSheetVisible}
        setModalVisible={setIsBottomSheetVisible}>
        <ShareMedicalRecordsContent
          physioId={physioId || params.physiotherapistId}
          dateFrom={dateFrom || params?.dateFrom || getDate(new Date())}
          saveAndContinue={saveAndContinue}
        />
      </BottomModalContainer>
      <BottomModalContainer
        modalVisible={tutorialModalVisible}
        setModalVisible={setTutorialModalVisible}>
        <BottomTutorialComponent
          title="T00908"
          subtitles={subtitles}
          onPress={() => setTutorialModalVisible(false)}
        />
      </BottomModalContainer>
      {params?.fillInSurveyProcess && (
        <BottomModalContainer
          modalVisible={abortModalVisible}
          setModalVisible={setAbortModalVisible}>
          <AbortFillSurveyBottomSheet
            onContinue={() => setAbortModalVisible(false)}
            onAbort={() => closeStack()}
          />
        </BottomModalContainer>
      )}
    </>
  );
};

export default MedicalRecordsToAppointment;
