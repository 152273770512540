import { spacing20, spacing4, spacing8 } from "@styles/spacing";
import { useAppTheme } from "@styles/theme";
import { FC, PropsWithChildren } from "react";
import { Pressable, StyleSheet } from "react-native";
import { Icon, List, Text } from "react-native-paper";

type CustomChipProps = {
  onPress: () => void;
  selected: boolean;
  title: string;
  color: string;
};

export const CustomChip: FC<PropsWithChildren<CustomChipProps>> = ({
  onPress,
  selected,
  title,
  color,
}) => {
  const {
    colors: { secondaryContainer, onPrimary, outline },
  } = useAppTheme();
  return (
    <Pressable
      onPress={onPress}
      style={[
        styles.container,
        {
          backgroundColor: selected ? secondaryContainer : onPrimary,
          borderColor: outline,
        },
      ]}>
      {selected && <Icon source="check" size={spacing20} />}
      <Text variant="labelLarge" style={{ paddingLeft: !selected && spacing8 }}>
        {title}
      </Text>
      <List.Icon icon="menu-down" color={color} />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: spacing8,
    flexDirection: "row",
    paddingVertical: spacing4,
    paddingHorizontal: spacing8,
    alignItems: "center",
    borderWidth: 1,
    gap: spacing4,
  },
});
