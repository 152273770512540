import AppointmentDetailsSections from "@components/Appointment/AppointmentDetailsSections";
import CheckboxSimpleText from "@components/Checkboxes/CheckboxSimpleText";
import PrivacyAndTermsAgreement from "@components/Checkboxes/PrivacyAndTermsAgreement";
import { AbsoluteBlurredFooter } from "@components/Footers";
import ServiceTileWithQuery from "@components/Tile/service/ServiceTileWithQuery";
import { PhysiotherapistCardWithRating } from "@components/cards";
import { FetchError } from "@components/errors";
import { AcceptablePaymentsSection } from "@components/index";
import { Payment } from "@globalTypes/payment.types";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePhysioServiceDetails } from "@hooks/queryHooks/usePhysioServiceDetails";
import { useErrors } from "@hooks/useErrors";
import { useTherapistPaymentMethods } from "@hooks/payments/useTherapistPaymentMethods";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { ScheduleAppointmentStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { queryKeysMedicalRecord } from "@screens/Profiles/MyProfile/MedicalRecords/queryKeysMedicalRecord";
import {
  OnlinePaymentMethodsResponse,
  StationaryPaymentMethodsResponse,
} from "@screens/Profiles/MyProfile/PhysiotherapistVerification/verification.types";
import {
  createAppointment,
  getAppointmentDetails,
} from "@services/ApiService/appointments";
import { shareMedicalRecords } from "@services/ApiService/medicalRecords";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isWeb } from "@utils/constants";
import { getPrice } from "@utils/prices";
import { showAlert } from "@utils/showAlert";
import { AxiosError } from "axios";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Control, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Platform, ScrollView, StyleSheet, View } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { boolean, object } from "yup";
import { AppointmentContext } from "../appointment.context";
import {
  AppointmentPaymentType,
  AppointmentSurveyType,
  ScheduleAppointmentType,
} from "../appointment.types";
import {
  queryKeysAppointments,
  queryKeysPhysiotherapist,
} from "../queryKeysAppointments";
import TherapistAccountTypeSection from "./TherapistAccountTypeSection";
import InfoTile from "@components/Tile/InfoTile";
import { config } from "@utils/config";
import { TransKey } from "@globalTypes/i18next";
import { ButtonBasic } from "@components/Button/Button.types";
import { usePayments } from "@hooks/index";

type initStripeType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("@stripe/stripe-react-native").initStripe;

let initStripe: initStripeType;

if (!isWeb) {
  initStripe = (
    require("@stripe/stripe-react-native") as typeof import("@stripe/stripe-react-native")
  ).initStripe;
}

type FormType = Omit<ScheduleAppointmentType, "physiotherapist">;

const ScheduledAppointmentSummary: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      ScheduleAppointmentStackParamsList,
      "ScheduledAppointmentSummary"
    >
  >
> = ({ navigation: { navigate }, route: { params } }) => {
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const [createdAppointmentId, setCreatedAppointmentId] = useState<
    number | undefined
  >(undefined);
  const [paymentType, setPaymentType] = useState<AppointmentPaymentType | null>(
    null,
  );
  const { t } = useTranslation();
  const { setErrorsFromResponse } = useErrors();
  const {
    setErrorsFromResponse: setCreateAppointmentErrors,
    errors: formErrors,
    clearErrors,
  } = useErrors();
  const scrollViewRef = useRef<ScrollView>(null);
  const { currency } = useUserDetails();
  const {
    data: {
      physiotherapist,
      dateFrom,
      injuryDescription,
      painLevel,
      previouslyTreated,
      disfunctionDescription,
      recordIds,
      shareUntilDate,
      serviceType,
      serviceId,
      appointmentLanguage,
      languageLabel,
      humanBones,
      humanMuscles,
      humanGeneral,
      gender,
    },
  } = useContext(AppointmentContext);

  const checkboxRules = boolean()
    .oneOf([true], t("T00014"))
    .required(t("T00014"));

  const schema = object().shape({
    privacyCheckbox: checkboxRules,
    returnsPolicyCheckbox: checkboxRules,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  }: UseFormReturn<FormType> = useForm<FormType>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues: {
      dateFrom,
      injuryDescription,
      painLevel,
      previouslyTreated,
      disfunctionDescription,
    },
  });
  const queryClient = useQueryClient();
  const surveyOmitted = params?.surveyOmitted;

  const { mutate: shareRecords, isLoading: isShareRecordsLoading } =
    useMutation({
      mutationFn: () =>
        shareMedicalRecords({
          asignee: physiotherapist,
          medicalRecords: recordIds,
          dueDate: shareUntilDate,
        }),
      onSuccess: async () => {
        const {
          createdByMeList,
          sharedBetweenMeAndOtherUserList,
          recordDetails,
        } = queryKeysMedicalRecord;
        await Promise.all([
          recordIds.map(id =>
            queryClient.invalidateQueries({
              queryKey: recordDetails(id),
            }),
          ),
          queryClient.invalidateQueries({ queryKey: createdByMeList() }),
          queryClient.invalidateQueries({
            queryKey: sharedBetweenMeAndOtherUserList(physiotherapist),
          }),
        ]);
      },
      onError: ({ response }: AxiosError) => {
        setErrorsFromResponse(response);
        alert(
          `${t("T00676")}. ${
            (response.data as { due_date: string[] }).due_date[0]
          }`,
        );
      },
    });

  const invalidateQueries = async () =>
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: queryKeysAppointments.list(),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysPhysiotherapist.myList(),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysPhysiotherapist.availability(physiotherapist),
      }),
    ]);

  const onCreateAppointmentSuccess = async (id: number) => {
    await invalidateQueries();
    recordIds?.length && !surveyOmitted && shareRecords();
    paymentType === "Online" &&
      (freeService ? await onPaymentSuccess(id) : setCreatedAppointmentId(id));
    paymentType === "Stationary" &&
      navigate("AppointmentConfirmation", { id, paymentType: "Stationary" });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      clearErrors();
      showAlert(t("T00836"));
    }
  }, [clearErrors, formErrors, t]);

  const {
    data: service,
    isLoading: isServiceLoading,
    isError: isServiceError,
  } = usePhysioServiceDetails({
    physioId: physiotherapist,
    serviceId,
    serviceType,
  });

  const freeService = service?.[0].serviceItem.freeService;

  const {
    mutate,
    isLoading,
    isSuccess: createAppointmentSuccess,
  } = useMutation({
    mutationFn: createAppointment,
    onError: ({ response }: AxiosError) => setCreateAppointmentErrors(response), //todo: handle errorr "Pacjent ma już zaplanowaną kolejną wizytę w tym czasie."
    onSuccess: async ({ data: { id } }) => await onCreateAppointmentSuccess(id),
  });

  const { data: payment, isFetching } = useQuery({
    queryKey: queryKeysAppointments.payment(createdAppointmentId),
    queryFn: async () => await getAppointmentDetails(createdAppointmentId),
    enabled: createAppointmentSuccess && !!createdAppointmentId && !freeService,
    select: data => data.payment || ({} as Payment),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    refetchInterval: data => (data?.paymentIntent ? 0 : 1000),
  });

  const {
    onlineMethods,
    stationaryMethods,
    isError: isPaymentMethodsError,
    isLoading: isPaymentMethodsLoading,
    refetch: refetchPaymentMethods,
  } = useTherapistPaymentMethods(physiotherapist);

  const showOnlineButton = useCallback(
    (paymentMethod: OnlinePaymentMethodsResponse) => {
      if (!paymentMethod) return false;
      return (
        (paymentMethod.onlineAppointments && serviceType === "Online") ||
        (paymentMethod.stationaryAppointments && serviceType === "Stationary")
      );
    },
    [serviceType],
  );

  const showStationaryButton = useCallback(
    (paymentMethod: StationaryPaymentMethodsResponse) => {
      if (!paymentMethod) return false;
      return paymentMethod.isActive && serviceType === "Stationary";
    },
    [serviceType],
  );

  const onSubmit = useCallback(
    (
      {
        painLevel,
        injuryDescription,
        previouslyTreated,
        disfunctionDescription,
        ...rest
      }: ScheduleAppointmentType,
      paymentType: AppointmentPaymentType,
    ) => {
      const params: ScheduleAppointmentType = {
        ...rest,
        appointmentLanguage,
        surveyOmitted,
        paymentType,
        service: serviceId,
        physiotherapist,
      };
      if (!surveyOmitted) {
        const surveyData: AppointmentSurveyType = {
          painLevel,
          injuryDescription,
          previouslyTreated,
          disfunctionDescription,
          humanBones,
          humanMuscles,
          humanGeneral,
          gender,
        };
        Object.assign(params, surveyData);
      }
      mutate(params);
    },
    [
      appointmentLanguage,
      gender,
      humanBones,
      humanGeneral,
      humanMuscles,
      mutate,
      physiotherapist,
      serviceId,
      surveyOmitted,
    ],
  );

  const onPaymentSuccess = async (id?: number) => {
    navigate("AppointmentConfirmation", {
      id: id || createdAppointmentId,
      paymentType: "Online",
    });
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: queryKeysAppointments.detail(id || createdAppointmentId),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysAppointments.list(),
      }),
      queryClient.invalidateQueries({
        queryKey: queryKeysPhysiotherapist.myList(),
      }),
    ]);
  };
  const onPaymentError = useCallback(
    () =>
      navigate("AppointmentConfirmation", {
        id: createdAppointmentId,
        paymentType: "Online",
        paymentError: true,
      }),
    [createdAppointmentId, navigate],
  );

  const { initializePaymentSheet, openPaymentSheet, wasPaymentInitialized } =
    usePayments({
      paymentIntentClientSecret: payment?.paymentIntentClientSecret,
      onPaymentSuccess,
      onPaymentError,
      returnURL: `${config.EXPO_PUBLIC_DEEP_LINK_URL}schedule-appointment-stack/appointmentConfirmation/${createdAppointmentId}`,
    });

  useEffect(() => {
    if (
      !payment?.paymentIntent ||
      wasPaymentInitialized ||
      isWeb ||
      freeService
    )
      return;
    void (async () => {
      await initStripe({
        publishableKey: payment.stripePublishableKey,
        stripeAccountId: payment.sellerStripeId,
      });
      await initializePaymentSheet();
    })();
  }, [
    initializePaymentSheet,
    payment,
    openPaymentSheet,
    wasPaymentInitialized,
    freeService,
  ]);

  const isAnyLoading = useMemo(
    () => isLoading || isShareRecordsLoading || isFetching || isServiceLoading,
    [isLoading, isShareRecordsLoading, isFetching, isServiceLoading],
  );

  useEffect(() => {
    if (errors.privacyCheckbox || errors.returnsPolicyCheckbox)
      scrollViewRef.current.scrollToEnd();
  }, [errors.privacyCheckbox, errors.returnsPolicyCheckbox]);

  const getPriceLabel = useCallback(() => {
    if (isServiceError) return "";
    const price = getPrice(service?.[0]?.prices, currency);
    return `${price?.baseAmount} ${price?.baseCurrency}`;
  }, [currency, isServiceError, service]);

  const payOnlineOnPress = wasPaymentInitialized
    ? openPaymentSheet
    : handleSubmit(data => onSubmit(data as ScheduleAppointmentType, "Online"));

  const payOnlineBtnProps: ButtonBasic = useMemo(
    () => ({
      label: t(freeService ? "T01528" : "T01146", {
        value: getPriceLabel(),
      }) as TransKey,
      onPress: () => {
        setPaymentType("Online");
        return payOnlineOnPress();
      },
      loading: isAnyLoading && paymentType === "Online",
      disabled: isAnyLoading,
    }),
    [
      freeService,
      getPriceLabel,
      isAnyLoading,
      payOnlineOnPress,
      paymentType,
      t,
    ],
  );

  const getPayStationaryBtnProps = useCallback(
    (mode: ButtonBasic["mode"] = "contained"): ButtonBasic => ({
      label: freeService ? "T01528" : "T01147",
      mode: mode,
      onPress: async () => {
        setPaymentType("Stationary");
        await handleSubmit(data =>
          onSubmit(data as ScheduleAppointmentType, "Stationary"),
        )();
      },
      loading: isAnyLoading && paymentType === "Stationary",
      disabled: isAnyLoading,
    }),
    [freeService, handleSubmit, isAnyLoading, onSubmit, paymentType],
  );

  const buttons: ButtonBasic[] = useMemo(() => {
    const showOnline = showOnlineButton(onlineMethods?.[0]);
    const showStationary = showStationaryButton(stationaryMethods?.[0]);
    if (!showOnline && !showStationary) return [];
    if (showOnline && !showStationary) return [payOnlineBtnProps];
    if (!showOnline && showStationary) return [getPayStationaryBtnProps()];
    if (showOnline && showStationary && freeService)
      return [getPayStationaryBtnProps()];
    return [getPayStationaryBtnProps("outlined"), payOnlineBtnProps];
  }, [
    freeService,
    showOnlineButton,
    onlineMethods,
    showStationaryButton,
    stationaryMethods,
    payOnlineBtnProps,
    getPayStationaryBtnProps,
  ]);

  return (
    <SafeAreaView
      edges={["bottom", "left", "right"]}
      style={globalStyles.container}>
      <ScrollView
        contentContainerStyle={[
          globalStyles.gapLarge,
          { paddingBottom: footerHeight },
        ]}
        showsVerticalScrollIndicator={false}
        ref={scrollViewRef}>
        <PhysiotherapistCardWithRating initialData={{ id: physiotherapist }} />
        <View>
          <Text variant="titleMedium">{t("T00583")}:</Text>
          <ServiceTileWithQuery
            serviceType={serviceType}
            serviceId={serviceId}
            physioId={physiotherapist}
          />
          <InfoTile status="info" content={<Text>{t("T01303")}</Text>} />
        </View>
        {service?.[0]?.serviceItem.freeService && (
          <InfoTile status="info" content={<Text>{t("T01527")}</Text>} />
        )}
        <AppointmentDetailsSections
          physiotherapistId={physiotherapist}
          serviceType={serviceType}
          dateFrom={dateFrom}
          languageLabel={languageLabel}
        />
        {freeService ? (
          <Divider bold />
        ) : (
          <AcceptablePaymentsSection physiotherapistId={physiotherapist} />
        )}
        <TherapistAccountTypeSection physiotherapistId={physiotherapist} />
        <View style={styles.checkboxContainer}>
          <PrivacyAndTermsAgreement
            name="privacyCheckbox"
            control={control as never as Control}
          />
          <CheckboxSimpleText
            name="returnsPolicyCheckbox"
            control={control as never as Control}
            text="T00978"
          />
        </View>
      </ScrollView>
      {isPaymentMethodsLoading ? (
        <ActivityIndicator />
      ) : isPaymentMethodsError ? (
        <FetchError action={refetchPaymentMethods} coverScreen={false} />
      ) : (
        <AbsoluteBlurredFooter buttons={buttons} onLayout={setFooterHeight} />
      )}
    </SafeAreaView>
  );
};

export default ScheduledAppointmentSummary;

const styles = StyleSheet.create({
  checkboxContainer: {
    ...globalStyles.gapMedium,
    marginTop: spacing16,
  },
});
