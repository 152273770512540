import { useQuery } from "@tanstack/react-query";
import { getAssistant } from "@api/common";
import { useEffect, useState } from "react";
import { useErrors } from "@hooks/useErrors";
import { AxiosError } from "axios";

const assistantQueryKey = ["Assistant"];

type AssistantHook = {
  assistantId: number | undefined;
  isAssistantId: boolean;
};

export const useAssistant = (userId?: number): AssistantHook => {
  const { setErrorsFromResponse } = useErrors();
  const { data: assistantId } = useQuery({
    queryKey: assistantQueryKey,
    queryFn: getAssistant,
    cacheTime: Infinity,
    select: data => data.data.fixmeUserId,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const [isAssistantId, setIsAssistantId] = useState(false);

  useEffect(() => {
    setIsAssistantId(userId === assistantId);
  }, [assistantId, userId]);

  return { assistantId, isAssistantId: isAssistantId };
};
