type Font = {
  fontFamily: string;
  fontWeight: "400" | "500" | "700";
  fontSize: number;
  lineHeight: number;
  letterSpacing: number;
};

type FontConfig = {
  [key: string]: Font;
};

export const fontConfig: FontConfig = {
  displayLarge: {
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
    fontSize: 57,
    lineHeight: 64,
    letterSpacing: -0.25,
  },
  displayMedium: {
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
    fontSize: 45,
    lineHeight: 52,
    letterSpacing: 0,
  },
  displaySmall: {
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
    fontSize: 36,
    lineHeight: 44,
    letterSpacing: 0,
  },
  headlineLarge: {
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
    fontSize: 32,
    lineHeight: 40,
    letterSpacing: 0,
  },
  headlineMedium: {
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
    fontSize: 28,
    lineHeight: 36,
    letterSpacing: 0,
  },
  headlineSmall: {
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
    fontSize: 24,
    lineHeight: 32,
    letterSpacing: 0,
  },
  bodyLarge: {
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.5,
  },
  bodyMedium: {
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.25,
  },
  bodySmall: {
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: 0.4,
  },
  labelLarge: {
    fontFamily: "Poppins-Medium",
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.101,
  },
  labelMedium: {
    fontFamily: "Poppins-Medium",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: 0.5,
  },
  labelSmall: {
    fontFamily: "Poppins-Medium",
    fontWeight: "500",
    fontSize: 11,
    lineHeight: 16,
    letterSpacing: 0.5,
  },
  titleLarge: {
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
    fontSize: 22,
    lineHeight: 28,
    letterSpacing: 0,
  },
  titleMedium: {
    fontFamily: "Poppins-Medium",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.15,
  },
  titleSmall: {
    fontFamily: "Poppins-Medium",
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.101,
  },
  titleSmallBolded: {
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.25,
  },
};
