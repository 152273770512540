import { PrimaryButton } from "@components/buttons";
import { Dispatch, FC, SetStateAction } from "react";
import { StyleSheet, View } from "react-native";
import { Purchase, Subscription, SubscriptionIOS } from "react-native-iap";
import { Text } from "react-native-paper";

import { globalStyles } from "@styles/global";
import { useTranslation } from "react-i18next";

type Props = {
  subscriptions: Subscription[];
  purchaseHistory: Purchase[];
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  handleBuySubscription: (sku: string, offerToken?: string) => Promise<void>;
  goBack: () => void;
  handleGetPurchaseHistory: () => void;
};

export const AppleSubscription: FC<Props> = ({
  subscriptions,
  purchaseHistory,
  loading,
  setLoading,
  handleBuySubscription,
  goBack,
  handleGetPurchaseHistory,
}) => {
  const { t } = useTranslation();
  const { box, specialTag, subTitle, subPrice, subDetailsContainer } = styles;

  const onPressButton = (pid: string) => {
    setLoading(prevState => !prevState);
    void handleBuySubscription(pid);
    void handleGetPurchaseHistory();
  };

  return (
    <View style={{ marginTop: 10 }}>
      {subscriptions.map((subscription: SubscriptionIOS, index) => {
        const owned = purchaseHistory.find(
          s => s?.productId === subscription.productId,
        );

        return (
          <View style={box} key={index}>
            {subscription?.introductoryPriceSubscriptionPeriodIOS && (
              <>
                <Text style={specialTag}>SPECIAL OFFER</Text>
              </>
            )}
            <View style={subDetailsContainer}>
              <Text style={subTitle}>{subscription?.title}</Text>
              <Text style={subPrice}>{subscription?.localizedPrice}</Text>
            </View>
            {subscription?.introductoryPriceSubscriptionPeriodIOS && (
              <Text>
                Free for 1{" "}
                {subscription?.introductoryPriceSubscriptionPeriodIOS}
              </Text>
            )}
            <Text style={{ paddingBottom: 20 }}>
              {subscription?.description}
            </Text>
            {!loading && !owned && (
              <PrimaryButton
                onPress={() => onPressButton(subscription.productId)}
                label="T00735"
              />
            )}
            {owned && (
              <View style={globalStyles.gapLarge}>
                <Text variant="bodyMedium">{t("T00736")}</Text>
                <PrimaryButton
                  label="T00737"
                  onPress={goBack}
                  mode="outlined"
                />
              </View>
            )}
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  subTitle: {
    paddingBottom: 10,
    fontWeight: "bold",
    fontSize: 18,
    textTransform: "uppercase",
  },
  subPrice: {
    paddingBottom: 20,
    fontWeight: "bold",
    fontSize: 18,
  },
  box: {
    margin: 10,
    marginBottom: 5,
    padding: 10,
    backgroundColor: "white",
    borderRadius: 7,
    shadowColor: "rgba(0, 0, 0, 0.45)",
    shadowOffset: { height: 16, width: 0 },
    shadowOpacity: 0.1,
    shadowRadius: 12,
  },
  specialTag: {
    color: "white",
    backgroundColor: "crimson",
    width: 125,
    padding: 4,
    fontWeight: "bold",
    fontSize: 12,
    borderRadius: 7,
    marginBottom: 2,
  },
  subDetailsContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
});
