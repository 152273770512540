import { isIOS, isANDROID } from "@utils/constants";
import { useState, useMemo } from "react";
import { useKeyboard } from "./useKeyboard";

export const useDisplayBlurredFooter = () => {
  const [footerHeight, setFooterHeight] = useState(0);
  const keyboardHeight = useKeyboard();
  const displayBlurredFooter = useMemo(
    () => isIOS || (isANDROID && !keyboardHeight),
    [keyboardHeight],
  );
  return {
    footerHeight,
    setFooterHeight,
    keyboardHeight,
    displayBlurredFooter,
  };
};
