import { yupResolver } from "@hookform/resolvers/yup";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, PropsWithChildren } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { object, string } from "yup";

import { Input } from "@components/Input";
import { PrimaryButton } from "@components/buttons";
import { FetchError } from "@components/errors";

import { useErrors } from "@hooks/useErrors";
import { ProfileCompletionStackParamList } from "@navigators/navigation.types";
import {
  addUserDetails,
  getUserDetails,
  updateUserDetails,
} from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { UserProfileDetails } from "./patientCompletion.types";
import { queryKeysPatientCompletion } from "./queryKeysPatientCompletion";
import { isIOS } from "@utils/constants";
import { useInvalidateProfileDetails } from "@hooks/index";
import { showProfileVerificationSnackbar } from "@components/snackbars";

const WeightAndHeight: FC<
  PropsWithChildren<
    NativeStackScreenProps<ProfileCompletionStackParamList, "WeightAndHeight">
  >
> = ({ navigation: { goBack } }) => {
  const { t } = useTranslation();
  const { setErrorsFromResponse, errors } = useErrors();
  const { invalidateProfileDetails } = useInvalidateProfileDetails();

  const {
    data: userData,
    isLoading: isQueryLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: queryKeysPatientCompletion.details(),
    queryFn: async () => {
      const data = await getUserDetails();
      return data.length ? data[0] : null;
    },
    onSuccess: data => {
      if (data !== null) {
        setValue("weight", data.weight);
        setValue("height", data.height);
      }
    },
  });
  const editionMode = !!userData?.id;

  const schemaType = string().required(t("T00014"));
  const schema = object().shape({
    weight: schemaType,
    height: schemaType,
  });

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: UserProfileDetails) =>
    mutate({ weight: data.weight, height: data.height });

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: UserProfileDetails) =>
      editionMode ? updateUserDetails(userData.id, data) : addUserDetails(data),
    onSuccess: async () => {
      await invalidateProfileDetails();
      goBack();
      showProfileVerificationSnackbar(t("T01311"));
    },
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const { loading, scrollContainer, gapLarge } = globalStyles;

  if (isQueryLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <ScrollView contentContainerStyle={[scrollContainer, gapLarge]}>
      <Text variant="bodyMedium">{t("T00360")}</Text>
      <Text variant="bodyMedium">{t("T00361")}</Text>
      <Input
        name="weight"
        label="T00358"
        control={control as unknown as Control<FieldValues>}
        keyboardType={isIOS ? "numbers-and-punctuation" : "numeric"}
        errors={errors?.weight}
      />
      <Input
        name="height"
        label="T00359"
        control={control as unknown as Control<FieldValues>}
        keyboardType="number-pad"
        errors={errors?.height}
      />
      <PrimaryButton
        label="T00164"
        onPress={handleSubmit(onSubmit)}
        loading={isLoading}
        disabled={isLoading}
      />
    </ScrollView>
  );
};

export default WeightAndHeight;
