import { MedicalRecordTile } from "@components/Tile";
import InfoTile from "@components/Tile/InfoTile";
import { FetchError } from "@components/errors";
import { TransKey, TranslationKey } from "@globalTypes/i18next";
import { useAppointmentData, useUserDetails } from "@hooks/index";
import { MedicalRecordsSimpleGet } from "@screens/Profiles/MyProfile/MedicalRecords/MedicalRecords.types";
import { queryKeysMedicalRecord } from "@screens/Profiles/MyProfile/MedicalRecords/queryKeysMedicalRecord";
import { getMedicalRecords } from "@services/ApiService/medicalRecords";
import { globalStyles } from "@styles/global";
import { spacing16 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ListRenderItem,
  RefreshControl,
  SectionList,
  View,
} from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";

type SectionType = {
  title: TransKey;
  data: MedicalRecordsSimpleGet[];
};

type MedicalRecordsListForAppointmentDetailsAndSessionProps = {
  appointmentId: number;
  onTilePress: (id: number) => void;
  sectionListTitle: TranslationKey;
};

export const MedicalRecordsListForAppointmentDetailsAndSession: FC<
  PropsWithChildren<MedicalRecordsListForAppointmentDetailsAndSessionProps>
> = ({ appointmentId, onTilePress, sectionListTitle }) => {
  const { t } = useTranslation();
  const { isPatient } = useUserDetails();
  const {
    data: appointment,
    isLoading: isAppointmentLoading,
    isError: isAppointmentError,
    refetch: refetchAppointment,
  } = useAppointmentData(appointmentId);
  const sharedWithId = isPatient
    ? appointment?.physiotherapist
    : appointment?.patient;
  const { sharedBetweenMeAndOtherUserList, createdForList } =
    queryKeysMedicalRecord;
  const {
    data: medicalRecords,
    isLoading: isMedicalRecordsLoading,
    isError: isMedicalRecordsError,
    refetch: refetchMedicalRecords,
  } = useQuery({
    queryKey: sharedBetweenMeAndOtherUserList(sharedWithId),
    queryFn: async () =>
      await getMedicalRecords({ sharedWithYouByUser: sharedWithId }),
    enabled: Boolean(sharedWithId),
  });

  const {
    data: medicalRecordsCreatedFor,
    isLoading: isMedicalRecordsCreatedForLoading,
    isError: isMedicalRecordsCreatedForError,
    refetch: refetchMedicalRecordsCreatedFor,
  } = useQuery({
    queryKey: createdForList(sharedWithId),
    queryFn: async () => await getMedicalRecords({ createdFor: sharedWithId }),
    enabled: Boolean(sharedWithId),
  });

  const refetchQueries = async () =>
    await Promise.all([
      refetchAppointment(),
      refetchMedicalRecords(),
      refetchMedicalRecordsCreatedFor(),
    ]);

  const { gapMedium, gapLarge, loading } = globalStyles;

  const sections: SectionType[] = useMemo(
    () => [
      {
        title: isPatient ? "T01228" : "T01229",
        data: medicalRecords,
      },
      {
        title: isPatient ? "T01226" : "T01227",
        data: medicalRecordsCreatedFor,
      },
    ],
    [isPatient, medicalRecords, medicalRecordsCreatedFor],
  );

  const renderMedicalRecord: ListRenderItem<MedicalRecordsSimpleGet> =
    useCallback(
      ({ item: { id } }) => (
        <MedicalRecordTile
          recordId={id}
          sharedToInfo
          onPress={() => onTilePress(id)}
        />
      ),
      [onTilePress],
    );

  const renderSectionHeader = ({
    section: { title, data },
  }: {
    section: {
      title: TranslationKey;
      data: MedicalRecordsSimpleGet[];
    };
  }) => (
    <View style={gapMedium}>
      <Text variant="titleMedium">{`${t(title)}:`}</Text>
      {!data.length && <Text variant="bodyMedium">{t("T00325")}</Text>}
    </View>
  );

  const anyLoading = useMemo(
    () =>
      isMedicalRecordsLoading ||
      isAppointmentLoading ||
      isMedicalRecordsCreatedForLoading,
    [
      isAppointmentLoading,
      isMedicalRecordsCreatedForLoading,
      isMedicalRecordsLoading,
    ],
  );

  const isAnyError = useMemo(
    () =>
      isMedicalRecordsError ||
      isAppointmentError ||
      isMedicalRecordsCreatedForError,
    [
      isAppointmentError,
      isMedicalRecordsCreatedForError,
      isMedicalRecordsError,
    ],
  );

  const renderEmptyComponent = useMemo(
    () => <Text variant="bodyMedium">{t("T00325")}</Text>,
    [t],
  );

  const renderListHeaderComponent = useMemo(
    () => (
      <View style={gapLarge}>
        <Text variant="titleLarge">{t(sectionListTitle)}</Text>
        <Divider />
        <InfoTile
          status="info"
          content={
            <Text variant="bodyMedium">
              {t(`${isPatient ? "T00851" : "T00850"}`)}
            </Text>
          }
        />
      </View>
    ),
    [gapLarge, isPatient, sectionListTitle, t],
  );

  if (anyLoading) return <ActivityIndicator style={loading} />;

  if (isAnyError) return <FetchError action={refetchQueries} />;

  return (
    <SectionList
      sections={
        !medicalRecords.length && !medicalRecordsCreatedFor.length
          ? []
          : sections
      }
      keyExtractor={({ id }, index) => `medicalrecord-item-${id}-${index}`}
      renderItem={renderMedicalRecord}
      renderSectionHeader={renderSectionHeader}
      ListEmptyComponent={renderEmptyComponent}
      ListHeaderComponent={renderListHeaderComponent}
      contentContainerStyle={[gapLarge, { padding: spacing16 }]}
      refreshControl={
        <RefreshControl refreshing={anyLoading} onRefresh={refetchQueries} />
      }
    />
  );
};
