import { TransKey } from "@globalTypes/i18next";

export const booleanButtons: { value: string; label: TransKey }[] = [
  {
    value: "No",
    label: "T00574",
  },
  {
    value: "Yes",
    label: "T00575",
  },
];
