import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState, useEffect } from "react";

export const SHOULD_SHOW_WHAT_CAN_YOU_EXPECT =
  "SHOULD_SHOW_WHAT_CAN_YOU_EXPECT";

export const useGetShowWhatCanYouExpectStatus = (isFocused: boolean) => {
  const [showWhatCanYouExpect, setShowWhatCanYouExpect] = useState(true);

  useEffect(() => {
    getShowWhatCanYouExpectStatus().catch(err => console.error(err));
  }, [isFocused]);

  const getShowWhatCanYouExpectStatus = async () => {
    try {
      const value = await AsyncStorage.getItem(SHOULD_SHOW_WHAT_CAN_YOU_EXPECT);
      value && setShowWhatCanYouExpect(value !== "true");
    } catch (error) {
      console.error(error);
    }
  };

  return showWhatCanYouExpect;
};
