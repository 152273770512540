import { useErrors } from "@hooks/useErrors";
import { queryKeysAppointments } from "@screens/Appointments/queryKeysAppointments";
import { getAppointmentsListWithInterlocutor } from "@services/ApiService/appointments";
import { useQuery } from "@tanstack/react-query";
import { formatDateForApi } from "@utils/date";
import { AxiosError } from "axios";

export const useUpcomingAppointment = (interlocutorId: number) => {
  const { setErrorsFromResponse } = useErrors();
  const todayDate = formatDateForApi(new Date());
  return useQuery({
    queryKey:
      queryKeysAppointments.listAppointmentsWithInterlocutor(interlocutorId),
    queryFn: async () =>
      await getAppointmentsListWithInterlocutor(todayDate, interlocutorId),
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
    enabled: !!interlocutorId,
    select: data =>
      data?.filter(
        ({ cancelled, dateTo }) =>
          !cancelled && new Date(dateTo).getTime() > new Date().getTime(),
      ),
  });
};
