import AppointmentDetailsSections from "@components/Appointment/AppointmentDetailsSections";
import { ButtonBasic } from "@components/Button/Button.types";
import { AbsoluteBlurredFooter } from "@components/Footers";
import InfoTile from "@components/Tile/InfoTile";
import ServiceTileWithQuery from "@components/Tile/service/ServiceTileWithQuery";
import { PrimaryButton } from "@components/buttons";
import { PhysiotherapistCardWithRating } from "@components/cards";
import { AcceptablePaymentsSection } from "@components/index";
import { ScheduleAppointmentStackParamsList } from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { globalStyles } from "@styles/global";
import { showAlertWithCustomButtons } from "@utils/showAlert";
import { formatISO } from "date-fns";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { AppointmentContext } from "../appointment.context";
import { usePhysioServiceDetails } from "@hooks/queryHooks/usePhysioServiceDetails";
import { FetchError } from "@components/errors";
import { useFlags } from "@hooks/useFlags";

type ScheduleAppointmentScreen = PropsWithChildren<
  NativeStackScreenProps<
    ScheduleAppointmentStackParamsList,
    "ScheduleAppointment"
  >
>;

const ScheduleAppointment: FC<ScheduleAppointmentScreen> = ({
  route,
  navigation: { navigate },
}) => {
  const [footerHeight, setFooterHeight] = useState(0);
  const { t } = useTranslation();
  const { updateData } = useContext(AppointmentContext);
  const {
    flags: { FREE_SERVICE_ENABLED },
  } = useFlags();

  const {
    physiotherapistId,
    date,
    serviceId,
    serviceType,
    appointmentLanguage,
    languageLabel,
  } = route.params;

  const { gapLarge, scrollContainer } = globalStyles;

  const {
    data: service,
    isLoading,
    isError,
    refetch,
  } = usePhysioServiceDetails({
    physioId: physiotherapistId,
    serviceId,
    serviceType,
    queryEnabled: FREE_SERVICE_ENABLED,
  });
  const freeService =
    FREE_SERVICE_ENABLED &&
    service?.length &&
    service[0]?.serviceItem.freeService;

  const navigateToSurvey = useCallback(
    () => navigate("PlaceOfInjury"),
    [navigate],
  );

  const footerButtons: ButtonBasic[] = useMemo(
    () => [
      {
        key: "skipSurvey",
        onPress: () =>
          showAlertWithCustomButtons({
            title: t("T01216"),
            message: t("T01219"),
            cancelButton: {
              text: t("T01217"),
              onPress: navigateToSurvey,
            },
            confirmButton: {
              text: t("T01218"),
              onPress: () =>
                navigate("ScheduledAppointmentSummary", {
                  surveyOmitted: true,
                }),
            },
          }),
        label: "T01215",
        mode: "outlined",
      },
      {
        key: "placeOfInjury",
        onPress: navigateToSurvey,
        label: "T00279",
      },
    ],
    [navigate, navigateToSurvey, t],
  );

  useEffect(
    () =>
      updateData({
        dateFrom: formatISO(new Date(date)),
        physiotherapist: physiotherapistId,
        serviceId,
        serviceType,
        appointmentLanguage,
        languageLabel,
      }),
    [
      languageLabel,
      date,
      physiotherapistId,
      updateData,
      serviceId,
      serviceType,
      appointmentLanguage,
    ],
  );

  return (
    <SafeAreaView edges={["bottom", "left", "right"]}>
      <ScrollView
        contentContainerStyle={[
          scrollContainer,
          gapLarge,
          { paddingBottom: footerHeight },
        ]}>
        <PhysiotherapistCardWithRating
          initialData={{ id: physiotherapistId }}
        />
        <View>
          <Text variant="titleMedium">{t("T00583")}:</Text>
          <ServiceTileWithQuery
            serviceType={serviceType}
            serviceId={serviceId}
            physioId={physiotherapistId}
          />
        </View>
        <PrimaryButton
          label="T00606"
          onPress={() =>
            navigate("PhysiotherapistCalendarForPatient", {
              physiotherapistId,
            })
          }
        />
        <InfoTile
          content={<Text variant="bodyMedium">{t("T00599")}</Text>}
          status="info"
        />
        <AppointmentDetailsSections
          physiotherapistId={physiotherapistId}
          serviceType={serviceType}
          dateFrom={date}
          languageLabel={languageLabel}
        />
        {FREE_SERVICE_ENABLED &&
        (isLoading ? (
          <ActivityIndicator />
        ) : isError ? (
          <FetchError action={refetch} coverScreen={false} />
        ) : (
          freeService
        )) ? (
          <Divider bold />
        ) : (
          <AcceptablePaymentsSection physiotherapistId={physiotherapistId} />
        )}
      </ScrollView>
      <AbsoluteBlurredFooter
        onLayout={height => setFooterHeight(height)}
        buttons={footerButtons}
      />
    </SafeAreaView>
  );
};

export default ScheduleAppointment;
