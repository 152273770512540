import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import OneSignal from "react-native-onesignal";

import {
  login,
  refreshToken,
  register,
  signInWithApple,
  signInWithGoogle,
  singInWithFacebook,
  validateAccessToken,
} from "@services/ApiService/auth";
import { useQueryClient } from "@tanstack/react-query";
import { isANDROID, isIOS, isWeb } from "@utils/constants";
import { getLang } from "@utils/lang";
import { storageManager } from "@utils/storageManager";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import { AccessToken, LoginManager } from "react-native-fbsdk-next";
import { v4 as uuid } from "uuid";
import { convertTokenToUserData } from "../../utils";
import {
  ProfileStatus,
  SignInWithApple,
  User,
  UserAuthenticationData,
  UserTokensFromApi,
} from "./user.types";
import { loginWithAppleWeb } from "./auth.helpers.web";
import { useAnalytics } from "@hooks/index";

type AppleAuthType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("@invertase/react-native-apple-authentication").appleAuth;
type GoogleSigninType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("@react-native-google-signin/google-signin").GoogleSignin;
type googleStatusCodesType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("@react-native-google-signin/google-signin").statusCodes;

type AppleAuthAndroid = Platform["OS"] extends "web"
  ? unknown
  : typeof import("@invertase/react-native-apple-authentication").appleAuthAndroid;

let appleAuth: AppleAuthType;
let GoogleSignin: GoogleSigninType;
let googleStatusCodes: googleStatusCodesType;
let appleAuthAndroid: AppleAuthAndroid;

if (!isWeb) {
  appleAuthAndroid = (
    require("@invertase/react-native-apple-authentication") as typeof import("@invertase/react-native-apple-authentication")
  ).appleAuthAndroid;
  appleAuth = (
    require("@invertase/react-native-apple-authentication") as typeof import("@invertase/react-native-apple-authentication")
  ).appleAuth;
  GoogleSignin = (
    require("@react-native-google-signin/google-signin") as typeof import("@react-native-google-signin/google-signin")
  ).GoogleSignin;
  googleStatusCodes = (
    require("@react-native-google-signin/google-signin") as typeof import("@react-native-google-signin/google-signin")
  ).statusCodes;
}

type AuthContextProps = {
  user: User | null;
  signIn: (userCredentials: UserAuthenticationData) => Promise<void>;
  signUp: (userCredentials: UserAuthenticationData) => Promise<void>;
  appleLogin: (redirectURI: string) => void;
  googleLogin: () => void;
  signOut: () => void;
  updateUser: () => void;
  facebookLogin: () => void;
  handleGoogleLoginSuccess: (idToken: string) => Promise<void>;
};

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps,
);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [activeUser, setActiveUser] = useState<User>(null);
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const { handleLoginAnalytics } = useAnalytics();

  const changeLang = useCallback(
    async (lang?: string) => {
      lang && (await i18n.changeLanguage(getLang(lang)));
    },
    [i18n],
  );

  useEffect(() => {
    if (activeUser?.profileStatus !== ProfileStatus.EmailUnconfirmed) {
      void changeLang(activeUser?.language);
    }
  }, [activeUser, changeLang]);

  const signIn = async (data: UserAuthenticationData) => {
    const {
      data: { access, refresh },
    } = await login(data);
    const userData = convertTokenToUserData(access);
    OneSignal.setExternalUserId(userData.id.toString());
    queryClient.clear();
    await setUser(userData, access, refresh);
  };

  const signInWithAppleAndSetUser: SignInWithApple = async (
    firstName,
    lastName,
    identityToken,
  ) => {
    let dataFromApple: AxiosResponse<{
      message: string;
      tokens: UserTokensFromApi;
    }>;
    if (!firstName && !lastName) {
      dataFromApple = await signInWithApple(identityToken);
    } else {
      dataFromApple = await signInWithApple(identityToken, firstName, lastName);
    }
    const {
      data: {
        tokens: { access, refresh },
      },
    } = dataFromApple;

    const userData = convertTokenToUserData(access);
    OneSignal.setExternalUserId(`${userData.id}`);
    queryClient.clear();
    await setUser(userData, access, refresh);
  };

  const loginWithAppleIOS = async () => {
    try {
      const { user, identityToken, fullName } = await appleAuth.performRequest({
        requestedOperation: appleAuth.Operation.LOGIN,
        requestedScopes: [appleAuth.Scope.FULL_NAME, appleAuth.Scope.EMAIL],
      });

      const credentialState = await appleAuth.getCredentialStateForUser(user);
      if (credentialState === appleAuth.State.AUTHORIZED) {
        const firstName = fullName?.givenName;
        const lastName = fullName?.familyName;
        await signInWithAppleAndSetUser(firstName, lastName, identityToken);
      } else {
        throw appleAuth.Error.FAILED;
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      switch (error.code) {
        case appleAuth.Error.CANCELED:
          alert(t("T00821"));
          break;
        case appleAuth.Error.INVALID_RESPONSE:
          alert(t("T00822"));
          break;
        case appleAuth.Error.FAILED:
          alert(t("T00823"));
          break;
        default:
          console.debug("default", error);
          alert(t("T00832"));
          break;
      }
    }
  };

  const loginWithAppleAndroid = async () => {
    const rawNonce = uuid();
    const state = uuid();
    try {
      appleAuthAndroid.configure({
        clientId: "com.fixmephysioapp.client-android",
        redirectUri: "https://www.fixmeapp.pl/",
        responseType: appleAuthAndroid.ResponseType.ALL,
        scope: appleAuthAndroid.Scope.ALL,
        nonce: rawNonce,
        state,
      });

      const response = await appleAuthAndroid.signIn();
      const firstName = response?.user?.name?.firstName;
      const lastName = response?.user?.name?.lastName;
      const identityToken = response?.id_token;
      await signInWithAppleAndSetUser(firstName, lastName, identityToken);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (error && error.message) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        switch (error.message) {
          case appleAuthAndroid.Error.NOT_CONFIGURED:
            alert(t("T00832"));
            break;
          case appleAuthAndroid.Error.SIGNIN_FAILED:
            alert(t("T00823"));
            break;
          case appleAuthAndroid.Error.SIGNIN_CANCELLED:
            alert(t("T00821"));
            break;
          default:
            break;
        }
      }
    }
  };

  const loginWebErrorHandler = (err: { error: unknown }) =>
    alert(`${t("T00160")}, ${err.error}`);

  const appleLogin = async (redirectURI: string) => {
    if (isIOS) {
      await loginWithAppleIOS();
    } else if (isANDROID) {
      await loginWithAppleAndroid();
    } else if (isWeb) {
      await loginWithAppleWeb(
        redirectURI,
        signInWithAppleAndSetUser,
        loginWebErrorHandler,
      );
    } else {
      return void 0;
    }
  };

  const handleGoogleLoginSuccess = async (idToken: string) => {
    const {
      data: {
        tokens: { access, refresh },
      },
    } = await signInWithGoogle(idToken);
    const userData = convertTokenToUserData(access);
    OneSignal.setExternalUserId(userData.id.toString());
    queryClient.clear();
    await setUser(userData, access, refresh);
  };

  const googleLogin = async () => {
    if (!isWeb) {
      try {
        await GoogleSignin.hasPlayServices();
        const { idToken } = await GoogleSignin.signIn();
        await handleGoogleLoginSuccess(idToken);
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        switch (error.code) {
          case googleStatusCodes.SIGN_IN_CANCELLED:
            alert(t("T00829"));
            break;
          case googleStatusCodes.PLAY_SERVICES_NOT_AVAILABLE:
            alert(t("T00830"));
            break;
          case googleStatusCodes.IN_PROGRESS:
            alert(t("T00831"));
            break;
          default:
            alert(t("T00832"));
            break;
        }
      }
    } else {
      return void 0;
    }
  };

  const facebookLogin = async () => {
    try {
      const result = await LoginManager.logInWithPermissions([
        "public_profile",
        "email",
      ]);
      if (result.isCancelled) {
        return alert(t("T00829"));
      }
      await AccessToken.getCurrentAccessToken().then(
        async ({ accessToken }) => {
          const {
            data: {
              tokens: { access, refresh },
            },
          } = await singInWithFacebook(accessToken);

          const userData = convertTokenToUserData(access);
          OneSignal.setExternalUserId(`${userData.id}`);
          queryClient.clear();
          await setUser(userData, access, refresh);
        },
      );
    } catch (error: unknown) {
      console.debug({ error });
      alert(t("T00832"));
    }
  };

  const signUp = async (data: UserAuthenticationData) => {
    const {
      data: { access, refresh },
    } = await register(data);
    const userData = convertTokenToUserData(access);
    OneSignal.setExternalUserId(userData.id.toString());
    await setUser(userData, access, refresh);
  };

  const setUser = async (
    userData: User,
    accessToken: string,
    refreshToken: string,
  ) => {
    await storageManager.setItem("accessToken", accessToken);
    await storageManager.setItem("refreshToken", refreshToken);

    await handleLoginAnalytics(`${userData.id}`);
    setActiveUser(userData);
  };

  const updateUser = useCallback(async () => {
    const token = await storageManager.getItem("refreshToken");
    const { access, refresh } = await refreshToken(token);
    const userData = convertTokenToUserData(access);
    await setUser(userData, access, refresh);
  }, []);

  const signOut = useCallback(async () => {
    queryClient.clear();

    await handleLoginAnalytics(null);

    storageManager
      .clear()
      .then(() => {
        setActiveUser(null);
        OneSignal.removeExternalUserId();
      })
      .catch(() => {
        /**/
      });
  }, [queryClient]);

  const autoSignIn = useCallback((token: UserTokensFromApi["access"]) => {
    const userData = convertTokenToUserData(token);
    OneSignal.setExternalUserId(userData.id.toString());
    setActiveUser(userData);
  }, []);

  const onValidateFail = useCallback(
    () =>
      storageManager
        .getItem("refreshToken")
        .then(refreshToken)
        .then(({ access }) => autoSignIn(access))
        .catch(signOut),

    [signOut, autoSignIn],
  );

  const validateToken = useCallback(
    (accessToken: UserTokensFromApi["access"]) => {
      validateAccessToken(accessToken)
        .then(() => autoSignIn(accessToken))
        .catch(onValidateFail);
    },
    [onValidateFail, autoSignIn],
  );

  useEffect(() => {
    storageManager
      .getItem("accessToken")
      .then(token => token && validateToken(token))
      .catch(onValidateFail);
  }, [onValidateFail, validateToken]);

  return (
    <AuthContext.Provider
      value={{
        user: activeUser,
        signIn,
        signOut,
        signUp,
        updateUser,
        appleLogin,
        googleLogin,
        facebookLogin,
        handleGoogleLoginSuccess,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const auth = useContext(AuthContext);
  if (auth.user === undefined) {
    throw new Error("useAuthContext can only be used inside AuthProvider");
  }
  return auth;
};
