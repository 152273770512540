import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = ({
  iconColor = "#fff",
  ...props
}: SvgProps & { iconColor: string }) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill={iconColor}
      d="M14.92 11.1V5.5c0-1.66-1.34-3-3-3-1.54 0-2.79 1.16-2.96 2.65l5.96 5.95Zm3.08.4c-.41 0-.77.3-.83.71-.05.32-.12.64-.22.93l1.27 1.27c.3-.6.52-1.25.63-1.94a.857.857 0 0 0-.85-.97ZM3.63 4.06a.996.996 0 0 0 0 1.41l5.29 5.3v.43c0 1.19.6 2.32 1.63 2.91.75.43 1.41.44 2.02.31l1.66 1.66c-.71.33-1.5.52-2.31.52-2.54 0-4.88-1.77-5.25-4.39a.839.839 0 0 0-.83-.71c-.52 0-.92.46-.85.97.46 2.96 2.96 5.3 5.93 5.75v2.28c0 .55.45 1 1 1s1-.45 1-1v-2.28a7.13 7.13 0 0 0 2.55-.9l3.49 3.49a.996.996 0 1 0 1.41-1.41L5.04 4.06a.996.996 0 0 0-1.41 0Z"
    />
  </Svg>
);
export default SvgComponent;
