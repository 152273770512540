import { TransKey } from "@globalTypes/i18next";
import { ScreenType } from "@screens/Common/EnterInvitationOrPromotionCode";
import { AnimationObject } from "lottie-react-native";

export const useInvitationOrPromotionCodeContent = (
  screenType: ScreenType,
  isPatient?: boolean,
) => {
  let animation: AnimationObject;
  let initialText: TransKey;
  let firstText: TransKey;
  let secondText: TransKey;
  let extraHelperText: TransKey;
  let buttonLabel: TransKey;

  switch (screenType) {
    case "invitationCode":
      animation =
        require("@assets/animations/promo_codes_and_invitations/AnimRefEnvelopeLogo.json") as AnimationObject;
      firstText = isPatient ? "T01428" : "T01429";
      extraHelperText = "T01431";
      buttonLabel = "T01177";
      if (isPatient) {
        initialText = "T01427";
        secondText = "T01430";
      }
      break;
    case "promotionCode":
      animation =
        require("@assets/animations/promo_codes_and_invitations/AnimRefGiftLogo.json") as AnimationObject;
      firstText = "T01432";
      secondText = "T01433";
      buttonLabel = "T01325";
      break;
  }

  return {
    animation,
    initialText,
    firstText,
    secondText,
    extraHelperText,
    buttonLabel,
  };
};
