import { TransKey } from "@globalTypes/i18next";

export const snackbarText: TransKey[] = [
  "T01030",
  "T01031",
  "T01032",
  "T01033",
  "T01034",
];

export const getSnackbarText = () => {
  const randomTextIndex = Math.floor(Math.random() * snackbarText.length);
  return snackbarText[randomTextIndex];
};
