import { PrimaryButton } from "@components/buttons";
import PermissionModal from "@components/Modals/PermissionModal";
import { AppointmentSessionContext } from "@contexts/AppointmentSessionContext/appointmentSession.context";
import { PermissionType } from "@globalTypes/common.types";
import { useCheckAndRequestMicAndCamPermissions } from "@hooks/permissions/useCheckAndRequestMicAndCamPermissions";
import { useErrors } from "@hooks/useErrors";
import { Appointment } from "@screens/Appointments/appointment.types";
import { queryKeysSessions } from "@screens/Appointments/queryKeysAppointments";
import { joinAppointment } from "@services/ApiService/appointments";
import { useQuery } from "@tanstack/react-query";
import { isWeb } from "@utils/constants";
import { AxiosError } from "axios";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  appointmentDetails: Appointment;
  appointmentId: number;
  isPhysiotherapist: boolean;
};

export const JoinOnlineAppointmentButton: FC<Props> = ({
  appointmentDetails,
  appointmentId,
  isPhysiotherapist,
}) => {
  const { data, updateData } = useContext(AppointmentSessionContext);
  const { setErrorsFromResponse: setSessionErrors } = useErrors();
  const { t } = useTranslation();
  const [showPermissionModalVisible, setShowPermissionModalVisible] =
    useState(false);

  const { isLoading, data: sessionData } = useQuery({
    queryKey: queryKeysSessions.sessionDetails(appointmentId),
    queryFn: async () => await joinAppointment(appointmentId),
    onError: ({ response }: AxiosError) => setSessionErrors(response),
  });

  const { requestMicAndCamPermissions, checkMicAndCamPermissionGranted } =
    useCheckAndRequestMicAndCamPermissions();

  const joinAppointmentSession = () => {
    const dateFrom = new Date(appointmentDetails?.dateFrom);
    const dateTo = new Date(appointmentDetails?.dateTo);
    const currentDate = new Date();
    const bufforTime = 15 * 60 * 1000;

    if (!__DEV__) {
      if (
        dateFrom > currentDate &&
        dateFrom.getTime() - currentDate.getTime() > bufforTime
      ) {
        return alert(
          t("T00778", {
            value: dateFrom.toLocaleString(),
          }),
        );
      } else if (dateTo.getTime() + bufforTime < currentDate.getTime()) {
        return alert(t("T00779"));
      }
    }
    updateData({
      sessionEnabled: true,
      appointmentId,
    });
  };

  const hidePermissionModalAndJoinSession = () => {
    setShowPermissionModalVisible(false);
    joinAppointmentSession();
  };

  const onPressConfirm = () =>
    requestMicAndCamPermissions(hidePermissionModalAndJoinSession);

  const onPressJoinSession = async () => {
    if (isWeb) {
      joinAppointmentSession();
    } else {
      const hasAllPermissions = await checkMicAndCamPermissionGranted();
      hasAllPermissions
        ? joinAppointmentSession()
        : setShowPermissionModalVisible(true);
    }
  };

  useEffect(() => {
    if (sessionData?.token) {
      updateData({
        sessionToken: sessionData?.token,
      });
    }
  }, [sessionData?.token, updateData]);

  const isAppointmentOnline = appointmentDetails?.serviceType === "Online";
  const paymentSuccessful =
    appointmentDetails?.payment?.paymentStatus === "succeeded";
  const isAppoinmentComing =
    new Date(appointmentDetails?.dateTo).getTime() > new Date().getTime();

  const isDisabled = useMemo(
    () =>
      isLoading ||
      (isPhysiotherapist && !paymentSuccessful) ||
      !data.sessionToken,
    [data?.sessionToken, isLoading, isPhysiotherapist, paymentSuccessful],
  );

  if (
    __DEV__ ||
    (isAppointmentOnline &&
      paymentSuccessful &&
      isAppoinmentComing &&
      !data.displayInFloatingView)
  ) {
    return (
      <>
        <PrimaryButton
          label="T00152"
          onPress={onPressJoinSession}
          loading={isLoading}
          disabled={isDisabled}
        />
        <PermissionModal
          isModalVisible={showPermissionModalVisible}
          setIsModalVisible={setShowPermissionModalVisible}
          onPressConfirm={onPressConfirm}
          type={PermissionType.cameraAndMicrophone}
          onPressNotNowCallback={joinAppointmentSession}
        />
      </>
    );
  } else {
    return null;
  }
};
