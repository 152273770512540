import { SearchListItem } from "@components/ListItems";
import { FetchError } from "@components/errors";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { queryKeysLanguages } from "@screens/Common/queryKeysLanguages";
import { Choice } from "@services/ApiService/api.types";
import { getLanguagesOptions } from "@services/ApiService/verifications";
import { globalStyles } from "@styles/global";
import { spacing8 } from "@styles/spacing";
import { useQuery } from "@tanstack/react-query";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FlatList, StyleProp, ViewStyle } from "react-native";
import { ActivityIndicator, Divider, Text } from "react-native-paper";

export type LanguagesModalProps = {
  searchQuery: string;
  setSelectedItems: Dispatch<SetStateAction<Choice[]>>;
  selectedItems: Choice[];
  style?: StyleProp<ViewStyle>;
};

export const LanguagesModal: FC<LanguagesModalProps> = ({
  searchQuery,
  setSelectedItems,
  selectedItems,
  style,
}) => {
  const [languagesList, setLanguagesList] = useState<Choice[]>([]);
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const { loading, flatListSeparator, scrollContainer } = globalStyles;

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: queryKeysLanguages.options(),
    queryFn: async () => {
      const { data } = await getLanguagesOptions();
      return data.actions.post.language.choices?.map(
        ({ displayName, ...e }) => ({
          ...e,
          displayName:
            displayName.charAt(0).toUpperCase() + displayName.slice(1),
        }),
      );
    },
  });

  useEffect(() => {
    if (searchQuery.length > 0) {
      setLanguagesList(() =>
        data?.filter(({ displayName }) =>
          displayName.toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      );
    } else {
      setLanguagesList(data?.length ? data : []);
    }
  }, [data, searchQuery]);

  const onPressItem = useCallback(
    (langName: string) => {
      const langData = data?.filter(({ value }) => value === langName);

      setSelectedItems(prevState =>
        prevState.some(({ value }) => value === langName)
          ? prevState.filter(({ value }) => value !== langName)
          : [...prevState, ...langData],
      );
    },
    [setSelectedItems, data],
  );

  const renderItem = useCallback(
    ({
      item: { displayName, value },
    }: {
      item: { displayName: string; value: string | number };
    }) => {
      return (
        <SearchListItem
          title={displayName}
          checked={selectedItems.some(e => e.value === value)}
          onPress={() => onPressItem(`${value}`)}
          isPriority={value === "pl"}
        />
      );
    },
    [onPressItem, selectedItems],
  );

  if (isLoading) return <ActivityIndicator style={loading} />;
  if (isError) return <FetchError action={refetch} />;

  return (
    <FlatList
      data={languagesList}
      ItemSeparatorComponent={() => (
        <Divider
          bold
          style={[flatListSeparator, { width, marginVertical: spacing8 }]}
        />
      )}
      renderItem={renderItem}
      contentContainerStyle={[scrollContainer, { paddingTop: spacing8 }, style]}
      keyExtractor={({ value }, index) => `language-item-${value}-${index}`}
      ListEmptyComponent={<Text variant="bodyMedium">{t("T00636")}</Text>}
    />
  );
};
