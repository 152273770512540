import { View } from "react-native";
import { spacing8 } from "@styles/spacing";
import { FC, useCallback, useState } from "react";
import { chartColors } from "@styles/colors";
import { useWindowDimensions } from "@hooks/ui/useWindowDimensions";
import { getDate } from "@utils/date";
import {
  GroupedChartLegend,
  SingleChartLegend,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryStack,
} from ".";

export type BasicChartItemType = {
  label: string;
  value: number;
};

export type BasicGroupedBarItemType = {
  group: string;
  groupItems: BasicChartItemType[];
};

type Props = {
  data: BasicChartItemType[] | BasicGroupedBarItemType[];
  currentDate?: Date;
  grouped?: boolean;
};

export const BarChartNew: FC<Props> = ({
  currentDate = new Date(),
  data,
  grouped,
}) => {
  const [displayLegend, setDisplayLegend] = useState("");
  const [displayedMonth, setDisplayedMonth] = useState<string[]>([]);

  const { width } = useWindowDimensions();

  const getItemLabel = useCallback(
    ({ label, value }: BasicChartItemType) => {
      const dateToDisplay = new Date(currentDate);
      dateToDisplay.setDate(+label);
      return `${getDate(dateToDisplay)} - ${value}`;
    },
    [currentDate],
  );

  const onPressData = useCallback(
    (item: BasicChartItemType) => ({
      target: "data",
      mutation: () => {
        return setDisplayLegend(prevState => {
          const newState = getItemLabel(item);

          return prevState === newState ? "" : newState;
        });
      },
    }),
    [getItemLabel],
  );

  const onPressGroupedData = ({
    groupItems,
    group,
  }: BasicGroupedBarItemType) => ({
    target: "data",
    mutation: () => {
      return setDisplayedMonth(prevState => {
        const newState = groupItems.map(
          ({ label, value }) => `${group}.${label} - ${value}`,
        );
        const isArraysEqual =
          prevState.length === newState.length &&
          prevState.every((item, index) => item === newState[index]);
        return isArraysEqual ? [] : newState;
      });
    },
  });

  const getBarEvents = useCallback(
    (item: BasicChartItemType | BasicGroupedBarItemType, grouped?: boolean) => [
      {
        target: "data",
        eventHandlers: {
          onPressIn: () => {
            return [
              grouped
                ? onPressGroupedData(item as BasicGroupedBarItemType)
                : onPressData(item as BasicChartItemType),
            ];
          },
          onClick: () => {
            return [
              grouped
                ? onPressGroupedData(item as BasicGroupedBarItemType)
                : onPressData(item as BasicChartItemType),
            ];
          },
        },
      },
    ],
    [onPressData],
  );

  const getXTickValues = () => {
    if (grouped) {
      return (data as BasicGroupedBarItemType[])
        .map(({ group }) => group)
        .filter(e => e !== null);
    } else {
      return (data as BasicChartItemType[])
        .map(({ label }) => (+label % 2 === 0 ? null : label))
        .filter(e => e !== null);
    }
  };

  const getYTickValues = () => {
    if (!grouped) {
      const tempChartYValues = (data as BasicChartItemType[])
        .map(({ value }) => value)
        .filter(e => e !== null)
        .sort((a, b) => a - b);

      const lastYValue = tempChartYValues[tempChartYValues.length - 1];

      return [0, Math.round(lastYValue / 2), lastYValue];
    } else {
      const tempData = data as BasicGroupedBarItemType[];
      const tempChartYValues = tempData.map(
        ({ groupItems }) => groupItems[0].value,
      );

      tempData.forEach(({ groupItems }) =>
        tempChartYValues.push(groupItems[1].value),
      );

      const resultChartYValues = tempChartYValues
        .filter(e => e !== null)
        .sort((a, b) => a - b);

      const lastYValue = resultChartYValues[resultChartYValues.length - 1];

      return [
        resultChartYValues[0] === lastYValue ? 0 : resultChartYValues[0],
        Math.round(lastYValue / 2),
        lastYValue,
      ];
    }
  };

  const renderSingleBar = useCallback(
    (item: BasicChartItemType, index: number) => {
      const { label, value } = item;
      const itemLabel = getItemLabel(item);
      const shouldBeSelected = itemLabel === displayLegend;

      const valueFill = shouldBeSelected
        ? chartColors.secondarySelected
        : chartColors.primary;

      return (
        <VictoryStack key={`stack-${label}-${index}`}>
          <VictoryBar
            data={[{ x: label, y: value }]}
            barWidth={spacing8 / 1.5}
            style={{
              data: {
                fill: valueFill,
              },
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            events={getBarEvents(item)}
          />
        </VictoryStack>
      );
    },
    [getBarEvents, displayLegend, getItemLabel],
  );

  const renderGroupedBars = useCallback(
    (item: BasicGroupedBarItemType, index: number) => (
      <VictoryGroup
        key={`grouped-bars-${item.group}-${index}`}
        offset={spacing8 + 2}
        style={{ data: { width: spacing8 } }}>
        {item.groupItems.map(({ label, value }, i) => {
          const shouldBeSelected = displayedMonth.some(
            el => el === `${item.group}.${label} - ${value}`,
          );

          const firstValueFill = shouldBeSelected
            ? chartColors.primarySelected
            : chartColors.primary;
          const secondValueFill = shouldBeSelected
            ? chartColors.secondarySelected
            : chartColors.secondary;

          return (
            <VictoryBar
              key={`bar-${label}-${i}`}
              data={[{ x: item.group, y: value }]}
              style={{
                data: {
                  fill: i === 0 ? firstValueFill : secondValueFill,
                },
              }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              events={getBarEvents(item, true)}
            />
          );
        })}
      </VictoryGroup>
    ),
    [getBarEvents, displayedMonth],
  );

  return (
    <View>
      {grouped ? (
        <GroupedChartLegend displayedMonth={displayedMonth} />
      ) : (
        <SingleChartLegend displayLegend={displayLegend} />
      )}
      <VictoryChart
        width={width}
        height={width / 2}
        domainPadding={{ x: 10 }}
        padding={{ bottom: 30, right: 70, left: grouped ? 50 : 40, top: 20 }}>
        {data.map(
          (item: BasicGroupedBarItemType | BasicChartItemType, index: number) =>
            grouped
              ? renderGroupedBars(item as BasicGroupedBarItemType, index)
              : renderSingleBar(item as BasicChartItemType, index),
        )}
        <VictoryAxis tickValues={getXTickValues()} />
        <VictoryAxis dependentAxis tickValues={getYTickValues()} />
      </VictoryChart>
    </View>
  );
};
