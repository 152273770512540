import { PatientUpdateProfileDetails } from "@screens/Profiles/MyProfile/PatientCompletion";
import { api } from "./api";
import { ResponseOptions } from "./api.types";
import { endpoints } from "./endpoints";
import { Profile, ProfileUpdateType } from "@contexts/AuthContext/user.types";
import {
  CreateNoteResponse,
  EditNoteResponse,
  NoteData,
} from "@screens/Profiles/MyProfile/PersonalNotes/notes.types";
import { PersonalNoteType } from "@screens/Profiles/MyProfile/PersonalNotes/personalNotes.types";
import { AxiosRequestConfig } from "axios";

export const getProfile = (id: number) =>
  api.get<Profile>(`${endpoints.PROFILES}${id}/`);

export const profileOptions = (id: number) =>
  api.options<ResponseOptions>(`${endpoints.PROFILES}${id}/`);

export const profileUpdate = (
  data: ProfileUpdateType,
  headers: AxiosRequestConfig["headers"],
) => api.put<Profile>(`${endpoints.PROFILES}${data.id}/`, data, { headers });

export const setProfileEmail = (email: string) =>
  api.patch<{ email: string }>(endpoints.SET_EMAIL, { email });

export const createNote = (data: NoteData) =>
  api.post<CreateNoteResponse>(endpoints.NOTES, data);

export const getPersonalNoteDetails = async (id: number) => {
  const { data } = await api.get<PersonalNoteType>(endpoints.NOTE_DETAILS(id));
  return data;
};
export const getPersonalNotes = async () => {
  const { data } = await api.get<{ id: number }[]>(endpoints.NOTES);
  return data;
};

export const editNote = (id: number, data: NoteData) =>
  api.patch<EditNoteResponse[]>(endpoints.NOTE_DETAILS(id), data);

export const deleteNote = (id: number) =>
  api.delete(endpoints.NOTE_DETAILS(id));

export const resendConfirmationLink = () =>
  api.post<null>(endpoints.RESEND_CONFIRMATION_LINK);

export const updateProfile = (id: number, data: PatientUpdateProfileDetails) =>
  api.patch<PatientUpdateProfileDetails>(endpoints.PROFILE_DETAILS(id), data);
