import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { List } from "react-native-paper";
import { SvgTrainingOptionSmall } from "../../../assets/svg";
import { UserCard } from "../../components/cards";
import {
  PatientProfileStackParamsList,
  RootStackParamList,
} from "@navigators/navigation.types";
import { globalStyles } from "@styles/global";
import { spacing16, spacing8 } from "@styles/spacing";
import ReportUserButton from "@components/buttons/ReportUserButton";
import { palettes } from "@styles/colors";
import Snackbar from "@components/Snackbar/Snackbar";
import { Host } from "react-native-portalize";
import { SafeAreaView } from "react-native-safe-area-context";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import ReportBottomSheetContent from "@components/BottomSheetContents/ReportBottomSheetContent";
import { StyleSheet, View } from "react-native";
import { CompositeScreenProps } from "@react-navigation/native";

const PatientProfile: FC<
  PropsWithChildren<
    CompositeScreenProps<
      NativeStackScreenProps<PatientProfileStackParamsList, "PatientProfile">,
      NativeStackScreenProps<
        RootStackParamList,
        "RehabilitationStatisticsStack"
      >
    >
  >
> = ({ route, navigation: { navigate } }) => {
  const { id } = route.params;
  const { t } = useTranslation();
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  const handleReportSuccess = () => {
    setReportModalVisible(false);
    setSnackbarVisible(true);
  };

  const hideSnackbar = () => setSnackbarVisible(false);
  const listItems = [
    {
      title: t("T00189"),
      rightIcon: "menu-right",
      onPress: () => navigate("RehabilitationTrainings", { patientId: id }),
    },
    {
      title: t("T00504"),
      leftIcon: "equalizer",
      rightIcon: "menu-right",
      onPress: () =>
        navigate("RehabilitationStatisticsStack", {
          screen: "RehabilitationStatistics",
          params: { patientId: id },
        }),
    },
  ];
  const { safeArea, snackbar, reportBtn } = styles;
  return (
    <Host>
      <SafeAreaView edges={["bottom", "left", "right"]} style={safeArea}>
        <UserCard initialData={{ id }} mode="outlined" />
        <View style={globalStyles.gapMedium}>
          {listItems.map(({ title, leftIcon, rightIcon, onPress }, index) => (
            <List.Item
              key={index}
              title={title}
              left={
                !leftIcon
                  ? ({ style }) => <SvgTrainingOptionSmall style={style} />
                  : props => <List.Icon {...props} icon={leftIcon} />
              }
              right={props => <List.Icon {...props} icon={rightIcon} />}
              onPress={onPress}
              style={{ backgroundColor: palettes.primary[99] }}
            />
          ))}
          <ReportUserButton
            label="T00559"
            onPress={() => setReportModalVisible(true)}
            style={reportBtn}
          />
        </View>
        <BottomModalContainer
          modalVisible={reportModalVisible}
          setModalVisible={setReportModalVisible}>
          <ReportBottomSheetContent
            recipient={id}
            onSuccess={handleReportSuccess}
          />
        </BottomModalContainer>
        <Snackbar
          visible={snackbarVisible}
          onDismiss={hideSnackbar}
          onIconPress={hideSnackbar}
          text="T00560"
          style={snackbar}
        />
      </SafeAreaView>
    </Host>
  );
};

export default PatientProfile;

const styles = StyleSheet.create({
  reportBtn: { alignSelf: "flex-start", left: -spacing8 },
  snackbar: { marginHorizontal: spacing16, marginBottom: spacing16 },
  safeArea: { ...globalStyles.gapLarge, ...globalStyles.container },
});
