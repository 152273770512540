import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import { PrimaryButton } from "@components/buttons";
import { spacing32, spacing40, spacing8 } from "@styles/spacing";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { globalStyles } from "@styles/global";
import { SvgAddCircleWhite, SvgRemoveCircleWhite } from "@assets/svg";
import { theme } from "@styles/theme";
import { SeriesType } from "@screens/TrainingsAndExercises/exercise.types";
import { ButtonBasic } from "@components/Button/Button.types";
import { formatTime } from "./player.helpers";

type SeriesResultInputBottomSheetProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  type: SeriesType;
  initialValue?: number;
  onNextPressCallback: (result: number) => void;
  buttonProps?: Partial<ButtonBasic>;
};

const REPETITIONS_STEP = 1;
const TIME_STEP = 5;

const SeriesResultInputBottomSheet: FC<SeriesResultInputBottomSheetProps> = ({
  visible,
  setVisible,
  type,
  initialValue = 0,
  onNextPressCallback,
  buttonProps,
}) => {
  const [result, setResult] = useState(initialValue);
  const [displayedButtonProps, setDisplayedButtonProps] =
    useState<Partial<ButtonBasic>>(null);
  const { t } = useTranslation();
  const { container, headerText, scoreInput, scoreText } = styles;
  const [isRepetitions, setIsRepetitions] = useState(
    type === SeriesType.REPETITIONS,
  );
  const isDecrementDisabled = result <= 0;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const updateResult = () => {
      visible
        ? setResult(initialValue)
        : (timeoutId = setTimeout(() => {
            setResult(initialValue);
            setIsRepetitions(type === SeriesType.REPETITIONS);
          }, 200));
    };
    updateResult();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [initialValue, type, visible]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const updateState = () => {
      buttonProps
        ? (timeout = setTimeout(
            () => setDisplayedButtonProps(buttonProps),
            200,
          ))
        : setDisplayedButtonProps({ label: "T01249" });
    };
    updateState();

    return () => clearTimeout(timeout);
  }, [buttonProps]);

  const onNextPress = () => {
    onNextPressCallback(result);
    setVisible(false);
  };

  const increment = () =>
    setResult(prev =>
      isRepetitions ? prev + REPETITIONS_STEP : prev + TIME_STEP,
    );

  const decrement = () =>
    setResult(prev => {
      const step = isRepetitions ? REPETITIONS_STEP : TIME_STEP;
      const result = prev - step;
      return result < 0 ? 0 : result;
    });

  return (
    <BottomModalContainer modalVisible={visible} setModalVisible={setVisible}>
      <View style={container}>
        <Text variant="headlineMedium" style={headerText}>
          {t("T01248")}
        </Text>
        <View style={scoreInput}>
          <SvgRemoveCircleWhite
            onPress={decrement}
            disabled={isDecrementDisabled}
            style={{ opacity: isDecrementDisabled ? 0.5 : 1 }}
          />
          <Text variant="displayMedium" style={scoreText}>
            {isRepetitions ? `x ${result}` : formatTime(result)}
          </Text>
          <SvgAddCircleWhite onPress={increment} />
        </View>
        <PrimaryButton
          label="T01249"
          onPress={onNextPress}
          {...displayedButtonProps}
        />
      </View>
    </BottomModalContainer>
  );
};

export default SeriesResultInputBottomSheet;

const styles = StyleSheet.create({
  container: {
    marginTop: spacing32,
    ...globalStyles.gapLarge,
  },
  headerText: {
    textAlign: "center",
  },
  scoreInput: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: spacing8,
  },
  scoreText: {
    justifyContent: "flex-start",
    fontSize: spacing40,
    color: theme.colors.primary,
    marginHorizontal: spacing8,
  },
});
