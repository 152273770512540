import { FC, useState } from "react";
import { Pressable } from "react-native";
import BottomModalContainer from "@components/BottomSheet/BottomModalContainer";
import ReportContentForm from "@components/Report/ReportContentForm";
import { ReportWrapperProps } from "@components/Report/report.types";
import Snackbar from "@components/Snackbar/Snackbar";

const ReportWrapper: FC<ReportWrapperProps> = ({
  children,
  systemMessage,
  onPress,
  item,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const sendReport = () => setModalVisible(true);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const hideSnackbar = () => setSnackbarVisible(false);
  const showSnackbar = () => setSnackbarVisible(true);

  const onReportSuccess = () => {
    setModalVisible(false);
    showSnackbar();
  };

  return (
    <>
      <Pressable
        onLongPress={systemMessage ? sendReport : null}
        onPress={onPress}>
        {children}
        <Snackbar
          visible={snackbarVisible}
          onDismiss={hideSnackbar}
          onIconPress={hideSnackbar}
          text="T00560"
        />
      </Pressable>
      <BottomModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}>
        <ReportContentForm
          systemMessage={systemMessage}
          item={item}
          onReportSuccess={onReportSuccess}
        />
      </BottomModalContainer>
    </>
  );
};

export default ReportWrapper;
