import { globalStyles } from "@styles/global";
import { FC } from "react";
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { Checkbox, CheckboxProps, Text } from "react-native-paper";
import { Image, ImageProps } from "expo-image";
import { spacing56 } from "@styles/spacing";

type Props = {
  title: string;
  img?: ImageProps["source"];
  svg?: JSX.Element;
  placeholder?: ImageProps["source"];
  onPress: () => void;
  checkboxProps: CheckboxProps;
  style?: StyleProp<ViewStyle>;
};

export const CheckboxListItem: FC<Props> = ({
  title,
  img,
  svg,
  onPress,
  checkboxProps,
  placeholder,
  style,
}) => {
  const { gapLarge } = globalStyles;
  const { container, imageStyle } = styles;

  return (
    <TouchableOpacity onPress={onPress} style={[gapLarge, container, style]}>
      <View style={[gapLarge, container]}>
        {img && (
          <Image source={img} style={imageStyle} placeholder={placeholder} />
        )}
        {svg && svg}
        <Text variant="bodyLarge" style={{ maxWidth: "80%" }}>
          {title}
        </Text>
      </View>
      <Checkbox.Android {...checkboxProps} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  imageStyle: { borderRadius: spacing56, width: spacing56, height: spacing56 },
});
