import { api } from "@api/api";
import { endpoints } from "@api/endpoints";
import {
  BasicChartItemType,
  BasicGroupedBarItemType,
  GroupedStackedBarItemType,
} from "@components/Charts";
import {
  AppointmentsStatistics,
  EarningsStatsResponse,
  OccupancyStatsChartResponse,
  OccupancyStatsResponse,
} from "@components/Charts/chart.types";

export const getAppointmentStatsYearChart = async () => {
  const { data } = await api.get<BasicGroupedBarItemType[]>(
    endpoints.APPOINTMENT_STATS_YEAR_CHART,
  );
  return data;
};

export const getOccupancyStats = async () => {
  const { data } = await api.get<OccupancyStatsResponse>(
    endpoints.OCCUPANCY_STATS_LIST,
  );
  return data;
};

export const getOccupancyStatsCharts = async (params?: {
  month?: number;
  year?: number;
}) => {
  const { data } = await api.get<OccupancyStatsChartResponse>(
    endpoints.OCCUPANCY_STATS_CHART,
    { params },
  );
  return data;
};

export const getAppointmentStatsMonthChart = async (
  month: number,
  year: number,
) => {
  const { data } = await api.get<BasicChartItemType[]>(
    endpoints.APPOINTMENT_STATS_MONTH_CHART,
    { params: { year, month } },
  );
  return data;
};

export const getAppointmentsStatistics = async () => {
  const { data } = await api.get<AppointmentsStatistics>(
    endpoints.APPOINTMENT_STATS_LIST,
  );
  return data;
};

export const getEarningsStats = async () => {
  const { data } = await api.get<EarningsStatsResponse>(
    endpoints.EARNINGS_STATS_LIST,
  );
  return data;
};

export const getEarningsStatsMonthChart = async (
  month: number,
  year: number,
) => {
  const { data } = await api.get<BasicChartItemType[]>(
    endpoints.EARNINGS_STATS_MONTH_CHART,
    { params: { year, month } },
  );
  return data;
};

export const getEarningsStatsYearChart = async () => {
  const { data } = await api.get<BasicGroupedBarItemType[]>(
    endpoints.EARNINGS_STATS_YEAR_CHART,
  );
  return data;
};

export const getYearOccupancyStats = async () => {
  const { data } = await api.get<GroupedStackedBarItemType[]>(
    endpoints.OCCUPANCY_STATS_YEAR_CHART,
  );
  return data;
};
