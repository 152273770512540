import Svg, { SvgProps, Rect, Path } from "react-native-svg";

export const SvgOnlinePaymentsIcon3 = (props: SvgProps) => (
  <Svg width={56} height={56} fill="none" {...props}>
    <Rect width={56} height={56} fill="#DEE1FF" rx={28} />
    <Path
      fill="#4666FF"
      d="m32 22 2.29 2.29-4.88 4.88-4-4L18 32.59 19.41 34l6-6 4 4 6.3-6.29L38 28v-6h-6Z"
    />
  </Svg>
);
