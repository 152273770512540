import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = ({
  iconColor = "#fff",
  ...props
}: SvgProps & { iconColor?: string }) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill={iconColor}
      d="m21.5 7-4 4V7.5c0-.55-.45-1-1-1h-6.18L21.5 17.68V7ZM3.77 2.5 2.5 3.77 5.23 6.5H4.5c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.54-.18l3.19 3.18 1.27-1.27L3.77 2.5Z"
    />
  </Svg>
);
export default SvgComponent;
