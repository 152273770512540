import {
  RootStackParamList,
  ScheduleAppointmentStackParamsList,
} from "@navigators/navigation.types";
import { CompositeScreenProps } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { AppointmentSlots } from "@screens/Calendar/availability.types";
import { getPhysiotherapistFullData } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { useQuery } from "@tanstack/react-query";
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CertificatesTab from "./CertificatesTab";
import InformationTab from "./InformationTab";
import { queryKeysPhysiotherapistProfileData } from "./queryKeysPhysiotherapist";
import RatingsTab from "./RatingsTab";
import ServicesTab from "./ServicesTab";
import { Text } from "react-native-paper";
import { TabHeader } from "./TabHeader";
import { AppbarTitleWithBackAction } from "@components/Appbar";
import { PhysiotherapistCardWithRating } from "@components/cards";
import { Platform, View } from "react-native";
import { SvgCalendar } from "@assets/svg";
import { PrimaryButton } from "@components/buttons";
import { palettes } from "@styles/colors";
import { spacing64 } from "@styles/spacing";
import { isWeb } from "@utils/constants";
import { PreviewType } from "@globalTypes/common.types";

type MaterialTabBarType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("react-native-collapsible-tab-view").MaterialTabBar;
type TabsType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("react-native-collapsible-tab-view").Tabs;
type MaterialTabItemType = Platform["OS"] extends "web"
  ? unknown
  : typeof import("react-native-collapsible-tab-view").MaterialTabItem;

export let MaterialTabBar: MaterialTabBarType;
export let Tabs: TabsType;
export let MaterialTabItem: MaterialTabItemType;

if (!isWeb) {
  MaterialTabBar = (
    require("react-native-collapsible-tab-view") as typeof import("react-native-collapsible-tab-view")
  ).MaterialTabBar;
  Tabs = (
    require("react-native-collapsible-tab-view") as typeof import("react-native-collapsible-tab-view")
  ).Tabs;
  MaterialTabItem = (
    require("react-native-collapsible-tab-view") as typeof import("react-native-collapsible-tab-view")
  ).MaterialTabItem;
}

type PhysiotherapistProfileScreenType = PropsWithChildren<
  CompositeScreenProps<
    NativeStackScreenProps<
      RootStackParamList,
      "PhysiotherapistProfile",
      "PreviewScreen"
    >,
    NativeStackScreenProps<ScheduleAppointmentStackParamsList>
  >
>;

export type TabItemProps = {
  id: number;
  navigateToPreview?: (previewType: PreviewType, uri: string) => void;
};

export const usePhysiotherapist = (id: number) => {
  return useQuery({
    queryKey: queryKeysPhysiotherapistProfileData.full(id),
    queryFn: async () => await getPhysiotherapistFullData(id),
  });
};

const PhysiotherapistProfile: FC<PhysiotherapistProfileScreenType> = ({
  route,
  navigation: { navigate, setOptions },
}) => {
  const { id, serviceType } = route.params;

  const [selectedServiceType, setSelectedServiceType] =
    useState<AppointmentSlots>(
      serviceType && serviceType !== AppointmentSlots.ONLINE_OR_STATIONARY
        ? serviceType
        : AppointmentSlots.STATIONARY,
    );

  const { scrollContainer, gapLarge } = globalStyles;

  const { t } = useTranslation();

  const handleScheduleAppointment = useCallback(
    (serviceType?: AppointmentSlots) =>
      navigate("ScheduleAppointmentStack", {
        screen: "PhysiotherapistCalendarForPatient",
        params: {
          physiotherapistId: id,
          redirectToScreen: route.params?.redirectToScreen,
          serviceType,
        },
      }),
    [id, navigate, route.params?.redirectToScreen],
  );

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack } }) => (
        <View>
          <AppbarTitleWithBackAction title={t("T00065")} onClose={goBack} />
          <View
            style={[
              scrollContainer,
              gapLarge,
              { backgroundColor: palettes.primary[100] },
            ]}>
            <PhysiotherapistCardWithRating initialData={{ id }} />
            <PrimaryButton
              icon={props => <SvgCalendar fill="white" {...props} />}
              label="T00049"
              onPress={() => handleScheduleAppointment(selectedServiceType)}
            />
          </View>
        </View>
      ),
    });
  }, [
    gapLarge,
    handleScheduleAppointment,
    id,
    navigate,
    route.params.redirectToScreen,
    scrollContainer,
    selectedServiceType,
    setOptions,
    t,
  ]);

  const scrollProps = {
    style: { ...scrollContainer },
    contentContainerStyle: { paddingBottom: spacing64 },
  };

  return (
    <>
      <Tabs.Container
        renderTabBar={props => (
          <MaterialTabBar
            {...props}
            scrollEnabled
            TabItemComponent={props => (
              <MaterialTabItem
                {...props}
                style={{ minWidth: 120 }}
                label={props => <Text>{props.name}</Text>}
              />
            )}
          />
        )}
        renderHeader={() => (
          <TabHeader
            id={id}
            navigate={handleScheduleAppointment}
            setSelectedServiceType={setSelectedServiceType}
            selectedServiceType={selectedServiceType}
          />
        )}>
        <Tabs.Tab name={t("T00147")}>
          <Tabs.ScrollView {...scrollProps}>
            <InformationTab id={id} />
          </Tabs.ScrollView>
        </Tabs.Tab>
        <Tabs.Tab name={t("T00373")}>
          <Tabs.ScrollView {...scrollProps}>
            <RatingsTab id={id} />
          </Tabs.ScrollView>
        </Tabs.Tab>
        <Tabs.Tab name={t("T00240")}>
          <Tabs.ScrollView {...scrollProps}>
            <ServicesTab id={id} />
          </Tabs.ScrollView>
        </Tabs.Tab>
        <Tabs.Tab name={t("T00274")}>
          <Tabs.ScrollView {...scrollProps}>
            <CertificatesTab
              id={id}
              navigateToPreview={(previewType, uri) =>
                navigate("PreviewScreen", { previewType, uri })
              }
            />
          </Tabs.ScrollView>
        </Tabs.Tab>
      </Tabs.Container>
    </>
  );
};

export default PhysiotherapistProfile;
