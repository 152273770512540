import { AppbarTitleWithBackAction } from "@components/Appbar";
import {
  RootStackParamList,
  RootTabsParamList,
} from "@navigators/navigation.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { FC, PropsWithChildren, useCallback, useEffect } from "react";
import { Alert, ScrollView, View } from "react-native";
import { Chip, Text } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { globalStyles } from "@styles/global";
import { GeneralTrainingTile } from "@components/Tile/training/GeneralTrainingTile";
import { PrimaryButton } from "@components/buttons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { assignWorkoutSet } from "@services/ApiService/trainings";
import { queryKeysProphylacticTrainings } from "../queryKeysTrainingsAndExercises";
import { useErrors } from "@hooks/useErrors";
import { AxiosError } from "axios";
import { CompositeScreenProps } from "@react-navigation/native";

export const GeneralWorkoutSetDetails: FC<
  PropsWithChildren<
    CompositeScreenProps<
      NativeStackScreenProps<RootStackParamList, "GeneralWorkoutSetDetails">,
      NativeStackScreenProps<RootTabsParamList, "TrainingsStack">
    >
  >
> = ({ route, navigation: { setOptions, navigate } }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setErrorsFromResponse } = useErrors();

  const { scrollContainer, gapLarge, gapMedium } = globalStyles;
  const { id, name, frequency, duration, prophylacticWorkoutSetOrder } =
    route.params.data;

  useEffect(() => {
    setOptions({
      header: ({ navigation: { goBack } }) => (
        <AppbarTitleWithBackAction onClose={goBack} title={name} />
      ),
    });
  }, [setOptions, name]);

  const assignProphylacticSuccessHandler = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKeysProphylacticTrainings.list(),
    });
    Alert.alert(t("T01035"), "", [
      {
        text: t("T00540"),
        onPress: () => navigate("GeneralWorkoutCategories"),
      },
      {
        text: t("T01036"),
        onPress: () =>
          navigate("TrainingsStack", {
            screen: "AllPatientTrainings",
            params: {
              initialRoute: "GeneralTrainings",
            },
          }),
      },
    ]);
  }, [navigate, queryClient, t]);

  const { mutate, isLoading } = useMutation({
    mutationFn: assignWorkoutSet,
    onSuccess: assignProphylacticSuccessHandler,
    onError: ({ response }: AxiosError) => setErrorsFromResponse(response),
  });

  const renderChipWithInfo = useCallback(
    (title: string, index: number) => (
      <Chip key={`set-info-${index}`} style={{ alignSelf: "flex-start" }}>
        <Text variant="labelLarge">{title}</Text>
      </Chip>
    ),
    [],
  );

  const chipList = [
    `${t("T01077")}: ${prophylacticWorkoutSetOrder.length}`,
    `${t("T00253")}: ${t("T01076", { value: frequency })}`,
    `${t("T01078", {
      value: duration,
    })}`,
  ];

  return (
    <ScrollView contentContainerStyle={[scrollContainer, gapLarge]}>
      <View style={gapMedium}>
        <Text variant="titleMedium">{t("T01080")}</Text>
        {chipList.map((e, i) => renderChipWithInfo(e, i))}
        <Text variant="bodyMedium">{t("T01079")}</Text>
      </View>
      <View style={gapMedium}>
        {prophylacticWorkoutSetOrder.map(({ workout }, index) => (
          <GeneralTrainingTile
            key={`prophylactic-training-${workout}-${index}`}
            id={workout}
            onPress={id =>
              navigate("GeneralWorkoutDetails", {
                trainingId: id,
              })
            }
          />
        ))}
      </View>
      <PrimaryButton
        label="T00834"
        onPress={() => mutate(id)}
        loading={isLoading}
      />
    </ScrollView>
  );
};
