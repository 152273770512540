import { TrainingTile } from "@components/Tile";
import { FetchError } from "@components/errors";
import { useErrors } from "@hooks/useErrors";
import { useUserDetails } from "@hooks/user/useUserDetails";
import { RootStackParamList } from "@navigators/navigation.types";
import {
  NavigationProp,
  useIsFocused,
  useNavigation,
} from "@react-navigation/native";
import { queryKeysForPatientAndPhysiotherapist } from "@screens/Common/queryKeysForPatientAndPhysiotherapist";
import { queryKeysIndividualTrainings } from "@screens/TrainingsAndExercises/queryKeysTrainingsAndExercises";
import { TrainingType } from "@screens/TrainingsAndExercises/training.types";
import { getAssignedTrainings } from "@services/ApiService/trainings";
import { getPatientBasicData } from "@services/ApiService/users";
import { globalStyles } from "@styles/global";
import { useQueries, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, SectionList, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { TransKey } from "@globalTypes/i18next";

type SectionType = {
  title: TransKey;
  data: (TrainingType | Pick<TrainingType, "id">)[];
};

type ListType = {
  patientId?: number;
  ListHeaderComponent: JSX.Element;
  isAssistant: boolean;
};

export const ActiveAndInactiveTrainingsList = ({
  patientId,
  ListHeaderComponent,
  isAssistant,
}: ListType) => {
  const { isPhysiotherapist } = useUserDetails();
  const { t } = useTranslation();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { loading, gapMedium, scrollContainer } = globalStyles;
  const { setErrorsFromResponse: setErrorsFromResponseActiveTrainings } =
    useErrors();
  const { setErrorsFromResponse: setErrorsFromResponseInActiveTrainings } =
    useErrors();
  const {
    activeList,
    assignedToPatientActiveList,
    inActiveList,
    assignedToPatientInActiveList,
  } = queryKeysIndividualTrainings;
  const [activeTrainings, inactiveTrainings] = useQueries({
    queries: [
      {
        queryKey: patientId
          ? assignedToPatientActiveList(patientId)
          : activeList(isAssistant),
        queryFn: async () =>
          await getAssignedTrainings({
            isLibrary: false,
            isActive: true,
            isFixmeUser: isAssistant,
            patientId,
          }),
        onError: ({ response }: AxiosError) =>
          setErrorsFromResponseActiveTrainings(response),
      },

      {
        queryKey: patientId
          ? assignedToPatientInActiveList(patientId)
          : inActiveList(isAssistant),
        queryFn: async () =>
          await getAssignedTrainings({
            isLibrary: false,
            isActive: false,
            isFixmeUser: isAssistant,
            patientId,
          }),
        onError: ({ response }: AxiosError) =>
          setErrorsFromResponseInActiveTrainings(response),
      },
    ],
  });

  const isFocused = useIsFocused();

  const {
    data: patientData,
    isLoading: isPatientDataLoading,
    isError: isPatientDataError,
    refetch: refetchPatientData,
  } = useQuery({
    queryKey: queryKeysForPatientAndPhysiotherapist.basicData(patientId),
    queryFn: async () => await getPatientBasicData(patientId),
    enabled: isPhysiotherapist,
  });

  const {
    data: activeTrainingsList,
    refetch: refetchActiveTrainings,
    isLoading: isActiveTrainingsLoading,
    isError: isActiveTrainingsError,
  } = activeTrainings;
  const {
    data: inactiveTrainingsList,
    refetch: refetchInactiveTrainings,
    isLoading: isInactiveTrainingsLoading,
    isError: isInactiveTrainingsError,
  } = inactiveTrainings;

  const sections: SectionType[] = useMemo(
    () => [
      {
        title: "T00376",
        data: activeTrainingsList,
      },
      {
        title: "T00377",
        data: inactiveTrainingsList,
      },
    ],
    [activeTrainingsList, inactiveTrainingsList],
  );

  const renderItem = useCallback(
    ({ item: { id } }) => (
      <TrainingTile
        trainingId={id as number}
        onPress={() =>
          navigate("AssignedTrainingDetails", {
            id: id as number,
            gender: patientData?.gender,
          })
        }
      />
    ),
    [navigate, patientData?.gender],
  );

  const renderSectionHeader = ({
    section: { title, data },
  }: {
    section: {
      title: TransKey;
      data: TrainingType[];
    };
  }) => {
    return (
      <View style={gapMedium}>
        <Text variant="titleMedium">{`${t(title)}:`}</Text>
        {!data.length && <Text variant="bodyMedium">{t("T00193")}</Text>}
      </View>
    );
  };

  const refetchQueries = useCallback(
    async () =>
      await Promise.all([
        refetchActiveTrainings(),
        refetchInactiveTrainings(),
        isPhysiotherapist && refetchPatientData(),
      ]),
    [
      isPhysiotherapist,
      refetchActiveTrainings,
      refetchInactiveTrainings,
      refetchPatientData,
    ],
  );

  useEffect(() => {
    if (isFocused) void refetchQueries();
  }, [isFocused, refetchQueries]);

  const isAnyLoading = useMemo(
    () =>
      isActiveTrainingsLoading ||
      isInactiveTrainingsLoading ||
      (isPhysiotherapist && isPatientDataLoading),
    [
      isActiveTrainingsLoading,
      isInactiveTrainingsLoading,
      isPatientDataLoading,
      isPhysiotherapist,
    ],
  );
  const isAnyError = useMemo(
    () =>
      isActiveTrainingsError ||
      isInactiveTrainingsError ||
      (isPhysiotherapist && isPatientDataError),
    [
      isActiveTrainingsError,
      isInactiveTrainingsError,
      isPatientDataError,
      isPhysiotherapist,
    ],
  );

  if (isAnyLoading) return <ActivityIndicator style={loading} />;
  if (isAnyError) return <FetchError action={refetchQueries} />;

  return (
    <SectionList
      ListHeaderComponent={ListHeaderComponent}
      refreshControl={
        <RefreshControl refreshing={isAnyLoading} onRefresh={refetchQueries} />
      }
      sections={
        !activeTrainingsList.length && !inactiveTrainingsList.length
          ? []
          : sections
      }
      keyExtractor={({ id }, i) => `${id}-${i}`}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      ListEmptyComponent={<Text variant="bodyMedium">{t("T00193")}</Text>}
      contentContainerStyle={[gapMedium, scrollContainer]}
      stickySectionHeadersEnabled={false}
    />
  );
};

export default ActiveAndInactiveTrainingsList;
