import { MediaTypeOptions } from "expo-image-picker";

export type PickedAssetType = {
  uri: string;
  name: string;
};
export type useImageOrVideoOptions = {
  mediaTypes?: MediaTypeOptions;
  multiselect?: boolean;
  initialData?: PickedAssetType[];
  supportedFileTypes?: FileType[];
  maxNumberOfFiles?: number;
};

export type FileType =
  | "MP4"
  | "AVI"
  | "MKV"
  | "MOV"
  | "WMV"
  | "HEVC"
  | "JPG"
  | "JPEG"
  | "PNG"
  | "HEIC"
  | "HEIF"
  | "PDF";

type InvalidFileFormatAlertType = (name: string) => void;

type InvalidFileSizeAlertType = (maxSize: number, name: string) => void;

export type ValidateResultsArgs<T> = {
  supportedFileTypes: FileType[];
  invalidFileFormatAlert: InvalidFileFormatAlertType;
  invalidFileSizeAlert: InvalidFileSizeAlertType;
  results: T[];
};

export type ValidateFileSizeArgs = {
  size: number;
  format: FileType;
  name: string;
  alert: InvalidFileSizeAlertType;
};

export enum MediaType {
  file = "file",
  gallery = "gallery",
}

export type PickFilesFromDevice = (galleryType: MediaType) => Promise<void>;
