import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import { t } from "i18next";
import { FC, PropsWithChildren, useState } from "react";
import { AppbarTitleWithBackAction } from "../components/Appbar";
import ScheduleAppointment from "../screens/Appointments/ScheduleAppointment/ScheduleAppointment";
import { ScheduleAppointmentStackParamsList } from "./navigation.types";
import PlaceOfInjury from "../screens/Appointments/ScheduleAppointment/PlaceOfInjury";
import { AppointmentContextProvider } from "@screens/Appointments/appointment.context";
import InjuryDescription from "../screens/Appointments/ScheduleAppointment/InjuryDescription";
import ScheduledAppointmentSummary from "../screens/Appointments/ScheduleAppointment/ScheduledAppointmentSummary";
import MedicalRecordsToAppointment from "@screens/Appointments/ScheduleAppointment/MedicalRecordsToAppointment";
import AppointmentConfirmation from "@screens/Appointments/ScheduleAppointment/AppointmentConfirmation/AppointmentConfirmation";
import PhysiotherapistCalendarForPatient from "@screens/Appointments/PhysiotherapistCalendarForPatient";
import AbortSchedulingAppointmentBottomSheet from "@screens/Appointments/ScheduleAppointment/AbortSchedulingAppointmentBottomSheet";

const Stack = createNativeStackNavigator<ScheduleAppointmentStackParamsList>();

const ScheduleAppointmentStackNavigator: FC<
  PropsWithChildren<
    NativeStackScreenProps<
      ScheduleAppointmentStackParamsList,
      "ScheduleAppointment"
    >
  >
> = ({ navigation: { pop } }) => {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <AppointmentContextProvider>
      <Stack.Navigator
        screenOptions={{
          headerBackVisible: false,
        }}>
        <Stack.Screen
          name="PhysiotherapistCalendarForPatient"
          component={PhysiotherapistCalendarForPatient}
          options={{
            header: () => (
              <AppbarTitleWithBackAction
                title={t("T00074")}
                onClose={() => setModalVisible(true)}
              />
            ),
          }}
        />
        <Stack.Screen
          name="ScheduleAppointment"
          component={ScheduleAppointment}
          options={{
            header: ({ navigation: { goBack } }) => (
              <AppbarTitleWithBackAction
                title={t("T00049")}
                onBack={goBack}
                onClose={() => setModalVisible(true)}
              />
            ),
          }}
        />
        <Stack.Screen
          name="PlaceOfInjury"
          component={PlaceOfInjury}
          options={{
            header: ({ navigation: { goBack } }) => (
              <AppbarTitleWithBackAction
                title={t("T00280")}
                onBack={goBack}
                onClose={() => setModalVisible(true)}
              />
            ),
          }}
        />
        <Stack.Screen
          name="InjuryDescription"
          component={InjuryDescription}
          options={{
            header: ({ navigation: { goBack } }) => (
              <AppbarTitleWithBackAction
                title={t("T00285")}
                onBack={goBack}
                onClose={() => setModalVisible(true)}
              />
            ),
          }}
        />
        <Stack.Screen
          name="MedicalRecordsToAppointment"
          component={MedicalRecordsToAppointment}
          options={{
            header: ({ navigation: { goBack } }) => (
              <AppbarTitleWithBackAction
                title={t("T00080")}
                onBack={goBack}
                onClose={() => setModalVisible(true)}
              />
            ),
          }}
        />
        <Stack.Screen
          name="ScheduledAppointmentSummary"
          component={ScheduledAppointmentSummary}
          options={{
            header: ({ navigation: { goBack } }) => (
              <AppbarTitleWithBackAction
                title={t("T00053")}
                onBack={goBack}
                onClose={() => setModalVisible(true)}
              />
            ),
          }}
        />
        <Stack.Screen
          name="AppointmentConfirmation"
          component={AppointmentConfirmation}
          options={{
            header: () => (
              <AppbarTitleWithBackAction
                title={t("T00594")}
                onClose={() => pop()}
              />
            ),
          }}
        />
      </Stack.Navigator>
      <AbortSchedulingAppointmentBottomSheet
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        pop={pop}
      />
    </AppointmentContextProvider>
  );
};

export default ScheduleAppointmentStackNavigator;
