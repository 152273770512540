import { WEBVIEW_URIS } from "@utils/constants";
import { t } from "i18next";

export enum ModalType {
  privacy = "privacy",
  terms = "terms",
  about_the_application_project = "aboutTheApplicationProject",
}

export const getWebViewData = (type: ModalType) => {
  switch (type) {
    case ModalType.privacy:
      return {
        uri: WEBVIEW_URIS.privacy,
        title: t("T00094"),
      };
      break;
    case ModalType.terms:
      return {
        uri: WEBVIEW_URIS.terms,
        title: t("T00093"),
      };
      break;
    case ModalType.about_the_application_project:
      return {
        uri: WEBVIEW_URIS.aboutTheApplicationProject,
        title: t("T00110"),
      };
      break;
  }
};
